import { ManagementLabels } from 'pages/management/interfaces/ManagementLabels';

export const feedManagementLabels: ManagementLabels = {
  title: 'Feed management',
  createButtonLabel: 'Create new feed',
  searchBarPlaceholder: 'Search feeds...',
  countLabel: 'Feeds',
  selectedCountLabel: 'Selected',
  dropdownLabel: 'Feed options',
  placeholderTitle: 'Feed details',
  placeholderDescriptionFirstLine: 'Select a feed to display',
  placeholderDescriptionSecondLine: 'its details here',
};
