import { CursorPointer } from 'styles/utils';
import { ReactComponent as SavedGray } from 'assets/saved-gray.svg';
import { ReactComponent as SavedSecondary } from 'assets/saved-secondary.svg';
import { ReactComponent as SavedSecondaryFilled } from 'assets/saved-secondary-filled.svg';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { saveAsync } from 'store/development/development-slice';
import { MouseEventHandler, useEffect, useState } from 'react';
import { selectAuthenticationResponse } from 'store/authentication/authentication-slice';
import { Development } from 'models';
import { setTriggerGetAllFilterSetEntries } from 'store/home-sidebar-filter/home-sidebar-filter-slice';
import { ArrowDirections, Tooltip } from 'components/Tooltip';
import styled from '@emotion/styled';

interface SaveDevelopmentButtonProps {
  developmentId?: number;
  savedByUserIds?: number[];
  setIsDevelopmentSavedByUser?: React.Dispatch<boolean>;
  onAfterSave: (
    development: Development | null,
    isDevelopmentSavedByUser: boolean
  ) => void;
  hasTooltip: boolean;
}

const TooltipContainer = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});

export const SaveDevelopmentButton = (props: SaveDevelopmentButtonProps) => {
  const dispatch = useAppDispatch();
  const authenticationResponse = useAppSelector(selectAuthenticationResponse);
  const [isSaveHovered, setIsSaveHovered] = useState(false);
  const [isDevelopmentSavedByUser, setIsDevelopmentSavedByUser] =
    useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [saveButtonTooltipContainer, setSaveButtonTooltipContainer] =
    useState<HTMLSpanElement | null>(null);

  useEffect(() => {
    if (props.savedByUserIds && authenticationResponse) {
      const savedUserIdsIncludesCurrentUserId = props.savedByUserIds.includes(
        authenticationResponse.id
      );
      setIsDevelopmentSavedByUser(savedUserIdsIncludesCurrentUserId);
      if (props.setIsDevelopmentSavedByUser) {
        props.setIsDevelopmentSavedByUser(savedUserIdsIncludesCurrentUserId);
      }
    }
  }, [props.savedByUserIds]);

  const handleSave: MouseEventHandler<HTMLSpanElement> = async (e) => {
    e.stopPropagation();
    if (!isSaveDisabled) {
      if (props.developmentId) {
        setIsSaveDisabled(true);
        setIsDevelopmentSavedByUser(!isDevelopmentSavedByUser);
        if (props.setIsDevelopmentSavedByUser) {
          props.setIsDevelopmentSavedByUser(!isDevelopmentSavedByUser);
        }
        const newDevelopment = await dispatch(saveAsync(props.developmentId));
        props.onAfterSave(newDevelopment, !isDevelopmentSavedByUser);
        dispatch(setTriggerGetAllFilterSetEntries());
        setIsSaveDisabled(false);
      }
    }
  };

  return (
    <CursorPointer
      id={`SaveDevelopmentButton${props.developmentId}`}
      onClick={handleSave}
      onMouseOver={() => setIsSaveHovered(true)}
      onMouseLeave={() => setIsSaveHovered(false)}
      style={{ height: '18px', position: 'relative' }}
    >
      {!isDevelopmentSavedByUser && !isSaveHovered && <SavedGray />}
      {!isDevelopmentSavedByUser && isSaveHovered && <SavedSecondary />}
      {isDevelopmentSavedByUser && <SavedSecondaryFilled />}
      {props.hasTooltip && (
        <TooltipContainer
          ref={(saveButtonTooltipContainer) =>
            setSaveButtonTooltipContainer(saveButtonTooltipContainer)
          }
        >
          <Tooltip
            id={`DevelopmentDetailSubHeaderSaveTooltip`}
            relativeToElement={saveButtonTooltipContainer}
            hasArrow
            arrowDirection={ArrowDirections.Up}
          >
            <span
              id={`DevelopmentDetailSubHeaderSaveTooltipSpan`}
              style={{ whiteSpace: 'nowrap' }}
            >
              Bookmark
            </span>
          </Tooltip>
        </TooltipContainer>
      )}
    </CursorPointer>
  );
};
