import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { getRegionsWithDevelopmentCountGreaterThanZero } from 'services/regions/regions-service';
import { Region, PaginatedResponse } from 'models';
import { paginationConfig } from 'config/pagination-config';
import { filterKeys } from 'config/filter-keys';
import { isArraySubsetOfAnother } from 'utils/array';

export interface HomeSidebarFilterRegionState {
  regions: Region[];
  totalRecords: number;
  searchTerm: string;
  page: number;
  previousPage: number;
  perPage: number;
  isConsecutiveFetch: boolean;
}

const initialState: HomeSidebarFilterRegionState = {
  regions: [],
  totalRecords: 0,
  searchTerm: '',
  page: 1,
  previousPage: 1,
  perPage: paginationConfig.regionsPerPage,
  isConsecutiveFetch: false,
};

export const getAllHomeSidebarFilterRegionsAsync = (): AppThunk<
  Promise<PaginatedResponse<Region> | null>
> => {
  return async (dispatch, getState) => {
    const selectedEnvironment = getState().environment.selectedEnvironment;
    const searchTerm = getState().homeSidebarFilterRegion.searchTerm;
    const page = getState().homeSidebarFilterRegion.page;
    const previousPage = getState().homeSidebarFilterRegion.previousPage;
    const perPage = getState().homeSidebarFilterRegion.perPage;
    const activeFilters = getState().homeSidebarFilter.activeFilters;
    const ids = getState().feedPicker.appliedFeed?.regionIds;
    const isConsecutiveFetch =
      getState().homeSidebarFilterRegion.isConsecutiveFetch;

    const getRegions = async (pageNumber: number) => {
      return await getRegionsWithDevelopmentCountGreaterThanZero(
        selectedEnvironment,
        searchTerm,
        pageNumber,
        perPage,
        activeFilters,
        ids
      );
    };

    if (isConsecutiveFetch) {
      dispatch(setIsConsecutiveRegionsFetch(false));
    }

    if (
      isConsecutiveFetch &&
      activeFilters[filterKeys.region] &&
      activeFilters[filterKeys.region].length &&
      !ids?.length
    ) {
      let currentPage = 0;
      let isLastPage = false;
      let allRegionIds: number[] = [];
      let areAllCheckedFiltersDisplayed = false;
      let response;
      let incomingRegions: Region[] = [];
      do {
        response = await getRegions(++currentPage);
        if (!response.data) {
          return null;
        }
        isLastPage =
          currentPage >= Math.ceil(response.data.totalRecords / perPage);
        incomingRegions = incomingRegions.concat(response.data.records);
        allRegionIds = allRegionIds.concat(
          response.data.records.map((x) => x.id!)
        );
        areAllCheckedFiltersDisplayed = isArraySubsetOfAnother(
          activeFilters[filterKeys.region] as number[],
          allRegionIds
        );
      } while (!areAllCheckedFiltersDisplayed && !isLastPage);
      response.data.records = incomingRegions;
      dispatch(setHomeSidebarFilterRegionsPage(currentPage));
      dispatch(setHomeSidebarFilterRegions(response.data));
      return response.data;
    } else {
      const response = await getRegions(page);
      if (page === 1) {
        dispatch(setHomeSidebarFilterRegions(response.data));
      } else {
        if (page > previousPage) {
          dispatch(addHomeSidebarFilterRegions(response.data));
        }
      }
      return response.data;
    }
  };
};

export const homeSidebarFilterRegionSlice = createSlice({
  name: 'home-sidebar-filter-region',
  initialState,
  reducers: {
    setHomeSidebarFilterRegions: (
      state,
      action: PayloadAction<PaginatedResponse<Region> | null>
    ) => {
      state.regions = action.payload?.records ?? [];
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    addHomeSidebarFilterRegions: (
      state,
      action: PayloadAction<PaginatedResponse<Region> | null>
    ) => {
      state.regions = state.regions.concat(action.payload?.records ?? []);
      state.totalRecords = action.payload?.totalRecords ?? 0;
      state.previousPage = state.page;
    },
    setHomeSidebarFilterRegionsSearchTerm: (
      state,
      action: PayloadAction<string>
    ) => {
      state.page = 1;
      state.searchTerm = action.payload;
    },
    setHomeSidebarFilterRegionsPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    increaseHomeSidebarFilterRegionsPage: (state) => {
      state.page = state.page + 1;
    },
    resetHomeSidebarFilterRegionsPage: (state) => {
      state.page = 1;
      state.previousPage = 1;
    },
    setIsConsecutiveRegionsFetch: (state, action: PayloadAction<boolean>) => {
      state.isConsecutiveFetch = action.payload;
    },
  },
});

export const {
  setHomeSidebarFilterRegions,
  addHomeSidebarFilterRegions,
  setHomeSidebarFilterRegionsSearchTerm,
  setHomeSidebarFilterRegionsPage,
  increaseHomeSidebarFilterRegionsPage,
  resetHomeSidebarFilterRegionsPage,
  setIsConsecutiveRegionsFetch,
} = homeSidebarFilterRegionSlice.actions;

export const selectHomeSidebarFilterRegions = (state: RootState) =>
  state.homeSidebarFilterRegion.regions;
export const selectHomeSidebarFilterRegionsTotalRecords = (state: RootState) =>
  state.homeSidebarFilterRegion.totalRecords;
export const selectHomeSidebarFilterRegionsSearchTerm = (state: RootState) =>
  state.homeSidebarFilterRegion.searchTerm;
export const selectHomeSidebarFilterRegionsPage = (state: RootState) =>
  state.homeSidebarFilterRegion.page;
export const selectHomeSidebarFilterRegionsPerPage = (state: RootState) =>
  state.homeSidebarFilterRegion.perPage;

export default homeSidebarFilterRegionSlice.reducer;
