import { developmentKeys } from 'config/development-keys';
import { DevelopmentIngestionStatus } from 'enums';
import { ActiveFilters, Environment, FilterEntryValue } from 'models';
import { dateRangeStringToElasticsearchQueryClauses } from 'utils/elasticsearch';
import { applyTimezoneOffset } from 'utils/date';
import environmetns from 'config/user-environments.json';

export const getDevelopmentsBaseQuery = (
  activeFilters: ActiveFilters,
  query: any,
  selectedEnvironment: Environment | null,
  propertiesToExclude?: string[]
): any => {
  const queryClone = JSON.parse(JSON.stringify(query));

  queryClone.filter.push({
    bool: {
      must: [
        {
          match: {
            [developmentKeys.ingestionStatus]: {
              query: DevelopmentIngestionStatus.resolved,
              minimum_should_match: '100%',
            },
          },
        },
      ],
    },
  });

  // if (selectedEnvironment) {
  //   if (!selectedEnvironment.canViewAllChannels) {
  //     queryClone.filter.push({
  //       terms: {
  //         [developmentKeys.externalRowIds]: selectedEnvironment.channelIds,
  //       },
  //     });
  //   } else {
  //     queryClone.filter.push({
  //       bool: {
  //         must_not: [
  //           {
  //             terms: {
  //               [developmentKeys.externalRowIds]:
  //                 selectedEnvironment.channelIds,
  //             },
  //           },
  //         ],
  //       },
  //     });
  //   }
  // }

  Object.entries(activeFilters).forEach(([property, values]) => {
    if (!propertiesToExclude || !propertiesToExclude.includes(property)) {
      if (Array.isArray(values)) {
        if (values && values.length) {
          const finalValues: FilterEntryValue[] = [];
          values.forEach((value) => {
            if (typeof value === 'string') {
              if (value.includes(',')) {
                const valuesSplittedByComma = value.split(',');
                finalValues.push(...valuesSplittedByComma);
              } else {
                finalValues.push(value);
              }
            } else {
              finalValues.push(value);
            }
          });
          /** This is only needed for the first filter set since it is the only one mixing up different development properties to filter by. */
          const groupedProperties: string[][] = [
            [developmentKeys.savedByUserIds],
          ];
          const filterSet = groupedProperties.find((filterSet) =>
            filterSet.includes(property)
          );
          if (!!filterSet) {
            const groupedPropertiesShouldClause = queryClone.filter.find(
              (boolClause: any) => {
                const alreadyContainsGroupedPropertiesClause = (
                  (boolClause.bool?.should || []) as any[]
                ).some((shouldClause: any) => {
                  return Object.keys(shouldClause.terms).some((key) => {
                    return filterSet.includes(key);
                  });
                });
                return alreadyContainsGroupedPropertiesClause;
              }
            );
            if (!!groupedPropertiesShouldClause) {
              groupedPropertiesShouldClause.bool.should.push({
                terms: {
                  [property]: finalValues,
                },
              });
            } else {
              queryClone.filter.push({
                bool: {
                  should: [
                    {
                      terms: {
                        [property]: finalValues,
                      },
                    },
                  ],
                  minimum_should_match: 1,
                },
              });
            }
          } else {
            const primitiveFinalValues = finalValues.filter(
              (finalValue) =>
                typeof finalValue === 'string' ||
                typeof finalValue === 'number' ||
                typeof finalValue === 'boolean'
            );
            const rangeFinalValues = finalValues.filter(
              (finalValue) => typeof finalValue === 'object'
            );
            const undefinedValues = finalValues.filter(
              (finalValue) => typeof finalValue === 'undefined'
            );
            if (primitiveFinalValues && primitiveFinalValues.length) {
              queryClone.filter.push({
                terms: {
                  [property]: primitiveFinalValues,
                },
              });
            }
            if (rangeFinalValues && rangeFinalValues.length) {
              rangeFinalValues.forEach((range) => {
                queryClone.filter.push({
                  range: {
                    [property]: range,
                  },
                });
              });
            }
            if (undefinedValues && undefinedValues.length) {
              queryClone.filter.push({
                bool: {
                  must_not: {
                    exists: {
                      field: property,
                    },
                  },
                },
              });
            }
          }
        }
      } else if (typeof values === 'string') {
        if (values.includes('-')) {
          const dateQuery = dateRangeStringToElasticsearchQueryClauses(
            property,
            values,
            true
          );
          queryClone.filter.push({
            bool: {
              must: [dateQuery],
            },
          });
        }
      }
    }
  });
  return queryClone;
};

export const adjustMillisecondsTimezoneOffset = (
  dateTimeInMilliseconds?: number
) => {
  if (dateTimeInMilliseconds) {
    const adjustedMilliseconds = applyTimezoneOffset(
      String(dateTimeInMilliseconds)
    );
    return Number(adjustedMilliseconds);
  }
  return dateTimeInMilliseconds;
};

export const getCanViewCopilot = (elasticsearchIndexPrefix: string) => {
  const entry = environmetns.environmetns.find(
    (item) => item.elasticsearchIndexPrefix === elasticsearchIndexPrefix
  );
  return entry ? entry.canViewCopilot : false; // Returns false if the prefix is not found
};
