import {
  configureStore,
  ThunkAction,
  Action,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import authenticationReducer from './authentication/authentication-slice';
import managementUserReducer from './management-user/management-user-slice';
import developmentReducer from './development/development-slice';
import developmentDetailReducer from './development-detail/development-detail-slice';
import recentlyViewedDevelopmentReducer from './recently-viewed-development/recently-viewed-development-slice';
import scrollSyncReducer from './scroll-sync/scroll-sync-slice';
import toastReducer from './toast/toast-slice';
import environmentReducer from './environment/environment-slice';
import feedPickerReducer from './feed/feed-picker-slice';
import managementFeedReducer from './feed/management-feed-slice';
import homeSidebarFilterReducer from './home-sidebar-filter/home-sidebar-filter-slice';
import homeSidebarFilterLanguageReducer from './home-sidebar-filter/home-sidebar-filter-language-slice';
import homeSidebarFilterRegionReducer from './home-sidebar-filter/home-sidebar-filter-region-slice';
import homeSidebarFilterCountryReducer from './home-sidebar-filter/home-sidebar-filter-country-slice';
import homeSidebarFilterAuthorityReducer from './home-sidebar-filter/home-sidebar-filter-authority-slice';
import homeSidebarFilterSubcategoryReducer from './home-sidebar-filter/home-sidebar-filter-subcategory-slice';
import homeSidebarFilterDocumentTypeReducer from './home-sidebar-filter/home-sidebar-filter-document-type-slice';
import newFeedFilterReducer from './new-feed-filter/new-feed-filter-slice';
import newFeedFilterLanguageReducer from './new-feed-filter/new-feed-filter-language-slice';
import newFeedFilterRegionReducer from './new-feed-filter/new-feed-filter-region-slice';
import newFeedFilterCountryReducer from './new-feed-filter/new-feed-filter-country-slice';
import newFeedFilterAuthorityReducer from './new-feed-filter/new-feed-filter-authority-slice';
import newFeedFilterSubcategoryReducer from './new-feed-filter/new-feed-filter-subcategory-slice';
import newFeedFilterDocumentTypeReducer from './new-feed-filter/new-feed-filter-document-type-slice';
import assignToTeamModalTeamReducer from './assign-to-team-modal-team/assign-to-team-modal-team-slice';
import assignToTeamSidebarFeedReducer from './assign-to-team-sidebar-feed/assign-to-team-sidebar-feed-slice';
import managementTeamReducer from './management-team/management-team-slice';
import createTeamModalUserReducer from './create-team-modal-user/create-team-modal-user-slice';
import realTimeReviewersReducer from './real-time-reviewers/real-time-reviewers-slice';
import reviewHistoryReducer from './review-history/review-history-slice';
import detailDevelopmentCitationReducer from './detail-development-citation/detail-development-citation-slice';
import performanceReportReducer from './performance-report/performance-report-slice';
import turnaroundTimeReportReducer from './turnaround-time-report/turnaround-time-report-slice';
import turnaroundTimeFilterReducer from './turnaround-time-report/turnaround-time-filter-slice';
import turnaroundTimeFilterAuthorityReducer from './turnaround-time-report/turnaround-time-filter-authority-slice';
import turnaroundTimeFilterCountryReducer from './turnaround-time-report/turnaround-time-filter-country-slice';
import turnaroundTimeFilterDocumentTypeReducer from './turnaround-time-report/turnaround-time-filter-document-type-slice';
import turnaroundTimeFilterSubcategoryReducer from './turnaround-time-report/turnaround-time-filter-subcategory-slice';

export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    development: developmentReducer,
    developmentDetail: developmentDetailReducer,
    recentlyViewedDevelopment: recentlyViewedDevelopmentReducer,
    scrollSync: scrollSyncReducer,
    toast: toastReducer,
    environment: environmentReducer,
    managementUser: managementUserReducer,
    managementFeed: managementFeedReducer,
    feedPicker: feedPickerReducer,
    homeSidebarFilter: homeSidebarFilterReducer,
    homeSidebarFilterLanguage: homeSidebarFilterLanguageReducer,
    homeSidebarFilterRegion: homeSidebarFilterRegionReducer,
    homeSidebarFilterCountry: homeSidebarFilterCountryReducer,
    homeSidebarFilterAuthority: homeSidebarFilterAuthorityReducer,
    homeSidebarFilterSubcategory: homeSidebarFilterSubcategoryReducer,
    homeSidebarFilterDocumentType: homeSidebarFilterDocumentTypeReducer,
    newFeedFilter: newFeedFilterReducer,
    newFeedFilterLanguage: newFeedFilterLanguageReducer,
    newFeedFilterRegion: newFeedFilterRegionReducer,
    newFeedFilterCountry: newFeedFilterCountryReducer,
    newFeedFilterAuthority: newFeedFilterAuthorityReducer,
    newFeedFilterSubcategory: newFeedFilterSubcategoryReducer,
    newFeedFilterDocumentType: newFeedFilterDocumentTypeReducer,
    assignToTeamModalTeam: assignToTeamModalTeamReducer,
    assignToTeamSidebarFeed: assignToTeamSidebarFeedReducer,
    managementTeam: managementTeamReducer,
    createTeamModalUser: createTeamModalUserReducer,
    realTimeReviewers: realTimeReviewersReducer,
    reviewHistory: reviewHistoryReducer,
    detailDevelopmentCitation: detailDevelopmentCitationReducer,
    performanceReport: performanceReportReducer,
    turnaroundTimeReport: turnaroundTimeReportReducer,
    turnaroundTimeFilter: turnaroundTimeFilterReducer,
    turnaroundTimeFilterAuthority: turnaroundTimeFilterAuthorityReducer,
    turnaroundTimeFilterCountry: turnaroundTimeFilterCountryReducer,
    turnaroundTimeFilterDocumentType: turnaroundTimeFilterDocumentTypeReducer,
    turnaroundTimeFilterSubcategory: turnaroundTimeFilterSubcategoryReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActionPaths: ['payload.content'],
      ignoredPaths: ['toast.content'],
    },
  }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
