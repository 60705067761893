import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {
  selectDevelopmentsSearchProperties,
  setDevelopmentsSearchProperties,
} from 'store/development/development-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Theme } from 'styles/themes';
import { Development } from 'models';
import { developmentKeys } from 'config/development-keys';
import { FlexContainer } from 'styles/utils';
import { Checkbox } from 'components/Checkbox';

const HomeAdvancedSearchContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: '0 8px 8px 8px',
  minHeight: '40px',
  backgroundColor: Theme.colors.white,
  border: `1px solid ${Theme.colors.grayBorder}`,
  borderTop: 'none',
  flexWrap: 'wrap',
});

const SearchByLabel = styled.span({
  display: 'flex',
  alignItems: 'center',
  padding: '0 8px 0 8px',
  fontSize: Theme.fontSize - 2,
  lineHeight: '22px',
  color: Theme.colors.grayDarker,
});

export const HomeAdvancedSearch = () => {
  const dispatch = useAppDispatch();
  const searchProperties = useAppSelector(selectDevelopmentsSearchProperties);

  const isRightPanelShow =
    process.env.REACT_APP_IS_CITATION_RIGHT_PANEL_SHOW === 'true';

  const [isById, setIsById] = useState(
    searchProperties.includes(developmentKeys.id)
  );
  const [isByTitle, setIsByTitle] = useState(
    searchProperties.includes(developmentKeys.title)
  );
  const [isByContent, setIsByContent] = useState(
    searchProperties.includes(developmentKeys.pdfContent)
  );
  const [isByDocumentCitation, setIsByDocumentCitation] = useState(
    searchProperties.includes(developmentKeys.documentCitation)
  );
  const [isByAuthority, setIsByAuthority] = useState(
    searchProperties.includes(
      `${developmentKeys.authorityAliasesAuthorities}.name`
    )
  );
  const [isByDocumentType, setIsByDocumentType] = useState(
    searchProperties.includes(`developmentRows.row.documentTypeName`)
  );
  const [isByCitations, setIsByCitations] = useState(
    searchProperties.includes(
      `${developmentKeys.developmentCitations}.citationName`
    )
  );
  const [isByObligations, setIsByObligations] = useState(
    searchProperties.includes(`obligations.name`)
  );

  useEffect(() => {
    const properties: (keyof Development | string)[] = [
      developmentKeys.externalId,
    ];
    if (isByTitle) {
      properties.push(developmentKeys.title);
    }
    if (isById) {
      properties.push(developmentKeys.id);
    }
    if (isByContent) {
      properties.push(developmentKeys.pdfContent);
    }
    if (isByDocumentCitation) {
      properties.push(developmentKeys.documentCitation);
    }
    if (isByAuthority) {
      properties.push(`${developmentKeys.authorityAliasesAuthorities}.name`);
    }
    if (isByDocumentType) {
      properties.push(`developmentRows.row.documentTypeName`);
    }
    if (isByCitations) {
      properties.push(`${developmentKeys.developmentCitations}.citationName`);
    }
    if (isByObligations) {
      properties.push(`obligations.name`);
    }
    dispatch(setDevelopmentsSearchProperties(properties));
  }, [
    isById,
    isByTitle,
    isByContent,
    isByDocumentCitation,
    isByAuthority,
    isByDocumentType,
    isByCitations,
    isByObligations,
  ]);

  return (
    <HomeAdvancedSearchContainer id="HomeAdvancedSearch">
      <SearchByLabel id="HomeAdvancedSearchSearchByLabel">
        Search by:
      </SearchByLabel>
      <FlexContainer
        id="HomeAdvancedSearchOptionsContainer"
        flexDirection="row"
        style={{
          gap: '10px',
        }}
      >
        <Checkbox
          id="HomeAdvancedSearchOptionsInputTitle"
          label="Title"
          isChecked={isByTitle}
          onChange={() => setIsByTitle(!isByTitle)}
          fontSize={Theme.fontSize - 2}
          whiteSpace="nowrap"
          fontColor={Theme.colors.grayDarker}
        />
        <Checkbox
          id="HomeAdvancedSearchOptionsInputId"
          label="Dev. ID"
          isChecked={isById}
          onChange={() => setIsById(!isById)}
          fontSize={Theme.fontSize - 2}
          whiteSpace="nowrap"
          fontColor={Theme.colors.grayDarker}
        />
        <Checkbox
          id="HomeAdvancedSearchOptionsInputDevelopmentText"
          label="Development Text"
          isChecked={isByContent}
          onChange={() => setIsByContent(!isByContent)}
          fontSize={Theme.fontSize - 2}
          whiteSpace="nowrap"
          fontColor={Theme.colors.grayDarker}
        />
        <Checkbox
          id="HomeAdvancedSearchOptionsInputDocumentCitation"
          label="Document Citation"
          isChecked={isByDocumentCitation}
          onChange={() => setIsByDocumentCitation(!isByDocumentCitation)}
          fontSize={Theme.fontSize - 2}
          whiteSpace="nowrap"
          fontColor={Theme.colors.grayDarker}
        />
        <Checkbox
          id="HomeAdvancedSearchOptionsInputAuthority"
          label="Issuing Authority"
          isChecked={isByAuthority}
          onChange={() => setIsByAuthority(!isByAuthority)}
          fontSize={Theme.fontSize - 2}
          whiteSpace="nowrap"
          fontColor={Theme.colors.grayDarker}
        />
        <Checkbox
          id="HomeAdvancedSearchOptionsInputDocumentType"
          label="Document Type"
          isChecked={isByDocumentType}
          onChange={() => setIsByDocumentType(!isByDocumentType)}
          fontSize={Theme.fontSize - 2}
          whiteSpace="nowrap"
          fontColor={Theme.colors.grayDarker}
        />
        {isRightPanelShow && (
          <Checkbox
            id="HomeAdvancedSearchOptionsInputCitations"
            label="Citations"
            isChecked={isByCitations}
            onChange={() => setIsByCitations(!isByCitations)}
            fontSize={Theme.fontSize - 2}
            whiteSpace="nowrap"
            fontColor={Theme.colors.grayDarker}
          />
        )}
        <Checkbox
          id="HomeAdvancedSearchOptionsInputObligations"
          label="Obligations"
          isChecked={isByObligations}
          onChange={() => setIsByObligations(!isByObligations)}
          fontSize={Theme.fontSize - 2}
          whiteSpace="nowrap"
          fontColor={Theme.colors.grayDarker}
        />
      </FlexContainer>
    </HomeAdvancedSearchContainer>
  );
};
