import { Header } from 'components/Header';
import { OverflowYAutoContainer } from 'components/OverflowYAutoContainer';
import { Theme } from 'styles/themes';
import { BoldText, FlexContainer } from 'styles/utils';
import { ImportObligationsCard, MatchObligationsCard } from './components';
import { useAppSelector } from 'store/hooks';
import { selectSelectedEnvironment } from 'store/environment/environment-slice';

export const AdminObligations = () => {
  const selectedEnvironment = useAppSelector(selectSelectedEnvironment);

  return (
    <>
      <FlexContainer
        flexDirection="column"
        style={{
          width: '100%',
          flex: 1,
          position: 'relative',
        }}
      >
        <Header hasBorderBottom style={{ width: '100%' }}>
          <BoldText style={{ fontSize: Theme.fontSize + 2 }}>
            Obligations Operations
          </BoldText>
        </Header>
        <OverflowYAutoContainer
          style={{
            backgroundColor: Theme.colors.primaryBackgroundColor,
            width: '100%',
            padding: '16px',
            height: 'calc(100% - 56px)',
          }}
        >
          <ImportObligationsCard />
          <br />
          <MatchObligationsCard selectedEnvironment={selectedEnvironment} />
        </OverflowYAutoContainer>
      </FlexContainer>
    </>
  );
};
