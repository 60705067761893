import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { Input } from 'components/Input';
import { OutputConsole } from 'components/OutputConsole';
import { SelectSearchbar } from 'components/SelectSearchbar';
import { localStorageKeys } from 'config/local-storage-keys';
import {
  DevelopmentIngestionStatus,
  RealTimeIngestDevelopmentsEvent,
} from 'enums';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { selectAuthenticationResponse } from 'store/authentication/authentication-slice';
import { useAppSelector } from 'store/hooks';
import { Theme } from 'styles/themes';
import { BaseSocketService } from 'utils/socket/BaseSocketService';

interface IngestDevelopmentsCardProps {}

export const IngestDevelopmentsCard = (props: IngestDevelopmentsCardProps) => {
  const authenticationResponse = useAppSelector(selectAuthenticationResponse);

  const [socketService, setSocketService] = useState<BaseSocketService | null>(
    null
  );
  const [developmentId, setDevelopmentId] = useState('');
  const [selectedIngestionStatuses, setSelectedIngestionStatuses] = useState<
    string[]
  >([]);
  const [outputs, setOutputs] = useState<string[]>([]);
  const [isProcessOnGoing, setIsProcessOnGoing] = useState<boolean>(false);

  const ingestionStatuses = useMemo(() => {
    return Object.entries(DevelopmentIngestionStatus).map(([key, value]) => {
      return {
        id: value,
        label: value,
      };
    });
  }, [DevelopmentIngestionStatus]);

  useEffect(() => {
    if (authenticationResponse?.accessToken) {
      setSocketService(
        new BaseSocketService(authenticationResponse.accessToken)
      );
    }
  }, [JSON.stringify(authenticationResponse)]);

  useEffect(() => {
    if (socketService) {
      socketService.listen(
        RealTimeIngestDevelopmentsEvent.IngestDevelopmentsUpdateProgress,
        (output: string) => {
          setOutputs((currentValue) => [...currentValue, output]);
        }
      );
      socketService.listen(
        RealTimeIngestDevelopmentsEvent.IngestDevelopmentsFinished,
        () => {
          setIsProcessOnGoing(false);
          localStorage.setItem(
            localStorageKeys.isPerformingIndefiniteOperation,
            btoa('false')
          );
        }
      );
      return () => {
        socketService.disconnect();
      };
    }
  }, [socketService]);

  const handlePrimaryAction = useCallback(async () => {
    if (authenticationResponse && socketService) {
      setOutputs([]);
      localStorage.setItem(
        localStorageKeys.isPerformingIndefiniteOperation,
        btoa('true')
      );
      setIsProcessOnGoing(true);
      socketService.emit(
        RealTimeIngestDevelopmentsEvent.IngestDevelopments,
        authenticationResponse.id,
        Number(developmentId),
        selectedIngestionStatuses && selectedIngestionStatuses.length
          ? selectedIngestionStatuses[0]
          : undefined
      );
    }
  }, [
    socketService,
    JSON.stringify(authenticationResponse),
    developmentId,
    JSON.stringify(selectedIngestionStatuses),
  ]);

  return (
    <Card>
      <span
        style={{
          fontSize: Theme.fontSize,
          fontWeight: 'bold',
          color: Theme.colors.primaryColor,
          marginBottom: '16px',
        }}
      >
        Ingest Developments
      </span>
      <div>
        <div style={{ width: '100%', marginBottom: '16px' }}>
          <Input
            type="number"
            placeholder="Development ID..."
            value={developmentId}
            setValue={setDevelopmentId}
          />
        </div>
        <span style={{ display: 'block', marginBottom: '16px' }}>OR</span>
        <div style={{ width: '100%', marginBottom: '16px' }}>
          <SelectSearchbar
            placeholder="Select development ingestion status..."
            recordsPropertyToDisplay="label"
            recordsPropertyToSortBy="label"
            values={selectedIngestionStatuses}
            setValues={(values) => setSelectedIngestionStatuses(values)}
            perPage={ingestionStatuses.length}
            rawData={ingestionStatuses}
            isRawDataMode
          />
        </div>
        <Button
          onClick={() => {
            handlePrimaryAction();
          }}
          style={{ marginBottom: '16px' }}
          isDisabled={isProcessOnGoing}
        >
          Run
        </Button>
        <div>
          <span
            style={{
              marginBottom: '16px',
              display: 'block',
            }}
          >
            Output
          </span>
          <OutputConsole outputs={outputs} height="300px" />
        </div>
      </div>
    </Card>
  );
};
