import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActiveFilters, FilterEntry, FilterEntryValue } from 'models';
import { addOrRemoveIfExists } from 'utils/array';
import { RootState } from '../store';

export interface TurnaroundTimeFilterState {
  turnaroundTimeActiveFilters: ActiveFilters;
  filterPickerEntries: FilterEntry[];
  triggerGetAllTurnaroundTimeFilterSetEntries: number;
}

const initialState: TurnaroundTimeFilterState = {
  turnaroundTimeActiveFilters: {},
  filterPickerEntries: [],
  triggerGetAllTurnaroundTimeFilterSetEntries: 0,
};

export const turnaroundTimeFilterSlice = createSlice({
  name: 'turnaround-time-filter',
  initialState,
  reducers: {
    updateFilterPickerEntries: (state, action: PayloadAction<FilterEntry>) => {
      state.filterPickerEntries = addOrRemoveIfExists(
        state.filterPickerEntries,
        action.payload
      );
    },
    clearFilterPickerEntries: (state) => {
      state.filterPickerEntries = [];
    },
    updateTurnaroundTimeActiveFilters: (
      state,
      action: PayloadAction<{
        propertyToFilterBy: string;
        value: FilterEntryValue;
        replace?: boolean;
      }>
    ) => {
      const { propertyToFilterBy, value, replace } = action.payload;
      (state.turnaroundTimeActiveFilters[propertyToFilterBy] as any) = replace
        ? value
        : addOrRemoveIfExists(
            state.turnaroundTimeActiveFilters[propertyToFilterBy] as any[],
            value
          );
    },
    clearTurnaroundTimeActiveFilters: (state) => {
      for (const key in state.turnaroundTimeActiveFilters) {
        if (
          Object.prototype.hasOwnProperty.call(
            state.turnaroundTimeActiveFilters,
            key
          )
        ) {
          if (Array.isArray(state.turnaroundTimeActiveFilters[key])) {
            state.turnaroundTimeActiveFilters[key] = [];
          } else if (
            typeof state.turnaroundTimeActiveFilters[key] === 'string'
          ) {
            state.turnaroundTimeActiveFilters[key] = '';
          }
        }
      }
    },
    setTurnaroundTimeActiveFilters: (
      state,
      action: PayloadAction<ActiveFilters>
    ) => {
      state.turnaroundTimeActiveFilters = action.payload;
    },
    setSomeTurnaroundTimeActiveFilters: (
      state,
      action: PayloadAction<
        {
          propertyToFilterBy: string;
          values: any[];
        }[]
      >
    ) => {
      action.payload.forEach((activeFilter) => {
        state.turnaroundTimeActiveFilters[activeFilter.propertyToFilterBy] =
          activeFilter.values;
      });
    },
    setTriggerGetAllTurnaroundTimeFilterSetEntries: (state) => {
      state.triggerGetAllTurnaroundTimeFilterSetEntries =
        state.triggerGetAllTurnaroundTimeFilterSetEntries + 1;
    },
  },
});

export const {
  updateFilterPickerEntries,
  clearFilterPickerEntries,
  updateTurnaroundTimeActiveFilters,
  clearTurnaroundTimeActiveFilters,
  setTurnaroundTimeActiveFilters,
  setSomeTurnaroundTimeActiveFilters,
  setTriggerGetAllTurnaroundTimeFilterSetEntries,
} = turnaroundTimeFilterSlice.actions;

export const selectFilterPickerEntries = (state: RootState) =>
  state.turnaroundTimeFilter.filterPickerEntries;
export const selectTurnaroundTimeActiveFilters = (state: RootState) =>
  state.turnaroundTimeFilter.turnaroundTimeActiveFilters;
export const selectTriggerGetAllTurnaroundTimeFilterSetEntries = (
  state: RootState
) => state.turnaroundTimeFilter.triggerGetAllTurnaroundTimeFilterSetEntries;

export default turnaroundTimeFilterSlice.reducer;
