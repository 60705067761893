import styled from '@emotion/styled';
import { Dispatch, SetStateAction, useMemo, useRef, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import {
  changeUserPasswordAsync,
  setAuthenticationResponse,
} from 'store/authentication/authentication-slice';
import { displayToast } from 'store/toast/toast-slice';
import {
  CloseIconContainer,
  Modal,
  ModalContentContainer,
  ModalInnerContainer,
  ModalTitle,
} from 'components/Modal';
import { Alert } from 'components/Alert';
import { PasswordInput } from 'components/Input';
import { InputLabel } from 'components/InputLabel';
import { Button, OutlinedButton } from 'components/Button';
import { PasswordTooltip } from 'components/PasswordTooltip';
import { ReactComponent as Close } from 'assets/close.svg';
import { ReactComponent as Warning } from 'assets/warning.svg';
import { ErrorText, FlexContainer } from 'styles/utils';
import { Theme } from 'styles/themes';
import { HttpResponse } from 'utils/http/HttpResponse';

interface PasswordChangeModalProps {
  isDisplayed: boolean;
  setIsDisplayed: Dispatch<SetStateAction<boolean>>;
}

const PasswordChangeModalContainer = styled.div({
  display: 'flex',
  width: '624px',
});

export const PasswordChangeModal = (props: PasswordChangeModalProps) => {
  const dispatch = useAppDispatch();
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmedNewPassword, setConfirmedNewPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const canSubmit = useMemo(() => {
    return (
      !!currentPassword && !!newPassword && !!confirmedNewPassword && !isLoading
    );
  }, [currentPassword, newPassword, confirmedNewPassword, isLoading]);

  const closeModal = () => {
    props.setIsDisplayed(false);
    setCurrentPassword('');
    setNewPassword('');
    setConfirmedNewPassword('');
    setIsLoading(false);
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      const authenticationResponse = await dispatch(
        changeUserPasswordAsync(
          currentPassword,
          newPassword,
          confirmedNewPassword
        )
      );
      if (!!authenticationResponse) {
        dispatch(setAuthenticationResponse(authenticationResponse));
        dispatch(
          displayToast({
            content: (
              <Alert>
                <span>Password changed successfully!</span>
              </Alert>
            ),
            rightDistance: 50,
          })
        );
      }
      closeModal();
    } catch (error) {
      const httpResponse = error as HttpResponse<null>;
      if (!httpResponse.success && httpResponse.error) {
        setErrorMessage(httpResponse.error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      id="PasswordChangeModal"
      isDisplayed={props.isDisplayed}
      isLoading={isLoading}
    >
      <PasswordChangeModalContainer id="PasswordChangeModalContainer">
        <ModalContentContainer id="PasswordChangeModalContentContainer">
          <ModalInnerContainer id="PasswordChangeModalInnerContainer">
            <ModalTitle
              id="PasswordChangeModalTitle"
              withoutMarginBottom={true}
              color={Theme.colors.primaryColor}
            >
              Change Password
            </ModalTitle>
            <CloseIconContainer id="PasswordChangeModalCloseIconContainer">
              <Close
                id="PasswordChangeModalClose"
                onClick={() => closeModal()}
              />
            </CloseIconContainer>
          </ModalInnerContainer>
          <ModalInnerContainer
            id="PasswordChangeModalInnerContainer1"
            flexDirection="column"
          >
            <FlexContainer
              id="CurrentPasswordContainer"
              style={{ width: '100%', marginBottom: '15px' }}
            >
              <InputLabel htmlFor="CurrentPassword" isFieldRequired>
                Current Password
              </InputLabel>
              <PasswordInput
                id="CurrentPassword"
                placeholder="●●●●●●●●"
                value={currentPassword}
                setValue={setCurrentPassword}
              />
            </FlexContainer>
            <FlexContainer
              id="NewPasswordContainer"
              style={{ width: '100%', marginBottom: '15px' }}
            >
              <InputLabel htmlFor="NewPassword" isFieldRequired>
                New Password
              </InputLabel>
              <PasswordInput
                id="NewPassword"
                placeholder="●●●●●●●●"
                value={newPassword}
                setValue={setNewPassword}
                inputRef={passwordInputRef}
              />
              <PasswordTooltip relativeToElement={passwordInputRef} />
            </FlexContainer>
            <FlexContainer
              id="ConfirmedNewPasswordContainer"
              style={{ width: '100%', marginBottom: '15px' }}
            >
              <InputLabel htmlFor="ConfirmedNewPassword" isFieldRequired>
                Confirm New Password
              </InputLabel>
              <PasswordInput
                id="ConfirmedNewPassword"
                placeholder="●●●●●●●●"
                value={confirmedNewPassword}
                setValue={setConfirmedNewPassword}
              />
            </FlexContainer>
            {errorMessage && errorMessage.length > 0 && (
              <FlexContainer
                id="PasswordChangeErrorContainer"
                style={{ marginTop: '10px' }}
              >
                <Warning
                  id="PasswordChangeWarningIcon"
                  style={{
                    margin: 'auto 5px auto 0',
                    color: Theme.colors.red,
                    flex: '0 0 25px',
                  }}
                />
                <ErrorText
                  id="PasswordChangeErrorText"
                  style={{ margin: 'auto 0', width: 'calc(100% - 30px)' }}
                >
                  {errorMessage}
                </ErrorText>
              </FlexContainer>
            )}
          </ModalInnerContainer>
          <ModalInnerContainer
            id="PasswordChangeModalInnerContainer2"
            withoutBorderBottom={true}
            padding="12px 16px"
          >
            <FlexContainer
              id="PasswordChangeModalFlexContainer2"
              style={{ marginLeft: 'auto' }}
            >
              <OutlinedButton
                id="PasswordChangeModalCancelButton"
                style={{ marginRight: '10px' }}
                onClick={() => closeModal()}
              >
                Cancel
              </OutlinedButton>
              <Button
                id="PasswordChangeModalConfirmButton"
                onClick={handleConfirm}
                isDisabled={!canSubmit}
              >
                Confirm
              </Button>
            </FlexContainer>
          </ModalInnerContainer>
        </ModalContentContainer>
      </PasswordChangeModalContainer>
    </Modal>
  );
};
