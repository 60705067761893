import { ReactNode, useCallback } from 'react';
import { UnderlinedButton } from 'components/Button';
import { FlexContainer } from 'styles/utils';
import { ReactComponent as LogoSmall } from 'assets/logo-small.svg';
import { ReactComponent as LogoFull } from 'assets/logo-full.svg';
import { ReactComponent as LoginRightFirstImage } from 'assets/login-right-first-image.svg';
import { ReactComponent as LoginRightSecondImage } from 'assets/login-right-second-image.svg';
import { ReactComponent as LoginRightThirdImage } from 'assets/login-right-third-image.svg';
import styled from '@emotion/styled';
import { Theme } from 'styles/themes';

const LoginLeftSideContainer = styled.div({
  display: 'flex',
  flex: '0 0 43%',
  height: '100%',
  justifyContent: 'center',
  padding: '0 7%',
  flexDirection: 'column',
});

const LoginRightSideContainer = styled.div({
  display: 'flex',
  flex: '1 1 auto',
  backgroundColor: Theme.colors.primaryColor,
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '16px',
  flexDirection: 'column',
});

const LoginTitle = styled.span({
  fontWeight: 'bold',
  fontSize: '27px',
  lineHeight: '33px',
});

export const LoginSubTitle = styled.span({
  fontSize: Theme.fontSize + 2,
  lineHeight: '25px',
});

export const AuthContainer = (props: AuthContainerProps) => {
  const goToPrivacyPolicy = useCallback(() => {
    const privacyPolicyUrl = 'https://innodata.com/privacy-policy';
    const privacyPolicyTarget = '_blank';
    const privacyPolicyWindow = window.open(
      privacyPolicyUrl,
      privacyPolicyTarget
    );
    privacyPolicyWindow?.focus();
  }, []);

  return (
    <FlexContainer
      id="AuthContainerFlexContainer"
      flexWrap="nowrap"
      style={{ height: '100%', width: '100%' }}
    >
      <LoginLeftSideContainer id="LoginLeftSideContainer">
        <FlexContainer
          id="LoginLeftSideContainerFlexContainer"
          flexDirection="column"
          style={{ marginRight: 'auto', marginBottom: '30px' }}
        >
          <LogoSmall
            id="LoginLeftSideContainerLogoSmall"
            style={{ marginBottom: '30px' }}
          />
          <LoginTitle id="LoginLeftSideContainerLoginTitle">
            {props.title}
          </LoginTitle>
          {props.subtitle}
        </FlexContainer>
        {props.children}
        {props.showPolicy && (
          <UnderlinedButton
            id="LoginLeftSideContainerPrivacyPolicyButton"
            color={Theme.colors.secondaryColor}
            onClick={goToPrivacyPolicy}
            style={{ marginTop: '20px' }}
          >
            Privacy policy
          </UnderlinedButton>
        )}
      </LoginLeftSideContainer>
      <LoginRightSideContainer id="LoginRightSideContainer">
        <LogoFull
          id="LoginRightSideContainerLogoFull"
          style={{ marginBottom: '30px', width: '80%' }}
        />
        <FlexContainer id="LoginRightSideContainerFlexContainer">
          <LoginRightFirstImage id="LoginRightSideContainerLoginRightFirstImage" />
          <LoginRightSecondImage id="LoginRightSideContainerLoginRightSecondImage" />
          <LoginRightThirdImage id="LoginRightSideContainerLoginRightThirdImage" />
        </FlexContainer>
      </LoginRightSideContainer>
    </FlexContainer>
  );
};

export interface AuthContainerProps {
  title: string;
  subtitle: string | ReactNode;
  showPolicy?: boolean;
  children?: React.ReactNode;
}
