import { ReactComponent as ArrowDown } from 'assets/arrow-down.svg';
import { ReactComponent as ArrowUp } from 'assets/arrow-up.svg';
import { Theme } from 'styles/themes';
import styled from '@emotion/styled';

interface ResultsNavigationHeaderProps {
  currentResult: number;
  totalResults: number;
  goToNextResult: () => void;
  goToPreviousResult: () => void;
}

const ResultsNavigationHeaderContainer = styled.div(() => {
  return {
    display: 'flex',
    height: '56px',
    position: 'absolute',
    top: 0,
    backgroundColor: Theme.colors.white,
    width: `calc(100% - 36px)`,
    border: `1px solid ${Theme.colors.grayBorder}`,
    boxShadow: '0px 15px 25px rgba(38, 38, 38, 0.1)',
    borderRadius: '4px 4px 0px 0px',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '16px',
    zIndex: 1,
  };
});

export const ResultsNavigationHeader = (
  props: ResultsNavigationHeaderProps
) => {
  return (
    <ResultsNavigationHeaderContainer id="ResultsNavitationHeader">
      <span id="ResultsNavitationHeaderCounter" style={{ marginRight: '10px' }}>
        Result (<b>{props.currentResult}</b> of <b>{props.totalResults}</b>)
      </span>
      <ArrowDown
        id="ResultsNavitationHeaderArrowDown"
        style={{
          cursor: 'pointer',
          color: Theme.colors.grayDark,
          marginRight: '4px',
          userSelect: 'none',
        }}
        onClick={props.goToNextResult}
      />
      <ArrowUp
        id="ResultsNavitationHeaderArrowUp"
        style={{
          cursor: 'pointer',
          color: Theme.colors.grayDark,
          userSelect: 'none',
        }}
        onClick={props.goToPreviousResult}
      />
    </ResultsNavigationHeaderContainer>
  );
};
