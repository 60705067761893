import styled from '@emotion/styled';
import { BaseFieldProps } from 'pages/management/interfaces/ManagementDetailField';
import { Theme } from 'styles/themes';

const FieldContainer = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const TitleContainer = styled.div<{ sameLine?: boolean }>((props) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: props.sameLine ? 'baseline' : 'center',
}));

const Title = styled.div({
  fontSize: '16px',
  fontWeight: 'bold',
  lineHeight: '22px',
});

const Value = styled.div({
  fontSize: '16px',
  fontWeight: 'normal',
  lineHeight: '22px',
});

const BigTitle = styled.div({
  fontWeight: '600',
  fontSize: '27px',
  lineHeight: '33px',
});

const ColorText = styled.div<{ color?: string }>((props) => ({
  fontSize: '16px',
  fontWeight: 'normal',
  lineHeight: '22px',
  color: props.color ?? 'inherit',
}));

const TitleButton = styled.button<{
  withPointer?: boolean;
}>((props) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: 'none',
  backgroundColor: Theme.colors.transparent,
  color: Theme.colors.grayDark,
  cursor: props.withPointer ? 'pointer' : 'default',
}));

export const TitleValue = (props: BaseFieldProps) => {
  const count = props.count === undefined ? '' : `(${props.count.toString()})`;
  const title = `${props.title} ${count}`;
  return (
    <FieldContainer id={props.id}>
      {props.title && (
        <TitleContainer id={`${props.id}TitleContainer`}>
          <Title id={`${props.id}Title`}>{title}</Title>
          {props.buttonIcon && (
            <TitleButton
              id={`${props.id}TitleButton`}
              withPointer
              onClick={props.onClickButton}
            >
              {props.buttonIcon}
            </TitleButton>
          )}
        </TitleContainer>
      )}
      {props.value && <Value id={`${props.id}Value`}>{props.value}</Value>}
    </FieldContainer>
  );
};

export const BigTitleStatus = (props: BigTitleStatusProps) => {
  return (
    <FieldContainer id={props.id}>
      <TitleContainer id={`${props.id}TitleContainer`} sameLine>
        <BigTitle id={`${props.id}Title`}>{props.title}</BigTitle>
        {props.status && (
          <ColorText id={`${props.id}Status`} color={props.statusColor}>
            {props.status}
          </ColorText>
        )}
      </TitleContainer>
      {props.value && (
        <ColorText id={`${props.id}Value`} color={props.valueColor}>
          {props.value}
        </ColorText>
      )}
    </FieldContainer>
  );
};

interface BigTitleStatusProps extends BaseFieldProps {
  status?: string;
  statusColor?: string;
  valueColor?: string;
}
