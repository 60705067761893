import { useMemo } from 'react';
import { Loading } from 'components/Loading';
import { FlexContainer } from 'styles/utils';
import { TurnaroundTimeReportData } from 'models';
import { ReportSummaryBlock } from './ReportSummaryBlock';
import { TurnaroundTimeSummaryChart } from './TurnaroundTimeSummaryChart';
import { TurnaroundTimeSummaryData } from './TurnaroundTimeSummaryData';
import { TurnaroundPageBucketTypes } from 'enums/TurnaroundPageBucketTypes';
import { TurnaroundTimeBucketTypes } from 'enums/TurnaroundTimeBucketTypes';

interface TurnaroundTimeSummaryProps {
  data: TurnaroundTimeReportData;
  isLoading: boolean;
}

export const TurnaroundTimeSummary = (props: TurnaroundTimeSummaryProps) => {
  const chartData = useMemo(
    () => ({
      [TurnaroundPageBucketTypes.From1To15Pages]:
        props.data[`total${TurnaroundPageBucketTypes.From1To15Pages}`],
      [TurnaroundPageBucketTypes.From16To100Pages]:
        props.data[`total${TurnaroundPageBucketTypes.From16To100Pages}`],
      [TurnaroundPageBucketTypes.From101To200Pages]:
        props.data[`total${TurnaroundPageBucketTypes.From101To200Pages}`],
      [TurnaroundPageBucketTypes.From201To1000Pages]:
        props.data[`total${TurnaroundPageBucketTypes.From201To1000Pages}`],
      [TurnaroundPageBucketTypes.From1001To1700Pages]:
        props.data[`total${TurnaroundPageBucketTypes.From1001To1700Pages}`],
      [TurnaroundPageBucketTypes.NonConformities]:
        props.data[`total${TurnaroundPageBucketTypes.NonConformities}`],
    }),
    [props.data]
  );

  return (
    <div style={{ position: 'relative', marginBottom: '16px' }}>
      {props.isLoading && <Loading />}
      <FlexContainer style={{ padding: '0 16px' }}>
        <ReportSummaryBlock hasDarkBars>
          <TurnaroundTimeSummaryChart data={chartData} />
        </ReportSummaryBlock>
        <ReportSummaryBlock>
          <TurnaroundTimeSummaryData
            turnaroudBucketType={TurnaroundTimeBucketTypes.Within2hours}
            value={props.data[`total${TurnaroundTimeBucketTypes.Within2hours}`]}
          />
        </ReportSummaryBlock>
        <ReportSummaryBlock>
          <TurnaroundTimeSummaryData
            turnaroudBucketType={TurnaroundTimeBucketTypes.Within24hours}
            value={
              props.data[`total${TurnaroundTimeBucketTypes.Within24hours}`]
            }
          />
        </ReportSummaryBlock>
        <ReportSummaryBlock>
          <TurnaroundTimeSummaryData
            turnaroudBucketType={TurnaroundTimeBucketTypes.Within36hours}
            value={
              props.data[`total${TurnaroundTimeBucketTypes.Within36hours}`]
            }
          />
        </ReportSummaryBlock>
        <ReportSummaryBlock>
          <TurnaroundTimeSummaryData
            turnaroudBucketType={TurnaroundTimeBucketTypes.Within60hours}
            value={
              props.data[`total${TurnaroundTimeBucketTypes.Within60hours}`]
            }
          />
        </ReportSummaryBlock>
        <ReportSummaryBlock>
          <TurnaroundTimeSummaryData
            turnaroudBucketType={TurnaroundTimeBucketTypes.Within4days}
            value={props.data[`total${TurnaroundTimeBucketTypes.Within4days}`]}
          />
        </ReportSummaryBlock>
        <ReportSummaryBlock hasDarkBars>
          <TurnaroundTimeSummaryData
            turnaroudBucketType={'totalDevelopments'}
            value={props.data.totalDevelopments}
            isBold
          />
        </ReportSummaryBlock>
      </FlexContainer>
    </div>
  );
};
