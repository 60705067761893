import { routes } from 'navigation/routes';
import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { ReactComponent as Feeds } from 'assets/feeds.svg';
import { ReactComponent as Chat } from 'assets/chat.svg';
import { ReactComponent as Management } from 'assets/management.svg';
import { ReactComponent as Users } from 'assets/users.svg';
import { ReactComponent as Teams } from 'assets/teams.svg';
import { ReactComponent as Reports } from 'assets/reports.svg';
import { ReactComponent as Logout } from 'assets/logout.svg';
import { ReactComponent as Document } from 'assets/document.svg';
import { ReactComponent as UserCircle } from 'assets/user-circle.svg';
import { ReactComponent as SendFeedback } from 'assets/send-feedback.svg';
import { ReactComponent as Gear } from 'assets/gear.svg';
import { NavbarSection, NavbarSectionTypes } from '.';
import { useAppSelector } from 'store/hooks';
import { selectAuthenticationResponse } from 'store/authentication/authentication-slice';
import { useEffect, useState } from 'react';
import { FeedbackModal } from 'components/FeedbackModal';
import { ReactComponent as LogoNameSmaller } from 'assets/logo-name-18px.svg';
import { useLogout } from 'hooks/use-logout';
import { UserRoles } from 'enums';
import { selectSelectedEnvironment } from 'store/environment/environment-slice';
import { getCanViewCopilot } from 'helpers/filters';

interface NavbarProps {
  isExpanded?: boolean;
}

const NavbarContainer = styled.div<NavbarProps>((props) => ({
  width: `${props.isExpanded ? 264 : 56}px`,
  maxWidth: `${props.isExpanded ? 264 : 56}px`,
  display: 'flex',
  flexDirection: 'column',
  flex: `1 0 ${props.isExpanded ? 264 : 56}px`,
  background: Theme.colors.primaryColor,
}));

export const Navbar = (props: NavbarProps) => {
  const { isExpanded } = props;
  const authenticationResponse = useAppSelector(selectAuthenticationResponse);
  const isManager = authenticationResponse?.isManager;
  const hasSuperadminRole = authenticationResponse?.roles?.includes(
    UserRoles.Superadmin
  );
  const [isFeedbackModalDisplayed, setIsFeedbackModalDisplayed] =
    useState(false);
  const [isShowCopilot, setIsShowCopilot] = useState(false);
  const selectedEnvironment = useAppSelector(selectSelectedEnvironment);
  const logout = useLogout();

  const handleSendFeedback = () => {
    setIsFeedbackModalDisplayed(true);
  };

  useEffect(() => {
    const elasticsearchIndexPrefix =
      selectedEnvironment?.elasticsearchIndexPrefix || '';
    const isShow = getCanViewCopilot(elasticsearchIndexPrefix) || false;
    setIsShowCopilot(isShow);
  }, [selectedEnvironment]);

  return (
    <>
      <NavbarContainer id="NavbarContainer" isExpanded={isExpanded}>
        <NavbarSection
          name="Innodata"
          type={NavbarSectionTypes.Logo}
          isExpanded={isExpanded}
        />
        <NavbarSection
          type={NavbarSectionTypes.TopSection}
          route={routes.home}
          children={<Feeds />}
          name="Feeds"
          isExpanded={isExpanded}
        />
        {isShowCopilot && (
          <NavbarSection
            type={NavbarSectionTypes.TopSection}
            route={routes.copilot}
            children={<Chat />}
            name="Copilot"
            isExpanded={isExpanded}
          />
        )}
        {isManager && (
          <NavbarSection
            type={NavbarSectionTypes.TopSection}
            route={routes.management}
            children={<Management />}
            name="Management"
            isExpanded={isExpanded}
            subSections={[
              {
                name: 'Users',
                route: routes.userManagement,
                children: <Users />,
              },
              {
                name: 'Teams',
                route: routes.teamManagement,
                children: <Teams />,
              },
              {
                name: 'Feeds',
                route: routes.feedManagement,
                children: <Feeds />,
              },
              {
                name: 'Sources',
                route: routes.indexTimeReport,
                children: <Document />,
                routesMatch: [routes.indexTimeDetails],
              },
            ]}
          />
        )}
        <NavbarSection
          type={NavbarSectionTypes.TopSection}
          route={routes.reports}
          routesMatch={[
            routes.turnaroundTimeDetails,
            routes.performanceDetails,
            routes.indexTimeDetails,
          ]}
          children={<Reports />}
          name="Reports"
          isExpanded={isExpanded}
          subSections={[
            {
              name: 'Turnaround Time',
              route: routes.turnaroundTimeReport,
              children: <Document />,
              routesMatch: [routes.turnaroundTimeDetails],
            },
            {
              name: 'General',
              route: routes.performanceReport,
              children: <Document />,
              routesMatch: [routes.performanceDetails],
            },
          ]}
        />
        <NavbarSection
          type={NavbarSectionTypes.TopSection}
          route={routes.profile}
          children={<UserCircle />}
          name="My profile"
          isExpanded={isExpanded}
        />
        {hasSuperadminRole && (
          <NavbarSection
            type={NavbarSectionTypes.TopSection}
            route={routes.admin}
            children={<Gear color={Theme.colors.white} />}
            name="Admin"
            isExpanded={isExpanded}
            subSections={[
              {
                name: 'Elasticsearch',
                route: routes.adminElasticsearch,
              },
              {
                name: 'Developments',
                route: routes.adminDevelopments,
              },
              {
                name: 'Authorities',
                route: routes.adminAuthorities,
              },
              {
                name: 'Obligations',
                route: routes.adminObligations,
              },
              // {
              //   name: 'Environments',
              //   route: routes.adminEnvironments,
              // },
            ]}
          />
        )}
        <div style={{ marginTop: 'auto' }}>
          <NavbarSection
            type={NavbarSectionTypes.BottomSection}
            children={<SendFeedback />}
            name="Send feedback"
            isExpanded={isExpanded}
            onClick={handleSendFeedback}
          />
          <NavbarSection
            type={NavbarSectionTypes.BottomSection}
            children={<Logout />}
            name="Log out"
            isExpanded={isExpanded}
            onClick={logout}
          />
          {isExpanded && (
            <NavbarSection
              type={NavbarSectionTypes.BottomLogo}
              children={<LogoNameSmaller />}
              name="Powered by"
            />
          )}
        </div>
      </NavbarContainer>
      <FeedbackModal
        isDisplayed={isFeedbackModalDisplayed}
        setIsDisplayed={setIsFeedbackModalDisplayed}
      />
    </>
  );
};
