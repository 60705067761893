import { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { Theme } from 'styles/themes';
import {
  Abstract,
  NewsBriefing,
  NewsBriefingResponse,
  Option,
} from 'lib/utils/Option';
import {
  CountryContainer,
  DevelopmentsContainer,
  DevelopmentsHeaderContainer,
  DisclaimerContainer,
  DropdownContainer,
  FeedsDropdownContainer,
  FlagContainer,
  HeaderContainer,
  KeyChangesContainer,
  MainContainer,
  NewsBriefContainer,
  PageContainer,
  PageContentContainer,
  SectionContainer,
  SectionHeader,
  SectionHeaderContainer,
  Summary,
  SummaryContainer,
} from './NewsBrief.styles';
import { Modal } from 'components/Modal/Modal';
import theme from 'lib/styles/theme';
import { CountryFlagIcon } from 'components/CountryFlagIcon';
import KeyChangesJurisdictions from 'components/KeyChangesJurisdictions/KeyChangesJurisdictions';
import AbstractComponent from 'components/Abstract/Abstract';
import { getNewsBrief } from 'services/news-brief/news-brief-service';
import { FlexContainer } from 'styles/utils';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import NewsBriefAbstractModal from 'components/NewsBriefAbstractModal/NewsBriefAbstractModal';
import {
  getCurrentUserFeedsAsync,
  selectFeedPickerFeeds,
  selectFeedPickerAppliedFeed,
} from 'store/feed/feed-picker-slice';
import { Feed } from 'models';
import { FeedsDropdown } from 'components/FeedsDropdown/FeedsDropdown';
import { CountrySelectorDropdown } from 'components/CountrySelectorDropdown/CountrySelectorDropdown';
import { ReactComponent as Info } from 'assets/info.svg';
import { HttpResponse } from 'utils/http/HttpResponse';
import { ReactComponent as NoResults } from 'assets/no-results.svg';
import styled from '@emotion/styled';

export const NewsBrief = () => {
  const dispatch = useAppDispatch();
  const [newsBrief, setNewsBrief] = useState<
    { [key: string]: NewsBriefing } | undefined
  >();
  const [selectedSummary, setSelectedSummary] = useState<string>();
  const [selectedAbstract, setSelectedAbstract] = useState<Abstract>();
  const [selectedJurisdiction, setSelectedJurisdiction] = useState<string>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFeed, setSelectedFeed] = useState<Feed>();
  const feeds = useAppSelector(selectFeedPickerFeeds);
  const date = new Date();
  const currentSelectedFeed = useAppSelector(selectFeedPickerAppliedFeed);

  const fetchNewsBrief = useCallback(
    async (feedId: number = 0) => {
      let newsBriefResponse;
      try {
        const oneDayBefore = new Date();
        // oneDayBefore.setDate(oneDayBefore.getDate() - 12); // Need to be commented
        const formattedDate = oneDayBefore.toLocaleDateString('en-US');
        newsBriefResponse = await getNewsBrief({
          date: formattedDate,
          feed: feedId,
        });
      } catch (error) {
        const httpResponse = error as HttpResponse<null>;
        if (httpResponse.status === 404 && httpResponse.error) {
          // Handle the error response
        }
      }

      if (newsBriefResponse?.data?.newsBrief) {
        setNewsBrief(newsBriefResponse.data.newsBrief);
        setSelectedSummary(
          Object.entries(newsBriefResponse.data.newsBrief)[0][1].summary
        );
        setSelectedJurisdiction(
          Object.entries(newsBriefResponse.data.newsBrief)[0][0]
        );
      } else if (newsBriefResponse?.data?.message) {
        setNewsBrief(undefined);
        newsBriefResponse = undefined;
      }
    },
    [dispatch, setNewsBrief, setSelectedSummary, setSelectedJurisdiction] // Add necessary dependencies
  );

  // Use memoized result
  const memoizedNewsBrief = useMemo(() => {
    if (newsBrief) {
      return Object.entries(newsBrief);
    }
    return null;
  }, [newsBrief]);

  useEffect(() => {
    if (feeds.length && !selectedFeed) {
      setSelectedFeed(feeds[0]); // Set only once
    }
  }, [feeds]); // Add `selectedFeed` dependency to avoid unnecessary updates

  useEffect(() => {
    if (currentSelectedFeed) {
      setSelectedFeed(currentSelectedFeed);
    }
  }, [currentSelectedFeed]); // Add `selectedFeed` dependency to avoid unnecessary updates

  useEffect(() => {
    const feedId = (selectedFeed && selectedFeed.id) || 0;
    if (feedId && feedId !== 0) {
      fetchNewsBrief(feedId);
    }
  }, [selectedFeed]);

  useEffect(() => {
    if (!feeds || !feeds.length) {
      setIsLoading(true);
    }
    dispatch(getCurrentUserFeedsAsync()).then(() => {
      setIsLoading(false);
    });
  }, [dispatch]); // Add the necessary dependencies

  const handleFeedClick = useCallback((feed: Feed) => {
    setSelectedFeed(feed);
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectAbstract = useCallback((abstract: Abstract) => {
    setSelectedAbstract(abstract);
  }, []);

  const handleSetJurisdiction = useCallback((jurisdiction: string) => {
    setSelectedJurisdiction(jurisdiction);
  }, []);

  const handleSelectedSummary = useCallback((summary: string) => {
    setSelectedSummary(summary);
  }, []);

  const DetailContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    width: 'calc(100% - 56px - 294px)',
  });

  return (
    <FlexContainer
      id="HomeContainer"
      flexWrap="nowrap"
      style={{
        height: '100%',
        flex: 1,
        overflowX: 'auto',
      }}
      // style={{ width: 'calc(100% - 56px)', height: '100%' }}
    >
      <NewsBriefAbstractModal
        isOpen={isOpen}
        toggle={toggle}
        abstract={selectedAbstract}
      />
      <MainContainer>
        <HeaderContainer>
          <div
            style={{
              color: theme.sidebar.colorPallete.primary,
              fontSize: '24px',
              fontWeight: '700',
              marginLeft: '5%',
            }}
          >
            News Brief
          </div>
          <FeedsDropdownContainer>
            <FeedsDropdown
              options={feeds}
              selectedFeed={selectedFeed}
              handleSelectFeed={handleFeedClick}
            ></FeedsDropdown>
          </FeedsDropdownContainer>
        </HeaderContainer>
        <NewsBriefContainer
          style={{
            alignItems: !newsBrief ? 'center' : 'flex-start',
            backgroundColor: !newsBrief
              ? Theme.colors.primaryBackgroundColor
              : 'white',
          }}
        >
          {newsBrief ? (
            <div
              style={{
                marginTop: '15px',
              }}
            >
              <DisclaimerContainer>
                <Info
                  style={{
                    color: theme.sidebar.colorPallete.primary,
                    fill: theme.sidebar.colorPallete.primary,
                  }}
                />
                <div
                  style={{
                    color: theme.sidebar.colorPallete.primary,
                    fontWeight: '600',
                    marginLeft: '15px',
                  }}
                >
                  Important
                </div>
                <div
                  style={{ color: theme.font.color.gray2, marginLeft: '10px' }}
                >
                  LLMs generate responses based on patterns in data and can
                  still make mistakes. It's essential to verify critical
                  information from reliable sources.
                </div>
              </DisclaimerContainer>

              <PageContentContainer>
                <SectionContainer>
                  <SectionHeaderContainer>
                    <div
                      style={{
                        color: theme.sidebar.colorPallete.primary,
                        fontSize: '55px',
                        fontWeight: 'bold',
                      }}
                    >
                      {date.toLocaleString('default', {
                        month: 'long',
                        day: 'numeric',
                      })}
                      , {date.getFullYear()}
                    </div>
                    <CountrySelectorDropdown
                      jurisdictions={newsBrief}
                      selectedJurisdiction={selectedJurisdiction}
                      handleSetJurisdiction={handleSetJurisdiction}
                    ></CountrySelectorDropdown>
                  </SectionHeaderContainer>
                  <KeyChangesContainer>
                    <SectionHeader>Key Changes</SectionHeader>
                    {newsBrief && (
                      <KeyChangesJurisdictions
                        jurisdictions={newsBrief}
                        toggle={toggle}
                        selectedJurisdiction={selectedJurisdiction}
                        handleSetJurisdiction={handleSetJurisdiction}
                        handleSelectedAbstract={handleSelectAbstract}
                        handleSelectedSummary={handleSelectedSummary}
                      />
                    )}
                  </KeyChangesContainer>
                  <SummaryContainer>
                    <SectionHeader>Summary</SectionHeader>
                    {selectedSummary && (
                      <Summary>
                        {selectedSummary.split('\n').map((value, index) => {
                          return (
                            <div style={{ marginBottom: '10px' }} key={index}>
                              {value}
                            </div>
                          );
                        })}
                      </Summary>
                    )}
                  </SummaryContainer>
                </SectionContainer>
                <DevelopmentsContainer>
                  <DevelopmentsHeaderContainer>
                    <div
                      style={{
                        color: theme.sidebar.colorPallete.primary,
                        fontSize: '18px',
                        fontWeight: 'bold',
                      }}
                    >
                      Developments
                    </div>
                  </DevelopmentsHeaderContainer>
                  {newsBrief && (
                    <AbstractComponent
                      jurisdictions={newsBrief}
                      toggle={toggle}
                      selectedJurisdiction={selectedJurisdiction}
                      handleSelectedAbstract={handleSelectAbstract}
                      handleSetJurisdiction={handleSetJurisdiction}
                    />
                  )}
                </DevelopmentsContainer>
              </PageContentContainer>
            </div>
          ) : (
            <div
              id="DevelopmentListNoResultsContainer"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '15px',
              }}
            >
              <NoResults id="DevelopmentListNoResultsIcon" />
              <span id="DevelopmentListNoResultsMessage">
                There is no news brief data available.
              </span>
            </div>
          )}
        </NewsBriefContainer>
      </MainContainer>
    </FlexContainer>
  );
};
