import styled from '@emotion/styled';
import { Checkbox } from 'components/Checkbox';
import { formatNumber } from 'utils/number-formatter';
import { Body } from 'components/Typography';
import { pascalCase } from 'utils/string-formatter';

export interface FilterSetEntryProps {
  filterSetTitle: string;
  name: string;
  count?: number;
  onChange?: () => void;
  onEntryChildClick?: () => void;
  checked?: boolean;
  child?: React.FC<any>;
  childProps?: any;
  width?: string;
}

const InlineDiv = styled.div<{ width?: string }>((props) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'start',
  justifyContent: 'space-between',
  margin: '4px 0',
  flex: `0 1 ${props.width || '100%'}`,
  wordBreak: 'break-word',
}));

const CounterContainer = styled.div({
  padding: '2px 0',
  marginLeft: 'auto',
});

export const FilterSetEntry = (props: FilterSetEntryProps) => {
  const {
    checked,
    onChange,
    onEntryChildClick,
    name,
    count,
    child,
    childProps,
    filterSetTitle,
  } = props;
  const htmlId =
    'FilterEntry' + pascalCase(filterSetTitle.trim()) + pascalCase(name.trim());

  return (
    <>
      <InlineDiv id={htmlId + 'InlineDiv'} width={props.width}>
        <Checkbox
          id={htmlId + 'InlineDivCheckbox'}
          isChecked={checked}
          onChange={onChange}
          label={name}
          marginRight={6}
        />
        {(!!count || count === 0) && (
          <CounterContainer id={htmlId + 'CounterContainer'}>
            <Body id={htmlId + 'Body'} style={{ wordBreak: 'keep-all' }}>
              {formatNumber(count)}
            </Body>
          </CounterContainer>
        )}
      </InlineDiv>
      {child &&
        checked &&
        child({ ...childProps, onClick: onEntryChildClick || onChange })}
    </>
  );
};
