import { ReactNode, useMemo, useRef, useState } from 'react';
import { Theme } from 'styles/themes';
import { FlexContainer, BoldText, CursorPointer } from 'styles/utils';
import { ReactComponent as ArrowDown } from 'assets/arrow-down.svg';
import { ReactComponent as UserIcon } from 'assets/user.svg';
import {
  Dropdown,
  DropdownOptionsContainer,
  DropdownOption,
  DropdownOptionButton,
  DropdownOptionIcon,
  DropdownOptionLabel,
} from 'components/Dropdown';
import { HTMLAttrID } from 'models';
import { pascalCase } from 'utils/string-formatter';

export const ManagementDropdown = (props: ManagementDropdownProps) => {
  const dropdownContainerRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const isDisabled = useMemo(
    () => props.isDisabled || !props.options.length,
    [props.isDisabled, props.options.length]
  );

  return (
    <FlexContainer id={`${props.id}MainContainer`} ref={dropdownContainerRef}>
      <CursorPointer
        style={{
          display: 'flex',
          alignItems: 'center',
          color: isDisabled
            ? Theme.colors.grayDisabled
            : Theme.colors.primaryColor,
        }}
        isDisabled={isDisabled}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <UserIcon id={`${props.id}TitleIcon`} style={{ marginRight: '8px' }} />
        <BoldText id={`${props.id}Title`}>
          <span
            style={{
              marginRight: '10px',
            }}
          >
            {props.title}
          </span>
        </BoldText>
      </CursorPointer>
      <div id={`${props.id}OuterContainer`} style={{ position: 'relative' }}>
        <CursorPointer
          isDisabled={isDisabled}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <ArrowDown
            id={`${props.id}ArrowDown`}
            style={{
              color: isDisabled
                ? Theme.colors.grayBorder
                : Theme.colors.grayDark,
            }}
          />
        </CursorPointer>
        <Dropdown
          id={props.id}
          isOpen={isDropdownOpen && !isDisabled}
          setIsOpen={setIsDropdownOpen}
          containerRef={dropdownContainerRef}
          transform="translate(-94%, 41px)"
          style={{ border: `1px solid ${Theme.colors.grayBorder}` }}
        >
          <DropdownOptionsContainer id={`${props.id}OptionsContainer`}>
            {props.options.map((option, index) => (
              <DropdownOption
                id={`${props.id}${pascalCase(option.label)}Option`}
                key={option.label}
                removeDivider={!index}
                ref={option.innerRef}
              >
                <DropdownOptionButton
                  id={`${props.id}${pascalCase(option.label)}OptionButton`}
                  onClick={option.onClick}
                  disabled={option.isDisabled}
                >
                  <DropdownOptionIcon
                    id={`${props.id}${pascalCase(option.label)}OptionIcon`}
                  >
                    {option.icon}
                  </DropdownOptionIcon>
                  <DropdownOptionLabel
                    id={`${props.id}${pascalCase(option.label)}OptionLabel`}
                  >
                    {option.label}
                  </DropdownOptionLabel>
                </DropdownOptionButton>
                {option.tooltip}
              </DropdownOption>
            ))}
          </DropdownOptionsContainer>
        </Dropdown>
      </div>
    </FlexContainer>
  );
};

export interface ManagementDropdownOption {
  icon: ReactNode;
  label: string;
  onClick?: () => void;
  isDisabled?: boolean;
  tooltip?: ReactNode;
  innerRef?: React.Ref<any>;
}

export interface ManagementDropdownProps extends HTMLAttrID {
  title: string;
  options: ManagementDropdownOption[];
  isDisabled?: boolean;
}
