import { useEffect, useRef } from 'react';
import { Development } from 'models';
import { InfiniteList } from 'components/InfiniteList';
import { Loading } from 'components/Loading';
import { OverflowYAutoContainer } from 'components/OverflowYAutoContainer';
import { DevelopmentCard } from 'components/DevelopmentList/components/DevelopmentCard';
import { useAppSelector } from 'store/hooks';
import { ErrorText } from 'styles/utils';
import { ReactComponent as NoResults } from 'assets/no-results.svg';

interface DevelopmentListProps {
  isLoading: boolean;
  onScroll?: (event: React.UIEvent<HTMLDivElement>) => void;
  developments: Development[];
  errorMessage: string;
  handleReachBottom: () => void;
  page: number;
  totalRecords: number;
  perPage: number;
  handleDevelopmentClick: (id: string) => void;
  handleDevelopmentCtrlClick?: (id: string) => void;
  onAfterSave: (development: Development | null) => void;
  scrollPosition?: number;
  maxHeightModifier?: number;
  pdfContentSnippets?: {
    [x: string]: string[];
  }[];
}

export const DevelopmentList = (props: DevelopmentListProps) => {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.scrollPosition && scrollableContainerRef.current) {
      const scrollableContainer = scrollableContainerRef.current;
      const currentScrollTop = scrollableContainer.scrollTop;
      if (props.scrollPosition !== currentScrollTop) {
        scrollableContainer.scrollTo({
          top: props.scrollPosition,
        });
      }
    }
  }, [
    props.scrollPosition,
    scrollableContainerRef,
    scrollableContainerRef.current,
  ]);

  return (
    <div
      id="DevelopmentListContainer"
      style={{
        position: 'relative',
        flex: '1 1 auto',
      }}
    >
      {props.isLoading && <Loading id="DevelopmentListLoading" />}
      <OverflowYAutoContainer
        id="DevelopmentList"
        ref={scrollableContainerRef}
        style={{ height: '100%' }}
        onScroll={props.onScroll}
        maxHeightModifier={props.maxHeightModifier}
      >
        {props.errorMessage && (
          <>
            <br />
            <ErrorText id="DevelopmentListErrorText">
              {props.errorMessage}
            </ErrorText>
          </>
        )}
        {!!props.developments.length && scrollableContainerRef.current && (
          <InfiniteList
            targetRef={scrollableContainerRef.current}
            onReachBottom={props.handleReachBottom}
            hasMorePages={
              props.page < Math.ceil(props.totalRecords / props.perPage)
            }
          >
            {props.developments.map((development, index) => (
              <DevelopmentCard
                key={development.externalId}
                development={development}
                onClick={(event) => {
                  if (
                    (event.metaKey || event.ctrlKey) &&
                    !!props.handleDevelopmentCtrlClick
                  ) {
                    props.handleDevelopmentCtrlClick(String(development.id));
                  } else {
                    props.handleDevelopmentClick(String(development.id));
                  }
                }}
                onAfterSave={props.onAfterSave}
                pdfContentSnippet={
                  props.pdfContentSnippets && props.pdfContentSnippets.length
                    ? props.pdfContentSnippets[index]
                    : undefined
                }
              />
            ))}
          </InfiniteList>
        )}
        {!props.developments.length && !props.isLoading && (
          <div
            id="DevelopmentListNoResultsContainer"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '15px',
            }}
          >
            <NoResults id="DevelopmentListNoResultsIcon" />
            <span id="DevelopmentListNoResultsMessage">
              There are no results for your search.
            </span>
          </div>
        )}
      </OverflowYAutoContainer>
    </div>
  );
};
