import { RadioButtonGroupOption } from 'components/RadioButtonGroup';
import { SortDevelopmentsDropdown } from 'components/SortDevelopmentsDropdown';
import { developmentKeys } from 'config/development-keys';
import { useAppSelector } from 'store/hooks';
import {
  selectPerformanceReportDevelopmentsSortByDirection,
  selectPerformanceReportDevelopmentsSortByProperty,
  setPerformanceReportDevelopmentsSortByDirection,
  setPerformanceReportDevelopmentsSortByProperty,
} from 'store/performance-report/performance-report-slice';

export const sortByOptions: RadioButtonGroupOption[] = [
  {
    text: 'Processed date',
    value: developmentKeys.processingDate,
  },
  {
    text: 'Published date',
    value: developmentKeys.mainPublicationDate,
  },
  {
    text: 'Effective date',
    value: developmentKeys.mainEffectiveDate,
  },
  {
    text: 'Obligation matches',
    value: developmentKeys.obligationsCount,
  },
  {
    text: 'Citations',
    value: developmentKeys.developmentCitationsCount,
  },
];

export const PerformanceSortDevelopmentsDropdown = () => {
  const sortByProperty = useAppSelector(
    selectPerformanceReportDevelopmentsSortByProperty
  );
  const sortByDirection = useAppSelector(
    selectPerformanceReportDevelopmentsSortByDirection
  );

  return (
    <SortDevelopmentsDropdown
      sortByOptions={sortByOptions}
      sortByProperty={sortByProperty}
      sortByDirection={sortByDirection}
      setSortByProperty={setPerformanceReportDevelopmentsSortByProperty}
      setSortByDirection={setPerformanceReportDevelopmentsSortByDirection}
    />
  );
};
