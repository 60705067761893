import styled from '@emotion/styled';
import { Theme } from 'styles/themes';

const TableElement = styled.div<TableElementProps>((props) => ({
  textAlign: props.textAlign,
}));

export const Table = styled(TableElement)({
  borderCollapse: 'collapse',
  width: '100%',
  boxShadow: `2px 2px 4px ${Theme.colors.grayBorder}`,
  backgroundColor: 'white',
}).withComponent('table');

export const Tr = styled(TableElement)<{
  isHighlighted?: boolean;
  isFaded?: boolean;
}>((props) => ({
  borderBottom: `1px solid ${Theme.colors.grayBorder}`,
  backgroundColor: props.isHighlighted
    ? Theme.colors.blueLightest
    : Theme.colors.white,
  opacity: props.isFaded ? 0.32 : 1,
})).withComponent('tr');

export const Th = styled(TableElement)((props) => ({
  cursor: props.onClick && !props.noHover ? 'pointer' : 'normal',
  padding: props.padding || '12px 14px',
  textAlign: props.textAlign || 'left',
  width: props.width,
})).withComponent('th');

export const Td = styled(TableElement)((props) => ({
  cursor: props.onClick && !props.noHover ? 'pointer' : 'normal',
  padding: props.padding || '12px 14px',
  color: props.color || 'inherit',
  verticalAlign: 'middle',
})).withComponent('td');

export const Thead = styled(TableElement)({
  fontWeight: 600,
}).withComponent('thead');

export const Tbody = styled(TableElement)((props) => ({
  tr: {
    fontSize: 14,
    ...{
      td: {
        padding:
          props.rowPadding === undefined ? '12px 14px' : props.rowPadding,
      },
      th: {
        padding: props.rowPadding,
      },
    },
  },
})).withComponent('tbody');

export const Tfoot = styled(TableElement)({
  tr: {
    borderBottomColor: Theme.colors.transparent,
    td: {
      padding: '4px 1em',
    },
  },
}).withComponent('tfoot');

export type Row<T> = T & {
  checked?: boolean;
};

interface TableElementProps {
  noHover?: boolean;
  rowPadding?: string | number;
  padding?: string;
  textAlign?: any;
  width?: string | number;
}
