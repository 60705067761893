import { format } from 'date-fns';
import {
  ManagementDetail,
  DetailButton,
  TitleValue,
  BigTitleStatus,
} from 'pages/management/components';
import { BlueChip } from 'components/Chip';
import { ReactComponent as AddIcon } from 'assets/plus-circle.svg';
import { ReactComponent as DeleteIcon } from 'assets/delete-user.svg';
import { ReactComponent as EditIcon } from 'assets/edit-smaller.svg';
import { Feed } from 'models';
import { Theme } from 'styles/themes';
import { FlexContainer } from 'styles/utils';
import { formatNumber } from 'utils/number-formatter';
import { FeedAppliedFilters } from './components/FeedAppliedFilters';
import { ManagementSectionProps } from '../components/ManagementDetail';

export const FeedDetail = ({
  feed,
  isLoading,
  onClickAssignToTeams,
  onClickEdit,
  onClickDelete,
}: FeedDetailProps) => {
  const createdDate = feed.createdAt
    ? format(new Date(feed.createdAt), 'MMMM d, yyyy')
    : 'No available created date';

  const averageDevelopmentsPerDay = formatNumber(
    feed.averageNewDevelopmentsPerDayCount
      ? feed.averageNewDevelopmentsPerDayCount > 1
        ? Math.round(feed.averageNewDevelopmentsPerDayCount)
        : 1
      : 0
  );

  const teamList =
    feed.teamFeeds && feed.teamsCount ? (
      <FlexContainer
        id="FeedManagementDetailTeamList"
        style={{ marginTop: '16px', gap: '8px' }}
      >
        {feed.teamFeeds.map((tf) => (
          <BlueChip
            id={`FeedManagementDetailTeamListBlueChip${tf.teamId}`}
            key={tf.teamId}
            style={{ fontSize: Theme.fontSize - 2, maxWidth: '275px' }}
          >
            {tf.teamName}
          </BlueChip>
        ))}
      </FlexContainer>
    ) : (
      <FlexContainer id="FeedManagementDetailTeamList" />
    );

  const sections: ManagementSectionProps[] = [
    {
      children: [
        <BigTitleStatus
          id="FeedManagementDetailNameTitle"
          key="NameTitle"
          title={feed.name}
        />,
      ],
    },
    {
      children: [
        <TitleValue
          id="FeedManagementDetailCreatedBy"
          key="CreatedBy"
          title={`Created${
            feed.userFullName ? ` by ${feed.userFullName}` : ''
          }`}
          value={createdDate}
        />,
      ],
    },
    {
      children: <FeedAppliedFilters key="AppliedFilters" feed={feed} />,
    },
    {
      children: [
        <TitleValue
          id="FeedManagementDetailAverageNewDevelopmentsPerDay"
          key="AverageNewDevelopmentsPerDay"
          title="Average new developments per day"
          value={averageDevelopmentsPerDay}
        />,
      ],
    },
    {
      children: [
        <TitleValue
          id="FeedManagementDetailAssignedTo"
          key="AssignedTo"
          title="Assigned to"
          count={feed.teamsCount ?? 0}
          buttonIcon={<AddIcon />}
          value={teamList}
          onClickButton={onClickAssignToTeams}
        />,
      ],
    },
  ];

  const buttons = [
    <DetailButton
      id="FeedManagementDetailDeleteButton"
      key="Delete"
      label="Delete"
      icon={<DeleteIcon />}
      color={Theme.colors.red}
      onClick={onClickDelete}
      removeDivider
    />,
    <DetailButton
      id="FeedManagementDetailEditButton"
      key="Edit"
      label="Edit"
      icon={<EditIcon />}
      color={Theme.colors.secondaryColor}
      onClick={onClickEdit}
    />,
  ];

  return (
    <ManagementDetail
      id="FeedManagementDetail"
      sections={sections}
      buttons={buttons}
      isLoading={isLoading}
    />
  );
};

interface FeedDetailProps {
  feed: Feed;
  isLoading?: boolean;
  onClickAssignToTeams: () => void;
  onClickEdit: () => void;
  onClickDelete: () => void;
}
