import { useCallback } from 'react';
import styled from '@emotion/styled';
import { Table, Tbody, Th, Thead, Tr } from 'components/Table';
import { Theme } from 'styles/themes';
import { FlexContainer } from 'styles/utils';
import { FilterEntry, TurnaroundTimeDetailedDataByPages } from 'models';
import { TurnaroundTimeDetailsRow } from './TurnaroundTimeDetailsRow';
import { TurnaroundPageBucketTypes } from 'enums/TurnaroundPageBucketTypes';
import {
  NonConformityFilter,
  PageCountThresholdLabel,
  TurnaroundTimeFilter,
} from 'enums';
import { getPageCountFilters } from 'helpers/page-count';
import { getTurnaroundTimeFilters } from 'helpers/turnaround-time';
import { getNonConformityFilters } from 'helpers/non-conformity';

interface TurnaroundTimeDetailsTableProps {
  data: TurnaroundTimeDetailedDataByPages;
  onClickCell: (
    filterEnties: FilterEntry[],
    isFromPreviousPeriod?: boolean,
    isForNewTab?: boolean
  ) => void;
  isPreviousPeriod?: boolean;
}

const StyledTable = styled(Table)({
  border: `1px solid ${Theme.colors.grayBorder}`,
  position: 'relative',
  boxShadow: 'none',
  marginBottom: '24px',
});

const StyledTr = styled(Tr)({
  height: '48px',
});

const StyledTh = styled(Th)({
  border: `1px solid ${Theme.colors.grayBorder}`,
  borderCollapse: 'collapse',
  fontWeight: 'bold',
});

export const TurnaroundTimeDetailsTable = (
  props: TurnaroundTimeDetailsTableProps
) => {
  const { data } = props;

  const handleCellClick = useCallback(
    (
      event: React.MouseEvent<any, MouseEvent>,
      pageCountThresholdLabel: PageCountThresholdLabel,
      turnaroundTimeFilter?: TurnaroundTimeFilter,
      nonConformityFilter?: NonConformityFilter
    ) => {
      const filters: FilterEntry[] = [];
      if (pageCountThresholdLabel) {
        const pageBucketFilterEntry = getPageCountFilters().find(
          (x) => x.name === pageCountThresholdLabel
        );
        if (pageBucketFilterEntry) {
          filters.push(pageBucketFilterEntry);
        }
      }
      if (turnaroundTimeFilter) {
        const turnaroundFilterEntry = getTurnaroundTimeFilters().find(
          (x) => x.name === turnaroundTimeFilter
        );
        if (turnaroundFilterEntry) {
          filters.push(turnaroundFilterEntry);
        }
      }
      if (nonConformityFilter) {
        const nonConformityFilterEntry = getNonConformityFilters().find(
          (x) => x.name === nonConformityFilter
        );
        if (nonConformityFilterEntry) {
          filters.push(nonConformityFilterEntry);
        }
      }
      let isForNewTab = false;
      if (event.metaKey || event.ctrlKey) {
        isForNewTab = true;
      }
      props.onClickCell(filters, props.isPreviousPeriod, isForNewTab);
    },
    [props.onClickCell, props.isPreviousPeriod]
  );

  return (
    <div style={{ padding: '0 16px' }}>
      <StyledTable>
        <Thead>
          <StyledTr>
            <StyledTh padding="12px 16px">Pages</StyledTh>
            <StyledTh padding="12px 16px">
              <FlexContainer style={{ justifyContent: 'flex-end' }}>
                Required TaT
              </FlexContainer>
            </StyledTh>
            <StyledTh padding="12px 16px">
              <FlexContainer style={{ justifyContent: 'flex-end' }}>
                % Within TaT
              </FlexContainer>
            </StyledTh>
            <StyledTh padding="12px 16px">
              <FlexContainer style={{ justifyContent: 'flex-end' }}>
                Outside TaT
              </FlexContainer>
            </StyledTh>
            <StyledTh padding="12px 16px">
              <FlexContainer style={{ justifyContent: 'flex-end' }}>
                Minimum TaT
              </FlexContainer>
            </StyledTh>
            <StyledTh padding="12px 16px">
              <FlexContainer style={{ justifyContent: 'flex-end' }}>
                Maximum TaT
              </FlexContainer>
            </StyledTh>
            <StyledTh padding="12px 16px">
              <FlexContainer style={{ justifyContent: 'flex-end' }}>
                Average TaT
              </FlexContainer>
            </StyledTh>
          </StyledTr>
        </Thead>
        <Tbody>
          <TurnaroundTimeDetailsRow
            data={data[TurnaroundPageBucketTypes.From1To15Pages]}
            pageBucketType={TurnaroundPageBucketTypes.From1To15Pages}
            requiredTatText="2 Hours"
            onClickPageBucketCell={(event) =>
              handleCellClick(event, PageCountThresholdLabel.From1To15Pages)
            }
            onClickOutsideTatCell={(event) =>
              handleCellClick(
                event,
                PageCountThresholdLabel.From1To15Pages,
                TurnaroundTimeFilter.OutsideTurnaroundTime,
                NonConformityFilter.No
              )
            }
          />
          <TurnaroundTimeDetailsRow
            data={data[TurnaroundPageBucketTypes.From16To100Pages]}
            pageBucketType={TurnaroundPageBucketTypes.From16To100Pages}
            requiredTatText="24 Hours"
            onClickPageBucketCell={(event) =>
              handleCellClick(event, PageCountThresholdLabel.From16To100Pages)
            }
            onClickOutsideTatCell={(event) =>
              handleCellClick(
                event,
                PageCountThresholdLabel.From16To100Pages,
                TurnaroundTimeFilter.OutsideTurnaroundTime
              )
            }
          />
          <TurnaroundTimeDetailsRow
            data={data[TurnaroundPageBucketTypes.From101To200Pages]}
            pageBucketType={TurnaroundPageBucketTypes.From101To200Pages}
            requiredTatText="36 Hours"
            onClickPageBucketCell={(event) =>
              handleCellClick(event, PageCountThresholdLabel.From101To200Pages)
            }
            onClickOutsideTatCell={(event) =>
              handleCellClick(
                event,
                PageCountThresholdLabel.From101To200Pages,
                TurnaroundTimeFilter.OutsideTurnaroundTime
              )
            }
          />
          <TurnaroundTimeDetailsRow
            data={data[TurnaroundPageBucketTypes.From201To1000Pages]}
            pageBucketType={TurnaroundPageBucketTypes.From201To1000Pages}
            requiredTatText="60 Hours"
            onClickPageBucketCell={(event) =>
              handleCellClick(event, PageCountThresholdLabel.From201To1000Pages)
            }
            onClickOutsideTatCell={(event) =>
              handleCellClick(
                event,
                PageCountThresholdLabel.From201To1000Pages,
                TurnaroundTimeFilter.OutsideTurnaroundTime
              )
            }
          />
          <TurnaroundTimeDetailsRow
            data={data[TurnaroundPageBucketTypes.From1001To1700Pages]}
            pageBucketType={TurnaroundPageBucketTypes.From1001To1700Pages}
            requiredTatText="4 Days"
            onClickPageBucketCell={(event) =>
              handleCellClick(
                event,
                PageCountThresholdLabel.From1001To1700Pages
              )
            }
            onClickOutsideTatCell={(event) =>
              handleCellClick(
                event,
                PageCountThresholdLabel.From1001To1700Pages,
                TurnaroundTimeFilter.OutsideTurnaroundTime
              )
            }
          />
          <TurnaroundTimeDetailsRow
            data={data[TurnaroundPageBucketTypes.NonConformities]}
            pageBucketType={TurnaroundPageBucketTypes.NonConformities}
            requiredTatText="4 Hours"
            onClickPageBucketCell={(event) =>
              handleCellClick(
                event,
                PageCountThresholdLabel.From1To15Pages,
                undefined,
                NonConformityFilter.Yes
              )
            }
            onClickOutsideTatCell={(event) =>
              handleCellClick(
                event,
                PageCountThresholdLabel.From1To15Pages,
                TurnaroundTimeFilter.OutsideTurnaroundTime,
                NonConformityFilter.Yes
              )
            }
          />
        </Tbody>
      </StyledTable>
    </div>
  );
};
