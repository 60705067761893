export const toQueryParams = (queryObject: any): string => {
  const urlSearchParams = new URLSearchParams();
  Object.entries(queryObject).forEach(([key, value]) => {
    if (value) {
      if (Array.isArray(value)) {
        if (!value.length) return;
        urlSearchParams.append(key, `[${value.toString()}]`);
      } else {
        urlSearchParams.append(key, value as any);
      }
    }
  });
  return urlSearchParams.toString();
};
