import { memo, useCallback } from 'react';
import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { RadioButton } from 'components/RadioButton';
import { AuthenticationResponse, Environment } from 'models';
import {
  setSelectedEnvironment,
  selectSelectedEnvironment,
  selectCurrentUserEnvironments,
} from 'store/environment/environment-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { pascalCase } from 'utils/string-formatter';
import { setAuthenticationResponse } from 'store/authentication/authentication-slice';

interface EnvironmentSelectorProps {
  loginAuthenticationResponse: AuthenticationResponse | null;
}

const EnvironmentSelectorContainer = styled.div({
  width: '100%',
  padding: '36px',
  display: 'flex',
  flexDirection: 'column',
});

const EnvironmentSelectorTitle = styled.div({
  marginBottom: '8px',
  alignItems: 'center',
  fontSize: Theme.fontSize,
  lineHeight: `${Theme.fontSize + 4}px`,
});

const EnvironmentSelectorButton = styled.button<{
  isSelected?: boolean;
}>((props) => ({
  width: '100%',
  padding: '32px 16px',
  marginTop: '16px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  textAlign: 'start',
  fontSize: Theme.fontSize,
  lineHeight: `${Theme.fontSize + 4}px`,
  backgroundColor: props.isSelected
    ? Theme.colors.blueLightest
    : Theme.colors.transparent,
  border: `1px solid ${
    props.isSelected ? Theme.colors.gray : Theme.colors.grayBorder
  }`,
  borderRadius: Theme.borderRadius,
  cursor: 'pointer',
}));

export const EnvironmentSelector = memo((props: EnvironmentSelectorProps) => {
  const dispatch = useAppDispatch();
  const selectedEnvironment = useAppSelector(selectSelectedEnvironment);
  const currentUserEnvironments = useAppSelector(selectCurrentUserEnvironments);

  const handleSelectEnvironment = useCallback(
    (environment: Environment) => {
      dispatch(setSelectedEnvironment(environment));
      dispatch(setAuthenticationResponse(props.loginAuthenticationResponse));
    },
    [props.loginAuthenticationResponse]
  );

  return (
    <EnvironmentSelectorContainer id="EnvironmentSelectorContainer">
      <EnvironmentSelectorTitle id="EnvironmentSelectorTitle">
        Please select your environment
      </EnvironmentSelectorTitle>
      {currentUserEnvironments.map((environment) => (
        <EnvironmentSelectorButton
          id={`EnvironmentSelectorButton${pascalCase(environment.name)}`}
          key={environment.name}
          isSelected={environment.name === selectedEnvironment?.name}
          onClick={() => handleSelectEnvironment(environment)}
        >
          <RadioButton
            key={`EnvironmentSelectorRadioButton${pascalCase(
              environment.name
            )}`}
            id={`EnvironmentSelectorRadioButton${pascalCase(environment.name)}`}
            text={''}
            checked={environment.name === selectedEnvironment?.name}
            name={environment.name}
            radioSize="100%"
            style={{
              width: '8%',
              marginRight: '8px',
            }}
          />
          {environment.name}
        </EnvironmentSelectorButton>
      ))}
    </EnvironmentSelectorContainer>
  );
});
