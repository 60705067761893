import styled from '@emotion/styled';
import { Theme } from 'styles/themes';

export const SubHeaderButton = styled.button<{
  isHighlighted?: boolean;
  disabled?: boolean;
}>((props) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '32px',
  height: '32px',
  marginRight: '12px',
  border: `1px solid ${Theme.colors.grayBorder}`,
  borderRadius: Theme.borderRadius,
  backgroundColor: Theme.colors.transparent,
  color: props.disabled
    ? Theme.colors.grayDisabled
    : props.isHighlighted
    ? Theme.colors.secondaryColor
    : Theme.colors.grayDark,
  opacity: props.disabled ? 0.6 : 1,
  cursor: props.disabled ? 'not-allowed' : 'pointer',
}));
