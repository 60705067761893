import styled from '@emotion/styled';
import { ChangeEvent, HTMLAttributes } from 'react';
import { Theme } from 'styles/themes';

export interface TextareaProps extends HTMLAttributes<HTMLTextAreaElement> {
  placeholder?: string;
  value?: string;
  setValue: React.Dispatch<string>;
  inputRef?: React.RefObject<HTMLTextAreaElement>;
  error?: string;
  maxHeight?: string;
}

const TextareaContainer = styled.div({
  width: '100%',
  position: 'relative',
});

const StyledTextarea = styled.textarea<{
  value?: string;
  hasError?: boolean;
  maxHeight?: string;
}>((props) => ({
  width: '100%',
  height: '100%',
  border: props.hasError
    ? `2px solid ${Theme.colors.red}`
    : `1px solid ${Theme.colors.grayBorder}`,
  borderRadius: Theme.borderRadius,
  padding: '8px',
  outline: 'none',
  fontSize: Theme.fontSize - 2,
  resize: 'vertical',
  maxHeight: props.maxHeight ? props.maxHeight : '200px',
  minHeight: '32px',
}));

export const Textarea: React.FC<TextareaProps> = (props: TextareaProps) => {
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) =>
    props.setValue(e.target.value);

  return (
    <>
      <TextareaContainer>
        <StyledTextarea
          id={props.id}
          name={props.id}
          ref={props.inputRef}
          placeholder={props.placeholder}
          value={props.value}
          onChange={handleChange}
          style={props.style}
          hasError={!!props.error}
          autoComplete={props.id}
          rows={3}
        />
      </TextareaContainer>
      {props.error && (
        <span
          style={{
            color: Theme.colors.red,
            marginTop: '6px',
            fontSize: Theme.fontSize - 2,
          }}
        >
          {props.error}
        </span>
      )}
    </>
  );
};
