import { developmentKeys } from 'config/development-keys';
import { PageCountThreshold, PageCountThresholdLabel } from 'enums';
import { FilterEntry } from 'models';

export const getPageCountFilters = (): FilterEntry[] => {
  return [
    {
      value: {
        gte: 1,
        lte: PageCountThreshold.Fifteen,
      },
      name: PageCountThresholdLabel.From1To15Pages,
      propertyToFilterByOverride: developmentKeys.pageCount,
    },
    {
      value: {
        gte: PageCountThreshold.Fifteen + 1,
        lte: PageCountThreshold.OneHundred,
      },
      name: PageCountThresholdLabel.From16To100Pages,
      propertyToFilterByOverride: developmentKeys.pageCount,
    },
    {
      value: {
        gte: PageCountThreshold.OneHundred + 1,
        lte: PageCountThreshold.TwoHundred,
      },
      name: PageCountThresholdLabel.From101To200Pages,
      propertyToFilterByOverride: developmentKeys.pageCount,
    },
    {
      value: {
        gte: PageCountThreshold.TwoHundred + 1,
        lte: PageCountThreshold.OneThousand,
      },
      name: PageCountThresholdLabel.From201To1000Pages,
      propertyToFilterByOverride: developmentKeys.pageCount,
    },
    {
      value: {
        gte: PageCountThreshold.OneThousand + 1,
        lte: PageCountThreshold.OneThousandSevenHundred,
      },
      name: PageCountThresholdLabel.From1001To1700Pages,
      propertyToFilterByOverride: developmentKeys.pageCount,
    },
    {
      value: {
        gt: PageCountThreshold.OneThousandSevenHundred,
      },
      name: PageCountThresholdLabel.MoreThan1700Pages,
      propertyToFilterByOverride: developmentKeys.pageCount,
    },
  ];
};
