import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { format } from 'date-fns';
import { formatNumber } from 'utils/number-formatter';
import { Feed } from 'models';
import { useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectAuthenticationResponse } from 'store/authentication/authentication-slice';

interface FeedCardRightSideProps {
  feed: Feed;
}

const FeedCardRightSideContainer = styled.div({
  width: '70%',
  height: '100%',
  padding: '16px',
  display: 'flex',
  borderLeft: `1px solid ${Theme.colors.grayBorder}`,
  flexDirection: 'column',
  justifyContent: 'center',
  fontSize: Theme.fontSize,
  lineHeight: '22px',
  color: Theme.colors.black,
});

const AccessTextContainer = styled.div({
  marginBottom: '12px',
});

const BlueText = styled.span({
  fontWeight: 'bold',
  color: Theme.colors.secondaryColor,
});

export const FeedCardRightSide = (props: FeedCardRightSideProps) => {
  const authenticationResponse = useAppSelector(selectAuthenticationResponse);
  const AccessText = useMemo(() => {
    const teamFeed = props.feed?.teamFeeds?.find((x) =>
      authenticationResponse?.teamIds?.includes(x.teamId)
    );
    if (
      props.feed?.userId === authenticationResponse?.id &&
      props.feed?.id !== Number.MAX_SAFE_INTEGER
    ) {
      return (
        <span id="AccessText">
          <b>Created by you</b> —{' '}
          {format(new Date(props.feed.createdAt || ''), 'PPP')}
        </span>
      );
    } else if (!!teamFeed) {
      return (
        <span id="AccessText">
          Assigned to <b>{teamFeed.teamName}</b> —{' '}
          {format(new Date(teamFeed.createdAt || ''), 'PPP')}
        </span>
      );
    } else {
      return (
        <span id="AccessText">
          Assigned to <b>All teams</b>
        </span>
      );
    }
  }, []);
  const filtersApplied = props.feed.filtersCount
    ? `Filters applied (${props.feed.filtersCount})`
    : 'No filters applied';
  const developmentsUploadedTodayCountFormatted = formatNumber(
    props.feed.developmentsUploadedTodayCount || 0
  );

  return (
    <FeedCardRightSideContainer
      id={`FeedCardRightSideContainer${props.feed.id}`}
    >
      <AccessTextContainer
        id={`FeedCardRightSideAccessTextContainer${props.feed.id}`}
      >
        {AccessText}
      </AccessTextContainer>
      <div id={`FeedCardRightSideFiltersAppliedContainer${props.feed.id}`}>
        <strong>{filtersApplied}</strong>
        {!!props.feed.filterTypes && ` (${props.feed.filterTypes})`}
      </div>
      <div id={`FeedCardRightSideInfoContainer${props.feed.id}`}>
        <strong>Developments</strong>
        &nbsp;Uploaded today{' '}
        <BlueText id={`FeedCardRightSideUploadedTodayCount${props.feed.id}`}>
          ({developmentsUploadedTodayCountFormatted})
        </BlueText>
      </div>
    </FeedCardRightSideContainer>
  );
};
