import { AppThunk } from '../store';
import { Feed } from 'models';
import {
  createFeed,
  deleteFeed,
  updateFeed,
} from 'services/feeds/feeds-service';

export const createFeedAsync = (feed: Feed): AppThunk<Promise<Feed | null>> => {
  return async (dispatch, getState) => {
    const response = await createFeed(feed);
    return response.data;
  };
};

export const updateFeedAsync = (feed: Feed): AppThunk<Promise<Feed | null>> => {
  return async (dispatch, getState) => {
    const response = await updateFeed(feed);
    return response.data;
  };
};

export const deleteFeedAsync = (
  feedId: number
): AppThunk<Promise<boolean | null>> => {
  return async (dispatch, getState) => {
    const response = await deleteFeed(feedId);
    return response.data;
  };
};
