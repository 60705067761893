import { ChangeEvent, useCallback, useRef } from 'react';
import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { HTMLAttrID } from 'models';

interface CheckBoxProps extends HTMLAttrID {
  isChecked?: boolean;
  onChange?: (e: ChangeEvent) => void;
  label?: string;
  fontSize?: number;
  fontColor?: string;
  checkmarkType?: CheckmarkTypes;
  marginRight?: number;
  whiteSpace?:
    | 'normal'
    | 'pre'
    | 'nowrap'
    | 'pre-wrap'
    | 'pre-line'
    | 'break-spaces';
  style?: React.CSSProperties;
}

const CheckBoxContainer = styled.div<Partial<CheckBoxProps>>((props) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginRight: props.marginRight,
}));

const InnerContainer = styled.div({
  position: 'relative',
  cursor: 'pointer',
  width: '24px',
  height: '22px',
});

const Checkmark = styled.span<Partial<CheckBoxProps>>((props) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: props.fontSize || 18,
  width: props.fontSize || 18,
  backgroundColor: Theme.colors.white,
  borderRadius: '2px',
  border: `2px solid ${Theme.colors.grayDark}`,
  ':after': {
    content: '""',
    position: 'absolute',
    display: 'none',
    top: '0px',
    ...(props.checkmarkType === CheckmarkTypes.checkmark
      ? {
          left: '4px',
          width: '4px',
          height: '10px',
          border: `solid ${Theme.colors.secondaryColor}`,
          borderWidth: '0 2px 2px 0',
          webkitTransform: 'rotate(40deg)',
          msTransform: 'rotate(40deg)',
          transform: 'rotate(40deg)',
        }
      : {
          left: '2px',
          width: '10px',
          height: '6px',
          border: `solid ${Theme.colors.secondaryColor}`,
          borderWidth: '0 0 2px 0',
        }),
  },
}));

const HiddenInput = styled.input({
  position: 'absolute',
  opacity: 0,
  height: 0,
  width: 0,
  ':checked ~ span': {
    borderColor: Theme.colors.secondaryColor,
  },
  ':checked ~ span:after': {
    display: 'block',
  },
});

const LabelText = styled.span<Partial<CheckBoxProps>>((props) => ({
  margin: 'auto 0',
  fontSize: props.fontSize || Theme.fontSize,
  flex: 1,
  padding: '0px 2px',
  whiteSpace: props.whiteSpace || 'normal',
  color: props.fontColor || 'default',
}));

export const Checkbox = ({ style, ...props }: CheckBoxProps) => {
  const {
    id,
    isChecked,
    label,
    onChange,
    fontSize,
    fontColor,
    checkmarkType = CheckmarkTypes.checkmark,
    marginRight,
    whiteSpace,
  } = props;
  const labelId = id ? `${id}Label` : undefined;
  const checkBox = useRef<HTMLInputElement>(null);
  const handleClick = useCallback(() => {
    checkBox.current && checkBox.current.click();
  }, []);

  return (
    <CheckBoxContainer marginRight={marginRight} style={{ ...style }}>
      <InnerContainer onClick={handleClick}>
        <HiddenInput
          type="checkbox"
          id={id}
          checked={isChecked}
          onChange={onChange}
          ref={checkBox}
        />
        <Checkmark checkmarkType={checkmarkType} />
      </InnerContainer>
      {!!label && (
        <LabelText
          id={labelId}
          fontColor={fontColor}
          fontSize={fontSize}
          whiteSpace={whiteSpace}
        >
          {label}
        </LabelText>
      )}
    </CheckBoxContainer>
  );
};

export enum CheckmarkTypes {
  checkmark,
  minusSign,
}
