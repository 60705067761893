import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { getCountriesWithDevelopmentCountGreaterThanZero } from 'services/countries/countries-service';
import { Country, PaginatedResponse } from 'models';
import { paginationConfig } from 'config/pagination-config';

export interface TurnaroundTimeFilterCountryState {
  countries: Country[];
  totalRecords: number;
  searchTerm: string;
  page: number;
  previousPage: number;
  perPage: number;
}

const initialState: TurnaroundTimeFilterCountryState = {
  countries: [],
  totalRecords: 0,
  searchTerm: '',
  page: 1,
  previousPage: 1,
  perPage: paginationConfig.countriesPerPage,
};

export const getAllTurnaroundTimeFilterCountriesAsync = (): AppThunk<
  Promise<PaginatedResponse<Country> | null>
> => {
  return async (dispatch, getState) => {
    const selectedEnvironment = getState().environment.selectedEnvironment;
    const searchTerm = getState().turnaroundTimeFilterCountry.searchTerm;
    const page = getState().turnaroundTimeFilterCountry.page;
    const previousPage = getState().turnaroundTimeFilterCountry.previousPage;
    const perPage = getState().turnaroundTimeFilterCountry.perPage;
    const activeFilters =
      getState().turnaroundTimeFilter.turnaroundTimeActiveFilters;
    const response = await getCountriesWithDevelopmentCountGreaterThanZero(
      selectedEnvironment,
      searchTerm,
      page,
      perPage,
      activeFilters,
      []
    );
    if (page === 1) {
      dispatch(setTurnaroundTimeFilterCountries(response.data));
    } else {
      if (page > previousPage) {
        dispatch(addTurnaroundTimeFilterCountries(response.data));
      }
    }
    return response.data;
  };
};

export const turnaroundTimeFilterCountrySlice = createSlice({
  name: 'turnaround-time-filter-country',
  initialState,
  reducers: {
    setTurnaroundTimeFilterCountries: (
      state,
      action: PayloadAction<PaginatedResponse<Country> | null>
    ) => {
      state.countries = action.payload?.records ?? [];
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    addTurnaroundTimeFilterCountries: (
      state,
      action: PayloadAction<PaginatedResponse<Country> | null>
    ) => {
      state.countries = state.countries.concat(action.payload?.records ?? []);
      state.totalRecords = action.payload?.totalRecords ?? 0;
      state.previousPage = state.page;
    },
    setTurnaroundTimeFilterCountriesSearchTerm: (
      state,
      action: PayloadAction<string>
    ) => {
      state.page = 1;
      state.searchTerm = action.payload;
    },
    increaseTurnaroundTimeFilterCountriesPage: (state) => {
      state.page = state.page + 1;
    },
    resetTurnaroundTimeFilterCountriesPage: (state) => {
      state.page = 1;
      state.previousPage = 1;
    },
  },
});

export const {
  setTurnaroundTimeFilterCountries,
  addTurnaroundTimeFilterCountries,
  setTurnaroundTimeFilterCountriesSearchTerm,
  increaseTurnaroundTimeFilterCountriesPage,
  resetTurnaroundTimeFilterCountriesPage,
} = turnaroundTimeFilterCountrySlice.actions;

export const selectTurnaroundTimeFilterCountries = (state: RootState) =>
  state.turnaroundTimeFilterCountry.countries;
export const selectTurnaroundTimeFilterCountriesTotalRecords = (
  state: RootState
) => state.turnaroundTimeFilterCountry.totalRecords;
export const selectTurnaroundTimeFilterCountriesSearchTerm = (
  state: RootState
) => state.turnaroundTimeFilterCountry.searchTerm;
export const selectTurnaroundTimeFilterCountrySearchTerm = (state: RootState) =>
  state.turnaroundTimeFilterCountry.searchTerm;
export const selectTurnaroundTimeFilterCountriesPage = (state: RootState) =>
  state.turnaroundTimeFilterCountry.page;
export const selectTurnaroundTimeFilterCountriesPerPage = (state: RootState) =>
  state.turnaroundTimeFilterCountry.perPage;

export default turnaroundTimeFilterCountrySlice.reducer;
