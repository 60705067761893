import { css } from '@emotion/react';

export const styles = css({
  '*': {
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
    fontFamily: 'sans-serif',
  },

  'html, body, #root': {
    height: '100%',
  },

  'a.no-style:link': {
    textDecoration: 'inherit',
    cursor: 'pointer',
  },

  'a.no-style:visited': {
    textDecoration: 'inherit',
    cursor: 'pointer',
  },
  /* Scrollbar size */
  '::-webkit-scrollbar': {
    width: '6px',
    height: '6px',
  },
  /* Scrollbar Handle */
  '::-webkit-scrollbar-thumb': {
    borderRadius: '6px',
    background: '#01416a',
  },
});
