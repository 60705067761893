import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { OutputConsole } from 'components/OutputConsole';
import { localStorageKeys } from 'config/local-storage-keys';
import { RealTimeImportObligationsEvent } from 'enums';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { selectAuthenticationResponse } from 'store/authentication/authentication-slice';
import { useAppSelector } from 'store/hooks';
import { Theme } from 'styles/themes';
import { BaseSocketService } from 'utils/socket/BaseSocketService';

interface ImportObligationsCardProps {}

export const ImportObligationsCard = (props: ImportObligationsCardProps) => {
  const authenticationResponse = useAppSelector(selectAuthenticationResponse);

  const [socketService, setSocketService] = useState<BaseSocketService | null>(
    null
  );
  const [outputs, setOutputs] = useState<string[]>([]);
  const [importObligationsFile, setImportObligationsFile] =
    useState<File | null>(null);
  const [isProcessOnGoing, setIsProcessOnGoing] = useState<boolean>(false);

  const importObligationsFileInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (authenticationResponse?.accessToken) {
      setSocketService(
        new BaseSocketService(authenticationResponse.accessToken)
      );
    }
  }, [JSON.stringify(authenticationResponse)]);

  useEffect(() => {
    if (socketService) {
      socketService.listen(
        RealTimeImportObligationsEvent.ImportObligationsUpdateProgress,
        (output: string) => {
          setOutputs((currentValue) => [...currentValue, output]);
        }
      );
      socketService.listen(
        RealTimeImportObligationsEvent.ImportObligationsFinished,
        () => {
          setIsProcessOnGoing(false);
          localStorage.setItem(
            localStorageKeys.isPerformingIndefiniteOperation,
            btoa('false')
          );
        }
      );
      return () => {
        socketService.disconnect();
      };
    }
  }, [socketService]);

  const handlePrimaryAction = useCallback(async () => {
    if (authenticationResponse && socketService && importObligationsFile) {
      setOutputs([]);
      localStorage.setItem(
        localStorageKeys.isPerformingIndefiniteOperation,
        btoa('true')
      );
      setIsProcessOnGoing(true);
      const arrayBuffer = await importObligationsFile.arrayBuffer();
      socketService.emit(
        RealTimeImportObligationsEvent.ImportObligations,
        authenticationResponse.id,
        arrayBuffer
      );
      setImportObligationsFile(null);
      if (importObligationsFileInput.current) {
        importObligationsFileInput.current.value = '';
      }
    }
  }, [
    socketService,
    JSON.stringify(authenticationResponse),
    importObligationsFile,
    importObligationsFileInput,
  ]);

  return (
    <Card>
      <span
        style={{
          fontSize: Theme.fontSize,
          fontWeight: 'bold',
          color: Theme.colors.primaryColor,
          marginBottom: '16px',
        }}
      >
        Import Obligations
      </span>
      <div>
        <div style={{ width: '100%', marginBottom: '16px' }}>
          <input
            type="file"
            ref={importObligationsFileInput}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const file = e.target.files ? e.target.files[0] : null;
              setImportObligationsFile(file);
            }}
          />
        </div>
        <Button
          onClick={() => {
            handlePrimaryAction();
          }}
          style={{ marginBottom: '16px' }}
          isDisabled={isProcessOnGoing}
        >
          Run
        </Button>
        <div>
          <span
            style={{
              marginBottom: '16px',
              display: 'block',
            }}
          >
            Output
          </span>
          <OutputConsole outputs={outputs} height="300px" />
        </div>
      </div>
    </Card>
  );
};
