import styled from '@emotion/styled';
import {
  CloseIconContainer,
  Modal,
  ModalContentContainer,
  ModalInnerContainer,
  ModalTitle,
} from 'components/Modal';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Theme } from 'styles/themes';
import { ReactComponent as Close } from 'assets/close.svg';
import { InputLabel } from 'components/InputLabel';
import { Input } from 'components/Input';
import { FlexContainer } from 'styles/utils';
import { Searchbar } from 'components/Searchbar';
import { InfiniteList } from 'components/InfiniteList';
import { Loading } from 'components/Loading';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addOrRemoveIfExists } from 'utils/array';
import {
  increaseCreateTeamModalUsersPage,
  resetCreateTeamModalUsers,
  resetCreateTeamModalUsersPage,
  resetCreateTeamModalUsersSearchTerm,
  selectCreateTeamModalUsersPage,
  selectCreateTeamModalUsersPerPage,
  selectCreateTeamModalUsersSearchTerm,
  selectCreateTeamModalUsersTotalRecords,
  setCreateTeamModalUsersSearchTerm,
  setTriggerCreateTeamModalUsersUpdate,
} from 'store/create-team-modal-user/create-team-modal-user-slice';
import { UserCard } from './UserCard';
import { Button, OutlinedButton } from 'components/Button';
import { Team, User } from 'models';
import { selectAuthenticationResponse } from 'store/authentication/authentication-slice';
import { createTeam } from 'services/teams/teams-service';
import { setTriggerManagementTeamsUpdate } from 'store/management-team/management-team-slice';
import { addUsersToTeamsAsync } from 'store/management-user/management-user-slice';
import { selectSelectedEnvironment } from 'store/environment/environment-slice';

interface CreateTeamModalProps {
  isDisplayed: boolean;
  setIsDisplayed: Dispatch<SetStateAction<boolean>>;
  isAddUsersToTeamsModal?: boolean;
  users: User[];
  onAddUsers?: (users: User[]) => void;
}

const CreateTeamModalContainer = styled.div({
  display: 'flex',
  width: '544px',
});

export const CreateTeamModal = (props: CreateTeamModalProps) => {
  const dispatch = useAppDispatch();
  const authenticationResponse = useAppSelector(selectAuthenticationResponse);
  const selectedEnvironment = useAppSelector(selectSelectedEnvironment);
  const totalRecords = useAppSelector(selectCreateTeamModalUsersTotalRecords);
  const searchTerm = useAppSelector(selectCreateTeamModalUsersSearchTerm);
  const page = useAppSelector(selectCreateTeamModalUsersPage);
  const perPage = useAppSelector(selectCreateTeamModalUsersPerPage);
  const [name, setName] = useState('');
  const [scrollableContainer, setScrollableContainer] =
    useState<HTMLDivElement | null>(null);
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const closeModal = () => {
    props.setIsDisplayed(false);
    setName('');
    setSelectedUserIds([]);
    dispatch(resetCreateTeamModalUsers());
    dispatch(resetCreateTeamModalUsersPage());
    dispatch(resetCreateTeamModalUsersSearchTerm());
    dispatch(setTriggerCreateTeamModalUsersUpdate());
    setIsLoading(false);
  };

  const isAnyUserSelected = useMemo(() => {
    return !!(selectedUserIds && selectedUserIds.length);
  }, [selectedUserIds]);

  const isMainActionButtonDisabled = useMemo(() => {
    return !isAnyUserSelected || (!name && !props.isAddUsersToTeamsModal);
  }, [isAnyUserSelected, name]);

  const handleMainAction = async () => {
    setIsLoading(true);
    if (!props.isAddUsersToTeamsModal) {
      if (!authenticationResponse) {
        return null;
      }
      if (!selectedEnvironment || !selectedEnvironment.id) {
        return null;
      }
      const team: Team = {
        name,
        userIds: selectedUserIds,
        organizationId: authenticationResponse.organizationId,
        environmentId: selectedEnvironment.id,
      };
      await createTeam(team);
      dispatch(setTriggerManagementTeamsUpdate());
    } else {
      if (props.onAddUsers) {
        props.onAddUsers(
          props.users.filter((u) => u.id && selectedUserIds.includes(u.id))
        );
      } else {
        await dispatch(
          addUsersToTeamsAsync(true, false, undefined, selectedUserIds)
        );
        dispatch(setTriggerManagementTeamsUpdate());
      }
    }
    closeModal();
  };

  return (
    <Modal
      id="CreateTeamModal"
      isDisplayed={props.isDisplayed}
      isLoading={isLoading}
    >
      <CreateTeamModalContainer id="CreateTeamModalContainer">
        <ModalContentContainer id="CreateTeamModalContentContainer">
          <ModalInnerContainer id="CreateTeamModalHeader">
            <ModalTitle
              id="CreateTeamModalTitle"
              withoutMarginBottom={true}
              color={Theme.colors.primaryColor}
            >
              {props.isAddUsersToTeamsModal ? 'Add user' : 'Create new team'}
            </ModalTitle>
            <CloseIconContainer id="CreateTeamModalCloseIconContainer">
              <Close
                id="CreateTeamModalCloseButton"
                onClick={() => closeModal()}
              />
            </CloseIconContainer>
          </ModalInnerContainer>
          {!props.isAddUsersToTeamsModal && (
            <ModalInnerContainer id="CreateTeamModalBodyContainer">
              <FlexContainer
                id="CreateTeamModalName"
                flexDirection="column"
                style={{ width: '100%' }}
              >
                <InputLabel htmlFor="id-name" isFieldRequired>
                  Team name
                </InputLabel>
                <Input
                  id="id-name"
                  placeholder="Enter team name..."
                  value={name}
                  setValue={setName}
                />
              </FlexContainer>
            </ModalInnerContainer>
          )}
          <ModalInnerContainer id="CreateTeamModalBodyContainer">
            <FlexContainer
              id="CreateTeamModalBodyContainer"
              flexDirection="column"
              style={{ width: '100%' }}
            >
              <Searchbar
                id="CreateTeamModalSearchbar"
                value={searchTerm}
                setValue={(value) => {
                  dispatch(setCreateTeamModalUsersSearchTerm(value));
                }}
                placeholder="Search users..."
              />
              <div
                id="CreateTeamModalUserListOuterContainer"
                ref={(element) => setScrollableContainer(element)}
                style={{
                  height: '282px',
                  width: '100%',
                  overflowY: 'auto',
                  marginTop: '16px',
                  border: `1px solid ${Theme.colors.grayBorder}`,
                  borderRadius: Theme.borderRadius,
                }}
              >
                {scrollableContainer && (
                  <InfiniteList
                    targetRef={scrollableContainer}
                    onReachBottom={() =>
                      dispatch(increaseCreateTeamModalUsersPage())
                    }
                    hasMorePages={page < Math.ceil(totalRecords / perPage)}
                    loader={<Loading id="CreateTeamModalUserListLoading" />}
                  >
                    {props.users.map((user, i) => {
                      if (user.id) {
                        return (
                          <UserCard
                            key={user.id}
                            id={user.id}
                            firstName={user.firstName}
                            lastName={user.lastName}
                            fullName={user.fullName || ''}
                            isManager={user.isManager || false}
                            onSelect={(userId) => {
                              const selectedUserIdsClone = [...selectedUserIds];
                              setSelectedUserIds(
                                addOrRemoveIfExists(
                                  selectedUserIdsClone,
                                  userId
                                )
                              );
                            }}
                            selectedIds={selectedUserIds}
                            isLast={i === props.users.length - 1}
                            isFirstOrSecond={i === 0 || i === 1}
                          />
                        );
                      } else {
                        return <></>;
                      }
                    })}
                  </InfiniteList>
                )}
              </div>
            </FlexContainer>
          </ModalInnerContainer>
          <ModalInnerContainer
            id="CreateTeamModalFooter"
            withoutBorderBottom={true}
            padding="12px 16px"
          >
            <FlexContainer
              id="CreateTeamModalFooterContainer"
              style={{ alignItems: 'center', fontSize: Theme.fontSize - 2 }}
            >
              {!isAnyUserSelected && (
                <span id="CreateTeamModalSelectAtLeastOneUserMessage">
                  Select at least one user to{' '}
                  {props.isAddUsersToTeamsModal ? 'add' : 'create the team'}
                </span>
              )}
              {isAnyUserSelected && (
                <span id="CreateTeamModalSelectUserMessage">
                  Users selected{' '}
                  <span
                    id="CreateTeamModalUsersSelectedCount"
                    style={{
                      color: Theme.colors.secondaryColor,
                      fontWeight: 'bold',
                    }}
                  >
                    ({selectedUserIds.length})
                  </span>
                </span>
              )}
            </FlexContainer>
            <FlexContainer
              id="CreateTeamModalButtonsContainer"
              style={{ marginLeft: 'auto' }}
            >
              <OutlinedButton
                id="CreateTeamModalCancelButton"
                color={Theme.colors.secondaryColor}
                style={{ marginRight: '10px' }}
                onClick={() => closeModal()}
              >
                Cancel
              </OutlinedButton>
              <Button
                id="CreateTeamModalSubmitButton"
                onClick={handleMainAction}
                isDisabled={isMainActionButtonDisabled}
              >
                {props.isAddUsersToTeamsModal ? 'Add user' : 'Confirm & create'}
              </Button>
            </FlexContainer>
          </ModalInnerContainer>
        </ModalContentContainer>
      </CreateTeamModalContainer>
    </Modal>
  );
};
