import styled from '@emotion/styled';
import { forwardRef, HTMLAttributes, useImperativeHandle } from 'react';
import { useEffect, useRef, useState } from 'react';
import { Theme } from 'styles/themes';

export interface OverflowYAutoContainerProps
  extends HTMLAttributes<HTMLDivElement> {
  isScrollbarInvisible?: boolean;
  maxHeightModifier?: number;
  doesntWaitForMaxHeightToRenderChildren?: boolean;
}

const StyledOverflowYAutoContainer = styled.div<{
  maxHeight: number | null;
  isScrollbarInvisible?: boolean;
}>((props) => ({
  maxHeight: props.maxHeight ? props.maxHeight : 'none',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: props.isScrollbarInvisible ? 0 : '8px',
    height: props.isScrollbarInvisible ? 0 : '10px',
  },
  '&::-moz-scrollbar': {
    width: props.isScrollbarInvisible ? 0 : '8px',
    height: props.isScrollbarInvisible ? 0 : '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: Theme.colors.grayShadow,
    borderRadius: Theme.borderRadius,
  },
  '&::-webkit-scrollbar-thumb:active': {
    backgroundColor: Theme.colors.gray,
  },
  msOverflowStyle: props.isScrollbarInvisible ? 'none' : 'auto',
}));

export const OverflowYAutoContainer = forwardRef<
  HTMLDivElement | null,
  OverflowYAutoContainerProps
>((props, ref) => {
  const [maxHeight, setMaxHeight] = useState<number | null>(null);
  const [originalMaxHeight, setOriginalMaxHeight] = useState<number | null>(
    null
  );
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(ref, () => {
    return scrollableContainerRef.current;
  });

  useEffect(() => {
    setOriginalMaxHeight(scrollableContainerRef.current?.clientHeight || null);
  }, [scrollableContainerRef.current]);

  useEffect(() => {
    if (originalMaxHeight) {
      setMaxHeight(originalMaxHeight + (props.maxHeightModifier || 0));
    }
  }, [originalMaxHeight, props.maxHeightModifier]);

  return (
    <StyledOverflowYAutoContainer
      id={props.id ? `${props.id}OverflowYAutoContainer` : undefined}
      ref={scrollableContainerRef}
      style={props.style}
      onScroll={props.onScroll}
      maxHeight={maxHeight}
      isScrollbarInvisible={props.isScrollbarInvisible}
    >
      {(maxHeight || props.doesntWaitForMaxHeightToRenderChildren) &&
        props.children}
    </StyledOverflowYAutoContainer>
  );
});
