import styled from '@emotion/styled';
import { Button, OutlinedButton, UnderlinedButton } from 'components/Button';
import { Input } from 'components/Input';
import {
  CloseIconContainer,
  Modal,
  ModalContentContainer,
  ModalInnerContainer,
  ModalTitle,
} from 'components/Modal';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Theme } from 'styles/themes';
import { FlexContainer } from 'styles/utils';
import { ReactComponent as Close } from 'assets/close.svg';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { FilterSet } from 'components/FilterSet';
import {
  ActiveFilters,
  AllDynamicFilterEntries,
  Feed,
  FilterEntry,
  FilterEntryValue,
} from 'models';
import { selectAuthenticationResponse } from 'store/authentication/authentication-slice';
import { selectSelectedEnvironment } from 'store/environment/environment-slice';
import { developmentKeys } from 'config/development-keys';
import {
  getAllNewFeedFilterLanguagesAsync,
  increaseNewFeedFilterLanguagesPage,
  resetNewFeedFilterLanguagesPage,
  resetNewFeedFilterLanguagesSearchTerm,
  selectNewFeedFilterLanguages,
  selectNewFeedFilterLanguagesPage,
  selectNewFeedFilterLanguagesPerPage,
  selectNewFeedFilterLanguagesSearchTerm,
  selectNewFeedFilterLanguagesTotalRecords,
  setNewFeedFilterLanguagesSearchTerm,
} from 'store/new-feed-filter/new-feed-filter-language-slice';
import {
  getAllNewFeedFilterRegionsAsync,
  selectNewFeedFilterRegions,
  selectNewFeedFilterRegionsPage,
  selectNewFeedFilterRegionsPerPage,
  selectNewFeedFilterRegionsSearchTerm,
  selectNewFeedFilterRegionsTotalRecords,
  setNewFeedFilterRegionsSearchTerm,
  increaseNewFeedFilterRegionsPage,
  resetNewFeedFilterRegionsPage,
  resetNewFeedFilterRegionsSearchTerm,
} from 'store/new-feed-filter/new-feed-filter-region-slice';
import {
  getAllNewFeedFilterCountriesAsync,
  selectNewFeedFilterCountries,
  selectNewFeedFilterCountriesPage,
  selectNewFeedFilterCountriesPerPage,
  selectNewFeedFilterCountriesSearchTerm,
  selectNewFeedFilterCountriesTotalRecords,
  setNewFeedFilterCountriesSearchTerm,
  increaseNewFeedFilterCountriesPage,
  resetNewFeedFilterCountriesPage,
  resetNewFeedFilterCountriesSearchTerm,
} from 'store/new-feed-filter/new-feed-filter-country-slice';
import {
  getAllNewFeedFilterAuthoritiesAsync,
  increaseNewFeedFilterAuthoritiesPage,
  resetNewFeedFilterAuthoritiesPage,
  resetNewFeedFilterAuthoritiesSearchTerm,
  selectNewFeedFilterAuthorities,
  selectNewFeedFilterAuthoritiesPage,
  selectNewFeedFilterAuthoritiesPerPage,
  selectNewFeedFilterAuthoritiesSearchTerm,
  selectNewFeedFilterAuthoritiesTotalRecords,
  setNewFeedFilterAuthoritiesSearchTerm,
} from 'store/new-feed-filter/new-feed-filter-authority-slice';
import {
  getAllNewFeedFilterSubcategoriesAsync,
  increaseNewFeedFilterSubcategoriesPage,
  resetNewFeedFilterSubcategoriesPage,
  resetNewFeedFilterSubcategoriesSearchTerm,
  selectNewFeedFilterSubcategories,
  selectNewFeedFilterSubcategoriesPage,
  selectNewFeedFilterSubcategoriesPerPage,
  selectNewFeedFilterSubcategoriesSearchTerm,
  selectNewFeedFilterSubcategoriesTotalRecords,
  setNewFeedFilterSubcategoriesSearchTerm,
} from 'store/new-feed-filter/new-feed-filter-subcategory-slice';
import {
  getAllNewFeedFilterDocumentTypesAsync,
  increaseNewFeedFilterDocumentTypesPage,
  resetNewFeedFilterDocumentTypesPage,
  resetNewFeedFilterDocumentTypesSearchTerm,
  selectNewFeedFilterDocumentTypes,
  selectNewFeedFilterDocumentTypesPage,
  selectNewFeedFilterDocumentTypesPerPage,
  selectNewFeedFilterDocumentTypesSearchTerm,
  selectNewFeedFilterDocumentTypesTotalRecords,
  setNewFeedFilterDocumentTypesSearchTerm,
} from 'store/new-feed-filter/new-feed-filter-document-type-slice';
import { ReactComponent as Warning } from 'assets/warning.svg';
import {
  createFeedAsync,
  updateFeedAsync,
  deleteFeedAsync,
} from 'store/feed/feed-thunks';
import {
  selectFeedAsync,
  selectFeedPickerFeeds,
  selectFeedPickerFeedToEdit,
  setFeedPickerFeedToEdit,
} from 'store/feed/feed-picker-slice';
import {
  selectManagementFeedToEdit,
  setManagementFeedToEdit,
} from 'store/feed/management-feed-slice';
import {
  selectIsNewFeedFilterAuthoritiesCollapsed,
  selectIsNewFeedFilterCountriesCollapsed,
  selectIsNewFeedFilterDocumentTypesCollapsed,
  selectIsNewFeedFilterLanguagesCollapsed,
  selectIsNewFeedFilterRegionsCollapsed,
  selectIsNewFeedFilterSubcategoriesCollapsed,
  selectNewFeedActiveFilters,
  selectNewFeedFilterTriggerGetAllFilterSetEntries,
  setIsNewFeedFilterAuthoritiesCollapsed,
  setIsNewFeedFilterCountriesCollapsed,
  setIsNewFeedFilterDocumentTypesCollapsed,
  setIsNewFeedFilterLanguagesCollapsed,
  setIsNewFeedFilterRegionsCollapsed,
  setIsNewFeedFilterSubcategoriesCollapsed,
  setNewFeedActiveFilters,
  setNewFeedFilterTriggerGetAllFilterSetEntries,
  updateNewFeedActiveFilters,
} from 'store/new-feed-filter/new-feed-filter-slice';
import { filterKeys } from 'config/filter-keys';

interface CreateFeedModalProps {
  isDisplayed: boolean;
  setIsDisplayed: Dispatch<SetStateAction<boolean>>;
  type: CreateFeedModalTypes;
  onUpdate?: (feed: Feed | null) => Feed | null;
  onDelete?: () => void;
  onAfterSubmit?: (feed: Feed | null) => void;
  setSelectsFeedAfterUpsert?: Dispatch<SetStateAction<boolean>>;
  selectsFeedAfterUpsert?: boolean;
}

export enum CreateFeedModalTypes {
  FeedPicker,
  FeedManagement,
}

const CreateFeedModalContainer = styled.div({
  display: 'flex',
  width: '656px',
  maxHeight: '511px',
  position: 'relative',
});

const CreateFeedModalButtonsContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  marginLeft: 'auto',
  alignItems: 'center',
});

const DeleteFeedPromptOverlay = styled.div({
  backgroundColor: Theme.colors.white,
  opacity: 0.5,
  position: 'absolute',
  top: 0,
  bottom: '165px',
  left: 0,
  right: 0,
  zIndex: 1,
});

const DeleteFeedPromptContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '165px',
  position: 'absolute',
  bottom: 0,
  width: '100%',
  padding: '16px',
  background: Theme.colors.primaryBackgroundColor,
  zIndex: 1,
});

const AreYouSureText = styled.span({
  fontSize: Theme.fontSize + 2,
  lineHeight: '25px',
  fontWeight: 'bold',
});

export const CreateFeedModal: React.FC<CreateFeedModalProps> = (
  props: CreateFeedModalProps
) => {
  const dispatch = useAppDispatch();
  const authenticationResponse = useAppSelector(selectAuthenticationResponse);
  const selectedEnvironment = useAppSelector(selectSelectedEnvironment);
  const feeds = useAppSelector(selectFeedPickerFeeds);
  let feedToEdit: Feed | null = null;
  if (props.type === CreateFeedModalTypes.FeedPicker) {
    feedToEdit = useAppSelector(selectFeedPickerFeedToEdit);
  } else {
    feedToEdit = useAppSelector(selectManagementFeedToEdit);
  }
  const newFeedActiveFilters = useAppSelector(selectNewFeedActiveFilters);
  const triggerGetAllFilterSetEntries = useAppSelector(
    selectNewFeedFilterTriggerGetAllFilterSetEntries
  );
  const isAuthoritiesFilterCollapsed = useAppSelector(
    selectIsNewFeedFilterAuthoritiesCollapsed
  );
  const isSubcategoriesFilterCollapsed = useAppSelector(
    selectIsNewFeedFilterSubcategoriesCollapsed
  );
  const isLanguagesFilterCollapsed = useAppSelector(
    selectIsNewFeedFilterLanguagesCollapsed
  );
  const isRegionsFilterCollapsed = useAppSelector(
    selectIsNewFeedFilterRegionsCollapsed
  );
  const isCountriesFilterCollapsed = useAppSelector(
    selectIsNewFeedFilterCountriesCollapsed
  );
  const isDocumentTypesFilterCollapsed = useAppSelector(
    selectIsNewFeedFilterDocumentTypesCollapsed
  );

  // Filter Languages
  const filterLanguages = useAppSelector(selectNewFeedFilterLanguages);
  const filterLanguagesTotalRecords = useAppSelector(
    selectNewFeedFilterLanguagesTotalRecords
  );
  const filterLanguagesSearchTerm = useAppSelector(
    selectNewFeedFilterLanguagesSearchTerm
  );
  const filterLanguagesPage = useAppSelector(selectNewFeedFilterLanguagesPage);
  const filterLanguagesPerPage = useAppSelector(
    selectNewFeedFilterLanguagesPerPage
  );
  //

  // Filter Countries
  const filterCountries = useAppSelector(selectNewFeedFilterCountries);
  const filterCountriesTotalRecords = useAppSelector(
    selectNewFeedFilterCountriesTotalRecords
  );
  const filterCountriesSearchTerm = useAppSelector(
    selectNewFeedFilterCountriesSearchTerm
  );
  const filterCountriesPage = useAppSelector(selectNewFeedFilterCountriesPage);
  const filterCountriesPerPage = useAppSelector(
    selectNewFeedFilterCountriesPerPage
  );
  //

  // Filter Regions
  const filterRegions = useAppSelector(selectNewFeedFilterRegions);
  const filterRegionsTotalRecords = useAppSelector(
    selectNewFeedFilterRegionsTotalRecords
  );
  const filterRegionsSearchTerm = useAppSelector(
    selectNewFeedFilterRegionsSearchTerm
  );
  const filterRegionsPage = useAppSelector(selectNewFeedFilterRegionsPage);
  const filterRegionsPerPage = useAppSelector(
    selectNewFeedFilterRegionsPerPage
  );
  //

  // Filter Authorities
  const filterAuthorities = useAppSelector(selectNewFeedFilterAuthorities);
  const filterAuthoritiesTotalRecords = useAppSelector(
    selectNewFeedFilterAuthoritiesTotalRecords
  );
  const filterAuthoritiesSearchTerm = useAppSelector(
    selectNewFeedFilterAuthoritiesSearchTerm
  );
  const filterAuthoritiesPage = useAppSelector(
    selectNewFeedFilterAuthoritiesPage
  );
  const filterAuthoritiesPerPage = useAppSelector(
    selectNewFeedFilterAuthoritiesPerPage
  );
  //

  // Filter Subcategories
  const filterSubcategories = useAppSelector(selectNewFeedFilterSubcategories);
  const filterSubcategoriesTotalRecords = useAppSelector(
    selectNewFeedFilterSubcategoriesTotalRecords
  );
  const filterSubcategoriesSearchTerm = useAppSelector(
    selectNewFeedFilterSubcategoriesSearchTerm
  );
  const filterSubcategoriesPage = useAppSelector(
    selectNewFeedFilterSubcategoriesPage
  );
  const filterSubcategoriesPerPage = useAppSelector(
    selectNewFeedFilterSubcategoriesPerPage
  );
  //

  // Filter Document Types
  const filterDocumentTypes = useAppSelector(selectNewFeedFilterDocumentTypes);
  const filterDocumentTypesTotalRecords = useAppSelector(
    selectNewFeedFilterDocumentTypesTotalRecords
  );
  const filterDocumentTypesSearchTerm = useAppSelector(
    selectNewFeedFilterDocumentTypesSearchTerm
  );
  const filterDocumentTypesPage = useAppSelector(
    selectNewFeedFilterDocumentTypesPage
  );
  const filterDocumentTypesPerPage = useAppSelector(
    selectNewFeedFilterDocumentTypesPerPage
  );
  //

  const languageEntries = useMemo(() => {
    if (!filterLanguages || !filterLanguages.length) {
      return undefined;
    }
    return filterLanguages.map((language) => {
      const filterEntry: FilterEntry = {
        value: language.id,
        name: language.name,
        developmentsCountFiltered: language.developmentsCountFiltered,
      };
      return filterEntry;
    });
  }, [JSON.stringify(filterLanguages)]);

  const regionEntries = useMemo(() => {
    if (!filterRegions || !filterRegions.length) {
      return undefined;
    }
    return filterRegions.map((region) => {
      const filterEntry: FilterEntry = {
        value: region.id,
        name: region.name,
        developmentsCountFiltered: region.developmentsCountFiltered,
      };
      return filterEntry;
    });
  }, [JSON.stringify(filterRegions)]);

  const countryEntries = useMemo(() => {
    if (!filterCountries || !filterCountries.length) {
      return undefined;
    }
    return filterCountries.map((country) => {
      const filterEntry: FilterEntry = {
        value: country.id,
        name: country.name,
        developmentsCountFiltered: country.developmentsCountFiltered,
      };
      return filterEntry;
    });
  }, [JSON.stringify(filterCountries)]);

  const authorityEntries = useMemo(() => {
    if (!filterAuthorities || !filterAuthorities.length) {
      return undefined;
    }
    return filterAuthorities.map((authority) => {
      const filterEntry: FilterEntry = {
        value: authority.id,
        name: authority.name,
        developmentsCountFiltered: authority.developmentsCountFiltered,
      };
      return filterEntry;
    });
  }, [JSON.stringify(filterAuthorities)]);

  const subcategoryEntries = useMemo(() => {
    if (!filterSubcategories || !filterSubcategories.length) {
      return undefined;
    }
    return filterSubcategories.map((subcategory) => {
      const filterEntry: FilterEntry = {
        value: subcategory.id,
        name: subcategory.name,
        developmentsCountFiltered: subcategory.developmentsCountFiltered,
      };
      return filterEntry;
    });
  }, [JSON.stringify(filterSubcategories)]);

  const documentTypeEntries = useMemo(() => {
    if (!filterDocumentTypes || !filterDocumentTypes.length) {
      return undefined;
    }
    return filterDocumentTypes.map((documentType) => {
      const filterEntry: FilterEntry = {
        value: documentType.id,
        name: documentType.name,
        developmentsCountFiltered: documentType.developmentsCountFiltered,
      };
      return filterEntry;
    });
  }, [JSON.stringify(filterDocumentTypes)]);

  const [feedName, setFeedName] = useState('');
  const [isDeleteFeedConfirmActive, setIsDeleteFeedConfirmActive] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(resetNewFeedFilterRegionsPage());
      dispatch(resetNewFeedFilterAuthoritiesPage());
      dispatch(resetNewFeedFilterCountriesPage());
      dispatch(resetNewFeedFilterDocumentTypesPage());
      dispatch(resetNewFeedFilterSubcategoriesPage());
      dispatch(resetNewFeedFilterLanguagesPage());
    };
  }, []);

  const filterEntries = useMemo(() => {
    const result: AllDynamicFilterEntries = {
      language: [],
      region: [],
      country: [],
      authority: [],
      subcategory: [],
      documentType: [],
    };
    if (
      !languageEntries ||
      !regionEntries ||
      !countryEntries ||
      !authorityEntries ||
      !subcategoryEntries ||
      !documentTypeEntries
    ) {
      return result;
    }
    const filters: AllDynamicFilterEntries = {
      language: languageEntries,
      region: regionEntries,
      country: countryEntries,
      authority: authorityEntries,
      subcategory: subcategoryEntries,
      documentType: documentTypeEntries,
    };
    Object.entries(filters).forEach(([key, values]) => {
      if (values && values.length) {
        result[key as keyof AllDynamicFilterEntries] = (
          values as FilterEntry[]
        ).map((filterEntry) => {
          const activeFilterKey =
            filterEntry.propertyToFilterByOverride ||
            developmentKeys[key] ||
            (filterKeys as any)[key];
          const newFeedActiveFiltersIncludeValue =
            !!newFeedActiveFilters[activeFilterKey] &&
            (typeof newFeedActiveFilters[activeFilterKey] === 'string'
              ? newFeedActiveFilters[activeFilterKey].includes(
                  String(filterEntry.value)
                )
              : Array.isArray(newFeedActiveFilters[activeFilterKey])
              ? (
                  newFeedActiveFilters[activeFilterKey] as FilterEntryValue[]
                ).some(
                  (x) => JSON.stringify(x) === JSON.stringify(filterEntry.value)
                )
              : false);
          const filterEntriesWithCheckedProperty: FilterEntry = {
            value: filterEntry.value,
            name: filterEntry.name,
            checked: newFeedActiveFiltersIncludeValue,
          };
          return filterEntriesWithCheckedProperty;
        });
      }
    });
    return result;
  }, [
    JSON.stringify(languageEntries),
    JSON.stringify(regionEntries),
    JSON.stringify(countryEntries),
    JSON.stringify(authorityEntries),
    JSON.stringify(subcategoryEntries),
    JSON.stringify(documentTypeEntries),
    JSON.stringify(newFeedActiveFilters),
  ]);

  const onFilterSetChange = useCallback(
    (filterKey: string) => {
      return (filterEntry: FilterEntry) => {
        dispatch(
          updateNewFeedActiveFilters({
            propertyToFilterBy:
              filterEntry.propertyToFilterByOverride || filterKey,
            value: filterEntry.value,
          })
        );
      };
    },
    [newFeedActiveFilters]
  );

  useEffect(() => {
    if (
      newFeedActiveFilters[filterKeys.authority] &&
      !!newFeedActiveFilters[filterKeys.authority].length
    ) {
      dispatch(setIsNewFeedFilterAuthoritiesCollapsed(false));
    }
    if (
      newFeedActiveFilters[filterKeys.subcategory] &&
      !!newFeedActiveFilters[filterKeys.subcategory].length
    ) {
      dispatch(setIsNewFeedFilterSubcategoriesCollapsed(false));
    }
    if (
      newFeedActiveFilters[filterKeys.language] &&
      !!newFeedActiveFilters[filterKeys.language].length
    ) {
      dispatch(setIsNewFeedFilterLanguagesCollapsed(false));
    }
    if (
      newFeedActiveFilters[filterKeys.region] &&
      !!newFeedActiveFilters[filterKeys.region].length
    ) {
      dispatch(setIsNewFeedFilterRegionsCollapsed(false));
    }
    if (
      newFeedActiveFilters[filterKeys.country] &&
      !!newFeedActiveFilters[filterKeys.country].length
    ) {
      dispatch(setIsNewFeedFilterCountriesCollapsed(false));
    }
    if (
      newFeedActiveFilters[filterKeys.documentType] &&
      !!newFeedActiveFilters[filterKeys.documentType].length
    ) {
      dispatch(setIsNewFeedFilterDocumentTypesCollapsed(false));
    }
  }, [newFeedActiveFilters]);

  useEffect(() => {
    if (feedToEdit) {
      setFeedName(feedToEdit.name);
      const newActiveFilters: ActiveFilters = {};
      if (feedToEdit.authorityIds && feedToEdit.authorityIds.length) {
        newActiveFilters[filterKeys.authority] = feedToEdit.authorityIds;
        dispatch(setIsNewFeedFilterAuthoritiesCollapsed(false));
      }
      if (feedToEdit.subcategoryIds && feedToEdit.subcategoryIds.length) {
        newActiveFilters[filterKeys.subcategory] = feedToEdit.subcategoryIds;
        dispatch(setIsNewFeedFilterSubcategoriesCollapsed(false));
      }
      if (feedToEdit.languageIds && feedToEdit.languageIds.length) {
        newActiveFilters[filterKeys.language] = feedToEdit.languageIds;
        dispatch(setIsNewFeedFilterLanguagesCollapsed(false));
      }
      if (feedToEdit.regionIds && feedToEdit.regionIds.length) {
        newActiveFilters[filterKeys.region] = feedToEdit.regionIds;
        dispatch(setIsNewFeedFilterRegionsCollapsed(false));
      }
      if (feedToEdit.countryIds && feedToEdit.countryIds.length) {
        newActiveFilters[filterKeys.country] = feedToEdit.countryIds;
        dispatch(setIsNewFeedFilterCountriesCollapsed(false));
      }
      if (feedToEdit.documentTypeIds && feedToEdit.documentTypeIds.length) {
        newActiveFilters[filterKeys.documentType] = feedToEdit.documentTypeIds;
        dispatch(setIsNewFeedFilterDocumentTypesCollapsed(false));
      }
      dispatch(setNewFeedActiveFilters(newActiveFilters));
    }
  }, [feedToEdit]);

  const verifyAllSelectedFilterAreDisplayed = useCallback(() => {
    let anyFilterWasNotBeingDisplayed = false;
    if (newFeedActiveFilters[filterKeys.language]) {
      const areAllDisplayed = (
        newFeedActiveFilters[filterKeys.language] as any
      ).every((filter: string) =>
        filterEntries.language.some((entry) => entry.value === filter)
      );
      if (!areAllDisplayed) {
        dispatch(increaseNewFeedFilterLanguagesPage());
        anyFilterWasNotBeingDisplayed = true;
      }
    }

    if (newFeedActiveFilters[filterKeys.region]) {
      const areAllDisplayed = (
        newFeedActiveFilters[filterKeys.region] as any
      ).every((filter: string) =>
        filterEntries.region.some((entry) => entry.value === filter)
      );
      if (!areAllDisplayed) {
        dispatch(increaseNewFeedFilterRegionsPage());
        anyFilterWasNotBeingDisplayed = true;
      }
    }

    if (newFeedActiveFilters[filterKeys.country]) {
      const areAllDisplayed = (
        newFeedActiveFilters[filterKeys.country] as any
      ).every((filter: string) =>
        filterEntries.country.some((entry) => entry.value === filter)
      );
      if (!areAllDisplayed) {
        dispatch(increaseNewFeedFilterCountriesPage());
        anyFilterWasNotBeingDisplayed = true;
      }
    }

    if (newFeedActiveFilters[filterKeys.authority]) {
      const areAllDisplayed = (
        newFeedActiveFilters[filterKeys.authority] as any
      ).every((filter: string) =>
        filterEntries.authority.some((entry) => entry.value === filter)
      );
      if (!areAllDisplayed) {
        dispatch(increaseNewFeedFilterAuthoritiesPage());
        anyFilterWasNotBeingDisplayed = true;
      }
    }

    if (newFeedActiveFilters[filterKeys.subcategory]) {
      const areAllDisplayed = (
        newFeedActiveFilters[filterKeys.subcategory] as any
      ).every((filter: string) =>
        filterEntries.subcategory.some((entry) => entry.value === filter)
      );
      if (!areAllDisplayed) {
        dispatch(increaseNewFeedFilterSubcategoriesPage());
        anyFilterWasNotBeingDisplayed = true;
      }
    }

    if (newFeedActiveFilters[filterKeys.documentType]) {
      const areAllDisplayed = (
        newFeedActiveFilters[filterKeys.documentType] as any
      ).every((filter: string) =>
        filterEntries.documentType.some((entry) => entry.value === filter)
      );
      if (!areAllDisplayed) {
        dispatch(increaseNewFeedFilterDocumentTypesPage());
        anyFilterWasNotBeingDisplayed = true;
      }
    }
    if (anyFilterWasNotBeingDisplayed) {
      dispatch(setNewFeedFilterTriggerGetAllFilterSetEntries());
    }
  }, [JSON.stringify(newFeedActiveFilters), JSON.stringify(filterEntries)]);

  useEffect(() => {
    verifyAllSelectedFilterAreDisplayed();
  }, [JSON.stringify(filterEntries), props.isDisplayed]);

  const buildFeed = () => {
    if (
      authenticationResponse &&
      selectedEnvironment &&
      selectedEnvironment.id &&
      feedName
    ) {
      const feed: Feed = {
        name: feedName,
        userId: authenticationResponse.id,
        environmentId: selectedEnvironment.id,
        authorityIds: (newFeedActiveFilters[filterKeys.authority] ||
          []) as number[],
        subcategoryIds: (newFeedActiveFilters[filterKeys.subcategory] ||
          []) as number[],
        languageIds: (newFeedActiveFilters[filterKeys.language] ||
          []) as number[],
        regionIds: (newFeedActiveFilters[filterKeys.region] || []) as number[],
        countryIds: (newFeedActiveFilters[filterKeys.country] ||
          []) as number[],
        documentTypeIds: (newFeedActiveFilters[filterKeys.documentType] ||
          []) as number[],
      };
      return feed;
    }
  };

  const createOrUpdateFeed = async () => {
    setIsLoading(true);
    const feed = buildFeed();
    if (feed) {
      let updatedFeed: Feed | null = null;
      if (!feedToEdit) {
        const createdFeed = await dispatch(createFeedAsync(feed));
        feed.id = createdFeed?.id;
        feed.createdAt = createdFeed?.createdAt;
      } else {
        feed.id = feedToEdit.id;
        feed.createdAt = feedToEdit.createdAt;
        if (props.onUpdate) {
          updatedFeed = props.onUpdate(feed);
        } else {
          updatedFeed = await dispatch(updateFeedAsync(feed));
        }
      }
      if (props.selectsFeedAfterUpsert) {
        await dispatch(selectFeedAsync(feed));
      }
      if (
        props.type === CreateFeedModalTypes.FeedManagement &&
        props.onAfterSubmit
      ) {
        props.onAfterSubmit(updatedFeed);
      }
    }
    closeModal();
    setIsLoading(false);
  };

  const promptDeleteFeedConfirm = () => {
    setIsDeleteFeedConfirmActive(true);
  };

  const deleteFeed = async () => {
    if (feedToEdit && feedToEdit.id) {
      const defaultFeed = feeds.find((x) => x.id === Number.MAX_SAFE_INTEGER);
      if (defaultFeed) {
        await dispatch(selectFeedAsync(defaultFeed));
      }
      await dispatch(deleteFeedAsync(feedToEdit.id));
      if (props.onDelete) {
        props.onDelete();
      }
      closeModal();
    }
  };

  const closeModal = () => {
    if (props.setSelectsFeedAfterUpsert) {
      props.setSelectsFeedAfterUpsert(true);
    }
    setFeedName('');
    dispatch(setNewFeedActiveFilters({}));

    dispatch(resetNewFeedFilterAuthoritiesPage());
    dispatch(resetNewFeedFilterAuthoritiesSearchTerm());
    dispatch(setIsNewFeedFilterAuthoritiesCollapsed(true));

    dispatch(resetNewFeedFilterSubcategoriesPage());
    dispatch(resetNewFeedFilterSubcategoriesSearchTerm());
    dispatch(setIsNewFeedFilterSubcategoriesCollapsed(true));

    dispatch(resetNewFeedFilterLanguagesPage());
    dispatch(resetNewFeedFilterLanguagesSearchTerm());
    dispatch(setIsNewFeedFilterLanguagesCollapsed(true));

    dispatch(resetNewFeedFilterRegionsPage());
    dispatch(resetNewFeedFilterRegionsSearchTerm());
    dispatch(setIsNewFeedFilterRegionsCollapsed(true));

    dispatch(resetNewFeedFilterCountriesPage());
    dispatch(resetNewFeedFilterCountriesSearchTerm());
    dispatch(setIsNewFeedFilterCountriesCollapsed(true));

    dispatch(resetNewFeedFilterDocumentTypesPage());
    dispatch(resetNewFeedFilterDocumentTypesSearchTerm());
    dispatch(setIsNewFeedFilterDocumentTypesCollapsed(true));

    props.setIsDisplayed(false);
    if (props.type === CreateFeedModalTypes.FeedPicker) {
      dispatch(setFeedPickerFeedToEdit(null));
    } else {
      dispatch(setManagementFeedToEdit(null));
    }
    setIsDeleteFeedConfirmActive(false);
    setIsLoading(false);
  };

  const isAnyFilterSelected = useMemo(() => {
    return !!(
      (newFeedActiveFilters[filterKeys.authority] &&
        newFeedActiveFilters[filterKeys.authority].length) ||
      (newFeedActiveFilters[filterKeys.subcategory] &&
        newFeedActiveFilters[filterKeys.subcategory].length) ||
      (newFeedActiveFilters[filterKeys.language] &&
        newFeedActiveFilters[filterKeys.language].length) ||
      (newFeedActiveFilters[filterKeys.region] &&
        newFeedActiveFilters[filterKeys.region].length) ||
      (newFeedActiveFilters[filterKeys.country] &&
        newFeedActiveFilters[filterKeys.country].length) ||
      (newFeedActiveFilters[filterKeys.documentType] &&
        newFeedActiveFilters[filterKeys.documentType].length)
    );
  }, [newFeedActiveFilters]);

  const filtersCount = useMemo(() => {
    return (
      (newFeedActiveFilters[filterKeys.authority]?.length || 0) +
      (newFeedActiveFilters[filterKeys.subcategory]?.length || 0) +
      (newFeedActiveFilters[filterKeys.language]?.length || 0) +
      (newFeedActiveFilters[filterKeys.region]?.length || 0) +
      (newFeedActiveFilters[filterKeys.country]?.length || 0) +
      (newFeedActiveFilters[filterKeys.documentType]?.length || 0)
    );
  }, [newFeedActiveFilters]);

  return (
    <Modal
      id="CreateFeedModal"
      isDisplayed={props.isDisplayed}
      isLoading={isLoading}
    >
      <CreateFeedModalContainer id="CreateFeedModalContainer">
        {isDeleteFeedConfirmActive && (
          <>
            <DeleteFeedPromptOverlay id="CreateFeedModalDeleteFeedPromptOverlay" />
            <DeleteFeedPromptContainer id="CreateFeedModalDeleteFeedPromptContainer">
              <FlexContainer
                id="CreateFeedModalDeleteFeedPromptTitleContainer"
                style={{ alignItems: 'center', marginBottom: '20px' }}
              >
                <Warning
                  id="CreateFeedModalDeleteFeedPromptWarningIcon"
                  style={{ color: Theme.colors.black, marginRight: '10px' }}
                />
                <AreYouSureText id="CreateFeedModalDeleteFeedPromptTitle">
                  Are you sure?
                </AreYouSureText>
              </FlexContainer>
              <FlexContainer id="CreateFeedModalDeleteFeedPromptMessageContainer">
                <span style={{ fontSize: Theme.fontSize }}>
                  Deleting this feed is <b>irreversible</b>, and you will need
                  to create a new one if you wish to work with these
                  configurations again.
                </span>
              </FlexContainer>
              <FlexContainer
                id="CreateFeedModalDeleteFeedPromptButtonContainer"
                style={{ margin: 'auto 0 0 auto' }}
              >
                <OutlinedButton
                  id="CreateFeedModalDeleteFeedPromptDeleteButton"
                  color={Theme.colors.secondaryColor}
                  style={{ marginRight: '10px' }}
                  onClick={deleteFeed}
                >
                  Delete feed
                </OutlinedButton>
                <Button
                  id="CreateFeedModalDeleteFeedPromptCancelButton"
                  onClick={() => setIsDeleteFeedConfirmActive(false)}
                >
                  Cancel
                </Button>
              </FlexContainer>
            </DeleteFeedPromptContainer>
          </>
        )}
        <ModalContentContainer id="CreateFeedModalContentContainer">
          <ModalInnerContainer id="CreateFeedModalHeader">
            <ModalTitle
              id="CreateFeedModalTitle"
              withoutMarginBottom={true}
              color={Theme.colors.primaryColor}
            >
              {feedToEdit ? 'Edit Feed' : 'Create New Feed'}
            </ModalTitle>
            <CloseIconContainer id="CreateFeedModalCloseIconContainer">
              <Close
                id="CreateFeedModalCloseButton"
                onClick={() => closeModal()}
              />
            </CloseIconContainer>
          </ModalInnerContainer>
          <ModalInnerContainer
            id="CreateFeedModalNameContainer"
            padding="8px 16px 14px 16px"
          >
            <FlexContainer
              id="CreateFeedModalNameFlexContainer"
              flexDirection="column"
              style={{ width: '100%' }}
            >
              <ModalTitle
                id="CreateFeedModalNameLabel"
                color={Theme.colors.black}
              >
                Feed name
              </ModalTitle>
              <Input
                id="CreateFeedModalName"
                placeholder="Enter name..."
                value={feedName}
                setValue={(value) => {
                  setFeedName(value);
                }}
              />
            </FlexContainer>
          </ModalInnerContainer>
          <ModalInnerContainer id="CreateFeedModalFiltersTitleContainer">
            <ModalTitle
              id="CreateFeedModalFiltersTitle"
              withoutMarginBottom={true}
              color={Theme.colors.primaryColor}
            >
              {feedToEdit ? 'Edit feed filters' : 'Configure feed filters'}
            </ModalTitle>
          </ModalInnerContainer>
          {filterEntries && !!selectedEnvironment && props.isDisplayed && (
            <ModalInnerContainer
              id="CreateFeedModalFiltersContainer"
              style={{ overflowY: 'auto' }}
              padding="6px 16px"
            >
              <FlexContainer
                id="CreateFeedModalFiltersFlexContainer"
                style={{
                  height: '100%',
                }}
              >
                <FilterSet
                  id="CreateFeedModalFilterSetLanguage"
                  isCollapsed={isLanguagesFilterCollapsed}
                  setIsCollapsed={setIsNewFeedFilterLanguagesCollapsed}
                  title="Language"
                  entries={filterEntries.language}
                  totalRecords={filterLanguagesTotalRecords}
                  getAllAsync={getAllNewFeedFilterLanguagesAsync}
                  searchTerm={filterLanguagesSearchTerm}
                  setSearchTerm={setNewFeedFilterLanguagesSearchTerm}
                  page={filterLanguagesPage}
                  perPage={filterLanguagesPerPage}
                  increasePage={increaseNewFeedFilterLanguagesPage}
                  resetPage={resetNewFeedFilterLanguagesPage}
                  onChange={onFilterSetChange(filterKeys.language)}
                  filterEntriesWidth="33.33%"
                  displayEntriesWithCountZero
                  hideEntriesCount
                  triggerGetAllFilterSetEntries={triggerGetAllFilterSetEntries}
                />
                <FilterSet
                  id="CreateFeedModalFilterSetRegion"
                  isCollapsed={isRegionsFilterCollapsed}
                  setIsCollapsed={setIsNewFeedFilterRegionsCollapsed}
                  title="Region"
                  entries={filterEntries.region}
                  totalRecords={filterRegionsTotalRecords}
                  getAllAsync={getAllNewFeedFilterRegionsAsync}
                  searchTerm={filterRegionsSearchTerm}
                  setSearchTerm={setNewFeedFilterRegionsSearchTerm}
                  page={filterRegionsPage}
                  perPage={filterRegionsPerPage}
                  increasePage={increaseNewFeedFilterRegionsPage}
                  resetPage={resetNewFeedFilterRegionsPage}
                  onChange={onFilterSetChange(filterKeys.region)}
                  filterEntriesWidth="33.33%"
                  displayEntriesWithCountZero
                  hideEntriesCount
                  triggerGetAllFilterSetEntries={triggerGetAllFilterSetEntries}
                />
                <FilterSet
                  id="CreateFeedModalFilterSetCountry"
                  isCollapsed={isCountriesFilterCollapsed}
                  setIsCollapsed={setIsNewFeedFilterCountriesCollapsed}
                  title="Jurisdiction"
                  entries={filterEntries.country}
                  totalRecords={filterCountriesTotalRecords}
                  getAllAsync={getAllNewFeedFilterCountriesAsync}
                  searchTerm={filterCountriesSearchTerm}
                  setSearchTerm={setNewFeedFilterCountriesSearchTerm}
                  page={filterCountriesPage}
                  perPage={filterCountriesPerPage}
                  increasePage={increaseNewFeedFilterCountriesPage}
                  resetPage={resetNewFeedFilterCountriesPage}
                  onChange={onFilterSetChange(filterKeys.country)}
                  filterEntriesWidth="33.33%"
                  displayEntriesWithCountZero
                  hideEntriesCount
                  triggerGetAllFilterSetEntries={triggerGetAllFilterSetEntries}
                />
                <FilterSet
                  id="CreateFeedModalFilterSetAuthority"
                  isCollapsed={isAuthoritiesFilterCollapsed}
                  setIsCollapsed={setIsNewFeedFilterAuthoritiesCollapsed}
                  title="Issuing Authority"
                  entries={filterEntries.authority}
                  totalRecords={filterAuthoritiesTotalRecords}
                  getAllAsync={getAllNewFeedFilterAuthoritiesAsync}
                  searchTerm={filterAuthoritiesSearchTerm}
                  setSearchTerm={setNewFeedFilterAuthoritiesSearchTerm}
                  page={filterAuthoritiesPage}
                  perPage={filterAuthoritiesPerPage}
                  increasePage={increaseNewFeedFilterAuthoritiesPage}
                  resetPage={resetNewFeedFilterAuthoritiesPage}
                  onChange={onFilterSetChange(filterKeys.authority)}
                  filterEntriesWidth="33.33%"
                  displayEntriesWithCountZero
                  hideEntriesCount
                  triggerGetAllFilterSetEntries={triggerGetAllFilterSetEntries}
                />
                <FilterSet
                  id="CreateFeedModalFilterSetSubcategory"
                  isCollapsed={isSubcategoriesFilterCollapsed}
                  setIsCollapsed={setIsNewFeedFilterSubcategoriesCollapsed}
                  title="Subcategory"
                  entries={filterEntries.subcategory}
                  totalRecords={filterSubcategoriesTotalRecords}
                  getAllAsync={getAllNewFeedFilterSubcategoriesAsync}
                  searchTerm={filterSubcategoriesSearchTerm}
                  setSearchTerm={setNewFeedFilterSubcategoriesSearchTerm}
                  page={filterSubcategoriesPage}
                  perPage={filterSubcategoriesPerPage}
                  increasePage={increaseNewFeedFilterSubcategoriesPage}
                  resetPage={resetNewFeedFilterSubcategoriesPage}
                  onChange={onFilterSetChange(filterKeys.subcategory)}
                  filterEntriesWidth="33.33%"
                  displayEntriesWithCountZero
                  hideEntriesCount
                  triggerGetAllFilterSetEntries={triggerGetAllFilterSetEntries}
                />
                <FilterSet
                  id="CreateFeedModalFilterSetDocumentType"
                  isCollapsed={isDocumentTypesFilterCollapsed}
                  setIsCollapsed={setIsNewFeedFilterDocumentTypesCollapsed}
                  title="Document type"
                  entries={filterEntries.documentType}
                  totalRecords={filterDocumentTypesTotalRecords}
                  getAllAsync={getAllNewFeedFilterDocumentTypesAsync}
                  searchTerm={filterDocumentTypesSearchTerm}
                  setSearchTerm={setNewFeedFilterDocumentTypesSearchTerm}
                  page={filterDocumentTypesPage}
                  perPage={filterDocumentTypesPerPage}
                  increasePage={increaseNewFeedFilterDocumentTypesPage}
                  resetPage={resetNewFeedFilterDocumentTypesPage}
                  onChange={onFilterSetChange(filterKeys.documentType)}
                  filterEntriesWidth="33.33%"
                  displayEntriesWithCountZero
                  hideEntriesCount
                  triggerGetAllFilterSetEntries={triggerGetAllFilterSetEntries}
                />
                <div style={{ height: '6px', width: '100%' }}></div>
              </FlexContainer>
            </ModalInnerContainer>
          )}
          <ModalInnerContainer
            id="CreateFeedModalFooterContainer"
            withoutBorderBottom={true}
            padding="12px 16px"
          >
            {feedToEdit && (
              <FlexContainer id="CreateFeedModalDeleteFeedButtonContainer">
                <UnderlinedButton
                  id="CreateFeedModalDeleteFeedButton"
                  color={Theme.colors.secondaryColor}
                  onClick={promptDeleteFeedConfirm}
                >
                  Delete feed
                </UnderlinedButton>
              </FlexContainer>
            )}
            <CreateFeedModalButtonsContainer id="CreateFeedModalButtonsContainer">
              <span
                id="CreateFeedModalFiltersSelectedText"
                style={{ marginRight: '15px', fontSize: Theme.fontSize - 2 }}
              >
                Filters selected{' '}
                <span
                  id="CreateFeedModalFiltersCount"
                  style={{
                    color: Theme.colors.secondaryColor,
                    fontWeight: 'bold',
                  }}
                >
                  ({filtersCount})
                </span>
              </span>
              <OutlinedButton
                id="CreateFeedModalCancelButton"
                color={Theme.colors.secondaryColor}
                style={{ marginRight: '10px' }}
                onClick={() => closeModal()}
              >
                Cancel
              </OutlinedButton>
              <Button
                id="CreateFeedModalSubmitButton"
                onClick={createOrUpdateFeed}
                isDisabled={!isAnyFilterSelected || !feedName}
              >
                {feedToEdit ? 'Update changes' : 'Create feed'}
              </Button>
            </CreateFeedModalButtonsContainer>
          </ModalInnerContainer>
        </ModalContentContainer>
      </CreateFeedModalContainer>
    </Modal>
  );
};
