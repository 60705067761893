import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { CheckboxInput } from 'components/CheckboxInput';
import { Input } from 'components/Input';
import { OutputConsole } from 'components/OutputConsole';
import { localStorageKeys } from 'config/local-storage-keys';
import { RealTimeMatchObligationsEvent } from 'enums';
import { Environment } from 'models';
import { useCallback, useEffect, useState } from 'react';
import { selectAuthenticationResponse } from 'store/authentication/authentication-slice';
import { useAppSelector } from 'store/hooks';
import { Theme } from 'styles/themes';
import { HttpResponse } from 'utils/http/HttpResponse';
import { BaseSocketService } from 'utils/socket/BaseSocketService';

interface MatchObligationsCardProps {
  selectedEnvironment: Environment | null;
}

export const MatchObligationsCard = (props: MatchObligationsCardProps) => {
  const authenticationResponse = useAppSelector(selectAuthenticationResponse);

  const [socketService, setSocketService] = useState<BaseSocketService | null>(
    null
  );
  const [isForce, setIsForce] = useState<boolean>(false);
  const [developmentId, setDevelopmentId] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [outputs, setOutputs] = useState<string[]>([]);
  const [isProcessOnGoing, setIsProcessOnGoing] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (authenticationResponse?.accessToken) {
      setSocketService(
        new BaseSocketService(authenticationResponse.accessToken)
      );
    }
  }, [JSON.stringify(authenticationResponse)]);

  useEffect(() => {
    if (socketService) {
      socketService.listen(
        RealTimeMatchObligationsEvent.MatchObligationsUpdateProgress,
        (output: string) => {
          setOutputs((currentValue) => [...currentValue, output]);
        }
      );
      socketService.listen(
        RealTimeMatchObligationsEvent.MatchObligationsFinished,
        () => {
          setIsProcessOnGoing(false);
          localStorage.setItem(
            localStorageKeys.isPerformingIndefiniteOperation,
            btoa('false')
          );
        }
      );
      return () => {
        socketService.disconnect();
      };
    }
  }, [socketService]);

  const handlePrimaryAction = useCallback(async () => {
    try {
      setErrorMessage('');
      if (
        props.selectedEnvironment &&
        authenticationResponse &&
        socketService
      ) {
        setOutputs([]);
        localStorage.setItem(
          localStorageKeys.isPerformingIndefiniteOperation,
          btoa('true')
        );
        setIsProcessOnGoing(true);
        socketService.emit(
          RealTimeMatchObligationsEvent.MatchObligations,
          authenticationResponse.id,
          props.selectedEnvironment.id,
          isForce,
          Number(developmentId),
          fromDate,
          toDate
        );
      }
    } catch (error) {
      const httpResponse = error as HttpResponse<null>;
      if (!httpResponse.success && httpResponse.error) {
        setErrorMessage(httpResponse.error.message);
      }
    } finally {
      setIsProcessOnGoing(false);
    }
  }, [
    socketService,
    JSON.stringify(authenticationResponse),
    props.selectedEnvironment?.id,
    isForce,
    developmentId,
    fromDate,
    toDate,
  ]);

  return (
    <Card>
      <span
        style={{
          fontSize: Theme.fontSize,
          fontWeight: 'bold',
          color: Theme.colors.primaryColor,
          marginBottom: '16px',
        }}
      >
        Match Obligations
      </span>
      <div>
        <div style={{ width: '100%', marginBottom: '16px' }}>
          <CheckboxInput
            label="Force"
            isSelected={isForce}
            setIsSelected={setIsForce}
          />
        </div>
        <span style={{ display: 'block', marginBottom: '16px' }}>AND</span>
        <div style={{ width: '100%', marginBottom: '16px' }}>
          <Input
            type="number"
            placeholder="Development ID..."
            value={developmentId}
            setValue={setDevelopmentId}
          />
        </div>
        <span style={{ display: 'block', marginBottom: '16px' }}>OR</span>
        <div style={{ width: '100%', marginBottom: '16px' }}>
          <Input
            type="text"
            placeholder="From date..."
            value={fromDate}
            setValue={setFromDate}
          />
          <span
            style={{
              fontSize: Theme.fontSize - 4,
              display: 'block',
              marginTop: '8px',
            }}
          >
            Example: 2023-01-01T00:00:00
          </span>
        </div>
        <div style={{ width: '100%', marginBottom: '16px' }}>
          <Input
            type="text"
            placeholder="To date..."
            value={toDate}
            setValue={setToDate}
          />
          <span
            style={{
              fontSize: Theme.fontSize - 4,
              display: 'block',
              marginTop: '8px',
            }}
          >
            Example: 2024-01-01T00:00:00
          </span>
        </div>
        <Button
          onClick={() => {
            handlePrimaryAction();
          }}
          style={{ marginBottom: '16px' }}
          isDisabled={isProcessOnGoing}
        >
          Run
        </Button>
        <div>
          <span
            style={{
              marginBottom: '16px',
              display: 'block',
            }}
          >
            Output
          </span>
          <OutputConsole outputs={outputs} height="300px" />
        </div>
      </div>
    </Card>
  );
};
