import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { useNavigate } from 'react-router-dom';
import { routes } from 'navigation/routes';

export interface Document {
  id: string;
  text: string;
  title: string;
  documentId: string;
  externalId: string;
}
interface CopilotCitationsProps {
  documents: Document[];
}

const CopilotChatboxContainer = styled.div({
  //display: 'flex',
  flexDirection: 'column',
  margin: '10px',
  height: '100%',
  width: '100%',
  overflow: 'auto',
  position: 'relative',
});

const ChatContainer = styled.div({
  padding: '10px 5px 10px',
  width: '95%',
  textOverflow: 'ellipsis',
});

const ChatBubble = styled.div({
  //maxWidth: '90%',
  padding: '5px 10px 5px',
  borderRadius: '5px',
  backgroundColor: Theme.colors.secondaryBackgroundColor,
  maxHeight: '150px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'block',
  lineHeight: '1.5',
  ':hover': {
    cursor: 'pointer',
    backgroundColor: Theme.colors.gray,
  },
});

export const CopilotCitations = (props: CopilotCitationsProps) => {
  const navigate = useNavigate();
  return (
    <CopilotChatboxContainer>
      {props.documents
        ? props.documents.map((document, index) => (
            <ChatContainer key={index}>
              <ChatBubble
                onClick={() =>
                  navigate(
                    routes.developmentDetail.replace(
                      ':developmentId',
                      String(document.documentId)
                    )
                  )
                }
              >
                {document.text}
              </ChatBubble>
            </ChatContainer>
          ))
        : null}
    </CopilotChatboxContainer>
  );
};
