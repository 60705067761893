import styled from '@emotion/styled';
import { Theme } from 'styles/themes';

export interface ChatType {
  role: string;
  message: string;
}
interface CopilotChatboxProps {
  chatHistory: ChatType[];
}

const CopilotChatboxContainer = styled.div({
  //display: 'flex',
  flexDirection: 'column',
  justifyContent: 'end',
  margin: '10px',
  height: '85%',
  width: '100%',
  overflow: 'auto',
  position: 'relative',
});

const ChatContainer = styled.div<{ align: string }>((props) => ({
  display: 'flex',
  padding: '20px',
  width: '100%',
  position: 'relative',
  alignItems: 'center',
  justifyContent: props.align,
}));

const ChatBubble = styled.div<{ color: string }>((props) => ({
  display: 'flex',
  maxWidth: '70%',
  alignItems: 'flex-end',
  padding: '5px 10px 5px',
  borderRadius: '5px',
  marginRight: '15px',
  backgroundColor: props.color,
  lineHeight: '1.5',
}));

export const CopilotChatbox = (props: CopilotChatboxProps) => {
  return (
    <CopilotChatboxContainer>
      {props.chatHistory.map((chat, index) => (
        <ChatContainer
          key={index}
          align={chat.role === 'USER' ? 'flex-end' : 'flex-start'}
        >
          <ChatBubble
            color={
              chat.role === 'USER'
                ? Theme.colors.blueLighter
                : Theme.colors.primaryBackgroundColor
            }
          >
            {chat.message}
          </ChatBubble>
        </ChatContainer>
      ))}
    </CopilotChatboxContainer>
  );
};
