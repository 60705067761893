import styled from '@emotion/styled';
import { ReactComponent as ArrowDown } from 'assets/arrow-down.svg';
import { Theme } from 'styles/themes';
import { HTMLAttrID } from 'models';
import { CSSProperties } from 'react';

interface DropdownArrowProps extends HTMLAttrID {
  isExpanded: boolean;
  color?: string;
  style?: CSSProperties;
}

const ArrowDownContainer = styled.div<DropdownArrowProps>((props) => ({
  transform: props.isExpanded ? 'rotate(180deg)' : 'none',
  transition: 'transform 0.3s ease-out',
  height: '100%',
}));

export const DropdownArrow = (props: DropdownArrowProps) => {
  const containerId = props.id ? `${props.id}Container` : undefined;
  const ArrowId = props.id ? `${props.id}Arrow` : undefined;

  return (
    <ArrowDownContainer
      id={containerId}
      isExpanded={props.isExpanded}
      style={props.style}
    >
      <ArrowDown
        id={ArrowId}
        style={{ color: props.color || Theme.colors.grayDarker }}
      />
    </ArrowDownContainer>
  );
};
