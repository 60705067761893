import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { getDocumentTypesWithDevelopmentCountGreaterThanZero } from 'services/document-types/document-types-service';
import { DocumentType, PaginatedResponse } from 'models';
import { paginationConfig } from 'config/pagination-config';
import { filterKeys } from 'config/filter-keys';
import { isArraySubsetOfAnother } from 'utils/array';

export interface HomeSidebarFilterDocumentTypeState {
  documentTypes: DocumentType[];
  totalRecords: number;
  searchTerm: string;
  page: number;
  previousPage: number;
  perPage: number;
  isConsecutiveFetch: boolean;
}

const initialState: HomeSidebarFilterDocumentTypeState = {
  documentTypes: [],
  totalRecords: 0,
  searchTerm: '',
  page: 1,
  previousPage: 1,
  perPage: paginationConfig.documentTypesPerPage,
  isConsecutiveFetch: false,
};

export const getAllHomeSidebarFilterDocumentTypesAsync = (): AppThunk<
  Promise<PaginatedResponse<DocumentType> | null>
> => {
  return async (dispatch, getState) => {
    const selectedEnvironment = getState().environment.selectedEnvironment;
    const searchTerm = getState().homeSidebarFilterDocumentType.searchTerm;
    const page = getState().homeSidebarFilterDocumentType.page;
    const previousPage = getState().homeSidebarFilterDocumentType.previousPage;
    const perPage = getState().homeSidebarFilterDocumentType.perPage;
    const activeFilters = getState().homeSidebarFilter.activeFilters;
    const ids = getState().feedPicker.appliedFeed?.documentTypeIds;
    const isConsecutiveFetch =
      getState().homeSidebarFilterDocumentType.isConsecutiveFetch;

    const getDocumentTypes = async (pageNumber: number) => {
      return await getDocumentTypesWithDevelopmentCountGreaterThanZero(
        selectedEnvironment,
        searchTerm,
        pageNumber,
        perPage,
        activeFilters,
        ids
      );
    };

    if (isConsecutiveFetch) {
      dispatch(setIsConsecutiveDocumentTypesFetch(false));
    }

    if (
      isConsecutiveFetch &&
      activeFilters[filterKeys.documentType] &&
      activeFilters[filterKeys.documentType].length &&
      !ids?.length
    ) {
      let currentPage = 0;
      let isLastPage = false;
      let allDocumentTypeIds: number[] = [];
      let areAllCheckedFiltersDisplayed = false;
      let response;
      let incomingDocumentTypes: DocumentType[] = [];
      do {
        response = await getDocumentTypes(++currentPage);
        if (!response.data) {
          return null;
        }
        isLastPage =
          currentPage >= Math.ceil(response.data.totalRecords / perPage);
        incomingDocumentTypes = incomingDocumentTypes.concat(
          response.data.records
        );
        allDocumentTypeIds = allDocumentTypeIds.concat(
          response.data.records.map((x) => x.id!)
        );
        areAllCheckedFiltersDisplayed = isArraySubsetOfAnother(
          activeFilters[filterKeys.documentType] as number[],
          allDocumentTypeIds
        );
      } while (!areAllCheckedFiltersDisplayed && !isLastPage);
      response.data.records = incomingDocumentTypes;
      dispatch(setHomeSidebarFilterDocumentTypesPage(currentPage));
      dispatch(setHomeSidebarFilterDocumentTypes(response.data));
      return response.data;
    } else {
      const response = await getDocumentTypes(page);
      if (page === 1) {
        dispatch(setHomeSidebarFilterDocumentTypes(response.data));
      } else {
        if (page > previousPage) {
          dispatch(addHomeSidebarFilterDocumentTypes(response.data));
        }
      }
      return response.data;
    }
  };
};

export const homeSidebarFilterDocumentTypeSlice = createSlice({
  name: 'home-sidebar-filter-documentType',
  initialState,
  reducers: {
    setHomeSidebarFilterDocumentTypes: (
      state,
      action: PayloadAction<PaginatedResponse<DocumentType> | null>
    ) => {
      state.documentTypes = action.payload?.records ?? [];
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    addHomeSidebarFilterDocumentTypes: (
      state,
      action: PayloadAction<PaginatedResponse<DocumentType> | null>
    ) => {
      state.documentTypes = state.documentTypes.concat(
        action.payload?.records ?? []
      );
      state.totalRecords = action.payload?.totalRecords ?? 0;
      state.previousPage = state.page;
    },
    setHomeSidebarFilterDocumentTypesSearchTerm: (
      state,
      action: PayloadAction<string>
    ) => {
      state.page = 1;
      state.searchTerm = action.payload;
    },
    setHomeSidebarFilterDocumentTypesPage: (
      state,
      action: PayloadAction<number>
    ) => {
      state.page = action.payload;
    },
    increaseHomeSidebarFilterDocumentTypesPage: (state) => {
      state.page = state.page + 1;
    },
    resetHomeSidebarFilterDocumentTypesPage: (state) => {
      state.page = 1;
      state.previousPage = 1;
    },
    setIsConsecutiveDocumentTypesFetch: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isConsecutiveFetch = action.payload;
    },
  },
});

export const {
  setHomeSidebarFilterDocumentTypes,
  addHomeSidebarFilterDocumentTypes,
  setHomeSidebarFilterDocumentTypesSearchTerm,
  setHomeSidebarFilterDocumentTypesPage,
  increaseHomeSidebarFilterDocumentTypesPage,
  resetHomeSidebarFilterDocumentTypesPage,
  setIsConsecutiveDocumentTypesFetch,
} = homeSidebarFilterDocumentTypeSlice.actions;

export const selectHomeSidebarFilterDocumentTypes = (state: RootState) =>
  state.homeSidebarFilterDocumentType.documentTypes;
export const selectHomeSidebarFilterDocumentTypesTotalRecords = (
  state: RootState
) => state.homeSidebarFilterDocumentType.totalRecords;
export const selectHomeSidebarFilterDocumentTypesSearchTerm = (
  state: RootState
) => state.homeSidebarFilterDocumentType.searchTerm;
export const selectHomeSidebarFilterDocumentTypesPage = (state: RootState) =>
  state.homeSidebarFilterDocumentType.page;
export const selectHomeSidebarFilterDocumentTypesPerPage = (state: RootState) =>
  state.homeSidebarFilterDocumentType.perPage;

export default homeSidebarFilterDocumentTypeSlice.reducer;
