import {
  useEffect,
  useMemo,
  useState,
  MutableRefObject,
  useCallback,
} from 'react';
import {
  ManagementDetail,
  DetailButton,
  TitleValue,
} from 'pages/management/components';
import { Input } from 'components/Input';
import { ClosableBlueChip } from 'components/Chip';
import { ReactComponent as CancelIcon } from 'assets/cancel.svg';
import { ReactComponent as ConfirmIcon } from 'assets/confirm-edit.svg';
import { ReactComponent as AddIcon } from 'assets/plus-circle.svg';
import { Theme } from 'styles/themes';
import { FlexContainer } from 'styles/utils';
import { Team, TeamFeed, Feed, User } from 'models';
import { useOnClickOutside } from 'hooks/use-on-click-outside';
import { ManagementSectionProps } from '../components/ManagementDetail';

export const TeamEdit = ({
  team,
  containerRef,
  isHidden,
  addedFeeds,
  addedUsers,
  onClickAddFeedsButton,
  onClickAddUsersButton,
  resetAddedFeeds,
  resetAddedUsers,
  onAttemptToCancel,
  onSubmit,
}: TeamEditProps) => {
  useOnClickOutside(containerRef, onAttemptToCancel);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean | undefined>(undefined);
  const [name, setName] = useState<string>(team.name);
  const [teamFeeds, setTeamFeeds] = useState<TeamFeed[]>(team.teamFeeds ?? []);
  const [users, setUsers] = useState<User[]>(team.users ?? []);

  const canSubmit = isDirty && name;

  useEffect(() => {
    if (isDirty === undefined) {
      setIsDirty(false);
    } else {
      setIsDirty(true);
    }
  }, [name, teamFeeds, users]);

  useEffect(() => {
    if (addedFeeds.length) {
      const currentFeedIds = teamFeeds.map((tf) => tf.feedId);
      const finalAddedTeamFeeds = addedFeeds
        .filter((f) => !currentFeedIds.includes(Number(f.id)))
        .map(
          (f) =>
            ({
              feedId: Number(f.id),
              feedName: f.name,
              teamId: team.id,
            } as TeamFeed)
        );
      setTeamFeeds(teamFeeds.concat(finalAddedTeamFeeds));
      resetAddedFeeds();
    }
  }, [addedFeeds]);

  useEffect(() => {
    if (addedUsers.length) {
      const currentUserIds = users.map((u) => u.id);
      const finalAddedUsers = addedUsers
        .filter((u) => !currentUserIds.includes(Number(u.id)))
        .map(
          (u) =>
            ({
              id: Number(u.id),
              firstName: u.firstName,
              lastName: u.lastName,
            } as User)
        );
      setUsers(users.concat(finalAddedUsers));
      resetAddedUsers();
    }
  }, [addedUsers]);

  const handleRemoveTeamFeed = useCallback(
    (teamFeed: TeamFeed, from: TeamFeed[]) => {
      const newTeamFeeds = from.filter(
        (tf: TeamFeed) => tf.feedId !== teamFeed.feedId
      );
      setTeamFeeds(newTeamFeeds);
    },
    []
  );

  const handleRemoveUser = useCallback((user: User, from: User[]) => {
    const newUsers = from.filter((u: User) => u.id !== user.id);
    setUsers(newUsers);
  }, []);

  const handleConfirmEdit = useCallback(async () => {
    if (canSubmit) {
      setIsLoading(true);
      const feedIds = teamFeeds.map((tf) => Number(tf.feedId));
      const userIds = users.map((u) => Number(u.id));
      const editedTeam: Team = {
        ...team,
        name,
        feedIds,
        userIds,
        users,
      };
      await onSubmit(editedTeam);
      setIsLoading(false);
    }
  }, [canSubmit, name, teamFeeds, users, team]);

  const feedList = useMemo(
    () =>
      teamFeeds.length ? (
        <FlexContainer
          id="TeamManagementEditFeedList"
          style={{ marginTop: '16px', gap: '8px' }}
        >
          {teamFeeds.map((tf) => (
            <ClosableBlueChip
              id={`TeamManagementEditFeedListBlueChip${tf.feedId}`}
              key={tf.feedId}
              isSelected
              onClickCloseButton={() => handleRemoveTeamFeed(tf, teamFeeds)}
              style={{
                maxHeight: '28px',
              }}
            >
              {tf.feedName}
            </ClosableBlueChip>
          ))}
        </FlexContainer>
      ) : (
        <FlexContainer id="TeamManagementEditFeedList" />
      ),
    [teamFeeds]
  );

  const userList = useMemo(
    () =>
      users.length ? (
        <FlexContainer
          id="TeamManagementEditUserList"
          style={{ marginTop: '16px', gap: '8px' }}
        >
          {users.map((u) => (
            <ClosableBlueChip
              id={`TeamManagementEditUserListBlueChip${u.id}`}
              key={u.id}
              isSelected
              onClickCloseButton={() => handleRemoveUser(u, users)}
              style={{
                maxHeight: '28px',
              }}
            >
              {`${u.firstName} ${u.lastName}`}
            </ClosableBlueChip>
          ))}
        </FlexContainer>
      ) : (
        <FlexContainer id="TeamManagementEditUserList" />
      ),
    [users]
  );

  const sections: ManagementSectionProps[] = [
    {
      children: [
        <TitleValue
          id="TeamManagementEditName"
          key="EditName"
          title="Team name"
          value={
            <Input
              id="id-edit-name"
              placeholder="Enter name..."
              value={name}
              setValue={setName}
              style={{
                marginTop: '4px',
                borderColor: name ? Theme.colors.grayBorder : Theme.colors.red,
              }}
            />
          }
        />,
      ],
    },
    {
      children: [
        <TitleValue
          id="TeamManagementEditFeeds"
          key="EditFeeds"
          title="Development feeds"
          count={teamFeeds.length}
          buttonIcon={<AddIcon />}
          onClickButton={onClickAddFeedsButton}
          value={feedList}
        />,
      ],
    },
    {
      children: [
        <TitleValue
          id="TeamManagementEditUsers"
          key="EditUsers"
          title="Members"
          count={team.usersCount}
          buttonIcon={<AddIcon />}
          onClickButton={onClickAddUsersButton}
          value={userList}
        />,
      ],
    },
  ];

  const buttons = [
    <DetailButton
      id="TeamManagementEditCancelButton"
      key="Cancel"
      label="Cancel"
      icon={<CancelIcon />}
      color={Theme.colors.red}
      onClick={onAttemptToCancel}
      removeDivider
    />,
    <DetailButton
      id="TeamManagementEditConfirmEditButton"
      key="ConfirmEdit"
      label="Confirm edit"
      icon={<ConfirmIcon />}
      color={Theme.colors.secondaryColor}
      onClick={canSubmit ? handleConfirmEdit : undefined}
      isDisabled={!canSubmit}
    />,
  ];

  return isHidden ? (
    <div />
  ) : (
    <ManagementDetail
      id="TeamManagementEdit"
      sections={sections}
      buttons={buttons}
      isLoading={isLoading}
    />
  );
};

interface TeamEditProps {
  team: Team;
  containerRef: MutableRefObject<any>;
  isHidden?: boolean;
  addedFeeds: Feed[];
  addedUsers: User[];
  onClickAddFeedsButton: () => void;
  onClickAddUsersButton: () => void;
  resetAddedFeeds: () => void;
  resetAddedUsers: () => void;
  onAttemptToCancel: () => void;
  onSubmit: (team: Team) => Promise<void>;
}
