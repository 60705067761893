import styled from '@emotion/styled';
import { MutableRefObject, ReactElement } from 'react';
import { Theme } from 'styles/themes';
import { ManagementLabels } from 'pages/management/interfaces/ManagementLabels';
import { ManagementDetailProps } from './ManagementDetail';
import { HTMLAttrID } from 'models';

const SiderbarContainer = styled.div({
  width: '30%',
  maxWidth: '345px',
  height: '100%',
  borderLeft: `solid 1px ${Theme.colors.grayBorder}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const PlaceholderContainer = styled.div({
  textAlign: 'center',
  fontSize: '16px',
  lineHeight: '22px',
});

const PlaceholderTitle = styled.div({
  fontWeight: 'bold',
  color: Theme.colors.primaryColor,
});

const SiderbarPlaceholder = (props: ManagementSidebarProps) => (
  <PlaceholderContainer id={`${props.id}Placeholder`}>
    <PlaceholderTitle id={`${props.id}PlaceholderTitle`}>
      <span>{props.placeholderTitle}</span>
    </PlaceholderTitle>
    <div>
      <span>{props.placeholderDescriptionFirstLine}</span>
    </div>
    <div>
      <span>{props.placeholderDescriptionSecondLine}</span>
    </div>
  </PlaceholderContainer>
);

export const ManagementSidebar = (props: ManagementSidebarProps) => {
  const content = props.children ?? <SiderbarPlaceholder {...props} />;
  return (
    <SiderbarContainer id={props.id} ref={props.containerRef}>
      {content}
    </SiderbarContainer>
  );
};

interface ManagementSidebarProps extends ManagementLabels, HTMLAttrID {
  children?: ReactElement<ManagementDetailProps> | string;
  containerRef?: MutableRefObject<any>;
}
