import { BlueChipList } from 'components/Chip';
import { Card } from 'components/Card';
import { User } from 'models';
import { Theme } from 'styles/themes';
import { BoldText } from 'styles/utils';
import { CollapsibleGridField } from './CollapsibleGridField';
import { Grid } from './Grid';
import { ReactComponent as TeamBiggerIcon } from 'assets/team-bigger.svg';

interface UserTeamsCardProps {
  user: User;
}

export const UserTeamsCard = (props: UserTeamsCardProps) => {
  const user = props.user;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '60%',
      }}
    >
      <Card>
        <span>
          Teams <BoldText>({user.teamsCount})</BoldText>
        </span>
        {!!user.teamsCount ? (
          user.teams &&
          user.teams.map((team) => {
            return (
              <Grid key={team.id} style={{ marginTop: '16px' }}>
                <CollapsibleGridField
                  title={
                    <>
                      <BoldText>{team.name}</BoldText>
                    </>
                  }
                />
                <CollapsibleGridField
                  title={
                    <>
                      <span>
                        Feeds assigned{' '}
                        <BoldText
                          style={{ color: Theme.colors.secondaryColor }}
                        >
                          ({team.feedsCount})
                        </BoldText>
                      </span>
                    </>
                  }
                  isCollapsible
                >
                  {!!team.teamFeeds && !!team.teamFeeds.length && (
                    <BlueChipList
                      id="ProfileUserTeamsCardFeedsAssigned"
                      items={team.teamFeeds}
                      getLabel={(teamFeed) => teamFeed.feedName}
                      hasFlexWrap
                      maxWidth={275}
                      inverseTooltipDirection
                    />
                  )}
                </CollapsibleGridField>
                <CollapsibleGridField
                  title={
                    <>
                      <span>
                        Members{' '}
                        <BoldText
                          style={{ color: Theme.colors.secondaryColor }}
                        >
                          ({team.usersCount})
                        </BoldText>
                      </span>
                    </>
                  }
                  isCollapsible
                >
                  {!!team.users && !!team.users.length && (
                    <BlueChipList
                      id="ProfileUserTeamsCardMembers"
                      items={team.users}
                      getLabel={(user) => user.fullName}
                      hasFlexWrap
                      maxWidth={275}
                      inverseTooltipDirection
                    />
                  )}
                </CollapsibleGridField>
                {/* <CollapsibleGridField
                  title={
                    <>
                      <span>
                        Developments completed{' '}
                        <BoldText
                          style={{ color: Theme.colors.secondaryColor }}
                        >
                          ({team.developmentsCompletedCount})
                        </BoldText>
                      </span>
                    </>
                  }
                /> */}
              </Grid>
            );
          })
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '25px 0',
            }}
          >
            <TeamBiggerIcon style={{ marginBottom: '20px' }} />
            <span>You're not part of any team yet.</span>
          </div>
        )}
      </Card>
    </div>
  );
};
