import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { FeedCardLeftSide } from './components/FeedCardLeftSide';
import { FeedCardRightSide } from './components/FeedCardRightSide';
import { HTMLAttributes, useMemo } from 'react';
import { Feed } from 'models';
import { useAppSelector } from 'store/hooks';
import { selectFeedPickerAppliedFeed } from 'store/feed/feed-picker-slice';

interface FeedCardProps extends HTMLAttributes<HTMLDivElement> {
  feed: Feed;
}

const FeedCardContainer = styled.div<{ isSelected?: boolean }>(
  ({ isSelected = false }) => ({
    width: '100%',
    height: '120px',
    borderBottom: `1px solid ${Theme.colors.grayBorder}`,
    display: 'flex',
    backgroundColor: isSelected
      ? Theme.colors.blueLightest
      : Theme.colors.white,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: Theme.colors.blueLightest,
    },
  })
);

export const FeedCard = (props: FeedCardProps) => {
  const selectedFeed = useAppSelector(selectFeedPickerAppliedFeed);

  const isFeedSelected = useMemo(() => {
    return props.feed.id === selectedFeed?.id;
  }, [selectedFeed]);

  return (
    <FeedCardContainer
      id={`FeedCard${props.feed.id}`}
      isSelected={isFeedSelected}
      onClick={props.onClick}
    >
      <FeedCardLeftSide
        id={props.feed.id}
        title={props.feed.name}
        newDevelopmentsCount={props.feed.newDevelopmentsCount || 0}
        isSelected={isFeedSelected}
      />
      <FeedCardRightSide feed={props.feed} />
    </FeedCardContainer>
  );
};
