import { Team } from 'models';
import { Theme } from 'styles/themes';
import { FlexContainer } from 'styles/utils';
import {
  BigTitleStatus,
  DetailButton,
  ManagementDetail,
  TitleValue,
} from 'pages/management/components';
import { BlueChip } from 'components/Chip';
import { ReactComponent as AddIcon } from 'assets/plus-circle.svg';
import { ReactComponent as DeleteIcon } from 'assets/delete-user.svg';
import { ReactComponent as EditIcon } from 'assets/edit-smaller.svg';
import { useCallback } from 'react';
import { setIsManagementTeamsDeleteConfirmationModalDisplayed } from 'store/management-team/management-team-slice';
import { useAppDispatch } from 'store/hooks';
import { ManagementSectionProps } from '../components/ManagementDetail';

export const TeamDetail = ({
  team,
  isLoading,
  onOpenAssingFeedsSidebar,
  onEditMode,
  onAddMemberClicked,
}: TeamDetailProps) => {
  const dispatch = useAppDispatch();

  const setIsDeleteConfirmationModalDisplayed = useCallback(
    (isDeleteConfirmationModalDisplayed: boolean) => {
      dispatch(
        setIsManagementTeamsDeleteConfirmationModalDisplayed(
          isDeleteConfirmationModalDisplayed
        )
      );
    },
    []
  );

  const feedList =
    team.teamFeeds && team.feedsCount ? (
      <FlexContainer
        id="TeamManagementDetailFeedList"
        style={{ marginTop: '16px', gap: '8px' }}
      >
        {team.teamFeeds.map((tf) => (
          <BlueChip
            id={`TeamManagementDetailFeedListBlueChip${tf.feedId}`}
            key={tf.feedId}
            style={{ fontSize: Theme.fontSize - 2, maxWidth: '275px' }}
          >
            {tf.feedName}
          </BlueChip>
        ))}
      </FlexContainer>
    ) : (
      <FlexContainer id="TeamManagementDetailFeedList" />
    );

  const userList =
    team.users && team.usersCount ? (
      <FlexContainer
        id="TeamManagementDetailUserList"
        style={{ marginTop: '16px', gap: '8px' }}
      >
        {team.users.map((u) => (
          <BlueChip
            id={`TeamManagementDetailUserListBlueChip${u.id}`}
            key={u.id}
            style={{ fontSize: Theme.fontSize - 2, maxWidth: '275px' }}
          >
            {`${u.firstName} ${u.lastName}`}
          </BlueChip>
        ))}
      </FlexContainer>
    ) : (
      <FlexContainer id="TeamManagementDetailUserList" />
    );

  const sections: ManagementSectionProps[] = [
    {
      children: [
        <BigTitleStatus
          id="TeamManagementDetailNameTitle"
          key="NameTitle"
          title={team.name}
        />,
      ],
    },
    {
      children: [
        <TitleValue
          id="TeamManagementDetailDevelopmentFeeds"
          key="DevelopmentFeeds"
          title="Development feeds"
          count={team.feedsCount ?? 0}
          buttonIcon={<AddIcon />}
          onClickButton={onOpenAssingFeedsSidebar}
          value={feedList}
        />,
      ],
    },
    {
      children: [
        <TitleValue
          id="TeamManagementDetailMembers"
          key="Members"
          title="Members"
          count={team.usersCount ?? 0}
          buttonIcon={<AddIcon />}
          onClickButton={onAddMemberClicked}
          value={userList}
        />,
      ],
    },
  ];

  const buttons = [
    <DetailButton
      id="TeamManagementDetailDeleteButton"
      key="Delete"
      label="Delete"
      icon={<DeleteIcon />}
      color={Theme.colors.red}
      onClick={() => setIsDeleteConfirmationModalDisplayed(true)}
      removeDivider
    />,
    <DetailButton
      id="TeamManagementDetailEditButton"
      key="Edit"
      label="Edit"
      icon={<EditIcon />}
      color={Theme.colors.secondaryColor}
      onClick={() => onEditMode(true)}
    />,
  ];

  return (
    <ManagementDetail
      id="TeamManagementDetail"
      sections={sections}
      buttons={buttons}
      isLoading={isLoading}
    />
  );
};

interface TeamDetailProps {
  team: Team;
  isLoading?: boolean;
  onOpenAssingFeedsSidebar: () => void;
  onEditMode: (value: boolean) => void;
  onAddMemberClicked: () => void;
}
