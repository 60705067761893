import { Theme } from 'styles/themes';

export enum UserStatus {
  InviteSent,
  Active,
  NoAccess,
}

export const UserStatusColorMap = {
  [UserStatus.Active]: 'black',
  [UserStatus.InviteSent]: 'orange',
  [UserStatus.NoAccess]: 'grayDark',
};

export const UserStatusColorValueMap = {
  [UserStatus.Active]: Theme.colors.black,
  [UserStatus.InviteSent]: Theme.colors.orange,
  [UserStatus.NoAccess]: Theme.colors.grayDark,
};
