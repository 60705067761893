import {
  useState,
  useEffect,
  CSSProperties,
  RefObject,
  HTMLAttributes,
} from 'react';
import { Alert } from 'components/Alert';
import styled from '@emotion/styled';
import { ReactComponent as TooltipArrowLeft } from 'assets/tooltip-arrow-left.svg';
import { ReactComponent as TooltipArrowDown } from 'assets/tooltip-arrow-down.svg';
import { HTMLAttrID } from 'models';
import { Theme } from 'styles/themes';

interface KeyChangePopoutProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  relativeToElement?: HTMLElement | null;
  hasArrow?: boolean;
  arrowDirection?: ArrowDirections;
  arrowFlipDirection?: ArrowDirections;
  style?: CSSProperties;
  cutterElement?: RefObject<Element>;
  isArrowOnRightCorner?: boolean;
}

export enum ArrowDirections {
  Left,
  Down,
  Up,
  Right,
}

const TooltipContainer = styled.div<{
  arrowDirection?: ArrowDirections;
  isOverflowAlreadyCalculated: boolean;
  isArrowOnRightCorner?: boolean;
}>((props) => {
  let left = '';
  let bottom = '';
  let top = '';
  let transform = '';
  let right = '';
  switch (props.arrowDirection) {
    case ArrowDirections.Left:
      bottom = '50%';
      left = 'calc(100% + 10px)';
      transform = 'translateY(50%)';
      break;
    case ArrowDirections.Down:
      bottom = 'calc(100%)';
      if (props.isArrowOnRightCorner) {
        right = '0';
      } else {
        left = '50%';
        transform = `translateX(-50%)`;
      }
      break;
    case ArrowDirections.Up:
      top = 'calc(100% + 10px)';
      if (props.isArrowOnRightCorner) {
        right = '0';
      } else {
        left = '50%';
        transform = `translateX(-50%)`;
      }
      break;
    case ArrowDirections.Right:
      bottom = '50%';
      right = 'calc(100% + 10px)';
      transform = 'translateY(50%)';
      break;
  }

  return {
    position: 'absolute',
    left,
    right,
    bottom,
    top,
    transform,
    zIndex: 999,
    visibility: props.isOverflowAlreadyCalculated ? 'visible' : 'hidden',
    boxShadow: `0 0 2px 2px ${Theme.colors.grayDark}`,
    borderRadius: Theme.borderRadius,
  };
});

const ArrowIcon = (
  props: {
    arrowDirection?: ArrowDirections;
    isArrowOnRightCorner?: boolean;
  } & HTMLAttrID
) => {
  switch (props.arrowDirection) {
    case ArrowDirections.Left:
      return (
        <TooltipArrowLeft
          id={props.id}
          style={{
            position: 'absolute',
            right: '100%',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        />
      );
    case ArrowDirections.Right:
      return (
        <TooltipArrowLeft
          id={props.id}
          style={{
            position: 'absolute',
            left: '100%',
            top: '50%',
            transform: 'translateY(-50%) rotate(180deg)',
          }}
        />
      );
    case ArrowDirections.Up:
      return (
        <TooltipArrowDown
          id={props.id}
          style={{
            position: 'absolute',
            bottom: '100%',
            right: props.isArrowOnRightCorner ? '1%' : '50%',
            transform: props.isArrowOnRightCorner
              ? 'rotate(180deg)'
              : 'translateX(55%) rotate(180deg)',
          }}
        />
      );
    case ArrowDirections.Down:
    default:
      return (
        <TooltipArrowDown
          id={props.id}
          style={{
            position: 'absolute',
            top: '100%',
            right: props.isArrowOnRightCorner ? '1%' : '50%',
            transform: props.isArrowOnRightCorner
              ? undefined
              : 'translateX(55%)',
          }}
        />
      );
  }
};

const AlertContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '8px 12px',
  backgroundColor: Theme.colors.white,
  borderRadius: Theme.borderRadius,
  color: Theme.colors.black,
  fontSize: Theme.fontSize - 2,
  lineHeight: '19px',
  zIndex: '2',
});

export const KeyChangePopout = (props: KeyChangePopoutProps) => {
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [tooltipContainer, setTooltipContainer] =
    useState<HTMLDivElement | null>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isOverflowAlreadyCalculated, setIsOverflowingAlreadyCalculated] =
    useState(false);

  useEffect(() => {
    if (props.relativeToElement) {
      props.relativeToElement.onmouseenter = () => {
        setIsDisplayed(true);
      };
      props.relativeToElement.onmouseleave = () => {
        setIsDisplayed(false);
      };
    }
    if (tooltipContainer) {
      if (
        props.cutterElement?.current &&
        getRect(tooltipContainer).top < getRect(props.cutterElement.current).top
      ) {
        setIsOverflowing(true);
      }
      setIsOverflowingAlreadyCalculated(true);
    }
  }, [props.relativeToElement, tooltipContainer]);

  const containerId = props.id ? `${props.id}Container` : undefined;
  const alertId = props.id ? `${props.id}Alert` : undefined;
  const arrowId = props.id ? `${props.id}Arrow` : undefined;

  const getRect = (element: Element) => element.getBoundingClientRect();

  return (
    <>
      {isDisplayed && (
        <TooltipContainer
          onMouseEnter={() => {
            setIsDisplayed(true);
          }}
          onMouseLeave={() => {
            setIsDisplayed(false);
          }}
          id={containerId}
          arrowDirection={
            isOverflowing ? props.arrowFlipDirection : props.arrowDirection
          }
          isOverflowAlreadyCalculated={isOverflowAlreadyCalculated}
          isArrowOnRightCorner={props.isArrowOnRightCorner}
          ref={setTooltipContainer}
        >
          <AlertContainer id={alertId} style={props.style}>
            {props.children}
          </AlertContainer>
        </TooltipContainer>
      )}
    </>
  );
};
