import {
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isToday,
  isWeekend,
} from 'date-fns';
import { map } from 'lodash/fp';
import { memo, useCallback, useMemo } from 'react';
import { Row } from '../Row/Row';
import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { DateRange } from 'utils/date';

export const Calendar = memo(
  ({
    matrix,
    currentDate,
    onClick,
    selectedDate,
    minimumDate,
    disableWeekends,
    dateRange,
    maximumDate,
  }: ICalendarProps) => {
    const isSelected = useCallback(
      (date: Date) => {
        return (
          (dateRange.startDate && isSameDay(dateRange.startDate, date)) ||
          (dateRange.endDate && isSameDay(dateRange.endDate, date))
        );
      },
      [dateRange, selectedDate]
    );

    const isInRange = useCallback(
      (date: Date) => {
        return (
          dateRange.startDate &&
          isAfter(date, dateRange.startDate) &&
          dateRange.endDate &&
          isBefore(date, dateRange.endDate)
        );
      },
      [dateRange]
    );

    const isActive = useCallback(
      (date: Date) => {
        return (
          (!disableWeekends || !isWeekend(date)) &&
          isSameMonth(date, currentDate) &&
          ((minimumDate && !isBefore(date, minimumDate)) || !minimumDate) &&
          ((maximumDate && !isAfter(date, maximumDate)) || !maximumDate)
        );
      },
      [disableWeekends, currentDate, minimumDate, maximumDate]
    );

    const mapDayElem = useMemo(
      () =>
        map((x: Date) => (
          <DateContainer
            type="button"
            key={x.toISOString()}
            isActive={isActive(x)}
            isSelected={!!isSelected(x) && isActive(x)}
            isToday={isToday(x) && isActive(x)}
            isInRange={!!isInRange(x) && isActive(x)}
            onClick={() => {
              if (isActive(x)) {
                onClick(x);
              }
            }}
          >
            {x.getDate()}
          </DateContainer>
        )),
      [
        selectedDate,
        onClick,
        currentDate,
        minimumDate,
        maximumDate,
        disableWeekends,
      ]
    );

    const mapDaysRow = map((r: Date[]) => (
      <Row key={r[0].toISOString()}>{mapDayElem(r)}</Row>
    ));

    return <Container>{mapDaysRow(matrix)}</Container>;
  }
);

const Container = styled.div({
  borderTop: `1px solid ${Theme.colors.grayBorder}`,
  borderRadius: Theme.borderRadius,
  padding: 12,
});

const getColor = (props: IDateContainerProps) => {
  switch (true) {
    case props.isSelected:
      return Theme.colors.white;
    case props.isInRange:
      return Theme.colors.secondaryColor;
    case props.isActive:
      return Theme.colors.grayDarkest;
    case props.isToday:
      return Theme.colors.grayDarkest;
    default:
      return Theme.colors.grayBorder;
  }
};

const DateContainer = styled.button<IDateContainerProps>((props) => {
  const color = getColor(props);

  const backgroundColor = props.isSelected
    ? Theme.colors.secondaryColor
    : props.isInRange
    ? `${Theme.colors.secondaryColor}0D`
    : Theme.colors.transparent;

  const shadowLength = props.isSelected ? 4 : 4;

  return {
    background: backgroundColor,
    border: 'none',
    borderRadius: props.isSelected ? 1 : 0,
    boxShadow:
      props.isSelected || props.isInRange
        ? `0 0 0 ${shadowLength}px ${backgroundColor}`
        : '0',
    color,
    cursor: 'pointer',
    fontSize: 16,
    fontWeight:
      props.isSelected || props.isInRange || (props.isToday && props.isActive)
        ? 'bold'
        : 'normal',
    height: '32px',
    outline: 'none',
    textAlign: 'center',
    width: '32px',
    zIndex: props.isSelected ? 0 : 1,

    '&:focus, &:active, &:hover': {
      outline: 'none',
    },
  };
});

interface IDateContainerProps {
  isActive: boolean;
  isSelected: boolean;
  isToday: boolean;
  isInRange: boolean;
}

interface ICalendarProps {
  matrix: Date[][];
  currentDate: Date;
  selectedDate?: Date;
  onClick: (date: Date) => void;
  minimumDate?: Date;
  maximumDate?: Date;
  disableWeekends?: boolean;
  dateRange: Partial<DateRange>;
}
