import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { getLanguagesWithDevelopmentCountGreaterThanZero } from 'services/languages/languages-service';
import { Language, PaginatedResponse } from 'models';
import { paginationConfig } from 'config/pagination-config';
import { filterKeys } from 'config/filter-keys';
import { isArraySubsetOfAnother } from 'utils/array';

export interface HomeSidebarFilterLanguageState {
  languages: Language[];
  totalRecords: number;
  searchTerm: string;
  page: number;
  previousPage: number;
  perPage: number;
  isConsecutiveFetch: boolean;
}

const initialState: HomeSidebarFilterLanguageState = {
  languages: [],
  totalRecords: 0,
  searchTerm: '',
  page: 1,
  previousPage: 1,
  perPage: paginationConfig.languagesPerPage,
  isConsecutiveFetch: false,
};

export const getAllHomeSidebarFilterLanguagesAsync = (): AppThunk<
  Promise<PaginatedResponse<Language> | null>
> => {
  return async (dispatch, getState) => {
    const selectedEnvironment = getState().environment.selectedEnvironment;
    const searchTerm = getState().homeSidebarFilterLanguage.searchTerm;
    const page = getState().homeSidebarFilterLanguage.page;
    const previousPage = getState().homeSidebarFilterLanguage.previousPage;
    const perPage = getState().homeSidebarFilterLanguage.perPage;
    const activeFilters = getState().homeSidebarFilter.activeFilters;
    const ids = getState().feedPicker.appliedFeed?.languageIds;
    const isConsecutiveFetch =
      getState().homeSidebarFilterLanguage.isConsecutiveFetch;

    const getLanguages = async (pageNumber: number) => {
      return await getLanguagesWithDevelopmentCountGreaterThanZero(
        selectedEnvironment,
        searchTerm,
        pageNumber,
        perPage,
        activeFilters,
        ids
      );
    };

    if (isConsecutiveFetch) {
      dispatch(setIsConsecutiveLanguagesFetch(false));
    }

    if (
      isConsecutiveFetch &&
      activeFilters[filterKeys.language] &&
      activeFilters[filterKeys.language].length &&
      !ids?.length
    ) {
      let currentPage = 0;
      let isLastPage = false;
      let allLanguageIds: number[] = [];
      let areAllCheckedFiltersDisplayed = false;
      let response;
      let incomingLanguages: Language[] = [];
      do {
        response = await getLanguages(++currentPage);
        if (!response.data) {
          return null;
        }
        isLastPage =
          currentPage >= Math.ceil(response.data.totalRecords / perPage);
        incomingLanguages = incomingLanguages.concat(response.data.records);
        allLanguageIds = allLanguageIds.concat(
          response.data.records.map((x) => x.id!)
        );
        areAllCheckedFiltersDisplayed = isArraySubsetOfAnother(
          activeFilters[filterKeys.language] as number[],
          allLanguageIds
        );
      } while (!areAllCheckedFiltersDisplayed && !isLastPage);
      response.data.records = incomingLanguages;
      dispatch(setHomeSidebarFilterLanguagesPage(currentPage));
      dispatch(setHomeSidebarFilterLanguages(response.data));
      return response.data;
    } else {
      const response = await getLanguages(page);
      if (page === 1) {
        dispatch(setHomeSidebarFilterLanguages(response.data));
      } else {
        if (page > previousPage) {
          dispatch(addHomeSidebarFilterLanguages(response.data));
        }
      }
      return response.data;
    }
  };
};

export const homeSidebarFilterLanguageSlice = createSlice({
  name: 'home-sidebar-filter-language',
  initialState,
  reducers: {
    setHomeSidebarFilterLanguages: (
      state,
      action: PayloadAction<PaginatedResponse<Language> | null>
    ) => {
      state.languages = action.payload?.records ?? [];
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    addHomeSidebarFilterLanguages: (
      state,
      action: PayloadAction<PaginatedResponse<Language> | null>
    ) => {
      state.languages = state.languages.concat(action.payload?.records ?? []);
      state.totalRecords = action.payload?.totalRecords ?? 0;
      state.previousPage = state.page;
    },
    setHomeSidebarFilterLanguagesSearchTerm: (
      state,
      action: PayloadAction<string>
    ) => {
      state.page = 1;
      state.searchTerm = action.payload;
    },
    setHomeSidebarFilterLanguagesPage: (
      state,
      action: PayloadAction<number>
    ) => {
      state.page = action.payload;
    },
    increaseHomeSidebarFilterLanguagesPage: (state) => {
      state.page = state.page + 1;
    },
    resetHomeSidebarFilterLanguagesPage: (state) => {
      state.page = 1;
      state.previousPage = 1;
    },
    setIsConsecutiveLanguagesFetch: (state, action: PayloadAction<boolean>) => {
      state.isConsecutiveFetch = action.payload;
    },
  },
});

export const {
  setHomeSidebarFilterLanguages,
  addHomeSidebarFilterLanguages,
  setHomeSidebarFilterLanguagesSearchTerm,
  setHomeSidebarFilterLanguagesPage,
  increaseHomeSidebarFilterLanguagesPage,
  resetHomeSidebarFilterLanguagesPage,
  setIsConsecutiveLanguagesFetch,
} = homeSidebarFilterLanguageSlice.actions;

export const selectHomeSidebarFilterLanguages = (state: RootState) =>
  state.homeSidebarFilterLanguage.languages;
export const selectHomeSidebarFilterLanguagesTotalRecords = (
  state: RootState
) => state.homeSidebarFilterLanguage.totalRecords;
export const selectHomeSidebarFilterLanguagesSearchTerm = (state: RootState) =>
  state.homeSidebarFilterLanguage.searchTerm;
export const selectHomeSidebarFilterLanguagesPage = (state: RootState) =>
  state.homeSidebarFilterLanguage.page;
export const selectHomeSidebarFilterLanguagesPerPage = (state: RootState) =>
  state.homeSidebarFilterLanguage.perPage;

export default homeSidebarFilterLanguageSlice.reducer;
