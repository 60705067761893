import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { ReactComponent as SortIconSVG } from 'assets/sort-arrow.svg';
import { SortByDirection } from 'enums';
import { useAppDispatch } from 'store/hooks';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { HTMLAttrID } from 'models';

interface SortByDirectionButtonProps extends HTMLAttrID {
  propertyToSortBy: string;
  currentPropertyBeingSorted?: string;
  currentDirectionBeingSorted?: SortByDirection;
  setSortByProperty: ActionCreatorWithPayload<string, string>;
  setSortByDirection: ActionCreatorWithPayload<SortByDirection, string>;
}

const SortIconContainer = styled.a<{ isActive: boolean }>((props) => ({
  color: props.isActive ? Theme.colors.secondaryColor : Theme.colors.black,
  margin: '2px 8px',
  display: 'inline-flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: 14,
  cursor: 'pointer',
}));

interface SortIconProps {
  color?: string;
  direction?: 'up' | 'down';
}

const SortIcon = styled(SortIconSVG)<SortIconProps>((props) => ({
  color: props.color || Theme.colors.grayBorder,
  transform: props.direction !== 'up' ? 'rotate(180deg)' : '',
}));

export const SortByDirectionButton = (props: SortByDirectionButtonProps) => {
  const dispatch = useAppDispatch();
  const isActive = props.propertyToSortBy === props.currentPropertyBeingSorted;
  const defaultColor = isActive
    ? Theme.colors.secondaryColorLight
    : Theme.colors.grayBorder;

  const changeSortByDirection = () => {
    if (!isActive) {
      dispatch(props.setSortByProperty(props.propertyToSortBy));
      dispatch(props.setSortByDirection(SortByDirection.DESC));
    } else {
      let newDirection: SortByDirection | null = null;
      switch (props.currentDirectionBeingSorted) {
        case SortByDirection.DESC:
          newDirection = SortByDirection.ASC;
          break;
        case SortByDirection.ASC:
          newDirection = SortByDirection.DESC;
          break;
      }
      if (newDirection) {
        dispatch(props.setSortByDirection(newDirection));
      }
    }
  };

  return (
    <SortIconContainer
      id={`${props.id}Container`}
      onClick={changeSortByDirection}
      isActive={isActive}
    >
      <SortIcon
        id={`${props.id}SortIconUp`}
        color={
          props.currentDirectionBeingSorted === SortByDirection.ASC && isActive
            ? Theme.colors.secondaryColor
            : defaultColor
        }
        direction="up"
      />
      <SortIcon
        id={`${props.id}SortIconDown`}
        color={
          props.currentDirectionBeingSorted === SortByDirection.DESC && isActive
            ? Theme.colors.secondaryColor
            : defaultColor
        }
        direction="down"
      />
    </SortIconContainer>
  );
};
