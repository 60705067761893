import styled from '@emotion/styled';
import { Theme } from 'styles/themes';

export const Grid = styled.div({
  width: '100%',
  borderTop: `1px solid ${Theme.colors.grayBorder}`,
  borderRight: `1px solid ${Theme.colors.grayBorder}`,
  borderLeft: `1px solid ${Theme.colors.grayBorder}`,
  borderRadius: Theme.borderRadius,
});
