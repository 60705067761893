import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { getSubcategoriesWithDevelopmentCountGreaterThanZero } from 'services/subcategories/subcategories-service';
import { Subcategory, PaginatedResponse } from 'models';
import { paginationConfig } from 'config/pagination-config';
import { filterKeys } from 'config/filter-keys';
import { isArraySubsetOfAnother } from 'utils/array';

export interface HomeSidebarFilterSubcategoryState {
  subcategories: Subcategory[];
  totalRecords: number;
  searchTerm: string;
  page: number;
  previousPage: number;
  perPage: number;
  isConsecutiveFetch: boolean;
}

const initialState: HomeSidebarFilterSubcategoryState = {
  subcategories: [],
  totalRecords: 0,
  searchTerm: '',
  page: 1,
  previousPage: 1,
  perPage: paginationConfig.subcategoriesPerPage,
  isConsecutiveFetch: false,
};

export const getAllHomeSidebarFilterSubcategoriesAsync = (): AppThunk<
  Promise<PaginatedResponse<Subcategory> | null>
> => {
  return async (dispatch, getState) => {
    const selectedEnvironment = getState().environment.selectedEnvironment;
    const searchTerm = getState().homeSidebarFilterSubcategory.searchTerm;
    const page = getState().homeSidebarFilterSubcategory.page;
    const previousPage = getState().homeSidebarFilterSubcategory.previousPage;
    const perPage = getState().homeSidebarFilterSubcategory.perPage;
    const activeFilters = getState().homeSidebarFilter.activeFilters;
    const ids = getState().feedPicker.appliedFeed?.subcategoryIds;
    const isConsecutiveFetch =
      getState().homeSidebarFilterSubcategory.isConsecutiveFetch;

    const getSubcategories = async (pageNumber: number) => {
      return await getSubcategoriesWithDevelopmentCountGreaterThanZero(
        selectedEnvironment,
        searchTerm,
        pageNumber,
        perPage,
        activeFilters,
        ids
      );
    };

    if (isConsecutiveFetch) {
      dispatch(setIsConsecutiveSubcategoriesFetch(false));
    }

    if (
      isConsecutiveFetch &&
      activeFilters[filterKeys.subcategory] &&
      activeFilters[filterKeys.subcategory].length &&
      !ids?.length
    ) {
      let currentPage = 0;
      let isLastPage = false;
      let allSubcategoryIds: number[] = [];
      let areAllCheckedFiltersDisplayed = false;
      let response;
      let incomingSubcategories: Subcategory[] = [];
      do {
        response = await getSubcategories(++currentPage);
        if (!response.data) {
          return null;
        }
        isLastPage =
          currentPage >= Math.ceil(response.data.totalRecords / perPage);
        incomingSubcategories = incomingSubcategories.concat(
          response.data.records
        );
        allSubcategoryIds = allSubcategoryIds.concat(
          response.data.records.map((x) => x.id!)
        );
        areAllCheckedFiltersDisplayed = isArraySubsetOfAnother(
          activeFilters[filterKeys.subcategory] as number[],
          allSubcategoryIds
        );
      } while (!areAllCheckedFiltersDisplayed && !isLastPage);
      response.data.records = incomingSubcategories;
      dispatch(setHomeSidebarFilterSubcategoriesPage(currentPage));
      dispatch(setHomeSidebarFilterSubcategories(response.data));
      return response.data;
    } else {
      const response = await getSubcategories(page);
      if (page === 1) {
        dispatch(setHomeSidebarFilterSubcategories(response.data));
      } else {
        if (page > previousPage) {
          dispatch(addHomeSidebarFilterSubcategories(response.data));
        }
      }
      return response.data;
    }
  };
};

export const homeSidebarFilterSubcategorySlice = createSlice({
  name: 'home-sidebar-filter-subcategory',
  initialState,
  reducers: {
    setHomeSidebarFilterSubcategories: (
      state,
      action: PayloadAction<PaginatedResponse<Subcategory> | null>
    ) => {
      state.subcategories = action.payload?.records ?? [];
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    addHomeSidebarFilterSubcategories: (
      state,
      action: PayloadAction<PaginatedResponse<Subcategory> | null>
    ) => {
      state.subcategories = state.subcategories.concat(
        action.payload?.records ?? []
      );
      state.totalRecords = action.payload?.totalRecords ?? 0;
      state.previousPage = state.page;
    },
    setHomeSidebarFilterSubcategoriesSearchTerm: (
      state,
      action: PayloadAction<string>
    ) => {
      state.page = 1;
      state.searchTerm = action.payload;
    },
    setHomeSidebarFilterSubcategoriesPage: (
      state,
      action: PayloadAction<number>
    ) => {
      state.page = action.payload;
    },
    increaseHomeSidebarFilterSubcategoriesPage: (state) => {
      state.page = state.page + 1;
    },
    resetHomeSidebarFilterSubcategoriesPage: (state) => {
      state.page = 1;
      state.previousPage = 1;
    },
    setIsConsecutiveSubcategoriesFetch: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isConsecutiveFetch = action.payload;
    },
  },
});

export const {
  setHomeSidebarFilterSubcategories,
  addHomeSidebarFilterSubcategories,
  setHomeSidebarFilterSubcategoriesSearchTerm,
  setHomeSidebarFilterSubcategoriesPage,
  increaseHomeSidebarFilterSubcategoriesPage,
  resetHomeSidebarFilterSubcategoriesPage,
  setIsConsecutiveSubcategoriesFetch,
} = homeSidebarFilterSubcategorySlice.actions;

export const selectHomeSidebarFilterSubcategories = (state: RootState) =>
  state.homeSidebarFilterSubcategory.subcategories;
export const selectHomeSidebarFilterSubcategoriesTotalRecords = (
  state: RootState
) => state.homeSidebarFilterSubcategory.totalRecords;
export const selectHomeSidebarFilterSubcategoriesSearchTerm = (
  state: RootState
) => state.homeSidebarFilterSubcategory.searchTerm;
export const selectHomeSidebarFilterSubcategorySearchTerm = (
  state: RootState
) => state.homeSidebarFilterSubcategory.searchTerm;
export const selectHomeSidebarFilterSubcategoriesPage = (state: RootState) =>
  state.homeSidebarFilterSubcategory.page;
export const selectHomeSidebarFilterSubcategoriesPerPage = (state: RootState) =>
  state.homeSidebarFilterSubcategory.perPage;

export default homeSidebarFilterSubcategorySlice.reducer;
