import {
  DevelopmentCitation,
  DevelopmentCitationMatchNormalizedCitation,
  HTMLAttrID,
  Obligation,
} from 'models';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Theme } from 'styles/themes';
import { CursorPointer, VerticalDivider } from 'styles/utils';
import { ReactComponent as ArrowDown } from 'assets/arrow-down-smaller.svg';
import { ReactComponent as ArrowUp } from 'assets/arrow-up-smaller.svg';
import { BlueChip, GreenChip } from 'components/Chip';
import {
  DevelopmentCitationMatchDetailsList,
  ObligationMatchesCard,
} from './components';
import { useAppSelector } from 'store/hooks';
import { selectDetailDevelopmentCitationsAreOnlyDirectMatchesDisplayed } from 'store/detail-development-citation/detail-development-citation-slice';

interface DevelopmentCitationCardProps extends HTMLAttrID {
  developmentCitation: DevelopmentCitation;
  selectedDevelopmentCitation: DevelopmentCitation | null;
  onClickDevelopmentCitation: (
    developmentCitation: DevelopmentCitation
  ) => void;
  triggerCollapseObligationMatchesCard: number;
}

export const DevelopmentCitationCard = (
  props: DevelopmentCitationCardProps
) => {
  const [
    isDevelopmentCitationPanelExpandButtonHovered,
    setIsDevelopmentCitationPanelExpandButtonHovered,
  ] = useState<boolean>(false);
  const [isObligationMatchesCardExpanded, setIsObligationMatchesCardExpanded] =
    useState<boolean>(false);
  const [
    isIndirectObligationMatchesCardExpanded,
    setIsIndirectObligationMatchesCardExpanded,
  ] = useState<boolean>(false);
  const [isCmsIdsListExpanded, setIsCmsIdsListExpanded] =
    useState<boolean>(false);
  const developmentCitationCardContainerRef = useRef<HTMLDivElement>(null);
  const areOnlyDirectMatchesDisplayed = useAppSelector(
    selectDetailDevelopmentCitationsAreOnlyDirectMatchesDisplayed
  );
  const [mainCmsColor, setMainCmsColor] = useState<string>(
    Theme.colors.cmsGray
  );

  const developmentCitationMatches = useMemo(() => {
    return props.developmentCitation.matches || [];
  }, [JSON.stringify(props.developmentCitation.matches)]);

  const developmentCitationMatchesCount = useMemo(() => {
    return developmentCitationMatches.length;
  }, [JSON.stringify(developmentCitationMatches)]);

  const anyDevelopmentCitationMatches = useMemo(() => {
    return !!developmentCitationMatchesCount;
  }, [developmentCitationMatchesCount]);

  const developmentCitationMatchNormalizedCitations = useMemo(() => {
    return (
      developmentCitationMatches.reduce(
        (a: DevelopmentCitationMatchNormalizedCitation[], b) =>
          a.concat(b.developmentCitationMatchNormalizedCitations || []),
        []
      ) || []
    );
  }, [JSON.stringify(developmentCitationMatches)]);

  const anyDevelopmentCitationNormalizedCitations = useMemo(() => {
    return !!developmentCitationMatchNormalizedCitations.length;
  }, [JSON.stringify(developmentCitationMatchNormalizedCitations)]);

  const anyDirectMatches = useMemo(() => {
    return !!developmentCitationMatchNormalizedCitations.filter(
      (x) => x.isDirectMatch
    ).length;
  }, [JSON.stringify(developmentCitationMatchNormalizedCitations)]);

  const anyIndirectMatches = useMemo(() => {
    return !!developmentCitationMatchNormalizedCitations.filter(
      (x) => !x.isDirectMatch
    ).length;
  }, [JSON.stringify(developmentCitationMatchNormalizedCitations)]);

  const directObligationMatchesCount = useMemo(() => {
    return (
      developmentCitationMatchNormalizedCitations
        ?.filter((x) => x.isDirectMatch)
        .reduce(
          (a: Obligation[], b) =>
            a.concat(b.normalizedCitation.obligations || []),
          []
        ).length || 0
    );
  }, [JSON.stringify(developmentCitationMatchNormalizedCitations)]);

  const indirectObligationMatchesCount = useMemo(() => {
    return (
      developmentCitationMatchNormalizedCitations
        ?.filter((x) => !x.isDirectMatch)
        .reduce(
          (a: Obligation[], b) =>
            a.concat(b.normalizedCitation.obligations || []),
          []
        ).length || 0
    );
  }, [JSON.stringify(developmentCitationMatchNormalizedCitations)]);

  const isSelectedDevelopmentCitation = useMemo(() => {
    return (
      props.selectedDevelopmentCitation?.id === props.developmentCitation.id
    );
  }, [props.selectedDevelopmentCitation?.id, props.developmentCitation.id]);

  const handleToggleExpandCmsIdsList = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      setIsCmsIdsListExpanded(!isCmsIdsListExpanded);
    },
    [isCmsIdsListExpanded]
  );

  useEffect(() => {
    setIsObligationMatchesCardExpanded(false);
  }, [props.triggerCollapseObligationMatchesCard]);

  useEffect(() => {
    if (isSelectedDevelopmentCitation) {
      developmentCitationCardContainerRef.current?.scrollIntoView(true);
      setIsObligationMatchesCardExpanded(true);
      if (!directObligationMatchesCount) {
        setIsIndirectObligationMatchesCardExpanded(true);
      }
    }
  }, [isSelectedDevelopmentCitation]);

  useEffect(() => {
    if (!anyDirectMatches && anyIndirectMatches) {
      setIsIndirectObligationMatchesCardExpanded(true);
    }
  }, [anyDirectMatches, anyIndirectMatches, isObligationMatchesCardExpanded]);

  return (
    <div
      id={props.id ? `${props.id}Container` : undefined}
      style={{
        width: '100%',
        border: `1px solid ${
          isSelectedDevelopmentCitation
            ? Theme.colors.secondaryColor
            : Theme.colors.grayBorder
        }`,
        padding: '8px',
        borderRadius: Theme.borderRadius,
        marginBottom: '12px',
        position: 'relative',
        cursor: 'pointer',
      }}
      onClick={() =>
        props.onClickDevelopmentCitation(props.developmentCitation)
      }
      ref={developmentCitationCardContainerRef}
    >
      <div
        id={props.id ? `${props.id}InnerContainer` : undefined}
        style={{ display: 'flex' }}
      >
        <VerticalDivider
          id={props.id ? `${props.id}VerticalDivider` : undefined}
          color={mainCmsColor}
          thickness={'2px'}
          style={{
            marginRight: '8px',
          }}
        />
        <div
          id={props.id ? `${props.id}ContentContainer` : undefined}
          style={{ width: 'calc(100% - 12px)' }}
        >
          <div
            id={props.id ? `${props.id}Content` : undefined}
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'flex-start',
              position: 'relative',
            }}
          >
            <div
              id={props.id ? `${props.id}ContentRightSideContainer` : undefined}
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                marginRight: '6px',
              }}
            >
              <div
                id={
                  props.id ? `${props.id}ContentCitationContainer` : undefined
                }
                style={{
                  width: anyDevelopmentCitationNormalizedCitations
                    ? 'calc(100% - 34px)'
                    : '100%',
                  marginBottom: '6px',
                }}
              >
                <span
                  id={props.id ? `${props.id}ContentCitationText` : undefined}
                  style={{
                    wordBreak: 'break-word',
                    fontSize: Theme.fontSize - 1,
                    fontWeight: 600,
                  }}
                >
                  {props.developmentCitation.citationName}
                </span>
              </div>
              <div
                id={props.id ? `${props.id}ContentCmsIdContainer` : undefined}
                style={{
                  width: '100%',
                  marginBottom: '4px',
                }}
              >
                {!anyDevelopmentCitationMatches && (
                  <div
                    id={
                      props.id
                        ? `${props.id}ContentCmsIdTextContainer`
                        : undefined
                    }
                    style={{
                      lineHeight: '24px',
                      width: '100%',
                    }}
                  >
                    <span
                      id={props.id ? `${props.id}ContentCmsIdText` : undefined}
                      style={{
                        fontSize: Theme.fontSize - 1,
                        color: Theme.colors.black,
                        wordBreak: 'break-word',
                        backgroundColor: Theme.colors.cmsGray,
                        borderRadius: Theme.borderRadius,
                        padding: '3px 4px',
                        marginRight: '4px',
                      }}
                    >
                      No CMS_ID
                    </span>
                  </div>
                )}
                {anyDevelopmentCitationMatches && (
                  <DevelopmentCitationMatchDetailsList
                    developmentCitationMatches={developmentCitationMatches}
                    isSelectedDevelopmentCitation={
                      isSelectedDevelopmentCitation
                    }
                    isCmsIdsListExpanded={isCmsIdsListExpanded}
                    setMainCmsColor={setMainCmsColor}
                  />
                )}
                {developmentCitationMatchesCount > 1 && (
                  <div>
                    <span
                      id={
                        props.id
                          ? `${props.id}ContentCmsIdExpansionChip`
                          : undefined
                      }
                      style={{
                        fontSize: Theme.fontSize - 4,
                        color: Theme.colors.black,
                        wordBreak: 'break-word',
                        backgroundColor: Theme.colors.cmsGray,
                        borderRadius: Theme.borderRadius,
                        padding: '6px 4px',
                        marginRight: '4px',
                        cursor: 'pointer',
                        display: 'inline-flex',
                        alignItems: 'center',
                      }}
                      onClick={handleToggleExpandCmsIdsList}
                    >
                      {!isCmsIdsListExpanded && (
                        <>
                          +{developmentCitationMatchesCount - 1}
                          <ArrowDown
                            id={
                              props.id
                                ? `${props.id}ContentCmsIdExpandButtonArrowDown`
                                : undefined
                            }
                            color={Theme.colors.black}
                            style={{
                              width: '8px',
                              height: '5px',
                              marginLeft: '4px',
                              flexShrink: 0,
                            }}
                          />
                        </>
                      )}
                      {isCmsIdsListExpanded && (
                        <ArrowDown
                          id={
                            props.id
                              ? `${props.id}ContentCmsIdCollapseButtonArrowDown`
                              : undefined
                          }
                          color={Theme.colors.black}
                          style={{
                            width: '8px',
                            height: '5px',
                            marginLeft: '4px',
                            flexShrink: 0,
                            transform: 'rotate(180deg) translate(1px)',
                          }}
                        />
                      )}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          {(anyDirectMatches || anyIndirectMatches) && (
            <div
              id={props.id ? `${props.id}MatchCountChipsContainer` : undefined}
              style={{ marginTop: '8px' }}
            >
              {anyDirectMatches && directObligationMatchesCount > 0 && (
                <GreenChip
                  id={props.id ? `${props.id}DirectMatchCountChip` : undefined}
                  style={{
                    fontSize: Theme.fontSize - 1,
                    fontWeight: 600,
                    backgroundColor: isSelectedDevelopmentCitation
                      ? Theme.colors.cmsGreenSelected
                      : Theme.colors.cmsGreen,
                    color: Theme.colors.black,
                  }}
                >
                  {directObligationMatchesCount} Direct OL
                </GreenChip>
              )}
              {anyIndirectMatches && indirectObligationMatchesCount > 0 && (
                <BlueChip
                  id={
                    props.id ? `${props.id}IndirectMatchCountChip` : undefined
                  }
                  style={{
                    fontSize: Theme.fontSize - 1,
                    fontWeight: 600,
                    marginTop: '8px',
                    backgroundColor: isSelectedDevelopmentCitation
                      ? Theme.colors.cmsBlueSelected
                      : Theme.colors.cmsBlue,
                    color: Theme.colors.black,
                  }}
                >
                  {indirectObligationMatchesCount} Indirect OL
                </BlueChip>
              )}
            </div>
          )}
          {isObligationMatchesCardExpanded && (
            <>
              {anyDirectMatches && (
                <ObligationMatchesCard
                  id={props.id ? `${props.id}DirectMatchesCard` : undefined}
                  developmentCitation={props.developmentCitation}
                  isSelected={isSelectedDevelopmentCitation}
                  areDirect
                />
              )}
              {anyIndirectMatches && (
                <>
                  {isIndirectObligationMatchesCardExpanded ? (
                    <ObligationMatchesCard
                      id={
                        props.id ? `${props.id}IndirectMatchesCard` : undefined
                      }
                      developmentCitation={props.developmentCitation}
                      isSelected={isSelectedDevelopmentCitation}
                      areDirect={false}
                    />
                  ) : (
                    <CursorPointer
                      id={
                        props.id
                          ? `${props.id}IndirectMatchesExpandButton`
                          : undefined
                      }
                      style={{
                        display: 'block',
                        color: Theme.colors.secondaryColor,
                        marginTop: '8px',
                        userSelect: 'none',
                      }}
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        e.stopPropagation();
                        setIsIndirectObligationMatchesCardExpanded(true);
                      }}
                    >
                      Expand indirect
                    </CursorPointer>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      {anyDevelopmentCitationNormalizedCitations && (
        <CursorPointer
          id={props.id ? `${props.id}ContentExpandButton` : undefined}
          style={{
            marginLeft: 'auto',
            backgroundColor:
              isDevelopmentCitationPanelExpandButtonHovered ||
              isObligationMatchesCardExpanded
                ? Theme.colors.primaryColor
                : Theme.colors.quaternaryBackgroundColor,
            borderRadius: Theme.borderRadius,
            padding: '2px',
            height: '24px',
            width: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: '0 0 24px',
            userSelect: 'none',
            position: 'absolute',
            top: '8px',
            right: '8px',
          }}
          onMouseOver={() =>
            setIsDevelopmentCitationPanelExpandButtonHovered(true)
          }
          onMouseLeave={() =>
            setIsDevelopmentCitationPanelExpandButtonHovered(false)
          }
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            setIsObligationMatchesCardExpanded(
              !isObligationMatchesCardExpanded
            );
            setIsIndirectObligationMatchesCardExpanded(false);
          }}
        >
          {isObligationMatchesCardExpanded ? (
            <ArrowUp
              id={
                props.id ? `${props.id}ContentExpandButtonArrowUp` : undefined
              }
              color={Theme.colors.white}
            />
          ) : (
            <ArrowDown
              id={
                props.id ? `${props.id}ContentExpandButtonArrowDown` : undefined
              }
              color={
                isDevelopmentCitationPanelExpandButtonHovered
                  ? Theme.colors.white
                  : Theme.colors.grayDarker
              }
            />
          )}
        </CursorPointer>
      )}
    </div>
  );
};
