import { useMemo, useRef, useState } from 'react';
import { Theme } from 'styles/themes';
import { BoldText, CursorPointer, FlexContainer } from 'styles/utils';
import { ReactComponent as ArrowDown } from 'assets/arrow-down.svg';
import { periodOptions } from 'config/report-period-options';
import {
  Dropdown,
  DropdownOption,
  DropdownOptionButton,
  DropdownOptionLabel,
  DropdownOptionsContainer,
} from 'components/Dropdown';
import styled from '@emotion/styled';
import { ArrowDirections, Tooltip } from 'components/Tooltip';
import { ReactComponent as Info } from 'assets/info.svg';

interface PeriodDropdownProps {
  periodProperty: string;
  setPeriodProperty: (period: string) => void;
  onSelectPeriod?: (period: string) => void;
  onSelectCustomPeriod?: () => void;
}

const TooltipContainer = styled.div({
  marginLeft: 'auto',
  position: 'relative',
  height: '20px',
});

export const PeriodDropdown = (props: PeriodDropdownProps) => {
  const [isPeriodDropdownOpen, setIsPeriodDropdownOpen] = useState(false);
  const periodDropdownContainerRef = useRef(null);
  const [tooltipContainerElement, setTooltipContainerElement] =
    useState<HTMLDivElement | null>(null);
  const [isTooltipHovered, setIsTooltipHovered] = useState(false);

  const period = useMemo(() => props.periodProperty, [props.periodProperty]);

  const pediodLabel = useMemo(() => {
    const option = periodOptions[period];
    return option ? option.text : periodOptions[0]?.text ?? period;
  }, [periodOptions, period]);

  return (
    <>
      <FlexContainer
        ref={periodDropdownContainerRef}
        style={{ alignItems: 'center', userSelect: 'none' }}
        flexWrap="nowrap"
      >
        <CursorPointer
          style={{ display: 'flex', margin: '0 8px 0 6px' }}
          onClick={() => setIsPeriodDropdownOpen(!isPeriodDropdownOpen)}
        >
          <BoldText>
            <span
              style={{ marginRight: '5px', color: Theme.colors.primaryColor }}
            >
              Show
            </span>
            <span
              style={{
                color: Theme.colors.secondaryColor,
              }}
            >
              {pediodLabel}
            </span>
          </BoldText>
        </CursorPointer>
        <div style={{ position: 'relative' }}>
          <CursorPointer
            onClick={() => setIsPeriodDropdownOpen(!isPeriodDropdownOpen)}
          >
            <div style={{ display: 'flex' }}>
              <ArrowDown style={{ color: Theme.colors.grayDarker }} />
            </div>
          </CursorPointer>
          <Dropdown
            isOpen={isPeriodDropdownOpen}
            setIsOpen={setIsPeriodDropdownOpen}
            containerRef={periodDropdownContainerRef}
            transform="translate(-100%, 15%)"
            style={{
              border: `1px solid ${Theme.colors.grayBorder}`,
              boxShadow: `0px 1px 15px ${Theme.colors.grayShadow}`,
            }}
          >
            <div style={{ width: '144px', color: Theme.colors.black }}>
              <FlexContainer flexDirection="column">
                <DropdownOptionsContainer style={{ width: '100%' }}>
                  {Object.keys(periodOptions).map((period, index) => {
                    const isFirst = index === 0;
                    const isLast = false;
                    return (
                      <DropdownOption
                        key={index}
                        style={{
                          height:
                            isFirst || (isLast && !props.onSelectCustomPeriod)
                              ? 46
                              : 38,
                        }}
                      >
                        <DropdownOptionButton
                          onClick={() => {
                            setIsPeriodDropdownOpen(false);
                            if (period) {
                              props.setPeriodProperty(period);
                            }
                            if (props.onSelectPeriod) {
                              props.onSelectPeriod(period);
                            }
                          }}
                          style={{
                            paddingTop: isFirst ? 16 : 8,
                            paddingBottom:
                              isLast && !props.onSelectCustomPeriod ? 16 : 8,
                          }}
                        >
                          <DropdownOptionLabel
                            style={{ height: 22, margin: 0 }}
                          >
                            {periodOptions[period].text}
                          </DropdownOptionLabel>
                        </DropdownOptionButton>
                      </DropdownOption>
                    );
                  })}
                  {props.onSelectCustomPeriod && (
                    <DropdownOption
                      key={'customPeriodOption'}
                      style={{ height: 46 }}
                    >
                      <DropdownOptionButton
                        onClick={() => {
                          setIsPeriodDropdownOpen(false);
                          props.onSelectCustomPeriod!();
                        }}
                        style={{
                          paddingTop: 8,
                          paddingBottom: 16,
                        }}
                      >
                        <DropdownOptionLabel style={{ height: 22, margin: 0 }}>
                          Custom
                        </DropdownOptionLabel>
                      </DropdownOptionButton>
                    </DropdownOption>
                  )}
                </DropdownOptionsContainer>
              </FlexContainer>
            </div>
          </Dropdown>
        </div>
      </FlexContainer>
      <TooltipContainer
        style={{ marginLeft: 12 }}
        ref={(tooltipContainer) => setTooltipContainerElement(tooltipContainer)}
      >
        <Tooltip
          relativeToElement={tooltipContainerElement}
          hasArrow
          arrowDirection={ArrowDirections.Up}
          isArrowOnRightCorner
        >
          <FlexContainer
            flexDirection="column"
            style={{
              width: '194px',
              height: '44px',
              fontSize: Theme.fontSize - 2,
              lineHeight: '22px',
              alignItems: 'center',
            }}
          >
            <span>The selected time period</span>
            <span>will define the data shown</span>
          </FlexContainer>
        </Tooltip>
        <Info
          onMouseOver={() => setIsTooltipHovered(true)}
          onMouseLeave={() => setIsTooltipHovered(false)}
          style={{
            color: isTooltipHovered
              ? Theme.colors.secondaryColor
              : Theme.colors.grayDark,
          }}
        />
      </TooltipContainer>
    </>
  );
};
