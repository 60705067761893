import { filterKeys } from 'config/filter-keys';
import { TurnaroundTimeFilter } from 'enums';
import { FilterEntry } from 'models';

export const getTurnaroundTimeFilters = (): FilterEntry[] => {
  return [
    {
      value: true,
      name: TurnaroundTimeFilter.WithinTurnaroundTime,
      propertyToFilterByOverride: filterKeys.isWithinRequiredTurnaroundTime,
    },
    {
      value: false,
      name: TurnaroundTimeFilter.OutsideTurnaroundTime,
      propertyToFilterByOverride: filterKeys.isWithinRequiredTurnaroundTime,
    },
    {
      value: undefined,
      name: TurnaroundTimeFilter.NotApplicable,
      propertyToFilterByOverride: filterKeys.isWithinRequiredTurnaroundTime,
    },
  ];
};
