import styled from '@emotion/styled';
import { Overlay } from 'components/Overlay';
import { Sidebar } from 'components/Sidebar';
import { Theme } from 'styles/themes';
import {
  CloseIconContainer,
  ModalInnerContainer,
  ModalTitle,
} from 'components/Modal';
import { ReactComponent as Close } from 'assets/close.svg';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { InfiniteList } from 'components/InfiniteList';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getAllReviewHistoryDevelopmentEventsAsync,
  increaseReviewHistoryDevelopmentEventsPage,
  selectReviewHistoryDevelopmentEvents,
  selectReviewHistoryDevelopmentEventsPage,
  selectReviewHistoryDevelopmentEventsPerPage,
  selectReviewHistoryDevelopmentEventsTotalRecords,
  selectTriggerReviewHistoryDevelopmentEventsUpdate,
} from 'store/review-history/review-history-slice';
import { Loading } from 'components/Loading';
import { DevelopmentEventCard } from '.';
import { OverflowYAutoContainer } from 'components/OverflowYAutoContainer';

export interface ReviewHistoryProps {
  isDisplayed: boolean;
  setIsDisplayed: Dispatch<SetStateAction<boolean>>;
  developmentId?: number;
  developmentProcessingDate?: string;
}

const ReviewHistoryContainer = styled.div({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  zIndex: 4,
  display: 'flex',
  justifyContent: 'end',
});

export const ReviewHistory = (props: ReviewHistoryProps) => {
  const dispatch = useAppDispatch();
  const developmentEvents = useAppSelector(
    selectReviewHistoryDevelopmentEvents
  );
  const totalRecords = useAppSelector(
    selectReviewHistoryDevelopmentEventsTotalRecords
  );
  const perPage = useAppSelector(selectReviewHistoryDevelopmentEventsPerPage);
  const page = useAppSelector(selectReviewHistoryDevelopmentEventsPage);
  const triggerDevelopmentEventsUpdate = useAppSelector(
    selectTriggerReviewHistoryDevelopmentEventsUpdate
  );
  const [scrollableContainer, setScrollableContainer] =
    useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (props.developmentId && props.developmentProcessingDate) {
      dispatch(
        getAllReviewHistoryDevelopmentEventsAsync(
          props.developmentId,
          props.developmentProcessingDate
        )
      );
    }
  }, [
    props.developmentId,
    props.developmentProcessingDate,
    page,
    triggerDevelopmentEventsUpdate,
  ]);

  const closeModal = () => {
    props.setIsDisplayed(false);
  };

  return (
    <>
      {props.isDisplayed && (
        <>
          <Overlay id="ReviewHistoryOverlay" onClick={() => closeModal()} />
          <ReviewHistoryContainer id="ReviewHistoryContainer">
            <Sidebar id="ReviewHistorySidebar">
              <ModalInnerContainer id="ReviewHistoryModalInnerContainer">
                <ModalTitle
                  id="ReviewHistoryModalTitle"
                  withoutMarginBottom={true}
                  color={Theme.colors.primaryColor}
                >
                  History
                </ModalTitle>
                <CloseIconContainer id="ReviewHistoryCloseIconContainer">
                  <Close
                    id="ReviewHistoryCloseIcon"
                    onClick={() => closeModal()}
                  />
                </CloseIconContainer>
              </ModalInnerContainer>
              <OverflowYAutoContainer
                id="ReviewHistory"
                ref={(element) => setScrollableContainer(element)}
                style={{ height: '100%', width: '100%' }}
              >
                {scrollableContainer && (
                  <InfiniteList
                    targetRef={scrollableContainer}
                    onReachBottom={() =>
                      dispatch(increaseReviewHistoryDevelopmentEventsPage())
                    }
                    hasMorePages={page < Math.ceil(totalRecords / perPage)}
                    loader={
                      <Loading id="ReviewHistoryDevelopmentEventListLoading" />
                    }
                  >
                    {developmentEvents.map((developmentEvent) => (
                      <DevelopmentEventCard
                        key={developmentEvent.id}
                        developmentEvent={developmentEvent}
                      />
                    ))}
                  </InfiniteList>
                )}
              </OverflowYAutoContainer>
            </Sidebar>
          </ReviewHistoryContainer>
        </>
      )}
    </>
  );
};
