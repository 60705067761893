import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ManagementDetail,
  DetailButton,
  TitleValue,
} from 'pages/management/components';
import { ReactComponent as CancelIcon } from 'assets/cancel.svg';
import { ReactComponent as ConfirmIcon } from 'assets/confirm-edit.svg';
import { useOnClickOutside } from 'hooks/use-on-click-outside';
import { Theme } from 'styles/themes';
import { Searchbar } from 'components/Searchbar';
import { FeedShortCard as FeedCard } from 'components/FeedShortCard';
import { feedManagementLabels } from '../feeds/labels';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectAssignToTeamSidebarFeeds,
  selectAssignToTeamSidebarFeedsTotalRecords,
  selectAssignToTeamSidebarFeedsPage,
  selectAssignToTeamSidebarFeedsPerPage,
  selectAssignToTeamSidebarFeedsSearchTerm,
  selectAssignToTeamSidebarSelectedFeeds,
  increaseAssignToTeamSidebarFeedsPage,
  setAssignToTeamSidebarFeedsSearchTerm,
  selectAssignToTeamSidebarFeed,
  resetAssignToTeamSidebarFeeds,
  resetAssignToTeamSidebarFeedsPage,
  resetAssignToTeamSidebarFeedsAll,
  getAllAssignToTeamSidebarFeedsAsync,
} from 'store/assign-to-team-sidebar-feed/assign-to-team-sidebar-feed-slice';
import { createFeedAsync } from 'store/feed/feed-thunks';
import { Team, Feed } from 'models';
import { OverflowYAutoContainer } from 'components/OverflowYAutoContainer';
import { InfiniteList } from 'components/InfiniteList';
import { addFeedsToTeamsAsync } from 'store/feed/management-feed-slice';
import { ManagementSectionProps } from '../components/ManagementDetail';

export const TeamAssignFeedSidebar = ({
  containerRef,
  withOptions,
  selectedTeams,
  areAllTeamsSelected,
  teamsSearchTerm,
  onAttemptToCancel,
  onAfterSubmit,
  onSubmit,
  onEditFeed,
}: TeamAssignFeedSidebarProps) => {
  const dispatch = useAppDispatch();
  const feeds = useAppSelector(selectAssignToTeamSidebarFeeds);
  const selectedFeeds = useAppSelector(selectAssignToTeamSidebarSelectedFeeds);
  const feedsSearchTerm = useAppSelector(
    selectAssignToTeamSidebarFeedsSearchTerm
  );
  const page = useAppSelector(selectAssignToTeamSidebarFeedsPage);
  const perPage = useAppSelector(selectAssignToTeamSidebarFeedsPerPage);
  const totalRecords = useAppSelector(
    selectAssignToTeamSidebarFeedsTotalRecords
  );

  useOnClickOutside(containerRef, onAttemptToCancel);

  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    dispatch(resetAssignToTeamSidebarFeedsAll());
    dispatch(getAllAssignToTeamSidebarFeedsAsync()).finally(() =>
      setIsLoading(false)
    );
  }, [selectedTeams, areAllTeamsSelected]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getAllAssignToTeamSidebarFeedsAsync()).finally(() =>
      setIsLoading(false)
    );
  }, [page, feedsSearchTerm]);

  const selectedFeedsCount = useMemo(
    () => Object.keys(selectedFeeds).length,
    [selectedFeeds]
  );

  const canSubmit = useMemo(() => !!selectedFeedsCount, [selectedFeedsCount]);

  const handleFeedsSearchTermChange = useCallback(
    (value: string) => dispatch(setAssignToTeamSidebarFeedsSearchTerm(value)),
    []
  );

  const handleSelectFeed = useCallback(
    (feed: Feed) => dispatch(selectAssignToTeamSidebarFeed(feed)),
    []
  );

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    if (onSubmit) {
      const feedsToSubmit = Object.values(selectedFeeds);
      onSubmit(feedsToSubmit);
      dispatch(resetAssignToTeamSidebarFeedsAll());
    } else {
      const mapNumberKey = (k: string) => Number(k);
      const selectedTeamIds = Object.keys(selectedTeams).map(mapNumberKey);
      const selectedFeedIds = Object.keys(selectedFeeds).map(mapNumberKey);
      const updatedFeeds = await dispatch(
        addFeedsToTeamsAsync(true, false, selectedTeamIds, selectedFeedIds)
      );
      if (updatedFeeds) {
        onAfterSubmit(updatedFeeds);
        dispatch(resetAssignToTeamSidebarFeedsAll());
      }
    }
    setIsLoading(false);
  }, [
    selectedFeeds,
    selectedTeams,
    areAllTeamsSelected,
    feedsSearchTerm,
    teamsSearchTerm,
  ]);

  const handleReachBottom = useCallback(() => {
    dispatch(increaseAssignToTeamSidebarFeedsPage());
  }, []);

  const handleOnEditFeed = useCallback(
    (feedId: number) => {
      if (onEditFeed) {
        const feed = feeds.find((f) => f.id === feedId);
        if (feed) {
          onEditFeed(feed);
        }
      }
    },
    [onEditFeed, feeds]
  );

  const handleOnDuplicate = useCallback(
    async (feedId: number) => {
      setIsLoading(true);
      const feed = feeds.find((f) => f.id === feedId);
      if (feed) {
        await dispatch(createFeedAsync(feed));
        dispatch(resetAssignToTeamSidebarFeeds());
        dispatch(resetAssignToTeamSidebarFeedsPage());
        if (page === 1) {
          await dispatch(getAllAssignToTeamSidebarFeedsAsync());
        }
      }
      setIsLoading(false);
    },
    [feeds, page]
  );

  const FeedCardsList = (
    <div
      id="TeamAssignFeedSidebarFeedCardsListContainer"
      style={{
        height: 'calc(100vh - 56px - 56px - 56px - 90px)',
      }}
    >
      <OverflowYAutoContainer
        id="TeamAssignFeedSidebarFeedCardsList"
        ref={scrollableContainerRef}
        style={{ height: '100%' }}
        isScrollbarInvisible
      >
        {!!feeds.length && scrollableContainerRef.current && (
          <InfiniteList
            targetRef={scrollableContainerRef.current}
            onReachBottom={handleReachBottom}
            hasMorePages={page < Math.ceil(totalRecords / perPage)}
          >
            {feeds.map((feed) => (
              <div
                id={`FeedShortCard${feed.id}Container`}
                key={feed.id}
                style={{ margin: '16px 0' }}
              >
                <FeedCard
                  id={`FeedShortCard${feed.id}`}
                  feed={feed}
                  isSelected={!!selectedFeeds[Number(feed.id)]}
                  onClick={() => handleSelectFeed(feed)}
                  options={
                    withOptions
                      ? {
                          onEditFeed: () => handleOnEditFeed(Number(feed.id)),
                          onDuplicate: () => handleOnDuplicate(Number(feed.id)),
                        }
                      : undefined
                  }
                />
              </div>
            ))}
          </InfiniteList>
        )}
      </OverflowYAutoContainer>
    </div>
  );

  const sections: ManagementSectionProps[] = [
    {
      children: [
        <TitleValue
          id="TeamAssignFeedSidebarSearchFeed"
          key="SearchFeed"
          title="Select feeds to assign"
          value={
            <div
              id="TeamAssignFeedSidebarSearchFeedSearchbarOuterContainer"
              style={{
                marginTop: '3px',
                borderRadius: Theme.borderRadius,
              }}
            >
              <Searchbar
                id="TeamAssignFeedSidebarSearchFeedSearchbar"
                value={feedsSearchTerm}
                setValue={handleFeedsSearchTermChange}
                placeholder={feedManagementLabels.searchBarPlaceholder}
              />
            </div>
          }
        />,
      ],
    },
    {
      children: [
        <TitleValue
          id="TeamAssignFeedSidebarFeedCardsList"
          key="FeedCardList"
          value={FeedCardsList}
        />,
      ],
      styles: { padding: '0 16px' },
    },
  ];

  const buttons = [
    <DetailButton
      id="TeamAssignFeedSidebarCancelButton"
      key="Cancel"
      label="Cancel"
      icon={<CancelIcon />}
      color={Theme.colors.red}
      onClick={onAttemptToCancel}
      removeDivider
    />,
    <DetailButton
      id="TeamAssignFeedSidebarConfirmButton"
      key="Confirm"
      label="Confirm"
      icon={<ConfirmIcon />}
      color={Theme.colors.secondaryColor}
      onClick={handleSubmit}
      isDisabled={!canSubmit}
    />,
  ];

  return (
    <ManagementDetail
      id="TeamAssignFeedSidebar"
      sections={sections}
      buttons={buttons}
      isLoading={isLoading}
    />
  );
};

interface TeamAssignFeedSidebarProps {
  containerRef: MutableRefObject<any>;
  withOptions?: boolean;
  selectedTeams: { [key: number]: Team };
  areAllTeamsSelected: boolean;
  teamsSearchTerm?: string;
  onAttemptToCancel: () => void;
  onEditFeed?: (feed: Feed) => void;
  onAfterSubmit: (feeds?: Feed[]) => void;
  onSubmit?: (feeds: Feed[]) => void;
}
