import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ManagementSidebar,
  ManagementHeader,
  ManagementSubHeader,
  ManagementMainBody,
  ManagementDropdown,
} from 'pages/management/components';
import { FlexContainer } from 'styles/utils';
import { feedManagementLabels } from './labels';
import {
  CreateFeedModal,
  CreateFeedModalTypes,
} from 'components/CreateFeedModal';
import { ReactComponent as AssignToTeamIcon } from 'assets/add-to-team.svg';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { ManagementDropdownOption } from '../components/ManagementDropdown';
import {
  AssignToTeamModal,
  AssignToTeamModalTypes,
} from 'components/AssignToTeamModal';
import { FeedsTable } from './components';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectManagementFeed,
  selectManagementFeeds,
  selectManagementFeedsPage,
  selectManagementFeedsTotalRecords,
  selectSelectedManagementFeeds,
  selectAllManagementFeeds,
  selectAreAllManagementFeedsSelected,
  getAllManagementFeedsAsync,
  selectManagementFeedsSortByProperty,
  selectManagementFeedsSortByDirection,
  selectManagementFeedsPerPage,
  setManagementFeedToEdit,
  setManagementFeedPage,
  selectTriggerManagementFeedsUpdate,
  selectManagementFeedsSearchTerm,
  setManagementFeedsSearchTerm,
  selectSelectedManagementFeedsOutsideOfCurrentPage,
  addSelectedManagementFeedsOutsideOfCurrentPage,
  removeSelectedManagementFeedsOutsideOfCurrentPage,
  deleteFeedsAsync,
  setTriggerManagementFeedsUpdate,
  removeSelectedManagementFeeds,
} from 'store/feed/management-feed-slice';
import { updateFeedAsync } from 'store/feed/feed-thunks';
import {
  getUserLastViewedFeedAsync,
  setSelectedFeed,
} from 'store/feed/feed-picker-slice';
import { Loading } from 'components/Loading';
import { Row } from 'components/Table/Table';
import { Feed, Team } from 'models';
import { Pagination } from 'components/Pagination';
import { FeedDetail } from './FeedDetail';
import { FeedEdit } from './FeedEdit';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { Body } from 'components/Typography';

export const FeedManagement = () => {
  const dispatch = useAppDispatch();
  const feedsList = useAppSelector(selectManagementFeeds);
  const totalRecords = useAppSelector(selectManagementFeedsTotalRecords);
  const searchTerm = useAppSelector(selectManagementFeedsSearchTerm);
  const areAllSelected = useAppSelector(selectAreAllManagementFeedsSelected);
  const selectedFeeds = useAppSelector(selectSelectedManagementFeeds);
  const sortByProperty = useAppSelector(selectManagementFeedsSortByProperty);
  const sortByDirection = useAppSelector(selectManagementFeedsSortByDirection);
  const triggerFeedsUpdate = useAppSelector(selectTriggerManagementFeedsUpdate);
  const selectedFeedsOutsideOfCurrentPage = useAppSelector(
    selectSelectedManagementFeedsOutsideOfCurrentPage
  );
  const page = useAppSelector(selectManagementFeedsPage);
  const perPage = useAppSelector(selectManagementFeedsPerPage);

  const [isAssignToTeamModalDisplayed, setIsAssignToTeamModalDisplayed] =
    useState(false);
  const [isCreateFeedModalDisplayed, setIsCreateFeedModalDisplayed] =
    useState(false);
  const onClickCreateButton = useCallback(
    () => setIsCreateFeedModalDisplayed(true),
    []
  );

  const sidebarContainerRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetailSidebar, setIsLoadingDetailSidebar] = useState(false);
  const [
    isDeleteConfirmationModalDisplayed,
    setIsDeleteConfirmationModalDisplayed,
  ] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [
    isDiscardConfirmationModalDisplayed,
    setIsDiscardConfirmationModalDisplayed,
  ] = useState(false);
  const [addedUpdatedFeedForEdit, setAddedUpdatedFeedForEdit] =
    useState<Feed | null>(null);
  const [addedTeamsForFeedEdit, setAddedTeamsForFeedEdit] = useState<Team[]>(
    []
  );

  useEffect(() => {
    setIsLoading(true);
    dispatch(getAllManagementFeedsAsync())
      .then((response) => {
        if (response && response.records) {
          dispatch(
            removeSelectedManagementFeedsOutsideOfCurrentPage(response.records)
          );
        }
      })
      .finally(() => setIsLoading(false));
  }, [page, searchTerm, sortByProperty, sortByDirection, triggerFeedsUpdate]);

  const totalPages = useMemo(
    () => Math.ceil(totalRecords / perPage),
    [totalRecords, perPage]
  );

  const feeds: Row<Feed>[] = useMemo(() => {
    return feedsList
      .map((f: Feed) => ({
        ...f,
        checked: areAllSelected !== !!selectedFeeds[f.id || 0],
      }))
      .filter((f) => f.id !== Number.MAX_SAFE_INTEGER);
  }, [feedsList, selectedFeeds, areAllSelected]);

  const feedsChecked = useMemo(
    () => Object.keys(selectedFeeds).length,
    [selectedFeeds]
  );

  const checkedFeeds = useMemo(
    () =>
      areAllSelected
        ? feeds.filter(
            (u) =>
              !Object.keys(selectedFeeds)
                .map((key) => Number(key))
                .includes(Number(u.id))
          )
        : Object.values(selectedFeeds),
    [selectedFeeds, areAllSelected, feeds]
  );

  const selectedFeedsCount = useMemo(
    () => (areAllSelected ? totalRecords - feedsChecked : feedsChecked),
    [areAllSelected, totalRecords, feedsChecked]
  );

  const areSomeFeedsChecked = useMemo(
    () => !!feedsChecked || areAllSelected,
    [areAllSelected, feedsChecked]
  );

  const singleSelectedFeed = useMemo(() => {
    if (selectedFeedsCount === 1) {
      if (checkedFeeds.length === 1) {
        return checkedFeeds[0];
      } else {
        if (selectedFeedsOutsideOfCurrentPage.length === 1) {
          return selectedFeedsOutsideOfCurrentPage[0];
        } else {
          return undefined;
        }
      }
    } else {
      return undefined;
    }
  }, [selectedFeedsCount, checkedFeeds, selectedFeedsOutsideOfCurrentPage]);

  const selectedFeedsText = useMemo(
    () =>
      selectedFeedsCount > 1
        ? `${selectedFeedsCount} feeds selected`
        : areAllSelected && selectedFeedsCount === 1
        ? '1 feed selected'
        : undefined,
    [selectedFeedsCount, areAllSelected]
  );

  const canBulkDelete = useMemo(
    () => selectedFeedsCount > 0,
    [selectedFeedsCount]
  );

  const handleSelectRow = useCallback((row: Row<Partial<Feed>>) => {
    dispatch(selectManagementFeed(row as Feed));
  }, []);

  const handleSelectAll = useCallback(() => {
    dispatch(selectAllManagementFeeds());
  }, []);

  const handleSearchTermChange = useCallback(
    (value: string) => dispatch(setManagementFeedsSearchTerm(value)),
    []
  );

  const handlePageChange = useCallback(
    (page: number) => {
      dispatch(addSelectedManagementFeedsOutsideOfCurrentPage(checkedFeeds));
      dispatch(setManagementFeedPage(page));
    },
    [checkedFeeds]
  );

  const handleEnterEditMode = useCallback(() => setIsEditing(true), []);

  const handleOpenEditFeedModal = useCallback(
    (customFeed?: Feed) => {
      if (singleSelectedFeed) {
        dispatch(setManagementFeedToEdit(customFeed || singleSelectedFeed));
        setIsCreateFeedModalDisplayed(true);
      }
    },
    [singleSelectedFeed]
  );

  const handleUpdateFeed = useCallback((submittedFeed: Feed | null) => {
    if (submittedFeed) {
      setAddedUpdatedFeedForEdit(submittedFeed);
    }
    return submittedFeed;
  }, []);

  const updateFeeds = useCallback(() => {
    dispatch(setTriggerManagementFeedsUpdate());
  }, []);

  const handleResetAddedUpdatedFeedsForEdit = useCallback(
    () => setAddedUpdatedFeedForEdit(null),
    []
  );

  const handleAddToTeamsForFeedEdit = useCallback(
    async (teams: Team[]) => setAddedTeamsForFeedEdit(teams),
    []
  );

  const handleResetAddedTeamsForFeedEdit = useCallback(
    () => setAddedTeamsForFeedEdit([]),
    []
  );

  const handleSubmitEditedFeed = useCallback(
    async (feed: Feed) => {
      if (isEditing) {
        setIsLoading(true);
        const updatedFeed = await dispatch(updateFeedAsync(feed));
        if (updatedFeed) {
          await dispatch(getAllManagementFeedsAsync()).finally(() => {
            if (singleSelectedFeed && updatedFeed) {
              dispatch(selectManagementFeed(singleSelectedFeed));
              dispatch(selectManagementFeed(updatedFeed));
            }
          });
          setIsEditing(false);
        }
        setIsLoading(false);
      }
    },
    [isEditing, singleSelectedFeed]
  );

  const handleDiscardChanges = useCallback(() => {
    setIsEditing(false);
    setIsDiscardConfirmationModalDisplayed(false);
  }, []);

  const handleCloseDiscardChangesModal = useCallback(
    () => setIsDiscardConfirmationModalDisplayed(false),
    []
  );

  const handleOpenDiscardChangesModal = useCallback(() => {
    if (
      !isCreateFeedModalDisplayed &&
      !isAssignToTeamModalDisplayed &&
      isEditing
    ) {
      setIsDiscardConfirmationModalDisplayed(true);
    }
  }, [isEditing, isCreateFeedModalDisplayed, isAssignToTeamModalDisplayed]);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalDisplayed(!isDeleteConfirmationModalDisplayed);
  }, [isDeleteConfirmationModalDisplayed]);

  const handleDeleteFeeds = useCallback(async () => {
    setIsLoadingDetailSidebar(true);
    setIsDeleteConfirmationModalDisplayed(false);
    await dispatch(deleteFeedsAsync());
    dispatch(getUserLastViewedFeedAsync()).then((feed) => {
      dispatch(setSelectedFeed(feed));
    });
    setIsLoadingDetailSidebar(false);
  }, []);

  const handleDeleteFeedsFromModal = useCallback(async () => {
    setIsLoadingDetailSidebar(true);
    dispatch(removeSelectedManagementFeeds());
    setIsEditing(false);
    dispatch(setTriggerManagementFeedsUpdate());
    dispatch(getUserLastViewedFeedAsync()).then((feed) => {
      dispatch(setSelectedFeed(feed));
    });
    setIsLoadingDetailSidebar(false);
  }, []);

  const dropdownOptions: ManagementDropdownOption[] = [
    {
      label: 'Assign to team',
      icon: <AssignToTeamIcon />,
      onClick: () => {
        setIsAssignToTeamModalDisplayed(true);
      },
    },
    {
      label: 'Delete',
      icon: <DeleteIcon />,
      onClick: () => setIsDeleteConfirmationModalDisplayed(true),
      isDisabled: !canBulkDelete,
    },
  ];

  return (
    <FlexContainer
      id="FeedManagementContainer"
      flexWrap="nowrap"
      flexDirection="column"
      style={{ width: '100%', flex: 1 }}
    >
      <ManagementHeader
        id="FeedManagementHeader"
        {...feedManagementLabels}
        onClickCreateButton={onClickCreateButton}
      />
      <ManagementSubHeader
        id="FeedManagementSubHeader"
        {...feedManagementLabels}
        count={totalRecords}
        selectedCount={selectedFeedsCount}
        searchTerm={searchTerm}
        onSearchChange={handleSearchTermChange}
      >
        <ManagementDropdown
          id="FeedManagementDropdown"
          title={feedManagementLabels.dropdownLabel}
          options={dropdownOptions}
          isDisabled={!selectedFeedsCount}
        />
      </ManagementSubHeader>
      <FlexContainer
        id="FeedManagementContentContainer"
        flexWrap="nowrap"
        flexDirection="row"
        style={{ flex: '1' }}
      >
        <ManagementMainBody id="FeedManagementMainBody">
          {isLoading && <Loading id="FeedManagementLoading" />}
          <FeedsTable
            rows={feeds}
            onSelectRow={handleSelectRow}
            onSelectAll={handleSelectAll}
            areSomeChecked={areSomeFeedsChecked}
            sortField={sortByProperty}
            sortDirection={sortByDirection}
          />
          <Pagination
            id="FeedManagementPagination"
            currentPage={page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </ManagementMainBody>
        <ManagementSidebar
          id="FeedManagementSidebar"
          {...feedManagementLabels}
          containerRef={sidebarContainerRef}
        >
          {(isEditing && singleSelectedFeed && (
            <FeedEdit
              containerRef={sidebarContainerRef}
              feed={singleSelectedFeed}
              updatedFeedFields={addedUpdatedFeedForEdit}
              addedTeams={addedTeamsForFeedEdit}
              onClickAddTeamsButton={() => {
                setIsAssignToTeamModalDisplayed(true);
              }}
              resetAddedTeams={handleResetAddedTeamsForFeedEdit}
              onClickEditFiltersButton={handleOpenEditFeedModal}
              resetUpdatedFeedFields={handleResetAddedUpdatedFeedsForEdit}
              onAttemptToCancel={handleOpenDiscardChangesModal}
              onSubmit={handleSubmitEditedFeed}
            />
          )) ||
            (singleSelectedFeed && (
              <FeedDetail
                feed={singleSelectedFeed}
                isLoading={isLoadingDetailSidebar}
                onClickAssignToTeams={() => {
                  setIsAssignToTeamModalDisplayed(true);
                }}
                onClickEdit={handleEnterEditMode}
                onClickDelete={handleToggleDeleteConfirmationModal}
              />
            )) ||
            selectedFeedsText}
        </ManagementSidebar>
      </FlexContainer>
      <CreateFeedModal
        isDisplayed={isCreateFeedModalDisplayed}
        setIsDisplayed={setIsCreateFeedModalDisplayed}
        onUpdate={handleUpdateFeed}
        onDelete={handleDeleteFeedsFromModal}
        onAfterSubmit={updateFeeds}
        type={CreateFeedModalTypes.FeedManagement}
      />
      <AssignToTeamModal
        isDisplayed={isAssignToTeamModalDisplayed}
        setIsDisplayed={setIsAssignToTeamModalDisplayed}
        type={
          isEditing
            ? AssignToTeamModalTypes.AddToTeam
            : AssignToTeamModalTypes.AssignToTeam
        }
        onAddToTeams={isEditing ? handleAddToTeamsForFeedEdit : undefined}
      />
      <ConfirmationModal
        id="DeleteFeedConfirmationModal"
        title={
          selectedFeedsCount > 1 ? 'Delete these feeds?' : 'Delete this feed?'
        }
        onPrimaryAction={handleToggleDeleteConfirmationModal}
        onSecondaryAction={handleDeleteFeeds}
        isDisplayed={isDeleteConfirmationModalDisplayed}
        primaryText="Cancel"
        secondaryText="Yes, delete"
      >
        <Body id="DeleteFeedConfirmationModalMessage">
          {selectedFeedsCount > 1 ? 'These feeds' : 'This feed'} will be
          permanently removed from the platform,{' '}
        </Body>
        <Body id="DeleteFeedConfirmationModalWarning" bold>
          {' '}
          this action can’t be undone.{' '}
        </Body>
        <Body id="DeleteFeedConfirmationModalAreYouSure">
          Are you sure you wish to delete?
        </Body>
      </ConfirmationModal>
      <ConfirmationModal
        id="DiscardFeedChangesConfirmationModal"
        title="Discard changes?"
        onPrimaryAction={handleCloseDiscardChangesModal}
        onSecondaryAction={handleDiscardChanges}
        isDisplayed={isDiscardConfirmationModalDisplayed}
        primaryText="Cancel"
        secondaryText="Yes, discard"
      >
        <Body id="DiscardFeedChangesConfirmationMessage">
          Exiting without confirming will discard any changes done.
        </Body>
      </ConfirmationModal>
    </FlexContainer>
  );
};
