import { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { Searchbar } from 'components/Searchbar';
import { ReactComponent as ArrowDownSmaller } from 'assets/arrow-down-smaller.svg';
import { Theme } from 'styles/themes';
import styled from '@emotion/styled';
import { Dropdown } from 'components/Dropdown';
import { OverflowYAutoContainer } from 'components/OverflowYAutoContainer';
import { InfiniteList } from 'components/InfiniteList';
import { Base, PaginatedResponse } from 'models';
import { SortByDirection } from 'enums';
import { HttpResponse } from 'utils/http/HttpResponse';
import { ReactComponent as Close } from 'assets/close.svg';

export interface SelectSearchbarProps {
  getAllRecords?: (
    searchTerm: string,
    sortByProperty: string,
    sortByDirection: SortByDirection,
    page: number,
    perPage: number
  ) => Promise<HttpResponse<PaginatedResponse<Base> | null>>;
  placeholder: string;
  recordsPropertyToDisplay: string;
  recordsPropertyToSortBy: string;
  values: any[];
  setValues: React.Dispatch<any[]>;
  preSelectedLabel?: string;
  perPage: number;
  isMultiselect?: boolean;
  isRawDataMode?: boolean;
  rawData?: { id: any; [x: string]: any }[];
  id?: string;
  characterWidthFactor?: number;
}

const SelectSearchbarContainer = styled.div({
  position: 'relative',
  width: '100%',
});

const RightIconContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  right: '12px',
  top: 0,
  bottom: 0,
  cursor: 'pointer',
});

const DropdownCard = styled.div<{
  cardHeight: number;
  isAlreadySelected: boolean;
}>((props) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: props.cardHeight,
  padding: '8px 16px',
  boxShadow: `inset 0px -1px 0px ${Theme.colors.grayBorder}`,
  cursor: props.isAlreadySelected ? 'default' : 'pointer',
  opacity: props.isAlreadySelected ? 0.5 : 1,
  wordBreak: 'break-all',
}));

const SelectedRecordChipsContainer = styled.div({
  position: 'absolute',
  top: 0,
  left: '30px',
  display: 'flex',
  alignItems: 'center',
  height: '32px',
});

const SelectedRecordChip = styled.div<{
  chipSidePadding: number;
  chipSideMargin: number;
}>((props) => ({
  border: `1px solid ${Theme.colors.grayBorder}`,
  borderRadius: '10px',
  margin: `0 ${props.chipSideMargin}px`,
  padding: `1px ${props.chipSidePadding}px`,
  backgroundColor: Theme.colors.white,
}));

export const SelectSearchbar: React.FC<SelectSearchbarProps> = (
  props: SelectSearchbarProps
) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const selectSearchbarContainerRef = useRef<HTMLDivElement>(null);
  const [scrollableContainer, setScrollableContainer] =
    useState<HTMLDivElement | null>(null);
  const cardHeight = 38;
  const paddingLeft = 28;
  const paddingRight = 32;
  const chipSidePadding = 8;
  const chipSideMargin = 4;
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(props.perPage);
  const [sortByProperty, setSortByProperty] = useState(
    props.recordsPropertyToSortBy
  );
  const [sortByDirection, setSortByDirection] = useState(SortByDirection.ASC);
  const [records, setRecords] = useState<any[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [widthLimit, setWidthLimit] = useState(Number.MAX_SAFE_INTEGER);
  const [paddingToAdd, setPaddingToAdd] = useState(paddingLeft);
  const placeholder = useMemo(() => {
    return props.isMultiselect && props.values && props.values.length > 0
      ? ''
      : props.placeholder;
  }, [props.values]);

  const onScrollableContainerRefChange = useCallback(
    async (scrollableContainer: HTMLDivElement) => {
      if (scrollableContainer) {
        setScrollableContainer(scrollableContainer);
        if (scrollableContainer?.parentElement) {
          setWidthLimit(
            scrollableContainer.parentElement?.clientWidth -
              (paddingLeft + paddingRight) -
              20
          );
        }
      }
    },
    []
  );

  const onChipsContainerRefChange = useCallback(
    async (chipsContainer: HTMLDivElement) => {
      if (chipsContainer) {
        setPaddingToAdd(chipsContainer.clientWidth + paddingLeft + 4);
      } else {
        setPaddingToAdd(paddingLeft);
      }
    },
    [selectedLabels]
  );

  const chipLimit = useMemo(() => {
    const getCharactersWidth = (charactersLength: number) => {
      return (
        charactersLength + charactersLength * (props.characterWidthFactor || 1)
      );
    };
    const chipPaddings = chipSidePadding * 2;
    const chipMargins = chipSideMargin * 2;
    const chipsWidths = selectedLabels.map(
      (x) => getCharactersWidth(x.length) + chipPaddings + chipMargins
    );
    const calculatedPlusChipWidth =
      getCharactersWidth(3) + chipPaddings + chipMargins;
    let chipsWidthsThatFitSum = paddingLeft + paddingRight;
    let chipsThatFit = 0;
    chipsWidths.every((chipWidth) => {
      chipsWidthsThatFitSum += chipWidth;
      const isWidthLimitExceeded =
        chipsWidthsThatFitSum + calculatedPlusChipWidth > widthLimit;
      if (!isWidthLimitExceeded) {
        chipsThatFit++;
      }
      return !isWidthLimitExceeded;
    });
    return chipsThatFit;
  }, [selectedLabels]);

  useEffect(() => {
    if (props.values && props.values.length && props.preSelectedLabel) {
      setSearchTerm(props.preSelectedLabel);
    }
  }, []);

  useEffect(() => {
    if (!props.isRawDataMode) {
      (async () => {
        if (props.getAllRecords) {
          const response = await props.getAllRecords(
            searchTerm,
            sortByProperty,
            sortByDirection,
            page,
            perPage
          );
          if (page > 1) {
            setRecords(records.concat(response.data?.records || []));
          } else {
            setRecords(response.data?.records || []);
          }
          setTotalRecords(response.data?.totalRecords || 0);
        }
      })();
    } else {
      if (props.rawData) {
        const start = (page - 1) * perPage;
        const end = page * perPage;
        const newRecords = [...props.rawData]
          .filter((x) =>
            String(x[props.recordsPropertyToDisplay])
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          )
          .sort((a, b) =>
            String(a[props.recordsPropertyToSortBy]).localeCompare(
              b[props.recordsPropertyToSortBy]
            )
          )
          .slice(start, end);
        if (page > 1) {
          setRecords(records.concat(newRecords));
        } else {
          setRecords(newRecords);
        }
        setTotalRecords(props.rawData.length);
      }
    }
  }, [page, searchTerm]);

  useEffect(() => {
    if (props.values && !props.values.length) {
      setSelectedLabels([]);
      setSearchTerm('');
    }
  }, [props.values]);

  const addSelectedRecord = (record: any) => {
    const id = record['id'];
    const label = String(record[props.recordsPropertyToDisplay]);
    if (props.isMultiselect) {
      setSearchTerm('');
      const valuesClone = [...props.values];
      valuesClone.push(id);
      props.setValues(valuesClone);
      setSelectedLabels([...selectedLabels].concat(label));
    } else {
      setSearchTerm(label);
      props.setValues([id]);
    }
  };

  const removeSelectedRecord = (record: any) => {
    const id = record['id'];
    if (props.isMultiselect) {
      const valuesClone = [...props.values];
      const index = valuesClone.indexOf(id);
      if (index > -1) {
        valuesClone.splice(index, 1);
      }
      props.setValues(valuesClone);
      const selectedLabelsClone = [...selectedLabels];
      selectedLabelsClone.splice(index, 1);
      setSelectedLabels(selectedLabelsClone);
    } else {
      setSearchTerm('');
      props.setValues([]);
    }
  };

  return (
    <SelectSearchbarContainer
      ref={selectSearchbarContainerRef}
      onClick={() => {
        if (
          props.isMultiselect ||
          (props.values && props.values.length === 0)
        ) {
          setIsDropdownOpen(!isDropdownOpen);
        }
      }}
    >
      <Searchbar
        id={props.id}
        value={searchTerm}
        setValue={(value) => {
          if (!props.isMultiselect) {
            props.setValues([]);
          }
          if (!isDropdownOpen) {
            setIsDropdownOpen(true);
          }
          setPage(1);
          setRecords([]);
          setTotalRecords(0);
          setSearchTerm(value);
        }}
        placeholder={placeholder}
        shouldDisplayCloseIcon={false}
        padding={`8px ${paddingRight}px 8px ${paddingToAdd}px`}
      />
      {props.isMultiselect && (
        <SelectedRecordChipsContainer ref={onChipsContainerRefChange}>
          {selectedLabels
            .filter((x, y) => y < chipLimit)
            .map((selectedLabel) => {
              return (
                <SelectedRecordChip
                  key={selectedLabel}
                  chipSidePadding={chipSidePadding}
                  chipSideMargin={chipSideMargin}
                >
                  {selectedLabel}
                </SelectedRecordChip>
              );
            })}
          {selectedLabels.length > chipLimit && (
            <SelectedRecordChip
              chipSidePadding={chipSidePadding}
              chipSideMargin={chipSideMargin}
            >
              +{selectedLabels.length - chipLimit}
            </SelectedRecordChip>
          )}
        </SelectedRecordChipsContainer>
      )}
      <RightIconContainer>
        {(props.isMultiselect || !props.values || !props.values.length) && (
          <ArrowDownSmaller style={{ color: Theme.colors.grayDarker }} />
        )}
        {!props.isMultiselect && props.values && !!props.values.length && (
          <Close
            onClick={() => {
              if (!props.isMultiselect) {
                props.setValues([]);
                setSearchTerm('');
              }
            }}
          />
        )}
      </RightIconContainer>
      {!!records && !!records.length && (
        <Dropdown
          isOpen={isDropdownOpen}
          setIsOpen={setIsDropdownOpen}
          containerRef={selectSearchbarContainerRef}
          transform="translate(-50%, 32px)"
          style={{
            border: `1px solid ${Theme.colors.grayBorder}`,
            width: '100%',
            boxShadow: '1px 1px 8px rgba(38, 38, 38, 0.1)',
            height: records.length * cardHeight + 2,
            maxHeight: (cardHeight * perPage) / 2,
          }}
        >
          <OverflowYAutoContainer
            ref={onScrollableContainerRefChange}
            style={{ height: '100%', width: '100%', margin: 'auto' }}
          >
            {scrollableContainer && (
              <InfiniteList
                targetRef={scrollableContainer}
                onReachBottom={() => setPage(page + 1)}
                hasMorePages={page < Math.ceil(totalRecords / perPage)}
              >
                {records.map((record) => {
                  const isAlreadySelected = props.values.includes(record['id']);

                  return (
                    <DropdownCard
                      key={record['id']}
                      cardHeight={cardHeight}
                      onClick={(e) => {
                        if (!isAlreadySelected) {
                          addSelectedRecord(record);
                        } else {
                          e.preventDefault();
                          e.stopPropagation();
                        }
                      }}
                      isAlreadySelected={isAlreadySelected}
                    >
                      <span>{record[props.recordsPropertyToDisplay]}</span>
                      {isAlreadySelected && (
                        <Close
                          style={{ marginLeft: 'auto', cursor: 'pointer' }}
                          onClick={(
                            e: React.MouseEvent<SVGSVGElement, MouseEvent>
                          ) => {
                            removeSelectedRecord(record);
                            e.stopPropagation();
                          }}
                        />
                      )}
                    </DropdownCard>
                  );
                })}
              </InfiniteList>
            )}
          </OverflowYAutoContainer>
        </Dropdown>
      )}
    </SelectSearchbarContainer>
  );
};
