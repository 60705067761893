import { ThemeProvider } from '@emotion/react';
import theme from '../../lib/styles/theme';
import styled from '@emotion/styled';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Abstract,
  KeyChanges,
  NewsBriefing,
  NewsBriefingResponse,
} from '../../lib/utils/Option';
import { ArrowDirections, Tooltip } from 'components/Tooltip';
import KeyChangeAbstract from 'components/KeyChangeAbstract/KeyChangeAbstract';

interface KeyChangesProps {
  jurisdictions: { [key: string]: NewsBriefing };
  toggle: () => void;
  selectedJurisdiction?: string;
  handleSetJurisdiction: (jurisdiction: string) => void;
  handleSelectedAbstract: (abstract: Abstract) => void;
  handleSelectedSummary: (summary: string) => void;
}

export interface AbstractDetails {
  id: number;
  title: string;
  issuingAuthority: string;
  effectiveDate?: string;
}

const OpenDevelopmentButton = styled.div({
  marginBottom: '10px',
  position: 'relative',
});

const KeyChangesContainer = styled.div({
  marginTop: '10px',
});

const KeyChangesJurisdictions: React.FC<KeyChangesProps> = ({
  jurisdictions,
  toggle,
  selectedJurisdiction,
  handleSetJurisdiction,
  handleSelectedAbstract,
  handleSelectedSummary,
}) => {
  const [selectedKeyChanges, setSelectedKeyChanges] = useState<KeyChanges[]>(
    () => {
      const keyChangesString = Object.entries(jurisdictions)[0][1].keyChanges;
      try {
        const parsedKeyChanges = JSON.parse(keyChangesString);
        return Array.isArray(parsedKeyChanges) ? parsedKeyChanges : [];
      } catch (error) {
        return [];
      }
    }
  );
  const [abstractDetails, setAbstractDetails] = useState<{
    [key: number]: AbstractDetails;
  }>();

  const handleSelectJurisdiction = useCallback(
    (jurisdiction: string, newsBriefing: NewsBriefing) => {
      const keyChanges: KeyChanges[] = JSON.parse(
        JSON.parse(newsBriefing.keyChanges)
      );
      handleSetJurisdiction(jurisdiction);
      setSelectedKeyChanges(keyChanges);
    },
    []
  );

  const handleSelectAbstract = (uniqueID: number) => {
    Object.entries(jurisdictions).map(([key, value]) => {
      if (key == selectedJurisdiction) {
        value.abstract.map((development) => {
          if (development.id == uniqueID) {
            handleSelectedAbstract(development);
            toggle();
          }
        });
      }
    });
  };

  useEffect(() => {
    setSelectedKeyChanges(() => {
      const keyChangesString = Object.entries(jurisdictions)[0][1].keyChanges;
      try {
        const parsedKeyChanges = JSON.parse(keyChangesString);
        return Array.isArray(parsedKeyChanges) ? parsedKeyChanges : [];
      } catch (error) {
        return [];
      }
    });
  }, [jurisdictions]);

  useEffect(() => {
    Object.entries(jurisdictions).map(([key, value]) => {
      if (key === selectedJurisdiction) {
        const summary = value?.summary || '';
        handleSelectedSummary(summary);
        try {
          const keyChanges =
            typeof value.keyChanges === 'string'
              ? JSON.parse(value.keyChanges)
              : value.keyChanges;
          setSelectedKeyChanges(keyChanges);
        } catch (error) {
          setSelectedKeyChanges([]); // Set an empty array if there's an error
        }
      }
    });
  }, [selectedJurisdiction]);

  useEffect(() => {
    if (jurisdictions && selectedJurisdiction) {
      const abstractData: { [key: number]: AbstractDetails } = {};
      Object.entries(jurisdictions).map(([key, value]) => {
        if (selectedJurisdiction === key) {
          let index = 1;
          value.abstract.map((abstract) => {
            abstractData[abstract.id] = {
              id: index,
              title: abstract.title,
              issuingAuthority: abstract.issuingAuthority.name,
              effectiveDate:
                abstract.effectiveDate &&
                abstract.effectiveDate[0].effectiveDate,
            };
            index++;
          });
        }
      });
      setAbstractDetails(abstractData);
    }
  }, [jurisdictions, selectedJurisdiction]);

  const handleGetAbstractData = useCallback(
    (uniqueId: number): AbstractDetails | undefined => {
      if (abstractDetails) {
        let result;
        Object.entries(abstractDetails).map(([key, value]) => {
          if (Number(key) === uniqueId) {
            result = value;
          }
        });
        return result;
      }
    },
    [abstractDetails]
  );

  return (
    <ThemeProvider theme={theme}>
      <KeyChangesContainer>
        {selectedKeyChanges.slice(0, 10).map((item, index) => (
          <OpenDevelopmentButton key={index}>
            <KeyChangeAbstract
              KeyChanges={item}
              index={index}
              handleSelectAbstract={handleSelectAbstract}
              uniqueId={item.metadata}
              abstract={handleGetAbstractData(item.metadata)}
            />
          </OpenDevelopmentButton>
        ))}
      </KeyChangesContainer>
    </ThemeProvider>
  );
};

export default KeyChangesJurisdictions;
