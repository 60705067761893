import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthenticationResponse, LoginCredentials } from 'models';
import { changeUserPassword, login } from 'services/auth/auth-service';
import { AppThunk, RootState } from '../store';
import { localStorageKeys } from 'config/local-storage-keys';
import { clearLocalStorageAndRedirectToLogin } from 'utils/auth';

export interface AuthenticationState {
  authenticationResponse: AuthenticationResponse | null;
}

const initialState: AuthenticationState = {
  authenticationResponse:
    JSON.parse(
      atob(
        localStorage.getItem(localStorageKeys.authenticationResponse) ||
          btoa('null')
      )
    ) || null,
};

export const loginAsync = (
  loginCredentials: LoginCredentials
): AppThunk<Promise<AuthenticationResponse | null>> => {
  return async (dispatch, getState) => {
    const response = await login(loginCredentials);
    return response.data;
  };
};

export const changeUserPasswordAsync = (
  currentPassword: string,
  newPassword: string,
  confirmedNewPassword: string
): AppThunk<Promise<AuthenticationResponse | null>> => {
  return async (dispatch, getState) => {
    const response = await changeUserPassword(
      currentPassword,
      newPassword,
      confirmedNewPassword
    );
    return response.data;
  };
};

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setAuthenticationResponse: (
      state,
      action: PayloadAction<AuthenticationResponse | null>
    ) => {
      const authenticationResponse = action.payload;
      localStorage.setItem(
        localStorageKeys.authenticationResponse,
        btoa(JSON.stringify(authenticationResponse))
      );
      state.authenticationResponse = authenticationResponse;
    },
    logout: (state) => {
      state.authenticationResponse = null;
      clearLocalStorageAndRedirectToLogin();
    },
  },
});

export const { setAuthenticationResponse, logout } =
  authenticationSlice.actions;

export const selectAuthenticationResponse = (state: RootState) =>
  state.authentication.authenticationResponse;

export default authenticationSlice.reducer;
