import styled from '@emotion/styled';
import { Theme } from 'styles/themes';

interface HeaderProps {
  hasBorderTop?: boolean;
  hasBorderBottom?: boolean;
  hasBorderRight?: boolean;
  hasBorderLeft?: boolean;
  padding?: string;
}

export const Header = styled.div((props: HeaderProps) => {
  const border = `1px solid ${Theme.colors.grayBorder}`;

  return {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    color: Theme.colors.primaryColor,
    backgroundColor: Theme.colors.white,
    borderTop: props.hasBorderTop ? border : 'none',
    borderBottom: props.hasBorderBottom ? border : 'none',
    borderRight: props.hasBorderRight ? border : 'none',
    borderLeft: props.hasBorderLeft ? border : 'none',
    padding: props.padding || '0 15px',
    flex: '0 0 56px',
  };
});
