import { FlexContainer } from 'styles/utils';
import { IndexReportEnvironmentBanner } from './components/EnvironmentBanner';
import { Environment } from 'models/Environment';
import { useState, useEffect } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectSelectedEnvironment } from 'store/environment/environment-slice';
import { EnvironmentsList } from 'models/reports/Source';

interface EnvironmentWrapperProps {
  children: JSX.Element;
  hasSuperadminRole: boolean;
  isIndexReport?: boolean;
  selectedIndexId: (id: number) => void;
  environmentsList: EnvironmentsList[];
}
const HorizonManagement: Environment = {
  id: 0,
  name: 'Horizon Management',
  organizationId: 0,
  organizationName: 'Horizon Management',
  elasticsearchIndexPrefix: '',
  canViewAllChannels: false,
  defaultsToAutomaticDevelopmentCitations: false,
  canViewReprocessedDevelopments: false,
};
export const IndexEnvironmentWrapper = ({
  isIndexReport = true,
  hasSuperadminRole,
  environmentsList,
  ...props
}: EnvironmentWrapperProps) => {
  const clientSelectedEnvironment = useAppSelector(selectSelectedEnvironment);
  const [selectedEnvironment, setSelectedEnvironment] =
    useState<Environment>(HorizonManagement);
  const handleSelectedEnvironment = (seletedEnv: Environment) => {
    props.selectedIndexId(seletedEnv.organizationId);
    setSelectedEnvironment(seletedEnv);
  };

  useEffect(() => {
    if (!hasSuperadminRole && clientSelectedEnvironment) {
      props.selectedIndexId(clientSelectedEnvironment.organizationId);
      setSelectedEnvironment(clientSelectedEnvironment);
    }
  }, [clientSelectedEnvironment]);

  return (
    <FlexContainer
      id="EnvironmentContainer"
      flexDirection="column"
      flexWrap="nowrap"
      style={{ width: '100%', height: '100%' }}
    >
      <IndexReportEnvironmentBanner
        selectedEnvironment={selectedEnvironment}
        handleSelectedEnvironment={handleSelectedEnvironment}
        environmentsList={environmentsList}
      />

      <FlexContainer
        id="MainLayoutContainer"
        style={{
          width: '100%',
          flex: 1,
        }}
      >
        {props.children}
      </FlexContainer>
    </FlexContainer>
  );
};
