import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { paginationConfig } from 'config/pagination-config';
import { PerformanceReportEntities, SortByDirection } from 'enums';
import {
  Development,
  Environment,
  PaginatedResponse,
  PerformanceReportEntity,
  Team,
} from 'models';
import {
  getAuthoritiesWithReportData,
  getAuthorityById,
} from 'services/authorities/authorities-service';
import {
  getCountriesWithReportData,
  getCountryById,
} from 'services/countries/countries-service';
import { getAllDevelopments } from 'services/developments/developments-service';
import {
  getDocumentTypeById,
  getDocumentTypesWithReportData,
} from 'services/document-types/document-types-service';
import {
  getCurrentDateMilliseconds,
  getSubtractionFromDateInMilliseconds,
} from 'utils/date';
import { HttpResponse } from 'utils/http/HttpResponse';
import { AppThunk, RootState } from '../store';
import { periodOptions } from 'config/report-period-options';
import {
  getAuthorityTeams,
  getCountryTeams,
  getDocumentTypeTeams,
} from 'services/teams/teams-service';
import { developmentKeys } from 'config/development-keys';

export interface PerformanceReportState {
  periodProperty: string;
  reportByProperty: PerformanceReportEntities;
  searchTerm: string;
  sortByProperty: string;
  sortByDirection: SortByDirection;
  page: number;
  previousPage: number;
  perPage: number;
  isReportDataFromAverageDevelopmentsPerDay: boolean;
  totalRecords: number;
  performanceRows: PerformanceReportEntity[];
  entityDetails?: PerformanceReportEntity;
  developments: Development[];
  developmentListPage: number;
  developmentListPerPage: number;
  developmentListTotalRecords: number;
  developmentListSearchTerm: string;
  developmentListSortByProperty: string;
  developmentListSortByDirection: SortByDirection;
  entityTeams: Team[];
  triggerDevelopmentListUpdate: number;
}

const initialState: PerformanceReportState = {
  periodProperty:
    new URLSearchParams(window.location.search).get('period') || 'sevenDays',
  reportByProperty: PerformanceReportEntities.Country,
  searchTerm: '',
  sortByProperty: 'newDevelopmentsCount',
  sortByDirection: SortByDirection.DESC,
  page: 1,
  previousPage: 1,
  perPage: paginationConfig.performanceReportRowsPerPage,
  totalRecords: 0,
  isReportDataFromAverageDevelopmentsPerDay: false,
  performanceRows: [],
  developments: [],
  developmentListPage: 1,
  developmentListPerPage: paginationConfig.performanceReportDevelopmentsPerPage,
  developmentListTotalRecords: 0,
  developmentListSearchTerm: '',
  developmentListSortByProperty: developmentKeys.processingDate,
  developmentListSortByDirection: SortByDirection.DESC,
  entityTeams: [],
  triggerDevelopmentListUpdate: 0,
};

export const getAllDataPerformanceReportAsync = (): AppThunk<
  Promise<PaginatedResponse<PerformanceReportEntity> | null>
> => {
  return async (dispatch, getState) => {
    const reportByProperty = getState().performanceReport.reportByProperty;
    const selectedEnvironment = getState().environment.selectedEnvironment;
    const searchTerm = getState().performanceReport.searchTerm;
    const page = getState().performanceReport.page;
    const previousPage = getState().performanceReport.previousPage;
    const perPage = getState().performanceReport.perPage;
    const isReportDataFromAverageDevelopmentsPerDay =
      getState().performanceReport.isReportDataFromAverageDevelopmentsPerDay;
    const sortByProperty = getState().performanceReport.sortByProperty;
    const sortByDirection = getState().performanceReport.sortByDirection;
    const period = getState().performanceReport.periodProperty;

    const periodInMilliseconds = getSubtractionFromDateInMilliseconds(
      new Date(),
      periodOptions[period].durationObject
    );

    let entityPromise: (
      searchTerm: string,
      sortByProperty: string,
      sortByDirection: SortByDirection,
      page: number,
      perPage: number,
      reportDataFromDateInMilliseconds: number,
      isReportDataFromAverageDevelopmentsPerDay: boolean,
      accessToken?: string
    ) => Promise<
      HttpResponse<PaginatedResponse<PerformanceReportEntity> | null>
    >;

    switch (reportByProperty) {
      case PerformanceReportEntities.Country:
        entityPromise = getCountriesWithReportData;
        break;
      case PerformanceReportEntities.Authority:
        entityPromise = getAuthoritiesWithReportData;
        break;
      case PerformanceReportEntities.DocumentType:
        entityPromise = getDocumentTypesWithReportData;
        break;
      default:
        entityPromise = getCountriesWithReportData;
        break;
    }

    const response = await entityPromise(
      searchTerm,
      sortByProperty,
      sortByDirection,
      page,
      perPage,
      periodInMilliseconds,
      isReportDataFromAverageDevelopmentsPerDay
    );
    if (response.data) {
      if (page === 1) {
        dispatch(setPerformanceReportRows(response.data));
      } else {
        if (page > previousPage) {
          dispatch(addPerformanceReportRows(response.data));
        }
      }
    }
    return response.data;
  };
};

export const getEntityDetails = (
  id: number,
  entityType: string
): AppThunk<Promise<PerformanceReportEntity | null>> => {
  return async (dispatch, getState) => {
    const isReportDataFromAverageDevelopmentsPerDay =
      getState().performanceReport.isReportDataFromAverageDevelopmentsPerDay;
    const period = getState().performanceReport.periodProperty;

    const periodInMilliseconds = getSubtractionFromDateInMilliseconds(
      new Date(),
      periodOptions[period].durationObject
    );

    let entityPromise: (
      id: number,
      reportDataFromDateInMilliseconds?: number,
      isReportDataFromAverageDevelopmentsPerDay?: boolean,
      accessToken?: string
    ) => Promise<HttpResponse<PerformanceReportEntity | null>>;

    switch (entityType) {
      case PerformanceReportEntities.Country:
        entityPromise = getCountryById;
        break;
      case PerformanceReportEntities.Authority:
        entityPromise = getAuthorityById;
        break;
      case PerformanceReportEntities.DocumentType:
        entityPromise = getDocumentTypeById;
        break;
      default:
        entityPromise = getCountryById;
        break;
    }

    const response = await entityPromise(
      id,
      periodInMilliseconds,
      isReportDataFromAverageDevelopmentsPerDay
    );

    if (response.data) {
      dispatch(setEntityDetails(response.data));
    }

    return response.data;
  };
};

export const getEntityTeams = (
  id: number,
  entityType: string
): AppThunk<Promise<any | null>> => {
  return async (dispatch) => {
    let entityPromise: (
      id: number,
      accessToken?: string
    ) => Promise<HttpResponse<PaginatedResponse<Team> | null>>;

    switch (entityType) {
      case PerformanceReportEntities.Country:
        entityPromise = getCountryTeams;
        break;
      case PerformanceReportEntities.Authority:
        entityPromise = getAuthorityTeams;
        break;
      case PerformanceReportEntities.DocumentType:
        entityPromise = getDocumentTypeTeams;
        break;
      default:
        entityPromise = getCountryTeams;
        break;
    }

    const response = await entityPromise(id);

    if (response.data) {
      dispatch(setEntityTeams(response.data.records));
    }

    return response.data;
  };
};

export const getAllDevelopmentsAsync = (
  entityParam: string,
  id: number,
  reviewStatus: number[],
  fromDateInMilliseconds: number
): AppThunk<Promise<PaginatedResponse<Development> | null>> => {
  return async (dispatch, getState) => {
    const selectedEnvironment = getState().environment.selectedEnvironment;
    const searchTerm = getState().performanceReport.developmentListSearchTerm;
    const page = getState().performanceReport.developmentListPage;
    const perPage = getState().performanceReport.developmentListPerPage;
    const sortByDirection =
      getState().performanceReport.developmentListSortByDirection;
    const sortByProperty =
      getState().performanceReport.developmentListSortByProperty;
    const response = await getAllDevelopments(
      selectedEnvironment,
      searchTerm,
      sortByProperty,
      sortByDirection,
      page,
      perPage,
      {
        reviewStatus,
        [entityParam]: [id],
      },
      fromDateInMilliseconds,
      getCurrentDateMilliseconds()
    );
    if (page > 1) {
      dispatch(addPerformanceReportDevelopments(response.data));
    } else {
      dispatch(setPerformanceReportDevelopments(response.data));
    }
    return response.data;
  };
};

export const performanceReportSlice = createSlice({
  name: 'performance-report',
  initialState,
  reducers: {
    setPeriodProperty: (state, action: PayloadAction<string>) => {
      state.periodProperty = action.payload;
    },
    setReportByProperty: (
      state,
      action: PayloadAction<PerformanceReportEntities>
    ) => {
      state.page = 1;
      state.previousPage = 1;
      state.reportByProperty = action.payload;
    },
    setPerformanceReportSearchTerm: (state, action: PayloadAction<string>) => {
      state.page = 1;
      state.previousPage = 1;
      state.searchTerm = action.payload;
    },
    setPerformanceReportSortByProperty: (
      state,
      action: PayloadAction<string>
    ) => {
      state.page = 1;
      state.previousPage = 1;
      state.sortByProperty = action.payload;
    },
    setPerformanceReportSortByDirection: (
      state,
      action: PayloadAction<SortByDirection>
    ) => {
      state.page = 1;
      state.previousPage = 1;
      state.sortByDirection = action.payload;
    },
    setPerformanceReportRows: (
      state,
      action: PayloadAction<PaginatedResponse<PerformanceReportEntity>>
    ) => {
      state.performanceRows = action.payload.records;
      state.totalRecords = action.payload.totalRecords;
    },
    setEntityTeams: (state, action: PayloadAction<Team[]>) => {
      state.entityTeams = action.payload;
    },
    addPerformanceReportRows: (
      state,
      action: PayloadAction<PaginatedResponse<PerformanceReportEntity>>
    ) => {
      state.performanceRows = [
        ...state.performanceRows,
        ...action.payload.records,
      ];
      state.totalRecords = action.payload.totalRecords ?? 0;
      state.previousPage = state.page;
    },
    increasePerformanceReportPage: (state) => {
      state.page = state.page + 1;
    },
    resetPerformanceReportPage: (state) => {
      state.page = 1;
      state.previousPage = 1;
    },
    setIsReportDataFromAverageDevelopmentsPerDay: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.page = 1;
      state.previousPage = 1;
      state.isReportDataFromAverageDevelopmentsPerDay = action.payload;
    },
    setEntityDetails: (
      state,
      action: PayloadAction<PerformanceReportEntity>
    ) => {
      state.entityDetails = action.payload;
    },
    increaseDevelopmentListPage: (state) => {
      state.developmentListPage = state.developmentListPage + 1;
    },
    setPerformanceReportDevelopments: (
      state,
      action: PayloadAction<PaginatedResponse<Development> | null>
    ) => {
      state.developments = action.payload?.records ?? [];
      state.developmentListTotalRecords = action.payload?.totalRecords ?? 0;
    },
    setPerformanceReportDevelopmentsSearchTerm: (
      state,
      action: PayloadAction<string>
    ) => {
      state.page = 1;
      state.previousPage = 1;
      state.developmentListSearchTerm = action.payload;
    },
    setPerformanceReportDevelopmentsSortByProperty: (
      state,
      action: PayloadAction<string>
    ) => {
      state.page = 1;
      state.previousPage = 1;
      state.developmentListSortByProperty = action.payload;
    },
    setPerformanceReportDevelopmentsSortByDirection: (
      state,
      action: PayloadAction<SortByDirection>
    ) => {
      state.page = 1;
      state.previousPage = 1;
      state.developmentListSortByDirection = action.payload;
    },
    addPerformanceReportDevelopments: (
      state,
      action: PayloadAction<PaginatedResponse<Development> | null>
    ) => {
      state.developments = state.developments.concat(
        action.payload?.records ?? []
      );
      state.developmentListTotalRecords = action.payload?.totalRecords ?? 0;
    },
    resetPerformanceReportDevelopmentsPage: (state) => {
      state.developmentListPage = 1;
    },
    setTriggerPerformanceReportDevelopmentsUpdate: (state) => {
      state.triggerDevelopmentListUpdate =
        state.triggerDevelopmentListUpdate + 1;
    },
  },
});

export const {
  setPeriodProperty,
  setReportByProperty,
  setPerformanceReportSearchTerm,
  setPerformanceReportSortByDirection,
  setPerformanceReportSortByProperty,
  increasePerformanceReportPage,
  resetPerformanceReportPage,
  setPerformanceReportRows,
  addPerformanceReportRows,
  setIsReportDataFromAverageDevelopmentsPerDay,
  setEntityDetails,
  setEntityTeams,
  increaseDevelopmentListPage,
  setPerformanceReportDevelopments,
  addPerformanceReportDevelopments,
  setPerformanceReportDevelopmentsSearchTerm,
  setPerformanceReportDevelopmentsSortByDirection,
  setPerformanceReportDevelopmentsSortByProperty,
  resetPerformanceReportDevelopmentsPage,
  setTriggerPerformanceReportDevelopmentsUpdate,
} = performanceReportSlice.actions;

export const selectPeriodProperty = (state: RootState) =>
  state.performanceReport.periodProperty;
export const selectReportByProperty = (state: RootState) =>
  state.performanceReport.reportByProperty;
export const selectPerformanceReportSearchTerm = (state: RootState) =>
  state.performanceReport.searchTerm;
export const selectPerformanceReportSortByProperty = (state: RootState) =>
  state.performanceReport.sortByProperty;
export const selectPerformanceReportSortByDirection = (state: RootState) =>
  state.performanceReport.sortByDirection;
export const selectPerformanceRows = (state: RootState) =>
  state.performanceReport.performanceRows;
export const selectPerformanceReportPage = (state: RootState) =>
  state.performanceReport.page;
export const selectPerformanceReportPreviousPage = (state: RootState) =>
  state.performanceReport.previousPage;
export const selectPerformanceReportPerPage = (state: RootState) =>
  state.performanceReport.perPage;
export const selectIsReportDataFromAverageDevelopmentsPerDay = (
  state: RootState
) => state.performanceReport.isReportDataFromAverageDevelopmentsPerDay;
export const selectPerformanceReportTotalRecords = (state: RootState) =>
  state.performanceReport.totalRecords;
export const selectEntityDetails = (state: RootState) =>
  state.performanceReport.entityDetails;
export const selectEntityTeams = (state: RootState) =>
  state.performanceReport.entityTeams;
export const selectPerformanceReportDevelopments = (state: RootState) =>
  state.performanceReport.developments;
export const selectPerformanceReportDevelopmentsPage = (state: RootState) =>
  state.performanceReport.developmentListPage;
export const selectPerformanceReportDevelopmentsTotalRecords = (
  state: RootState
) => state.performanceReport.developmentListTotalRecords;
export const selectPerformanceReportDevelopmentsSearchTerm = (
  state: RootState
) => state.performanceReport.developmentListSearchTerm;
export const selectPerformanceReportDevelopmentsSortByProperty = (
  state: RootState
) => state.performanceReport.developmentListSortByProperty;
export const selectPerformanceReportDevelopmentsSortByDirection = (
  state: RootState
) => state.performanceReport.developmentListSortByDirection;
export const selectTriggerPerformanceReportDevelopmentsUpdate = (
  state: RootState
) => state.performanceReport.triggerDevelopmentListUpdate;

export default performanceReportSlice.reducer;
