import styled from '@emotion/styled';
import {
  Dispatch,
  HTMLAttributes,
  MutableRefObject,
  ReactNode,
  SetStateAction,
} from 'react';
import { Theme } from 'styles/themes';
import { useOnClickOutside } from 'hooks/use-on-click-outside';

export interface DropdownProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  keepOpenAfterClick?: boolean;
  containerRef: MutableRefObject<any>;
  transform?: string;
}

const DropdownContainer = styled.div<Partial<DropdownProps>>((props) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: props.transform ? props.transform : 'translate(-50%, 5px)',
  borderRadius: Theme.borderRadius,
  backgroundColor: Theme.colors.white,
  zIndex: 2,
}));

export const Dropdown: React.FC<DropdownProps> = (props: DropdownProps) => {
  useOnClickOutside(props.containerRef, () => props.setIsOpen(false));
  const containerId = props.id ? `${props.id}Container` : undefined;

  return (
    <>
      {props.isOpen && (
        <DropdownContainer
          id={containerId}
          transform={props.transform}
          style={props.style}
          onClick={
            props.keepOpenAfterClick ? (e) => e.stopPropagation() : undefined
          }
        >
          {props.children}
        </DropdownContainer>
      )}
    </>
  );
};
