import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { HTMLAttrID } from 'models';

interface AvatarProps extends HTMLAttrID {
  text: string;
  width?: string;
  height?: string;
  fontSize?: string;
}

const AvatarContainer = styled.div<{
  width?: string;
  height?: string;
  fontSize?: string;
}>((props) => ({
  display: 'flex',
  minWidth: props.width || '32px',
  height: props.height || '32px',
  padding: '6px',
  borderRadius: '50%',
  backgroundColor: Theme.colors.primaryColor,
  color: Theme.colors.white,
  fontWeight: 'bold',
  fontSize: props.fontSize || Theme.fontSize - 4,
  lineHeight: '19px',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const Avatar = (props: AvatarProps) => {
  return (
    <AvatarContainer
      id={props.id}
      width={props.width}
      height={props.height}
      fontSize={props.fontSize}
    >
      {props.text}
    </AvatarContainer>
  );
};
