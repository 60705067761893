import { httpGet } from 'utils/http/base-http-service';
import { HttpResponse } from 'utils/http/HttpResponse';
import {
  ActiveFilters,
  Language,
  Environment,
  PaginatedResponse,
} from 'models';
import { toQueryParams } from 'utils/to-query-params';
import { escapeQueryStringQueryReservedCharacters } from 'utils/elasticsearch';
import { SortByDirection } from 'enums';
import { getDevelopmentsBaseQuery } from 'helpers/filters';
import { filterKeys } from 'config/filter-keys';

const getLanguagesBaseQuery = (searchTerm: string) => {
  let query: any = {
    filter: [],
  };
  if (searchTerm) {
    query.filter.push({
      bool: {
        should: [
          {
            query_string: {
              query: `*${escapeQueryStringQueryReservedCharacters(
                searchTerm
              )}*`,
              fields: ['name'],
              minimum_should_match: '100%',
            },
          },
        ],
        minimum_should_match: 1,
      },
    });
  }
  return query;
};

export const getLanguagesWithDevelopmentCountGreaterThanZero = async (
  selectedEnvironment: Environment | null,
  searchTerm: string,
  page: number,
  perPage: number,
  activeFilters: ActiveFilters,
  ids?: number[]
): Promise<HttpResponse<PaginatedResponse<Language> | null>> => {
  const query = getLanguagesBaseQuery(searchTerm);
  let filterCountersQuery: any = {
    filter: [],
  };
  query.filter.push({
    range: {
      developmentsCount: {
        gt: 0,
      },
    },
  });
  filterCountersQuery = getDevelopmentsBaseQuery(
    activeFilters,
    filterCountersQuery,
    selectedEnvironment,
    [filterKeys.language]
  );
  const queryParameters = toQueryParams({
    defaultQueryParameters: JSON.stringify({
      query: JSON.stringify(query),
      page,
      perPage,
      sortByProperty: 'name.keyword',
      sortByDirection: SortByDirection.ASC,
    }),
    filterCountersQueryParameters: JSON.stringify({
      query: JSON.stringify(filterCountersQuery),
    }),
    idsToFilter: JSON.stringify(ids),
  });
  return httpGet<PaginatedResponse<Language>>(`languages?${queryParameters}`);
};

export const getLanguages = async (
  searchTerm: string,
  page: number,
  perPage: number
): Promise<HttpResponse<PaginatedResponse<Language> | null>> => {
  const query = getLanguagesBaseQuery(searchTerm);
  const queryParameters = toQueryParams({
    defaultQueryParameters: JSON.stringify({
      query: JSON.stringify(query),
      page,
      perPage,
      sortByProperty: 'name.keyword',
      sortByDirection: SortByDirection.ASC,
    }),
  });
  return httpGet<PaginatedResponse<Language>>(`languages?${queryParameters}`);
};
