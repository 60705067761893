import { httpGet } from 'utils/http/base-http-service';
import { HttpResponse } from 'utils/http/HttpResponse';
import {
  Subcategory,
  PaginatedResponse,
  ActiveFilters,
  Environment,
} from 'models';
import { toQueryParams } from 'utils/to-query-params';
import { SortByDirection } from 'enums';
import { escapeQueryStringQueryReservedCharacters } from 'utils/elasticsearch';
import { getDevelopmentsBaseQuery } from 'helpers/filters';
import { filterKeys } from 'config/filter-keys';

const getSubcategoriesBaseQuery = (searchTerm: string) => {
  let query: any = {
    filter: [],
  };
  if (searchTerm) {
    query.filter.push({
      bool: {
        should: [
          {
            query_string: {
              query: `*${escapeQueryStringQueryReservedCharacters(
                searchTerm
              )}*`,
              fields: ['name'],
              minimum_should_match: '100%',
            },
          },
        ],
        minimum_should_match: 1,
      },
    });
  }
  return query;
};

export const getSubcategoriesWithDevelopmentCountGreaterThanZero = async (
  selectedEnvironment: Environment | null,
  searchTerm: string,
  page: number,
  perPage: number,
  activeFilters: ActiveFilters,
  ids?: number[]
): Promise<HttpResponse<PaginatedResponse<Subcategory> | null>> => {
  const query = getSubcategoriesBaseQuery(searchTerm);
  let filterCountersQuery: any = {
    filter: [],
  };
  query.filter.push({
    range: {
      developmentsCount: {
        gt: 0,
      },
    },
  });
  filterCountersQuery = getDevelopmentsBaseQuery(
    activeFilters,
    filterCountersQuery,
    selectedEnvironment,
    [filterKeys.subcategory]
  );
  const queryParameters = toQueryParams({
    defaultQueryParameters: JSON.stringify({
      query: JSON.stringify(query),
      page,
      perPage,
      sortByProperty: 'name.keyword',
      sortByDirection: SortByDirection.ASC,
    }),
    filterCountersQueryParameters: JSON.stringify({
      query: JSON.stringify(filterCountersQuery),
    }),
    idsToFilter: JSON.stringify(ids),
  });
  return httpGet<PaginatedResponse<Subcategory>>(
    `subcategories?${queryParameters}`
  );
};

export const getSubcategories = async (
  searchTerm: string,
  page: number,
  perPage: number
): Promise<HttpResponse<PaginatedResponse<Subcategory> | null>> => {
  const query = getSubcategoriesBaseQuery(searchTerm);
  const queryParameters = toQueryParams({
    defaultQueryParameters: JSON.stringify({
      query: JSON.stringify(query),
      page,
      perPage,
      sortByProperty: 'name.keyword',
      sortByDirection: SortByDirection.ASC,
    }),
  });
  return httpGet<PaginatedResponse<Subcategory>>(
    `subcategories?${queryParameters}`
  );
};
