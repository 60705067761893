import {
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
} from 'utils/http/base-http-service';
import { HttpResponse } from 'utils/http/HttpResponse';
import {
  AddUsersToTeamsPayload,
  PaginatedResponse,
  User,
  UsersBulkActionPayload,
} from 'models';
import { toQueryParams } from 'utils/to-query-params';
import { SortByDirection } from 'enums';
import { escapeQueryStringQueryReservedCharacters } from 'utils/elasticsearch';

export const register = async (
  user: User
): Promise<HttpResponse<User | null>> => {
  return await httpPost<User, User>('users', user);
};

export const sendInvite = async (
  user: User
): Promise<HttpResponse<void | null>> => {
  return await httpPost<void, User>('users/send-invite', user);
};

export const getCurrentUser = async (
  accessToken: string
): Promise<HttpResponse<User | null>> => {
  return await httpGet<User>('users/me', false, {
    Authorization: `Bearer ${accessToken}`,
  });
};

export const getAllUsers = async (
  searchTerm: string,
  page: number,
  perPage: number,
  sortByProperty: string,
  sortByDirection: SortByDirection
): Promise<HttpResponse<PaginatedResponse<User> | null>> => {
  let query: any = {
    filter: [],
  };
  if (searchTerm) {
    query.filter.push({
      bool: {
        should: [
          {
            query_string: {
              query: `*${escapeQueryStringQueryReservedCharacters(
                searchTerm
              )}*`,
              fields: ['fullName'],
              minimum_should_match: '100%',
            },
          },
          {
            match_phrase_prefix: {
              statusText: searchTerm,
            },
          },
          {
            query_string: {
              query: `*${escapeQueryStringQueryReservedCharacters(
                searchTerm
              )}*`,
              fields: ['teams.name'],
              minimum_should_match: '100%',
            },
          },
        ],
        minimum_should_match: 1,
      },
    });
  }
  const queryParameters = toQueryParams({
    query: JSON.stringify(query),
    page,
    perPage,
    sortByProperty,
    sortByDirection,
  });
  return await httpGet<PaginatedResponse<User>>(`users?${queryParameters}`);
};

export const addUsersToTeams = async (
  addUsersToTeamsPayload: AddUsersToTeamsPayload
): Promise<HttpResponse<User[] | null>> => {
  return await httpPost<User[], AddUsersToTeamsPayload>(
    'users/add-teams',
    addUsersToTeamsPayload
  );
};

export const updateUser = async (
  payload: User
): Promise<HttpResponse<User | null>> => {
  const id = payload.id as number;
  return await httpPut<User, User>(`users/${id}`, payload);
};

export const giveUserAccess = async (
  payload: UsersBulkActionPayload
): Promise<HttpResponse<User[] | null>> => {
  return await httpPut<User[], UsersBulkActionPayload>(
    'users/give-access',
    payload
  );
};

export const revokeUserAccess = async (
  payload: UsersBulkActionPayload
): Promise<HttpResponse<User[] | null>> => {
  return await httpPut<User[], UsersBulkActionPayload>(
    'users/revoke-access',
    payload
  );
};

export const deleteUsers = async (
  payload: UsersBulkActionPayload
): Promise<HttpResponse<User[] | null>> => {
  return await httpDelete<User[], UsersBulkActionPayload>(
    `users/bulk-delete`,
    payload
  );
};

export const getUserById = async (
  id: number
): Promise<HttpResponse<User | null>> => {
  return await httpGet<User>(`users/${id}`);
};
