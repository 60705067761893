import { Feed } from 'models';
import { Row, Table, Tbody, Td, Th, Thead, Tr } from 'components/Table';
import { RefObject, useCallback, useRef, useState } from 'react';
import { Checkbox } from 'components/Checkbox';
import styled from '@emotion/styled';
import { SortByDirectionButton } from 'components/SortByDirectionButton';
import { Body } from 'components/Typography';
import { FlexContainer } from 'styles/utils';
import { BlueChipList } from 'components/Chip';
import { EllipsedSpan } from 'components/Typography';
import { CheckmarkTypes } from 'components/Checkbox/Checkbox';
import { ManagementTableProps } from 'pages/management/interfaces/ManagementTableProps';
import { OverflowYAutoContainer } from 'components/OverflowYAutoContainer';
import {
  setManagementFeedSortByDirection,
  setManagementFeedSortByProperty,
} from 'store/feed/management-feed-slice';

const NameContainer = styled(EllipsedSpan)({
  display: 'inline-block',
  maxWidth: '85%',
});

const FeedRow = (props: FeedRowProps) => {
  const { feed, checked } = props;

  const [isHighlighted, setHighlighted] = useState(false);
  const handleHighlightChange = useCallback(
    (highlight: boolean) => () => setHighlighted(highlight),
    [setHighlighted]
  );

  const handleCheck = useCallback(() => {
    props.onSelectRow && props.onSelectRow({ ...feed, checked: !checked });
  }, [checked, feed]);

  return (
    <Tr
      id={`FeedManagementTableRow${feed.id}`}
      onMouseEnter={handleHighlightChange(true)}
      onMouseLeave={handleHighlightChange(false)}
      onClick={handleCheck}
      isHighlighted={isHighlighted || checked}
    >
      <Td
        id={`FeedManagementTableRow${feed.id}CheckboxTd`}
        style={{ padding: '12px 0 12px 16px' }}
      >
        <Checkbox
          id={`FeedManagementTableRow${feed.id}Checkbox`}
          onChange={handleCheck}
          isChecked={!!checked}
          label=""
        />
      </Td>
      <Td
        id={`FeedManagementTableRow${feed.id}NameTd`}
        style={{ padding: '12px 16px' }}
      >
        <FlexContainer id={`FeedManagementTableRow${feed.id}NameTdContainer`}>
          <NameContainer
            id={`FeedManagementTableRow${feed.id}NameContainer`}
            size={16}
            noMargin
          >
            {feed.name}
          </NameContainer>
        </FlexContainer>
      </Td>
      <Td
        id={`FeedManagementTableRow${feed.id}TeamsTd`}
        style={{ padding: '9.5px 16px' }}
      >
        {feed.teamFeeds?.length ? (
          <BlueChipList
            id={`FeedManagementTableRow${feed.id}TeamsBlueChipList`}
            isSelected={checked || isHighlighted}
            items={feed.teamFeeds}
            getLabel={(teamFeed) => teamFeed.teamName}
            cutterElement={props.cutterElementRef}
            maxWidth={275}
          />
        ) : (
          <Body
            id={`FeedManagementTableRow${feed.id}NoTeamMessage`}
            noMargin
            color="grayDark"
            textStyle="italic"
          >
            No team
          </Body>
        )}
      </Td>
      <Td
        id={`FeedManagementTableRow${feed.id}FiltersTd`}
        style={{ padding: '12px 16px' }}
      >
        <Body id={`FeedManagementTableRow${feed.id}Filters`} noMargin>
          {feed.filtersCount}
        </Body>
      </Td>
    </Tr>
  );
};

export const FeedsTable = (props: FeedsTableProps) => {
  const {
    rows,
    onSelectAll,
    onSelectRow,
    sortDirection,
    sortField,
    areSomeChecked = false,
  } = props;

  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const checkAll = useCallback(() => {
    onSelectAll && onSelectAll();
  }, []);

  const handleCheckFeed = useCallback((row: Row<Partial<Feed>>) => {
    onSelectRow && onSelectRow(row);
  }, []);

  const FeedRows = rows.map((f) => (
    <FeedRow
      key={f.id}
      feed={f}
      checked={f.checked}
      onSelectRow={handleCheckFeed}
      cutterElementRef={scrollableContainerRef}
    />
  ));

  return (
    <OverflowYAutoContainer
      id="FeedManagementTable"
      ref={scrollableContainerRef}
      style={{
        height: 'calc(100% - 48px)',
        width: '100%',
        marginBottom: '16px',
      }}
    >
      <Table
        id="FeedManagementTable"
        style={{ position: 'relative', tableLayout: 'fixed' }}
      >
        <Thead id="FeedManagementTableThead">
          <Tr id="FeedManagementTableTr" style={{ height: '48px' }}>
            <Th
              id="FeedManagementTableThSelectAll"
              width="24px"
              padding="12px 0 12px 16px"
            >
              <Checkbox
                id="FeedManagementTableSelectAllCheckbox"
                checkmarkType={CheckmarkTypes.minusSign}
                onChange={checkAll}
                isChecked={areSomeChecked}
              />
            </Th>
            <Th id="FeedManagementTableNameTh" width="30%" padding="12px 16px">
              <FlexContainer id="FeedManagementTableNameThContainer">
                Name{' '}
                <SortByDirectionButton
                  id="FeedManagementTableNameThSortByDirectionButton"
                  propertyToSortBy="name.keyword"
                  currentPropertyBeingSorted={sortField}
                  currentDirectionBeingSorted={sortDirection}
                  setSortByProperty={setManagementFeedSortByProperty}
                  setSortByDirection={setManagementFeedSortByDirection}
                />
              </FlexContainer>
            </Th>
            <Th id="FeedManagementTableTeamsTh" width="44%" padding="12px 16px">
              Assigned to
            </Th>
            <Th
              id="FeedManagementTableFiltersTh"
              width="80px"
              padding="12px 16px"
            >
              <FlexContainer id="FeedManagementTableFiltersThContainer">
                Filters
                <SortByDirectionButton
                  id="FeedManagementTableFiltersThSortByDirectionButton"
                  propertyToSortBy="filtersCount"
                  currentPropertyBeingSorted={sortField}
                  currentDirectionBeingSorted={sortDirection}
                  setSortByProperty={setManagementFeedSortByProperty}
                  setSortByDirection={setManagementFeedSortByDirection}
                />
              </FlexContainer>
            </Th>
          </Tr>
        </Thead>
        <Tbody id="FeedManagementTableTbody">{FeedRows}</Tbody>
      </Table>
    </OverflowYAutoContainer>
  );
};

interface FeedRowProps {
  feed: Partial<Feed>;
  checked?: boolean;
  onSelectRow?: (row: Row<Partial<Feed>>) => void;
  cutterElementRef?: RefObject<Element>;
}

export interface FeedsTableProps extends ManagementTableProps<Partial<Feed>> {}
