import { memo } from 'react';
import { DatePicker, DatePickerProps } from './DatePicker/DatePicker';
import { Modal } from 'components/Modal';

interface DatePickerModalProps extends DatePickerProps {
  isOpen: boolean;
}

export const DatePickerModal = memo((props: DatePickerModalProps) => {
  const { isOpen, ...rest } = props;

  return (
    <Modal id="DatePickerModal" isDisplayed={isOpen}>
      <DatePicker {...rest} />
    </Modal>
  );
});
