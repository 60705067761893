import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { Theme } from 'styles/themes';
import { FlexContainer, BoldText, CursorPointer } from 'styles/utils';
import { ReactComponent as ArrowDown } from 'assets/arrow-down.svg';
import {
  Dropdown,
  DropdownOptionsContainer,
  DropdownOption,
} from 'components/Dropdown';
import { HTMLAttrID } from 'models';
import { pascalCase } from 'utils/string-formatter';
import { Checkbox } from 'components/Checkbox';
import { EnvironmentsList } from 'models/reports/Source';
import styled from '@emotion/styled';
import { Searchbar } from 'components/Searchbar';

export interface FilterJurisdictionProps extends HTMLAttrID {
  title: string;
  options: EnvironmentsList[];
  selectedCountriesIds: (ids: string) => void;
}

const CustomDropdownOptionsContainer = styled(DropdownOptionsContainer)`
  width: 264px;
  gap: 8px;
  padding: 8px 0;
`;

const CustomDropdownOption = styled(DropdownOption)`
  height: auto;
  padding: 16px;
`;

const CustomSearchbar = styled(Searchbar)`
  #JurisdictionModalSearchbar > div {
    height: 60px;
  }
`;

export const FilterJurisdiction = (props: FilterJurisdictionProps) => {
  const dropdownContainerRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [checkedStates, setCheckedStates] = useState<{
    [key: number]: boolean;
  }>({});
  const [environmentsData, setEnvironmentsData] = useState<EnvironmentsList[]>(
    props.options
  );
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setEnvironmentsData(props.options);
  }, [props.options]);

  const trueCount = Object.values(checkedStates).filter(
    (value) => value === true
  ).length;

  const checkedIds = Object.entries(checkedStates)
    .filter(([key, value]) => value === true) // Filter entries where the value is true
    .map(([key]) => parseInt(key, 10)) // Convert keys to integers
    .join(','); // Join the array into a comma-separated string

  const handleCheckboxChange = (id: number) => {
    setCheckedStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  useEffect(() => {
    props.selectedCountriesIds(checkedIds);
  }, [handleCheckboxChange]);

  const handleSearchChange = (searchText: string) => {
    setSearchTerm(searchText);
    if (searchText) {
      const filteredData = environmentsData.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setEnvironmentsData(filteredData);
    } else {
      setEnvironmentsData(props.options);
    }
  };

  return (
    <FlexContainer
      style={{
        gap: '8px',
        borderRight: '2px solid #DDE1E3',
        marginRight: '1rem',
        paddingRight: '1rem',
      }}
      id={`${props.id}MainContainer`}
      ref={dropdownContainerRef}
    >
      <CursorPointer
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <BoldText
          id={`${props.id}Title`}
          style={{ display: 'flex', gap: '4px' }}
        >
          <span
            style={{
              fontSize: '16px',
              color: '#01416A',
            }}
          >
            {props.title}
          </span>
          <span style={{ color: '#0298F7' }}>({trueCount})</span>
        </BoldText>
      </CursorPointer>
      <div id={`${props.id}OuterContainer`} style={{ position: 'relative' }}>
        <CursorPointer onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
          <ArrowDown id={`${props.id}ArrowDown`} />
        </CursorPointer>
        <Dropdown
          id={props.id}
          isOpen={isDropdownOpen}
          setIsOpen={setIsDropdownOpen}
          containerRef={dropdownContainerRef}
          transform="translate(-94%, 41px)"
          style={{
            border: `1px solid ${Theme.colors.grayBorder}`,
            maxHeight: '450px',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <CustomDropdownOptionsContainer id={`${props.id}OptionsContainer`}>
            <FlexContainer
              id="JurisdictionModalBodyContainer"
              flexDirection="column"
              style={{ width: '100%', padding: '16px' }}
            >
              <CustomSearchbar
                id="JurisdictionModalSearchbar"
                value={searchTerm}
                setValue={handleSearchChange}
                placeholder="Search..."
              />
            </FlexContainer>
            {environmentsData.map((option, index) => (
              <CustomDropdownOption
                id={`${props.id}${pascalCase(option.name)}Option`}
                key={option.name}
                removeDivider={true}
              >
                <Checkbox
                  key={option.id} // Ensure each checkbox has a unique key
                  id={`Filter${props.id}UserCheckboxInput`}
                  isChecked={checkedStates[option.id] || false} // Ensure it defaults to false if undefined
                  onChange={() => handleCheckboxChange(option.id)}
                  label={option.name}
                  style={{ gap: '4px' }}
                />
              </CustomDropdownOption>
            ))}
          </CustomDropdownOptionsContainer>
        </Dropdown>
      </div>
    </FlexContainer>
  );
};
