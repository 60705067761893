import theme from '../../lib/styles/theme';
import styled from '@emotion/styled';

export const ModalOverlay = styled.div({
  zIndex: '999',
  width: '100vw',
  height: '100vh',
  display: 'flex',
  position: 'fixed',
  top: '0',
  left: '0',
  background: 'rgba(0, 0, 0, 0.5)',
  justifyContent: 'center',
  alignItems: 'center',
  overflowX: 'hidden',
  overflowY: 'auto',
  transition: 'width 0.25s',
});

export const ModalBox = styled.div({
  zIndex: '9999',
  background: 'white',
  width: '60%',
  position: 'absolute',
  top: '100px',
  borderRadius: '0.3rem',
});

export const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.font.color.gray4}`,
  height: '45px',
});

export const HeaderTitle = styled.div({
  marginTop: '13px',
  marginLeft: '20px',
  fontSize: '16px',
  fontWeight: 'bold',
  color: theme.sidebar.colorPallete.primary,
});

export const CloseIconContainer = styled.div({
  cursor: 'pointer',
});

export const AbstractTitle = styled.div({
  fontSize: '19px',
  fontWeight: 'bold',
});

export const MainContainer = styled.div({
  margin: '20px',
});

export const ContainerTitle = styled.div({
  display: 'flex',
  marginTop: '15px',
  fontSize: '17px',
  fontWeight: 'bold',
  justifyContent: 'space-between',
});

export const EffectiveDate = styled.div({
  marginTop: '10px',
  fontSize: '15px',
});

export const SectionContainer = styled.div({
  marginTop: '30px',
});

export const SectionContent = styled.div({
  marginTop: '15px',
  lineHeight: '24px',
});

export const FooterContainer = styled.div({
  display: 'flex',
  height: '50px',
  borderTop: `1px solid ${theme.font.color.gray4}`,
  justifyContent: 'flex-end',
});

export const ConfirmButton = styled.button({
  backgroundColor: theme.button.backgroundPallete.primary,
  color: 'white',
  fontWeight: 'bold',
  padding: '5px 15px',
  borderRadius: '5px',
  outline: '0',
  border: '0',
  margin: '10px 10px',
  cursor: 'pointer',
  transition: 'ease background-color 250ms',
});

export const IDContainer = styled.div({
  minWidth: '30px',
  minHeight: '25px',
  backgroundColor: theme.button.backgroundPallete.secondaryColorLight,
  textAlign: 'center',
  color: theme.sidebar.colorPallete.primary,
  borderRadius: '0.2rem',
});
