import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { getLanguages } from 'services/languages/languages-service';
import { Language, PaginatedResponse } from 'models';
import { paginationConfig } from 'config/pagination-config';

export interface NewFeedFilterLanguageState {
  languages: Language[];
  totalRecords: number;
  searchTerm: string;
  page: number;
  perPage: number;
}

const initialState: NewFeedFilterLanguageState = {
  languages: [],
  totalRecords: 0,
  searchTerm: '',
  page: 1,
  perPage: paginationConfig.languagesPerPage,
};

export const getAllNewFeedFilterLanguagesAsync = (): AppThunk<
  Promise<PaginatedResponse<Language> | null>
> => {
  return async (dispatch, getState) => {
    const searchTerm = getState().newFeedFilterLanguage.searchTerm;
    const page = getState().newFeedFilterLanguage.page;
    const perPage = getState().newFeedFilterLanguage.perPage;
    const response = await getLanguages(searchTerm, page, perPage);
    if (page > 1) {
      dispatch(addNewFeedFilterLanguages(response.data));
    } else {
      dispatch(setNewFeedFilterLanguages(response.data));
    }
    return response.data;
  };
};

export const newFeedFilterLanguageSlice = createSlice({
  name: 'new-feed-filter-language',
  initialState,
  reducers: {
    setNewFeedFilterLanguages: (
      state,
      action: PayloadAction<PaginatedResponse<Language> | null>
    ) => {
      state.languages = action.payload?.records ?? [];
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    addNewFeedFilterLanguages: (
      state,
      action: PayloadAction<PaginatedResponse<Language> | null>
    ) => {
      state.languages = state.languages.concat(action.payload?.records ?? []);
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    setNewFeedFilterLanguagesSearchTerm: (
      state,
      action: PayloadAction<string>
    ) => {
      state.page = 1;
      state.searchTerm = action.payload;
    },
    increaseNewFeedFilterLanguagesPage: (state) => {
      state.page = state.page + 1;
    },
    resetNewFeedFilterLanguagesPage: (state) => {
      state.page = 1;
    },
    resetNewFeedFilterLanguagesSearchTerm: (state) => {
      state.searchTerm = '';
    },
  },
});

export const {
  setNewFeedFilterLanguages,
  addNewFeedFilterLanguages,
  setNewFeedFilterLanguagesSearchTerm,
  increaseNewFeedFilterLanguagesPage,
  resetNewFeedFilterLanguagesPage,
  resetNewFeedFilterLanguagesSearchTerm,
} = newFeedFilterLanguageSlice.actions;

export const selectNewFeedFilterLanguages = (state: RootState) =>
  state.newFeedFilterLanguage.languages;
export const selectNewFeedFilterLanguagesTotalRecords = (state: RootState) =>
  state.newFeedFilterLanguage.totalRecords;
export const selectNewFeedFilterLanguagesSearchTerm = (state: RootState) =>
  state.newFeedFilterLanguage.searchTerm;
export const selectNewFeedFilterLanguagesPage = (state: RootState) =>
  state.newFeedFilterLanguage.page;
export const selectNewFeedFilterLanguagesPerPage = (state: RootState) =>
  state.newFeedFilterLanguage.perPage;

export default newFeedFilterLanguageSlice.reducer;
