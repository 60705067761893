import { Avatar } from 'components/Avatar';
import { Card } from 'components/Card';
import { User } from 'models';
import { ReactComponent as Manager } from 'assets/manager.svg';
import { Theme } from 'styles/themes';
import { BoldText, CursorPointer } from 'styles/utils';
import { ReactComponent as EditIcon } from 'assets/edit.svg';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Grid } from './Grid';
import { CollapsibleGridField } from './CollapsibleGridField';
import { dateToUTCDateString, DateFormats } from 'utils/date';
import { Input } from 'components/Input';
import { InputLabel } from 'components/InputLabel';
import { SelectSearchbar } from 'components/SelectSearchbar';
import { paginationConfig } from 'config/pagination-config';
import { getSelectSearchbarCountries } from 'services/countries/countries-service';
import { validateEmailInput, validateTextInput } from 'utils/input-validation';
import { Button, OutlinedButton } from 'components/Button';
import { updateUser } from 'services/users/users-service';
import { HttpResponse } from 'utils/http/HttpResponse';
import { Loading } from 'components/Loading';

interface UserCardProps {
  user: User;
  triggerUserUpdate: number;
  setTriggerUserUpdate: Dispatch<SetStateAction<number>>;
  setIsPasswordChangeModalDisplayed: Dispatch<SetStateAction<boolean>>;
}

export const UserCard = (props: UserCardProps) => {
  const user = props.user;
  const [isEditHovered, setIsEditHovered] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [email, setEmail] = useState<string>(user.email);
  const [countryIds, setCountryIds] = useState<number[]>([user.countryId]);
  const [city, setCity] = useState<string>(user.city);
  const [invalidEmailError, setInvalidEmailError] = useState('');
  const [invalidCityNameError, setInvalidCityNameError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    validateTextInput(city, setInvalidCityNameError);
  }, [city]);

  useEffect(() => {
    validateEmailInput(email, setInvalidEmailError);
  }, [email]);

  const resetCard = () => {
    props.setTriggerUserUpdate(props.triggerUserUpdate + 1);
    setIsLoading(false);
    setIsEditHovered(false);
    setIsEditing(false);
    setInvalidEmailError('');
    setInvalidCityNameError('');
  };

  const cancelEdit = () => {
    resetCard();
    setEmail(user.email);
    setCountryIds([user.countryId]);
    setCity(user.city);
  };

  const update = async () => {
    try {
      setIsLoading(true);
      const newUser: User = {
        id: user.id,
        email,
        firstName: user.firstName,
        lastName: user.lastName,
        status: user.status,
        statusText: user.statusText,
        city,
        countryId: countryIds[0],
        organizationId: user.organizationId,
        isManager: user.isManager,
        teamIds: user.teamIds,
      };
      await updateUser(newUser);
      resetCard();
    } catch (error) {
      const httpResponse = error as HttpResponse<null>;
      if (httpResponse.status === 409 && httpResponse.error) {
        setInvalidEmailError(httpResponse.error.message);
      }
      setIsLoading(false);
    }
  };

  const areFieldsCompleted = useMemo(() => {
    return !!email && !!countryIds && !!countryIds.length && !!city;
  }, [email, countryIds, city]);

  const isAnyFieldWithError = useMemo(() => {
    return !!invalidCityNameError || !!invalidEmailError;
  }, [invalidCityNameError, invalidEmailError]);

  const hasAnyFieldChanged = useMemo(() => {
    return (
      user.email !== email ||
      user.countryId !== countryIds[0] ||
      user.city !== city
    );
  }, [user.email, user.countryId, user.city, email, countryIds, city]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '40%',
        marginRight: '16px',
      }}
    >
      <Card>
        <div style={{ width: '100%', position: 'relative' }}>
          {isLoading && <Loading />}
          <div style={{ display: 'flex', marginBottom: '20px' }}>
            <Avatar
              width="56px"
              height="56px"
              fontSize="22px"
              text={`${user.firstName.charAt(0)}${user.lastName.charAt(0)}`}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '16px',
                width: '100%',
              }}
            >
              <span style={{ fontSize: '27px', fontWeight: 600 }}>
                {user.fullName}
              </span>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: 'auto',
                  width: '100%',
                }}
              >
                <span>{user.statusText}</span>
                {user.isManager && (
                  <>
                    <span style={{ marginLeft: '4px' }}>—</span>
                    <div style={{ display: 'flex', marginLeft: '4px' }}>
                      <Manager
                        style={{
                          color: Theme.colors.secondaryColor,
                        }}
                      />
                      <span
                        style={{
                          fontSize: Theme.fontSize,
                          color: Theme.colors.secondaryColor,
                          marginLeft: '2px',
                        }}
                      >
                        Manager
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
            {!isEditing && (
              <CursorPointer
                style={{ width: '18px', height: '18px' }}
                onMouseEnter={() => setIsEditHovered(true)}
                onMouseLeave={() => setIsEditHovered(false)}
                onClick={() => setIsEditing(true)}
              >
                <EditIcon
                  style={{
                    color: isEditHovered
                      ? Theme.colors.secondaryColor
                      : Theme.colors.grayDark,
                  }}
                />
              </CursorPointer>
            )}
          </div>
          {!isEditing ? (
            <Grid>
              <CollapsibleGridField
                height="68px"
                title={
                  <>
                    <BoldText style={{ marginBottom: '4px' }}>Email</BoldText>
                    <span>{user.email}</span>
                  </>
                }
              />
              <CollapsibleGridField
                height="68px"
                title={
                  <>
                    <BoldText style={{ marginBottom: '4px' }}>
                      Location
                    </BoldText>
                    <span>
                      {user.countryName}, {user.city}
                    </span>
                  </>
                }
              />
              {user.createdAt && (
                <CollapsibleGridField
                  height="68px"
                  title={
                    <>
                      <BoldText style={{ marginBottom: '4px' }}>
                        Creation date
                      </BoldText>
                      <span>
                        {dateToUTCDateString(user.createdAt, DateFormats.PPP)}
                      </span>
                    </>
                  }
                />
              )}
              {/* <CollapsibleGridField
                height="68px"
                title={
                  <>
                    <BoldText style={{ marginBottom: '4px' }}>
                      Developments completed
                    </BoldText>
                    <span>{user.developmentsCompletedCount}</span>
                  </>
                }
              /> */}
            </Grid>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {/* <div style={{ marginBottom: '20px' }}>
                <InputLabel htmlFor="id-email" isBold>
                  Email
                </InputLabel>
                <Input
                  id="id-email"
                  placeholder="user@account.com"
                  value={email}
                  setValue={setEmail}
                  error={invalidEmailError}
                />
              </div> */}
              <div style={{ marginBottom: '20px' }}>
                <InputLabel htmlFor="id-country" isBold>
                  Jurisdiction
                </InputLabel>
                <SelectSearchbar
                  id="id-country"
                  getAllRecords={getSelectSearchbarCountries}
                  placeholder="Select jurisdiction..."
                  recordsPropertyToDisplay="name"
                  recordsPropertyToSortBy="name.keyword"
                  values={countryIds}
                  setValues={setCountryIds}
                  perPage={paginationConfig.countriesDropdownPerPage}
                  preSelectedLabel={user.countryName}
                />
              </div>
              <div style={{ marginBottom: '20px' }}>
                <InputLabel htmlFor="id-city" isBold>
                  City
                </InputLabel>
                <Input
                  id="id-city"
                  placeholder="Enter city..."
                  value={city}
                  setValue={setCity}
                  maxLength={255}
                  error={invalidCityNameError}
                />
              </div>
              <div style={{ display: 'flex', marginLeft: 'auto' }}>
                <OutlinedButton
                  color={Theme.colors.secondaryColor}
                  style={{ marginRight: '10px' }}
                  onClick={() => cancelEdit()}
                >
                  Cancel
                </OutlinedButton>
                <Button
                  onClick={() => update()}
                  isDisabled={
                    !areFieldsCompleted ||
                    isAnyFieldWithError ||
                    !hasAnyFieldChanged
                  }
                >
                  Save changes
                </Button>
              </div>
            </div>
          )}
        </div>
        {!isEditing && (
          <div
            id="ChangePasswordContainer"
            style={{
              width: '100%',
              position: 'relative',
              display: 'flex',
              justifyContent: 'right',
            }}
          >
            <Button
              id="ChangePasswordButton"
              style={{ marginTop: '16px' }}
              onClick={() => props.setIsPasswordChangeModalDisplayed(true)}
            >
              Change password
            </Button>
          </div>
        )}
      </Card>
    </div>
  );
};
