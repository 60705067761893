import { useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectNewFeedFilterLanguages } from 'store/new-feed-filter/new-feed-filter-language-slice';
import { selectNewFeedFilterRegions } from 'store/new-feed-filter/new-feed-filter-region-slice';
import { selectNewFeedFilterCountries } from 'store/new-feed-filter/new-feed-filter-country-slice';
import { selectNewFeedFilterAuthorities } from 'store/new-feed-filter/new-feed-filter-authority-slice';
import { selectNewFeedFilterSubcategories } from 'store/new-feed-filter/new-feed-filter-subcategory-slice';
import { selectNewFeedFilterDocumentTypes } from 'store/new-feed-filter/new-feed-filter-document-type-slice';
import { FeedAppliedFiltersProps } from './interfaces';
import { buildFilterFieldByIds, buildTitleField } from './helpers';

export const DynamicFeedAppliedFilters = (props: FeedAppliedFiltersProps) => {
  const filterLanguages = useAppSelector(selectNewFeedFilterLanguages);
  const filterRegions = useAppSelector(selectNewFeedFilterRegions);
  const filterCountries = useAppSelector(selectNewFeedFilterCountries);
  const filterAuthorities = useAppSelector(selectNewFeedFilterAuthorities);
  const filterSubcategories = useAppSelector(selectNewFeedFilterSubcategories);
  const filterDocumentTypes = useAppSelector(selectNewFeedFilterDocumentTypes);

  const appliedFiltersTitle = useMemo(
    () =>
      buildTitleField(
        props.feed.filtersCount,
        props.isEdit,
        props.onClickEditFilters
      ),
    [props.feed.filtersCount, props.isEdit, props.onClickEditFilters]
  );

  const appliedLanguageFilters = useMemo(
    () =>
      props.feed.languageIds && props.feed.languageIds.length
        ? [
            buildFilterFieldByIds(
              'Language',
              props.feed.languageIds,
              filterLanguages
            ),
          ]
        : [],
    [props.feed.languageIds, filterLanguages]
  );

  const appliedRegionFilters = useMemo(
    () =>
      props.feed.regionIds && props.feed.regionIds.length
        ? [buildFilterFieldByIds('Region', props.feed.regionIds, filterRegions)]
        : [],
    [props.feed.regionIds, filterRegions]
  );

  const appliedCountryFilters = useMemo(
    () =>
      props.feed.countryIds && props.feed.countryIds.length
        ? [
            buildFilterFieldByIds(
              'Country',
              props.feed.countryIds,
              filterCountries
            ),
          ]
        : [],
    [props.feed.countryIds, filterCountries]
  );

  const appliedAuthorityFilters = useMemo(
    () =>
      props.feed.authorityIds && props.feed.authorityIds.length
        ? [
            buildFilterFieldByIds(
              'Authority',
              props.feed.authorityIds,
              filterAuthorities
            ),
          ]
        : [],
    [props.feed.authorityIds, filterAuthorities]
  );

  const appliedSubcategoryFilters = useMemo(
    () =>
      props.feed.subcategoryIds && props.feed.subcategoryIds.length
        ? [
            buildFilterFieldByIds(
              'Subcategory',
              props.feed.subcategoryIds,
              filterSubcategories
            ),
          ]
        : [],
    [props.feed.subcategoryIds, filterSubcategories]
  );

  const appliedDocumentTypeFilters = useMemo(
    () =>
      props.feed.documentTypeIds && props.feed.documentTypeIds.length
        ? [
            buildFilterFieldByIds(
              'Document type',
              props.feed.documentTypeIds,
              filterDocumentTypes
            ),
          ]
        : [],
    [props.feed.documentTypeIds, filterDocumentTypes]
  );

  const appliedFilters = useMemo(
    () => [
      appliedFiltersTitle,
      ...appliedLanguageFilters,
      ...appliedRegionFilters,
      ...appliedCountryFilters,
      ...appliedAuthorityFilters,
      ...appliedSubcategoryFilters,
      ...appliedDocumentTypeFilters,
    ],
    [
      appliedFiltersTitle,
      appliedLanguageFilters,
      appliedRegionFilters,
      appliedCountryFilters,
      appliedAuthorityFilters,
      appliedSubcategoryFilters,
      appliedDocumentTypeFilters,
    ]
  );

  return <>{appliedFilters}</>;
};
