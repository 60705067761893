import { useMemo } from 'react';
import { Loading } from 'components/Loading';
import { FlexContainer } from 'styles/utils';
import { TurnaroundTimeDetailedReportData } from 'models';
import { ReportSummaryBlock } from './ReportSummaryBlock';
import { TurnaroundTimeSummaryChart } from './TurnaroundTimeSummaryChart';
import { TurnaroundTimeDetailsSummaryData } from './TurnaroundTimeDetailsSummaryData';
import { TurnaroundPageBucketTypes } from 'enums/TurnaroundPageBucketTypes';

interface TurnaroundTimeDetailsSummaryProps {
  data: TurnaroundTimeDetailedReportData;
  isLoading: boolean;
}

export const TurnaroundTimeDetailsSummary = (
  props: TurnaroundTimeDetailsSummaryProps
) => {
  const chartData = useMemo(() => {
    const { currentPeriodReportData: data } =
      props.data.detailedReportDataByPeriods;
    return {
      [TurnaroundPageBucketTypes.From1To15Pages]:
        data[TurnaroundPageBucketTypes.From1To15Pages].totalDevelopments,
      [TurnaroundPageBucketTypes.From16To100Pages]:
        data[TurnaroundPageBucketTypes.From16To100Pages].totalDevelopments,
      [TurnaroundPageBucketTypes.From101To200Pages]:
        data[TurnaroundPageBucketTypes.From101To200Pages].totalDevelopments,
      [TurnaroundPageBucketTypes.From201To1000Pages]:
        data[TurnaroundPageBucketTypes.From201To1000Pages].totalDevelopments,
      [TurnaroundPageBucketTypes.From1001To1700Pages]:
        data[TurnaroundPageBucketTypes.From1001To1700Pages].totalDevelopments,
      [TurnaroundPageBucketTypes.NonConformities]:
        data[TurnaroundPageBucketTypes.NonConformities].totalDevelopments,
    };
  }, [props.data.detailedReportDataByPeriods.currentPeriodReportData]);

  return (
    <div style={{ position: 'relative', marginBottom: '16px' }}>
      {props.isLoading && <Loading />}
      <FlexContainer style={{ padding: '0 16px' }}>
        <ReportSummaryBlock hasDarkBars>
          <TurnaroundTimeSummaryChart
            data={chartData}
            chartStyles={{ paddingRight: '20px' }}
          />
        </ReportSummaryBlock>
        <ReportSummaryBlock hasDarkBars>
          <TurnaroundTimeDetailsSummaryData
            title="Total Number of Developments"
            value={
              props.data.detailedReportDataByPeriods.currentPeriodReportData
                .totalDevelopments
            }
            differencePercentage={
              props.data.totalDevelopmentsDifferencePercentage
            }
          />
        </ReportSummaryBlock>
        <ReportSummaryBlock hasDarkBars>
          <TurnaroundTimeDetailsSummaryData
            title="Within Turnaround Time"
            value={
              props.data.detailedReportDataByPeriods.currentPeriodReportData
                .withinTurnaroundTimePercentage
            }
            differencePercentage={
              props.data.withinTurnaroundTimeDifferencePercentage
            }
            isPercentageValue
          />
        </ReportSummaryBlock>
        <ReportSummaryBlock hasDarkBars>
          <TurnaroundTimeDetailsSummaryData
            title="Average Turnaround Time"
            value={
              props.data.detailedReportDataByPeriods.currentPeriodReportData
                .averageTurnaroundTimeInMilliseconds
            }
            differencePercentage={
              props.data.averageTurnaroundTimeDifferencePercentage
            }
            isTimeValue
            isReverseDisadvantage
          />
        </ReportSummaryBlock>
      </FlexContainer>
    </div>
  );
};
