import { ThemeProvider } from '@emotion/react';
import theme, { Theme } from '../../lib/styles/theme';
import styled from '@emotion/styled';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Abstract,
  NewsBriefing,
  NewsBriefingResponse,
} from '../../lib/utils/Option';
import { Pagination } from 'components/Pagination';

interface AbstractProps {
  jurisdictions: { [key: string]: NewsBriefing };
  toggle: () => void;
  selectedJurisdiction?: string;
  handleSelectedAbstract: (abstract: Abstract) => void;
  handleSetJurisdiction: (jurisdiction: string) => void;
}

const JurisdictionContainer = styled.div({
  marginTop: '10px',
  fontWeight: 'bold',
  display: 'flex',
  marginBottom: '30px',
  borderBottom: `1px solid ${theme.font.color.gray4}`,
});

const RowComponent = styled.button({
  fontSize: '16px',
  fontWeight: 'bold',
  marginRight: '10px',
  background: 'white',
  border: '0',
  outline: '0',
  borderBottom: '2px solid transparent',
  transition: 'ease border-bottom 250ms',
  '&.active': {
    borderBottom: `2px solid ${theme.font.color.blue}`,
    opacity: '1',
    color: theme.font.color.blue,
  },
  '&:hover': {
    opacity: '1',
    color: theme.font.color.blue,
  },
});

const AbstractContainer = styled.div({
  marginBottom: '20px',
});

const CountContainer = styled.div({
  fontSize: '50px',
  fontWeight: 'bold',
  color: theme.sidebar.colorPallete.primary,
});

const AbstractTitle = styled.div({
  color: theme.font.color.blue,
  fontSize: '16px',
  textDecoration: 'underline',
  marginBottom: '8px',
  cursor: 'pointer',
  marginTop: '20px',
});

const IssuingAuthority = styled.div({
  marginBottom: '8px',
});

const EffectiveDate = styled.div({
  marginBottom: '16px',
});

const AbstractComponent: React.FC<AbstractProps> = ({
  jurisdictions,
  toggle,
  selectedJurisdiction,
  handleSelectedAbstract,
  handleSetJurisdiction,
}) => {
  const [selectedAbstracts, setSelectedAbstracts] = useState<Abstract[]>();
  const [displayAbstract, setDisplayAbstracts] = useState<Abstract[]>();

  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  useEffect(() => {
    setDisplayAbstracts(selectedAbstracts?.slice((page - 1) * 5, page * 5));
  }, [page, selectedAbstracts]);

  const handleSetAbstract = useCallback((abstract: Abstract) => {
    toggle();
    handleSelectedAbstract(abstract);
  }, []);

  useEffect(() => {
    Object.entries(jurisdictions).map(([key, value]) => {
      if (selectedJurisdiction === key) {
        setSelectedAbstracts(value.abstract);
        setDisplayAbstracts(value.abstract.slice(0, 5));
        setPage(1);
        setPageCount(Math.ceil(value.abstract.length / 5));
      }
    });
  }, [selectedJurisdiction]);

  const handleSelectJurisdiction = (jurisdiction: string) => {
    handleSetJurisdiction(jurisdiction);
  };

  return (
    <ThemeProvider theme={theme}>
      {displayAbstract?.map((abstract, index) => {
        return (
          <AbstractContainer key={abstract.id || index}>
            <AbstractTitle onClick={() => handleSetAbstract(abstract)}>
              {abstract.title}
            </AbstractTitle>
            <IssuingAuthority>
              {abstract.issuingAuthority.name}
            </IssuingAuthority>
            {abstract.effectiveDate && (
              <EffectiveDate>
                Effective Date: {abstract.effectiveDate[0].effectiveDate}
              </EffectiveDate>
            )}
          </AbstractContainer>
        );
      })}
      <Pagination
        id="AbstractPagination"
        currentPage={page}
        totalPages={pageCount}
        onPageChange={handlePageChange}
        disableDoubleNavigation
        justifyContent={'flex-end'}
      />
    </ThemeProvider>
  );
};

export default AbstractComponent;
