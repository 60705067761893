import { ConfirmationModal } from 'components/ConfirmationModal';
import { Development } from 'models';
import { routes } from 'navigation/routes';
import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Notification } from 'assets/notification.svg';
import { useAppDispatch } from 'store/hooks';
import {
  resetDevelopment,
  resetDevelopmentDetailSearchTerm,
} from 'store/development-detail/development-detail-slice';

interface NewDevelopmentVersionAvailableModalProps {
  isDisplayed: boolean;
  setIsDisplayed: Dispatch<SetStateAction<boolean>>;
  development: Development;
}

export const NewDevelopmentVersionAvailableModal = (
  props: NewDevelopmentVersionAvailableModalProps
) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <ConfirmationModal
      id="NewDevelopmentVersionAvailableModal"
      title={'New version available'}
      onPrimaryAction={() => {
        const latestDevelopmentVersion =
          props.development.developmentVersions &&
          props.development.developmentVersions.length
            ? props.development.developmentVersions.reduce((a, b) =>
                new Date(a.processingDate!) > new Date(b.processingDate!)
                  ? a
                  : b
              )
            : null;
        if (latestDevelopmentVersion) {
          props.setIsDisplayed(false);
          dispatch(resetDevelopment());
          dispatch(resetDevelopmentDetailSearchTerm());
          navigate(
            routes.developmentDetail.replace(
              ':developmentId',
              String(latestDevelopmentVersion.id)
            )
          );
        }
      }}
      onSecondaryAction={() => props.setIsDisplayed(false)}
      isDisplayed={props.isDisplayed}
      primaryText="Yes, view now"
      secondaryText="No, not now"
      titleIcon={<Notification style={{ marginRight: '8px' }} />}
    >
      <div style={{ padding: '24px' }}>
        <p style={{ marginBottom: '16px' }}>
          An update has been made to this development.
        </p>
        <p>
          <b>Would you like to view it now?</b>
        </p>
      </div>
    </ConfirmationModal>
  );
};
