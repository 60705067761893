import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DevelopmentCitation, PaginatedResponse } from 'models';
import { AppThunk, RootState } from '../store';
import { paginationConfig } from 'config/pagination-config';
import { getDevelopmentCitationsOfDevelopment } from 'services/development-citations/development-citations-service';
import { matchObligations } from 'services/developments/developments-service';

export interface DetailDevelopmentCitationState {
  developmentCitations: DevelopmentCitation[];
  allDevelopmentCitations: DevelopmentCitation[];
  totalRecords: number;
  page: number;
  perPage: number;
  searchTerm: string;
  areOnlyDirectMatchesDisplayed: boolean;
  selectedDevelopmentCitation: DevelopmentCitation | null;
}

const initialState: DetailDevelopmentCitationState = {
  developmentCitations: [],
  allDevelopmentCitations: [],
  totalRecords: 0,
  page: 1,
  perPage: paginationConfig.detailDevelopmentCitationsPerPage,
  searchTerm: '',
  areOnlyDirectMatchesDisplayed: false,
  selectedDevelopmentCitation: null,
};

export const getAllDetailDevelopmentCitationsAsync = (
  developmentId: number,
  regionId?: number,
  processingDate?: string
): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const developmentCitationState = getState().detailDevelopmentCitation;
    const environmentState = getState().environment;
    const selectedEnvironment = environmentState.selectedEnvironment;
    const page = developmentCitationState.page;
    const perPage = developmentCitationState.perPage;
    const searchTerm = developmentCitationState.searchTerm;
    const areOnlyDirectMatchesDisplayed =
      developmentCitationState.areOnlyDirectMatchesDisplayed;
    let developmentCitations = developmentCitationState.allDevelopmentCitations;
    if (page === 1 && (!developmentCitations || !developmentCitations.length)) {
      const response = await getDevelopmentCitationsOfDevelopment(
        developmentId,
        selectedEnvironment,
        regionId,
        processingDate
      );
      if (response.data) {
        developmentCitations = response.data.records;
        dispatch(setAllDetailDevelopmentCitations(developmentCitations));
      }
    }
    if (areOnlyDirectMatchesDisplayed) {
      developmentCitations = developmentCitations.filter(
        (developmentCitation) => {
          return developmentCitation.matches?.some(
            (developmentCitationMatch) => {
              return developmentCitationMatch.developmentCitationMatchNormalizedCitations?.some(
                (developmentCitationMatchNormalizedCitation) => {
                  return developmentCitationMatchNormalizedCitation.isDirectMatch;
                }
              );
            }
          );
        }
      );
    }
    if (searchTerm) {
      developmentCitations = developmentCitations.filter((x) =>
        x.citationName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    dispatch(
      setDetailDevelopmentCitations({
        records: developmentCitations,
        totalRecords: developmentCitations.length,
      })
    );
  };
};

export const matchObligationsAsync = (
  payload: number
): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    await matchObligations(payload);
  };
};

export const detailDevelopmentCitationSlice = createSlice({
  name: 'detail-development-citation',
  initialState,
  reducers: {
    setDetailDevelopmentCitations: (
      state,
      action: PayloadAction<PaginatedResponse<DevelopmentCitation> | null>
    ) => {
      state.developmentCitations = action.payload?.records ?? [];
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    setDetailDevelopmentCitationsPage: (
      state,
      action: PayloadAction<number>
    ) => {
      state.page = action.payload;
    },
    increaseDetailDevelopmentCitationsPage: (state) => {
      state.page = state.page + 1;
    },
    setDetailDevelopmentCitationsSearchTerm: (
      state,
      action: PayloadAction<string>
    ) => {
      state.searchTerm = action.payload;
    },
    setDetailDevelopmentCitationsAreOnlyDirectMatchesDisplayed: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.areOnlyDirectMatchesDisplayed = action.payload;
    },
    resetDetailCitationsDevelopmentCitations: (state) => {
      state.developmentCitations = [];
      state.totalRecords = 0;
      state.page = 1;
      state.searchTerm = '';
      state.areOnlyDirectMatchesDisplayed = false;
      state.selectedDevelopmentCitation = null;
      state.allDevelopmentCitations = [];
    },
    setAllDetailDevelopmentCitations: (
      state,
      action: PayloadAction<DevelopmentCitation[] | null>
    ) => {
      state.allDevelopmentCitations = action.payload || [];
    },
    setDetailSelectedDevelopmentCitation: (
      state,
      action: PayloadAction<DevelopmentCitation | null>
    ) => {
      state.selectedDevelopmentCitation = action.payload;
    },
  },
});

export const {
  setDetailDevelopmentCitations,
  setDetailDevelopmentCitationsPage,
  increaseDetailDevelopmentCitationsPage,
  setDetailDevelopmentCitationsSearchTerm,
  setDetailDevelopmentCitationsAreOnlyDirectMatchesDisplayed,
  resetDetailCitationsDevelopmentCitations,
  setAllDetailDevelopmentCitations,
  setDetailSelectedDevelopmentCitation,
} = detailDevelopmentCitationSlice.actions;

export const selectDetailDevelopmentCitations = (state: RootState) =>
  state.detailDevelopmentCitation.developmentCitations;
export const selectDetailDevelopmentCitationsTotalRecords = (
  state: RootState
) => state.detailDevelopmentCitation.totalRecords;
export const selectDetailDevelopmentCitationsPage = (state: RootState) =>
  state.detailDevelopmentCitation.page;
export const selectDetailDevelopmentCitationsPerPage = (state: RootState) =>
  state.detailDevelopmentCitation.perPage;
export const selectDetailDevelopmentCitationsSearchTerm = (state: RootState) =>
  state.detailDevelopmentCitation.searchTerm;
export const selectDetailDevelopmentCitationsAreOnlyDirectMatchesDisplayed = (
  state: RootState
) => state.detailDevelopmentCitation.areOnlyDirectMatchesDisplayed;
export const selectAllDetailDevelopmentCitations = (state: RootState) =>
  state.detailDevelopmentCitation.allDevelopmentCitations;
export const selectDetailSelectedDevelopmentCitation = (state: RootState) =>
  state.detailDevelopmentCitation.selectedDevelopmentCitation;

export default detailDevelopmentCitationSlice.reducer;
