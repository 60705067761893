import { Theme } from 'styles/themes';
import { ReactComponent as Info } from 'assets/info.svg';
import { Checkbox } from 'components/Checkbox';
import { ArrowDirections, Tooltip } from 'components/Tooltip';
import { CSSProperties, useState } from 'react';
import styled from '@emotion/styled';
import { HTMLAttrID } from 'models';

interface CheckboxInputProps extends HTMLAttrID {
  label: string;
  isSelected: boolean;
  setIsSelected: React.Dispatch<boolean>;
  fontSize?: number;
  whiteSpace?:
    | 'normal'
    | 'pre'
    | 'nowrap'
    | 'pre-wrap'
    | 'pre-line'
    | 'break-spaces';
  style?: CSSProperties;
  hasTooltip?: boolean;
  tooltipContent?: JSX.Element;
  tooltipStyles?: CSSProperties;
}

const CheckboxInputContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: '8px',
  border: `1px solid ${Theme.colors.grayBorder}`,
  borderRadius: Theme.borderRadius,
  height: '32px',
  width: '100%',
});

const TooltipContainer = styled.div({
  marginLeft: 'auto',
  position: 'relative',
  height: '20px',
});

export const CheckboxInput = (props: CheckboxInputProps) => {
  const [tooltipContainerElement, setTooltipContainerElement] =
    useState<HTMLDivElement | null>(null);
  const [isTooltipHovered, setIsTooltipHovered] = useState(false);

  return (
    <CheckboxInputContainer id={props.id} style={props.style}>
      <Checkbox
        id={`${props.id}Checkbox`}
        label={props.label}
        isChecked={props.isSelected}
        onChange={() => props.setIsSelected(!props.isSelected)}
        fontSize={props.fontSize}
        whiteSpace={props.whiteSpace}
      />
      {props.hasTooltip && (
        <TooltipContainer
          id={`${props.id}TooltipContainer`}
          ref={(tooltipContainer) =>
            setTooltipContainerElement(tooltipContainer)
          }
        >
          <Tooltip
            id={`${props.id}Tooltip`}
            relativeToElement={tooltipContainerElement}
            hasArrow
            arrowDirection={ArrowDirections.Down}
            style={props.tooltipStyles}
          >
            {props.tooltipContent}
          </Tooltip>
          <Info
            id={`${props.id}InfoIcon`}
            onMouseOver={() => setIsTooltipHovered(true)}
            onMouseLeave={() => setIsTooltipHovered(false)}
            style={{
              color: isTooltipHovered
                ? Theme.colors.secondaryColor
                : Theme.colors.grayDark,
            }}
          />
        </TooltipContainer>
      )}
    </CheckboxInputContainer>
  );
};
