import { useEffect, useMemo, useState } from 'react';
import { useNavigate, generatePath } from 'react-router-dom';
import { FlexContainer, HorizontalDivider } from 'styles/utils';
import {
  ReportsHeader,
  ReportsSubHeader,
  ReportsMainBody,
} from 'pages/reports/components';
import { turnaroundTimeReportLabels } from '../turnaround-time/labels';
import { PerformanceReportEntities } from 'enums';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  clearEntityDetails,
  getAllDataTurnaroundTimeReportAsync,
  resetCustomDateRangeInMilliseconds,
  resetTurnaroundTimeReportPage,
  selectCustomDateRangeInMilliseconds,
  selectPeriodProperty,
  selectReportByProperty,
  selectTurnaroundTimeReportPage,
  selectTurnaroundTimeReportPerPage,
  selectTurnaroundTimeReportSearchTerm,
  selectTurnaroundTimeReportSortByDirection,
  selectTurnaroundTimeReportSortByProperty,
  selectTurnaroundTimeReportTotalRecords,
  selectTurnaroundTimeRows,
  selectTurnaroundTimeSummaryData,
  setCustomDateRangeInMilliseconds,
  setPeriodProperty,
  setReportByProperty,
  setTurnaroundTimeReportPage,
  setTurnaroundTimeReportSearchTerm,
} from 'store/turnaround-time-report/turnaround-time-report-slice';
import { Loading } from 'components/Loading';
import { TurnaroundTimeSummary } from './components/TurnaroundTimeSummary';
import { TurnaroundTimeTable } from './components/TurnaroundTimeTable';
import { reportByOptions } from './report-by-options';
import { Pagination } from 'components/Pagination';
import { PerformanceReportEntity } from 'models';
import { routes } from 'navigation/routes';
import { DatePickerModal } from 'components/DatePicker';
import {
  DateRange,
  dateRangeToNormalizedDateRangeInMilliseconds,
  dateRangeToNormalizedString,
} from 'utils/date';
import {
  clearFilterPickerEntries,
  clearTurnaroundTimeActiveFilters,
  selectTurnaroundTimeActiveFilters,
} from 'store/turnaround-time-report/turnaround-time-filter-slice';
import { filterKeys } from 'config/filter-keys';

export const TurnaroundTimeReport = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const searchTerm = useAppSelector(selectTurnaroundTimeReportSearchTerm);
  const reportByProperty = useAppSelector(selectReportByProperty);
  const periodProperty = useAppSelector(selectPeriodProperty);
  const customDateRangeInMilliseconds = useAppSelector(
    selectCustomDateRangeInMilliseconds
  );
  const summaryData = useAppSelector(selectTurnaroundTimeSummaryData);
  const turnaroundTimeRows = useAppSelector(selectTurnaroundTimeRows);
  const page = useAppSelector(selectTurnaroundTimeReportPage);
  const perPage = useAppSelector(selectTurnaroundTimeReportPerPage);
  const totalRecords = useAppSelector(selectTurnaroundTimeReportTotalRecords);
  const sortByProperty = useAppSelector(
    selectTurnaroundTimeReportSortByProperty
  );
  const sortByDirection = useAppSelector(
    selectTurnaroundTimeReportSortByDirection
  );
  const turnaroundTimeActiveFilters = useAppSelector(
    selectTurnaroundTimeActiveFilters
  );
  const [isLoadingSummary, setIsLoadingSummary] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDatePickerDisplayed, setIsDatePickerDisplayed] = useState(false);
  const [rangePeriodText, setRangePeriodText] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null); // Error state accepts string or null

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setIsLoadingSummary(true);
        await dispatch(getAllDataTurnaroundTimeReportAsync());
      } catch (err: unknown) {
        if (err instanceof Error) {
          setError(err.message); // Accessing `message` property of `Error`
        } else {
          setError('An unexpected error occurred'); // Handling non-Error cases
        }
      } finally {
        setIsLoading(false);
        setIsLoadingSummary(false);
      }
    };
    fetchData();
  }, [
    sortByProperty,
    sortByDirection,
    page,
    periodProperty,
    customDateRangeInMilliseconds,
    searchTerm,
    reportByProperty,
  ]);

  useEffect(() => {
    if (customDateRangeInMilliseconds) {
      const customDateRange: Partial<DateRange> = {
        startDate: customDateRangeInMilliseconds.startDate
          ? new Date(customDateRangeInMilliseconds.startDate)
          : undefined,
        endDate: customDateRangeInMilliseconds.endDate
          ? new Date(customDateRangeInMilliseconds.endDate)
          : undefined,
      };
      setRangePeriodText(dateRangeToNormalizedString(customDateRange));
    } else {
      setRangePeriodText(null);
    }
  }, [customDateRangeInMilliseconds]);

  const reportByOptionEntity = useMemo(() => {
    return reportByOptions[reportByProperty];
  }, [reportByProperty]);

  const reportBySearchBarLabel = useMemo(() => {
    return reportByOptionEntity.searchBarText;
  }, [reportByOptionEntity]);

  const handleSearchChange = (searchText: string) => {
    dispatch(setTurnaroundTimeReportSearchTerm(searchText));
  };

  const handleSetPeriod = (period: string) => {
    dispatch(resetCustomDateRangeInMilliseconds());
    dispatch(setPeriodProperty(period));
  };

  const handleSetReportByProperty = (
    reportEntity: PerformanceReportEntities
  ) => {
    dispatch(setReportByProperty(reportEntity));
  };

  const handlePageChange = (page: number) => {
    dispatch(setTurnaroundTimeReportPage(page));
  };

  const handleResetPage = () => {
    dispatch(resetTurnaroundTimeReportPage());
  };

  const handleClickRow = (row: PerformanceReportEntity) => {
    const isCountry = reportByProperty === PerformanceReportEntities.Country;
    const hasPreviousCountryFilter =
      turnaroundTimeActiveFilters[filterKeys.country]?.length === 1;
    const isDifferentCountryThanPrevious =
      hasPreviousCountryFilter &&
      row.id !== turnaroundTimeActiveFilters[filterKeys.country]?.[0];
    if (
      isCountry &&
      hasPreviousCountryFilter &&
      isDifferentCountryThanPrevious
    ) {
      dispatch(clearFilterPickerEntries());
      dispatch(clearTurnaroundTimeActiveFilters());
    }
    dispatch(clearEntityDetails());
    navigate(
      generatePath(routes.turnaroundTimeDetails, {
        id: String(row.id),
        entityType: reportByOptionEntity.urlText,
      })
    );
  };

  const handleOpenDatePicker = () => {
    setIsDatePickerDisplayed(true);
  };

  const handleCloseDatePicker = () => {
    setIsDatePickerDisplayed(false);
  };

  const handleConfirmDatePicker = (range: Partial<DateRange>) => {
    dispatch(
      setCustomDateRangeInMilliseconds(
        dateRangeToNormalizedDateRangeInMilliseconds(range)
      )
    );
    handleCloseDatePicker();
  };

  return (
    <>
      <FlexContainer
        flexWrap="nowrap"
        flexDirection="column"
        style={{
          width: '100%',
          flex: 1,
          overflowX: 'hidden',
        }}
      >
        <ReportsHeader
          breadcrumb={[{ text: turnaroundTimeReportLabels.title }]}
        />
        <div style={{ padding: '0 16px' }}>
          <HorizontalDivider />
        </div>
        <ReportsSubHeader
          {...turnaroundTimeReportLabels}
          periodProps={{
            period: rangePeriodText ?? periodProperty,
            setPeriod: handleSetPeriod,
            onSelectPeriod: handleResetPage,
            onSelectCustomPeriod: handleOpenDatePicker,
          }}
          hasNoBorders
        />
        <TurnaroundTimeSummary
          data={summaryData}
          isLoading={isLoadingSummary}
        />
        <ReportsSubHeader
          {...turnaroundTimeReportLabels}
          searchBarLabel={`${turnaroundTimeReportLabels.searchBarLabel} ${reportBySearchBarLabel}...`}
          searchProps={{
            searchTerm: searchTerm,
            onSearchChange: handleSearchChange,
          }}
          reportByProps={{
            reportBy: reportByProperty,
            setReportBy: handleSetReportByProperty,
          }}
          hasNoBorders
        />
        <ReportsMainBody
          style={{
            padding: 0,
            backgroundColor: 'white',
          }}
        >
          {isLoading && <Loading />}
          <FlexContainer style={{ height: 'calc(100% - 48px)' }}>
            <div
              style={{
                flexGrow: 1,
                backgroundColor: 'white',
                padding: '16px',
                position: 'relative',
              }}
            >
              <TurnaroundTimeTable
                reportByProperty={reportByProperty}
                sortDirection={sortByDirection}
                sortField={sortByProperty}
                rows={turnaroundTimeRows}
                onReachBottom={() => {}}
                onClickRow={handleClickRow}
                hasMoreRows={page < Math.ceil(totalRecords / perPage)}
                setIsLoading={setIsLoading}
              />
            </div>
          </FlexContainer>
          <Pagination
            id="TurnaroundTimePagination"
            currentPage={page}
            totalPages={Math.ceil(totalRecords / perPage)}
            onPageChange={handlePageChange}
            justifyContent="center"
          />
        </ReportsMainBody>
      </FlexContainer>
      <DatePickerModal
        isOpen={isDatePickerDisplayed}
        onChange={() => {}}
        onConfirm={handleConfirmDatePicker}
        onCancel={handleCloseDatePicker}
      />
    </>
  );
};
