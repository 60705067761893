import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { paginationConfig } from 'config/pagination-config';
import { PerformanceReportEntities, SortByDirection } from 'enums';
import {
  ActiveFilters,
  FilterEntry,
  PaginatedResponse,
  PerformanceReportEntity,
  TurnaroundTimeDetailedReportData,
  TurnaroundTimeExtendedData,
  TurnaroundTimeReportData,
} from 'models';
import {
  getAuthoritiesWithTurnaroundTimeReportData,
  getAuthorityByIdWithTurnaroundTimeExtendedData,
  getAuthorityByIdWithTurnaroundTimeReportData,
} from 'services/authorities/authorities-service';
import {
  getCountriesWithTurnaroundTimeReportData,
  getCountryByIdWithTurnaroundTimeExtendedData,
  getCountryByIdWithTurnaroundTimeReportData,
} from 'services/countries/countries-service';
import {
  getDocumentTypeByIdWithTurnaroundTimeExtendedData,
  getDocumentTypeByIdWithTurnaroundTimeReportData,
  getDocumentTypesWithTurnaroundTimeReportData,
} from 'services/document-types/document-types-service';
import {
  DateRangeInMilliseconds,
  getSubtractionFromDateInMilliseconds,
  dateRangeInMillisecondsStringToDateRangeInMilliseconds,
} from 'utils/date';
import { HttpResponse } from 'utils/http/HttpResponse';
import { updateElementInArrayIfExists } from 'utils/array';
import { AppThunk, RootState } from '../store';
import { periodOptions } from 'config/report-period-options';
import { filterKeys } from 'config/filter-keys';
import { adjustMillisecondsTimezoneOffset } from 'helpers/filters';

const initializedTurnaroundTimeReportData: TurnaroundTimeReportData = {
  totalDevelopments: 0,
  totalWithin2hours: 0,
  totalWithin24hours: 0,
  totalWithin36hours: 0,
  totalWithin60hours: 0,
  totalWithin4days: 0,
  totalMoreThan4days: 0,
  totalFrom1To15Pages: 0,
  totalFrom16To100Pages: 0,
  totalFrom101To200Pages: 0,
  totalFrom201To1000Pages: 0,
  totalFrom1001To1700Pages: 0,
  totalMoreThan1700Pages: 0,
  totalNonConformities: 0,
};

const initializedTurnaroundTimeDetailedReportData: TurnaroundTimeDetailedReportData =
  {
    totalDevelopmentsDifferencePercentage: null,
    averageTurnaroundTimeDifferencePercentage: null,
    withinTurnaroundTimeDifferencePercentage: null,
    detailedReportDataByPeriods: {
      currentPeriodReportData: {
        totalDevelopments: 0,
        averageTurnaroundTimeInMilliseconds: null,
        withinTurnaroundTimePercentage: null,
        From1To15Pages: {
          totalDevelopments: 0,
          averageTurnaroundTimeInMilliseconds: null,
          withinTurnaroundTimePercentage: null,
        },
        From16To100Pages: {
          totalDevelopments: 0,
          averageTurnaroundTimeInMilliseconds: null,
          withinTurnaroundTimePercentage: null,
        },
        From101To200Pages: {
          totalDevelopments: 0,
          averageTurnaroundTimeInMilliseconds: null,
          withinTurnaroundTimePercentage: null,
        },
        From201To1000Pages: {
          totalDevelopments: 0,
          averageTurnaroundTimeInMilliseconds: null,
          withinTurnaroundTimePercentage: null,
        },
        From1001To1700Pages: {
          totalDevelopments: 0,
          averageTurnaroundTimeInMilliseconds: null,
          withinTurnaroundTimePercentage: null,
        },
        MoreThan1700Pages: {
          totalDevelopments: 0,
          averageTurnaroundTimeInMilliseconds: null,
          withinTurnaroundTimePercentage: null,
        },
        NonConformities: {
          totalDevelopments: 0,
          averageTurnaroundTimeInMilliseconds: null,
          withinTurnaroundTimePercentage: null,
        },
      },
      previousPeriodReportData: {
        totalDevelopments: 0,
        averageTurnaroundTimeInMilliseconds: null,
        withinTurnaroundTimePercentage: null,
        From1To15Pages: {
          totalDevelopments: 0,
          averageTurnaroundTimeInMilliseconds: null,
          withinTurnaroundTimePercentage: null,
        },
        From16To100Pages: {
          totalDevelopments: 0,
          averageTurnaroundTimeInMilliseconds: null,
          withinTurnaroundTimePercentage: null,
        },
        From101To200Pages: {
          totalDevelopments: 0,
          averageTurnaroundTimeInMilliseconds: null,
          withinTurnaroundTimePercentage: null,
        },
        From201To1000Pages: {
          totalDevelopments: 0,
          averageTurnaroundTimeInMilliseconds: null,
          withinTurnaroundTimePercentage: null,
        },
        From1001To1700Pages: {
          totalDevelopments: 0,
          averageTurnaroundTimeInMilliseconds: null,
          withinTurnaroundTimePercentage: null,
        },
        MoreThan1700Pages: {
          totalDevelopments: 0,
          averageTurnaroundTimeInMilliseconds: null,
          withinTurnaroundTimePercentage: null,
        },
        NonConformities: {
          totalDevelopments: 0,
          averageTurnaroundTimeInMilliseconds: null,
          withinTurnaroundTimePercentage: null,
        },
      },
    },
  };

export interface TurnaroundTimeReportState {
  reportByProperty: PerformanceReportEntities;
  periodProperty: string;
  customDateRangeInMilliseconds?: Partial<DateRangeInMilliseconds>;
  fromDateInMilliseconds?: number;
  toDateInMilliseconds?: number;
  searchTerm: string;
  sortByProperty: string;
  sortByDirection: SortByDirection;
  page: number;
  previousPage: number;
  perPage: number;
  isReportDataFromAverageDevelopmentsPerDay: boolean;
  totalRecords: number;
  turnaroundTimeRows: PerformanceReportEntity[];
  turnaroundTimeSummaryData: TurnaroundTimeReportData;
  turnaroundTimeDetailedData: TurnaroundTimeDetailedReportData;
  entityDetails?: PerformanceReportEntity;
  turnaroundTimeActiveFilters: ActiveFilters;
  filterPickerEntries: FilterEntry[];
  triggerGetEntityDetailsAsync: number;
}

const initialPeriodFromUrl = new URLSearchParams(window.location.search).get(
  'period'
);
const customDateRangeFromUrl =
  initialPeriodFromUrl &&
  initialPeriodFromUrl.includes('-') &&
  !periodOptions[initialPeriodFromUrl]
    ? dateRangeInMillisecondsStringToDateRangeInMilliseconds(
        initialPeriodFromUrl
      )
    : undefined;

const initialState: TurnaroundTimeReportState = {
  reportByProperty: PerformanceReportEntities.Country,
  periodProperty:
    new URLSearchParams(window.location.search).get('period') || 'sevenDays',
  customDateRangeInMilliseconds: customDateRangeFromUrl || undefined,
  fromDateInMilliseconds: customDateRangeFromUrl
    ? customDateRangeFromUrl.startDate
    : getSubtractionFromDateInMilliseconds(
        new Date(),
        periodOptions[
          new URLSearchParams(window.location.search).get('period') ||
            'sevenDays'
        ].durationObject
      ),
  toDateInMilliseconds: customDateRangeFromUrl?.endDate,
  searchTerm: '',
  sortByProperty: 'newDevelopmentsCount',
  sortByDirection: SortByDirection.DESC,
  page: 1,
  previousPage: 1,
  perPage: paginationConfig.turnaroundTimeReportRowsPerPage,
  totalRecords: 0,
  isReportDataFromAverageDevelopmentsPerDay: false,
  turnaroundTimeRows: [],
  turnaroundTimeSummaryData: initializedTurnaroundTimeReportData,
  turnaroundTimeDetailedData: initializedTurnaroundTimeDetailedReportData,
  turnaroundTimeActiveFilters: {},
  filterPickerEntries: [],
  triggerGetEntityDetailsAsync: 0,
};

export const getAllDataTurnaroundTimeReportAsync = (): AppThunk<
  Promise<PaginatedResponse<PerformanceReportEntity> | null>
> => {
  return async (dispatch, getState) => {
    const reportByProperty = getState().turnaroundTimeReport.reportByProperty;
    const searchTerm = getState().turnaroundTimeReport.searchTerm;
    const page = getState().turnaroundTimeReport.page;
    const perPage = getState().turnaroundTimeReport.perPage;
    const isReportDataFromAverageDevelopmentsPerDay =
      getState().turnaroundTimeReport.isReportDataFromAverageDevelopmentsPerDay;
    const sortByProperty = getState().turnaroundTimeReport.sortByProperty;
    const sortByDirection = getState().turnaroundTimeReport.sortByDirection;
    const { fromDateInMilliseconds, toDateInMilliseconds } =
      getState().turnaroundTimeReport;

    let responsePromise: (
      searchTerm: string,
      sortByProperty: string,
      sortByDirection: SortByDirection,
      page: number,
      perPage: number,
      isReportDataFromAverageDevelopmentsPerDay: boolean,
      reportDataFromDateInMilliseconds?: number,
      reportDataToDateInMilliseconds?: number,
      accessToken?: string
    ) => Promise<
      HttpResponse<
        | (PaginatedResponse<PerformanceReportEntity> & {
            totalTurnaroundTimeData?: TurnaroundTimeReportData;
          })
        | null
      >
    >;

    switch (reportByProperty) {
      case PerformanceReportEntities.Country:
        responsePromise = getCountriesWithTurnaroundTimeReportData;
        break;
      case PerformanceReportEntities.Authority:
        responsePromise = getAuthoritiesWithTurnaroundTimeReportData;
        break;
      case PerformanceReportEntities.DocumentType:
        responsePromise = getDocumentTypesWithTurnaroundTimeReportData;
        break;
      default:
        responsePromise = getCountriesWithTurnaroundTimeReportData;
        break;
    }

    const response = await responsePromise(
      searchTerm,
      sortByProperty,
      sortByDirection,
      page,
      perPage,
      isReportDataFromAverageDevelopmentsPerDay,
      adjustMillisecondsTimezoneOffset(fromDateInMilliseconds),
      adjustMillisecondsTimezoneOffset(toDateInMilliseconds)
    );
    if (response.data) {
      dispatch(setTurnaroundTimeReportRows(response.data));
      if (response.data.totalTurnaroundTimeData) {
        dispatch(
          setTurnaroundTimeSummaryData(response.data.totalTurnaroundTimeData)
        );
      }
    }
    return response.data;
  };
};

export const getEntityDetailsAsync = (
  id: number,
  entityType: string
): AppThunk<
  Promise<{
    reportEntity: PerformanceReportEntity;
    turnaroundTimeDetailedReportData?: TurnaroundTimeDetailedReportData;
  } | null>
> => {
  return async (dispatch, getState) => {
    const { fromDateInMilliseconds, toDateInMilliseconds } =
      getState().turnaroundTimeReport;
    const { turnaroundTimeActiveFilters } = getState().turnaroundTimeFilter;
    const turnaroundTimeReportActiveFilters: { [x: string]: number[] } = {};

    let responsePromise: (
      id: number,
      reportDataFromDateInMilliseconds?: number,
      reportDataToDateInMilliseconds?: number,
      turnaroundTimeReportActiveFilters?: { [x: string]: number[] }
    ) => Promise<
      HttpResponse<{
        reportEntity: PerformanceReportEntity;
        turnaroundTimeDetailedReportData?: TurnaroundTimeDetailedReportData;
      } | null>
    >;

    switch (entityType) {
      case PerformanceReportEntities.Country:
        const filterTypes: ('authority' | 'subcategory' | 'documentType')[] = [
          'authority',
          'subcategory',
          'documentType',
        ];
        filterTypes.forEach((filterType) => {
          if (
            turnaroundTimeActiveFilters[filterKeys[filterType]] &&
            Array.isArray(
              turnaroundTimeActiveFilters[filterKeys[filterType]]
            ) &&
            turnaroundTimeActiveFilters[filterKeys[filterType]].length
          ) {
            turnaroundTimeReportActiveFilters[filterType] = [
              ...turnaroundTimeActiveFilters[filterKeys[filterType]],
            ] as number[];
          }
        });
        responsePromise = getCountryByIdWithTurnaroundTimeReportData;
        break;
      case PerformanceReportEntities.Authority:
        responsePromise = getAuthorityByIdWithTurnaroundTimeReportData;
        break;
      case PerformanceReportEntities.DocumentType:
        responsePromise = getDocumentTypeByIdWithTurnaroundTimeReportData;
        break;
      default:
        responsePromise = getCountryByIdWithTurnaroundTimeReportData;
        break;
    }

    const response = await responsePromise(
      id,
      adjustMillisecondsTimezoneOffset(fromDateInMilliseconds),
      adjustMillisecondsTimezoneOffset(toDateInMilliseconds),
      turnaroundTimeReportActiveFilters
    );

    if (response.data) {
      dispatch(setEntityDetails(response.data.reportEntity));
      if (response.data.turnaroundTimeDetailedReportData) {
        dispatch(
          setTurnaroundTimeDetailedData(
            response.data.turnaroundTimeDetailedReportData
          )
        );
      } else {
        dispatch(
          setTurnaroundTimeDetailedData(
            initializedTurnaroundTimeDetailedReportData
          )
        );
      }
    }

    return response.data;
  };
};

export const getEntityExtendedDataAsync = (
  id: number,
  entityType: string
): AppThunk<Promise<TurnaroundTimeExtendedData | null>> => {
  return async (dispatch, getState) => {
    const { fromDateInMilliseconds, toDateInMilliseconds } =
      getState().turnaroundTimeReport;

    let responsePromise: (
      id: number,
      reportDataFromDateInMilliseconds?: number,
      reportDataToDateInMilliseconds?: number,
      accessToken?: string
    ) => Promise<HttpResponse<TurnaroundTimeExtendedData | null>>;

    switch (entityType) {
      case PerformanceReportEntities.Country:
        responsePromise = getCountryByIdWithTurnaroundTimeExtendedData;
        break;
      case PerformanceReportEntities.Authority:
        responsePromise = getAuthorityByIdWithTurnaroundTimeExtendedData;
        break;
      case PerformanceReportEntities.DocumentType:
        responsePromise = getDocumentTypeByIdWithTurnaroundTimeExtendedData;
        break;
      default:
        responsePromise = getCountryByIdWithTurnaroundTimeExtendedData;
        break;
    }

    const response = await responsePromise(
      id,
      adjustMillisecondsTimezoneOffset(fromDateInMilliseconds),
      adjustMillisecondsTimezoneOffset(toDateInMilliseconds)
    );

    if (response.data) {
      dispatch(
        setTurnaroundTimeReportRowExtendedData({
          id,
          extendedData: response.data,
        })
      );
    }

    return response.data;
  };
};

export const turnaroundTimeReportSlice = createSlice({
  name: 'turnaround-time-report',
  initialState,
  reducers: {
    setPeriodProperty: (state, action: PayloadAction<string>) => {
      state.periodProperty = action.payload;
      state.fromDateInMilliseconds = getSubtractionFromDateInMilliseconds(
        new Date(),
        periodOptions[action.payload].durationObject
      );
    },
    setReportByProperty: (
      state,
      action: PayloadAction<PerformanceReportEntities>
    ) => {
      state.page = 1;
      state.previousPage = 1;
      state.reportByProperty = action.payload;
    },
    setTurnaroundTimeReportSearchTerm: (
      state,
      action: PayloadAction<string>
    ) => {
      state.page = 1;
      state.previousPage = 1;
      state.searchTerm = action.payload;
    },
    setTurnaroundTimeReportSortByProperty: (
      state,
      action: PayloadAction<string>
    ) => {
      state.page = 1;
      state.previousPage = 1;
      state.sortByProperty = action.payload;
    },
    setTurnaroundTimeReportSortByDirection: (
      state,
      action: PayloadAction<SortByDirection>
    ) => {
      state.page = 1;
      state.previousPage = 1;
      state.sortByDirection = action.payload;
    },
    setTurnaroundTimeReportRows: (
      state,
      action: PayloadAction<PaginatedResponse<PerformanceReportEntity>>
    ) => {
      state.turnaroundTimeRows = action.payload.records;
      state.totalRecords = action.payload.totalRecords;
    },
    addTurnaroundTimeReportRows: (
      state,
      action: PayloadAction<PaginatedResponse<PerformanceReportEntity>>
    ) => {
      state.turnaroundTimeRows = [
        ...state.turnaroundTimeRows,
        ...action.payload.records,
      ];
      state.totalRecords = action.payload.totalRecords ?? 0;
      state.previousPage = state.page;
    },
    setTurnaroundTimeReportRowExtendedData: (
      state,
      action: PayloadAction<{
        id: number;
        extendedData?: TurnaroundTimeExtendedData;
      }>
    ) => {
      const turnaroundTimeRow = state.turnaroundTimeRows.find(
        (x) => x.id === action.payload.id
      );
      if (turnaroundTimeRow) {
        turnaroundTimeRow.turnaroundTimeExtendedData =
          action.payload.extendedData;
        state.turnaroundTimeRows = updateElementInArrayIfExists(
          state.turnaroundTimeRows,
          turnaroundTimeRow,
          'id'
        );
      }
    },
    setTurnaroundTimeSummaryData: (
      state,
      action: PayloadAction<TurnaroundTimeReportData>
    ) => {
      state.turnaroundTimeSummaryData = action.payload;
    },
    increaseTurnaroundTimeReportPage: (state) => {
      state.page = state.page + 1;
    },
    setTurnaroundTimeReportPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    resetTurnaroundTimeReportPage: (state) => {
      state.page = 1;
      state.previousPage = 1;
    },
    setCustomDateRangeInMilliseconds: (
      state,
      action: PayloadAction<Partial<DateRangeInMilliseconds>>
    ) => {
      const customDateRangeInMilliseconds = action.payload;
      state.customDateRangeInMilliseconds = customDateRangeInMilliseconds;

      let fromDateInMilliseconds = customDateRangeInMilliseconds?.startDate;
      let toDateInMilliseconds = customDateRangeInMilliseconds?.endDate;
      if (!fromDateInMilliseconds && toDateInMilliseconds) {
        fromDateInMilliseconds = toDateInMilliseconds;
        toDateInMilliseconds = undefined;
      }

      state.fromDateInMilliseconds = fromDateInMilliseconds;
      state.toDateInMilliseconds = toDateInMilliseconds;
    },
    resetCustomDateRangeInMilliseconds: (state) => {
      state.customDateRangeInMilliseconds = undefined;
      state.fromDateInMilliseconds = getSubtractionFromDateInMilliseconds(
        new Date(),
        periodOptions[state.periodProperty].durationObject
      );
      state.toDateInMilliseconds = undefined;
    },
    setIsReportDataFromAverageDevelopmentsPerDay: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.page = 1;
      state.previousPage = 1;
      state.isReportDataFromAverageDevelopmentsPerDay = action.payload;
    },
    setEntityDetails: (
      state,
      action: PayloadAction<PerformanceReportEntity>
    ) => {
      state.entityDetails = action.payload;
    },
    clearEntityDetails: (state) => {
      state.entityDetails = undefined;
    },
    setTurnaroundTimeDetailedData: (
      state,
      action: PayloadAction<TurnaroundTimeDetailedReportData>
    ) => {
      state.turnaroundTimeDetailedData = action.payload;
    },
    resetTurnaroundTimeDetailedData: (state) => {
      state.turnaroundTimeDetailedData =
        initializedTurnaroundTimeDetailedReportData;
    },
    setTriggerGetEntityDetailsAsync: (state) => {
      state.triggerGetEntityDetailsAsync =
        state.triggerGetEntityDetailsAsync + 1;
    },
  },
});

export const {
  setPeriodProperty,
  setReportByProperty,
  setTurnaroundTimeReportSearchTerm,
  setTurnaroundTimeReportSortByDirection,
  setTurnaroundTimeReportSortByProperty,
  increaseTurnaroundTimeReportPage,
  setTurnaroundTimeReportPage,
  resetTurnaroundTimeReportPage,
  setTurnaroundTimeReportRows,
  addTurnaroundTimeReportRows,
  setTurnaroundTimeReportRowExtendedData,
  setTurnaroundTimeSummaryData,
  setCustomDateRangeInMilliseconds,
  resetCustomDateRangeInMilliseconds,
  setIsReportDataFromAverageDevelopmentsPerDay,
  setEntityDetails,
  clearEntityDetails,
  setTurnaroundTimeDetailedData,
  resetTurnaroundTimeDetailedData,
  setTriggerGetEntityDetailsAsync,
} = turnaroundTimeReportSlice.actions;

export const selectPeriodProperty = (state: RootState) =>
  state.turnaroundTimeReport.periodProperty;
export const selectReportByProperty = (state: RootState) =>
  state.turnaroundTimeReport.reportByProperty;
export const selectTurnaroundTimeReportSearchTerm = (state: RootState) =>
  state.turnaroundTimeReport.searchTerm;
export const selectTurnaroundTimeReportSortByProperty = (state: RootState) =>
  state.turnaroundTimeReport.sortByProperty;
export const selectTurnaroundTimeReportSortByDirection = (state: RootState) =>
  state.turnaroundTimeReport.sortByDirection;
export const selectTurnaroundTimeRows = (state: RootState) =>
  state.turnaroundTimeReport.turnaroundTimeRows;
export const selectTurnaroundTimeSummaryData = (state: RootState) =>
  state.turnaroundTimeReport.turnaroundTimeSummaryData;
export const selectTurnaroundTimeReportPage = (state: RootState) =>
  state.turnaroundTimeReport.page;
export const selectTurnaroundTimeReportPreviousPage = (state: RootState) =>
  state.turnaroundTimeReport.previousPage;
export const selectTurnaroundTimeReportPerPage = (state: RootState) =>
  state.turnaroundTimeReport.perPage;
export const selectCustomDateRangeInMilliseconds = (state: RootState) =>
  state.turnaroundTimeReport.customDateRangeInMilliseconds;
export const selectFromDateInMilliseconds = (state: RootState) =>
  state.turnaroundTimeReport.fromDateInMilliseconds;
export const selectToDateInMilliseconds = (state: RootState) =>
  state.turnaroundTimeReport.toDateInMilliseconds;
export const selectIsReportDataFromAverageDevelopmentsPerDay = (
  state: RootState
) => state.turnaroundTimeReport.isReportDataFromAverageDevelopmentsPerDay;
export const selectTurnaroundTimeReportTotalRecords = (state: RootState) =>
  state.turnaroundTimeReport.totalRecords;
export const selectEntityDetails = (state: RootState) =>
  state.turnaroundTimeReport.entityDetails;
export const selectTurnaroundTimeDetailedData = (state: RootState) =>
  state.turnaroundTimeReport.turnaroundTimeDetailedData;
export const selectTriggerGetEntityDetailsAsync = (state: RootState) =>
  state.turnaroundTimeReport.triggerGetEntityDetailsAsync;

export default turnaroundTimeReportSlice.reducer;
