import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { CursorPointer, HorizontalDivider } from 'styles/utils';
import { ReactComponent as Legend } from 'assets/legend.svg';
import { ReactComponent as Collapse } from 'assets/collapse.svg';
import { ReactComponent as Filters } from 'assets/strawberry-menu.svg';
import { ReactComponent as Refresh } from 'assets/refresh.svg';
import { ArrowDirections, Tooltip } from 'components/Tooltip';
import { useCallback, useState } from 'react';
import { Searchbar } from 'components/Searchbar';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectDetailDevelopmentCitationsAreOnlyDirectMatchesDisplayed,
  selectDetailDevelopmentCitationsSearchTerm,
  setDetailDevelopmentCitationsAreOnlyDirectMatchesDisplayed,
  setDetailDevelopmentCitationsSearchTerm,
} from 'store/detail-development-citation/detail-development-citation-slice';
import { Checkbox } from 'components/Checkbox';
import { selectAuthenticationResponse } from 'store/authentication/authentication-slice';
import { UserRoles } from 'enums';

interface DetailRightSidebarHeaderProps {
  setDocumentCitationsHeaderHeight: React.Dispatch<
    React.SetStateAction<number>
  >;
  developmentCitationsCount: number;
  setTriggerCollapseObligationMatchesCards: React.Dispatch<
    React.SetStateAction<number>
  >;
  setIsLegendOptionsDisplayed: React.Dispatch<React.SetStateAction<boolean>>;
  setIsReprocessDevelopmentModalDisplayed: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

const TooltipContainer = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});

export const DetailRightSidebarHeader = (
  props: DetailRightSidebarHeaderProps
) => {
  const dispatch = useAppDispatch();
  const [legendButtonTooltipContainer, setLegendButtonTooltipContainer] =
    useState<HTMLSpanElement | null>(null);
  const [
    collapseAllButtonTooltipContainer,
    setCollapseAllButtonTooltipContainer,
  ] = useState<HTMLSpanElement | null>(null);
  const [filtersButtonTooltipContainer, setFiltersButtonTooltipContainer] =
    useState<HTMLSpanElement | null>(null);
  const [isFilterOptionsDisplayed, setIsFilterOptionsDisplayed] =
    useState<boolean>(false);
  const [reprocessButtonTooltipContainer, setReprocessButtonTooltipContainer] =
    useState<HTMLSpanElement | null>(null);
  const searchTerm = useAppSelector(selectDetailDevelopmentCitationsSearchTerm);
  const areOnlyDirectMatchesDisplayed = useAppSelector(
    selectDetailDevelopmentCitationsAreOnlyDirectMatchesDisplayed
  );

  const authenticationResponse = useAppSelector(selectAuthenticationResponse);
  const hasSuperadminRole = authenticationResponse?.roles?.includes(
    UserRoles.Superadmin
  );
  const hasDevelopmentReprocesserRole = authenticationResponse?.roles?.includes(
    UserRoles.DevelopmentReprocesser
  );
  const canReprocessDevelopments =
    hasSuperadminRole || hasDevelopmentReprocesserRole;

  const sendLegendOptionsDisplayed = useCallback(() => {
    props.setIsLegendOptionsDisplayed(true);
  }, []);

  const sendDevelopmentForReprocessing = useCallback(() => {
    props.setIsReprocessDevelopmentModalDisplayed(true);
  }, []);

  return (
    <div
      id="DevelopmentDetailRightSidebarHeaderContainer"
      ref={(documentCitationsHeader) => {
        if (documentCitationsHeader) {
          props.setDocumentCitationsHeaderHeight(
            documentCitationsHeader.clientHeight + 1
          );
        }
      }}
      style={{ padding: '15px' }}
    >
      <div
        id="DevelopmentDetailRightSidebarHeaderTitleContainer"
        style={{ display: 'flex', marginBottom: '16px' }}
      >
        <span
          id="DevelopmentDetailRightSidebarHeaderTitleText"
          style={{
            fontWeight: 'bold',
            margin: 'auto 0',
          }}
        >
          Citations{' '}
          <span
            id="DevelopmentDetailRightSidebarHeaderTitleCitationCount"
            style={{ color: Theme.colors.secondaryColor }}
          >
            ({props.developmentCitationsCount})
          </span>
        </span>
        <div
          id="DevelopmentDetailRightSidebarHeaderActionsContainer"
          style={{ display: 'flex', marginLeft: 'auto' }}
        >
          <CursorPointer
            id="DevelopmentDetailRightSidebarHeaderFiltersButton"
            style={{
              display: 'block',
              height: '24px',
              userSelect: 'none',
              position: 'relative',
              marginRight: canReprocessDevelopments ? '16px' : '0px',
            }}
            onClick={sendLegendOptionsDisplayed}
          >
            <Legend
              id="DevelopmentDetailRightSidebarHeaderLegendIcon"
              height={32}
              color={Theme.colors.grayDark}
            />
            <TooltipContainer
              id="DevelopmentDetailRightSidebarHeaderLegendTooltipContainer"
              ref={(legendButtonTooltipContainer) =>
                setLegendButtonTooltipContainer(legendButtonTooltipContainer)
              }
            >
              <Tooltip
                id={`DevelopmentDetailRightSidebarHeaderLegendTooltip`}
                relativeToElement={legendButtonTooltipContainer}
                hasArrow
                arrowDirection={ArrowDirections.Up}
              >
                <span
                  id={`DevelopmentDetailRightSidebarHeaderLegendTooltipSpan`}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  Legend
                </span>
              </Tooltip>
            </TooltipContainer>
          </CursorPointer>
          <CursorPointer
            id="DevelopmentDetailRightSidebarHeaderCollapseAllButton"
            style={{
              display: 'block',
              height: '24px',
              userSelect: 'none',
              position: 'relative',
              marginRight: '16px',
            }}
            onClick={() =>
              props.setTriggerCollapseObligationMatchesCards(
                (currentValue) => currentValue + 1
              )
            }
          >
            <Collapse id="DevelopmentDetailRightSidebarHeaderCollapseAllIcon" />
            <TooltipContainer
              id="DevelopmentDetailRightSidebarHeaderCollapseAllTooltipContainer"
              ref={(collapseAllButtonTooltipContainer) =>
                setCollapseAllButtonTooltipContainer(
                  collapseAllButtonTooltipContainer
                )
              }
            >
              <Tooltip
                id={`DevelopmentDetailRightSidebarHeaderCollapseAllTooltip`}
                relativeToElement={collapseAllButtonTooltipContainer}
                hasArrow
                arrowDirection={ArrowDirections.Up}
              >
                <span
                  id={`DevelopmentDetailRightSidebarHeaderCollapseAllTooltipSpan`}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  Collapse all
                </span>
              </Tooltip>
            </TooltipContainer>
          </CursorPointer>
          <CursorPointer
            id="DevelopmentDetailRightSidebarHeaderFiltersButton"
            style={{
              display: 'block',
              height: '24px',
              userSelect: 'none',
              position: 'relative',
              marginRight: canReprocessDevelopments ? '16px' : '0px',
            }}
            onClick={() =>
              setIsFilterOptionsDisplayed(!isFilterOptionsDisplayed)
            }
          >
            <Filters
              id="DevelopmentDetailRightSidebarHeaderFiltersIcon"
              height={24}
              color={Theme.colors.grayDark}
            />
            <TooltipContainer
              id="DevelopmentDetailRightSidebarHeaderFiltersTooltipContainer"
              ref={(filtersButtonTooltipContainer) =>
                setFiltersButtonTooltipContainer(filtersButtonTooltipContainer)
              }
            >
              <Tooltip
                id={`DevelopmentDetailRightSidebarHeaderFiltersTooltip`}
                relativeToElement={filtersButtonTooltipContainer}
                hasArrow
                arrowDirection={ArrowDirections.Up}
                isArrowOnRightCorner
              >
                <span
                  id={`DevelopmentDetailRightSidebarHeaderFiltersTooltipSpan`}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  Filters
                </span>
              </Tooltip>
            </TooltipContainer>
          </CursorPointer>
          {canReprocessDevelopments && (
            <CursorPointer
              id="DevelopmentDetailRightSidebarHeaderReprocessButton"
              style={{
                display: 'block',
                height: '24px',
                userSelect: 'none',
                position: 'relative',
              }}
              onClick={sendDevelopmentForReprocessing}
            >
              <Refresh
                id="DevelopmentDetailRightSidebarHeaderReprocessIcon"
                height={24}
                color={Theme.colors.grayDark}
              />
              <TooltipContainer
                id="DevelopmentDetailRightSidebarHeaderReprocessTooltipContainer"
                ref={(reprocessButtonTooltipContainer) =>
                  setReprocessButtonTooltipContainer(
                    reprocessButtonTooltipContainer
                  )
                }
              >
                <Tooltip
                  id={`DevelopmentDetailRightSidebarHeaderReprocessTooltip`}
                  relativeToElement={reprocessButtonTooltipContainer}
                  hasArrow
                  arrowDirection={ArrowDirections.Up}
                  isArrowOnRightCorner
                >
                  <span
                    id={`DevelopmentDetailRightSidebarHeaderReprocessTooltipSpan`}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    Send for reprocessing
                  </span>
                </Tooltip>
              </TooltipContainer>
            </CursorPointer>
          )}
        </div>
      </div>
      <Searchbar
        id="DevelopmentDetailRightSidebarHeaderSearchbar"
        value={searchTerm}
        setValue={(value) => {
          dispatch(setDetailDevelopmentCitationsSearchTerm(value));
        }}
        placeholder={'Search in citations'}
      />
      {isFilterOptionsDisplayed && (
        <div
          id="DevelopmentDetailRightSidebarHeaderFilterOptionsContainer"
          style={{
            backgroundColor: Theme.colors.primaryBackgroundColor,
            marginTop: '16px',
            padding: '8px',
          }}
        >
          <div
            id="DevelopmentDetailRightSidebarHeaderFilterOptionsLabelContainer"
            style={{ display: 'flex', marginBottom: '12px' }}
          >
            <span
              id="DevelopmentDetailRightSidebarHeaderFilterOptionsLabel"
              style={{
                fontWeight: 'bold',
                color: Theme.colors.primaryColor,
                margin: 'auto 0',
              }}
            >
              Filters
            </span>
          </div>
          <div>
            <Checkbox
              id="DevelopmentDetailRightSidebarHeaderFilterOptionsDirectMatchesOnly"
              label={'Direct matches only'}
              isChecked={areOnlyDirectMatchesDisplayed}
              onChange={() => {
                dispatch(
                  setDetailDevelopmentCitationsAreOnlyDirectMatchesDisplayed(
                    !areOnlyDirectMatchesDisplayed
                  )
                );
              }}
            />
          </div>
        </div>
      )}
      <HorizontalDivider
        id="DevelopmentDetailRightSidebarHeaderHorizontalDivider"
        style={{ marginTop: '16px' }}
      />
    </div>
  );
};
