import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAuthenticationResponse } from 'store/authentication/authentication-slice';
import { getCurrentUserEnvironmentsAsync } from 'store/environment/environment-slice';
import { ReactComponent as EnvironmentIcon } from 'assets/environment.svg';
import { FlexContainer } from 'styles/utils';
import { Dropdown, DropdownArrow } from 'components/Dropdown';
import { EnvironmentPicker } from './components';
import { Environment } from 'models';

interface EnvironmentBannerProps {
  selectedEnvironment: Environment;
}

const EnvironmentBannerContainer = styled.div<{
  color: string;
  backgroundColor: string;
}>((props) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: props.color,
  backgroundColor: props.backgroundColor,
}));

const EnvironmentBannerTitle = styled.div({
  marginLeft: '8px',
  fontWeight: 'bold',
  fontSize: Theme.fontSize,
  lineHeight: '20px',
});

export const EnvironmentBanner = (props: EnvironmentBannerProps) => {
  const dispatch = useAppDispatch();
  const authenticationResponse = useAppSelector(selectAuthenticationResponse);
  const environmentBannerContainerRef = useRef<HTMLDivElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const height = '40px';

  useEffect(() => {
    if (!!authenticationResponse) {
      dispatch(
        getCurrentUserEnvironmentsAsync(authenticationResponse.accessToken)
      );
    }
  }, [authenticationResponse]);

  const handleToggleDropdown = useCallback(() => {
    setIsDropdownOpen(!isDropdownOpen);
  }, [isDropdownOpen]);

  const handleCloseDropdown = useCallback(() => {
    setIsDropdownOpen(false);
  }, []);

  const colors: { color: string; backgroundColor: string } = useMemo(() => {
    if (props.selectedEnvironment.canViewAllChannels) {
      if (props.selectedEnvironment.defaultsToAutomaticDevelopmentCitations) {
        return {
          color: Theme.colors.primaryColorDark,
          backgroundColor: Theme.colors.secondaryColorLight,
        };
      } else {
        return {
          color: Theme.colors.white,
          backgroundColor: Theme.colors.redLight,
        };
      }
    } else {
      if (props.selectedEnvironment.canViewReprocessedDevelopments) {
        return {
          color: Theme.colors.white,
          backgroundColor: Theme.colors.purpleLight,
        };
      } else {
        return {
          color: Theme.colors.secondaryColorLight,
          backgroundColor: Theme.colors.primaryColorDark,
        };
      }
    }
  }, [
    props.selectedEnvironment.canViewAllChannels,
    props.selectedEnvironment.defaultsToAutomaticDevelopmentCitations,
  ]);

  return (
    <EnvironmentBannerContainer
      id="EnvironmentBanner"
      ref={environmentBannerContainerRef}
      color={colors.color}
      backgroundColor={colors.backgroundColor}
    >
      <FlexContainer
        id="EnvironmentBannerInnerContainer"
        onClick={handleToggleDropdown}
        style={{
          height,
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          cursor: 'pointer',
          userSelect: 'none',
        }}
      >
        <EnvironmentIcon
          id="EnvironmentBannerIcon"
          style={{ width: Theme.fontSize }}
        />
        <EnvironmentBannerTitle id="EnvironmentBannerTitle">
          {props.selectedEnvironment.name}
        </EnvironmentBannerTitle>
        <FlexContainer
          id="EnvironmentBannerDropdownArrowContainerContainer"
          style={{
            marginTop: isDropdownOpen ? '-4px' : '4px',
            marginLeft: '8px',
            userSelect: 'none',
          }}
        >
          <DropdownArrow
            id="EnvironmentBannerDropdownArrow"
            isExpanded={isDropdownOpen}
            color="inherit"
          />
        </FlexContainer>
        <Dropdown
          id="EnvironmentBannerDropdown"
          isOpen={isDropdownOpen}
          setIsOpen={setIsDropdownOpen}
          containerRef={environmentBannerContainerRef}
          transform={'translate(-50%, 0)'}
          style={{
            top: height,
            border: `1px solid ${Theme.colors.grayBorder}`,
            borderRadius: '0px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            cursor: 'default',
          }}
        >
          <EnvironmentPicker onSelect={handleCloseDropdown} />
        </Dropdown>
      </FlexContainer>
    </EnvironmentBannerContainer>
  );
};
