import styled from '@emotion/styled';
import { Table, Tbody, Th, Thead, Tr } from 'components/Table';
import { useState } from 'react';
import { Theme } from 'styles/themes';
import { FlexContainer } from 'styles/utils';
import { OverflowYAutoContainer } from 'components/OverflowYAutoContainer';
import { TurnaroundTimeRow } from './TurnaroundTimeRow';
import { Source } from 'models/reports/Source';

interface TableProps {
  handleRowClick: (row: Source) => void;
  data: Source[];
}

const StyledTable = styled(Table)({
  border: `1px solid ${Theme.colors.grayBorder}`,
  position: 'relative',
  boxShadow: 'none',
});

const StyledTr = styled(Tr)({
  height: '48px',
});

const StyledTh = styled(Th)({
  border: `1px solid ${Theme.colors.grayBorder}`,
  borderCollapse: 'collapse',
});

export const TurnaroundTimeTable = ({ data, handleRowClick }: TableProps) => {
  const [scrollableContainer, setScrollableContainer] =
    useState<HTMLDivElement | null>(null);

  return (
    <OverflowYAutoContainer
      ref={setScrollableContainer}
      style={{
        height: '100%',
        width: '100%',
        borderBottom: `solid 1px ${Theme.colors.grayBorder}`,
      }}
    >
      {scrollableContainer && (
        <StyledTable>
          <Thead>
            <StyledTr>
              <StyledTh
                padding="12px 16px"
                style={{ fontWeight: 'bold', minWidth: '110px' }}
              >
                <FlexContainer>Source Id</FlexContainer>
              </StyledTh>
              <StyledTh
                padding="12px 16px"
                style={{ fontWeight: 'bold', minWidth: '200px' }}
              >
                <FlexContainer>Jurisdiction</FlexContainer>
              </StyledTh>
              <StyledTh padding="12px 16px" style={{ fontWeight: 'bold' }}>
                <FlexContainer>Issuing Authority</FlexContainer>
              </StyledTh>
              <StyledTh padding="12px 16px" style={{ fontWeight: 'bold' }}>
                <FlexContainer>Subcategory</FlexContainer>
              </StyledTh>
              <StyledTh
                padding="12px 16px"
                style={{ fontWeight: 'bold', minWidth: '200px' }}
              >
                <FlexContainer>Document Type</FlexContainer>
              </StyledTh>
              <StyledTh
                padding="12px 16px"
                style={{ fontWeight: 'bold', minWidth: '150px' }}
              >
                <FlexContainer>Environment</FlexContainer>
              </StyledTh>
            </StyledTr>
          </Thead>
          <Tbody>
            {data &&
              data.length > 0 &&
              data.map((row: Source) => {
                const tableRows: JSX.Element[] = [
                  <TurnaroundTimeRow
                    key={`row_${row.RowId}`}
                    data={row}
                    onClickRow={handleRowClick}
                  />,
                ];
                return tableRows;
              })}
          </Tbody>
        </StyledTable>
      )}
    </OverflowYAutoContainer>
  );
};
