import styled from '@emotion/styled';
import { ModalInnerContainer } from 'components/Modal';
import { differenceInDays, format, formatDistance } from 'date-fns';
import { DevelopmentEvent } from 'models';
import { HTMLAttrID } from 'models';
import { useMemo } from 'react';
import { Theme } from 'styles/themes';

interface DevelopmentEventCardProps extends HTMLAttrID {
  developmentEvent: DevelopmentEvent;
}

const DevelopmentEventHeader = styled.div({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

const DevelopmentEventSubHeader = styled(DevelopmentEventHeader)({
  marginTop: '6px',
});

const DevelopmentEventName = styled.span({
  fontSize: Theme.fontSize,
  color: Theme.colors.secondaryColor,
  width: 'calc(60% - 5px)',
  fontWeight: 'bold',
});

const DevelopmentEventCreatedDate = styled.span({
  fontSize: Theme.fontSize,
  color: Theme.colors.grayDarker,
  width: 'calc(40% - 5px)',
  wordBreak: 'break-word',
});

export const DevelopmentEventCard = (props: DevelopmentEventCardProps) => {
  const createdDateFormatted = useMemo(() => {
    const createdAt = props.developmentEvent.createdAt;
    if (differenceInDays(new Date(), new Date(createdAt)) < 1) {
      const timeAgo = formatDistance(new Date(createdAt), new Date(), {
        addSuffix: true,
      });
      return timeAgo;
    } else {
      return format(new Date(createdAt), 'MMM d, yyyy');
    }
  }, [props.developmentEvent.createdAt]);

  return (
    <ModalInnerContainer id={props.id} flexDirection="column">
      <DevelopmentEventHeader id={`${props.id}Header`}>
        <DevelopmentEventName id={`${props.id}Name`}>
          {props.developmentEvent.name}
        </DevelopmentEventName>
        <DevelopmentEventCreatedDate id={`${props.id}CreatedDate`}>
          {createdDateFormatted}
        </DevelopmentEventCreatedDate>
      </DevelopmentEventHeader>
      {props.developmentEvent.userId && (
        <DevelopmentEventSubHeader id={`${props.id}SubHeader`}>
          <span
            id={`${props.id}UserName`}
          >{`${props.developmentEvent.userFirstName} ${props.developmentEvent.userLastName}`}</span>
        </DevelopmentEventSubHeader>
      )}
    </ModalInnerContainer>
  );
};
