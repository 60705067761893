import { CSSProperties, useMemo } from 'react';
import styled from '@emotion/styled';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import { Theme } from 'styles/themes';
import { TurnaroundPageBucketTypes } from 'enums/TurnaroundPageBucketTypes';
import { FlexContainer } from 'styles/utils';
import {
  turnaroundPageLegendColors,
  turnaroundPageSummaryLabels,
} from '../labels';

interface TurnaroundTimeSummaryChartProps {
  data: { [x in TurnaroundPageBucketTypes]?: number };
  chartStyles?: CSSProperties;
}

const BulletSquare = styled.div((props: { color: string }) => ({
  height: '7px',
  width: '7px',
  marginRight: '6px',
  backgroundColor: props.color,
}));

const BulletText = styled.div({
  fontSize: '12px',
  lineHeight: '16px',
  marginTop: '1px',
});

const BulletedData = (props: { fill: string; name: string }) => {
  return (
    <FlexContainer style={{ alignItems: 'center' }}>
      <BulletSquare color={props.fill} />
      <BulletText>{props.name}</BulletText>
    </FlexContainer>
  );
};

export const TurnaroundTimeSummaryChart = (
  props: TurnaroundTimeSummaryChartProps
) => {
  const legendDataSets = useMemo(
    () =>
      Object.keys(props.data).map((key) => ({
        key: key,
        name: turnaroundPageSummaryLabels[key],
        value: props.data[key as TurnaroundPageBucketTypes],
        fill: turnaroundPageLegendColors[key],
      })),
    [props.data]
  );

  const chartDataSets = useMemo(
    () => legendDataSets.filter((x) => !!x.value).reverse(),
    [legendDataSets]
  );

  const hasOnlyOneChartSection = useMemo(() => {
    let setsWithTruthyValue = 0;
    for (const dataSet of legendDataSets) {
      if (dataSet.value) {
        setsWithTruthyValue++;
      }
    }
    return setsWithTruthyValue === 1;
  }, [legendDataSets]);

  return (
    <FlexContainer
      flexDirection="row"
      style={{
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <FlexContainer flexDirection="column">
        {legendDataSets.map((dataSet) => (
          <BulletedData {...dataSet} />
        ))}
      </FlexContainer>
      <FlexContainer style={{ padding: '4px', ...props.chartStyles }}>
        <PieChart width={80} height={80}>
          <Pie
            data={chartDataSets}
            innerRadius={24}
            outerRadius={40}
            startAngle={hasOnlyOneChartSection ? 90 : 95}
            endAngle={hasOnlyOneChartSection ? 450 : 455}
            paddingAngle={hasOnlyOneChartSection ? 0 : 4}
            dataKey="value"
          >
            {chartDataSets.map((dataSet) => (
              <Cell {...dataSet} style={{ outline: 'none' }} />
            ))}
          </Pie>
          <Tooltip
            isAnimationActive={false}
            position={{ x: 85, y: 25 }}
            wrapperStyle={{
              outline: 'none',
              zIndex: 1,
            }}
            contentStyle={{
              backgroundColor: Theme.colors.black,
              borderColor: Theme.colors.black,
              borderRadius: Theme.borderRadius,
              padding: '0 8px',
            }}
            itemStyle={{
              color: Theme.colors.white,
              fontFamily: 'sans-serif',
              fontSize: '13px',
              lineHeight: '19px',
            }}
          />
        </PieChart>
      </FlexContainer>
    </FlexContainer>
  );
};
