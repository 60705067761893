import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { getAllDevelopmentEvents } from 'services/development-events/development-events-service';
import { DevelopmentEvent, PaginatedResponse } from 'models';
import { paginationConfig } from 'config/pagination-config';
import { SortByDirection } from 'enums';
import { developmentKeys } from 'config/development-keys';

export interface ReviewHistoryState {
  developmentEvents: DevelopmentEvent[];
  developmentEventsTotalRecords: number;
  developmentEventsSortByProperty: string;
  developmentEventsSortByDirection: SortByDirection;
  developmentEventsPage: number;
  developmentEventsPerPage: number;
  triggerDevelopmentEventsUpdate: number;
}

const initialState: ReviewHistoryState = {
  developmentEvents: [],
  developmentEventsTotalRecords: 0,
  developmentEventsSortByProperty: developmentKeys.createdAt,
  developmentEventsSortByDirection: SortByDirection.DESC,
  developmentEventsPage: 1,
  developmentEventsPerPage:
    paginationConfig.reviewHistoryDevelopmentEventsPerPage,
  triggerDevelopmentEventsUpdate: 0,
};

export const getAllReviewHistoryDevelopmentEventsAsync = (
  developmentId: number,
  developmentProcessingDate: string
): AppThunk<Promise<PaginatedResponse<DevelopmentEvent> | null>> => {
  return async (dispatch, getState) => {
    const sortByProperty =
      getState().reviewHistory.developmentEventsSortByProperty;
    const sortByDirection =
      getState().reviewHistory.developmentEventsSortByDirection;
    const page = getState().reviewHistory.developmentEventsPage;
    const perPage = getState().reviewHistory.developmentEventsPerPage;
    const response = await getAllDevelopmentEvents(
      sortByProperty,
      sortByDirection,
      page,
      perPage,
      developmentId,
      developmentProcessingDate
    );
    if (page > 1) {
      dispatch(addReviewHistoryDevelopmentEvents(response.data));
    } else {
      dispatch(setReviewHistoryDevelopmentEvents(response.data));
    }
    return response.data;
  };
};

export const reviewHistorySlice = createSlice({
  name: 'review-history',
  initialState,
  reducers: {
    setReviewHistoryDevelopmentEvents: (
      state,
      action: PayloadAction<PaginatedResponse<DevelopmentEvent> | null>
    ) => {
      state.developmentEvents = action.payload?.records ?? [];
      state.developmentEventsTotalRecords = action.payload?.totalRecords ?? 0;
    },
    addReviewHistoryDevelopmentEvents: (
      state,
      action: PayloadAction<PaginatedResponse<DevelopmentEvent> | null>
    ) => {
      state.developmentEvents = state.developmentEvents.concat(
        action.payload?.records ?? []
      );
      state.developmentEventsTotalRecords = action.payload?.totalRecords ?? 0;
    },
    increaseReviewHistoryDevelopmentEventsPage: (state) => {
      state.developmentEventsPage = state.developmentEventsPage + 1;
    },
    resetReviewHistoryDevelopmentEventsPage: (state) => {
      state.developmentEventsPage = 1;
    },
    setTriggerReviewHistoryDevelopmentEventsUpdate: (state) => {
      state.triggerDevelopmentEventsUpdate =
        state.triggerDevelopmentEventsUpdate + 1;
    },
  },
});

export const {
  setReviewHistoryDevelopmentEvents,
  addReviewHistoryDevelopmentEvents,
  increaseReviewHistoryDevelopmentEventsPage,
  resetReviewHistoryDevelopmentEventsPage,
  setTriggerReviewHistoryDevelopmentEventsUpdate,
} = reviewHistorySlice.actions;

export const selectReviewHistoryDevelopmentEvents = (state: RootState) =>
  state.reviewHistory.developmentEvents;
export const selectReviewHistoryDevelopmentEventsTotalRecords = (
  state: RootState
) => state.reviewHistory.developmentEventsTotalRecords;
export const selectReviewHistoryDevelopmentEventsPage = (state: RootState) =>
  state.reviewHistory.developmentEventsPage;
export const selectReviewHistoryDevelopmentEventsPerPage = (state: RootState) =>
  state.reviewHistory.developmentEventsPerPage;
export const selectTriggerReviewHistoryDevelopmentEventsUpdate = (
  state: RootState
) => state.reviewHistory.triggerDevelopmentEventsUpdate;

export default reviewHistorySlice.reducer;
