import styled from '@emotion/styled';
import { CSSProperties, HTMLAttributes } from 'react';
import { Theme } from 'styles/themes';

const ProgressBarContainer = styled.div<{ displaysPercentage: boolean }>(
  (props) => ({
    width: props.style?.width || '100%',
    height: props.displaysPercentage ? '40px' : '8px',
    backgroundColor: Theme.colors.primaryBackgroundColor,
    border: props.style?.border || `1px solid ${Theme.colors.grayBorder}`,
    borderRadius: props.displaysPercentage
      ? Theme.borderRadius
      : Theme.borderRadius - 2,
  })
);

const ProgressBarBody = styled.div<{
  percentage: number;
  displaysPercentage: boolean;
}>((props) => ({
  backgroundColor: Theme.colors.secondaryColorLight,
  height: '100%',
  alignContent: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  width: `${props.percentage}%`,
  minWidth: props.displaysPercentage ? '15%' : '0%',
}));

const Percentage = styled.p({
  display: 'inline-block',
  color: Theme.colors.white,
  fontWeight: 'bold',
  fontSize: Theme.fontSize,
  cursor: 'default',
  marginRight: '8px',
});

interface ProgressBarProps extends HTMLAttributes<HTMLDivElement> {
  percentage: number;
  displaysPercentage: boolean;
}

export const ProgressBar = (props: ProgressBarProps) => {
  const percentageStyle: CSSProperties = {};
  if (props.style?.fontSize) {
    percentageStyle.fontSize = props.style?.fontSize;
  }
  return (
    <ProgressBarContainer
      displaysPercentage={props.displaysPercentage}
      style={props.style}
    >
      <ProgressBarBody
        percentage={props.percentage}
        displaysPercentage={props.displaysPercentage}
      >
        {props.displaysPercentage && (
          <Percentage style={percentageStyle}>
            {Math.round(props.percentage)}%
          </Percentage>
        )}
      </ProgressBarBody>
    </ProgressBarContainer>
  );
};
