import styled from '@emotion/styled';
import { ReactComponent as CheckCircleOutlineSmaller } from 'assets/check-circle-outline-smaller.svg';
import { ReactComponent as CancelSmaller } from 'assets/cancel-smaller.svg';
import { ArrowDirections, Tooltip } from 'components/Tooltip';
import { Caption } from 'components/Typography';
import { Theme } from 'styles/themes';
import { RefObject, useMemo } from 'react';
import { PasswordValidity, validatePassword } from 'helpers/password';

const TooltipContent = styled.div({
  backgroundColor: Theme.colors.white,
  display: 'flex',
  flexDirection: 'column',
  color: Theme.colors.black,
  width: '200px',
  padding: 4,
});

interface PasswordTooltipProps {
  relativeToElement: RefObject<HTMLInputElement> | null;
}

const CheckCircle = styled(CheckCircleOutlineSmaller)({
  color: Theme.colors.secondaryColor,
});

const CancelIcon = styled(CancelSmaller)({
  color: Theme.colors.red,
});

const IconContainer = styled.div({
  width: 13,
  height: 13,
  marginRight: 4,
  display: 'block',
});

const CaptionEntry = styled.div({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  margin: '3px 0',
});

export const PasswordTooltip = (props: PasswordTooltipProps) => {
  let { relativeToElement } = props;

  if (!relativeToElement || !relativeToElement.current) return null;

  const password = relativeToElement.current.value;

  const requirements: PasswordValidity = useMemo(() => {
    return validatePassword(password);
  }, [password]);

  return (
    <Tooltip
      id="PasswordTooltip"
      style={{
        backgroundColor: Theme.colors.white,
        boxShadow: `1px 1px 3px ${Theme.colors.black}`,
      }}
      arrowDirection={ArrowDirections.Left}
      relativeToElement={relativeToElement.current}
    >
      <TooltipContent id="PasswordTooltipContent">
        <Caption id="PasswordTooltipMainCaption" bold>
          Password must contain:
        </Caption>
        <CaptionEntry id="PasswordTooltipCaptionEntry1">
          <IconContainer id="PasswordTooltipIconContainer1">
            {requirements.hasLength ? (
              <CheckCircle id="PasswordTooltipCheckCirle1" />
            ) : (
              <CancelIcon id="PasswordTooltipCancelIcon1" />
            )}
          </IconContainer>
          <Caption id="PasswordTooltipCaption1" noMargin>
            At least 8 characters
          </Caption>
        </CaptionEntry>
        <CaptionEntry id="PasswordTooltipCaptionEntry2">
          <IconContainer id="PasswordTooltipIconContainer2">
            {requirements.hasCasing ? (
              <CheckCircle id="PasswordTooltipCheckCirle2" />
            ) : (
              <CancelIcon id="PasswordTooltipCancelIcon2" />
            )}
          </IconContainer>
          <Caption id="PasswordTooltipCaption2" noMargin>
            Upper and lowercase
          </Caption>
        </CaptionEntry>
        <CaptionEntry id="PasswordTooltipCaptionEntry3">
          <IconContainer id="PasswordTooltipIconContainer3">
            {requirements.hasNumber ? (
              <CheckCircle id="PasswordTooltipCheckCirle3" />
            ) : (
              <CancelIcon id="PasswordTooltipCancelIcon3" />
            )}
          </IconContainer>
          <Caption id="PasswordTooltipCaption3" noMargin>
            At least 1 number
          </Caption>
        </CaptionEntry>
      </TooltipContent>
    </Tooltip>
  );
};
