import React, { ReactNode } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import { Abstract } from '../../lib/utils/Option';
import { useNavigate } from 'react-router-dom';
import {
  AbstractTitle,
  CloseIconContainer,
  ConfirmButton,
  ContainerTitle,
  EffectiveDate,
  FooterContainer,
  Header,
  HeaderTitle,
  IDContainer,
  MainContainer,
  ModalBox,
  ModalOverlay,
  SectionContainer,
  SectionContent,
} from './NewsBriefAbstractModal.styles';
import { routes } from 'navigation/routes';

interface ModalType {
  abstract?: Abstract;
  isOpen: boolean;
  toggle: () => void;
}

const NewsBriefAbstractModal: React.FC<ModalType> = ({
  isOpen,
  toggle,
  abstract,
}) => {
  const navigate = useNavigate();
  return (
    <>
      {isOpen && abstract && (
        <ModalOverlay>
          <ModalBox>
            <Header>
              <HeaderTitle>Abstract</HeaderTitle>
              <CloseIconContainer onClick={toggle}>
                <IoCloseOutline
                  size={30}
                  style={{ marginTop: '7px', marginRight: '7px' }}
                />
              </CloseIconContainer>
            </Header>
            <MainContainer>
              <AbstractTitle>{abstract.title}</AbstractTitle>
              <ContainerTitle>
                <div>{abstract.issuingAuthority.name}</div>
                <IDContainer>ID {abstract.id}</IDContainer>
              </ContainerTitle>
              {abstract.effectiveDate && (
                <EffectiveDate>
                  Effective Date: {abstract.effectiveDate[0].effectiveDate}
                </EffectiveDate>
              )}
              <SectionContainer>
                <ContainerTitle>Summary</ContainerTitle>
                <SectionContent>{abstract.summary}</SectionContent>
              </SectionContainer>
              <SectionContainer>
                <ContainerTitle>Key Changes</ContainerTitle>
                <SectionContent>
                  {abstract.keyChanges.split('\n').map((value: any) => {
                    return <p>{value}</p>;
                  })}
                </SectionContent>
              </SectionContainer>
            </MainContainer>
            <FooterContainer>
              <ConfirmButton
                onClick={() =>
                  navigate(
                    routes.developmentDetail.replace(
                      ':developmentId',
                      String(abstract.id)
                    )
                  )
                }
              >
                Open Development
              </ConfirmButton>
            </FooterContainer>
          </ModalBox>
        </ModalOverlay>
      )}
    </>
  );
};

export default NewsBriefAbstractModal;
