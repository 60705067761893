import { Input } from 'components/Input';
import { useFocus } from 'hooks/use-focus';
import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { ReactComponent as Search } from 'assets/search.svg';
import { ReactComponent as Close } from 'assets/close.svg';

export interface SearchbarProps {
  value?: string;
  setValue: React.Dispatch<string>;
  placeholder?: string;
  shouldDisplayCloseIcon?: boolean;
  padding?: string;
  id?: string;
  isDisabled?: boolean;
  title?: string;
}

const SearchbarContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '0px',
  height: '32px',
  borderRadius: Theme.borderRadius,
  position: 'relative',
  width: '100%',
});

const SearchIconContainer = styled.div({
  position: 'absolute',
  left: '12px',
  height: '16px',
  zIndex: 1,
});

const CloseIconContainer = styled.div({
  position: 'absolute',
  right: '12px',
  cursor: 'pointer',
  height: '16px',
});

export const Searchbar: React.FC<SearchbarProps> = (props: SearchbarProps) => {
  const [inputRef, setInputFocus] = useFocus();
  const [searchTerm, setSearchTerm] = useState(props.value || '');
  const shouldDisplayCloseIcon =
    typeof props.shouldDisplayCloseIcon === 'undefined'
      ? true
      : props.shouldDisplayCloseIcon;

  const clearText = () => {
    props.setValue('');
    setSearchTerm('');
    setInputFocus();
  };

  useEffect(() => {
    if (props.value !== undefined && props.value !== null) {
      setSearchTerm(props.value);
    }
  }, [props.value]);

  // eslint-disable-next-line
  const debounceHandler = useCallback(_.debounce(props.setValue, 620), []);

  const containerId = props.id ? `${props.id}Container` : undefined;
  const searchIconContainerId = props.id
    ? `${props.id}SearchIconContainer`
    : undefined;
  const searchIconId = props.id ? `${props.id}SearchIcon` : undefined;
  const closeIconContainerId = props.id
    ? `${props.id}CloseIconContainer`
    : undefined;
  const closeIconId = props.id ? `${props.id}CloseIcon` : undefined;

  return (
    <SearchbarContainer id={containerId}>
      <SearchIconContainer id={searchIconContainerId}>
        <Search id={searchIconId} />
      </SearchIconContainer>
      <Input
        id={props.id}
        inputRef={inputRef}
        style={{ padding: props.padding || '8px 32px 8px 28px' }}
        placeholder={props.placeholder || ''}
        value={searchTerm}
        setValue={(value) => {
          setSearchTerm(value);
          debounceHandler(value);
        }}
        isDisabled={props.isDisabled}
        title={props.title}
      />
      {shouldDisplayCloseIcon && props.value && props.value.length && (
        <CloseIconContainer id={closeIconContainerId}>
          <Close id={closeIconId} onClick={clearText} />
        </CloseIconContainer>
      )}
    </SearchbarContainer>
  );
};
