export const paginationConfig = {
  developmentsPerPage: 10,
  feedPickerFeedsPerPage: 8,
  languagesPerPage: 10,
  regionsPerPage: 10,
  countriesPerPage: 10,
  authoritiesPerPage: 10,
  subcategoriesPerPage: 10,
  documentTypesPerPage: 10,
  assignToTeamModalTeamsPerPage: 12,
  createTeamModalUsersPerPage: 12,
  managementFeedsPerPage: 10,
  managementUsersPerPage: 10,
  managementTeamsPerPage: 10,
  performanceReportRowsPerPage: 15,
  performanceReportDevelopmentsPerPage: 10,
  turnaroundTimeReportRowsPerPage: 10,
  turnaroundTimeReportDevelopmentsPerPage: 10,
  countriesDropdownPerPage: 8,
  teamsDropdownPerPage: 8,
  reviewHistoryDevelopmentEventsPerPage: 12,
  detailDevelopmentCitationsPerPage: 15,
  detailObligationsPerPage: 15,
};
