import styled from '@emotion/styled';
import {
  CloseIconContainer,
  Modal,
  ModalContentContainer,
  ModalInnerContainer,
  ModalTitle,
} from 'components/Modal';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Theme } from 'styles/themes';
import { ReactComponent as Close } from 'assets/close.svg';
import { FlexContainer } from 'styles/utils';
import { Button, OutlinedButton } from 'components/Button';
import { Textarea } from 'components/Textarea';
import { Feedback } from 'models';
import { createFeedback } from 'services/feedback/feedbacks-service';
import { routeNames, routes } from 'navigation/routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAuthenticationResponse } from 'store/authentication/authentication-slice';
import { displayToast } from 'store/toast/toast-slice';
import { Alert } from 'components/Alert';
import { setTriggerReviewHistoryDevelopmentEventsUpdate } from 'store/review-history/review-history-slice';

interface FeedbackModalProps {
  isDisplayed: boolean;
  setIsDisplayed: Dispatch<SetStateAction<boolean>>;
  isDiscardDevelopmentModal?: boolean;
}

const FeedbackModalContainer = styled.div({
  display: 'flex',
  width: '624px',
});

export const FeedbackModal = (props: FeedbackModalProps) => {
  const dispatch = useAppDispatch();
  const [feedbackText, setFeedbackText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const authenticationResponse = useAppSelector(selectAuthenticationResponse);

  const closeModal = () => {
    props.setIsDisplayed(false);
    setFeedbackText('');
    setIsLoading(false);
  };

  const isMainActionButtonDisabled = useMemo(() => {
    return !feedbackText && !props.isDiscardDevelopmentModal;
  }, [feedbackText]);

  const handleMainAction = async () => {
    if (authenticationResponse && authenticationResponse.id) {
      setIsLoading(true);
      const screen = window.location;
      let pathname = screen.pathname;
      let developmentId = undefined;
      const developmentDetailRouteWithoutParam =
        routes.developmentDetail.split(':')[0];
      if (pathname.includes(developmentDetailRouteWithoutParam)) {
        developmentId = Number(
          pathname.split(developmentDetailRouteWithoutParam)[1]
        );
        pathname = routes.developmentDetail;
      }
      const feedback: Feedback = {
        text: feedbackText,
        screenName: routeNames[pathname],
        screenUrl: screen.href,
        developmentId,
        userId: authenticationResponse.id,
      };
      try {
        if (!props.isDiscardDevelopmentModal) {
          await createFeedback(feedback);
          dispatch(
            displayToast({
              content: (
                <Alert id="FeedbackAlertToast">
                  <span id="FeedbackAlertMessage">
                    Feedback sent successfully!
                  </span>
                </Alert>
              ),
              rightDistance: 50,
            })
          );
        }
        dispatch(setTriggerReviewHistoryDevelopmentEventsUpdate());
        closeModal();
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  return (
    <Modal
      id="FeedbackModal"
      isDisplayed={props.isDisplayed}
      isLoading={isLoading}
    >
      <FeedbackModalContainer id="FeedbackModalContainer">
        <ModalContentContainer id="FeedbackModalContentContainer">
          <ModalInnerContainer id="FeedbackModalInnerContainer">
            <ModalTitle
              id="FeedbackModalTitle"
              withoutMarginBottom={true}
              color={Theme.colors.primaryColor}
            >
              {props.isDiscardDevelopmentModal
                ? 'Discard development'
                : 'Feedback'}
            </ModalTitle>
            <CloseIconContainer id="FeedbackModalCloseIconContainer">
              <Close id="FeedbackModalClose" onClick={() => closeModal()} />
            </CloseIconContainer>
          </ModalInnerContainer>
          <ModalInnerContainer id="FeedbackModalInnerContainer1">
            <FlexContainer
              id="FeedbackModalFlexContainer1"
              flexDirection="column"
              style={{ width: '100%' }}
            >
              <Textarea
                id="FeedbackModalTextarea"
                placeholder="Enter feedback..."
                value={feedbackText}
                setValue={setFeedbackText}
              />
            </FlexContainer>
          </ModalInnerContainer>
          <ModalInnerContainer
            id="FeedbackModalInnerContainer2"
            withoutBorderBottom={true}
            padding="12px 16px"
          >
            <FlexContainer
              id="FeedbackModalFlexContainer2"
              style={{ marginLeft: 'auto' }}
            >
              <OutlinedButton
                id="FeedbackModalCancelButton"
                color={Theme.colors.secondaryColor}
                style={{ marginRight: '10px' }}
                onClick={() => closeModal()}
              >
                Cancel
              </OutlinedButton>
              <Button
                id="FeedbackModalSendButton"
                onClick={handleMainAction}
                isDisabled={isMainActionButtonDisabled}
              >
                {props.isDiscardDevelopmentModal ? 'Send & Discard' : 'Send'}
              </Button>
            </FlexContainer>
          </ModalInnerContainer>
        </ModalContentContainer>
      </FeedbackModalContainer>
    </Modal>
  );
};
