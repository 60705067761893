import { Team } from 'models';
import styled from '@emotion/styled';
import { Table, Tbody, Td, Th, Thead, Tr, Row } from 'components/Table';
import { RefObject, useCallback, useRef, useState } from 'react';
import { Checkbox } from 'components/Checkbox';
import { SortByDirectionButton } from 'components/SortByDirectionButton';
import { Body } from 'components/Typography';
import { FlexContainer } from 'styles/utils';
import { BlueChipList } from 'components/Chip';
import { EllipsedSpan } from 'components/Typography';
import { CheckmarkTypes } from 'components/Checkbox/Checkbox';
import { OverflowYAutoContainer } from 'components/OverflowYAutoContainer';
import { ManagementTableProps } from 'pages/management/interfaces/ManagementTableProps';
import {
  setManagementTeamsSortByDirection,
  setManagementTeamsSortByProperty,
} from 'store/management-team/management-team-slice';

const NameContainer = styled(EllipsedSpan)({
  display: 'inline-block',
  maxWidth: '85%',
});

const TeamRow = (props: TeamRowProps) => {
  const { team, checked } = props;
  const [isHighlighted, setHighlighted] = useState(false);
  const handleHighlightChange = useCallback(
    (highlight: boolean) => () => setHighlighted(highlight),
    [setHighlighted]
  );

  const handleCheck = useCallback(() => {
    props.onSelectRow && props.onSelectRow({ ...team, checked: !checked });
  }, [checked, team]);

  return (
    <Tr
      id={`TeamManagementTableRow${team.id}`}
      onMouseEnter={handleHighlightChange(true)}
      onMouseLeave={handleHighlightChange(false)}
      onClick={handleCheck}
      isHighlighted={isHighlighted || checked}
      isFaded={props.isFaded}
      style={{ height: '48px' }}
    >
      <Td
        id={`TeamManagementTableRow${team.id}CheckboxTd`}
        style={{ padding: '12px 0 12px 16px' }}
      >
        <Checkbox
          id={`TeamManagementTableRow${team.id}Checkbox`}
          onChange={handleCheck}
          isChecked={!!checked}
        />
      </Td>
      <Td
        id={`TeamManagementTableRow${team.id}NameTd`}
        style={{ padding: '12px 16px' }}
      >
        <FlexContainer id={`TeamManagementTableRow${team.id}NameTdContainer`}>
          <NameContainer
            id={`TeamManagementTableRow${team.id}NameContainer`}
            size={16}
            noMargin
          >
            {team.name}
          </NameContainer>
        </FlexContainer>
      </Td>
      <Td
        id={`TeamManagementTableRow${team.id}FeedsTd`}
        style={{ padding: '9.5px 16px' }}
      >
        {team.teamFeeds?.length ? (
          <BlueChipList
            id={`TeamManagementTableRow${team.id}FeedsBlueChipList`}
            isSelected={checked || isHighlighted}
            items={team.teamFeeds}
            getLabel={(teamFeed) => teamFeed.feedName}
            cutterElement={props.cutterElementRef}
            maxWidth={275}
          />
        ) : (
          <Body
            id={`TeamManagementTableRow${team.id}NoFeedAssignedMessage`}
            noMargin
            color="grayDark"
            textStyle="italic"
          >
            No feed assigned
          </Body>
        )}
      </Td>
      <Td
        id={`TeamManagementTableRow${team.id}MembersTd`}
        style={{ padding: '12px 16px' }}
      >
        <Body id={`TeamManagementTableRow${team.id}Members`} noMargin>
          {team.usersCount}
        </Body>
      </Td>
    </Tr>
  );
};

export const TeamsTable = (props: TeamsTableProps) => {
  const {
    rows,
    onSelectAll,
    onSelectRow,
    sortDirection,
    sortField,
    areSomeChecked = false,
  } = props;

  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const checkAll = useCallback(() => {
    onSelectAll && onSelectAll();
  }, []);

  const handleCheck = useCallback((row: Row<Team>) => {
    onSelectRow && onSelectRow(row);
  }, []);

  const spotlitTeamIds = props.spotlitTeams?.map((t) => Number(t.id));

  const teamRows = rows.map((t) => (
    <TeamRow
      key={t.id}
      team={t}
      checked={t.checked}
      onSelectRow={handleCheck}
      isFaded={spotlitTeamIds && !spotlitTeamIds.includes(Number(t.id))}
      cutterElementRef={scrollableContainerRef}
    />
  ));

  return (
    <OverflowYAutoContainer
      id="TeamManagementTable"
      ref={scrollableContainerRef}
      style={{
        height: 'calc(100% - 48px)',
        width: '100%',
        marginBottom: '16px',
      }}
    >
      <Table
        id="TeamManagementTable"
        style={{ position: 'relative', tableLayout: 'fixed' }}
      >
        <Thead id="TeamManagementTableThead">
          <Tr id="TeamManagementTableTr" style={{ height: '48px' }}>
            <Th
              id="TeamManagementTableThSelectAll"
              width="24px"
              padding="12px 0 12px 16px"
            >
              <Checkbox
                id="TeamManagementTableSelectAllCheckbox"
                checkmarkType={CheckmarkTypes.minusSign}
                onChange={checkAll}
                isChecked={areSomeChecked}
              />
            </Th>
            <Th id="TeamManagementTableNameTh" width="30%" padding="12px 16px">
              <FlexContainer id="TeamManagementTableNameThContainer">
                Team{' '}
                <SortByDirectionButton
                  id="TeamManagementTableNameThSortByDirectionButton"
                  propertyToSortBy="name.keyword"
                  currentPropertyBeingSorted={sortField}
                  currentDirectionBeingSorted={sortDirection}
                  setSortByProperty={setManagementTeamsSortByProperty}
                  setSortByDirection={setManagementTeamsSortByDirection}
                />
              </FlexContainer>
            </Th>
            <Th id="TeamManagementTableFeedsTh" width="44%" padding="12px 16px">
              Feeds
            </Th>
            <Th
              id="TeamManagementTableMembersTh"
              width="80px"
              padding="12px 16px"
            >
              <FlexContainer id="TeamManagementTableMembersThContainer">
                Members
                <SortByDirectionButton
                  id="TeamManagementTableMembersThSortByDirectionButton"
                  propertyToSortBy="usersCount"
                  currentPropertyBeingSorted={sortField}
                  currentDirectionBeingSorted={sortDirection}
                  setSortByProperty={setManagementTeamsSortByProperty}
                  setSortByDirection={setManagementTeamsSortByDirection}
                />
              </FlexContainer>
            </Th>
          </Tr>
        </Thead>
        <Tbody id="TeamManagementTableTbody">{teamRows}</Tbody>
      </Table>
    </OverflowYAutoContainer>
  );
};

interface TeamRowProps {
  team: Team;
  checked?: boolean;
  onSelectRow?: (row: Row<Team>) => void;
  isFaded?: boolean;
  cutterElementRef?: RefObject<Element>;
}

export interface TeamsTableProps extends ManagementTableProps<Team> {
  spotlitTeams?: Team[];
}
