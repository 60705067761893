import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { ReactComponent as Arrow } from 'assets/keyboard-arrow-right.svg';
import { ReactComponent as BackArrow } from 'assets/arrow-left-secondary.svg';
import { useNavigate } from 'react-router-dom';
import { Fragment } from 'react';
import { Header } from 'components/Header';
import { BackButton } from 'components/BackButton';

interface ReportsHeaderProps {
  breadcrumb: {
    text: string;
    path?: string;
  }[];
  backButtonPath?: string;
  onClickBack?: () => void;
  hasBorderBottom?: boolean;
}

const BreadcrumbStep = styled.span({
  fontSize: '18px',
  lineHeight: '32px',
  color: Theme.colors.secondaryColor,
  fontWeight: '400',
  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
});

const Title = styled.div({
  fontSize: '18px',
  lineHeight: '32px',
  fontWeight: 'bold',
});

const BackArrowContainer = styled.div({
  border: `1px solid ${Theme.colors.grayBorder}`,
  height: '32px',
  width: '32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: Theme.borderRadius,
  marginRight: '8px',
  cursor: 'pointer',
});

export const ReportsHeader = (props: ReportsHeaderProps) => {
  const navigate = useNavigate();

  return (
    <Header hasBorderBottom={props.hasBorderBottom}>
      {!!props.onClickBack && <BackButton onClick={props.onClickBack} />}
      {props.breadcrumb.length > 1 && (
        <BackArrowContainer
          onClick={() => props.backButtonPath && navigate(props.backButtonPath)}
        >
          <BackArrow />
        </BackArrowContainer>
      )}
      {props.breadcrumb.map((step, index) => {
        const isLast = index === props.breadcrumb.length - 1;

        return isLast ? (
          <Title key={index}>{step.text}</Title>
        ) : (
          <Fragment key={index}>
            <div onClick={() => navigate(step.path!)}>
              <BreadcrumbStep>{step.text}</BreadcrumbStep>
            </div>
            <Arrow />
          </Fragment>
        );
      })}
    </Header>
  );
};
