import { useNavigate, useParams } from 'react-router';
import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getDevelopmentByIdAsync,
  resetDevelopment,
  resetDevelopmentDetailSearchTerm,
  selectDevelopmentDetail,
  selectDevelopmentDetailSearchTerm,
  selectDisplayNewDevelopmentVersionAvailableModal,
  setDisplayNewDevelopmentVersionAvailableModal,
  setSelectedHighlights,
  viewAsync,
} from 'store/development-detail/development-detail-slice';
import { FlexContainer } from 'styles/utils';
import { useCallback, useEffect, useState } from 'react';
import {
  DetailLeftSidebar,
  DetailRightSidebar,
  DetailHeader,
  DetailSubHeader,
  ReviewHistory,
  DevelopmentPdfViewer,
} from './components';
import { selectAuthenticationResponse } from 'store/authentication/authentication-slice';
import {
  resetDetailCitationsDevelopmentCitations,
  selectDetailSelectedDevelopmentCitation,
  setDetailSelectedDevelopmentCitation,
} from 'store/detail-development-citation/detail-development-citation-slice';
import { HttpResponse } from 'utils/http/HttpResponse';
import {
  Development,
  DevelopmentCitation,
  DevelopmentUserReview,
  Environment,
} from 'models';
import {
  getDevelopmentEnvironmentAsync,
  selectSelectedEnvironment,
  setSelectedEnvironment,
} from 'store/environment/environment-slice';
import { EnvironmentSwitchModal } from './components/EnvironmentSwitchModal';
import { NotFoundModal } from './components/NotFoundModal';
import { setHomePreventsGetDevelopments } from 'store/development/development-slice';
import { routes } from 'navigation/routes';
import { RecentlyViewedDevelopmentsModal } from 'components/RecentlyViewedDevelopments/RecentlyViewedDevelopmentsModal';
import { OpenSidebarButton } from './components/OpenSidebarButton';
import { BaseSocketService } from 'utils/socket/BaseSocketService';
import { RealTimeReviewersEvent } from 'enums';
import { setReviewers } from 'store/real-time-reviewers/real-time-reviewers-slice';
import { ReprocessDevelopmentModal } from './components/ReprocessDevelopmentModal';
import { NewDevelopmentVersionAvailableModal } from './components/NewDevelopmentVersionAvailableModal';
import { LegendModal } from './components/LegendModal';

const DetailContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  width: 'calc(100% - 56px - 294px)',
});

const DetailContentContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: Theme.colors.tertiaryBackgroundColor,
  flex: '1 1 auto',
});

export const Detail = () => {
  const { developmentId } = useParams<{ developmentId?: string }>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const development = useAppSelector(selectDevelopmentDetail);
  const authenticationResponse = useAppSelector(selectAuthenticationResponse);
  const selectedEnvironment = useAppSelector(selectSelectedEnvironment);
  const selectedDevelopmentCitation = useAppSelector(
    selectDetailSelectedDevelopmentCitation
  );
  const searchTerm = useAppSelector(selectDevelopmentDetailSearchTerm);
  const displayNewDevelopmentVersionAvailableModal = useAppSelector(
    selectDisplayNewDevelopmentVersionAvailableModal
  );
  const [isDetailLeftSidebarDisplayed, setIsDetailLeftSidebarDisplayed] =
    useState(true);
  const [isDetailRightSidebarDisplayed, setIsDetailRightSidebarDisplayed] =
    useState(true);
  const [isPdfRendering, setIsPdfRendering] = useState(true);
  const [
    isRecentlyViewedDevelopmentsModalDisplayed,
    setIsRecentlyViewedDevelopmentsModalDisplayed,
  ] = useState(false);
  const [isReviewHistoryDisplayed, setIsReviewHistoryDisplayed] =
    useState(false);
  const [title, setTitle] = useState<string>('Loading...');
  const [environmentToSwitchTo, setEnvironmentToSwitchTo] =
    useState<Environment | null>(null);
  const [isTheNotFoundModalDisplayed, setIsTheNotFoundModalDisplayed] =
    useState(false);
  const [isLegendOptionsDisplayed, setIsLegendOptionsDisplayed] =
    useState(false);
  const [
    isReprocessDevelopmentModalDisplayed,
    setIsReprocessDevelopmentModalDisplayed,
  ] = useState(false);
  const [pdfWidth, setPdfWidth] = useState<number>(0);
  const [
    isNewDevelopmentVersionModalDisplayed,
    setIsNewDevelopmentVersionModalDisplayed,
  ] = useState(false);

  const isRightPanelShow =
    process.env.REACT_APP_IS_CITATION_RIGHT_PANEL_SHOW === 'true';

  useEffect(() => {
    dispatch(resetDetailCitationsDevelopmentCitations());
    dispatch(setSelectedHighlights([]));
  }, [development?.id, developmentId]);

  useEffect(() => {
    (async () => {
      setEnvironmentToSwitchTo(null);
      if (!developmentId) {
        return;
      }
      try {
        const fetchedDevelopment = await dispatch(
          getDevelopmentByIdAsync(developmentId)
        );
        setTitle(fetchedDevelopment?.title || 'No title');
      } catch (error) {
        const httpResponse = error as HttpResponse<Development>;
        if (httpResponse.status === 404) {
          setTitle('Development not found.');
          if (!!authenticationResponse) {
            try {
              const environment = await dispatch(
                getDevelopmentEnvironmentAsync(
                  Number(developmentId),
                  authenticationResponse.accessToken
                )
              );
              if (!!environment) {
                setEnvironmentToSwitchTo(environment);
              }
            } catch (environmentFetchError) {
              const environmentFetchHttpResponse =
                environmentFetchError as HttpResponse<Environment>;
              if (environmentFetchHttpResponse.status === 404) {
                setIsTheNotFoundModalDisplayed(true);
              } else {
                setTitle('Error fetching the development environment.');
              }
            }
          }
        } else {
          const errorMessage = httpResponse.error?.message
            ? httpResponse.error.message
            : '';
          if (errorMessage) {
            setTitle(`Error fetching the development: '${errorMessage}'.`);
          } else {
            setTitle(`Error fetching the development.`);
          }
        }
      }
    })();
  }, [developmentId, authenticationResponse, selectedEnvironment]);

  useEffect(() => {
    if (authenticationResponse?.accessToken && developmentId) {
      const socketService = new BaseSocketService(
        authenticationResponse.accessToken
      );
      socketService.emit(
        RealTimeReviewersEvent.StartReview,
        authenticationResponse,
        Number(developmentId)
      );
      socketService.listen(
        RealTimeReviewersEvent.RefreshReviewers,
        (reviewers: DevelopmentUserReview[]) => {
          dispatch(setReviewers(reviewers));
        }
      );
      socketService.emit(
        RealTimeReviewersEvent.RequestReviewers,
        Number(developmentId)
      );
      return () => {
        socketService.disconnect();
      };
    }
  }, [developmentId]);

  useEffect(() => {
    if (!developmentId || !development) {
      return;
    }
    dispatch(viewAsync(Number(developmentId)));
  }, [development?.id, developmentId]);

  useEffect(() => {
    if (displayNewDevelopmentVersionAvailableModal && development) {
      if (
        development.developmentVersions &&
        development.developmentVersions.length > 1 &&
        !development.isLatestVersion
      ) {
        setIsNewDevelopmentVersionModalDisplayed(true);
      }
    }
  }, [development?.id]);

  useEffect(() => {
    return () => {
      dispatch(resetDevelopment());
      dispatch(resetDevelopmentDetailSearchTerm());
      dispatch(setDisplayNewDevelopmentVersionAvailableModal(true));
    };
  }, []);

  const onPdfContainerRefChange = useCallback(
    (pdfContainer: HTMLDivElement) => {
      if (pdfContainer) {
        let pdfWidthPercentOfScreen = 48;
        if (!isDetailLeftSidebarDisplayed) {
          pdfWidthPercentOfScreen += 15;
        }
        if (isRightPanelShow && !isDetailRightSidebarDisplayed) {
          pdfWidthPercentOfScreen += 10;
        }
        if (!isRightPanelShow) {
          pdfWidthPercentOfScreen += 10;
        }
        const pdfWidthBasedOnWindow =
          (window.innerWidth * pdfWidthPercentOfScreen) / 100;
        setPdfWidth(pdfWidthBasedOnWindow);
      }
    },
    [isDetailLeftSidebarDisplayed, isDetailRightSidebarDisplayed]
  );

  const handleCancelSwitchEnvironment = useCallback(() => {
    setEnvironmentToSwitchTo(null);
  }, []);

  const handleProceedToSwitchEnvironment = useCallback(() => {
    dispatch(setSelectedEnvironment(environmentToSwitchTo));
  }, [environmentToSwitchTo]);

  const handleGoToHome = () => {
    dispatch(setHomePreventsGetDevelopments(true));
    navigate(routes.home);
  };

  const handleClickDevelopmentCitation = useCallback(
    (developmentCitation: DevelopmentCitation) => {
      if (
        !!selectedDevelopmentCitation?.id &&
        selectedDevelopmentCitation.id === developmentCitation.id
      ) {
        dispatch(setDetailSelectedDevelopmentCitation(null));
        dispatch(setSelectedHighlights([]));
      } else {
        dispatch(setDetailSelectedDevelopmentCitation(developmentCitation));
        if (
          developmentCitation.highlights &&
          developmentCitation.highlights.length
        ) {
          dispatch(setSelectedHighlights(developmentCitation.highlights));
        }
      }
    },
    [selectedDevelopmentCitation?.id]
  );

  return (
    <>
      <FlexContainer
        id="DevelopmentDetail"
        flexWrap="nowrap"
        style={{ width: 'calc(100% - 56px)', height: '100%' }}
      >
        <DetailContainer id="DevelopmentDetailContainer">
          <DetailHeader
            developmentId={development?.id}
            developmentExternalId={development?.externalId}
            developmentTitle={title}
            onClickBack={handleGoToHome}
          />
          <DetailSubHeader
            isPdfRendering={isPdfRendering}
            setIsReviewHistoryDisplayed={setIsReviewHistoryDisplayed}
            setIsRecentlyViewedDevelopmentsModalDisplayed={
              setIsRecentlyViewedDevelopmentsModalDisplayed
            }
            developmentSourceUrl={development?.contentUri}
            developmentId={development?.id}
            developmentExternalId={development?.externalId}
          />
          <FlexContainer
            id="DevelopmentDetailContentOuterContainer"
            flexWrap="nowrap"
            style={{ flex: '1 1 auto' }}
          >
            <DetailLeftSidebar
              development={development}
              isDisplayed={isDetailLeftSidebarDisplayed}
              setIsDisplayed={setIsDetailLeftSidebarDisplayed}
            />
            <DetailContentContainer id="DevelopmentDetailContentContainer">
              <FlexContainer
                id="DevelopmentDetailPrimaryContainer"
                style={{
                  flex: '1 1 auto',
                  position: 'relative',
                  width: `${pdfWidth}px`,
                  maxWidth: `${pdfWidth}px`,
                  margin: '0 auto',
                }}
                ref={onPdfContainerRefChange}
              >
                {!isDetailLeftSidebarDisplayed && (
                  <OpenSidebarButton
                    id="OpenLeftSidebarButton"
                    top={90}
                    left={-70}
                    onClick={() => setIsDetailLeftSidebarDisplayed(true)}
                  />
                )}
                {development && development.id && (
                  <DevelopmentPdfViewer
                    developmentId={String(development.id)}
                    searchTerm={searchTerm}
                    onClickDevelopmentCitation={handleClickDevelopmentCitation}
                    setIsPdfRendering={setIsPdfRendering}
                  />
                )}
                {isRightPanelShow && (
                  <OpenSidebarButton
                    id="OpenRightSidebarButton"
                    bottom={135}
                    right={-54}
                    onClick={() =>
                      setIsDetailRightSidebarDisplayed(
                        !isDetailRightSidebarDisplayed
                      )
                    }
                    isSidePanelOpen={isDetailRightSidebarDisplayed}
                  />
                )}
              </FlexContainer>
            </DetailContentContainer>
          </FlexContainer>
        </DetailContainer>
        {isRightPanelShow && (
          <DetailRightSidebar
            development={development}
            onClickDevelopmentCitation={handleClickDevelopmentCitation}
            isDisplayed={isDetailRightSidebarDisplayed}
            setIsLegendOptionsDisplayed={setIsLegendOptionsDisplayed}
            setIsReprocessDevelopmentModalDisplayed={
              setIsReprocessDevelopmentModalDisplayed
            }
          />
        )}
      </FlexContainer>
      <RecentlyViewedDevelopmentsModal
        isDisplayed={isRecentlyViewedDevelopmentsModalDisplayed}
        setIsDisplayed={setIsRecentlyViewedDevelopmentsModalDisplayed}
        currentDevelopmentId={developmentId}
      />
      <ReviewHistory
        isDisplayed={isReviewHistoryDisplayed}
        setIsDisplayed={setIsReviewHistoryDisplayed}
        developmentId={development?.id}
        developmentProcessingDate={development?.processingDate}
      />
      <EnvironmentSwitchModal
        isDisplayed={!!environmentToSwitchTo}
        environmentName={environmentToSwitchTo?.name}
        onCancel={handleCancelSwitchEnvironment}
        onProceed={handleProceedToSwitchEnvironment}
      />
      <NotFoundModal
        isDisplayed={isTheNotFoundModalDisplayed}
        onClickButton={handleGoToHome}
      />
      <LegendModal
        isDisplayed={isLegendOptionsDisplayed}
        setIsDisplayed={setIsLegendOptionsDisplayed}
      />
      <ReprocessDevelopmentModal
        isDisplayed={isReprocessDevelopmentModalDisplayed}
        setIsDisplayed={setIsReprocessDevelopmentModalDisplayed}
      />
      {development && (
        <NewDevelopmentVersionAvailableModal
          isDisplayed={isNewDevelopmentVersionModalDisplayed}
          setIsDisplayed={setIsNewDevelopmentVersionModalDisplayed}
          development={development}
        />
      )}
    </>
  );
};
