import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { HTMLAttrID } from 'models';
import { ReactNode } from 'react';

export interface ChipProps extends HTMLAttrID {
  noMargin?: boolean;
  isSelected?: boolean;
  children?: ReactNode;
}

export const Chip = styled.span<ChipProps>((props) => ({
  padding: '4px 8px',
  backgroundColor: Theme.colors.tertiaryBackgroundColor,
  display: 'inline-flex',
  borderRadius: Theme.borderRadius,
  border: props.isSelected ? `1px solid ${Theme.colors.grayBorder}` : 'none',
  margin: props.noMargin ? '0' : '2px',
  color: Theme.colors.grayDarker,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  userSelect: 'none',
  alignItems: 'center',
}));
