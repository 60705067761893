import { memo } from 'react';
import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { Button, OutlinedButton } from 'components/Button';

export interface EnvironmentSwitcherProps {
  onProceed: () => void;
  onCancel: () => void;
  environmentName?: string;
}

const EnvironmentSwitcherContainer = styled.div({
  width: '320px',
  padding: '24px 58px',
  display: 'flex',
  flexDirection: 'column',
});

const EnvironmentSwitcherTitle = styled.div({
  marginBottom: '16px',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  fontSize: Theme.fontSize + 2,
  lineHeight: `${Theme.fontSize + 4}px`,
  fontWeight: 'bold',
});

const EnvironmentSwitcherDescription = styled.div({
  marginBottom: '16px',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  fontSize: Theme.fontSize,
  lineHeight: `${Theme.fontSize + 4}px`,
});

const EnvironmentSwitcherButtonsContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const EnvironmentSwitcher = memo((props: EnvironmentSwitcherProps) => {
  return (
    <EnvironmentSwitcherContainer id="EnvironmentSwitcherContainer">
      <EnvironmentSwitcherTitle id="EnvironmentSwitcherTitle">
        Development not found
      </EnvironmentSwitcherTitle>
      <EnvironmentSwitcherDescription id="EnvironmentSwitcherDescription">
        This development is located in <i>{props.environmentName}</i>.{' '}
        <strong>
          Would you like to proceed and switch to the{' '}
          <i>{props.environmentName} environment</i>?
        </strong>
      </EnvironmentSwitcherDescription>
      <EnvironmentSwitcherButtonsContainer id="EnvironmentSwitcherButtonsContainer">
        <OutlinedButton
          id="EnvironmentSwitcherButtonCancel"
          color={Theme.colors.secondaryColor}
          onClick={props.onCancel}
        >
          Cancel
        </OutlinedButton>
        <Button id="EnvironmentSwitcherButtonProceed" onClick={props.onProceed}>
          Yes, proceed
        </Button>
      </EnvironmentSwitcherButtonsContainer>
    </EnvironmentSwitcherContainer>
  );
});
