import styled from '@emotion/styled';
import { ReactElement } from 'react';
import { Searchbar } from 'components/Searchbar';
import { Theme } from 'styles/themes';
import { FlexContainer, BoldText, VerticalDivider } from 'styles/utils';
import { ManagementLabels } from 'pages/management/interfaces/ManagementLabels';
import { ManagementDropdownProps } from './ManagementDropdown';
import { HTMLAttrID } from 'models';

const SubHeaderContainer = styled.div({
  width: '100%',
  height: '56px',
  padding: '12px 16px',
  borderBottom: `solid 1px ${Theme.colors.grayBorder}`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const ManagementSubHeader = (props: ManagementSubHeaderProps) => {
  return (
    <SubHeaderContainer id={props.id}>
      <div id={`${props.id}SearchBarOuterContainer`} style={{ width: '30%' }}>
        <Searchbar
          id={`${props.id}Searchbar`}
          value={props.searchTerm}
          setValue={props.onSearchChange}
          placeholder={props.searchBarPlaceholder}
        />
      </div>
      <FlexContainer
        id={`${props.id}InfoContainer`}
        style={{ alignItems: 'center' }}
      >
        <FlexContainer
          id={`${props.id}CountContainer`}
          style={{ alignItems: 'center' }}
        >
          <span
            id={`${props.id}CountLabel`}
            style={{ marginRight: '5px', color: Theme.colors.primaryColor }}
          >
            {props.countLabel}
          </span>
          <BoldText
            id={`${props.id}Count`}
            style={{ color: Theme.colors.secondaryColor }}
          >
            {props.count}
          </BoldText>
        </FlexContainer>
        <VerticalDivider height={'24px'} style={{ margin: '0 6px' }} />
        <FlexContainer
          id={`${props.id}SelectedCountContainer`}
          style={{ alignItems: 'center' }}
        >
          <span
            id={`${props.id}SelectedCountLabel`}
            style={{ marginRight: '5px', color: Theme.colors.primaryColor }}
          >
            {props.selectedCountLabel}
          </span>
          <BoldText
            id={`${props.id}SelectedCount`}
            style={{ color: Theme.colors.secondaryColor }}
          >
            ({props.selectedCount})
          </BoldText>
        </FlexContainer>
        <VerticalDivider height={'24px'} style={{ margin: '0 6px' }} />
        {props.children}
      </FlexContainer>
    </SubHeaderContainer>
  );
};

interface ManagementSubHeaderProps extends ManagementLabels, HTMLAttrID {
  count: number;
  selectedCount: number;
  children: ReactElement<ManagementDropdownProps>;
  searchTerm: string;
  onSearchChange: (value: string) => void;
}
