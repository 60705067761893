import { localStorageKeys } from 'config/local-storage-keys';
import { routes } from 'navigation/routes';

export const clearLocalStorageAndRedirectToLogin = () => {
  localStorage.setItem(
    localStorageKeys.authenticationResponse,
    btoa(JSON.stringify(null))
  );
  localStorage.setItem(
    localStorageKeys.selectedEnvironment,
    btoa(JSON.stringify(null))
  );
  window.location.href = `${routes.login}?returnUrl=${window.location.pathname}`;
};
