import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddUsersToTeamsPayload,
  PaginatedResponse,
  User,
  UsersBulkActionPayload,
} from 'models';
import { AppThunk, RootState } from '../store';
import {
  getAllUsers,
  addUsersToTeams,
  revokeUserAccess,
  giveUserAccess,
  deleteUsers,
  updateUser,
} from 'services/users/users-service';
import { paginationConfig } from 'config/pagination-config';
import { SortByDirection } from 'enums';
import { sendInvite } from 'services/users/users-service';

export interface ManagementUserState {
  users: User[];
  selectedUsers: { [key: number]: User };
  areAllSelected: boolean;
  totalRecords: number;
  sortByProperty: string;
  sortByDirection: SortByDirection;
  isDeleting: boolean;
  page: number;
  perPage: number;
  searchTerm: string;
  triggerUsersUpdate: number;
  selectedUsersOutsideOfCurrentPage: User[];
}

const initialState: ManagementUserState = {
  users: [],
  selectedUsers: {},
  areAllSelected: false,
  totalRecords: 0,
  sortByProperty: 'fullName',
  sortByDirection: SortByDirection.ASC,
  isDeleting: false,
  page: 1,
  perPage: paginationConfig.managementUsersPerPage,
  searchTerm: '',
  triggerUsersUpdate: 0,
  selectedUsersOutsideOfCurrentPage: [],
};

export const getAllManagementUsersAsync = (): AppThunk<Promise<User[]>> => {
  return async (dispatch, getState) => {
    const userState = getState().managementUser;
    const page = userState.page;
    const perPage = userState.perPage;
    const searchTerm = userState.searchTerm;
    const sortByProperty = `${userState.sortByProperty}.keyword`;
    const sortByDirection = userState.sortByDirection;
    const response = await getAllUsers(
      searchTerm,
      page,
      perPage,
      sortByProperty,
      sortByDirection
    );
    dispatch(setManagementUsers(response?.data));
    dispatch(updateSelectedManagementUsers(response?.data?.records || []));
    return response?.data?.records || [];
  };
};

export const addUsersToTeamsAsync = (
  neverAffectsAllUsers: boolean,
  neverAffectsAllTeams: boolean,
  teamIds?: number[],
  userIds?: number[]
): AppThunk<Promise<User[] | null>> => {
  return async (dispatch, getState) => {
    const userState = getState().managementUser;
    const teamState = getState().managementTeam;
    const addUsersToTeamsPayload: AddUsersToTeamsPayload = {
      userIds: userIds
        ? userIds
        : Object.keys(userState.selectedUsers).map((x) => Number(x)),
      teamIds: teamIds
        ? teamIds
        : Object.keys(teamState.selectedTeams).map((x) => Number(x)),
      affectsAllUsers: neverAffectsAllUsers ? false : userState.areAllSelected,
      affectsAllTeams: neverAffectsAllTeams
        ? false
        : teamState.areAllTeamsSelected,
      usersSearchTerm: neverAffectsAllUsers ? undefined : userState.searchTerm,
      teamsSearchTerm: neverAffectsAllTeams ? undefined : teamState.searchTerm,
    };
    const response = await addUsersToTeams(addUsersToTeamsPayload);
    dispatch(setTriggerManagementUsersUpdate());
    return response.data;
  };
};

export const updateUserAsync = (
  payload: User
): AppThunk<Promise<User | null>> => {
  return async (dispatch, getState) => {
    const response = await updateUser(payload);
    if (response.data) {
      await dispatch(getAllManagementUsersAsync());
      dispatch(selectManagementUser(response.data));
    }
    return response?.data;
  };
};

export const revokeUserAccessAsync = (): AppThunk<Promise<User[]>> => {
  return async (dispatch, getState) => {
    const userState = getState().managementUser;
    const payload: UsersBulkActionPayload = {
      userIds: Object.keys(userState.selectedUsers).map((x) => Number(x)),
      affectsAllUsers: userState.areAllSelected,
      searchTerm: userState.searchTerm,
    };
    const response = await revokeUserAccess(payload);
    dispatch(setTriggerManagementUsersUpdate());
    return response?.data || [];
  };
};

export const giveUserAccessAsync = (): AppThunk<Promise<User[]>> => {
  return async (dispatch, getState) => {
    const userState = getState().managementUser;
    const payload: UsersBulkActionPayload = {
      userIds: Object.keys(userState.selectedUsers).map((x) => Number(x)),
      affectsAllUsers: userState.areAllSelected,
      searchTerm: userState.searchTerm,
    };
    const response = await giveUserAccess(payload);
    dispatch(setTriggerManagementUsersUpdate());
    return response?.data || [];
  };
};

export const deleteUsersAsync = (): AppThunk<Promise<User[]>> => {
  return async (dispatch, getState) => {
    const userState = getState().managementUser;
    const payload: UsersBulkActionPayload = {
      userIds: Object.keys(userState.selectedUsers).map((x) => Number(x)),
      affectsAllUsers: userState.areAllSelected,
      searchTerm: userState.searchTerm,
    };
    const response = await deleteUsers(payload);
    dispatch(removeSelectedManagementUsers());
    dispatch(setTriggerManagementUsersUpdate());
    return response?.data || [];
  };
};

export const sendInviteAsync = (user: User): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    await sendInvite(user);
  };
};

export const managementUserSlice = createSlice({
  name: 'management-user',
  initialState,
  reducers: {
    setManagementUsers: (
      state,
      action: PayloadAction<PaginatedResponse<User> | null>
    ) => {
      state.users = action.payload?.records || [];
      state.totalRecords = action.payload?.totalRecords || 0;
    },
    selectManagementUser: (state, action: PayloadAction<User>) => {
      const user = action.payload;
      const selectedUsersLength = Object.keys(state.selectedUsers).length + 1;

      if (state.selectedUsers[user.id || 0]) {
        delete state.selectedUsers[user.id || 0];
      } else {
        state.selectedUsers[user.id || 0] = user;
        if (
          selectedUsersLength === state.totalRecords &&
          state.areAllSelected
        ) {
          state.areAllSelected = false;
          state.selectedUsers = {};
        }
      }
    },
    selectAllManagementUsers: (state) => {
      if (!Object.keys(state.selectedUsers).length && !state.areAllSelected) {
        state.areAllSelected = true;
      } else {
        state.selectedUsers = {};
        state.areAllSelected = false;
      }
    },
    setSelectedManagementUsers: (
      state,
      action: PayloadAction<User[] | { [key: number]: User }>
    ) => {
      const users = action.payload;
      if (Array.isArray(users)) {
        const selectedUsers: { [key: number]: User } = {};
        users.forEach((user) => {
          selectedUsers[user.id as number] = user;
        });
        state.selectedUsers = selectedUsers;
      } else {
        state.selectedUsers = users;
      }
    },
    updateSelectedManagementUsers: (state, action: PayloadAction<User[]>) => {
      const users = action.payload;
      users.forEach((user) => {
        if (!!state.selectedUsers[user.id || 0]) {
          state.selectedUsers[user.id || 0] = user;
        }
      });
    },
    removeSelectedManagementUsers: (state) => {
      if (state.areAllSelected) {
        state.users = Object.values(state.selectedUsers).slice(
          0,
          state.perPage
        );
        state.areAllSelected = false;
      }
      state.selectedUsers = {};
    },
    setManagementUsersPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setManagementUsersSearchTerm: (state, action: PayloadAction<string>) => {
      state.page = 1;
      state.searchTerm = action.payload;
      state.areAllSelected = false;
    },
    setManagementUsersSortByProperty: (
      state,
      action: PayloadAction<string>
    ) => {
      state.sortByProperty = action.payload;
    },
    setManagementUsersSortByDirection: (
      state,
      action: PayloadAction<SortByDirection>
    ) => {
      state.sortByDirection = action.payload;
    },
    setTriggerManagementUsersUpdate: (state) => {
      state.triggerUsersUpdate = state.triggerUsersUpdate + 1;
    },
    toggleDeleteUserModal: (state) => {
      state.isDeleting = !state.isDeleting;
    },
    addSelectedManagementUsersOutsideOfCurrentPage: (
      state,
      action: PayloadAction<User[]>
    ) => {
      const arrClone = [...state.selectedUsersOutsideOfCurrentPage];
      const idsArr = arrClone.map((x) => x.id);
      action.payload.forEach((user) => {
        if (!idsArr.includes(user.id)) {
          arrClone.push(user);
        }
      });
      state.selectedUsersOutsideOfCurrentPage = arrClone;
    },
    removeSelectedManagementUsersOutsideOfCurrentPage: (
      state,
      action: PayloadAction<User[]>
    ) => {
      const arrClone = [...state.selectedUsersOutsideOfCurrentPage];
      const idsArr = arrClone.map((x) => x.id);
      action.payload.forEach((user) => {
        const index = idsArr.indexOf(user.id);
        if (index > -1) {
          arrClone.splice(index, 1);
        }
      });
      state.selectedUsersOutsideOfCurrentPage = arrClone;
    },
  },
});

export const {
  setManagementUsers,
  selectManagementUser,
  selectAllManagementUsers,
  setSelectedManagementUsers,
  updateSelectedManagementUsers,
  removeSelectedManagementUsers,
  setManagementUsersPage,
  setManagementUsersSearchTerm,
  setManagementUsersSortByProperty,
  setManagementUsersSortByDirection,
  setTriggerManagementUsersUpdate,
  toggleDeleteUserModal,
  addSelectedManagementUsersOutsideOfCurrentPage,
  removeSelectedManagementUsersOutsideOfCurrentPage,
} = managementUserSlice.actions;

export const selectManagementUsers = (state: RootState) =>
  state.managementUser.users;
export const selectSelectedManagementUsers = (state: RootState) =>
  state.managementUser.selectedUsers;
export const selectAreAllManagementUsersSelected = (state: RootState) =>
  state.managementUser.areAllSelected;
export const selectManagementUsersTotalRecords = (state: RootState) =>
  state.managementUser.totalRecords;
export const selectManagementUsersPage = (state: RootState) =>
  state.managementUser.page;
export const selectManagementUsersPerPage = (state: RootState) =>
  state.managementUser.perPage;
export const selectManagementUsersSearchTerm = (state: RootState) =>
  state.managementUser.searchTerm;
export const selectManagementUsersSortByProperty = (state: RootState) =>
  state.managementUser.sortByProperty;
export const selectManagementUsersSortByDirection = (state: RootState) =>
  state.managementUser.sortByDirection;
export const selectTriggerManagementUsersUpdate = (state: RootState) =>
  state.managementUser.triggerUsersUpdate;
export const selectIsDeletingManagementUsers = (state: RootState) =>
  state.managementUser.isDeleting;
export const selectSelectedManagementUsersOutsideOfCurrentPage = (
  state: RootState
) => state.managementUser.selectedUsersOutsideOfCurrentPage;

export default managementUserSlice.reducer;
