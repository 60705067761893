import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { CSSProperties, ReactNode } from 'react';
import { Loading } from 'components/Loading';
import { Overlay } from 'components/Overlay';
import { HTMLAttrID } from 'models';

export interface ModalProps extends HTMLAttrID {
  children?: ReactNode;
  isDisplayed: boolean;
  isLoading?: boolean;
  onClickOutside?: () => void;
  style?: CSSProperties;
}

const ModalContainer = styled.div<Partial<ModalProps>>((props) => ({
  display: props.isDisplayed ? 'flex' : 'none',
  flexDirection: 'column',
  borderRadius: '10px',
  backgroundColor: Theme.colors.white,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 4,
}));

export const Modal: React.FC<ModalProps> = (props) => {
  const loadingId = props.id ? `${props.id}Loading` : undefined;
  return (
    <>
      {props.isDisplayed && <Overlay onClick={props.onClickOutside} />}
      <ModalContainer
        id={props.id}
        isDisplayed={props.isDisplayed}
        style={props.style}
      >
        {props.children}
        {props.isLoading && <Loading id={loadingId} />}
      </ModalContainer>
    </>
  );
};

export const ModalContentContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const ModalInnerContainer = styled.div<{
  withoutBorderBottom?: boolean;
  padding?: string;
  flexDirection?: 'row' | 'column';
}>((props) => ({
  display: 'flex',
  flexDirection: props.flexDirection ? props.flexDirection : 'row',
  width: '100%',
  padding: props.padding ? props.padding : '16px',
  borderBottom: props.withoutBorderBottom ? 'none' : `1px solid #E0E0E0`,
}));

export const ModalTitle = styled.span<{
  withoutMarginBottom?: boolean;
  color: string;
}>((props) => ({
  color: props.color,
  fontSize: Theme.fontSize,
  lineHeight: '22px',
  fontWeight: 'bold',
  marginRight: '20px',
  marginBottom: props.withoutMarginBottom ? '0px' : '8px',
}));

export const CloseIconContainer = styled.div({
  cursor: 'pointer',
  margin: 'auto 0px auto auto',
  height: '14px',
  zIndex: 2,
});
