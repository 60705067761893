import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { Input } from 'components/Input';
import { OutputConsole } from 'components/OutputConsole';
import { SelectSearchbar } from 'components/SelectSearchbar';
import { YearInput } from 'components/YearInput';
import { localStorageKeys } from 'config/local-storage-keys';
import { ElasticsearchIndexes, RealTimeElasticsearchEvent } from 'enums';
import { Environment } from 'models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { selectAuthenticationResponse } from 'store/authentication/authentication-slice';
import { useAppSelector } from 'store/hooks';
import { Theme } from 'styles/themes';
import { BaseSocketService } from 'utils/socket/BaseSocketService';

interface UpsertDataCardProps {
  elasticsearchIndexes: {
    id: ElasticsearchIndexes;
    label: ElasticsearchIndexes;
  }[];
  selectedEnvironment: Environment | null;
}

export const UpsertDataCard = (props: UpsertDataCardProps) => {
  const authenticationResponse = useAppSelector(selectAuthenticationResponse);

  const [socketService, setSocketService] = useState<BaseSocketService | null>(
    null
  );
  const [selectedIndexes, setSelectedIndexes] = useState<string[]>([]);
  const [selectedYears, setSelectedYears] = useState<number[]>([]);
  const [concurrency, setConcurrency] = useState('');
  const [fromId, setFromId] = useState('');
  const [toId, setToId] = useState('');
  const [outputs, setOutputs] = useState<string[]>([]);
  const [isConfirmationModalDisplayed, setIsConfirmationModalDisplayed] =
    useState(false);
  const [isProcessOnGoing, setIsProcessOnGoing] = useState<boolean>(false);

  useEffect(() => {
    if (authenticationResponse?.accessToken) {
      setSocketService(
        new BaseSocketService(authenticationResponse.accessToken)
      );
    }
  }, [JSON.stringify(authenticationResponse)]);

  useEffect(() => {
    if (socketService) {
      socketService.listen(
        RealTimeElasticsearchEvent.UpsertDataUpdateProgress,
        (output: string) => {
          setOutputs((currentValue) => [...currentValue, output]);
        }
      );
      socketService.listen(
        RealTimeElasticsearchEvent.UpsertDataFinished,
        () => {
          setIsProcessOnGoing(false);
          localStorage.setItem(
            localStorageKeys.isPerformingIndefiniteOperation,
            btoa('false')
          );
        }
      );
      return () => {
        socketService.disconnect();
      };
    }
  }, [socketService]);

  const elasticsearchYears = useMemo(() => {
    const earliestYearWithData = 2021;
    const currentYear = new Date().getFullYear();
    const years: number[] = [];
    for (let year = earliestYearWithData; year <= currentYear; year++) {
      years.push(year);
    }
    return years.map((year) => {
      return {
        id: year,
        label: year,
      };
    });
  }, []);

  const handlePrimaryAction = useCallback(async () => {
    if (props.selectedEnvironment && authenticationResponse && socketService) {
      setOutputs([]);
      localStorage.setItem(
        localStorageKeys.isPerformingIndefiniteOperation,
        btoa('true')
      );
      setIsProcessOnGoing(true);
      socketService.emit(
        RealTimeElasticsearchEvent.UpsertData,
        authenticationResponse.id,
        props.selectedEnvironment.elasticsearchIndexPrefix,
        selectedIndexes,
        selectedYears && selectedYears.length ? selectedYears[0] : undefined,
        Number(concurrency),
        Number(fromId),
        Number(toId)
      );
    }
  }, [
    socketService,
    JSON.stringify(authenticationResponse),
    props.selectedEnvironment?.elasticsearchIndexPrefix,
    JSON.stringify(selectedIndexes),
    JSON.stringify(selectedYears),
    concurrency,
    fromId,
    toId,
  ]);

  return (
    <Card>
      <span
        style={{
          fontSize: Theme.fontSize,
          fontWeight: 'bold',
          color: Theme.colors.primaryColor,
          marginBottom: '16px',
        }}
      >
        Upsert Data
      </span>
      <div>
        <div style={{ width: '100%', marginBottom: '16px' }}>
          <SelectSearchbar
            placeholder="Select indexes..."
            recordsPropertyToDisplay="label"
            recordsPropertyToSortBy="label"
            values={selectedIndexes}
            setValues={(values) => setSelectedIndexes(values)}
            isMultiselect
            perPage={props.elasticsearchIndexes.length}
            rawData={props.elasticsearchIndexes}
            isRawDataMode
          />
        </div>
        <div style={{ width: '100%', marginBottom: '16px' }}>
          <Input
            type="number"
            placeholder="Concurrency..."
            value={concurrency}
            setValue={setConcurrency}
          />
        </div>
        {selectedIndexes &&
          (selectedIndexes.includes(ElasticsearchIndexes.development) ||
            selectedIndexes.includes(ElasticsearchIndexes.developmentEvent) ||
            selectedIndexes.includes(
              ElasticsearchIndexes.developmentCitation
            )) && (
            <div style={{ width: '100%', marginBottom: '16px' }}>
              <YearInput
                values={selectedYears}
                setValues={(values) => setSelectedYears(values)}
                options={elasticsearchYears}
              />
            </div>
          )}
        {selectedIndexes &&
          selectedIndexes.includes(
            ElasticsearchIndexes.developmentCitation
          ) && (
            <div style={{ width: '100%', marginBottom: '16px' }}>
              <Input
                type="number"
                placeholder="From ID..."
                value={fromId}
                setValue={setFromId}
              />
            </div>
          )}
        {selectedIndexes &&
          selectedIndexes.includes(
            ElasticsearchIndexes.developmentCitation
          ) && (
            <div style={{ width: '100%', marginBottom: '16px' }}>
              <Input
                type="number"
                placeholder="To ID..."
                value={toId}
                setValue={setToId}
              />
            </div>
          )}
        <Button
          onClick={() => {
            setIsConfirmationModalDisplayed(true);
          }}
          style={{ marginBottom: '16px' }}
          isDisabled={isProcessOnGoing}
        >
          Run
        </Button>
        <div>
          <span
            style={{
              marginBottom: '16px',
              display: 'block',
            }}
          >
            Output
          </span>
          <OutputConsole outputs={outputs} height="300px" />
        </div>
      </div>
      <ConfirmationModal
        title={'Upsert Elasticsearch Data?'}
        onPrimaryAction={() => {
          handlePrimaryAction();
          setIsConfirmationModalDisplayed(false);
        }}
        onSecondaryAction={() => {
          setIsConfirmationModalDisplayed(false);
        }}
        isDisplayed={isConfirmationModalDisplayed}
        primaryText="Yes"
        secondaryText="Cancel"
      >
        <span>
          Are you sure that you want to upsert the data in elasticsearch for{' '}
          {selectedIndexes.length
            ? selectedIndexes.length === 1
              ? 'index ' + selectedIndexes[0]
              : 'indexes ' + selectedIndexes.join(', ')
            : 'every index'}
          ? Make sure that the process is finished, else, data would be missing.
        </span>
      </ConfirmationModal>
    </Card>
  );
};
