import { Header } from 'components/Header';
import { OverflowYAutoContainer } from 'components/OverflowYAutoContainer';
import { Theme } from 'styles/themes';
import { BoldText, FlexContainer } from 'styles/utils';
import { CleanDataCard, UpsertDataCard } from './components';
import { useAppSelector } from 'store/hooks';
import { selectSelectedEnvironment } from 'store/environment/environment-slice';
import { useMemo } from 'react';
import { ElasticsearchIndexes } from 'enums';

export const AdminElasticsearch = () => {
  const selectedEnvironment = useAppSelector(selectSelectedEnvironment);

  const elasticsearchIndexes = useMemo(() => {
    return Object.entries(ElasticsearchIndexes).map(([key, value]) => {
      return {
        id: value,
        label: value,
      };
    });
  }, [ElasticsearchIndexes]);

  return (
    <>
      <FlexContainer
        flexDirection="column"
        style={{
          width: '100%',
          flex: 1,
          position: 'relative',
        }}
      >
        <Header hasBorderBottom style={{ width: '100%' }}>
          <BoldText style={{ fontSize: Theme.fontSize + 2 }}>
            Elasticsearch Operations
          </BoldText>
        </Header>
        <OverflowYAutoContainer
          style={{
            backgroundColor: Theme.colors.primaryBackgroundColor,
            width: '100%',
            padding: '16px',
            height: 'calc(100% - 56px)',
          }}
        >
          <CleanDataCard
            selectedEnvironment={selectedEnvironment}
            elasticsearchIndexes={elasticsearchIndexes}
          />
          <br />
          <UpsertDataCard
            selectedEnvironment={selectedEnvironment}
            elasticsearchIndexes={elasticsearchIndexes}
          />
        </OverflowYAutoContainer>
      </FlexContainer>
    </>
  );
};
