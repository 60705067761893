import { routes } from 'navigation/routes';
import { ReportsHeader } from 'pages/reports/components';
import { ReportDevelopmentsListSubHeader } from 'pages/reports/performance/components/PerformanceDevelopmentList/components';
import { FlexContainer } from 'styles/utils';
import { performanceReportLabels } from 'pages/reports/performance/labels';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getAllDevelopmentsAsync,
  getEntityDetails,
  increaseDevelopmentListPage,
  selectPerformanceReportDevelopmentsPage,
  selectPerformanceReportDevelopmentsSearchTerm,
  selectPerformanceReportDevelopmentsSortByDirection,
  selectPerformanceReportDevelopmentsSortByProperty,
  selectEntityDetails,
  selectIsReportDataFromAverageDevelopmentsPerDay,
  selectPerformanceReportDevelopments,
  selectPerformanceReportPerPage,
  selectPeriodProperty,
  selectPerformanceReportDevelopmentsTotalRecords,
  resetPerformanceReportDevelopmentsPage,
  selectTriggerPerformanceReportDevelopmentsUpdate,
  setTriggerPerformanceReportDevelopmentsUpdate,
} from 'store/performance-report/performance-report-slice';
import { useEffect, useMemo, useState } from 'react';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { DevelopmentReviewStatuses } from 'enums';
import { reportByOptions } from 'pages/reports/performance/report-by-options';
import { Theme } from 'styles/themes';
import { DevelopmentList } from 'components/DevelopmentList';
import { Development } from 'models';
import { getSubtractionFromDateInMilliseconds } from 'utils/date';
import { periodOptions } from 'config/report-period-options';

export const PerformanceDevelopmentList = () => {
  const search = useLocation().search;
  const navigate = useNavigate();
  const params = new URLSearchParams(search);
  const reviewStatuses = params.getAll('reviewStatus');
  const dispatch = useAppDispatch();
  const developments = useAppSelector(selectPerformanceReportDevelopments);
  const searchTerm = useAppSelector(
    selectPerformanceReportDevelopmentsSearchTerm
  );
  const sortByProperty = useAppSelector(
    selectPerformanceReportDevelopmentsSortByProperty
  );
  const sortByDirection = useAppSelector(
    selectPerformanceReportDevelopmentsSortByDirection
  );
  const page = useAppSelector(selectPerformanceReportDevelopmentsPage);
  const perPage = useAppSelector(selectPerformanceReportPerPage);
  const totalRecords = useAppSelector(
    selectPerformanceReportDevelopmentsTotalRecords
  );
  const { entityType, id } = useParams<{ entityType: string; id: string }>();

  const periodProperty = useAppSelector(selectPeriodProperty);
  const isReportDataFromAverageDevelopmentsPerDay = useAppSelector(
    selectIsReportDataFromAverageDevelopmentsPerDay
  );
  const entityDetails = useAppSelector(selectEntityDetails);
  const triggerDevelopmentListUpdate = useAppSelector(
    selectTriggerPerformanceReportDevelopmentsUpdate
  );
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleDevelopmentClick = (id: string) => {
    navigate(
      generatePath(routes.developmentDetail, {
        id,
      })
    );
  };
  const handleReachBottom = () => {
    dispatch(increaseDevelopmentListPage());
  };

  const fromDateInMilliseconds = useMemo(() => {
    return getSubtractionFromDateInMilliseconds(
      new Date(),
      periodOptions[periodProperty].durationObject
    );
  }, [periodProperty]);

  const reportByOptionEntity = useMemo(() => {
    return Object.values(reportByOptions).find((v) => v.urlText === entityType);
  }, [entityType]);

  useEffect(() => {
    dispatch(getEntityDetails(Number(id), reportByOptionEntity!.value));
  }, [entityType, id]);

  useEffect(() => {
    dispatch(resetPerformanceReportDevelopmentsPage());
  }, [searchTerm, sortByProperty, sortByDirection, search]);

  useEffect(() => {
    dispatch(
      getAllDevelopmentsAsync(
        reportByOptionEntity!.queryParam,
        Number(id),
        reviewStatuses.map((status) => Number(status)),
        Number(fromDateInMilliseconds)
      )
    )
      .catch((error: string) => {
        setErrorMessage(error);
      })
      .finally(() => setIsLoading(false));
  }, [
    isReportDataFromAverageDevelopmentsPerDay,
    periodProperty,
    page,
    search,
    searchTerm,
    sortByProperty,
    sortByDirection,
    entityType,
    id,
    fromDateInMilliseconds,
    triggerDevelopmentListUpdate,
  ]);

  const breadcrumb = useMemo(() => {
    let statusTitle = '';
    if (
      reviewStatuses.includes(
        String(DevelopmentReviewStatuses.ActionRequired)
      ) &&
      reviewStatuses.includes(
        String(DevelopmentReviewStatuses.NoActionRequired)
      )
    ) {
      statusTitle = 'Completed';
    } else if (
      reviewStatuses.includes(String(DevelopmentReviewStatuses.ActionRequired))
    ) {
      statusTitle = 'Actionable';
    } else if (
      reviewStatuses.includes(
        String(DevelopmentReviewStatuses.NoActionRequired)
      )
    ) {
      statusTitle = 'Not actionable';
    }

    return [
      {
        text: performanceReportLabels.title,
        path: routes.performanceReport,
      },
      {
        text: entityDetails?.name || '',
        path: generatePath(routes.performanceDetails, {
          entityType: entityType?.toLowerCase(),
          id,
        }),
      },
      {
        text: `${statusTitle} by ${reportByOptionEntity?.text.toLowerCase()}`,
      },
    ];
  }, [entityDetails, entityType, id, reviewStatuses]);

  return (
    <FlexContainer
      flexWrap="nowrap"
      flexDirection="column"
      style={{
        width: '100%',
        flex: 1,
        overflowX: 'hidden',
        backgroundColor: Theme.colors.primaryBackgroundColor,
      }}
    >
      <ReportsHeader
        breadcrumb={breadcrumb}
        backButtonPath={generatePath(routes.performanceDetails, {
          entityType: entityType?.toLowerCase(),
          id,
        })}
        hasBorderBottom
      />
      <ReportDevelopmentsListSubHeader />
      <DevelopmentList
        isLoading={isLoading}
        developments={developments}
        errorMessage={errorMessage}
        handleReachBottom={handleReachBottom}
        page={page}
        totalRecords={totalRecords}
        perPage={perPage}
        handleDevelopmentClick={handleDevelopmentClick}
        onAfterSave={(development: Development | null) => {
          if (development) {
            dispatch(setTriggerPerformanceReportDevelopmentsUpdate());
          }
        }}
      />
    </FlexContainer>
  );
};
