interface ITheme {
  colors: { readonly [key in keyof typeof appColors]: string };
  fontSizes: { readonly [key in keyof typeof fontSizes]: string | number };
}

const fontSizes = {
  default: 16,
};

const appColors = {
  borderGray: '#E0E0E0',
  borderGray2: '#9E9E9E',
  gray2: '#616161',
  gray1: '#212121',
  support2: '#F7F7FA',
  highlight: '#DFEFFD',
  highlight2: '#D7EEFF',
  highlight3: '#FCE9D8',
  highlight4: '#ebf7fe',
  primary: '#e3f4ff',
  white: 'white',
  danger: '#BF3131',
  warning: '#DDAC03',
};

export const Theme: ITheme = {
  colors: appColors,
  fontSizes,
};

const theme = {
  font: {
    family: 'Inter',
    fontAwesome: 'FontAwesome',
    size: {
      base: '14px',
      small: '12px',
      smallest: '11px',
      large: '16px',
      extra: '18px',
      extraLarge: '26px',
    },
    color: {
      base: '#777777',
      white: '#FFFFFF',
      blue: '#0296F2',
      black: '#212121',
      gray: '#9E9E9E',
      gray2: '#616161',
      gray3: '#F7F7FA',
      gray4: '#E0E0E0',
      brand: '#2c3a57',
      danger: '#BF3131',
      warning: '#DDAC03',
    },
    weight: {
      light: '300',
      regular: '400',
      bold: '600',
      extrabold: '700',
    },
  },
  border: {
    size: {
      one: '1px',
      two: '2px',
    },
    color: {
      base: '#E0E0E0',
      hover: '#0296F2',
      focus: '#D7EFFF',
      danger: '#BF3131',
      warning: '#DDAC03',
      blue: '#0296F2',
    },
  },
  opacity: {
    high: 1,
    low: 0.4,
  },
  input: {
    colorPallete: {
      default: '#212121',
      primary: '#0296F2',
      secondary: '#ffffff',
      success: '#3DB349',
      danger: '#BF3131',
      warning: '#DDAC03',
      placeholder: '#9e9e9e',
    },
    backgroundPallete: {
      danger: 'rgba(191, 49, 49, 0.05)',
      warning: 'rgba(254, 249, 190, 0.08)',
    },
  },
  textarea: {
    height: {
      small: '80px',
      extrasmall: '107px',
      medium: '124px',
      large: '132px',
      extralarge: '264px',
    },
  },
  button: {
    colorPallete: {
      primary: '#FFFFFF',
      secondary: '#212121',
      tertiary: '#0296F2',
      dangerSecondary: '#651B1B',
    },
    backgroundPallete: {
      primary: '#0296F2',
      secondary: '#F5F5F5',
      tertiary: 'transparent',
      danger: '#BF3131',
      hover: '#21A8FD',
      focus: '#0264A2',
      dangerSecondary: '#F7DFDF',
      secondaryColorLight: '#E5F5FF',
    },
  },
  cursor: {
    default: 'pointer',
  },
  dropdown: {
    colorPallete: {
      default: '#212121',
      primary: '#0296F2',
      secondary: '#ffffff',
      success: '#3DB349',
      danger: '#BF3131',
      warning: '#E7D503',
      labelColor: ' #212121',
      borderColor: '#E0E0E0',
      borderColorHover: '#0296F2',
      borderColorFocus: '#D7EFFF',
      header: 'rgb(79, 165, 249)',
    },
    backgroundPallete: {
      default: '#FFFFFF',
      selected: 'rgba(2, 150, 242, 0.08)',
      hover: 'rgba(2, 150, 242, 0.08)',
      header: 'rgb(79, 165, 249)',
    },
  },
  heading: {
    color: {
      one: '#212121',
    },
  },
  content: {
    color: {
      one: '#616161',
    },
  },
  card: {
    selected: 'rgba(2, 150, 242, 0.08);',
  },
  pagination: {
    break: {
      color: '#9E9E9E',
    },
  },
  accordion: {
    colorPallete: {
      default: '#212121',
    },
    backgroundPallete: {
      default: '#FFFFFF',
    },
    borderBottom: {
      default: '1px solid #E0E0E0',
    },
    padding: {
      default: '20px 24px',
    },
    header: {
      grey: '#E5E5E9',
    },
  },
  section: {
    colorPallete: {
      default: '#000000',
      primary: '#616161',
      secondary: '#2c3a57',
      labelColor: '#212121',
      arrowbutton: '#9e9e9e',
    },
    backgroundPallete: {
      danger: 'rgba(191, 49, 49, 0.05)',
      default: '#ffffff',
      transparent: 'transparent',
    },
  },
  sidebar: {
    fontSizes: {
      default: '20px',
    },
    colorPallete: {
      default: '#FFFFFF',
      primary: '#2c3a57',
    },
    backgroundPallete: {
      default: 'rgba(93, 192, 253, 0.32)',
      primary: '#002238',
      borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
      secondary: '#2C3A57',
    },
  },
  sidebarMenu: {
    default: 'rgba(0, 0, 0, 0.3)',
    primary: 'rgba(0, 0, 0, 0.2)',
    light: 'rgba(255,255,255,0.1)',
    selected: '#0264A2',
  },
  toast: {
    titleColor: {
      success: '#39A744',
      error: '#BF3131',
      info: '#21A8FD',
      warning: '#383F08',
    },
    iconColor: {
      success: '#39A744',
      error: '#BF3131',
      info: '#21A8FD',
      warning: '#E7D503',
    },
    wrapperBorder: 'rgba(0, 0, 0, 0.05);',
    boxShadow:
      'rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04),0px 0px 1px rgba(0, 0, 0, 0.04);',
    loaderBoxShadow:
      'rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);',
  },
  toolTip: {
    fontFamily: 'Heebo',
    fontWeight: '400',
    fontSize: '14px',
    color: '#ffffff',
    backgroundColor: '#212121',
    marginY: '44px',
    marginX: '8px',
    arrowSize: '8px',
  },
  advanceSearch: { backgroundColor: '#f7f7fa' },
  scrollBar: {
    track: '#e5e5e9',
    thumb: '#01416a',
  },
  identifier: {
    bvdidStockExchange: '#21a8fd',
    noBvdidStockExchange: '#4ca63f',
    bvdid: '#212121',
    stockExchange: '#ef893d',
    statusEmpty: '#e0e0e0',
    ceasedTrading: '#ec78e7',
  },
  duplicate: {
    base: '#FEF9BE',
    hover: '#FEF481',
  },
  companyHeaderColor: {
    default: 'rgb(255, 255, 255)',
    isAdvisor: 'rgb(255, 201, 201)',
    equityFund: 'rgb(201, 255, 201)',
    hedgeFund: 'rgb(180, 200, 255)',
  },
};

export default theme;
