import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorText, FlexContainer } from 'styles/utils';
import { ReactComponent as Warning } from 'assets/warning.svg';
import { recoverPassword } from 'services/auth/auth-service';
import { routes } from 'navigation/routes';
import { Theme } from 'styles/themes';
import { AuthContainer, LoginSubTitle } from 'pages/auth/components';

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    window.onkeydown = (e: any) => {
      if (e.key === 'Enter' && email) {
        handleSubmit();
      }
    };
  }, [email]);

  const handleSubmit = useCallback(() => {
    if (emailSent) {
      navigate(routes.login);
      return;
    }
    recoverPassword(email)
      .then((d) => setEmailSent(true))
      .catch((e) => setErrorMessage(e));
  }, [email, setEmailSent, emailSent]);

  return (
    <AuthContainer
      title={emailSent ? 'Email sent!' : 'Forgot your password'}
      subtitle={
        <LoginSubTitle>
          {emailSent
            ? 'We have sent you an email containing the instructions and a link that will allow you to reset your password.'
            : 'Please confirm your email and we will send you a link to reset your password'}
        </LoginSubTitle>
      }
      showPolicy
    >
      <>
        {!emailSent && (
          <FlexContainer
            id="ForgotPasswordEmailContainer"
            style={{ width: '100%', marginBottom: '30px' }}
          >
            <label
              htmlFor="email"
              style={{ marginRight: 'auto', marginBottom: '5px' }}
            >
              Email
            </label>
            <Input
              id="email"
              placeholder="example@email.com"
              value={email}
              setValue={setEmail}
            />
          </FlexContainer>
        )}
        <Button
          id="ForgotPasswordSubmitButton"
          onClick={handleSubmit}
          isDisabled={!email}
        >
          {emailSent ? 'Go to Log in' : 'Submit'}
        </Button>
        {errorMessage && errorMessage.length > 0 && (
          <FlexContainer
            id="ForgotPasswordErrorContainer"
            style={{ marginTop: '10px' }}
          >
            <Warning
              id="ForgotPasswordWarningIcon"
              style={{ margin: 'auto 5px auto 0', color: Theme.colors.red }}
            />
            <ErrorText
              id="ForgotPasswordErrorText"
              style={{ margin: 'auto 0' }}
            >
              {errorMessage}
            </ErrorText>
          </FlexContainer>
        )}
      </>
    </AuthContainer>
  );
};
