import styled from '@emotion/styled';
import { InputHTMLAttributes } from 'react';
import { Theme } from 'styles/themes';
import { FlexContainer } from 'styles/utils';

export interface RadioButtonProps
  extends InputHTMLAttributes<HTMLInputElement>,
    RadioButtonStyles {
  text: string;
  disabled?: boolean;
}

export interface RadioButtonStyles {
  fontSize?: number;
  radioSize?: number | string;
}

const RadioButtonInput = styled.input<{ radioSize?: number | string }>(
  (props) => ({
    width: props.radioSize || '24px',
    height: props.radioSize || '24px',
    margin: 'auto 6px auto 0',
    cursor: 'pointer',
  })
);

const RadioButtonText = styled.label<{ disabled?: boolean; fontSize?: number }>(
  (props) => ({
    color: props.disabled ? Theme.colors.grayDark : 'inherit',
    fontSize: props.fontSize || Theme.fontSize,
    margin: 'auto 0',
    cursor: 'pointer',
  })
);

export const RadioButton: React.FC<RadioButtonProps> = (
  props: RadioButtonProps
) => {
  return (
    <FlexContainer style={props.style}>
      <RadioButtonInput
        type="radio"
        id={props.id}
        name={props.name}
        value={props.value}
        disabled={props.disabled}
        radioSize={props.radioSize}
        checked={props.checked}
        onChange={() => {}}
      />
      <RadioButtonText
        htmlFor={props.id}
        disabled={props.disabled}
        fontSize={props.fontSize}
      >
        {props.text}
      </RadioButtonText>
    </FlexContainer>
  );
};
