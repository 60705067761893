export const addOrRemoveIfExists = <T>(arr: T[], element: T): T[] => {
  const arrClone = arr ? [...arr] : [];
  const arrCloneStringified = arrClone.map((x) => JSON.stringify(x));
  if (!arrCloneStringified.includes(JSON.stringify(element))) {
    arrClone.push(element);
  } else {
    const index = arrCloneStringified.indexOf(JSON.stringify(element));
    if (index > -1) {
      arrClone.splice(index, 1);
    }
  }
  return arrClone;
};

export const updateElementInArrayIfExists = <T>(
  arr: T[],
  element: T,
  idProperty: keyof T
): T[] => {
  const arrClone = arr ? [...arr] : [];
  const index = arrClone.map((x) => x[idProperty]).indexOf(element[idProperty]);
  if (index > -1) {
    arrClone.splice(index, 1);
    arrClone.splice(index, 0, element);
  }
  return arrClone;
};

export const doBothArraysHaveTheSameElements = (
  arr1?: (string | number)[],
  arr2?: (string | number)[]
): boolean => {
  const arr1Clone = arr1 ? [...arr1] : [];
  const arr2Clone = arr2 ? [...arr2] : [];
  return arr1Clone.sort().toString() === arr2Clone.sort().toString();
};

export const isArraySubsetOfAnother = <T>(
  subArr: T[],
  targetArr: T[]
): boolean => {
  return !targetArr.length || subArr.every((x) => targetArr.includes(x));
};

export const paginate = <T>(arr: T[], page: number, perPage: number): T[] => {
  const paginatedRecords: T[] = [];
  for (let index = 1; index <= page; index++) {
    paginatedRecords.push(...arr.slice((index - 1) * perPage, index * perPage));
  }
  return paginatedRecords;
};
