import { ElasticsearchIndexes } from 'enums';
import { HttpResponse } from 'utils/http/HttpResponse';
import { httpPost } from 'utils/http/base-http-service';

export const cleanData = async (
  environmentElasticsearchIndexPrefix: string,
  indexes?: ElasticsearchIndexes[]
): Promise<HttpResponse<string | null>> => {
  return await httpPost<
    string,
    {
      environmentElasticsearchIndexPrefix: string;
      indexes?: ElasticsearchIndexes[];
    }
  >(`elasticsearch/clean-data`, {
    environmentElasticsearchIndexPrefix,
    indexes,
  });
};
