import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { Overlay } from 'components/Overlay';
import { HTMLAttributes } from 'react';

interface LoadingProps extends HTMLAttributes<HTMLDivElement> {
  withOverlay?: boolean;
  opacity?: number;
}

/** Spinner CSS taken from: https://jsfiddle.net/kmturley/54hqzmk7/ */
const LoadingContainer = styled.div({
  width: '50px',
  height: '50px',
  position: 'relative',
  boxSizing: 'content-box',
  zIndex: 2,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  '.circle.left': {
    position: 'absolute',
    clip: 'rect(0, 50px, 50px, 25px)',
    boxSizing: 'content-box',
  },
  '.circle.right': {
    position: 'absolute',
    clip: 'rect(0px, 25px, 50px, 0px)',
    boxSizing: 'content-box',
  },
  '.circle span': {
    width: '40px',
    height: '40px',
    borderRadius: '100%',
    position: 'absolute',
    border: `5px solid ${Theme.colors.primaryColor}`,
    boxSizing: 'content-box',
  },
  '.circle.left span': {
    clip: 'rect(0px, 25px, 50px, 0px)',
    animation: 'rotate-left 1s infinite linear',
  },
  '.circle.right span': {
    clip: 'rect(0, 50px, 50px, 25px)',
    animation: 'rotate-right 1s infinite linear',
  },
  '@keyframes rotate-left': {
    '0%': { transform: 'rotate(0deg)' },
    '50%': { transform: 'rotate(180deg)' },
    '100%': { transform: 'rotate(180deg)' },
  },
  '@keyframes rotate-right': {
    '0%': { transform: 'rotate(0deg)' },
    '50%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(180deg)' },
  },
});

export const Loading = ({ withOverlay = true, id, opacity }: LoadingProps) => {
  const LoadingContainerContainer = () => {
    return (
      <LoadingContainer id={id}>
        <div className="circle left rotate">
          <span></span>
        </div>
        <div className="circle right rotate">
          <span></span>
        </div>
      </LoadingContainer>
    );
  };

  return withOverlay ? (
    <Overlay backgroundColor={Theme.colors.white} opacity={opacity}>
      <LoadingContainerContainer />
    </Overlay>
  ) : (
    <LoadingContainerContainer />
  );
};
