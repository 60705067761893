import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { routes } from 'navigation/routes';
import { RadioButton } from 'components/RadioButton';
import { Environment } from 'models';
import {
  setSelectedEnvironment,
  selectSelectedEnvironment,
  selectCurrentUserEnvironments,
} from 'store/environment/environment-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { pascalCase } from 'utils/string-formatter';

interface EnvironmentPickerProps {
  onSelect: () => void;
}

const EnvironmentPickerContainer = styled.div({
  width: '288px',
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
});

const EnvironmentPickerTitle = styled.div({
  marginBottom: '4px',
  alignItems: 'center',
  fontSize: Theme.fontSize,
  lineHeight: `${Theme.fontSize + 4}px`,
  color: Theme.colors.grayDark,
});

const EnvironmentPickerButton = styled.button<{
  isSelected?: boolean;
}>((props) => ({
  width: '100%',
  padding: '16px',
  marginTop: '9px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  textAlign: 'start',
  fontWeight: props.isSelected ? 'bold' : 'inherit',
  fontSize: Theme.fontSize,
  lineHeight: `${Theme.fontSize + 4}px`,
  backgroundColor: props.isSelected
    ? Theme.colors.blueLightest
    : Theme.colors.transparent,
  border: `1px solid ${
    props.isSelected ? Theme.colors.secondaryColor : Theme.colors.grayBorder
  }`,
  color: Theme.colors.primaryColor,
  cursor: 'pointer',
}));

export const EnvironmentPicker = memo((props: EnvironmentPickerProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedEnvironment = useAppSelector(selectSelectedEnvironment);
  const currentUserEnvironments = useAppSelector(selectCurrentUserEnvironments);

  const handleSelectEnvironment = useCallback(
    (environment: Environment) => {
      props.onSelect();
      dispatch(setSelectedEnvironment(environment));
      navigate(routes.home);
      window.location.reload();
    },
    [props.onSelect]
  );

  return (
    <EnvironmentPickerContainer id="EnvironmentPickerContainer">
      <EnvironmentPickerTitle id="EnvironmentPickerTitle">
        Select environment:
      </EnvironmentPickerTitle>
      {currentUserEnvironments.map((environment) => (
        <EnvironmentPickerButton
          id={`EnvironmentPickerButton${pascalCase(environment.name)}`}
          key={environment.name}
          isSelected={environment.name === selectedEnvironment?.name}
          onClick={() => handleSelectEnvironment(environment)}
        >
          <RadioButton
            key={`EnvironmentPickerRadioButton${pascalCase(environment.name)}`}
            id={`EnvironmentPickerRadioButton${pascalCase(environment.name)}`}
            text={''}
            checked={environment.name === selectedEnvironment?.name}
            name={environment.name}
            style={{
              marginRight: '8px',
            }}
          />
          {environment.name}
        </EnvironmentPickerButton>
      ))}
    </EnvironmentPickerContainer>
  );
});
