import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { Chip } from 'components/Chip';

export const BlueChip = styled(Chip)((props) => ({
  backgroundColor: Theme.colors.secondaryColorLighter,
  border: props.isSelected
    ? `1px solid ${Theme.colors.secondaryColorLight}`
    : 'none',
  color: Theme.colors.primaryColor,
}));
