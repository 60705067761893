import { memo } from 'react';
import { NotFoundPrompt, NotFoundPromptProps } from './components';
import { Modal } from 'components/Modal';

interface NotFoundModalProps extends NotFoundPromptProps {
  isDisplayed: boolean;
}

export const NotFoundModal = memo((props: NotFoundModalProps) => {
  const { isDisplayed, ...rest } = props;
  return (
    <Modal id="NotFoundModal" isDisplayed={isDisplayed}>
      <NotFoundPrompt {...rest} />
    </Modal>
  );
});
