import { useEffect, useState } from 'react';
import { Sidebar } from 'components/Sidebar';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getAllDetailDevelopmentCitationsAsync,
  selectDetailDevelopmentCitations,
  selectDetailDevelopmentCitationsAreOnlyDirectMatchesDisplayed,
  selectDetailDevelopmentCitationsPage,
  selectDetailDevelopmentCitationsSearchTerm,
  selectDetailDevelopmentCitationsTotalRecords,
  selectDetailSelectedDevelopmentCitation,
} from 'store/detail-development-citation/detail-development-citation-slice';
import { FlexContainer } from 'styles/utils';
import { Loading } from 'components/Loading';
import { Development, DevelopmentCitation } from 'models';
import {
  DetailRightSidebarHeader,
  DevelopmentCitationCard,
} from './components';

interface DetailRightSidebarProps {
  development?: Development | null;
  onClickDevelopmentCitation: (
    developmentCitation: DevelopmentCitation
  ) => void;
  isDisplayed: boolean;
  setIsLegendOptionsDisplayed: React.Dispatch<React.SetStateAction<boolean>>;
  setIsReprocessDevelopmentModalDisplayed: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

export const DetailRightSidebar: React.FC<DetailRightSidebarProps> = (
  props: DetailRightSidebarProps
) => {
  const dispatch = useAppDispatch();
  const [documentCitationsHeaderHeight, setDocumentCitationsHeaderHeight] =
    useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [
    triggerCollapseObligationMatchesCards,
    setTriggerCollapseObligationMatchesCards,
  ] = useState<number>(0);
  const developmentCitations = useAppSelector(selectDetailDevelopmentCitations);
  const developmentCitationsTotalRecords = useAppSelector(
    selectDetailDevelopmentCitationsTotalRecords
  );
  const developmentCitationsPage = useAppSelector(
    selectDetailDevelopmentCitationsPage
  );
  const developmentCitationsSearchTerm = useAppSelector(
    selectDetailDevelopmentCitationsSearchTerm
  );
  const developmentCitationsAreOnlyDirectMatchesDisplayed = useAppSelector(
    selectDetailDevelopmentCitationsAreOnlyDirectMatchesDisplayed
  );
  const selectedDevelopmentCitation = useAppSelector(
    selectDetailSelectedDevelopmentCitation
  );

  useEffect(() => {
    (async () => {
      if (
        props.development &&
        props.development.id &&
        props.development.developmentCitationsCount
      ) {
        setIsLoading(true);
        await dispatch(
          getAllDetailDevelopmentCitationsAsync(
            props.development.id,
            props.development.mainAuthority?.regionId,
            props.development.processingDate
          )
        );
        setIsLoading(false);
      }
    })();
  }, [
    developmentCitationsPage,
    developmentCitationsSearchTerm,
    developmentCitationsAreOnlyDirectMatchesDisplayed,
    developmentCitationsTotalRecords,
    props.development?.id,
  ]);

  return (
    <>
      {props.isDisplayed && (
        <>
          <Sidebar
            id="DevelopmentDetailRightSidebar"
            header={
              <DetailRightSidebarHeader
                setDocumentCitationsHeaderHeight={
                  setDocumentCitationsHeaderHeight
                }
                developmentCitationsCount={developmentCitationsTotalRecords}
                setTriggerCollapseObligationMatchesCards={
                  setTriggerCollapseObligationMatchesCards
                }
                setIsLegendOptionsDisplayed={props.setIsLegendOptionsDisplayed}
                setIsReprocessDevelopmentModalDisplayed={
                  props.setIsReprocessDevelopmentModalDisplayed
                }
              />
            }
            headerHeight={documentCitationsHeaderHeight}
          >
            <FlexContainer
              id="DevelopmentDetailRightSidebarContainer"
              style={{
                width: '100%',
                padding: '0 15px',
              }}
              flexDirection="column"
            >
              {isLoading && <Loading withOverlay />}
              {developmentCitations.map((developmentCitation, y) => {
                return (
                  <DevelopmentCitationCard
                    id={`DevelopmentDetailRightSidebarDevelopmentCitationCard${y}`}
                    key={developmentCitation.id}
                    developmentCitation={developmentCitation}
                    selectedDevelopmentCitation={selectedDevelopmentCitation}
                    onClickDevelopmentCitation={
                      props.onClickDevelopmentCitation
                    }
                    triggerCollapseObligationMatchesCard={
                      triggerCollapseObligationMatchesCards
                    }
                  />
                );
              })}
            </FlexContainer>
          </Sidebar>
        </>
      )}
    </>
  );
};
