import styled from '@emotion/styled';
import { Avatar } from 'components/Avatar';
import { ArrowDirections, Tooltip } from 'components/Tooltip';
import { DevelopmentUserReview } from 'models';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { selectReviewers } from 'store/real-time-reviewers/real-time-reviewers-slice';
import { useAppSelector } from 'store/hooks';

const AvatarsReviewingContainer = styled.div({
  display: 'flex',
  '> div:not(:first-of-type)': {
    marginLeft: '4px',
  },
  '> div:not(:last-child)': {
    marginRight: '4px',
  },
});

export const UsersReviewing = () => {
  const { developmentId } = useParams<{ developmentId?: string }>();
  const realTimeReviewers = useAppSelector(selectReviewers);
  const [
    avatarsReviewingContainerElement,
    setAvatarsReviewingContainerElement,
  ] = useState<HTMLDivElement | null>(null);
  const [
    moreUsersReviewingContainerElement,
    setMoreUsersReviewingContainerElement,
  ] = useState<HTMLDivElement | null>(null);
  const [usersReviewing, setUsersReviewing] = useState<DevelopmentUserReview[]>(
    []
  );
  const [usersReviewingCount, setUsersReviewingCount] = useState(0);
  const usersReviewingDisplayLimit = 3;
  const [
    moreUsersReviewingTooltipContent,
    setMoreUsersReviewingTooltipContent,
  ] = useState<DevelopmentUserReview[]>([]);

  const onAvatarsReviewingContainerRefChange = useCallback(
    (avatarsReviewingContainer: HTMLDivElement) => {
      if (avatarsReviewingContainer) {
        setAvatarsReviewingContainerElement(avatarsReviewingContainer);
      }
    },
    [usersReviewing, developmentId]
  );
  const onMoreUsersReviewingContainerRefChange = useCallback(
    (moreUsersReviewingContainer: HTMLDivElement) => {
      if (moreUsersReviewingContainer) {
        setMoreUsersReviewingContainerElement(moreUsersReviewingContainer);
      }
    },
    [usersReviewing, developmentId]
  );

  useEffect(() => {
    if (usersReviewing && usersReviewing.length) {
      setUsersReviewingCount(usersReviewing.length);
    }
  }, [usersReviewing]);

  useEffect(() => {
    if (usersReviewingCount > usersReviewingDisplayLimit) {
      setMoreUsersReviewingTooltipContent(
        [...usersReviewing]
          .filter((x, y) => y >= usersReviewingDisplayLimit)
          .sort(
            (a, b) =>
              Date.parse(b.dateReviewed || '') -
              Date.parse(a.dateReviewed || '')
          )
      );
    }
  }, [usersReviewingCount]);

  useEffect(() => {
    const reviewersOnCurrentDevelopment = realTimeReviewers.filter(
      (x) => String(x.developmentId) === developmentId
    );
    setUsersReviewing(reviewersOnCurrentDevelopment);
  }, [JSON.stringify(realTimeReviewers), developmentId]);

  return (
    <AvatarsReviewingContainer
      id="AvatarsReviewingContainer"
      ref={onAvatarsReviewingContainerRefChange}
    >
      {usersReviewing
        .filter((x, y) => y < usersReviewingDisplayLimit)
        .map((x, y) => {
          return (
            <div
              id={`AvatarReviewing${y}`}
              key={`${x.developmentId}_${x.userId}_${x.reviewStatus}`}
              style={{ position: 'relative' }}
            >
              <Tooltip
                id={`AvatarReviewing${y}Tooltip`}
                relativeToElement={
                  avatarsReviewingContainerElement?.children[y] as HTMLElement
                }
                hasArrow
                arrowDirection={ArrowDirections.Right}
              >
                <span
                  id={`AvatarReviewing${y}Name`}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {x.userFirstName} {x.userLastName}
                </span>
              </Tooltip>
              <Avatar
                id={`AvatarReviewing${y}Avatar`}
                text={
                  (x.userFirstName || 'X').charAt(0) +
                  (x.userLastName || 'X').charAt(0)
                }
              />
            </div>
          );
        })}
      {usersReviewingCount > usersReviewingDisplayLimit && (
        <div
          id="AvatarsReviewingCounter"
          ref={onMoreUsersReviewingContainerRefChange}
          style={{ position: 'relative' }}
        >
          <Tooltip
            id="AvatarsReviewingCounterTooltip"
            relativeToElement={moreUsersReviewingContainerElement}
            hasArrow
            arrowDirection={ArrowDirections.Up}
          >
            {moreUsersReviewingTooltipContent.map((x) => (
              <span
                id={`AvatarsReviewingCounterTooltipContent${x.userId}`}
                key={x.userId}
                style={{ whiteSpace: 'nowrap' }}
              >
                {`${x.userFirstName} ${x.userLastName}`}
              </span>
            ))}
          </Tooltip>
          <Avatar
            id="AvatarsReviewingCounterAvatar"
            text={`+${usersReviewingCount - usersReviewingDisplayLimit}`}
          />
        </div>
      )}
    </AvatarsReviewingContainer>
  );
};
