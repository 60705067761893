import { developmentKeys } from 'config/development-keys';
import { FilterEntry } from 'models';

export const getStatusFilters = (userId: number): FilterEntry[] => {
  return [
    {
      value: userId,
      name: 'Saved',
      propertyToFilterByOverride: developmentKeys.savedByUserIds,
    },
    {
      value: true,
      name: 'Latest version',
      propertyToFilterByOverride: developmentKeys.isLatestNonUniqueVersion,
    },
  ];
};
