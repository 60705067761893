import styled from '@emotion/styled';
import { Overlay } from 'components/Overlay';
import { Sidebar } from 'components/Sidebar';
import { Theme } from 'styles/themes';
import {
  CloseIconContainer,
  ModalInnerContainer,
  ModalTitle,
} from 'components/Modal';
import { ReactComponent as Close } from 'assets/close.svg';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { RecentlyViewedDevelopmentsList } from '../RecentlyViewedDevelopmentsList';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getAllRecentlyViewedDevelopmentsAsync,
  selectRecentlyViewedDevelopments,
  selectRecentlyViewedDevelopmentsPage,
} from 'store/recently-viewed-development/recently-viewed-development-slice';

export interface RecentlyViewedDevelopmentsModalProps {
  isDisplayed: boolean;
  setIsDisplayed: Dispatch<SetStateAction<boolean>>;
  currentDevelopmentId?: string;
}

const RecentlyViewedDevelopmentsModalContainer = styled.div({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  zIndex: 4,
  maxWidth: 'min-content',
  display: 'flex',
  justifyContent: 'end',
});

export const RecentlyViewedDevelopmentsModal = (
  props: RecentlyViewedDevelopmentsModalProps
) => {
  const dispatch = useAppDispatch();
  const developments = useAppSelector(selectRecentlyViewedDevelopments);
  const page = useAppSelector(selectRecentlyViewedDevelopmentsPage);

  useEffect(() => {
    dispatch(getAllRecentlyViewedDevelopmentsAsync());
  }, [page]);

  const closeModal = () => {
    props.setIsDisplayed(false);
  };

  return (
    <>
      {props.isDisplayed && (
        <>
          <Overlay
            id="RecentlyViewedDevelopmentsModalOverlay"
            onClick={() => closeModal()}
          />
          <RecentlyViewedDevelopmentsModalContainer id="RecentlyViewedDevelopmentsModalContainer">
            <Sidebar id="RecentlyViewedDevelopmentsModalSidebar">
              <ModalInnerContainer id="RecentlyViewedDevelopmentsModalInnerContainer">
                <ModalTitle
                  id="RecentlyViewedDevelopmentsModalTitle"
                  withoutMarginBottom={true}
                  color={Theme.colors.primaryColor}
                >
                  Recently viewed developments
                </ModalTitle>
                <CloseIconContainer id="RecentlyViewedDevelopmentsModalCloseIconContainer">
                  <Close
                    id="RecentlyViewedDevelopmentsModalCloseIcon"
                    onClick={() => closeModal()}
                  />
                </CloseIconContainer>
              </ModalInnerContainer>
              <RecentlyViewedDevelopmentsList
                developments={developments}
                onClick={() => closeModal()}
                currentDevelopmentId={props.currentDevelopmentId}
              />
            </Sidebar>
          </RecentlyViewedDevelopmentsModalContainer>
        </>
      )}
    </>
  );
};
