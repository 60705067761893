import { useMemo, useState } from 'react';
import { Header } from 'components/Header';
import { HomeSortDevelopmentsDropdown } from 'pages/home/components/HomeSortDevelopmentsDropdown';
import { Searchbar } from 'components/Searchbar';
import {
  selectDevelopmentsSearchProperties,
  selectDevelopmentsSearchTerm,
  selectDevelopmentsSortByDirection,
  selectDevelopmentsSortByProperty,
  selectDevelopmentsTotalRecords,
  setDevelopmentsSearchTerm,
} from 'store/development/development-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Theme } from 'styles/themes';
import { BoldText, VerticalDivider, FlexContainer } from 'styles/utils';
import { ReactComponent as RefreshIcon } from 'assets/refresh.svg';
import { developmentKeys } from 'config/development-keys';
import { selectActiveFilters } from 'store/home-sidebar-filter/home-sidebar-filter-slice';
import { ExportDevelopmentsButton } from 'components/ExportDevelopmentsButton';
import { SubHeaderButton } from 'components/SubHeaderButton';
import { BaseSocketService } from 'utils/socket/BaseSocketService';
import { AuthenticationResponse, Environment } from 'models';
import { getDevelopmentsQuery } from 'services/developments/developments-service';
import { RealTimeExportDevelopmentsEvent } from 'enums';

interface HomeSubHeaderProps {
  isFiltering: boolean;
  isAdvancedSearchEnabled?: boolean;
  onClickAdvancedSearchButton?: () => void;
  onClickRefreshButton?: () => void;
}

export const HomeSubHeader = (props: HomeSubHeaderProps) => {
  const dispatch = useAppDispatch();
  const searchTerm = useAppSelector(selectDevelopmentsSearchTerm);
  const searchProperties = useAppSelector(selectDevelopmentsSearchProperties);
  const totalRecords = useAppSelector(selectDevelopmentsTotalRecords);
  const activeFilters = useAppSelector(selectActiveFilters);
  const sortByProperty = useAppSelector(selectDevelopmentsSortByProperty);
  const sortByDirection = useAppSelector(selectDevelopmentsSortByDirection);

  const [isAdvancedSearchButtonHovered, setIsAdvancedSearchButtonHovered] =
    useState(false);
  const [isRefreshButtonHovered, setIsRefreshButtonHovered] = useState(false);

  const isSearchingOrFiltering = useMemo(() => {
    return !!searchTerm || props.isFiltering;
  }, [searchTerm, props.isFiltering]);

  const searchbarPlaceholder = useMemo(() => {
    const searchPropertyNames = searchProperties
      .map((searchProperty) => {
        switch (searchProperty) {
          case developmentKeys.title:
            return 'Title';
          case developmentKeys.id:
            return 'Dev. ID';
          case developmentKeys.pdfContent:
            return 'Development Text';
          case developmentKeys.documentCitation:
            return 'Document Citation';
          case `${developmentKeys.authorityAliasesAuthorities}.name`:
            return 'Issuing Authority';
          case `developmentRows.row.documentTypeName`:
            return 'Document Type';
          case `${developmentKeys.developmentCitations}.citationName`:
            return 'Citations';
          case `obligations.name`:
            return 'Obligations';
        }
      })
      .filter((x) => !!x);

    if (searchPropertyNames && searchPropertyNames.length) {
      return `Search by ${searchPropertyNames.slice(0, -1).join(', ')}${
        searchPropertyNames.at(-1)
          ? `${
              searchPropertyNames.length > 1 ? ' or ' : ''
            }${searchPropertyNames.at(-1)}`
          : ''
      }`;
    } else {
      return 'No search criteria selected';
    }
  }, [JSON.stringify(searchProperties)]);

  const handleExportDevelopments = (
    socketService: BaseSocketService,
    authenticationResponse: AuthenticationResponse,
    environment: Environment
  ) => {
    const query = getDevelopmentsQuery(
      environment,
      activeFilters,
      searchTerm,
      searchProperties
    );
    const queryParameters = {
      query: JSON.stringify(query),
      sortByProperty: sortByProperty,
      sortByDirection: sortByDirection,
    };
    const withTurnaroundTimeData = false;
    socketService.emit(
      RealTimeExportDevelopmentsEvent.ExportDevelopments,
      authenticationResponse.id,
      environment.elasticsearchIndexPrefix,
      queryParameters,
      withTurnaroundTimeData
    );
  };

  return (
    <Header id="HomeSubHeader" hasBorderLeft hasBorderRight padding="12px 15px">
      <div
        id="HomeSubHeaderSearchBarOuterContainer"
        style={{
          flexGrow: 1,
          marginRight: '12px',
        }}
      >
        <Searchbar
          id="HomeSubHeaderSearchbar"
          value={searchTerm}
          setValue={(value) => {
            dispatch(setDevelopmentsSearchTerm(value));
          }}
          placeholder={searchbarPlaceholder}
          title={searchbarPlaceholder}
        />
      </div>
      <span
        id="HomeSubHeaderAdvancedSearchButton"
        onMouseOver={() => setIsAdvancedSearchButtonHovered(true)}
        onMouseLeave={() => setIsAdvancedSearchButtonHovered(false)}
        onClick={props.onClickAdvancedSearchButton}
        style={{
          userSelect: 'none',
          marginRight: '12px',
          cursor: 'pointer',
          fontSize: Theme.fontSize - 2,
          color:
            props.isAdvancedSearchEnabled || isAdvancedSearchButtonHovered
              ? Theme.colors.blue
              : Theme.colors.secondaryColor,
        }}
      >
        Advanced Search
      </span>
      <SubHeaderButton
        id="HomeSubHeaderRefreshButton"
        title="Refresh developments"
        onMouseOver={() => setIsRefreshButtonHovered(true)}
        onMouseLeave={() => setIsRefreshButtonHovered(false)}
        isHighlighted={isRefreshButtonHovered}
        onClick={props.onClickRefreshButton}
      >
        <RefreshIcon id="HomeSubHeaderRefreshIcon" />
      </SubHeaderButton>
      <ExportDevelopmentsButton
        totalRecords={totalRecords}
        emitExportEvent={handleExportDevelopments}
      />
      <FlexContainer
        id="HomeSubHeaderResultsContainer"
        style={{ marginLeft: 'auto', alignItems: 'center' }}
      >
        <FlexContainer
          id="HomeSubHeaderResults"
          style={{ alignItems: 'center' }}
        >
          <span style={{ marginRight: '5px' }}>
            {isSearchingOrFiltering ? 'Results ' : 'Developments '}
          </span>
          <BoldText
            id="HomeSubHeaderTotalRecords"
            style={{ color: Theme.colors.secondaryColor }}
          >
            {totalRecords}
          </BoldText>
        </FlexContainer>
        <VerticalDivider height={'24px'} style={{ margin: '0 6px 0 12px' }} />
        <HomeSortDevelopmentsDropdown />
      </FlexContainer>
    </Header>
  );
};
