import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { getRegions } from 'services/regions/regions-service';
import { Region, PaginatedResponse } from 'models';
import { paginationConfig } from 'config/pagination-config';

export interface NewFeedFilterRegionState {
  regions: Region[];
  totalRecords: number;
  searchTerm: string;
  page: number;
  perPage: number;
}

const initialState: NewFeedFilterRegionState = {
  regions: [],
  totalRecords: 0,
  searchTerm: '',
  page: 1,
  perPage: paginationConfig.regionsPerPage,
};

export const getAllNewFeedFilterRegionsAsync = (): AppThunk<
  Promise<PaginatedResponse<Region> | null>
> => {
  return async (dispatch, getState) => {
    const searchTerm = getState().newFeedFilterRegion.searchTerm;
    const page = getState().newFeedFilterRegion.page;
    const perPage = getState().newFeedFilterRegion.perPage;
    const response = await getRegions(searchTerm, page, perPage);
    if (page > 1) {
      dispatch(addNewFeedFilterRegions(response.data));
    } else {
      dispatch(setNewFeedFilterRegions(response.data));
    }
    return response.data;
  };
};

export const newFeedFilterRegionSlice = createSlice({
  name: 'new-feed-filter-region',
  initialState,
  reducers: {
    setNewFeedFilterRegions: (
      state,
      action: PayloadAction<PaginatedResponse<Region> | null>
    ) => {
      state.regions = action.payload?.records ?? [];
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    addNewFeedFilterRegions: (
      state,
      action: PayloadAction<PaginatedResponse<Region> | null>
    ) => {
      state.regions = state.regions.concat(action.payload?.records ?? []);
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    setNewFeedFilterRegionsSearchTerm: (
      state,
      action: PayloadAction<string>
    ) => {
      state.page = 1;
      state.searchTerm = action.payload;
    },
    increaseNewFeedFilterRegionsPage: (state) => {
      state.page = state.page + 1;
    },
    resetNewFeedFilterRegionsPage: (state) => {
      state.page = 1;
    },
    resetNewFeedFilterRegionsSearchTerm: (state) => {
      state.searchTerm = '';
    },
  },
});

export const {
  setNewFeedFilterRegions,
  addNewFeedFilterRegions,
  setNewFeedFilterRegionsSearchTerm,
  increaseNewFeedFilterRegionsPage,
  resetNewFeedFilterRegionsPage,
  resetNewFeedFilterRegionsSearchTerm,
} = newFeedFilterRegionSlice.actions;

export const selectNewFeedFilterRegions = (state: RootState) =>
  state.newFeedFilterRegion.regions;
export const selectNewFeedFilterRegionsTotalRecords = (state: RootState) =>
  state.newFeedFilterRegion.totalRecords;
export const selectNewFeedFilterRegionsSearchTerm = (state: RootState) =>
  state.newFeedFilterRegion.searchTerm;
export const selectNewFeedFilterRegionsPage = (state: RootState) =>
  state.newFeedFilterRegion.page;
export const selectNewFeedFilterRegionsPerPage = (state: RootState) =>
  state.newFeedFilterRegion.perPage;

export default newFeedFilterRegionSlice.reducer;
