import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { localStorageKeys } from 'config/local-storage-keys';
import { Environment } from 'models';
import {
  getCurrentUserEnvironments,
  getDevelopmentEnvironment,
} from 'services/environments/environments-service';
import { AppThunk, RootState } from '../store';

export interface EnvironmentState {
  selectedEnvironment: Environment | null;
  currentUserEnvironments: Environment[];
}

const initialState: EnvironmentState = {
  selectedEnvironment:
    JSON.parse(
      atob(
        localStorage.getItem(localStorageKeys.selectedEnvironment) ||
          btoa('null')
      )
    ) || null,
  currentUserEnvironments: [],
};

export const getCurrentUserEnvironmentsAsync = (
  accessToken: string
): AppThunk<Promise<Environment[]>> => {
  return async (dispatch, getState) => {
    const response = await getCurrentUserEnvironments(accessToken);
    if (response.data && response.data.length) {
      dispatch(setCurrentUserEnvironments(response.data));
      return response.data;
    } else {
      return [];
    }
  };
};

export const getDevelopmentEnvironmentAsync = (
  developmentId: number,
  accessToken: string
): AppThunk<Promise<Environment | null>> => {
  return async (dispatch, getState) => {
    const response = await getDevelopmentEnvironment(
      developmentId,
      accessToken
    );
    if (response.data) {
      return response.data;
    } else {
      return null;
    }
  };
};

export const environmentSlice = createSlice({
  name: 'environment',
  initialState,
  reducers: {
    setSelectedEnvironment: (
      state,
      action: PayloadAction<Environment | null>
    ) => {
      const selectedEnvironment = action.payload;
      localStorage.setItem(
        localStorageKeys.selectedEnvironment,
        btoa(JSON.stringify(selectedEnvironment))
      );
      state.selectedEnvironment = selectedEnvironment;
    },
    setCurrentUserEnvironments: (
      state,
      action: PayloadAction<Environment[]>
    ) => {
      state.currentUserEnvironments = action.payload;
    },
  },
});

export const { setSelectedEnvironment, setCurrentUserEnvironments } =
  environmentSlice.actions;

export const selectSelectedEnvironment = (state: RootState) =>
  state.environment.selectedEnvironment;
export const selectCurrentUserEnvironments = (state: RootState) =>
  state.environment.currentUserEnvironments;

export default environmentSlice.reducer;
