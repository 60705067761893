import { httpGet, httpPost } from 'utils/http/base-http-service';
import { HttpResponse } from 'utils/http/HttpResponse';
import { PaginatedResponse } from 'models';
import { CountriesList, EnvironmentsList, Source } from 'models/reports/Source';
import { toQueryParams } from 'utils/to-query-params';
import { SortByDirection } from 'enums';

export const getSourceList = async (
  searchTerm: string,
  page: number,
  perPage: number,
  sortByProperty: string,
  sortByDirection: SortByDirection,
  environmentIds: string = '', // environmentIds is now a string
  countryIds: string = '',
  organizationId: number | undefined
): Promise<HttpResponse<PaginatedResponse<Source> | null>> => {
  const queryParameters = toQueryParams({
    searchTerm,
    environmentIds,
    countryIds,
    organizationId,
    page,
    perPage,
    sortByProperty: sortByProperty,
    sortByDirection: sortByDirection,
  });

  return httpGet<PaginatedResponse<Source>>(`source-list?${queryParameters}`);
};

export const getAllEnvironments = async (
  organizationId?: number | undefined
): Promise<HttpResponse<HttpResponse<EnvironmentsList> | null>> => {
  const queryParameters = toQueryParams({
    organizationId,
  });
  return httpGet<HttpResponse<EnvironmentsList>>(
    `environments/all-environments?${queryParameters}`
  );
};

export const getCountriesList = async (): Promise<
  HttpResponse<PaginatedResponse<CountriesList> | null>
> => {
  return httpGet<PaginatedResponse<CountriesList>>('countries/list');
};
