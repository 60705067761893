export interface PasswordValidity {
  hasLength: boolean;
  hasNumber: boolean;
  hasCasing: boolean;
  isValid: boolean;
}

export const validatePassword = (password: string): PasswordValidity => {
  const hasLength = /.{8,}/g.test(password);
  const hasNumber = /\d/.test(password);
  const hasCasing = /[a-z]/.test(password) && /[A-Z]/.test(password);
  return {
    hasLength,
    hasCasing,
    hasNumber,
    isValid: hasLength && hasNumber && hasCasing,
  };
};
