import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { OverflowYAutoContainer } from 'components/OverflowYAutoContainer';
import { HTMLAttributes, ReactElement, useEffect, useRef } from 'react';
import { Loading } from 'components/Loading';

interface SidebarProps extends HTMLAttributes<HTMLDivElement> {
  setHeight?: (height: number) => void;
  isLoading?: boolean;
  header?: ReactElement;
  headerHeight?: number;
  footer?: ReactElement;
  footerHeight?: number;
  scrollableContainerRef?: React.RefObject<HTMLDivElement>;
}

const OverflowXVisibleContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'visible',
  height: '100%',
  position: 'relative',
});

export const Sidebar: React.FC<SidebarProps> = (props: SidebarProps) => {
  const scrollableContainerRef =
    props.scrollableContainerRef || useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (props.setHeight && scrollableContainerRef.current?.clientHeight) {
      props.setHeight(scrollableContainerRef.current.clientHeight);
    }
  }, [scrollableContainerRef]);

  const overflowXVisibleContainerId = props.id
    ? `${props.id}OverflowXVisibleContainer`
    : undefined;

  return (
    <OverflowXVisibleContainer
      id={overflowXVisibleContainerId}
      style={{
        backgroundColor: props.style?.backgroundColor || Theme.colors.white,
        minWidth: props.style?.minWidth || '294px',
        flex: props.style?.flex || '0 0 18%',
      }}
    >
      {props.header}
      <OverflowYAutoContainer
        id={props.id}
        ref={scrollableContainerRef}
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: '0 0 100%',
        }}
        maxHeightModifier={
          -((props.headerHeight || 0) + (props.footerHeight || 0))
        }
      >
        {props.children}
      </OverflowYAutoContainer>
      {props.footer}
      {props.isLoading && <Loading />}
    </OverflowXVisibleContainer>
  );
};
