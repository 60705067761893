import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import {
  AddFeedsToTeamsPayload,
  Feed,
  PaginatedResponse,
  FeedsBulkActionPayload,
} from 'models';
import {
  addFeedsToTeams,
  getAllFeeds,
  deleteFeeds,
} from 'services/feeds/feeds-service';
import { paginationConfig } from 'config/pagination-config';
import { SortByDirection } from 'enums';

export interface ManagementFeedState {
  feeds: Feed[];
  totalRecords: number;
  page: number;
  perPage: number;
  searchTerm: string;
  areAllSelected: boolean;
  sortByProperty: string;
  sortByDirection: SortByDirection;
  selectedFeeds: { [key: number]: Feed };
  feedToEdit: Feed | null;
  triggerFeedsUpdate: number;
  selectedFeedsOutsideOfCurrentPage: Feed[];
}

const initialState: ManagementFeedState = {
  feeds: [],
  totalRecords: 0,
  page: 1,
  perPage: paginationConfig.managementFeedsPerPage,
  searchTerm: '',
  areAllSelected: false,
  sortByProperty: 'name.keyword',
  sortByDirection: SortByDirection.ASC,
  selectedFeeds: {},
  feedToEdit: null,
  triggerFeedsUpdate: 0,
  selectedFeedsOutsideOfCurrentPage: [],
};

export const getAllManagementFeedsAsync = (): AppThunk<
  Promise<PaginatedResponse<Feed> | null>
> => {
  return async (dispatch, getState) => {
    const feedState = getState().managementFeed;
    const page = feedState.page;
    const perPage = feedState.perPage;
    const searchTerm = feedState.searchTerm;
    const sortByProperty = feedState.sortByProperty;
    const sortByDirection = feedState.sortByDirection;
    const response = await getAllFeeds(
      searchTerm,
      page,
      perPage,
      sortByProperty,
      sortByDirection
    );
    dispatch(updateSelectedManagementFeeds(response?.data?.records || []));
    dispatch(setManagementFeeds(response.data));
    return response.data;
  };
};

export const deleteFeedsAsync = (): AppThunk<Promise<Feed[]>> => {
  return async (dispatch, getState) => {
    const feedState = getState().managementFeed;
    const payload: FeedsBulkActionPayload = {
      feedIds: Object.keys(feedState.selectedFeeds).map((x) => Number(x)),
      affectsAllFeeds: feedState.areAllSelected,
      searchTerm: feedState.searchTerm,
    };
    const response = await deleteFeeds(payload);
    dispatch(removeSelectedManagementFeeds());
    dispatch(setTriggerManagementFeedsUpdate());
    return response?.data || [];
  };
};

export const addFeedsToTeamsAsync = (
  neverAffectsAllFeeds: boolean,
  neverAffectsAllTeams: boolean,
  teamIds?: number[],
  feedIds?: number[]
): AppThunk<Promise<Feed[] | null>> => {
  return async (dispatch, getState) => {
    const feedState = getState().managementFeed;
    const teamState = getState().managementTeam;
    const addFeedsToTeamsPayload: AddFeedsToTeamsPayload = {
      feedIds: feedIds
        ? feedIds
        : Object.keys(feedState.selectedFeeds).map((x) => Number(x)),
      teamIds: teamIds
        ? teamIds
        : Object.keys(teamState.selectedTeams).map((x) => Number(x)),
      affectsAllFeeds: neverAffectsAllFeeds ? false : feedState.areAllSelected,
      affectsAllTeams: neverAffectsAllTeams
        ? false
        : teamState.areAllTeamsSelected,
      feedsSearchTerm: neverAffectsAllFeeds ? undefined : feedState.searchTerm,
      teamsSearchTerm: neverAffectsAllTeams ? undefined : teamState.searchTerm,
    };
    const response = await addFeedsToTeams(addFeedsToTeamsPayload);
    dispatch(updateManagementFeeds(response.data || []));
    dispatch(setTriggerManagementFeedsUpdate());
    return response.data;
  };
};

export const managementFeedSlice = createSlice({
  name: 'management-feed',
  initialState,
  reducers: {
    setManagementFeeds: (
      state,
      action: PayloadAction<PaginatedResponse<Feed> | null>
    ) => {
      state.feeds = action.payload?.records ?? [];
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    addManagementFeeds: (
      state,
      action: PayloadAction<PaginatedResponse<Feed> | null>
    ) => {
      state.feeds = state.feeds.concat(action.payload?.records ?? []);
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    increaseManagementFeedsPage: (state) => {
      state.page = state.page + 1;
    },
    setManagementFeedPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setManagementFeedsSearchTerm: (state, action: PayloadAction<string>) => {
      state.page = 1;
      state.searchTerm = action.payload;
      state.areAllSelected = false;
    },
    selectManagementFeed: (state, action: PayloadAction<Feed>) => {
      const feed = action.payload;
      const selectedFeedsLength = Object.keys(state.selectedFeeds).length + 1;

      if (state.selectedFeeds[feed.id || 0]) {
        delete state.selectedFeeds[feed.id || 0];
      } else {
        state.selectedFeeds[feed.id || 0] = feed;
        if (
          selectedFeedsLength === state.totalRecords &&
          state.areAllSelected
        ) {
          state.areAllSelected = false;
          state.selectedFeeds = {};
        }
      }
    },
    selectAllManagementFeeds: (state) => {
      if (!Object.keys(state.selectedFeeds).length && !state.areAllSelected) {
        state.areAllSelected = true;
      } else {
        state.selectedFeeds = {};
        state.areAllSelected = false;
      }
    },
    setManagementFeedSortByProperty: (state, action: PayloadAction<string>) => {
      state.sortByProperty = action.payload;
    },
    setManagementFeedSortByDirection: (
      state,
      action: PayloadAction<SortByDirection>
    ) => {
      state.sortByDirection = action.payload;
    },
    setManagementFeedToEdit: (state, action: PayloadAction<Feed | null>) => {
      state.feedToEdit = action.payload;
    },
    setTriggerManagementFeedsUpdate: (state) => {
      state.triggerFeedsUpdate = state.triggerFeedsUpdate + 1;
    },
    updateManagementFeeds: (state, action: PayloadAction<Feed[]>) => {
      const feeds = action.payload;
      feeds.forEach((feed) => {
        if (!!state.selectedFeeds[feed.id || 0]) {
          state.selectedFeeds[feed.id || 0] = feed;
        }
      });
    },
    updateSelectedManagementFeeds: (state, action: PayloadAction<Feed[]>) => {
      const feeds = action.payload;
      feeds.forEach((feed) => {
        if (!!state.selectedFeeds[feed.id || 0]) {
          state.selectedFeeds[feed.id || 0] = feed;
        }
      });
    },
    removeSelectedManagementFeeds: (state) => {
      if (state.areAllSelected) {
        state.feeds = Object.values(state.selectedFeeds).slice(
          0,
          state.perPage
        );
        state.areAllSelected = false;
      }
      state.selectedFeeds = {};
    },
    addSelectedManagementFeedsOutsideOfCurrentPage: (
      state,
      action: PayloadAction<Feed[]>
    ) => {
      const arrClone = [...state.selectedFeedsOutsideOfCurrentPage];
      const idsArr = arrClone.map((x) => x.id);
      action.payload.forEach((feed) => {
        if (!idsArr.includes(feed.id)) {
          arrClone.push(feed);
        }
      });
      state.selectedFeedsOutsideOfCurrentPage = arrClone;
    },
    removeSelectedManagementFeedsOutsideOfCurrentPage: (
      state,
      action: PayloadAction<Feed[]>
    ) => {
      const arrClone = [...state.selectedFeedsOutsideOfCurrentPage];
      const idsArr = arrClone.map((x) => x.id);
      action.payload.forEach((feed) => {
        const index = idsArr.indexOf(feed.id);
        if (index > -1) {
          arrClone.splice(index, 1);
        }
      });
      state.selectedFeedsOutsideOfCurrentPage = arrClone;
    },
  },
});

export const {
  setManagementFeeds,
  addManagementFeeds,
  increaseManagementFeedsPage,
  setManagementFeedPage,
  selectManagementFeed,
  selectAllManagementFeeds,
  setManagementFeedsSearchTerm,
  setManagementFeedSortByProperty,
  setManagementFeedSortByDirection,
  setManagementFeedToEdit,
  setTriggerManagementFeedsUpdate,
  updateManagementFeeds,
  updateSelectedManagementFeeds,
  removeSelectedManagementFeeds,
  addSelectedManagementFeedsOutsideOfCurrentPage,
  removeSelectedManagementFeedsOutsideOfCurrentPage,
} = managementFeedSlice.actions;

export const selectManagementFeeds = (state: RootState) =>
  state.managementFeed.feeds;
export const selectManagementFeedsTotalRecords = (state: RootState) =>
  state.managementFeed.totalRecords;
export const selectManagementFeedsPage = (state: RootState) =>
  state.managementFeed.page;
export const selectManagementFeedsPerPage = (state: RootState) =>
  state.managementFeed.perPage;
export const selectManagementFeedsSearchTerm = (state: RootState) =>
  state.managementFeed.searchTerm;
export const selectSelectedManagementFeeds = (state: RootState) =>
  state.managementFeed.selectedFeeds;
export const selectAreAllManagementFeedsSelected = (state: RootState) =>
  state.managementFeed.areAllSelected;
export const selectManagementFeedsSortByProperty = (state: RootState) =>
  state.managementFeed.sortByProperty;
export const selectManagementFeedsSortByDirection = (state: RootState) =>
  state.managementFeed.sortByDirection;
export const selectManagementFeedToEdit = (state: RootState) =>
  state.managementFeed.feedToEdit;
export const selectTriggerManagementFeedsUpdate = (state: RootState) =>
  state.managementFeed.triggerFeedsUpdate;
export const selectSelectedManagementFeedsOutsideOfCurrentPage = (
  state: RootState
) => state.managementFeed.selectedFeedsOutsideOfCurrentPage;

export default managementFeedSlice.reducer;
