import { Button } from 'components/Button';
import { PasswordInput } from 'components/Input';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorText, FlexContainer } from 'styles/utils';
import { ReactComponent as Warning } from 'assets/warning.svg';
import { useLocation } from 'react-router-dom';
import { setUserPassword } from 'services/auth/auth-service';
import { routes } from 'navigation/routes';
import { PasswordTooltip } from 'components/PasswordTooltip';
import { validatePassword } from 'helpers/password';
import { Theme } from 'styles/themes';
import { AuthContainer, LoginSubTitle } from 'pages/auth/components';

export const ChangePassword = () => {
  const search = useLocation().search;
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const token = new URLSearchParams(search).get('token');
  const navigate = useNavigate();
  const [confirmation, setConfirmation] = useState('');
  const [isPasswordSet, setIsPasswordSet] = useState(false);
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const isButtonDisabled = useMemo(() => {
    const passwordValidity = validatePassword(password || '');
    return !confirmation || !passwordValidity.isValid;
  }, [confirmation, password]);

  useEffect(() => {
    window.onkeydown = (e: any) => {
      if (e.key === 'Enter' && !isButtonDisabled) {
        handlePasswordChange();
      }
    };
  }, [isButtonDisabled, confirmation, password]);

  const handlePasswordChange = useCallback(() => {
    if (isPasswordSet) {
      navigate(routes.login);
      return;
    }
    if (password !== confirmation) {
      setErrorMessage('Passwords must match');
      return;
    }
    setUserPassword(password, token || '')
      .then((d) => {
        setIsPasswordSet(true);
        setErrorMessage('');
      })
      .catch(() => {
        setErrorMessage('There was a problem setting up the new password');
      });
  }, [setIsPasswordSet, password, token, confirmation, isPasswordSet]);

  return (
    <AuthContainer
      title={isPasswordSet ? 'Password changed!' : 'New password'}
      subtitle={
        <LoginSubTitle>
          {isPasswordSet
            ? 'Your password has been modified correctly, you can now login with your new password.'
            : 'Please create your new password'}
        </LoginSubTitle>
      }
      showPolicy={!isPasswordSet}
    >
      <>
        {!isPasswordSet && (
          <>
            <FlexContainer
              id="ChangePasswordPasswordContainer"
              style={{
                width: '100%',
                marginBottom: '15px',
                position: 'relative',
              }}
            >
              <label
                htmlFor="password"
                style={{ marginRight: 'auto', marginBottom: '5px' }}
              >
                Password
              </label>
              <PasswordInput
                id="password"
                placeholder="●●●●●●●●"
                value={password}
                setValue={setPassword}
                inputRef={passwordInputRef}
              />
              <PasswordTooltip relativeToElement={passwordInputRef} />
            </FlexContainer>
            <FlexContainer
              id="ChangePasswordRetypePasswordContainer"
              style={{ width: '100%', marginBottom: '15px' }}
            >
              <label
                htmlFor="retype-password"
                style={{ marginRight: 'auto', marginBottom: '5px' }}
              >
                Retype password
              </label>
              <PasswordInput
                id="retype-password"
                placeholder="●●●●●●●●"
                value={confirmation}
                setValue={setConfirmation}
              />
            </FlexContainer>
          </>
        )}
        <Button
          id="ChangePasswordSubmitButton"
          onClick={handlePasswordChange}
          isDisabled={isButtonDisabled}
        >
          {isPasswordSet ? 'Go to Log in' : 'Save your new password'}
        </Button>
        {errorMessage && errorMessage.length > 0 && (
          <FlexContainer
            id="ChangePasswordErrorContainer"
            style={{ marginTop: '10px' }}
          >
            <Warning
              id="ChangePasswordWarningIcon"
              style={{ margin: 'auto 5px auto 0', color: Theme.colors.red }}
            />
            <ErrorText
              id="ChangePasswordErrorText"
              style={{ margin: 'auto 0' }}
            >
              {errorMessage}
            </ErrorText>
          </FlexContainer>
        )}
      </>
    </AuthContainer>
  );
};
