import { ReactNode, useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Searchbar } from 'components/Searchbar';
import { Theme } from 'styles/themes';
import { ReportsLabels } from 'pages/reports/interfaces/ReportsLabels';
import { PerformanceReportEntities } from 'enums';
import { OverflowXContainer } from 'components/OverflowXContainer';
import { feedManagementLabels } from 'pages/management/feeds/labels';
import { CountriesList, EnvironmentsList, Source } from 'models/reports/Source';
import { SubHeaderButton } from 'components/SubHeaderButton';
import { ReactComponent as ExportIcon } from 'assets/export.svg';
import { FilterJurisdiction } from 'pages/management/index-time/components/FilterJurisdiction';
import { FilterDropdown } from 'pages/management/index-time/components/FilterDropdown';

interface ReportsSubHeaderSearchProps {
  searchTerm: string;
  onSearchChange: (value: string) => void;
}

interface ReportsSubHeaderPeriodProps {
  period: string;
  setPeriod: (period: string) => void;
  onSelectPeriod: () => void;
  onSelectCustomPeriod?: () => void;
}

interface ReportsSubHeaderReportByProps {
  reportBy: PerformanceReportEntities;
  setReportBy: (reportEntity: PerformanceReportEntities) => void;
}

interface ReportsSubHeaderProps extends ReportsLabels {
  children?: ReactNode;
  searchProps?: ReportsSubHeaderSearchProps;
  periodProps?: ReportsSubHeaderPeriodProps;
  reportByProps?: ReportsSubHeaderReportByProps;
  hasNoBorders?: boolean;
  totalDevelopments?: number;
  filterOptions: EnvironmentsList[];
  CoutriesFilterOptions: CountriesList[];
  selectedIds: (ids: string) => void;
  selectedCountriesIds: (ids: string) => void;
  onExportReports: () => void;
}

const SubHeaderContainer = styled.div((props: { hasNoBorders?: boolean }) => ({
  width: '100%',
  height: '56px',
  padding: '12px 16px',
  borderBottom: props.hasNoBorders
    ? 'none'
    : `solid 1px ${Theme.colors.grayBorder}`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const SubTitle = styled.span({
  fontSize: '18px',
  lineHeight: '25px',
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  marginRight: '8px',
});

export const ReportsIndexSubHeader = (props: ReportsSubHeaderProps) => {
  const [isSubTitleHovered, setIsSubTitleHovered] = useState(false);
  const overflowXElementWidth = document
    .querySelector('#ReportSubHeaderOverflowXContainer')
    ?.getBoundingClientRect().width;
  const overflowXElementScrollWidth = document.querySelector(
    '#ReportSubHeaderOverflowXContainer'
  )?.scrollWidth;

  const isOverflowX = useMemo(
    () =>
      Math.ceil(overflowXElementScrollWidth || 0) >
      Math.ceil(overflowXElementWidth || 0),
    [overflowXElementWidth, overflowXElementScrollWidth]
  );

  return (
    <SubHeaderContainer hasNoBorders={props.hasNoBorders}>
      <OverflowXContainer
        id="ReportSubHeaderOverflowXContainer"
        isScrollDisplayed={isSubTitleHovered && isOverflowX}
        onMouseEnter={() => setIsSubTitleHovered(true)}
        onMouseLeave={() => setIsSubTitleHovered(false)}
        style={{
          marginRight: 0,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {props.children}
        {!!props.searchProps && (
          <Searchbar
            value={props.searchProps.searchTerm}
            setValue={props.searchProps.onSearchChange}
            placeholder={props.searchBarLabel}
          />
        )}
      </OverflowXContainer>
      <SubHeaderButton
        id="DevelopmentsExportButton"
        style={{ marginLeft: 'auto' }}
        onClick={() => {
          props.onExportReports();
        }}
      >
        <ExportIcon id="DevelopmentsExportIcon" />
      </SubHeaderButton>
      <FilterJurisdiction
        id="FilterDropdown"
        title={feedManagementLabels.filterByJurisdiction || ''}
        options={props.CoutriesFilterOptions}
        selectedCountriesIds={props.selectedCountriesIds}
      />
      <FilterDropdown
        id="FilterDropdown"
        title={feedManagementLabels.filterByEnvironment || ''}
        options={props.filterOptions}
        selectedIds={props.selectedIds}
      />
    </SubHeaderContainer>
  );
};
