import styled from '@emotion/styled';
import {
  CloseIconContainer,
  Modal,
  ModalContentContainer,
  ModalInnerContainer,
  ModalTitle,
} from 'components/Modal';
import { Theme } from 'styles/themes';
import { ReactComponent as Close } from 'assets/close.svg';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { FlexContainer } from 'styles/utils';
import { Input } from 'components/Input';
import { SelectSearchbar } from 'components/SelectSearchbar';
import { getSelectSearchbarCountries } from 'services/countries/countries-service';
import { getAllTeams } from 'services/teams/teams-service';
import { CheckboxInput } from 'components/CheckboxInput';
import { Button, OutlinedButton, UnderlinedButton } from 'components/Button';
import { User } from 'models';
import { register } from 'services/users/users-service';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAuthenticationResponse } from 'store/authentication/authentication-slice';
import { InputLabel } from 'components/InputLabel';
import { ManagerTooltipContent } from 'components/ManagerTooltip/ManagerTooltip';
import { setTriggerManagementUsersUpdate } from 'store/management-user/management-user-slice';
import { paginationConfig } from 'config/pagination-config';
import { HttpResponse } from 'utils/http/HttpResponse';
import { validateTextInput, validateEmailInput } from 'utils/input-validation';

interface CreateUserModalProps {
  isDisplayed: boolean;
  setIsDisplayed: Dispatch<SetStateAction<boolean>>;
}

const CreateUserModalContainer = styled.div({
  display: 'flex',
  width: '624px',
});

export const CreateUserModal: React.FC<CreateUserModalProps> = (
  props: CreateUserModalProps
) => {
  const dispatch = useAppDispatch();
  const authenticationResponse = useAppSelector(selectAuthenticationResponse);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [countryIds, setCountryIds] = useState<number[]>([]);
  const [city, setCity] = useState('');
  const [teamIds, setTeamIds] = useState<number[]>([]);
  const [isManager, setIsManager] = useState(false);
  const [invalidFirstNameError, setInvalidFirstNameError] = useState('');
  const [invalidLastNameError, setInvalidLastNameError] = useState('');
  const [invalidCityNameError, setInvalidCityNameError] = useState('');
  const [invalidEmailError, setInvalidEmailError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const areFieldsCompleted = useMemo(() => {
    return (
      !!firstName &&
      !!lastName &&
      !!email &&
      !!countryIds &&
      !!countryIds.length &&
      !!city
    );
  }, [firstName, lastName, email, countryIds, city]);

  const isAnyFieldWithError = useMemo(() => {
    return (
      !!invalidFirstNameError ||
      !!invalidLastNameError ||
      !!invalidCityNameError ||
      !!invalidEmailError
    );
  }, [
    invalidFirstNameError,
    invalidLastNameError,
    invalidCityNameError,
    invalidEmailError,
  ]);

  useEffect(() => {
    validateTextInput(firstName, setInvalidFirstNameError);
  }, [firstName]);

  useEffect(() => {
    validateTextInput(lastName, setInvalidLastNameError);
  }, [lastName]);

  useEffect(() => {
    validateTextInput(city, setInvalidCityNameError);
  }, [city]);

  useEffect(() => {
    validateEmailInput(email, setInvalidEmailError);
  }, [email]);

  const clearFields = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setCountryIds([]);
    setCity('');
    setTeamIds([]);
    setIsManager(false);
    setIsLoading(false);
  };

  const closeModal = () => {
    props.setIsDisplayed(false);
    clearFields();
  };

  const buildUserPayload = (): User | null => {
    if (!authenticationResponse) {
      return null;
    }
    const user: User = {
      email,
      firstName,
      lastName,
      countryId: countryIds[0],
      city,
      teamIds,
      isManager,
      organizationId: authenticationResponse.organizationId,
    };
    return user;
  };

  const inviteAndCreateAnother = async () => {
    setIsLoading(true);
    const user = buildUserPayload();
    if (user) {
      try {
        await register(user);
        dispatch(setTriggerManagementUsersUpdate());
        clearFields();
      } catch (error) {
        const httpResponse = error as HttpResponse<null>;
        if (httpResponse.status === 409 && httpResponse.error) {
          setInvalidEmailError(httpResponse.error.message);
        }
        setIsLoading(false);
      }
    }
  };

  const confirmAndInvite = async () => {
    setIsLoading(true);
    const user = buildUserPayload();
    if (user) {
      try {
        await register(user);
        dispatch(setTriggerManagementUsersUpdate());
        closeModal();
      } catch (error) {
        const httpResponse = error as HttpResponse<null>;
        if (
          (httpResponse.status === 409 || httpResponse.status === 500) &&
          httpResponse.error
        ) {
          setInvalidEmailError(httpResponse.error.message);
        }
        setIsLoading(false);
      }
    }
  };

  return (
    <Modal
      id="CreateUserModal"
      isDisplayed={props.isDisplayed}
      isLoading={isLoading}
    >
      <CreateUserModalContainer id="CreateUserModalContainer">
        <ModalContentContainer id="CreateUserModalContentContainer">
          <ModalInnerContainer id="CreateUserModalHeader">
            <ModalTitle
              id="CreateUserModalTitle"
              withoutMarginBottom={true}
              color={Theme.colors.primaryColor}
            >
              Create new user
            </ModalTitle>
            <CloseIconContainer id="CreateUserModalCloseIconContainer">
              <Close
                id="CreateUserModalCloseButton"
                onClick={() => closeModal()}
              />
            </CloseIconContainer>
          </ModalInnerContainer>
          <ModalInnerContainer id="CreateUserModalBody">
            <FlexContainer
              id="CreateUserModalFormContainer"
              flexDirection="column"
              style={{ width: '100%' }}
            >
              <FlexContainer
                id="CreateUserModalInputGroupContainer1"
                style={{ width: '100%', marginBottom: '24px' }}
              >
                <div
                  id="CreateUserModalNameInputContainer"
                  style={{ width: 'calc(50% - 9px)', marginRight: '18px' }}
                >
                  <InputLabel htmlFor="id-first-name" isFieldRequired>
                    Name
                  </InputLabel>
                  <Input
                    id="id-first-name"
                    placeholder="Enter name..."
                    value={firstName}
                    setValue={setFirstName}
                    maxLength={255}
                    error={invalidFirstNameError}
                  />
                </div>
                <div
                  id="CreateUserModalLastNameInputContainer"
                  style={{ width: 'calc(50% - 9px)' }}
                >
                  <InputLabel htmlFor="id-last-name" isFieldRequired>
                    Last name
                  </InputLabel>
                  <Input
                    id="id-last-name"
                    placeholder="Enter last name..."
                    value={lastName}
                    setValue={setLastName}
                    maxLength={255}
                    error={invalidLastNameError}
                  />
                </div>
              </FlexContainer>
              <FlexContainer
                id="CreateUserModalInputGroupContainer2"
                style={{ width: '100%', marginBottom: '24px' }}
              >
                <div
                  id="CreateUserModalEmailInputContainer"
                  style={{ width: '100%' }}
                >
                  <InputLabel htmlFor="id-email" isFieldRequired>
                    Email
                  </InputLabel>
                  <Input
                    id="id-email"
                    placeholder="user@account.com"
                    value={email}
                    setValue={setEmail}
                    error={invalidEmailError}
                  />
                </div>
              </FlexContainer>
              <FlexContainer
                id="CreateUserModalInputGroupContainer3"
                style={{ width: '100%', marginBottom: '24px' }}
              >
                <div
                  id="CreateUserModalCountryInputContainer"
                  style={{ width: 'calc(50% - 9px)', marginRight: '18px' }}
                >
                  <InputLabel htmlFor="id-country" isFieldRequired>
                    Jurisdiction
                  </InputLabel>
                  <SelectSearchbar
                    id="id-country"
                    getAllRecords={getSelectSearchbarCountries}
                    placeholder="Select jurisdiction..."
                    recordsPropertyToDisplay="name"
                    recordsPropertyToSortBy="name.keyword"
                    values={countryIds}
                    setValues={setCountryIds}
                    perPage={paginationConfig.countriesDropdownPerPage}
                  />
                </div>
                <div
                  id="CreateUserModalCityInputContainer"
                  style={{ width: 'calc(50% - 9px)' }}
                >
                  <InputLabel htmlFor="id-city" isFieldRequired>
                    City
                  </InputLabel>
                  <Input
                    id="id-city"
                    placeholder="Enter city..."
                    value={city}
                    setValue={setCity}
                    maxLength={255}
                    error={invalidCityNameError}
                  />
                </div>
              </FlexContainer>
              <FlexContainer
                id="CreateUserModalInputGroupContainer4"
                style={{ width: '100%' }}
              >
                <div
                  id="CreateUserModalAddToTeamInputContainer"
                  style={{ width: 'calc(50% - 9px)', marginRight: '18px' }}
                >
                  <InputLabel htmlFor="id-teams">Add to team</InputLabel>
                  <SelectSearchbar
                    id="id-teams"
                    getAllRecords={getAllTeams}
                    placeholder="Select teams..."
                    recordsPropertyToDisplay="name"
                    recordsPropertyToSortBy="name.keyword"
                    values={teamIds}
                    setValues={setTeamIds}
                    perPage={paginationConfig.teamsDropdownPerPage}
                    isMultiselect
                    characterWidthFactor={4}
                  />
                </div>
                <div
                  id="CreateUserModalAssignAsManagerInputContainer"
                  style={{
                    width: 'calc(50% - 9px)',
                    display: 'flex',
                    alignItems: 'flex-end',
                  }}
                >
                  <CheckboxInput
                    id="CreateUserModalAssignAsManagerCheckboxInput"
                    label="Assign as manager"
                    isSelected={isManager}
                    setIsSelected={setIsManager}
                    hasTooltip
                    tooltipContent={<ManagerTooltipContent />}
                  />
                </div>
              </FlexContainer>
            </FlexContainer>
          </ModalInnerContainer>
          <ModalInnerContainer
            id="CreateUserModalFooter"
            withoutBorderBottom={true}
            padding="12px 16px"
          >
            <FlexContainer id="CreateUserModalCancelButtonContainer">
              <UnderlinedButton
                id="CreateUserModalCancelButton"
                color={Theme.colors.secondaryColor}
                onClick={() => closeModal()}
              >
                Cancel
              </UnderlinedButton>
            </FlexContainer>
            <FlexContainer
              id="CreateUserModalButtonsContainer"
              style={{ marginLeft: 'auto' }}
            >
              <OutlinedButton
                id="CreateUserModalSendAndInviteAnotherButton"
                color={Theme.colors.secondaryColor}
                style={{ marginRight: '10px' }}
                isDisabled={!areFieldsCompleted || isAnyFieldWithError}
                onClick={() => inviteAndCreateAnother()}
              >
                Send & Invite another
              </OutlinedButton>
              <Button
                id="CreateUserModalSendInviteButton"
                isDisabled={!areFieldsCompleted || isAnyFieldWithError}
                onClick={() => confirmAndInvite()}
              >
                Send invite
              </Button>
            </FlexContainer>
          </ModalInnerContainer>
        </ModalContentContainer>
      </CreateUserModalContainer>
    </Modal>
  );
};
