import styled from '@emotion/styled';
import { ReactComponent as ArrowIcon } from 'assets/arrow-down.svg';
import { ReactComponent as DoubleArrowIcon } from 'assets/double-arrow.svg';
import { Button } from 'components/Button';
import { Body } from 'components/Typography';
import { Input } from 'components/Input';
import { useCallback, useEffect, useState } from 'react';
import { Theme } from 'styles/themes';
import { ButtonProps } from 'components/Button';
import _ from 'lodash';
import { HTMLAttrID } from 'models/common/HTMLAttrID';

interface PaginationProps extends HTMLAttrID {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  justifyContent?: string;
}

const PaginationContainer = styled.div(
  (props: { justifyContent?: string }) => ({
    display: 'flex',
    justifyContent: props.justifyContent || 'flex-end',
    alignItems: 'center',
    width: '100%',
    gap: 6,
  })
);

const NavigationIcon = styled(ArrowIcon)<{
  direction: 'left' | 'right';
}>((props) => ({
  transform: props.direction === 'left' ? 'rotate(90deg)' : 'rotate(-90deg)',
}));

const DoubleNavigationIcon = styled(DoubleArrowIcon)<{
  direction: 'left' | 'right';
}>((props) => ({
  transform: props.direction === 'left' ? 'initial' : 'rotate(180deg)',
}));

const InputContainer = styled.div({
  width: 32,
});

const PaginationButton = (props: ButtonProps) => (
  <Button
    {...props}
    style={{
      width: 32,
      backgroundColor: Theme.colors.white,
      color: Theme.colors.secondaryColor,
      padding: 0,
      minWidth: 32,
    }}
  />
);

export const Pagination = (props: PaginationProps) => {
  const { currentPage, totalPages, onPageChange } = props;
  const [inputValue, setInputValue] = useState(`${currentPage}`);

  useEffect(() => {
    setInputValue(`${props.currentPage}`);
  }, [props.currentPage]);

  const handleInputChange = useCallback((value: string) => {
    setInputValue(value);
    debouncedPageChange(+value);
  }, []);

  const debouncedPageChange = useCallback(_.debounce(onPageChange, 300), []);

  const handleNextPageClick = useCallback(() => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  }, [currentPage, totalPages, onPageChange]);

  const handlePreviousPageClick = useCallback(() => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  }, [currentPage, totalPages, onPageChange]);

  const handleGoToFirstClick = useCallback(() => {
    onPageChange(1);
  }, [onPageChange]);

  const handleGoToLastClick = useCallback(() => {
    onPageChange(totalPages);
  }, [onPageChange, totalPages]);

  return (
    <PaginationContainer id={props.id} justifyContent={props.justifyContent}>
      <PaginationButton
        id={`${props.id}LeftDoubleNavigationButton`}
        isDisabled={currentPage === 1}
      >
        <DoubleNavigationIcon
          id={`${props.id}LeftDoubleNavigationIcon`}
          direction="left"
          onClick={handleGoToFirstClick}
        />
      </PaginationButton>
      <PaginationButton
        id={`${props.id}LeftNavigationButton`}
        isDisabled={currentPage === 1}
      >
        <NavigationIcon
          id={`${props.id}LeftNavigationIcon`}
          direction="left"
          onClick={handlePreviousPageClick}
        />
      </PaginationButton>
      <Body id={`${props.id}PageLabel`} noMargin>
        Page{' '}
      </Body>
      <InputContainer id={`${props.id}CurrentPageInputContainer`}>
        <Input
          id={`${props.id}CurrentPageInput`}
          placeholder={`${currentPage}`}
          setValue={handleInputChange}
          value={inputValue}
          style={{ textAlign: 'center' }}
        />
      </InputContainer>
      <Body id={`${props.id}OfLabel`} noMargin>
        {' '}
        of{' '}
      </Body>
      <Body id={`${props.id}TotalPages`} noMargin bold>
        {' '}
        {totalPages}
      </Body>
      <PaginationButton
        id={`${props.id}RightNavigationButton`}
        isDisabled={currentPage === totalPages}
      >
        <NavigationIcon
          id={`${props.id}RightNavigationIcon`}
          direction="right"
          onClick={handleNextPageClick}
        />
      </PaginationButton>
      <PaginationButton
        id={`${props.id}RightDoubleNavigationButton`}
        isDisabled={currentPage === totalPages}
      >
        <DoubleNavigationIcon
          id={`${props.id}RightDoubleNavigationIcon`}
          direction="right"
          onClick={handleGoToLastClick}
        />
      </PaginationButton>
    </PaginationContainer>
  );
};
