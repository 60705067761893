import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FilterSet } from 'components/FilterSet';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Sidebar } from 'components/Sidebar';
import {
  resetDevelopmentsPage,
  selectDevelopmentsSearchProperties,
  selectDevelopmentsSearchTerm,
  setTriggerFeedUpdate,
} from 'store/development/development-slice';
import {
  clearActiveFilters,
  getFilterCountersAsync,
  selectActiveFilters,
  selectFeedActiveFilters,
  selectFilterCounters,
  updateActiveFilters,
  setFeedActiveFilters,
  selectIsAuthoritiesFilterCollapsed,
  selectIsSubcategoriesFilterCollapsed,
  selectIsLanguagesFilterCollapsed,
  selectIsRegionsFilterCollapsed,
  selectIsCountriesFilterCollapsed,
  selectIsDocumentTypesFilterCollapsed,
  setIsAuthoritiesFilterCollapsed,
  setIsSubcategoriesFilterCollapsed,
  setIsLanguagesFilterCollapsed,
  setIsRegionsFilterCollapsed,
  setIsCountriesFilterCollapsed,
  setIsDocumentTypesFilterCollapsed,
  selectIsStatusFilterCollapsed,
  selectIsDateFilterCollapsed,
  setIsStatusFilterCollapsed,
  setIsDateFilterCollapsed,
  setActiveFilters,
  selectIsPageCountFilterCollapsed,
  setIsPageCountFilterCollapsed,
  setIsTurnaroundTimeFilterCollapsed,
  selectIsTurnaroundTimeFilterCollapsed,
  selectTriggerGetAllFilterSetEntries,
  selectIsNonConformityFilterCollapsed,
  setIsNonConformityFilterCollapsed,
  selectSkipSetAllActiveFiltersOnce,
} from 'store/home-sidebar-filter/home-sidebar-filter-slice';
import { getStatusFilters } from 'helpers/review-status';
import { selectAuthenticationResponse } from 'store/authentication/authentication-slice';
import { BoldText, FlexContainer } from 'styles/utils';
import { RecentlyViewedDevelopmentsDropdown } from 'components/RecentlyViewedDevelopments/RecentlyViewedDevelopmentsDropdown';
import { DatePickerModal } from 'components/DatePicker';
import {
  dateRangeToString,
  DateRange,
  ignoreDateRangeTime,
  dateRangeInMillisecondsStringToDateRange,
} from 'utils/date';
import { DateChip } from 'components/DateChip/DateChip';
import styled from '@emotion/styled';
import { TextButton, UnderlinedButton } from 'components/Button';
import { developmentKeys } from 'config/development-keys';
import {
  getAllHomeSidebarFilterLanguagesAsync,
  increaseHomeSidebarFilterLanguagesPage,
  resetHomeSidebarFilterLanguagesPage,
  selectHomeSidebarFilterLanguages,
  selectHomeSidebarFilterLanguagesPage,
  selectHomeSidebarFilterLanguagesPerPage,
  selectHomeSidebarFilterLanguagesSearchTerm,
  selectHomeSidebarFilterLanguagesTotalRecords,
  setHomeSidebarFilterLanguagesSearchTerm,
} from 'store/home-sidebar-filter/home-sidebar-filter-language-slice';
import {
  getAllHomeSidebarFilterAuthoritiesAsync,
  increaseHomeSidebarFilterAuthoritiesPage,
  resetHomeSidebarFilterAuthoritiesPage,
  selectHomeSidebarFilterAuthorities,
  selectHomeSidebarFilterAuthoritiesPage,
  selectHomeSidebarFilterAuthoritiesPerPage,
  selectHomeSidebarFilterAuthoritiesSearchTerm,
  selectHomeSidebarFilterAuthoritiesTotalRecords,
  setHomeSidebarFilterAuthoritiesSearchTerm,
} from 'store/home-sidebar-filter/home-sidebar-filter-authority-slice';
import {
  getAllHomeSidebarFilterSubcategoriesAsync,
  increaseHomeSidebarFilterSubcategoriesPage,
  resetHomeSidebarFilterSubcategoriesPage,
  selectHomeSidebarFilterSubcategories,
  selectHomeSidebarFilterSubcategoriesPage,
  selectHomeSidebarFilterSubcategoriesPerPage,
  selectHomeSidebarFilterSubcategoriesSearchTerm,
  selectHomeSidebarFilterSubcategoriesTotalRecords,
  setHomeSidebarFilterSubcategoriesSearchTerm,
} from 'store/home-sidebar-filter/home-sidebar-filter-subcategory-slice';
import {
  getAllHomeSidebarFilterDocumentTypesAsync,
  increaseHomeSidebarFilterDocumentTypesPage,
  resetHomeSidebarFilterDocumentTypesPage,
  selectHomeSidebarFilterDocumentTypes,
  selectHomeSidebarFilterDocumentTypesPage,
  selectHomeSidebarFilterDocumentTypesPerPage,
  selectHomeSidebarFilterDocumentTypesSearchTerm,
  selectHomeSidebarFilterDocumentTypesTotalRecords,
  setHomeSidebarFilterDocumentTypesSearchTerm,
} from 'store/home-sidebar-filter/home-sidebar-filter-document-type-slice';
import {
  getAllHomeSidebarFilterRegionsAsync,
  selectHomeSidebarFilterRegions,
  selectHomeSidebarFilterRegionsPage,
  selectHomeSidebarFilterRegionsPerPage,
  selectHomeSidebarFilterRegionsSearchTerm,
  selectHomeSidebarFilterRegionsTotalRecords,
  setHomeSidebarFilterRegionsSearchTerm,
  increaseHomeSidebarFilterRegionsPage,
  resetHomeSidebarFilterRegionsPage,
} from 'store/home-sidebar-filter/home-sidebar-filter-region-slice';
import {
  getAllHomeSidebarFilterCountriesAsync,
  selectHomeSidebarFilterCountries,
  selectHomeSidebarFilterCountriesPage,
  selectHomeSidebarFilterCountriesPerPage,
  selectHomeSidebarFilterCountriesSearchTerm,
  selectHomeSidebarFilterCountriesTotalRecords,
  setHomeSidebarFilterCountriesSearchTerm,
  increaseHomeSidebarFilterCountriesPage,
  resetHomeSidebarFilterCountriesPage,
} from 'store/home-sidebar-filter/home-sidebar-filter-country-slice';
import {
  selectFeedPickerAppliedFeed,
  selectFeedPickerLastViewedFeed,
} from 'store/feed/feed-picker-slice';
import { ReactComponent as Save } from 'assets/save.svg';
import { Theme } from 'styles/themes';
import { doBothArraysHaveTheSameElements } from 'utils/array';
import {
  ActiveFilters,
  AllFilterEntries,
  FilterEntry,
  FilterEntryValue,
} from 'models';
import {
  setIsNewFeedFilterAuthoritiesCollapsed,
  setIsNewFeedFilterCountriesCollapsed,
  setIsNewFeedFilterDocumentTypesCollapsed,
  setIsNewFeedFilterLanguagesCollapsed,
  setIsNewFeedFilterRegionsCollapsed,
  setNewFeedActiveFilters,
  setIsNewFeedFilterSubcategoriesCollapsed,
} from 'store/new-feed-filter/new-feed-filter-slice';
import { selectScrollPosition } from 'store/scroll-sync/scroll-sync-slice';
import { filterKeys } from 'config/filter-keys';
import { FilterResetWarningModal } from 'components/FilterResetWarningModal';
import { getPageCountFilters } from 'helpers/page-count';
import { getTurnaroundTimeFilters } from 'helpers/turnaround-time';
import { getNonConformityFilters } from 'helpers/non-conformity';

interface HomeSidebarProps {
  isFiltering: boolean;
  setIsCreateFeedModalDisplayed: React.Dispatch<boolean>;
  setCreateFeedModalSelectsFeedAfterUpsert: React.Dispatch<boolean>;
}

const TitleContainer = styled(FlexContainer)({
  alignItems: 'center',
  flex: '0 0 56px',
  width: '100%',
  justifyContent: 'space-between',
});

const SaveAsFeedButtonContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding: '16px',
  marginTop: 'auto',
  borderTop: `1px solid ${Theme.colors.grayBorder}`,
  cursor: 'pointer',
});

export const HomeSidebar = (props: HomeSidebarProps) => {
  const recentlyViewedDevelopmentsDropdownContainerHeight = 56;
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [isLoadingLanguages, setIsLoadingLanguages] = useState(false);
  const [isLoadingRegions, setIsLoadingRegions] = useState(false);
  const [isLoadingCountries, setIsLoadingCountries] = useState(false);
  const [isLoadingAuthorities, setIsLoadingAuthorities] = useState(false);
  const [isLoadingSubcategories, setIsLoadingSubcategories] = useState(false);
  const [isLoadingDocumentTypes, setIsLoadingDocumentTypes] = useState(false);
  const [isLoadingPageCount, setIsLoadingPageCount] = useState(false);
  const [isLoadingTurnaroundTime, setIsLoadingTurnaroundTime] = useState(false);
  const [isLoadingNonConformity, setIsLoadingNonConformity] = useState(false);
  const [saveAsFeedButtonContainerHeight, setSaveAsFeedButtonContainerHeight] =
    useState<number>(0);
  const [previousActiveFilters, setPreviousActiveFilters] =
    useState<ActiveFilters>({});
  const [currentDateProperty, setCurrentDateProperty] = useState<string | null>(
    null
  );
  const [previousDateProperty, setPreviousDateProperty] = useState<
    string | null
  >(null);
  const [scrollableContainerHeight, setScrollableContainerHeight] = useState(0);
  const [
    isFilterResetWarningModalDisplayed,
    setIsFilterResetWarningModalDisplayed,
  ] = useState(false);
  const [isDateRangeSelected, setIsDateRangeSelected] = useState(false);
  const dispatch = useAppDispatch();
  const triggerGetAllFilterSetEntries = useAppSelector(
    selectTriggerGetAllFilterSetEntries
  );
  const searchTerm = useAppSelector(selectDevelopmentsSearchTerm);
  const searchProperties = useAppSelector(selectDevelopmentsSearchProperties);
  const activeFilters = useAppSelector(selectActiveFilters);
  const isSkipSetAllActiveFiltersOnce = useAppSelector(
    selectSkipSetAllActiveFiltersOnce
  );
  const filterCounters = useAppSelector(selectFilterCounters);
  const feedActiveFilters = useAppSelector(selectFeedActiveFilters);
  const appliedFeed = useAppSelector(selectFeedPickerAppliedFeed);
  const lastViewedFeed = useAppSelector(selectFeedPickerLastViewedFeed);
  const authenticationResponse = useAppSelector(selectAuthenticationResponse);
  const scrollPosition = useAppSelector(selectScrollPosition);
  const isStatusFilterCollapsed = useAppSelector(selectIsStatusFilterCollapsed);
  const isAuthoritiesFilterCollapsed = useAppSelector(
    selectIsAuthoritiesFilterCollapsed
  );
  const isSubcategoriesFilterCollapsed = useAppSelector(
    selectIsSubcategoriesFilterCollapsed
  );
  const isLanguagesFilterCollapsed = useAppSelector(
    selectIsLanguagesFilterCollapsed
  );
  const isRegionsFilterCollapsed = useAppSelector(
    selectIsRegionsFilterCollapsed
  );
  const isCountriesFilterCollapsed = useAppSelector(
    selectIsCountriesFilterCollapsed
  );
  const isDocumentTypesFilterCollapsed = useAppSelector(
    selectIsDocumentTypesFilterCollapsed
  );
  const isDateFilterCollapsed = useAppSelector(selectIsDateFilterCollapsed);
  const isPageCountFilterCollapsed = useAppSelector(
    selectIsPageCountFilterCollapsed
  );
  const isTurnaroundTimeFilterCollapsed = useAppSelector(
    selectIsTurnaroundTimeFilterCollapsed
  );
  const isNonConformityFilterCollapsed = useAppSelector(
    selectIsNonConformityFilterCollapsed
  );

  // Filter Languages
  const filterLanguages = useAppSelector(selectHomeSidebarFilterLanguages);
  const filterLanguagesTotalRecords = useAppSelector(
    selectHomeSidebarFilterLanguagesTotalRecords
  );
  const filterLanguagesSearchTerm = useAppSelector(
    selectHomeSidebarFilterLanguagesSearchTerm
  );
  const filterLanguagesPage = useAppSelector(
    selectHomeSidebarFilterLanguagesPage
  );
  const filterLanguagesPerPage = useAppSelector(
    selectHomeSidebarFilterLanguagesPerPage
  );
  //

  // Filter Regions
  const filterRegions = useAppSelector(selectHomeSidebarFilterRegions);
  const filterRegionsTotalRecords = useAppSelector(
    selectHomeSidebarFilterRegionsTotalRecords
  );
  const filterRegionsSearchTerm = useAppSelector(
    selectHomeSidebarFilterRegionsSearchTerm
  );
  const filterRegionsPage = useAppSelector(selectHomeSidebarFilterRegionsPage);
  const filterRegionsPerPage = useAppSelector(
    selectHomeSidebarFilterRegionsPerPage
  );
  //

  // Filter Countries
  const filterCountries = useAppSelector(selectHomeSidebarFilterCountries);
  const filterCountriesTotalRecords = useAppSelector(
    selectHomeSidebarFilterCountriesTotalRecords
  );
  const filterCountriesSearchTerm = useAppSelector(
    selectHomeSidebarFilterCountriesSearchTerm
  );
  const filterCountriesPage = useAppSelector(
    selectHomeSidebarFilterCountriesPage
  );
  const filterCountriesPerPage = useAppSelector(
    selectHomeSidebarFilterCountriesPerPage
  );
  //

  // Filter Authorities
  const filterAuthorities = useAppSelector(selectHomeSidebarFilterAuthorities);
  const filterAuthoritiesTotalRecords = useAppSelector(
    selectHomeSidebarFilterAuthoritiesTotalRecords
  );
  const filterAuthoritiesSearchTerm = useAppSelector(
    selectHomeSidebarFilterAuthoritiesSearchTerm
  );
  const filterAuthoritiesPage = useAppSelector(
    selectHomeSidebarFilterAuthoritiesPage
  );
  const filterAuthoritiesPerPage = useAppSelector(
    selectHomeSidebarFilterAuthoritiesPerPage
  );
  //

  // Filter Subcategories
  const filterSubcategories = useAppSelector(
    selectHomeSidebarFilterSubcategories
  );
  const filterSubcategoriesTotalRecords = useAppSelector(
    selectHomeSidebarFilterSubcategoriesTotalRecords
  );
  const filterSubcategoriesSearchTerm = useAppSelector(
    selectHomeSidebarFilterSubcategoriesSearchTerm
  );
  const filterSubcategoriesPage = useAppSelector(
    selectHomeSidebarFilterSubcategoriesPage
  );
  const filterSubcategoriesPerPage = useAppSelector(
    selectHomeSidebarFilterSubcategoriesPerPage
  );
  //

  // Filter Document Types
  const filterDocumentTypes = useAppSelector(
    selectHomeSidebarFilterDocumentTypes
  );
  const filterDocumentTypesTotalRecords = useAppSelector(
    selectHomeSidebarFilterDocumentTypesTotalRecords
  );
  const filterDocumentTypesSearchTerm = useAppSelector(
    selectHomeSidebarFilterDocumentTypesSearchTerm
  );
  const filterDocumentTypesPage = useAppSelector(
    selectHomeSidebarFilterDocumentTypesPage
  );
  const filterDocumentTypesPerPage = useAppSelector(
    selectHomeSidebarFilterDocumentTypesPerPage
  );
  //

  const languageEntries = useMemo(() => {
    return filterLanguages.map((language) => {
      const filterEntry: FilterEntry = {
        value: language.id,
        name: language.name,
        developmentsCountFiltered: language.developmentsCountFiltered,
      };
      return filterEntry;
    });
  }, [JSON.stringify(filterLanguages)]);

  const regionEntries = useMemo(() => {
    return filterRegions.map((region) => {
      const filterEntry: FilterEntry = {
        value: region.id,
        name: region.name,
        developmentsCountFiltered: region.developmentsCountFiltered,
      };
      return filterEntry;
    });
  }, [JSON.stringify(filterRegions)]);

  const countryEntries = useMemo(() => {
    return filterCountries.map((country) => {
      const filterEntry: FilterEntry = {
        value: country.id,
        name: country.name,
        developmentsCountFiltered: country.developmentsCountFiltered,
      };
      return filterEntry;
    });
  }, [JSON.stringify(filterCountries)]);

  const authorityEntries = useMemo(() => {
    return filterAuthorities.map((authority) => {
      const filterEntry: FilterEntry = {
        value: authority.id,
        name: authority.name,
        developmentsCountFiltered: authority.developmentsCountFiltered,
      };
      return filterEntry;
    });
  }, [JSON.stringify(filterAuthorities)]);

  const subcategoryEntries = useMemo(() => {
    return filterSubcategories.map((subcategory) => {
      const filterEntry: FilterEntry = {
        value: subcategory.id,
        name: subcategory.name,
        developmentsCountFiltered: subcategory.developmentsCountFiltered,
      };
      return filterEntry;
    });
  }, [JSON.stringify(filterSubcategories)]);

  const documentTypeEntries = useMemo(() => {
    return filterDocumentTypes.map((documentType) => {
      const filterEntry: FilterEntry = {
        value: documentType.id,
        name: documentType.name,
        developmentsCountFiltered: documentType.developmentsCountFiltered,
      };
      return filterEntry;
    });
  }, [JSON.stringify(filterDocumentTypes)]);

  const statusEntries: FilterEntry[] = useMemo(() => {
    if (authenticationResponse) {
      return getStatusFilters(authenticationResponse.id);
    } else {
      return [];
    }
  }, [authenticationResponse]);

  const pageCountEntries: FilterEntry[] = useMemo(() => {
    return getPageCountFilters();
  }, []);

  const turnaroundTimeEntries: FilterEntry[] = useMemo(() => {
    return getTurnaroundTimeFilters();
  }, []);

  const nonConformityEntries: FilterEntry[] = useMemo(() => {
    return getNonConformityFilters();
  }, []);

  const filterEntries = useMemo(() => {
    const result: AllFilterEntries = {
      language: [],
      region: [],
      country: [],
      authority: [],
      subcategory: [],
      documentType: [],
      status: [],
      pageCount: [],
      turnaroundTime: [],
      nonConformity: [],
    };
    const filters: AllFilterEntries = {
      language: languageEntries,
      region: regionEntries,
      country: countryEntries,
      authority: authorityEntries,
      subcategory: subcategoryEntries,
      documentType: documentTypeEntries,
      status: statusEntries,
      pageCount: pageCountEntries,
      turnaroundTime: turnaroundTimeEntries,
      nonConformity: nonConformityEntries,
    };
    Object.entries(filters).forEach(([key, values]) => {
      if (values && values.length) {
        result[key as keyof AllFilterEntries] = (values as FilterEntry[]).map(
          (filterEntry) => {
            const activeFilterKey =
              filterEntry.propertyToFilterByOverride ||
              developmentKeys[key] ||
              (filterKeys as any)[key];
            const activeFiltersIncludeValue =
              !!activeFilters[activeFilterKey] &&
              (typeof activeFilters[activeFilterKey] === 'string'
                ? activeFilters[activeFilterKey].includes(
                    String(filterEntry.value)
                  )
                : Array.isArray(activeFilters[activeFilterKey])
                ? (activeFilters[activeFilterKey] as FilterEntryValue[]).some(
                    (x) =>
                      JSON.stringify(x) === JSON.stringify(filterEntry.value)
                  )
                : false);
            const feedActiveFiltersDontIncludeValue =
              !!feedActiveFilters[activeFilterKey] &&
              !(typeof feedActiveFilters[activeFilterKey] === 'string'
                ? feedActiveFilters[activeFilterKey].includes(
                    String(filterEntry.value)
                  )
                : Array.isArray(feedActiveFilters[activeFilterKey])
                ? (
                    feedActiveFilters[activeFilterKey] as FilterEntryValue[]
                  ).some(
                    (x) =>
                      JSON.stringify(x) === JSON.stringify(filterEntry.value)
                  )
                : false);
            const filterEntriesWithCheckedProperty: FilterEntry = {
              value: filterEntry.value,
              name: filterEntry.name,
              developmentsCountFiltered: filterEntry.developmentsCountFiltered,
              checked:
                activeFiltersIncludeValue && feedActiveFiltersDontIncludeValue,
              propertyToFilterByOverride:
                filterEntry.propertyToFilterByOverride,
            };
            return filterEntriesWithCheckedProperty;
          }
        );
      }
    });
    return result;
  }, [
    JSON.stringify(languageEntries),
    JSON.stringify(regionEntries),
    JSON.stringify(countryEntries),
    JSON.stringify(authorityEntries),
    JSON.stringify(subcategoryEntries),
    JSON.stringify(documentTypeEntries),
    JSON.stringify(statusEntries),
    activeFilters,
    feedActiveFilters,
  ]);

  const handleClearFilters = useCallback(() => {
    if (
      Object.values(feedActiveFilters).some(
        (filterProperty) => !!filterProperty.length
      )
    ) {
      dispatch(setActiveFilters(feedActiveFilters));
    } else {
      dispatch(clearActiveFilters());
    }
    dispatch(setTriggerFeedUpdate());
  }, [feedActiveFilters]);

  const onFilterSetChange = useCallback((filterKey?: string) => {
    return (filterEntry: FilterEntry) => {
      dispatch(resetDevelopmentsPage());
      dispatch(
        updateActiveFilters({
          propertyToFilterBy:
            filterKey || filterEntry.propertyToFilterByOverride || '',
          value: filterEntry.value,
        })
      );
      dispatch(setTriggerFeedUpdate());
    };
  }, []);

  const handleDateFilterClick = useCallback(
    (filterEntry: FilterEntry) => {
      if (filterEntry) {
        if (filterEntry.checked) {
          if (filterEntry.propertyToFilterByOverride) {
            dispatch(resetDevelopmentsPage());
            dispatch(
              updateActiveFilters({
                propertyToFilterBy: filterEntry.propertyToFilterByOverride,
                value: '',
                replace: true,
              })
            );
          }
          dispatch(setTriggerFeedUpdate());
        } else if (filterEntry.propertyToFilterByOverride) {
          openDatePickerModal(filterEntry);
        }
      }
    },
    [activeFilters]
  );

  const openDatePickerModal = useCallback((filterEntry: FilterEntry) => {
    if (filterEntry && filterEntry.propertyToFilterByOverride) {
      setCurrentDateProperty(filterEntry.propertyToFilterByOverride);
    }
  }, []);

  const dateFilterEntries: FilterEntry[] = useMemo(() => {
    const doActiveFiltersHaveAnyProcessingDate =
      !!activeFilters[developmentKeys.processingDate] &&
      typeof activeFilters[developmentKeys.processingDate] === 'string';

    const doActiveFiltersHaveAnyPublicationDate =
      !!activeFilters[filterKeys.publicationDates] &&
      typeof activeFilters[filterKeys.publicationDates] === 'string';

    const doActiveFiltersHaveAnyEffectiveDate =
      !!activeFilters[filterKeys.effectiveDates] &&
      typeof activeFilters[filterKeys.effectiveDates] === 'string';

    const doActiveFiltersHaveAnyComplianceDate =
      !!activeFilters[filterKeys.complianceDates] &&
      typeof activeFilters[filterKeys.complianceDates] === 'string';

    return [
      {
        name: 'Processed date',
        checked: doActiveFiltersHaveAnyProcessingDate,
        propertyToFilterByOverride: developmentKeys.processingDate,
        child: DateChip,
        childProps: {
          range: doActiveFiltersHaveAnyProcessingDate
            ? dateRangeInMillisecondsStringToDateRange(
                `${activeFilters[developmentKeys.processingDate]}`
              )
            : null,
        },
      },
      {
        name: 'Published date',
        propertyToFilterByOverride: filterKeys.publicationDates,
        checked: doActiveFiltersHaveAnyPublicationDate,
        child: DateChip,
        childProps: {
          range: doActiveFiltersHaveAnyPublicationDate
            ? dateRangeInMillisecondsStringToDateRange(
                `${activeFilters[filterKeys.publicationDates]}`
              )
            : null,
        },
      },
      {
        name: 'Effective date',
        propertyToFilterByOverride: filterKeys.effectiveDates,
        checked: doActiveFiltersHaveAnyEffectiveDate,
        child: DateChip,
        childProps: {
          range: doActiveFiltersHaveAnyEffectiveDate
            ? dateRangeInMillisecondsStringToDateRange(
                `${activeFilters[filterKeys.effectiveDates]}`
              )
            : null,
        },
      },
      {
        name: 'Compliance date',
        propertyToFilterByOverride: filterKeys.complianceDates,
        checked: doActiveFiltersHaveAnyComplianceDate,
        child: DateChip,
        childProps: {
          range: doActiveFiltersHaveAnyComplianceDate
            ? dateRangeInMillisecondsStringToDateRange(
                `${activeFilters[filterKeys.complianceDates]}`
              )
            : null,
        },
      },
    ];
  }, [
    activeFilters[developmentKeys.processingDate],
    activeFilters[filterKeys.publicationDates],
    activeFilters[filterKeys.effectiveDates],
    activeFilters[filterKeys.complianceDates],
  ]);

  const handleDateRangeSelected = useCallback(
    (range: Partial<DateRange>) => {
      if (currentDateProperty) {
        if (range.startDate && !range.endDate) {
          range.endDate = range.startDate;
        }
        dispatch(resetDevelopmentsPage());
        dispatch(
          updateActiveFilters({
            propertyToFilterBy: currentDateProperty,
            value: dateRangeToString(ignoreDateRangeTime(range)),
            replace: true,
          })
        );
        handleCancelDateSelection(currentDateProperty);
        setPreviousActiveFilters(activeFilters);
        setIsDateRangeSelected(true);
        dispatch(setTriggerFeedUpdate());
      }
    },
    [JSON.stringify(activeFilters), currentDateProperty]
  );

  const currentDateRange = useMemo(() => {
    return currentDateProperty &&
      activeFilters[currentDateProperty] &&
      typeof activeFilters[currentDateProperty] === 'string'
      ? dateRangeInMillisecondsStringToDateRange(
          `${activeFilters[currentDateProperty]}`
        )
      : null;
  }, [currentDateProperty, activeFilters]);

  const handleCancelDateSelection = useCallback((dateProperty?: string) => {
    if (dateProperty) {
      setPreviousDateProperty(dateProperty);
    }
    setCurrentDateProperty(null);
  }, []);

  const isSaveAsFeedButtonDisplayed = useMemo(() => {
    const isFeedAppliedDifferentFromDefault =
      appliedFeed?.id !== Number.MAX_SAFE_INTEGER;
    const isAnyFilterSelected = !!(
      (activeFilters[filterKeys.authority] &&
        activeFilters[filterKeys.authority].length) ||
      (activeFilters[filterKeys.subcategory] &&
        activeFilters[filterKeys.subcategory].length) ||
      (activeFilters[filterKeys.language] &&
        activeFilters[filterKeys.language].length) ||
      (activeFilters[filterKeys.region] &&
        activeFilters[filterKeys.region].length) ||
      (activeFilters[filterKeys.country] &&
        activeFilters[filterKeys.country].length) ||
      (activeFilters[filterKeys.documentType] &&
        activeFilters[filterKeys.documentType].length)
    );
    const isAnySelectedFilterDifferentFromFeedActiveFilters = !(
      doBothArraysHaveTheSameElements(
        activeFilters[filterKeys.authority] as number[],
        appliedFeed?.authorityIds
      ) &&
      doBothArraysHaveTheSameElements(
        activeFilters[filterKeys.subcategory] as number[],
        appliedFeed?.subcategoryIds
      ) &&
      doBothArraysHaveTheSameElements(
        activeFilters[filterKeys.language] as number[],
        appliedFeed?.languageIds
      ) &&
      doBothArraysHaveTheSameElements(
        activeFilters[filterKeys.region] as number[],
        appliedFeed?.regionIds
      ) &&
      doBothArraysHaveTheSameElements(
        activeFilters[filterKeys.country] as number[],
        appliedFeed?.countryIds
      ) &&
      doBothArraysHaveTheSameElements(
        activeFilters[filterKeys.documentType] as number[],
        appliedFeed?.documentTypeIds
      )
    );
    return (
      (!isFeedAppliedDifferentFromDefault && isAnyFilterSelected) ||
      (isFeedAppliedDifferentFromDefault &&
        isAnySelectedFilterDifferentFromFeedActiveFilters)
    );
  }, [appliedFeed, activeFilters]);

  useEffect(() => {
    if (scrollPosition && scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollTo({
        top: scrollPosition,
      });
    }
  }, [scrollPosition, scrollableContainerRef]);

  useEffect(() => {
    if (lastViewedFeed) {
      dispatch(getFilterCountersAsync());
    }
  }, [
    searchTerm,
    JSON.stringify(searchProperties),
    JSON.stringify(activeFilters),
    isSkipSetAllActiveFiltersOnce,
  ]);

  useEffect(() => {
    if (appliedFeed) {
      if (
        activeFilters[filterKeys.authority] &&
        activeFilters[filterKeys.authority].length === 0
      ) {
        dispatch(
          setFeedActiveFilters({
            propertyToFilterBy: filterKeys.authority,
            values: appliedFeed.authorityIds,
          })
        );
      }
      if (
        activeFilters[filterKeys.subcategory] &&
        activeFilters[filterKeys.subcategory].length === 0
      ) {
        dispatch(
          setFeedActiveFilters({
            propertyToFilterBy: filterKeys.subcategory,
            values: appliedFeed.subcategoryIds,
          })
        );
      }
      if (
        activeFilters[filterKeys.language] &&
        activeFilters[filterKeys.language].length === 0
      ) {
        dispatch(
          setFeedActiveFilters({
            propertyToFilterBy: filterKeys.language,
            values: appliedFeed.languageIds,
          })
        );
      }
      if (
        activeFilters[filterKeys.region] &&
        activeFilters[filterKeys.region].length === 0
      ) {
        dispatch(
          setFeedActiveFilters({
            propertyToFilterBy: filterKeys.region,
            values: appliedFeed.regionIds,
          })
        );
      }
      if (
        activeFilters[filterKeys.country] &&
        activeFilters[filterKeys.country].length === 0
      ) {
        dispatch(
          setFeedActiveFilters({
            propertyToFilterBy: filterKeys.country,
            values: appliedFeed.countryIds,
          })
        );
      }
      if (
        activeFilters[filterKeys.documentType] &&
        activeFilters[filterKeys.documentType].length === 0
      ) {
        dispatch(
          setFeedActiveFilters({
            propertyToFilterBy: filterKeys.documentType,
            values: appliedFeed.documentTypeIds,
          })
        );
      }
    }
  }, [appliedFeed, activeFilters]);

  useEffect(() => {
    if (appliedFeed && !isSkipSetAllActiveFiltersOnce) {
      if (appliedFeed.authorityIds) {
        if (appliedFeed.authorityIds.length === 0) {
          dispatch(setIsAuthoritiesFilterCollapsed(true));
        } else {
          dispatch(setIsAuthoritiesFilterCollapsed(false));
        }
      }
      if (appliedFeed.subcategoryIds) {
        if (appliedFeed.subcategoryIds.length === 0) {
          dispatch(setIsSubcategoriesFilterCollapsed(true));
        } else {
          dispatch(setIsSubcategoriesFilterCollapsed(false));
        }
      }
      if (appliedFeed.languageIds) {
        if (appliedFeed.languageIds.length === 0) {
          dispatch(setIsLanguagesFilterCollapsed(true));
        } else {
          dispatch(setIsLanguagesFilterCollapsed(false));
        }
      }
      if (appliedFeed.regionIds) {
        if (appliedFeed.regionIds.length === 0) {
          dispatch(setIsRegionsFilterCollapsed(true));
        } else {
          dispatch(setIsRegionsFilterCollapsed(false));
        }
      }
      if (appliedFeed.countryIds) {
        if (appliedFeed.countryIds.length === 0) {
          dispatch(setIsCountriesFilterCollapsed(true));
        } else {
          dispatch(setIsCountriesFilterCollapsed(false));
        }
      }
      if (appliedFeed.documentTypeIds) {
        if (appliedFeed.documentTypeIds.length === 0) {
          dispatch(setIsDocumentTypesFilterCollapsed(true));
        } else {
          dispatch(setIsDocumentTypesFilterCollapsed(false));
        }
      }
    }
  }, [appliedFeed]);

  useEffect(() => {
    if (
      activeFilters[filterKeys.authority] &&
      activeFilters[filterKeys.authority].length
    ) {
      dispatch(setIsAuthoritiesFilterCollapsed(false));
    }
    if (
      activeFilters[filterKeys.subcategory] &&
      activeFilters[filterKeys.subcategory].length
    ) {
      dispatch(setIsSubcategoriesFilterCollapsed(false));
    }
    if (
      activeFilters[filterKeys.language] &&
      activeFilters[filterKeys.language].length
    ) {
      dispatch(setIsLanguagesFilterCollapsed(false));
    }
    if (
      activeFilters[filterKeys.region] &&
      activeFilters[filterKeys.region].length
    ) {
      dispatch(setIsRegionsFilterCollapsed(false));
    }
    if (
      activeFilters[filterKeys.country] &&
      activeFilters[filterKeys.country].length
    ) {
      dispatch(setIsCountriesFilterCollapsed(false));
    }
    if (
      activeFilters[filterKeys.documentType] &&
      activeFilters[filterKeys.documentType].length
    ) {
      dispatch(setIsDocumentTypesFilterCollapsed(false));
    }
    if (
      (!!activeFilters[developmentKeys.processingDate] &&
        typeof activeFilters[developmentKeys.processingDate] === 'string') ||
      (!!activeFilters[filterKeys.publicationDates] &&
        typeof activeFilters[filterKeys.publicationDates] === 'string') ||
      (!!activeFilters[filterKeys.effectiveDates] &&
        typeof activeFilters[filterKeys.effectiveDates] === 'string') ||
      (!!activeFilters[filterKeys.complianceDates] &&
        typeof activeFilters[filterKeys.complianceDates] === 'string')
    ) {
      dispatch(setIsDateFilterCollapsed(false));
    }
    if (
      activeFilters[developmentKeys.pageCount] &&
      activeFilters[developmentKeys.pageCount].length
    ) {
      dispatch(setIsPageCountFilterCollapsed(false));
    }
    if (
      activeFilters[filterKeys.isWithinRequiredTurnaroundTime] &&
      activeFilters[filterKeys.isWithinRequiredTurnaroundTime].length
    ) {
      dispatch(setIsTurnaroundTimeFilterCollapsed(false));
    }
    if (
      activeFilters[developmentKeys.exceptionFlag] &&
      activeFilters[developmentKeys.exceptionFlag].length
    ) {
      dispatch(setIsNonConformityFilterCollapsed(false));
    }
  }, [activeFilters]);

  const saveAsFeed = useCallback(() => {
    const createFeedActiveFilters: ActiveFilters = {};
    if (
      activeFilters[filterKeys.authority] &&
      activeFilters[filterKeys.authority].length
    ) {
      createFeedActiveFilters[filterKeys.authority] =
        activeFilters[filterKeys.authority];
      dispatch(setIsNewFeedFilterAuthoritiesCollapsed(false));
    }
    if (
      activeFilters[filterKeys.subcategory] &&
      activeFilters[filterKeys.subcategory].length
    ) {
      createFeedActiveFilters[filterKeys.subcategory] =
        activeFilters[filterKeys.subcategory];
      dispatch(setIsNewFeedFilterSubcategoriesCollapsed(false));
    }
    if (
      activeFilters[filterKeys.language] &&
      activeFilters[filterKeys.language].length
    ) {
      createFeedActiveFilters[filterKeys.language] =
        activeFilters[filterKeys.language];
      dispatch(setIsNewFeedFilterLanguagesCollapsed(false));
    }
    if (
      activeFilters[filterKeys.region] &&
      activeFilters[filterKeys.region].length
    ) {
      createFeedActiveFilters[filterKeys.region] =
        activeFilters[filterKeys.region];
      dispatch(setIsNewFeedFilterRegionsCollapsed(false));
    }
    if (
      activeFilters[filterKeys.country] &&
      activeFilters[filterKeys.country].length
    ) {
      createFeedActiveFilters[filterKeys.country] =
        activeFilters[filterKeys.country];
      dispatch(setIsNewFeedFilterCountriesCollapsed(false));
    }
    if (
      activeFilters[filterKeys.documentType] &&
      activeFilters[filterKeys.documentType].length
    ) {
      createFeedActiveFilters[filterKeys.documentType] =
        activeFilters[filterKeys.documentType];
      dispatch(setIsNewFeedFilterDocumentTypesCollapsed(false));
    }
    dispatch(setNewFeedActiveFilters(createFeedActiveFilters));
    props.setIsCreateFeedModalDisplayed(true);
  }, [activeFilters]);

  return (
    <>
      <Sidebar
        id="HomeSidebar"
        setHeight={setScrollableContainerHeight}
        isLoading={
          isLoadingStatus ||
          isLoadingLanguages ||
          isLoadingRegions ||
          isLoadingCountries ||
          isLoadingAuthorities ||
          isLoadingSubcategories ||
          isLoadingDocumentTypes ||
          isLoadingPageCount ||
          isLoadingTurnaroundTime ||
          isLoadingNonConformity
        }
        scrollableContainerRef={scrollableContainerRef}
        header={
          !!scrollableContainerHeight ? (
            <RecentlyViewedDevelopmentsDropdown
              scrollableParentContainerHeight={scrollableContainerHeight}
              dropdownContainerHeight={
                recentlyViewedDevelopmentsDropdownContainerHeight
              }
            />
          ) : undefined
        }
        headerHeight={recentlyViewedDevelopmentsDropdownContainerHeight}
        footer={
          isSaveAsFeedButtonDisplayed ? (
            <SaveAsFeedButtonContainer
              id="HomeSidebarSaveAsFeedButtonContainer"
              onClick={saveAsFeed}
              ref={(saveAsFeedButtonContainer) => {
                if (saveAsFeedButtonContainer) {
                  setSaveAsFeedButtonContainerHeight(
                    saveAsFeedButtonContainer.clientHeight + 1
                  );
                }
              }}
            >
              <TextButton
                id="HomeSidebarSaveAsFeedButton"
                color={Theme.colors.secondaryColor}
                fontSize={Theme.fontSize}
              >
                <Save id="HomeSidebarSaveIcon" style={{ marginRight: '5px' }} />
                Save as feed
              </TextButton>
            </SaveAsFeedButtonContainer>
          ) : undefined
        }
        footerHeight={saveAsFeedButtonContainerHeight}
      >
        <FlexContainer
          id="HomeSidebarFlexContainer"
          flexDirection="row"
          style={{
            padding: '0 15px 15px 15px',
            width: '100%',
          }}
        >
          <FlexContainer
            id="HomeSidebarInnerFlexContainer"
            flexDirection="column"
            style={{ width: '100%' }}
          >
            <TitleContainer id="HomeSidebarTitleContainer">
              <BoldText
                id="HomeSidebarTitleBoldText"
                color={Theme.colors.primaryColor}
              >
                Filter by
              </BoldText>
              <UnderlinedButton
                id="HomeSidebarTitleButton"
                color={Theme.colors.secondaryColor}
                onClick={handleClearFilters}
                isDisabled={!props.isFiltering}
              >
                Clear all
              </UnderlinedButton>
            </TitleContainer>
          </FlexContainer>
          {filterEntries &&
            activeFilters &&
            Object.keys(activeFilters).length > 0 && (
              <>
                <FilterSet
                  noMarginTop
                  isCollapsed={isStatusFilterCollapsed}
                  setIsCollapsed={setIsStatusFilterCollapsed}
                  title="Status"
                  entries={filterEntries.status}
                  onChange={onFilterSetChange()}
                  filterCounters={filterCounters?.status}
                  setIsLoading={setIsLoadingStatus}
                  displayEntriesWithCountZero
                />
                <FilterSet
                  isCollapsed={isLanguagesFilterCollapsed}
                  setIsCollapsed={setIsLanguagesFilterCollapsed}
                  title="Language"
                  entries={filterEntries.language}
                  totalRecords={filterLanguagesTotalRecords}
                  getAllAsync={getAllHomeSidebarFilterLanguagesAsync}
                  searchTerm={filterLanguagesSearchTerm}
                  setSearchTerm={setHomeSidebarFilterLanguagesSearchTerm}
                  page={filterLanguagesPage}
                  perPage={filterLanguagesPerPage}
                  increasePage={increaseHomeSidebarFilterLanguagesPage}
                  resetPage={resetHomeSidebarFilterLanguagesPage}
                  idsToFilter={appliedFeed?.languageIds || []}
                  onChange={onFilterSetChange(filterKeys.language)}
                  activeFilters={activeFilters}
                  activeFiltersPropertyToIgnore={filterKeys.language}
                  setIsLoading={setIsLoadingLanguages}
                  triggerGetAllFilterSetEntries={triggerGetAllFilterSetEntries}
                  noGetAllAsyncOnRender={isSkipSetAllActiveFiltersOnce}
                />
                <FilterSet
                  isCollapsed={isRegionsFilterCollapsed}
                  setIsCollapsed={setIsRegionsFilterCollapsed}
                  title="Region"
                  entries={filterEntries.region}
                  totalRecords={filterRegionsTotalRecords}
                  getAllAsync={getAllHomeSidebarFilterRegionsAsync}
                  searchTerm={filterRegionsSearchTerm}
                  setSearchTerm={setHomeSidebarFilterRegionsSearchTerm}
                  page={filterRegionsPage}
                  perPage={filterRegionsPerPage}
                  increasePage={increaseHomeSidebarFilterRegionsPage}
                  resetPage={resetHomeSidebarFilterRegionsPage}
                  idsToFilter={appliedFeed?.regionIds || []}
                  onChange={onFilterSetChange(filterKeys.region)}
                  activeFilters={activeFilters}
                  activeFiltersPropertyToIgnore={filterKeys.region}
                  setIsLoading={setIsLoadingRegions}
                  triggerGetAllFilterSetEntries={triggerGetAllFilterSetEntries}
                  noGetAllAsyncOnRender={isSkipSetAllActiveFiltersOnce}
                />
                <FilterSet
                  isCollapsed={isCountriesFilterCollapsed}
                  setIsCollapsed={setIsCountriesFilterCollapsed}
                  title="Jurisdiction"
                  entries={filterEntries.country}
                  totalRecords={filterCountriesTotalRecords}
                  getAllAsync={getAllHomeSidebarFilterCountriesAsync}
                  searchTerm={filterCountriesSearchTerm}
                  setSearchTerm={setHomeSidebarFilterCountriesSearchTerm}
                  page={filterCountriesPage}
                  perPage={filterCountriesPerPage}
                  increasePage={increaseHomeSidebarFilterCountriesPage}
                  resetPage={resetHomeSidebarFilterCountriesPage}
                  idsToFilter={appliedFeed?.countryIds || []}
                  onChange={onFilterSetChange(filterKeys.country)}
                  activeFilters={activeFilters}
                  activeFiltersPropertyToIgnore={filterKeys.country}
                  setIsLoading={setIsLoadingCountries}
                  triggerGetAllFilterSetEntries={triggerGetAllFilterSetEntries}
                  noGetAllAsyncOnRender={isSkipSetAllActiveFiltersOnce}
                />
                <FilterSet
                  isCollapsed={isAuthoritiesFilterCollapsed}
                  setIsCollapsed={setIsAuthoritiesFilterCollapsed}
                  title="Issuing Authority"
                  entries={filterEntries.authority}
                  totalRecords={filterAuthoritiesTotalRecords}
                  getAllAsync={getAllHomeSidebarFilterAuthoritiesAsync}
                  searchTerm={filterAuthoritiesSearchTerm}
                  setSearchTerm={setHomeSidebarFilterAuthoritiesSearchTerm}
                  page={filterAuthoritiesPage}
                  perPage={filterAuthoritiesPerPage}
                  increasePage={increaseHomeSidebarFilterAuthoritiesPage}
                  resetPage={resetHomeSidebarFilterAuthoritiesPage}
                  idsToFilter={appliedFeed?.authorityIds || []}
                  onChange={onFilterSetChange(filterKeys.authority)}
                  activeFilters={activeFilters}
                  activeFiltersPropertyToIgnore={filterKeys.authority}
                  setIsLoading={setIsLoadingAuthorities}
                  triggerGetAllFilterSetEntries={triggerGetAllFilterSetEntries}
                  noGetAllAsyncOnRender={isSkipSetAllActiveFiltersOnce}
                />
                <FilterSet
                  isCollapsed={isSubcategoriesFilterCollapsed}
                  setIsCollapsed={setIsSubcategoriesFilterCollapsed}
                  title="Subcategory"
                  entries={filterEntries.subcategory}
                  totalRecords={filterSubcategoriesTotalRecords}
                  getAllAsync={getAllHomeSidebarFilterSubcategoriesAsync}
                  searchTerm={filterSubcategoriesSearchTerm}
                  setSearchTerm={setHomeSidebarFilterSubcategoriesSearchTerm}
                  page={filterSubcategoriesPage}
                  perPage={filterSubcategoriesPerPage}
                  increasePage={increaseHomeSidebarFilterSubcategoriesPage}
                  resetPage={resetHomeSidebarFilterSubcategoriesPage}
                  idsToFilter={appliedFeed?.subcategoryIds || []}
                  onChange={onFilterSetChange(filterKeys.subcategory)}
                  activeFilters={activeFilters}
                  activeFiltersPropertyToIgnore={filterKeys.subcategory}
                  setIsLoading={setIsLoadingSubcategories}
                  triggerGetAllFilterSetEntries={triggerGetAllFilterSetEntries}
                  noGetAllAsyncOnRender={isSkipSetAllActiveFiltersOnce}
                />
                <FilterSet
                  isCollapsed={isDocumentTypesFilterCollapsed}
                  setIsCollapsed={setIsDocumentTypesFilterCollapsed}
                  title="Document type"
                  entries={filterEntries.documentType}
                  totalRecords={filterDocumentTypesTotalRecords}
                  getAllAsync={getAllHomeSidebarFilterDocumentTypesAsync}
                  searchTerm={filterDocumentTypesSearchTerm}
                  setSearchTerm={setHomeSidebarFilterDocumentTypesSearchTerm}
                  page={filterDocumentTypesPage}
                  perPage={filterDocumentTypesPerPage}
                  increasePage={increaseHomeSidebarFilterDocumentTypesPage}
                  resetPage={resetHomeSidebarFilterDocumentTypesPage}
                  idsToFilter={appliedFeed?.documentTypeIds || []}
                  onChange={onFilterSetChange(filterKeys.documentType)}
                  activeFilters={activeFilters}
                  activeFiltersPropertyToIgnore={filterKeys.documentType}
                  setIsLoading={setIsLoadingDocumentTypes}
                  triggerGetAllFilterSetEntries={triggerGetAllFilterSetEntries}
                  noGetAllAsyncOnRender={isSkipSetAllActiveFiltersOnce}
                />
                <FilterSet
                  isCollapsed={isDateFilterCollapsed}
                  setIsCollapsed={setIsDateFilterCollapsed}
                  title="Date range"
                  entries={dateFilterEntries}
                  totalRecords={dateFilterEntries.length}
                  onChange={handleDateFilterClick}
                  onEntryChildClick={openDatePickerModal}
                  displayEntriesWithCountZero
                  hideEntriesCount
                />
                <FilterSet
                  isCollapsed={isPageCountFilterCollapsed}
                  setIsCollapsed={setIsPageCountFilterCollapsed}
                  title="Page Count"
                  entries={filterEntries.pageCount}
                  onChange={onFilterSetChange()}
                  filterCounters={filterCounters?.pageCount}
                  setIsLoading={setIsLoadingPageCount}
                  displayEntriesWithCountZero
                />
                <FilterSet
                  isCollapsed={isTurnaroundTimeFilterCollapsed}
                  setIsCollapsed={setIsTurnaroundTimeFilterCollapsed}
                  title="Turnaround Time"
                  entries={filterEntries.turnaroundTime}
                  onChange={onFilterSetChange()}
                  filterCounters={filterCounters?.turnaroundTime}
                  setIsLoading={setIsLoadingTurnaroundTime}
                  displayEntriesWithCountZero
                />
                <FilterSet
                  isCollapsed={isNonConformityFilterCollapsed}
                  setIsCollapsed={setIsNonConformityFilterCollapsed}
                  title="Non-Conformity"
                  entries={filterEntries.nonConformity}
                  onChange={onFilterSetChange()}
                  filterCounters={filterCounters?.nonConformity}
                  setIsLoading={setIsLoadingNonConformity}
                  displayEntriesWithCountZero
                />
              </>
            )}
        </FlexContainer>
      </Sidebar>
      {!!currentDateProperty && (
        <DatePickerModal
          isOpen
          onChange={() => {}}
          initialValue={currentDateRange}
          onConfirm={handleDateRangeSelected}
          onCancel={handleCancelDateSelection}
        />
      )}
      <FilterResetWarningModal
        isDisplayed={isFilterResetWarningModalDisplayed}
        setIsDisplayed={setIsFilterResetWarningModalDisplayed}
        previousActiveFilters={previousActiveFilters}
        previousDatePropertySelected={previousDateProperty}
        setIsCreateFeedModalDisplayed={props.setIsCreateFeedModalDisplayed}
        setCreateFeedModalSelectsFeedAfterUpsert={
          props.setCreateFeedModalSelectsFeedAfterUpsert
        }
        isDateRangeSelected={isDateRangeSelected}
        setIsDateRangeSelected={setIsDateRangeSelected}
      />
    </>
  );
};
