import { Source } from 'models/reports/Source';
import * as XLSX from 'xlsx';

export const exportToExcel = (excelData: Source[], headers: string[]) => {
  const combinedData = [
    headers,
    ...excelData.map((item: any, index: number) => {
      const rowData = [
        item.RowId ?? '',
        item.Countries ?? '',
        item.Authorities ?? '',
        item.Subcategories ?? '',
        item.DocumentTypes ?? '',
        item.Environments ?? '',
      ];
      return rowData;
    }),
  ];

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const ws = XLSX.utils.aoa_to_sheet(combinedData); // Create a worksheet from data
  const wb = XLSX.utils.book_new(); // Create a new workbook
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1'); // Append the worksheet to the workbook

  // Write the workbook to an Excel file
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

  // Create a Blob for the Excel file
  const blob = new Blob([excelBuffer], { type: fileType });

  // Create a download link
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'Report.xlsx'); // Set the file extension to .xlsx
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};
