import { memo } from 'react';
import { EnvironmentSwitcher, EnvironmentSwitcherProps } from './components';
import { Modal } from 'components/Modal';

interface EnvironmentSwitchModalProps extends EnvironmentSwitcherProps {
  isDisplayed: boolean;
}

export const EnvironmentSwitchModal = memo(
  (props: EnvironmentSwitchModalProps) => {
    const { isDisplayed, ...rest } = props;
    return (
      <Modal id="EnvironmentSwitchModal" isDisplayed={isDisplayed}>
        <EnvironmentSwitcher {...rest} />
      </Modal>
    );
  }
);
