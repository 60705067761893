import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Searchbar } from 'components/Searchbar';
import { FilterSetEntry } from './components/FilterSetEntry';
import { AppThunk } from 'store/store';
import { ActiveFilters, FilterEntry, PaginatedResponse } from 'models';
import { useAppDispatch } from 'store/hooks';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { ArrowDirections, Tooltip } from 'components/Tooltip';
import { FlexContainer } from 'styles/utils';
import { DropdownArrow } from 'components/Dropdown';
import { HTMLAttrID } from 'models';
import { pascalCase } from 'utils/string-formatter';
import { LoadMoreButton } from 'components/LoadMoreButton';

export interface FilterSetProps extends HTMLAttrID {
  noMarginTop?: boolean;
  isCollapsed?: boolean;
  setIsCollapsed?: ActionCreatorWithPayload<boolean, string>;
  title: string;
  entries: FilterEntry[];
  totalRecords?: number;
  getAllAsync?: () => AppThunk<Promise<PaginatedResponse<any> | null>>;
  searchTerm?: string;
  setSearchTerm?: ActionCreatorWithPayload<string, string>;
  page?: number;
  perPage?: number;
  increasePage?: ActionCreatorWithPayload<void>;
  resetPage?: ActionCreatorWithPayload<void>;
  idsToFilter?: number[];
  searchbarPlaceholder?: string;
  onChange?: (filterEntry: FilterEntry) => void;
  onEntryChildClick?: (filterEntry: FilterEntry) => void;
  filterCounters?: {};
  filterEntriesWidth?: string;
  activeFilters?: ActiveFilters;
  activeFiltersPropertyToIgnore?: string;
  displayEntriesWithCountZero?: boolean;
  hideEntriesCount?: boolean;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
  triggerGetAllFilterSetEntries?: number;
  noHeader?: boolean;
  noBorders?: boolean;
  noGetAllAsyncOnRender?: boolean;
}

const MainContainer = styled.div<{ noMarginTop?: boolean }>((props) => ({
  margin: props.noMarginTop ? '0 0 6px 0' : '6px 0',
  width: '100%',
}));

const CollapsableContainer = styled.div<{
  isCollapsed?: boolean;
  noBorders?: boolean;
}>((props) => ({
  display: props.isCollapsed ? 'none' : 'flex',
  border: props.noBorders ? 'none' : `1px solid ${Theme.colors.grayBorder}`,
  borderBottomLeftRadius: Theme.borderRadius,
  borderBottomRightRadius: Theme.borderRadius,
  flexDirection: 'row',
  overflow: 'hidden',
  padding: '4px 8px',
  alignItems: 'start',
  width: '100%',
  flexWrap: 'wrap',
}));

const Title = styled.div<{ isCollapsed?: boolean }>((props) => ({
  border: `1px solid ${Theme.colors.grayBorder}`,
  borderTopLeftRadius: Theme.borderRadius,
  borderTopRightRadius: Theme.borderRadius,
  backgroundColor: props.isCollapsed
    ? Theme.colors.transparent
    : Theme.colors.primaryBackgroundColor,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '4px 8px',
  cursor: 'pointer',
  width: '100%',
  userSelect: 'none',
}));

const SearchBarContainer = styled.div({
  margin: '6px 0',
  width: '100%',
});

export const FilterSet = (props: FilterSetProps & { id?: string }) => {
  const dispatch = useAppDispatch();
  const [filtersCountContainerElement, setFiltersCountContainerElement] =
    useState<HTMLDivElement | null>(null);
  const [triggerGetEntries, setTriggerGetEntries] = useState(0);

  const isFirstTime = useRef<boolean>(true);

  const onFiltersCountContainerRefChange = useCallback(
    (filtersCountContainer: HTMLDivElement) => {
      if (filtersCountContainer) {
        setFiltersCountContainerElement(filtersCountContainer);
      }
    },
    []
  );

  const activeFiltersJson = useMemo(() => {
    return JSON.stringify(
      Object.entries(props.activeFilters || {}).filter(
        ([key, value]) => key !== props.activeFiltersPropertyToIgnore
      )
    );
  }, [props.activeFilters]);

  useEffect(() => {
    const skipGetAllAsync = props.noGetAllAsyncOnRender && isFirstTime.current;
    if (props.getAllAsync && !skipGetAllAsync) {
      if (props.setIsLoading) {
        props.setIsLoading(true);
      }
      dispatch(props.getAllAsync()).then(() => {
        if (props.setIsLoading) {
          props.setIsLoading(false);
        }
      });
    }
  }, [triggerGetEntries, props.triggerGetAllFilterSetEntries]);

  useEffect(() => {
    if (!isFirstTime.current) {
      if (props.resetPage) {
        setTriggerGetEntries((currentValue) => currentValue + 1);
      }
    }
    isFirstTime.current = false;
  }, [activeFiltersJson]);

  const handleCollapse = useCallback(() => {
    if (props.setIsCollapsed) {
      dispatch(props.setIsCollapsed(!props.isCollapsed));
    }
  }, [props.isCollapsed]);

  const entryCount = useCallback(
    (entry: FilterEntry) => {
      let count: number = 0;
      if (props.filterCounters) {
        const filterCounterKeyValue = Object.entries(props.filterCounters).find(
          ([key, value]) => key === entry.name
        );
        if (filterCounterKeyValue && filterCounterKeyValue.length > 1) {
          count = Number(filterCounterKeyValue[1]);
        }
      } else {
        count = entry.developmentsCountFiltered || 0;
      }
      return count;
    },
    [props.filterCounters]
  );

  const MappedEntries = useMemo(() => {
    return props.entries
      .filter((entry) => {
        const count: number = entryCount(entry);
        return count > 0 || props.displayEntriesWithCountZero;
      })
      .map((entry) => {
        const count: number = entryCount(entry);
        return (
          <FilterSetEntry
            key={
              entry.value &&
              (typeof entry.value === 'string' ||
                typeof entry.value === 'number')
                ? entry.value
                : entry.name
            }
            {...entry}
            count={props.hideEntriesCount ? undefined : count}
            onChange={() => {
              props.onChange && props.onChange(entry);
            }}
            onEntryChildClick={() =>
              props.onEntryChildClick && props.onEntryChildClick(entry)
            }
            width={props.filterEntriesWidth}
            filterSetTitle={props.title}
          />
        );
      });
  }, [
    props.entries,
    props.displayEntriesWithCountZero,
    props.hideEntriesCount,
    props.filterEntriesWidth,
    props.filterCounters,
  ]);

  const FiltersCount = useMemo(() => {
    if (props.idsToFilter && props.idsToFilter.length) {
      return (
        <span
          style={{ color: Theme.colors.secondaryColor, fontWeight: 'bold' }}
        >
          ({props.idsToFilter.length})*
        </span>
      );
    } else if (props.totalRecords) {
      return <span style={{ fontWeight: 'bold' }}>({props.totalRecords})</span>;
    } else {
      return (
        <span style={{ fontWeight: 'bold' }}>({MappedEntries.length})</span>
      );
    }
  }, [props.idsToFilter, props.totalRecords, MappedEntries]);

  const htmlId = props.id || 'FilterSet' + pascalCase(props.title);

  return (
    <MainContainer
      id={htmlId + 'MainContainer'}
      noMarginTop={props.noMarginTop}
    >
      {!props.noHeader && (
        <Title
          id={htmlId + 'Title'}
          onClick={handleCollapse}
          isCollapsed={props.isCollapsed}
        >
          <FlexContainer id={htmlId + 'FlexContainer'}>
            <span
              id={htmlId + 'TitleSpan'}
              style={{
                fontWeight: 'bold',
                marginRight: '5px',
                userSelect: 'none',
              }}
            >
              {props.title}
            </span>
            <div
              id={htmlId + 'FiltersCount'}
              ref={onFiltersCountContainerRefChange}
              style={{ position: 'relative', userSelect: 'none' }}
            >
              {FiltersCount}
              <div
                id={htmlId + 'FiltersCountInnerDiv'}
                style={{
                  display:
                    props.idsToFilter && props.idsToFilter.length
                      ? 'block'
                      : 'none',
                }}
              >
                <Tooltip
                  id={htmlId + 'Tooltip'}
                  relativeToElement={filtersCountContainerElement}
                  hasArrow
                  arrowDirection={ArrowDirections.Down}
                >
                  <span
                    id={htmlId + 'TooltipText'}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    Configured filter
                  </span>
                </Tooltip>
              </div>
            </div>
          </FlexContainer>
          <div
            id={htmlId + 'DropdownArrowContainer'}
            style={{ height: '24px' }}
          >
            <DropdownArrow isExpanded={!props.isCollapsed} />
          </div>
        </Title>
      )}
      <CollapsableContainer
        id={htmlId + 'CollapsableContainer'}
        isCollapsed={props.isCollapsed}
        noBorders={props.noBorders}
      >
        {!!props.getAllAsync && (
          <SearchBarContainer id={htmlId + 'SearchBarContainer'}>
            <Searchbar
              id={htmlId + 'Searchbar'}
              placeholder={
                props.searchbarPlaceholder ||
                `Search ${props.title.toLowerCase()}...`
              }
              value={props.searchTerm}
              setValue={(value) => {
                if (props.setSearchTerm) {
                  dispatch(props.setSearchTerm(value));
                  setTriggerGetEntries((currentValue) => currentValue + 1);
                }
              }}
            />
          </SearchBarContainer>
        )}
        {MappedEntries}
        {!!props.page &&
          !!props.perPage &&
          !!props.totalRecords &&
          props.increasePage && (
            <LoadMoreButton
              id={`${htmlId}LoadMoreButton`}
              page={props.page}
              perPage={props.perPage}
              totalRecords={props.totalRecords}
              increasePage={() => {
                if (props.increasePage) {
                  dispatch(props.increasePage());
                  setTriggerGetEntries((currentValue) => currentValue + 1);
                }
              }}
              usesSquarePlusIcon
            />
          )}
      </CollapsableContainer>
    </MainContainer>
  );
};
