import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { Team, PaginatedResponse } from 'models';
import { getAllTeams } from 'services/teams/teams-service';
import { paginationConfig } from 'config/pagination-config';
import { SortByDirection } from 'enums';

export interface AssignToTeamModalState {
  teams: Team[];
  totalRecords: number;
  page: number;
  perPage: number;
  searchTerm: string;
  triggerTeamsUpdate: number;
}

const initialState: AssignToTeamModalState = {
  teams: [],
  totalRecords: 0,
  page: 1,
  perPage: paginationConfig.assignToTeamModalTeamsPerPage,
  searchTerm: '',
  triggerTeamsUpdate: 0,
};

export const getAllAssignToTeamModalTeamsAsync = (): AppThunk<
  Promise<PaginatedResponse<Team> | null>
> => {
  return async (dispatch, getState) => {
    const assignToTeamModalState = getState().assignToTeamModalTeam;
    const page = assignToTeamModalState.page;
    const perPage = assignToTeamModalState.perPage;
    const searchTerm = assignToTeamModalState.searchTerm;
    const response = await getAllTeams(
      searchTerm,
      'name.keyword',
      SortByDirection.ASC,
      page,
      perPage
    );
    if (page > 1) {
      dispatch(addAssignToTeamModalTeams(response.data));
    } else {
      dispatch(setAssignToTeamModalTeams(response.data));
    }
    return response.data;
  };
};

export const assignToTeamModalTeamSlice = createSlice({
  name: 'assign-to-team-modal-team',
  initialState,
  reducers: {
    setAssignToTeamModalTeams: (
      state,
      action: PayloadAction<PaginatedResponse<Team> | null>
    ) => {
      state.teams = action.payload?.records ?? [];
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    addAssignToTeamModalTeams: (
      state,
      action: PayloadAction<PaginatedResponse<Team> | null>
    ) => {
      state.teams = state.teams.concat(action.payload?.records ?? []);
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    increaseAssignToTeamModalTeamsPage: (state) => {
      state.page = state.page + 1;
    },
    resetAssignToTeamModalTeams: (state) => {
      state.teams = [];
      state.totalRecords = 0;
    },
    resetAssignToTeamModalTeamsPage: (state) => {
      state.page = 1;
    },
    setAssignToTeamModalTeamsSearchTerm: (
      state,
      action: PayloadAction<string>
    ) => {
      state.page = 1;
      state.searchTerm = action.payload;
    },
    resetAssignToTeamModalTeamsSearchTerm: (state) => {
      state.searchTerm = '';
    },
    setTriggerAssignToTeamModalTeamsUpdate: (state) => {
      state.triggerTeamsUpdate = state.triggerTeamsUpdate + 1;
    },
  },
});

export const {
  setAssignToTeamModalTeams,
  addAssignToTeamModalTeams,
  increaseAssignToTeamModalTeamsPage,
  resetAssignToTeamModalTeams,
  resetAssignToTeamModalTeamsPage,
  setAssignToTeamModalTeamsSearchTerm,
  resetAssignToTeamModalTeamsSearchTerm,
  setTriggerAssignToTeamModalTeamsUpdate,
} = assignToTeamModalTeamSlice.actions;

export const selectAssignToTeamModalTeams = (state: RootState) =>
  state.assignToTeamModalTeam.teams;
export const selectAssignToTeamModalTeamsTotalRecords = (state: RootState) =>
  state.assignToTeamModalTeam.totalRecords;
export const selectAssignToTeamModalTeamsPage = (state: RootState) =>
  state.assignToTeamModalTeam.page;
export const selectAssignToTeamModalTeamsPerPage = (state: RootState) =>
  state.assignToTeamModalTeam.perPage;
export const selectAssignToTeamModalTeamsSearchTerm = (state: RootState) =>
  state.assignToTeamModalTeam.searchTerm;
export const selectTriggerAssignToTeamModalTeamsUpdate = (state: RootState) =>
  state.assignToTeamModalTeam.triggerTeamsUpdate;

export default assignToTeamModalTeamSlice.reducer;
