import styled from '@emotion/styled';
import { HTMLAttrID } from 'models';
import { Theme } from 'styles/themes';
import { ReactComponent as ArrowLeftSecondary } from 'assets/arrow-left-secondary.svg';

interface BackButtonProps extends HTMLAttrID {
  onClick: () => void;
}

const BackButtonContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: '4px',
  border: `1px solid ${Theme.colors.grayBorder}`,
  boxSizing: 'border-box',
  borderRadius: Theme.borderRadius,
  cursor: 'pointer',
  width: '28px',
  height: '28px',
  marginRight: '10px',
});

export const BackButton = (props: BackButtonProps) => {
  return (
    <BackButtonContainer id={props.id} onClick={props.onClick}>
      <ArrowLeftSecondary id={`${props.id}ArrowLeftSecondary`} />
    </BackButtonContainer>
  );
};
