import { User } from 'models';
import { Table, Tbody, Td, Th, Thead, Tr, Row } from 'components/Table';
import { RefObject, useCallback, useRef, useState } from 'react';
import { Checkbox } from 'components/Checkbox';
import { UserStatus } from 'enums';
import styled from '@emotion/styled';
import { SortByDirectionButton } from 'components/SortByDirectionButton';
import { Body } from 'components/Typography';
import { FlexContainer } from 'styles/utils';
import { BlueChipList } from 'components/Chip';
import { EllipsedSpan } from 'components/Typography';
import { UserStatusColorMap } from 'enums/UserStatus';
import { CheckmarkTypes } from 'components/Checkbox/Checkbox';
import { OverflowYAutoContainer } from 'components/OverflowYAutoContainer';
import { ManagementTableProps } from 'pages/management/interfaces/ManagementTableProps';
import { ManagerTooltip } from 'components/ManagerTooltip';
import {
  setManagementUsersSortByDirection,
  setManagementUsersSortByProperty,
} from 'store/management-user/management-user-slice';

const NameContainer = styled(EllipsedSpan)(
  (props: { isManager?: boolean }) => ({
    display: 'inline-block',
    maxWidth: props.isManager ? '85%' : '100%',
    marginRight: props.isManager ? '12px' : '0px',
  })
);

const UserRow = (props: UserRowProps) => {
  const { user, checked } = props;
  const [isHighlighted, setHighlighted] = useState(false);
  const handleHighlightChange = useCallback(
    (highlight: boolean) => () => setHighlighted(highlight),
    [setHighlighted]
  );

  const handleCheck = useCallback(() => {
    props.onSelectRow && props.onSelectRow({ ...user, checked: !checked });
  }, [checked, user]);

  return (
    <Tr
      id={`UserManagementTableRow${user.id}`}
      onMouseEnter={handleHighlightChange(true)}
      onMouseLeave={handleHighlightChange(false)}
      onClick={handleCheck}
      isHighlighted={isHighlighted || checked}
      isFaded={props.isFaded}
      style={{ height: '48px' }}
    >
      <Td
        id={`UserManagementTableRow${user.id}CheckboxTd`}
        style={{ padding: '12px 0 12px 16px' }}
      >
        <Checkbox
          id={`UserManagementTableRow${user.id}Checkbox`}
          onChange={handleCheck}
          isChecked={!!checked}
        />
      </Td>
      <Td
        id={`UserManagementTableRow${user.id}NameTd`}
        style={{ padding: '12px 16px' }}
      >
        <FlexContainer id={`UserManagementTableRow${user.id}NameTdContainer`}>
          <NameContainer
            id={`UserManagementTableRow${user.id}NameContainer`}
            size={16}
            noMargin
            isManager={user.isManager}
          >
            {user.fullName}{' '}
          </NameContainer>
          {user.isManager && (
            <ManagerTooltip
              id={`UserManagementTableRow${user.id}isManagerTooltip`}
            />
          )}
        </FlexContainer>
      </Td>
      <Td
        id={`UserManagementTableRow${user.id}TeamsTd`}
        style={{ padding: '9.5px 16px' }}
      >
        {user.teams?.length ? (
          <BlueChipList
            id={`UserManagementTableRow${user.id}TeamsBlueChipList`}
            isSelected={checked || isHighlighted}
            items={user.teams}
            getLabel={(team) => team.name}
            cutterElement={props.cutterElementRef}
            maxWidth={275}
          />
        ) : (
          <Body
            id={`UserManagementTableRow${user.id}NoTeamAssignedMessage`}
            noMargin
            color="grayDark"
            textStyle="italic"
          >
            No team assigned
          </Body>
        )}
      </Td>
      <Td
        id={`UserManagementTableRow${user.id}StatusTd`}
        style={{ padding: '12px 16px' }}
      >
        <Body
          id={`UserManagementTableRow${user.id}Status`}
          noMargin
          textStyle={user.status === UserStatus.NoAccess ? 'italic' : undefined}
          color={UserStatusColorMap[user.status || UserStatus.Active] as any}
        >
          {user.statusText}
        </Body>
      </Td>
    </Tr>
  );
};

export const UsersTable = (props: UsersTableProps) => {
  const {
    rows,
    onSelectAll,
    onSelectRow,
    sortDirection,
    sortField,
    areSomeChecked = false,
  } = props;

  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const checkAll = useCallback(() => {
    onSelectAll && onSelectAll();
  }, []);

  const handleCheckUser = useCallback((row: Row<User>) => {
    onSelectRow && onSelectRow(row);
  }, []);

  const userRows = rows.map((u) => (
    <UserRow
      key={u.id}
      user={u}
      checked={u.checked}
      onSelectRow={handleCheckUser}
      isFaded={props.spotlitUser && props.spotlitUser.id !== u.id}
      cutterElementRef={scrollableContainerRef}
    />
  ));

  return (
    <OverflowYAutoContainer
      id="UserManagementTable"
      ref={scrollableContainerRef}
      style={{
        height: 'calc(100% - 48px)',
        width: '100%',
        marginBottom: '16px',
      }}
    >
      <Table
        id="UserManagementTable"
        style={{ position: 'relative', tableLayout: 'fixed' }}
      >
        <Thead id="UserManagementTableThead">
          <Tr id="UserManagementTableTr" style={{ height: '48px' }}>
            <Th
              id="UserManagementTableThSelectAll"
              width="24px"
              padding="12px 0 12px 16px"
            >
              <Checkbox
                id="UserManagementTableSelectAllCheckbox"
                checkmarkType={CheckmarkTypes.minusSign}
                onChange={checkAll}
                isChecked={areSomeChecked}
              />
            </Th>
            <Th id="UserManagementTableNameTh" width="32%" padding="12px 16px">
              <FlexContainer id="UserManagementTableNameThContainer">
                Name{' '}
                <SortByDirectionButton
                  id="UserManagementTableNameThSortByDirectionButton"
                  propertyToSortBy="fullName"
                  currentPropertyBeingSorted={sortField}
                  currentDirectionBeingSorted={sortDirection}
                  setSortByProperty={setManagementUsersSortByProperty}
                  setSortByDirection={setManagementUsersSortByDirection}
                />
              </FlexContainer>
            </Th>
            <Th id="UserManagementTableTeamsTh" width="46%" padding="12px 16px">
              Teams
            </Th>
            <Th
              id="UserManagementTableStatusTh"
              width="80px"
              padding="12px 16px"
            >
              <FlexContainer id="UserManagementTableStatusThContainer">
                Status
                <SortByDirectionButton
                  id="UserManagementTableStatusThSortByDirectionButton"
                  propertyToSortBy="statusText"
                  currentPropertyBeingSorted={sortField}
                  currentDirectionBeingSorted={sortDirection}
                  setSortByProperty={setManagementUsersSortByProperty}
                  setSortByDirection={setManagementUsersSortByDirection}
                />
              </FlexContainer>
            </Th>
          </Tr>
        </Thead>
        <Tbody id="UserManagementTableTbody">{userRows}</Tbody>
      </Table>
    </OverflowYAutoContainer>
  );
};

interface UserRowProps {
  user: User;
  checked?: boolean;
  onSelectRow?: (row: Row<User>) => void;
  isFaded?: boolean;
  cutterElementRef?: RefObject<Element>;
}

export interface UsersTableProps extends ManagementTableProps<User> {
  spotlitUser?: User;
}
