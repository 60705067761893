import styled from '@emotion/styled';
import { TextButton } from 'components/Button';
import { HTMLAttrID } from 'models';
import { useCallback } from 'react';
import { Theme } from 'styles/themes';
import { ReactComponent as SquarePlus } from 'assets/plus-square.svg';

interface LoadMoreButtonProps extends HTMLAttrID {
  page: number;
  perPage: number;
  totalRecords: number;
  increasePage: () => void;
  usesSquarePlusIcon?: boolean;
}

const PlusIcon = styled.span({
  fontSize: Theme.fontSize + 6,
  marginRight: '6px',
});

const SquarePlusIcon = styled(SquarePlus)({
  margin: '0 3px',
});

export const LoadMoreButton = (props: LoadMoreButtonProps) => {
  const loadMore = useCallback(() => {
    props.increasePage();
  }, [props.increasePage]);

  return (
    <>
      {!!props.page &&
        !!props.perPage &&
        props.page < Math.ceil(props.totalRecords / props.perPage) && (
          <TextButton
            id={props.id}
            color={Theme.colors.secondaryColor}
            onClick={loadMore}
            style={{ marginRight: 'auto' }}
          >
            {props.page === Math.ceil(props.totalRecords / props.perPage) - 1 &&
            props.totalRecords % props.perPage > 0 ? (
              <>
                {props.usesSquarePlusIcon ? (
                  <SquarePlusIcon id={`${props.id}SquarePlusIcon`} />
                ) : (
                  <PlusIcon id={`${props.id}PlusIcon`}>+</PlusIcon>
                )}
                Load {props.totalRecords % props.perPage} more
              </>
            ) : (
              <>
                {props.usesSquarePlusIcon ? (
                  <SquarePlusIcon id={`${props.id}SquarePlusIcon`} />
                ) : (
                  <PlusIcon id={`${props.id}PlusIcon`}>+</PlusIcon>
                )}
                Load {props.perPage} more
              </>
            )}
          </TextButton>
        )}
    </>
  );
};
