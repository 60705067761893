import styled from '@emotion/styled';
import { ChangeEvent, CSSProperties, HTMLAttributes } from 'react';
import { Theme } from 'styles/themes';
import { ReactComponent as Warning } from 'assets/warning.svg';

export interface InputProps extends HTMLAttributes<HTMLInputElement> {
  type?: string;
  placeholder: string;
  value?: string;
  setValue: React.Dispatch<string>;
  inputRef?: React.RefObject<HTMLInputElement>;
  error?: string;
  maxLength?: number;
  containerStyle?: CSSProperties;
  isDisabled?: boolean;
  title?: string;
  rightIcon?: JSX.Element;
}

const InputContainer = styled.div<{ isDisabled?: boolean }>((props) => ({
  width: '100%',
  height: '32px',
  position: 'relative',
  cursor: props.isDisabled ? 'not-allowed' : 'auto',
}));

const StyledInput = styled.input<{
  value?: string;
  hasError?: boolean;
  isDisabled?: boolean;
}>((props) => ({
  width: '100%',
  height: '100%',
  border: props.hasError
    ? `2px solid ${Theme.colors.red}`
    : `1px solid ${Theme.colors.grayBorder}`,
  borderRadius: Theme.borderRadius,
  padding: '8px',
  ':focus': {
    outline: `${Theme.colors.secondaryColor} solid 2px`,
  },
  ':not(:focus)': {
    outline: 'none',
  },
  fontSize: Theme.fontSize - 2,
  backgroundColor: props.isDisabled ? 'lightgray' : 'transparent',
  pointerEvents: props.isDisabled ? 'none' : 'auto',
}));

export const InputRightIconContainer = styled.div<{
  hasCursorPointer?: boolean;
}>((props) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  right: '12px',
  top: 0,
  bottom: 0,
  cursor: props.hasCursorPointer ? 'pointer' : 'default',
}));

export const Input: React.FC<InputProps> = (props: InputProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    props.setValue(e.target.value);

  return (
    <>
      <InputContainer
        style={props.containerStyle}
        isDisabled={props.isDisabled}
        title={props.title}
      >
        <StyledInput
          id={props.id}
          name={props.id}
          ref={props.inputRef}
          type={props.type || 'text'}
          placeholder={props.placeholder}
          value={props.value}
          onChange={handleChange}
          style={props.style}
          hasError={!!props.error}
          autoComplete={props.id}
          maxLength={props.maxLength}
          isDisabled={props.isDisabled}
        />
        {props.error ? (
          <InputRightIconContainer>
            <Warning style={{ color: Theme.colors.red }} />
          </InputRightIconContainer>
        ) : (
          props.rightIcon
        )}
        {props.rightIcon}
      </InputContainer>
      {props.error && (
        <span
          style={{
            color: Theme.colors.red,
            marginTop: '6px',
            fontSize: Theme.fontSize - 2,
          }}
        >
          {props.error}
        </span>
      )}
    </>
  );
};
