import { TitleValue } from 'pages/management/components';
import { ReactComponent as EditIcon } from 'assets/edit-smaller.svg';
import { FeedAppliedFilterEntity } from './interfaces';
import { pascalCase } from 'utils/string-formatter';

export const buildTitleField = (
  filtersCount?: number,
  withEditButton?: boolean,
  onClickEditFilters?: () => void
) => (
  <TitleValue
    id="AppliedFiltersTitle"
    key="AppliedFilters"
    title="Filters applied"
    count={filtersCount ?? 0}
    buttonIcon={withEditButton ? <EditIcon /> : undefined}
    onClickButton={withEditButton ? onClickEditFilters : () => {}}
  />
);

export const buildFilterField = (title: string, names: string[]) => {
  return (
    <TitleValue
      id={`FeedAppliedFilters${pascalCase(title)}`}
      key={pascalCase(title)}
      title={title}
      value={`(${names.join(', ')})`}
    />
  );
};

export const buildFilterFieldByIds = (
  title: string,
  filterIds: number[],
  filters: FeedAppliedFilterEntity[]
) => {
  const names = filterIds.map(
    (id) => filters.find((filter) => Number(filter.id) === id)?.name
  );
  return buildFilterField(title, names.filter((x) => !!x) as string[]);
};
