import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { Input } from 'components/Input';
import { OutputConsole } from 'components/OutputConsole';
import { useCallback, useState } from 'react';
import { restoreDevelopment } from 'services/developments/developments-service';
import { Theme } from 'styles/themes';
import { HttpResponse } from 'utils/http/HttpResponse';

interface RestoreDevelopmentCardProps {}

export const RestoreDevelopmentCard = (props: RestoreDevelopmentCardProps) => {
  const [outputs, setOutputs] = useState<string[]>([]);
  const [developmentId, setDevelopmentId] = useState<string>('');
  const [isProcessOnGoing, setIsProcessOnGoing] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handlePrimaryAction = useCallback(async () => {
    try {
      setErrorMessage('');
      if (!developmentId) {
        setErrorMessage('Development ID is required.');
        return;
      }

      if (Number.isNaN(Number(developmentId))) {
        setErrorMessage('Development ID must be a number.');
        return;
      }

      setIsProcessOnGoing(true);
      setOutputs([]);
      const response = await restoreDevelopment(Number(developmentId));
      if (!!response.data) {
        setOutputs((currentValue) => [...currentValue, response.data!]);
      }
      setDevelopmentId('');
    } catch (error) {
      const httpResponse = error as HttpResponse<null>;
      if (!httpResponse.success && httpResponse.error) {
        setErrorMessage(httpResponse.error.message);
      }
    } finally {
      setIsProcessOnGoing(false);
    }
  }, [developmentId]);

  return (
    <Card>
      <span
        style={{
          fontSize: Theme.fontSize,
          fontWeight: 'bold',
          color: Theme.colors.primaryColor,
          marginBottom: '16px',
        }}
      >
        Restore Development
      </span>
      <div>
        <div style={{ width: '100%', marginBottom: '16px' }}>
          <Input
            placeholder="Development ID..."
            type="number"
            value={developmentId}
            setValue={setDevelopmentId}
            error={errorMessage}
          />
        </div>
        <Button
          onClick={() => {
            handlePrimaryAction();
          }}
          style={{ marginBottom: '16px' }}
          isDisabled={isProcessOnGoing}
        >
          Run
        </Button>
        <div>
          <span
            style={{
              marginBottom: '16px',
              display: 'block',
            }}
          >
            Output
          </span>
          <OutputConsole outputs={outputs} height="300px" />
        </div>
      </div>
    </Card>
  );
};
