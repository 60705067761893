import styled from '@emotion/styled';
import { Theme } from './themes';
import { Property } from 'csstype';

export const BoldText = styled.span<{ color?: string }>(({ color }) => ({
  fontWeight: 'bold',
  color,
}));

export const ItalicText = styled.span({
  fontStyle: 'italic',
});

export const CursorPointer = styled.span<{ isDisabled?: boolean }>(
  ({ isDisabled }) => ({
    cursor: isDisabled ? 'default' : 'pointer',
  })
);

export const ErrorText = styled.span({
  color: Theme.colors.red,
  fontSize: Theme.fontSize,
});

export const FlexContainer = styled.div<{
  display?: 'flex' | 'inline-flex';
  flexDirection?: Property.FlexDirection;
  flexWrap?: 'wrap' | 'nowrap';
}>((props) => ({
  display: props.display ? props.display : 'flex',
  flexDirection: props.flexDirection ? props.flexDirection : 'row',
  flexWrap: props.flexWrap ? props.flexWrap : 'wrap',
}));

export const VerticalDivider = styled.div<{
  color?: string;
  height?: string;
  thickness?: string;
}>((props) => ({
  width: '0px',
  height: props.height ? props.height : 'auto',
  border: `${props.thickness ? props.thickness : '1px'} solid ${
    props.color ? props.color : Theme.colors.grayBorder
  }`,
  borderRadius: Theme.borderRadius,
}));

export const HorizontalDivider = styled.div<{
  color?: string;
  thickness?: string;
}>((props) => ({
  width: '100%',
  height: '0px',
  border: `${props.thickness ? props.thickness : '1px'} solid ${
    props.color ? props.color : Theme.colors.grayBorder
  }`,
  borderRadius: Theme.borderRadius,
}));
