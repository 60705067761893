import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { getCountries } from 'services/countries/countries-service';
import { Country, PaginatedResponse } from 'models';
import { paginationConfig } from 'config/pagination-config';

export interface NewFeedFilterCountryState {
  countries: Country[];
  totalRecords: number;
  searchTerm: string;
  page: number;
  perPage: number;
}

const initialState: NewFeedFilterCountryState = {
  countries: [],
  totalRecords: 0,
  searchTerm: '',
  page: 1,
  perPage: paginationConfig.countriesPerPage,
};

export const getAllNewFeedFilterCountriesAsync = (): AppThunk<
  Promise<PaginatedResponse<Country> | null>
> => {
  return async (dispatch, getState) => {
    const searchTerm = getState().newFeedFilterCountry.searchTerm;
    const page = getState().newFeedFilterCountry.page;
    const perPage = getState().newFeedFilterCountry.perPage;
    const response = await getCountries(searchTerm, page, perPage);
    if (page > 1) {
      dispatch(addNewFeedFilterCountries(response.data));
    } else {
      dispatch(setNewFeedFilterCountries(response.data));
    }
    return response.data;
  };
};

export const newFeedFilterCountrySlice = createSlice({
  name: 'new-feed-filter-country',
  initialState,
  reducers: {
    setNewFeedFilterCountries: (
      state,
      action: PayloadAction<PaginatedResponse<Country> | null>
    ) => {
      state.countries = action.payload?.records ?? [];
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    addNewFeedFilterCountries: (
      state,
      action: PayloadAction<PaginatedResponse<Country> | null>
    ) => {
      state.countries = state.countries.concat(action.payload?.records ?? []);
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    setNewFeedFilterCountriesSearchTerm: (
      state,
      action: PayloadAction<string>
    ) => {
      state.page = 1;
      state.searchTerm = action.payload;
    },
    increaseNewFeedFilterCountriesPage: (state) => {
      state.page = state.page + 1;
    },
    resetNewFeedFilterCountriesPage: (state) => {
      state.page = 1;
    },
    resetNewFeedFilterCountriesSearchTerm: (state) => {
      state.searchTerm = '';
    },
  },
});

export const {
  setNewFeedFilterCountries,
  addNewFeedFilterCountries,
  setNewFeedFilterCountriesSearchTerm,
  increaseNewFeedFilterCountriesPage,
  resetNewFeedFilterCountriesPage,
  resetNewFeedFilterCountriesSearchTerm,
} = newFeedFilterCountrySlice.actions;

export const selectNewFeedFilterCountries = (state: RootState) =>
  state.newFeedFilterCountry.countries;
export const selectNewFeedFilterCountriesTotalRecords = (state: RootState) =>
  state.newFeedFilterCountry.totalRecords;
export const selectNewFeedFilterCountriesSearchTerm = (state: RootState) =>
  state.newFeedFilterCountry.searchTerm;
export const selectNewFeedFilterCountriesPage = (state: RootState) =>
  state.newFeedFilterCountry.page;
export const selectNewFeedFilterCountriesPerPage = (state: RootState) =>
  state.newFeedFilterCountry.perPage;

export default newFeedFilterCountrySlice.reducer;
