import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { getAuthoritiesWithDevelopmentCountGreaterThanZero } from 'services/authorities/authorities-service';
import { Authority, PaginatedResponse } from 'models';
import { paginationConfig } from 'config/pagination-config';
import { filterKeys } from 'config/filter-keys';
import { isArraySubsetOfAnother } from 'utils/array';

export interface HomeSidebarFilterAuthorityState {
  authorities: Authority[];
  totalRecords: number;
  searchTerm: string;
  page: number;
  previousPage: number;
  perPage: number;
  isConsecutiveFetch: boolean;
}

const initialState: HomeSidebarFilterAuthorityState = {
  authorities: [],
  totalRecords: 0,
  searchTerm: '',
  page: 1,
  previousPage: 1,
  perPage: paginationConfig.authoritiesPerPage,
  isConsecutiveFetch: false,
};

export const getAllHomeSidebarFilterAuthoritiesAsync = (): AppThunk<
  Promise<PaginatedResponse<Authority> | null>
> => {
  return async (dispatch, getState) => {
    const selectedEnvironment = getState().environment.selectedEnvironment;
    const searchTerm = getState().homeSidebarFilterAuthority.searchTerm;
    const page = getState().homeSidebarFilterAuthority.page;
    const previousPage = getState().homeSidebarFilterAuthority.previousPage;
    const perPage = getState().homeSidebarFilterAuthority.perPage;
    const activeFilters = getState().homeSidebarFilter.activeFilters;
    const ids = getState().feedPicker.appliedFeed?.authorityIds;
    const isConsecutiveFetch =
      getState().homeSidebarFilterAuthority.isConsecutiveFetch;

    const getAuthorities = async (pageNumber: number) => {
      return await getAuthoritiesWithDevelopmentCountGreaterThanZero(
        selectedEnvironment,
        searchTerm,
        pageNumber,
        perPage,
        activeFilters,
        ids
      );
    };

    if (isConsecutiveFetch) {
      dispatch(setIsConsecutiveAuthoritiesFetch(false));
    }

    if (
      isConsecutiveFetch &&
      activeFilters[filterKeys.authority] &&
      activeFilters[filterKeys.authority].length &&
      !ids?.length
    ) {
      let currentPage = 0;
      let isLastPage = false;
      let allAuthorityIds: number[] = [];
      let areAllCheckedFiltersDisplayed = false;
      let response;
      let incomingAuthorities: Authority[] = [];
      do {
        response = await getAuthorities(++currentPage);
        if (!response.data) {
          return null;
        }
        isLastPage =
          currentPage >= Math.ceil(response.data.totalRecords / perPage);
        incomingAuthorities = incomingAuthorities.concat(response.data.records);
        allAuthorityIds = allAuthorityIds.concat(
          response.data.records.map((x) => x.id!)
        );
        areAllCheckedFiltersDisplayed = isArraySubsetOfAnother(
          activeFilters[filterKeys.authority] as number[],
          allAuthorityIds
        );
      } while (!areAllCheckedFiltersDisplayed && !isLastPage);
      response.data.records = incomingAuthorities;
      dispatch(setHomeSidebarFilterAuthoritiesPage(currentPage));
      dispatch(setHomeSidebarFilterAuthorities(response.data));
      return response.data;
    } else {
      const response = await getAuthorities(page);
      if (page === 1) {
        dispatch(setHomeSidebarFilterAuthorities(response.data));
      } else {
        if (page > previousPage) {
          dispatch(addHomeSidebarFilterAuthorities(response.data));
        }
      }
      return response.data;
    }
  };
};

export const homeSidebarFilterAuthoritySlice = createSlice({
  name: 'home-sidebar-filter-authority',
  initialState,
  reducers: {
    setHomeSidebarFilterAuthorities: (
      state,
      action: PayloadAction<PaginatedResponse<Authority> | null>
    ) => {
      state.authorities = action.payload?.records ?? [];
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    addHomeSidebarFilterAuthorities: (
      state,
      action: PayloadAction<PaginatedResponse<Authority> | null>
    ) => {
      state.authorities = state.authorities.concat(
        action.payload?.records ?? []
      );
      state.totalRecords = action.payload?.totalRecords ?? 0;
      state.previousPage = state.page;
    },
    setHomeSidebarFilterAuthoritiesSearchTerm: (
      state,
      action: PayloadAction<string>
    ) => {
      state.page = 1;
      state.searchTerm = action.payload;
    },
    setHomeSidebarFilterAuthoritiesPage: (
      state,
      action: PayloadAction<number>
    ) => {
      state.page = action.payload;
    },
    increaseHomeSidebarFilterAuthoritiesPage: (state) => {
      state.page = state.page + 1;
    },
    resetHomeSidebarFilterAuthoritiesPage: (state) => {
      state.page = 1;
      state.previousPage = 1;
    },
    setIsConsecutiveAuthoritiesFetch: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isConsecutiveFetch = action.payload;
    },
  },
});

export const {
  setHomeSidebarFilterAuthorities,
  addHomeSidebarFilterAuthorities,
  setHomeSidebarFilterAuthoritiesSearchTerm,
  setHomeSidebarFilterAuthoritiesPage,
  increaseHomeSidebarFilterAuthoritiesPage,
  resetHomeSidebarFilterAuthoritiesPage,
  setIsConsecutiveAuthoritiesFetch,
} = homeSidebarFilterAuthoritySlice.actions;

export const selectHomeSidebarFilterAuthorities = (state: RootState) =>
  state.homeSidebarFilterAuthority.authorities;
export const selectHomeSidebarFilterAuthoritiesTotalRecords = (
  state: RootState
) => state.homeSidebarFilterAuthority.totalRecords;
export const selectHomeSidebarFilterAuthoritiesSearchTerm = (
  state: RootState
) => state.homeSidebarFilterAuthority.searchTerm;
export const selectHomeSidebarFilterAuthoritiesPage = (state: RootState) =>
  state.homeSidebarFilterAuthority.page;
export const selectHomeSidebarFilterAuthoritiesPerPage = (state: RootState) =>
  state.homeSidebarFilterAuthority.perPage;

export default homeSidebarFilterAuthoritySlice.reducer;
