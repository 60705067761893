import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { Feed, PaginatedResponse } from 'models';
import { getAllFeeds } from 'services/feeds/feeds-service';
import { paginationConfig } from 'config/pagination-config';
import { SortByDirection } from 'enums';
import { updateElementInArrayIfExists } from 'utils/array';

export interface AssignToTeamSidebarState {
  feeds: Feed[];
  totalRecords: number;
  page: number;
  perPage: number;
  searchTerm: string;
  selectedFeeds: { [key: number]: Feed };
}

const initialState: AssignToTeamSidebarState = {
  feeds: [],
  totalRecords: 0,
  page: 1,
  perPage: paginationConfig.feedPickerFeedsPerPage,
  searchTerm: '',
  selectedFeeds: {},
};

export const getAllAssignToTeamSidebarFeedsAsync = (): AppThunk<
  Promise<PaginatedResponse<Feed> | null>
> => {
  return async (dispatch, getState) => {
    const assignToTeamSidebarState = getState().assignToTeamSidebarFeed;
    const page = assignToTeamSidebarState.page;
    const perPage = assignToTeamSidebarState.perPage;
    const searchTerm = assignToTeamSidebarState.searchTerm;
    const response = await getAllFeeds(
      searchTerm,
      page,
      perPage,
      'name.keyword',
      SortByDirection.ASC
    );
    if (page > 1) {
      dispatch(addAssignToTeamSidebarFeeds(response.data));
    } else {
      dispatch(setAssignToTeamSidebarFeeds(response.data));
    }
    return response.data;
  };
};

export const assignToTeamSidebarFeedSlice = createSlice({
  name: 'assign-to-team-sidebar-feed',
  initialState,
  reducers: {
    setAssignToTeamSidebarFeeds: (
      state,
      action: PayloadAction<PaginatedResponse<Feed> | null>
    ) => {
      state.feeds = action.payload?.records ?? [];
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    addAssignToTeamSidebarFeeds: (
      state,
      action: PayloadAction<PaginatedResponse<Feed> | null>
    ) => {
      state.feeds = state.feeds.concat(action.payload?.records ?? []);
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    updateAssignToTeamSidebarFeeds: (state, action: PayloadAction<Feed[]>) => {
      const feeds = [...action.payload];
      feeds.forEach((feed) => {
        state.feeds = updateElementInArrayIfExists(state.feeds, feed, 'id');
      });
    },
    increaseAssignToTeamSidebarFeedsPage: (state) => {
      state.page = state.page + 1;
    },
    resetAssignToTeamSidebarFeeds: (state) => {
      state.feeds = [];
      state.totalRecords = 0;
    },
    resetAssignToTeamSidebarFeedsPage: (state) => {
      state.page = 1;
    },
    setAssignToTeamSidebarFeedsSearchTerm: (
      state,
      action: PayloadAction<string>
    ) => {
      state.page = 1;
      state.searchTerm = action.payload;
    },
    resetAssignToTeamSidebarFeedsSearchTerm: (state) => {
      state.searchTerm = '';
    },
    selectAssignToTeamSidebarFeed: (state, action: PayloadAction<Feed>) => {
      const feed = action.payload;
      if (state.selectedFeeds[feed.id || 0]) {
        delete state.selectedFeeds[feed.id || 0];
      } else {
        state.selectedFeeds[feed.id || 0] = feed;
      }
    },
    resetAssignToTeamSidebarFeedsAll: (state) => {
      state.feeds = [];
      state.totalRecords = 0;
      state.page = 1;
      state.searchTerm = '';
      state.selectedFeeds = {};
    },
  },
});

export const {
  setAssignToTeamSidebarFeeds,
  addAssignToTeamSidebarFeeds,
  updateAssignToTeamSidebarFeeds,
  increaseAssignToTeamSidebarFeedsPage,
  resetAssignToTeamSidebarFeeds,
  resetAssignToTeamSidebarFeedsPage,
  setAssignToTeamSidebarFeedsSearchTerm,
  resetAssignToTeamSidebarFeedsSearchTerm,
  selectAssignToTeamSidebarFeed,
  resetAssignToTeamSidebarFeedsAll,
} = assignToTeamSidebarFeedSlice.actions;

export const selectAssignToTeamSidebarFeeds = (state: RootState) =>
  state.assignToTeamSidebarFeed.feeds;
export const selectAssignToTeamSidebarFeedsTotalRecords = (state: RootState) =>
  state.assignToTeamSidebarFeed.totalRecords;
export const selectAssignToTeamSidebarFeedsPage = (state: RootState) =>
  state.assignToTeamSidebarFeed.page;
export const selectAssignToTeamSidebarFeedsPerPage = (state: RootState) =>
  state.assignToTeamSidebarFeed.perPage;
export const selectAssignToTeamSidebarFeedsSearchTerm = (state: RootState) =>
  state.assignToTeamSidebarFeed.searchTerm;
export const selectAssignToTeamSidebarSelectedFeeds = (state: RootState) =>
  state.assignToTeamSidebarFeed.selectedFeeds;

export default assignToTeamSidebarFeedSlice.reducer;
