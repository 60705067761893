import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import {
  DefaultFeedUserView,
  Environment,
  Feed,
  PaginatedResponse,
} from 'models';
import {
  getCurrentUserFeeds,
  getUserLastViewedFeed,
  viewDefaultFeed,
  viewFeed,
} from 'services/feeds/feeds-service';
import { paginationConfig } from 'config/pagination-config';
import { localStorageKeys } from 'config/local-storage-keys';
import { resetHomeSidebarFilterDocumentTypesPage } from 'store/home-sidebar-filter/home-sidebar-filter-document-type-slice';
import { resetHomeSidebarFilterLanguagesPage } from 'store/home-sidebar-filter/home-sidebar-filter-language-slice';
import { resetHomeSidebarFilterCountriesPage } from 'store/home-sidebar-filter/home-sidebar-filter-country-slice';
import { resetHomeSidebarFilterRegionsPage } from 'store/home-sidebar-filter/home-sidebar-filter-region-slice';
import { resetHomeSidebarFilterAuthoritiesPage } from 'store/home-sidebar-filter/home-sidebar-filter-authority-slice';
import { resetHomeSidebarFilterSubcategoriesPage } from 'store/home-sidebar-filter/home-sidebar-filter-subcategory-slice';
import { setTriggerGetAllFilterSetEntries } from 'store/home-sidebar-filter/home-sidebar-filter-slice';

export interface FeedPickerState {
  feeds: Feed[];
  totalRecords: number;
  page: number;
  perPage: number;
  searchTerm: string;
  appliedFeed: Feed | null;
  lastViewedFeed: Feed | null;
  feedToEdit: Feed | null;
  homeWatchesAppliedFeed: boolean;
}

const initialState: FeedPickerState = {
  feeds: [],
  totalRecords: 0,
  page: 1,
  perPage: paginationConfig.feedPickerFeedsPerPage,
  searchTerm: '',
  appliedFeed: null,
  lastViewedFeed: null,
  feedToEdit: null,
  homeWatchesAppliedFeed: true,
};

export const getCurrentUserFeedsAsync = (): AppThunk<
  Promise<PaginatedResponse<Feed> | null>
> => {
  return async (dispatch, getState) => {
    const feedPickerState = getState().feedPicker;
    const page = feedPickerState.page;
    const perPage = feedPickerState.perPage;
    const searchTerm = feedPickerState.searchTerm;
    const response = await getCurrentUserFeeds(page, perPage, searchTerm);
    if (page > 1) {
      dispatch(addFeeds(response.data));
    } else {
      dispatch(setFeeds(response.data));
    }
    return response.data;
  };
};

export const viewFeedAsync = (
  feedId: number
): AppThunk<Promise<Feed | null>> => {
  return async (dispatch, getState) => {
    const authenticationResponse =
      getState().authentication.authenticationResponse;
    const userId = authenticationResponse?.id;
    const selectedEnvironment = JSON.parse(
      atob(
        localStorage.getItem(localStorageKeys.selectedEnvironment) ||
          btoa('null')
      )
    ) as Environment | undefined;
    if (feedId && userId) {
      const response = await viewFeed(feedId, userId, selectedEnvironment?.id);
      return response.data;
    } else {
      return null;
    }
  };
};

export const viewDefaultFeedAsync = (
  feed: Feed
): AppThunk<Promise<DefaultFeedUserView | null>> => {
  return async (dispatch, getState) => {
    const authenticationResponse =
      getState().authentication.authenticationResponse;
    const userId = authenticationResponse?.id;
    const selectedEnvironment = JSON.parse(
      atob(
        localStorage.getItem(localStorageKeys.selectedEnvironment) ||
          btoa('null')
      )
    ) as Environment | undefined;
    if (userId) {
      const response = await viewDefaultFeed(userId, selectedEnvironment?.id);
      return response.data;
    } else {
      return null;
    }
  };
};

export const getUserLastViewedFeedAsync = (): AppThunk<
  Promise<Feed | null>
> => {
  return async (dispatch, getState) => {
    const response = (await getUserLastViewedFeed()).data;
    if (response) {
      dispatch(setLastViewedFeed(response));
    }
    return response;
  };
};

export const selectFeedAsync = (feed: Feed): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    if (feed.id) {
      if (feed.id !== Number.MAX_SAFE_INTEGER) {
        await dispatch(viewFeedAsync(feed.id));
      } else {
        await dispatch(viewDefaultFeedAsync(feed));
      }
      dispatch(setHomeWatchesAppliedFeed(true));
      dispatch(resetHomeSidebarFilterLanguagesPage());
      dispatch(resetHomeSidebarFilterCountriesPage());
      dispatch(resetHomeSidebarFilterRegionsPage());
      dispatch(resetHomeSidebarFilterAuthoritiesPage());
      dispatch(resetHomeSidebarFilterSubcategoriesPage());
      dispatch(resetHomeSidebarFilterDocumentTypesPage());
      dispatch(setSelectedFeed(feed));
      dispatch(setTriggerGetAllFilterSetEntries());
      dispatch(resetFeedsPage());
      dispatch(resetFeedsSearchTerm());
    }
  };
};

export const feedPickerSlice = createSlice({
  name: 'feed-picker',
  initialState,
  reducers: {
    setFeeds: (
      state,
      action: PayloadAction<PaginatedResponse<Feed> | null>
    ) => {
      state.feeds = action.payload?.records ?? [];
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    addFeeds: (
      state,
      action: PayloadAction<PaginatedResponse<Feed> | null>
    ) => {
      state.feeds = state.feeds.concat(action.payload?.records ?? []);
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    increaseFeedsPage: (state) => {
      state.page = state.page + 1;
    },
    resetFeedsPage: (state) => {
      state.page = 1;
    },
    setFeedsSearchTerm: (state, action: PayloadAction<string>) => {
      state.page = 1;
      state.searchTerm = action.payload;
    },
    setSelectedFeed: (state, action: PayloadAction<Feed | null>) => {
      state.appliedFeed = action.payload;
    },
    setLastViewedFeed: (state, action: PayloadAction<Feed | null>) => {
      state.lastViewedFeed = action.payload;
    },
    setFeedPickerFeedToEdit: (state, action: PayloadAction<Feed | null>) => {
      state.feedToEdit = action.payload;
    },
    resetFeedsSearchTerm: (state) => {
      state.searchTerm = '';
    },
    setHomeWatchesAppliedFeed: (state, action: PayloadAction<boolean>) => {
      state.homeWatchesAppliedFeed = action.payload;
    },
  },
});

export const {
  setFeeds,
  addFeeds,
  increaseFeedsPage,
  resetFeedsPage,
  setFeedsSearchTerm,
  setSelectedFeed,
  setLastViewedFeed,
  setFeedPickerFeedToEdit,
  resetFeedsSearchTerm,
  setHomeWatchesAppliedFeed,
} = feedPickerSlice.actions;

export const selectFeedPickerFeeds = (state: RootState) =>
  state.feedPicker.feeds;
export const selectFeedPickerFeedsTotalRecords = (state: RootState) =>
  state.feedPicker.totalRecords;
export const selectFeedPickerFeedsPage = (state: RootState) =>
  state.feedPicker.page;
export const selectFeedPickerFeedsPerPage = (state: RootState) =>
  state.feedPicker.perPage;
export const selectFeedPickerFeedsSearchTerm = (state: RootState) =>
  state.feedPicker.searchTerm;
export const selectFeedPickerAppliedFeed = (state: RootState) =>
  state.feedPicker.appliedFeed;
export const selectFeedPickerLastViewedFeed = (state: RootState) =>
  state.feedPicker.lastViewedFeed;
export const selectFeedPickerFeedToEdit = (state: RootState) =>
  state.feedPicker.feedToEdit;
export const selectHomeWatchesAppliedFeed = (state: RootState) =>
  state.feedPicker.homeWatchesAppliedFeed;

export default feedPickerSlice.reducer;
