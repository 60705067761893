import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { getSubcategories } from 'services/subcategories/subcategories-service';
import { Subcategory, PaginatedResponse } from 'models';
import { paginationConfig } from 'config/pagination-config';

export interface NewFeedFilterSubcategoryState {
  subcategories: Subcategory[];
  totalRecords: number;
  searchTerm: string;
  page: number;
  perPage: number;
}

const initialState: NewFeedFilterSubcategoryState = {
  subcategories: [],
  totalRecords: 0,
  searchTerm: '',
  page: 1,
  perPage: paginationConfig.subcategoriesPerPage,
};

export const getAllNewFeedFilterSubcategoriesAsync = (): AppThunk<
  Promise<PaginatedResponse<Subcategory> | null>
> => {
  return async (dispatch, getState) => {
    const searchTerm = getState().newFeedFilterSubcategory.searchTerm;
    const page = getState().newFeedFilterSubcategory.page;
    const perPage = getState().newFeedFilterSubcategory.perPage;
    const response = await getSubcategories(searchTerm, page, perPage);
    if (page > 1) {
      dispatch(addNewFeedFilterSubcategories(response.data));
    } else {
      dispatch(setNewFeedFilterSubcategories(response.data));
    }
    return response.data;
  };
};

export const newFeedFilterSubcategorySlice = createSlice({
  name: 'new-feed-filter-subcategory',
  initialState,
  reducers: {
    setNewFeedFilterSubcategories: (
      state,
      action: PayloadAction<PaginatedResponse<Subcategory> | null>
    ) => {
      state.subcategories = action.payload?.records ?? [];
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    addNewFeedFilterSubcategories: (
      state,
      action: PayloadAction<PaginatedResponse<Subcategory> | null>
    ) => {
      state.subcategories = state.subcategories.concat(
        action.payload?.records ?? []
      );
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    setNewFeedFilterSubcategoriesSearchTerm: (
      state,
      action: PayloadAction<string>
    ) => {
      state.page = 1;
      state.searchTerm = action.payload;
    },
    increaseNewFeedFilterSubcategoriesPage: (state) => {
      state.page = state.page + 1;
    },
    resetNewFeedFilterSubcategoriesPage: (state) => {
      state.page = 1;
    },
    resetNewFeedFilterSubcategoriesSearchTerm: (state) => {
      state.searchTerm = '';
    },
  },
});

export const {
  setNewFeedFilterSubcategories,
  addNewFeedFilterSubcategories,
  setNewFeedFilterSubcategoriesSearchTerm,
  increaseNewFeedFilterSubcategoriesPage,
  resetNewFeedFilterSubcategoriesPage,
  resetNewFeedFilterSubcategoriesSearchTerm,
} = newFeedFilterSubcategorySlice.actions;

export const selectNewFeedFilterSubcategories = (state: RootState) =>
  state.newFeedFilterSubcategory.subcategories;
export const selectNewFeedFilterSubcategoriesTotalRecords = (
  state: RootState
) => state.newFeedFilterSubcategory.totalRecords;
export const selectNewFeedFilterSubcategoriesSearchTerm = (state: RootState) =>
  state.newFeedFilterSubcategory.searchTerm;
export const selectNewFeedFilterSubcategoriesPage = (state: RootState) =>
  state.newFeedFilterSubcategory.page;
export const selectNewFeedFilterSubcategoriesPerPage = (state: RootState) =>
  state.newFeedFilterSubcategory.perPage;

export default newFeedFilterSubcategorySlice.reducer;
