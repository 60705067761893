export const downloadFile = (blob: Blob, fileName: string | null): void => {
  const blobURL = URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  anchor.href = blobURL;
  anchor.style.display = 'none';
  if (fileName) {
    anchor.download = fileName;
  }
  document.body.appendChild(anchor);
  anchor.click();
  anchor.remove();
};
