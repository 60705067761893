import { Alert } from 'components/Alert';
import { Header } from 'components/Header';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { displayToast } from 'store/toast/toast-slice';
import { CursorPointer, FlexContainer } from 'styles/utils';
import { copyToClipboard } from 'utils/copy-to-clipboard';
import { ReactComponent as ClipWhite } from 'assets/clip-white.svg';
import { ReactComponent as Options } from 'assets/options.svg';
import { Searchbar } from 'components/Searchbar';
import { SaveDevelopmentButton } from 'components/SaveDevelopmentButton';
import {
  selectDevelopmentDetail,
  selectDevelopmentDetailSearchTerm,
  setDevelopmentDetailSearchTerm,
} from 'store/development-detail/development-detail-slice';
import { Development } from 'models';
import { setDevelopmentDetail } from 'store/development-detail/development-detail-slice';
import {
  selectDevelopments,
  selectDevelopmentsTotalRecords,
  setDevelopments,
  updateDevelopment,
} from 'store/development/development-slice';
import { selectActiveFilters } from 'store/home-sidebar-filter/home-sidebar-filter-slice';
import { developmentKeys } from 'config/development-keys';
import { Theme } from 'styles/themes';
import { ReactComponent as HistoryGray } from 'assets/history-gray.svg';
import { ReactComponent as HistorySecondary } from 'assets/history-secondary.svg';
import { ReactComponent as EyeGray } from 'assets/eye-gray.svg';
import { ReactComponent as EyeSecondary } from 'assets/eye-secondary.svg';
import { UsersReviewing } from './components/UsersReviewing';
import {
  Dropdown,
  DropdownOption,
  DropdownOptionButton,
  DropdownOptionLabel,
  DropdownOptionsContainer,
} from 'components/Dropdown';
import { ReactComponent as Copy } from 'assets/copy.svg';
import { ReactComponent as ExternalLink } from 'assets/external-link.svg';
import { ReactComponent as Export } from 'assets/export.svg';
import { getPdf } from 'services/developments/developments-service';
import { ArrowDirections, Tooltip } from 'components/Tooltip';
import styled from '@emotion/styled';

interface DetailSubHeaderProps {
  isPdfRendering: boolean;
  setIsReviewHistoryDisplayed: Dispatch<SetStateAction<boolean>>;
  setIsRecentlyViewedDevelopmentsModalDisplayed: Dispatch<
    SetStateAction<boolean>
  >;
  developmentSourceUrl: string | undefined;
  developmentId: number | undefined;
  developmentExternalId: string | undefined;
}

const TooltipContainer = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});

export const DetailSubHeader = (props: DetailSubHeaderProps) => {
  const dispatch = useAppDispatch();
  const developments = useAppSelector(selectDevelopments);
  const developmentsTotalRecords = useAppSelector(
    selectDevelopmentsTotalRecords
  );
  const development = useAppSelector(selectDevelopmentDetail);
  const searchTerm = useAppSelector(selectDevelopmentDetailSearchTerm);
  const [searchTermAfterRendering, setSearchTermAfterRendering] = useState('');
  const activeFilters = useAppSelector(selectActiveFilters);
  const [
    isRecentlyViewedDevelopmentsHovered,
    setIsRecentlyViewedDevelopmentsHovered,
  ] = useState(false);
  const [isHistoryHovered, setIsHistoryHovered] = useState(false);
  const [isShareHovered, setIsShareHovered] = useState(false);

  const [
    recentlyViewedButtonTooltipContainer,
    setRecentlyViewedButtonTooltipContainer,
  ] = useState<HTMLSpanElement | null>(null);
  const [
    reviewHistoryButtonTooltipContainer,
    setReviewHistoryButtonTooltipContainer,
  ] = useState<HTMLSpanElement | null>(null);
  const [shareButtonTooltipContainer, setShareButtonTooltipContainer] =
    useState<HTMLSpanElement | null>(null);

  const [isShareDropdownOpen, setIsShareDropdownOpen] = useState(false);
  const shareDropdownContainerRef = useRef(null);

  useEffect(() => {
    if (!props.isPdfRendering) {
      setSearchTermAfterRendering(searchTerm);
    }
  }, [props.isPdfRendering, searchTerm]);

  const handleAfterSave = (
    development: Development | null,
    isDevelopmentSavedByUser: boolean
  ): void => {
    if (development) {
      dispatch(setDevelopmentDetail(development));
      if (
        activeFilters[developmentKeys.savedByUserIds] &&
        activeFilters[developmentKeys.savedByUserIds].length &&
        !isDevelopmentSavedByUser &&
        developmentsTotalRecords
      ) {
        dispatch(
          setDevelopments({
            records: developments.filter((x) => x.id !== development.id),
            totalRecords: developmentsTotalRecords - 1,
          })
        );
      } else {
        dispatch(updateDevelopment(development));
      }
    }
  };

  const handleCopyLink = (): void => {
    copyToClipboard(window.location.href);
    dispatch(
      displayToast({
        content: (
          <Alert>
            <span>
              <ClipWhite style={{ marginRight: '7px' }} />
              Link copied to clipboard!
            </span>
          </Alert>
        ),
        rightDistance: 300,
      })
    );
  };

  const handleGoToSource = (): void => {
    window.open(props.developmentSourceUrl);
  };

  const handleDownloadPdf = () => {
    if (props.developmentId && props.developmentExternalId) {
      getPdf(props.developmentId, props.developmentExternalId);
    }
  };

  return (
    <Header id="DevelopmentDetailSubHeader" hasBorderRight>
      <div
        id="DevelopmentDetailSubHeaderSearchbarOuterContainer"
        style={{ flex: '1 1 auto', marginRight: '10%' }}
      >
        <Searchbar
          id="DevelopmentDetailSubHeaderSearchbar"
          value={searchTermAfterRendering}
          setValue={(value) => {
            dispatch(setDevelopmentDetailSearchTerm(value));
          }}
          placeholder={
            props.isPdfRendering
              ? 'Search will be available after PDF finishes rendering...'
              : 'Search in development...'
          }
          isDisabled={props.isPdfRendering}
        />
      </div>
      <CursorPointer
        id="DevelopmentDetailSubHeaderRecentlyViewedButton"
        onClick={() => {
          props.setIsRecentlyViewedDevelopmentsModalDisplayed(true);
        }}
        onMouseOver={() => setIsRecentlyViewedDevelopmentsHovered(true)}
        onMouseLeave={() => setIsRecentlyViewedDevelopmentsHovered(false)}
        style={{ marginRight: '16px', height: '16px', position: 'relative' }}
      >
        {!isRecentlyViewedDevelopmentsHovered && (
          <EyeGray id="DevelopmentDetailSubHeaderEyeGrayIcon" />
        )}
        {isRecentlyViewedDevelopmentsHovered && (
          <EyeSecondary id="DevelopmentDetailSubHeaderEyeSecondaryIcon" />
        )}
        <TooltipContainer
          ref={(recentlyViewedButtonTooltipContainer) =>
            setRecentlyViewedButtonTooltipContainer(
              recentlyViewedButtonTooltipContainer
            )
          }
        >
          <Tooltip
            id={`DevelopmentDetailSubHeaderRecentlyViewedTooltip`}
            relativeToElement={recentlyViewedButtonTooltipContainer}
            hasArrow
            arrowDirection={ArrowDirections.Up}
          >
            <span
              id={`DevelopmentDetailSubHeaderRecentlyViewedTooltipSpan`}
              style={{ whiteSpace: 'nowrap' }}
            >
              Recently Viewed
            </span>
          </Tooltip>
        </TooltipContainer>
      </CursorPointer>
      <div
        id="DevelopmentDetailSubHeaderSaveButtonContainer"
        style={{ marginRight: '16px', height: '18px' }}
      >
        <SaveDevelopmentButton
          developmentId={development?.id}
          savedByUserIds={development?.savedByUserIds}
          onAfterSave={handleAfterSave}
          hasTooltip
        />
      </div>
      <CursorPointer
        id="DevelopmentDetailSubHeaderHistoryButton"
        onClick={() => {
          props.setIsReviewHistoryDisplayed(true);
        }}
        onMouseOver={() => setIsHistoryHovered(true)}
        onMouseLeave={() => setIsHistoryHovered(false)}
        style={{ marginRight: '16px', height: '22px', position: 'relative' }}
      >
        {!isHistoryHovered && (
          <HistoryGray id="DevelopmentDetailSubHeaderHistoryGrayIcon" />
        )}
        {isHistoryHovered && (
          <HistorySecondary id="DevelopmentDetailSubHeaderHistorySecondaryIcon" />
        )}
        <TooltipContainer
          ref={(reviewHistoryTooltipContainer) =>
            setReviewHistoryButtonTooltipContainer(
              reviewHistoryTooltipContainer
            )
          }
        >
          <Tooltip
            id={`DevelopmentDetailSubHeaderReviewHistoryTooltip`}
            relativeToElement={reviewHistoryButtonTooltipContainer}
            hasArrow
            arrowDirection={ArrowDirections.Up}
          >
            <span
              id={`DevelopmentDetailSubHeaderReviewHistoryTooltipSpan`}
              style={{ whiteSpace: 'nowrap' }}
            >
              History
            </span>
          </Tooltip>
        </TooltipContainer>
      </CursorPointer>
      <div
        style={{
          position: 'relative',
          marginTop: '4px',
        }}
        ref={shareDropdownContainerRef}
      >
        <CursorPointer
          id="DevelopmentDetailSubHeaderShareButton"
          onClick={() => {
            setIsShareDropdownOpen(!isShareDropdownOpen);
          }}
          onMouseOver={() => setIsShareHovered(true)}
          onMouseLeave={() => setIsShareHovered(false)}
          style={{
            marginRight: '32px',
            userSelect: 'none',
            position: 'relative',
          }}
        >
          <Options
            id="DevelopmentDetailSubHeaderOptionsIcon"
            color={
              isShareHovered
                ? Theme.colors.secondaryColor
                : Theme.colors.grayDark
            }
          />
          <TooltipContainer
            ref={(shareTooltipContainer) =>
              setShareButtonTooltipContainer(shareTooltipContainer)
            }
          >
            <Tooltip
              id={`DevelopmentDetailSubHeaderShareTooltip`}
              relativeToElement={shareButtonTooltipContainer}
              hasArrow
              arrowDirection={ArrowDirections.Up}
            >
              <span
                id={`DevelopmentDetailSubHeaderShareTooltipSpan`}
                style={{ whiteSpace: 'nowrap' }}
              >
                Options
              </span>
            </Tooltip>
          </TooltipContainer>
        </CursorPointer>
        <Dropdown
          id="DevelopmentDetailSubHeaderShareDropdown"
          isOpen={isShareDropdownOpen}
          setIsOpen={setIsShareDropdownOpen}
          containerRef={shareDropdownContainerRef}
          transform="translate(-100%, 25%)"
          style={{
            border: `1px solid ${Theme.colors.grayBorder}`,
            boxShadow: `0px 1px 15px ${Theme.colors.grayShadow}`,
          }}
        >
          <div style={{ width: '240px', color: Theme.colors.black }}>
            <FlexContainer flexDirection="column">
              <DropdownOptionsContainer style={{ width: '100%' }}>
                <DropdownOption id="DevelopmentDetailSubHeaderShareDropdownCopyLinkOption">
                  <DropdownOptionButton
                    id="DevelopmentDetailSubHeaderShareDropdownCopyLinkOptionButton"
                    onClick={() => {
                      setIsShareDropdownOpen(false);
                      handleCopyLink();
                    }}
                  >
                    <Copy
                      id="DevelopmentDetailSubHeaderCopyIcon"
                      color={Theme.colors.grayDark}
                    />
                    <DropdownOptionLabel
                      id="DevelopmentDetailSubHeaderShareDropdownCopyLinkOptionLabel"
                      style={{
                        height: 22,
                        margin: '0px 0px 0px 6px',
                      }}
                    >
                      Copy link
                    </DropdownOptionLabel>
                  </DropdownOptionButton>
                </DropdownOption>
                <DropdownOption id="DevelopmentDetailSubHeaderShareDropdownGoToSourceOption">
                  <DropdownOptionButton
                    id="DevelopmentDetailSubHeaderShareDropdownGoToSourceOptionButton"
                    onClick={() => {
                      setIsShareDropdownOpen(false);
                      handleGoToSource();
                    }}
                  >
                    <ExternalLink
                      id="DevelopmentDetailSubHeaderExternalLinkIcon"
                      color={Theme.colors.grayDark}
                      height="20"
                      width="20"
                    />
                    <DropdownOptionLabel
                      id="DevelopmentDetailSubHeaderShareDropdownGoToSourceOptionLabel"
                      style={{
                        height: 22,
                        margin: '0px 0px 0px 6px',
                      }}
                    >
                      Go to monitoring source
                    </DropdownOptionLabel>
                  </DropdownOptionButton>
                </DropdownOption>
                <DropdownOption id="DevelopmentDetailSubHeaderShareDropdownDownloadPdfOption">
                  <DropdownOptionButton
                    id="DevelopmentDetailSubHeaderShareDropdownDownloadPdfOptionButton"
                    onClick={() => {
                      setIsShareDropdownOpen(false);
                      handleDownloadPdf();
                    }}
                  >
                    <Export
                      id="DevelopmentDetailSubHeaderDownloadIcon"
                      color={Theme.colors.grayDark}
                      width="16"
                      height="20"
                    />
                    <DropdownOptionLabel
                      id="DevelopmentDetailSubHeaderShareDropdownDownloadPdfOptionLabel"
                      style={{
                        height: 22,
                        margin: '0px 0px 0px 6px',
                      }}
                    >
                      Download PDF
                    </DropdownOptionLabel>
                  </DropdownOptionButton>
                </DropdownOption>
              </DropdownOptionsContainer>
            </FlexContainer>
          </div>
        </Dropdown>
      </div>
      <UsersReviewing />
    </Header>
  );
};
