import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { getAuthoritiesWithDevelopmentCountGreaterThanZero } from 'services/authorities/authorities-service';
import { Authority, PaginatedResponse } from 'models';
import { paginationConfig } from 'config/pagination-config';

export interface TurnaroundTimeFilterAuthorityState {
  authorities: Authority[];
  totalRecords: number;
  searchTerm: string;
  page: number;
  previousPage: number;
  perPage: number;
}

const initialState: TurnaroundTimeFilterAuthorityState = {
  authorities: [],
  totalRecords: 0,
  searchTerm: '',
  page: 1,
  previousPage: 1,
  perPage: paginationConfig.authoritiesPerPage,
};

export const getAllTurnaroundTimeFilterAuthoritiesAsync = (): AppThunk<
  Promise<PaginatedResponse<Authority> | null>
> => {
  return async (dispatch, getState) => {
    const selectedEnvironment = getState().environment.selectedEnvironment;
    const searchTerm = getState().turnaroundTimeFilterAuthority.searchTerm;
    const page = getState().turnaroundTimeFilterAuthority.page;
    const previousPage = getState().turnaroundTimeFilterAuthority.previousPage;
    const perPage = getState().turnaroundTimeFilterAuthority.perPage;
    const activeFilters =
      getState().turnaroundTimeFilter.turnaroundTimeActiveFilters;
    const response = await getAuthoritiesWithDevelopmentCountGreaterThanZero(
      selectedEnvironment,
      searchTerm,
      page,
      perPage,
      activeFilters,
      []
    );
    if (page === 1) {
      dispatch(setTurnaroundTimeFilterAuthorities(response.data));
    } else {
      if (page > previousPage) {
        dispatch(addTurnaroundTimeFilterAuthorities(response.data));
      }
    }
    return response.data;
  };
};

export const turnaroundTimeFilterAuthoritySlice = createSlice({
  name: 'turnaround-time-filter-authority',
  initialState,
  reducers: {
    setTurnaroundTimeFilterAuthorities: (
      state,
      action: PayloadAction<PaginatedResponse<Authority> | null>
    ) => {
      state.authorities = action.payload?.records ?? [];
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    addTurnaroundTimeFilterAuthorities: (
      state,
      action: PayloadAction<PaginatedResponse<Authority> | null>
    ) => {
      state.authorities = state.authorities.concat(
        action.payload?.records ?? []
      );
      state.totalRecords = action.payload?.totalRecords ?? 0;
      state.previousPage = state.page;
    },
    setTurnaroundTimeFilterAuthoritiesSearchTerm: (
      state,
      action: PayloadAction<string>
    ) => {
      state.page = 1;
      state.searchTerm = action.payload;
    },
    increaseTurnaroundTimeFilterAuthoritiesPage: (state) => {
      state.page = state.page + 1;
    },
    resetTurnaroundTimeFilterAuthoritiesPage: (state) => {
      state.page = 1;
      state.previousPage = 1;
    },
  },
});

export const {
  setTurnaroundTimeFilterAuthorities,
  addTurnaroundTimeFilterAuthorities,
  setTurnaroundTimeFilterAuthoritiesSearchTerm,
  increaseTurnaroundTimeFilterAuthoritiesPage,
  resetTurnaroundTimeFilterAuthoritiesPage,
} = turnaroundTimeFilterAuthoritySlice.actions;

export const selectTurnaroundTimeFilterAuthorities = (state: RootState) =>
  state.turnaroundTimeFilterAuthority.authorities;
export const selectTurnaroundTimeFilterAuthoritiesTotalRecords = (
  state: RootState
) => state.turnaroundTimeFilterAuthority.totalRecords;
export const selectTurnaroundTimeFilterAuthoritiesSearchTerm = (
  state: RootState
) => state.turnaroundTimeFilterAuthority.searchTerm;
export const selectTurnaroundTimeFilterAuthoritiesPage = (state: RootState) =>
  state.turnaroundTimeFilterAuthority.page;
export const selectTurnaroundTimeFilterAuthoritiesPerPage = (
  state: RootState
) => state.turnaroundTimeFilterAuthority.perPage;

export default turnaroundTimeFilterAuthoritySlice.reducer;
