import { ReportsLabels } from 'pages/reports/interfaces/ReportsLabels';
import { TurnaroundPageBucketTypes } from 'enums/TurnaroundPageBucketTypes';
import { TurnaroundTimeBucketTypes } from 'enums/TurnaroundTimeBucketTypes';

export const turnaroundTimeReportLabels: ReportsLabels = {
  title: 'Reports',
  subTitle: 'Turnaround Time',
  searchBarLabel: 'Search',
  tableNameLabel: 'Turnaround Time',
};

export const turnaroundTimeSummaryLabels: { [x: string]: string } = {
  [TurnaroundTimeBucketTypes.Within2hours]: 'Within 2 Hours',
  [TurnaroundTimeBucketTypes.Within24hours]: 'Within 24 Hours',
  [TurnaroundTimeBucketTypes.Within36hours]: 'Within 36 Hours',
  [TurnaroundTimeBucketTypes.Within60hours]: 'Within 60 Hours',
  [TurnaroundTimeBucketTypes.Within4days]: 'Within 4 Days',
  totalDevelopments: 'Total Developments',
};

export const turnaroundPageSummaryLabels: { [x: string]: string } = {
  [TurnaroundPageBucketTypes.From1To15Pages]: '1-15 Pages',
  [TurnaroundPageBucketTypes.From16To100Pages]: '16-100 Pages',
  [TurnaroundPageBucketTypes.From101To200Pages]: '101-200 Pages',
  [TurnaroundPageBucketTypes.From201To1000Pages]: '201-1,000 Pages',
  [TurnaroundPageBucketTypes.From1001To1700Pages]: '1,001-1,700 Pages',
  [TurnaroundPageBucketTypes.NonConformities]: 'Non-conformities (1-15 Pages)',
};

export const turnaroundPageLegendColors: { [x: string]: string } = {
  [TurnaroundPageBucketTypes.From1To15Pages]: '#2F43A5',
  [TurnaroundPageBucketTypes.From16To100Pages]: '#4A7BF8',
  [TurnaroundPageBucketTypes.From101To200Pages]: '#91AEFA',
  [TurnaroundPageBucketTypes.From201To1000Pages]: '#AEAEAE',
  [TurnaroundPageBucketTypes.From1001To1700Pages]: '#DCDCDC',
  [TurnaroundPageBucketTypes.NonConformities]: '#FFC034',
};
