export enum ElasticsearchIndexes {
  language = 'language',
  region = 'region',
  country = 'country',
  authority = 'authority',
  documentType = 'doc-type',
  subcategory = 'subcategory',
  user = 'user',
  team = 'team',
  feed = 'feed',
  development = 'dev',
  feedback = 'feedback',
  developmentEvent = 'dev-evt',
  developmentCitation = 'dev-cit',
}
