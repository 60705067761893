import { useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';

export const ProtectedRoute = ({
  children,
  condition,
  urlToNavigateTo,
  withReturnUrl,
}: {
  children: JSX.Element;
  condition: boolean;
  urlToNavigateTo: string;
  withReturnUrl?: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!condition) {
      const returnUrl = searchParams.get('returnUrl');

      navigate(
        `${urlToNavigateTo}${
          withReturnUrl ? `?returnUrl=${returnUrl || location.pathname}` : ''
        }`
      );
    }
  }, [condition, navigate, urlToNavigateTo, withReturnUrl, location]);

  if (condition) {
    return children;
  } else {
    return <></>;
  }
};
