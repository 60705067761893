import styled from '@emotion/styled';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Theme } from 'styles/themes';
import { FlexContainer } from 'styles/utils';
import { TurnaroundTimeDetailsFilterSet } from './TurnaroundTimeDetailsFilterSet';
import { FilterSet } from 'components/FilterSet';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { AllFilterEntries, FilterEntry, FilterEntryValue } from 'models';
import { setTriggerGetEntityDetailsAsync } from 'store/turnaround-time-report/turnaround-time-report-slice';
import {
  selectFilterPickerEntries,
  selectTurnaroundTimeActiveFilters,
  setSomeTurnaroundTimeActiveFilters,
  updateTurnaroundTimeActiveFilters,
  updateFilterPickerEntries,
  selectTriggerGetAllTurnaroundTimeFilterSetEntries,
  setTriggerGetAllTurnaroundTimeFilterSetEntries,
} from 'store/turnaround-time-report/turnaround-time-filter-slice';
import {
  getAllTurnaroundTimeFilterCountriesAsync,
  selectTurnaroundTimeFilterCountries,
} from 'store/turnaround-time-report/turnaround-time-filter-country-slice';
import {
  getAllTurnaroundTimeFilterAuthoritiesAsync,
  increaseTurnaroundTimeFilterAuthoritiesPage,
  resetTurnaroundTimeFilterAuthoritiesPage,
  selectTurnaroundTimeFilterAuthorities,
  selectTurnaroundTimeFilterAuthoritiesPage,
  selectTurnaroundTimeFilterAuthoritiesPerPage,
  selectTurnaroundTimeFilterAuthoritiesSearchTerm,
  selectTurnaroundTimeFilterAuthoritiesTotalRecords,
  setTurnaroundTimeFilterAuthoritiesSearchTerm,
} from 'store/turnaround-time-report/turnaround-time-filter-authority-slice';
import {
  getAllTurnaroundTimeFilterSubcategoriesAsync,
  increaseTurnaroundTimeFilterSubcategoriesPage,
  resetTurnaroundTimeFilterSubcategoriesPage,
  selectTurnaroundTimeFilterSubcategories,
  selectTurnaroundTimeFilterSubcategoriesPage,
  selectTurnaroundTimeFilterSubcategoriesPerPage,
  selectTurnaroundTimeFilterSubcategoriesSearchTerm,
  selectTurnaroundTimeFilterSubcategoriesTotalRecords,
  setTurnaroundTimeFilterSubcategoriesSearchTerm,
} from 'store/turnaround-time-report/turnaround-time-filter-subcategory-slice';
import {
  getAllTurnaroundTimeFilterDocumentTypesAsync,
  increaseTurnaroundTimeFilterDocumentTypesPage,
  resetTurnaroundTimeFilterDocumentTypesPage,
  selectTurnaroundTimeFilterDocumentTypes,
  selectTurnaroundTimeFilterDocumentTypesPage,
  selectTurnaroundTimeFilterDocumentTypesPerPage,
  selectTurnaroundTimeFilterDocumentTypesSearchTerm,
  selectTurnaroundTimeFilterDocumentTypesTotalRecords,
  setTurnaroundTimeFilterDocumentTypesSearchTerm,
} from 'store/turnaround-time-report/turnaround-time-filter-document-type-slice';
import { developmentKeys } from 'config/development-keys';
import { filterKeys } from 'config/filter-keys';
import { reportByOptions } from '../../report-by-options';
import { Loading } from 'components/Loading';

const FilterPickerContainer = styled(FlexContainer)({
  flexGrow: 1,
  width: '100%',
  height: '48px',
  backgroundColor: Theme.colors.primaryBackgroundColor,
  alignItems: 'center',
});

const LabelContainer = styled.div({
  padding: '0 16px',
});

export const TurnaroundTimeDetailsFilterPicker = (
  props: TurnaroundTimeDetailsFilterPickerProps
) => {
  const dispatch = useAppDispatch();
  const activeFilters = useAppSelector(selectTurnaroundTimeActiveFilters);
  const filterPickerEntries = useAppSelector(selectFilterPickerEntries);
  const triggerGetAllFilterSetEntries = useAppSelector(
    selectTriggerGetAllTurnaroundTimeFilterSetEntries
  );

  // Filter Countries
  const filterCountries = useAppSelector(selectTurnaroundTimeFilterCountries);

  // Filter Authorities
  const filterAuthorities = useAppSelector(
    selectTurnaroundTimeFilterAuthorities
  );
  const filterAuthoritiesTotalRecords = useAppSelector(
    selectTurnaroundTimeFilterAuthoritiesTotalRecords
  );
  const filterAuthoritiesSearchTerm = useAppSelector(
    selectTurnaroundTimeFilterAuthoritiesSearchTerm
  );
  const filterAuthoritiesPage = useAppSelector(
    selectTurnaroundTimeFilterAuthoritiesPage
  );
  const filterAuthoritiesPerPage = useAppSelector(
    selectTurnaroundTimeFilterAuthoritiesPerPage
  );
  const [isLoadingAuthorities, setIsLoadingAuthorities] = useState(false);

  // Filter Subcategories
  const filterSubcategories = useAppSelector(
    selectTurnaroundTimeFilterSubcategories
  );
  const filterSubcategoriesTotalRecords = useAppSelector(
    selectTurnaroundTimeFilterSubcategoriesTotalRecords
  );
  const filterSubcategoriesSearchTerm = useAppSelector(
    selectTurnaroundTimeFilterSubcategoriesSearchTerm
  );
  const filterSubcategoriesPage = useAppSelector(
    selectTurnaroundTimeFilterSubcategoriesPage
  );
  const filterSubcategoriesPerPage = useAppSelector(
    selectTurnaroundTimeFilterSubcategoriesPerPage
  );
  const [isLoadingSubcategories, setIsLoadingSubcategories] = useState(false);

  // Filter Document Types
  const filterDocumentTypes = useAppSelector(
    selectTurnaroundTimeFilterDocumentTypes
  );
  const filterDocumentTypesTotalRecords = useAppSelector(
    selectTurnaroundTimeFilterDocumentTypesTotalRecords
  );
  const filterDocumentTypesSearchTerm = useAppSelector(
    selectTurnaroundTimeFilterDocumentTypesSearchTerm
  );
  const filterDocumentTypesPage = useAppSelector(
    selectTurnaroundTimeFilterDocumentTypesPage
  );
  const filterDocumentTypesPerPage = useAppSelector(
    selectTurnaroundTimeFilterDocumentTypesPerPage
  );
  const [isLoadingDocumentTypes, setIsLoadingDocumentTypes] = useState(false);

  useEffect(() => {
    const hasCountryFilter = activeFilters[filterKeys.country]?.length === 1;
    const isSameAsPreviousCountry =
      hasCountryFilter &&
      activeFilters[filterKeys.country][0] === props.countryId;
    const isSameAsPreviousProcessingDate =
      activeFilters[developmentKeys.processingDate] === props.dateFilter;
    dispatch(
      setSomeTurnaroundTimeActiveFilters([
        {
          propertyToFilterBy: reportByOptions.Country.queryParam,
          values: [Number(props.countryId)],
        },
      ])
    );
    dispatch(
      updateTurnaroundTimeActiveFilters({
        propertyToFilterBy: developmentKeys.processingDate,
        value: props.dateFilter,
        replace: true,
      })
    );
    if (!isSameAsPreviousCountry || !isSameAsPreviousProcessingDate) {
      dispatch(setTriggerGetAllTurnaroundTimeFilterSetEntries());
    }
  }, [props.countryId, props.dateFilter]);

  useEffect(() => {
    const hasCountryFilter = activeFilters[filterKeys.country]?.length === 1;
    const hasProcessingDateFilter =
      !!activeFilters[developmentKeys.processingDate];
    if (hasCountryFilter && hasProcessingDateFilter) {
      setIsLoadingAuthorities(true);
      setIsLoadingSubcategories(true);
      setIsLoadingDocumentTypes(true);
      dispatch(getAllTurnaroundTimeFilterCountriesAsync());
      dispatch(getAllTurnaroundTimeFilterAuthoritiesAsync()).then(() => {
        setIsLoadingAuthorities(false);
      });
      dispatch(getAllTurnaroundTimeFilterSubcategoriesAsync()).then(() => {
        setIsLoadingSubcategories(false);
      });
      dispatch(getAllTurnaroundTimeFilterDocumentTypesAsync()).then(() => {
        setIsLoadingDocumentTypes(false);
      });
    }
  }, [triggerGetAllFilterSetEntries]);

  const countryEntries = useMemo(() => {
    return filterCountries.map((country) => {
      const filterEntry: FilterEntry = {
        value: country.id,
        name: country.name,
        developmentsCountFiltered: country.developmentsCountFiltered,
      };
      return filterEntry;
    });
  }, [JSON.stringify(filterCountries)]);

  const authorityEntries = useMemo(() => {
    return filterAuthorities.map((authority) => {
      const filterEntry: FilterEntry = {
        value: authority.id,
        name: authority.name,
        developmentsCountFiltered: authority.developmentsCountFiltered,
      };
      return filterEntry;
    });
  }, [JSON.stringify(filterAuthorities)]);

  const subcategoryEntries = useMemo(() => {
    return filterSubcategories.map((subcategory) => {
      const filterEntry: FilterEntry = {
        value: subcategory.id,
        name: subcategory.name,
        developmentsCountFiltered: subcategory.developmentsCountFiltered,
      };
      return filterEntry;
    });
  }, [JSON.stringify(filterSubcategories)]);

  const documentTypeEntries = useMemo(() => {
    return filterDocumentTypes.map((documentType) => {
      const filterEntry: FilterEntry = {
        value: documentType.id,
        name: documentType.name,
        developmentsCountFiltered: documentType.developmentsCountFiltered,
      };
      return filterEntry;
    });
  }, [JSON.stringify(filterDocumentTypes)]);

  const filterEntries = useMemo(() => {
    const result: AllFilterEntries = {
      country: [],
      authority: [],
      subcategory: [],
      documentType: [],
      status: [],
      pageCount: [],
      turnaroundTime: [],
      nonConformity: [],
      language: [],
      region: [],
    };
    const filters: AllFilterEntries = {
      ...result,
      country: countryEntries,
      authority: authorityEntries,
      subcategory: subcategoryEntries,
      documentType: documentTypeEntries,
    };
    Object.entries(filters).forEach(([key, values]) => {
      if (values && values.length) {
        result[key as keyof AllFilterEntries] = (values as FilterEntry[]).map(
          (filterEntry) => {
            const activeFilterKey =
              filterEntry.propertyToFilterByOverride ||
              developmentKeys[key] ||
              (filterKeys as any)[key];
            const activeFiltersIncludeValue =
              !!activeFilters[activeFilterKey] &&
              (typeof activeFilters[activeFilterKey] === 'string'
                ? activeFilters[activeFilterKey].includes(
                    String(filterEntry.value)
                  )
                : Array.isArray(activeFilters[activeFilterKey])
                ? (activeFilters[activeFilterKey] as FilterEntryValue[]).some(
                    (x) =>
                      JSON.stringify(x) === JSON.stringify(filterEntry.value)
                  )
                : false);
            const filterEntriesWithCheckedProperty: FilterEntry = {
              value: filterEntry.value,
              name: filterEntry.name,
              developmentsCountFiltered: filterEntry.developmentsCountFiltered,
              checked: activeFiltersIncludeValue,
              propertyToFilterByOverride:
                filterEntry.propertyToFilterByOverride,
            };
            return filterEntriesWithCheckedProperty;
          }
        );
      }
    });
    return result;
  }, [
    JSON.stringify(countryEntries),
    JSON.stringify(authorityEntries),
    JSON.stringify(subcategoryEntries),
    JSON.stringify(documentTypeEntries),
    activeFilters,
  ]);

  const onFilterSetChange = useCallback((filterKey?: string) => {
    return (filterEntry: FilterEntry) => {
      const { value, name, propertyToFilterByOverride } = filterEntry;
      const propertyToFilterBy = filterKey || propertyToFilterByOverride || '';
      dispatch(
        updateTurnaroundTimeActiveFilters({
          propertyToFilterBy,
          value,
        })
      );
      dispatch(
        updateFilterPickerEntries({
          value,
          name,
          propertyToFilterByOverride: propertyToFilterBy,
        })
      );
      dispatch(setTriggerGetAllTurnaroundTimeFilterSetEntries());
      dispatch(setTriggerGetEntityDetailsAsync());
    };
  }, []);

  return (
    <FilterPickerContainer>
      {(isLoadingAuthorities ||
        isLoadingSubcategories ||
        isLoadingDocumentTypes) && <Loading />}
      <LabelContainer>Add Filters:</LabelContainer>
      <TurnaroundTimeDetailsFilterSet
        title="Issuing Authority"
        count={filterAuthoritiesTotalRecords}
        isLoading={isLoadingAuthorities}
        transform="translate(43%, 27%)"
      >
        <FilterSet
          title="Issuing Authority"
          entries={filterEntries.authority}
          totalRecords={filterAuthoritiesTotalRecords}
          getAllAsync={getAllTurnaroundTimeFilterAuthoritiesAsync}
          searchTerm={filterAuthoritiesSearchTerm}
          setSearchTerm={setTurnaroundTimeFilterAuthoritiesSearchTerm}
          page={filterAuthoritiesPage}
          perPage={filterAuthoritiesPerPage}
          increasePage={increaseTurnaroundTimeFilterAuthoritiesPage}
          resetPage={resetTurnaroundTimeFilterAuthoritiesPage}
          onChange={onFilterSetChange(filterKeys.authority)}
          activeFilters={activeFilters}
          activeFiltersPropertyToIgnore={filterKeys.authority}
          setIsLoading={setIsLoadingAuthorities}
          triggerGetAllFilterSetEntries={triggerGetAllFilterSetEntries}
          noGetAllAsyncOnRender
          noHeader
          noBorders
        />
      </TurnaroundTimeDetailsFilterSet>
      <TurnaroundTimeDetailsFilterSet
        title="Subcategory"
        count={filterSubcategoriesTotalRecords}
        isLoading={isLoadingSubcategories}
        transform="translate(96%, 27%)"
      >
        <FilterSet
          title="Subcategory"
          entries={filterEntries.subcategory}
          totalRecords={filterSubcategoriesTotalRecords}
          getAllAsync={getAllTurnaroundTimeFilterSubcategoriesAsync}
          searchTerm={filterSubcategoriesSearchTerm}
          setSearchTerm={setTurnaroundTimeFilterSubcategoriesSearchTerm}
          page={filterSubcategoriesPage}
          perPage={filterSubcategoriesPerPage}
          increasePage={increaseTurnaroundTimeFilterSubcategoriesPage}
          resetPage={resetTurnaroundTimeFilterSubcategoriesPage}
          onChange={onFilterSetChange(filterKeys.subcategory)}
          activeFilters={activeFilters}
          activeFiltersPropertyToIgnore={filterKeys.subcategory}
          setIsLoading={setIsLoadingSubcategories}
          triggerGetAllFilterSetEntries={triggerGetAllFilterSetEntries}
          noGetAllAsyncOnRender
          noHeader
          noBorders
        />
      </TurnaroundTimeDetailsFilterSet>
      <TurnaroundTimeDetailsFilterSet
        title="Document type"
        count={filterDocumentTypesTotalRecords}
        isLoading={isLoadingDocumentTypes}
        transform="translate(157%, 27%)"
      >
        <FilterSet
          title="Document type"
          entries={filterEntries.documentType}
          totalRecords={filterDocumentTypesTotalRecords}
          getAllAsync={getAllTurnaroundTimeFilterDocumentTypesAsync}
          searchTerm={filterDocumentTypesSearchTerm}
          setSearchTerm={setTurnaroundTimeFilterDocumentTypesSearchTerm}
          page={filterDocumentTypesPage}
          perPage={filterDocumentTypesPerPage}
          increasePage={increaseTurnaroundTimeFilterDocumentTypesPage}
          resetPage={resetTurnaroundTimeFilterDocumentTypesPage}
          onChange={onFilterSetChange(filterKeys.documentType)}
          activeFilters={activeFilters}
          activeFiltersPropertyToIgnore={filterKeys.documentType}
          setIsLoading={setIsLoadingDocumentTypes}
          triggerGetAllFilterSetEntries={triggerGetAllFilterSetEntries}
          noGetAllAsyncOnRender
          noHeader
          noBorders
        />
      </TurnaroundTimeDetailsFilterSet>
    </FilterPickerContainer>
  );
};

interface TurnaroundTimeDetailsFilterPickerProps {
  countryId: number;
  dateFilter: string;
}
