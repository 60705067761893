import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { getDocumentTypesWithDevelopmentCountGreaterThanZero } from 'services/document-types/document-types-service';
import { DocumentType, PaginatedResponse } from 'models';
import { paginationConfig } from 'config/pagination-config';

export interface TurnaroundTimeFilterDocumentTypeState {
  documentTypes: DocumentType[];
  totalRecords: number;
  searchTerm: string;
  page: number;
  previousPage: number;
  perPage: number;
}

const initialState: TurnaroundTimeFilterDocumentTypeState = {
  documentTypes: [],
  totalRecords: 0,
  searchTerm: '',
  page: 1,
  previousPage: 1,
  perPage: paginationConfig.documentTypesPerPage,
};

export const getAllTurnaroundTimeFilterDocumentTypesAsync = (): AppThunk<
  Promise<PaginatedResponse<DocumentType> | null>
> => {
  return async (dispatch, getState) => {
    const selectedEnvironment = getState().environment.selectedEnvironment;
    const searchTerm = getState().turnaroundTimeFilterDocumentType.searchTerm;
    const page = getState().turnaroundTimeFilterDocumentType.page;
    const previousPage =
      getState().turnaroundTimeFilterDocumentType.previousPage;
    const perPage = getState().turnaroundTimeFilterDocumentType.perPage;
    const activeFilters =
      getState().turnaroundTimeFilter.turnaroundTimeActiveFilters;
    const response = await getDocumentTypesWithDevelopmentCountGreaterThanZero(
      selectedEnvironment,
      searchTerm,
      page,
      perPage,
      activeFilters,
      []
    );
    if (page === 1) {
      dispatch(setTurnaroundTimeFilterDocumentTypes(response.data));
    } else {
      if (page > previousPage) {
        dispatch(addTurnaroundTimeFilterDocumentTypes(response.data));
      }
    }
    return response.data;
  };
};

export const turnaroundTimeFilterDocumentTypeSlice = createSlice({
  name: 'turnaround-time-filter-documentType',
  initialState,
  reducers: {
    setTurnaroundTimeFilterDocumentTypes: (
      state,
      action: PayloadAction<PaginatedResponse<DocumentType> | null>
    ) => {
      state.documentTypes = action.payload?.records ?? [];
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    addTurnaroundTimeFilterDocumentTypes: (
      state,
      action: PayloadAction<PaginatedResponse<DocumentType> | null>
    ) => {
      state.documentTypes = state.documentTypes.concat(
        action.payload?.records ?? []
      );
      state.totalRecords = action.payload?.totalRecords ?? 0;
      state.previousPage = state.page;
    },
    setTurnaroundTimeFilterDocumentTypesSearchTerm: (
      state,
      action: PayloadAction<string>
    ) => {
      state.page = 1;
      state.searchTerm = action.payload;
    },
    increaseTurnaroundTimeFilterDocumentTypesPage: (state) => {
      state.page = state.page + 1;
    },
    resetTurnaroundTimeFilterDocumentTypesPage: (state) => {
      state.page = 1;
      state.previousPage = 1;
    },
  },
});

export const {
  setTurnaroundTimeFilterDocumentTypes,
  addTurnaroundTimeFilterDocumentTypes,
  setTurnaroundTimeFilterDocumentTypesSearchTerm,
  increaseTurnaroundTimeFilterDocumentTypesPage,
  resetTurnaroundTimeFilterDocumentTypesPage,
} = turnaroundTimeFilterDocumentTypeSlice.actions;

export const selectTurnaroundTimeFilterDocumentTypes = (state: RootState) =>
  state.turnaroundTimeFilterDocumentType.documentTypes;
export const selectTurnaroundTimeFilterDocumentTypesTotalRecords = (
  state: RootState
) => state.turnaroundTimeFilterDocumentType.totalRecords;
export const selectTurnaroundTimeFilterDocumentTypesSearchTerm = (
  state: RootState
) => state.turnaroundTimeFilterDocumentType.searchTerm;
export const selectTurnaroundTimeFilterDocumentTypesPage = (state: RootState) =>
  state.turnaroundTimeFilterDocumentType.page;
export const selectTurnaroundTimeFilterDocumentTypesPerPage = (
  state: RootState
) => state.turnaroundTimeFilterDocumentType.perPage;

export default turnaroundTimeFilterDocumentTypeSlice.reducer;
