import styled from '@emotion/styled';
import { Button } from 'components/Button';
import { Theme } from 'styles/themes';
import { ManagementLabels } from 'pages/management/interfaces/ManagementLabels';
import { ReactComponent as PlusCircleSmaller } from 'assets/plus-circle-smaller.svg';
import { HTMLAttrID } from 'models';
import { Header } from 'components/Header';

interface ManagementHeaderProps extends ManagementLabels, HTMLAttrID {
  onClickCreateButton: () => void;
}

const ManagementHeaderTitle = styled.span({
  fontSize: Theme.fontSize + 2,
  fontWeight: 'bold',
  color: Theme.colors.primaryColor,
  lineHeight: '25px',
});

export const ManagementHeader = (props: ManagementHeaderProps) => {
  return (
    <Header id={props.id} hasBorderBottom>
      <ManagementHeaderTitle id={`${props.id}Title`}>
        {props.title}
      </ManagementHeaderTitle>
      <Button
        id={`${props.id}CreateButton`}
        onClick={props.onClickCreateButton}
        style={{ marginLeft: 'auto' }}
      >
        <PlusCircleSmaller
          id={`${props.id}CreateButtonIcon`}
          style={{ marginRight: '6px' }}
        />
        {props.createButtonLabel}
      </Button>
    </Header>
  );
};
