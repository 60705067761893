import { developmentKeys } from 'config/development-keys';
import { NonConformityFilter } from 'enums';
import { FilterEntry } from 'models';

export const getNonConformityFilters = (): FilterEntry[] => {
  return [
    {
      value: true,
      name: NonConformityFilter.Yes,
      propertyToFilterByOverride: developmentKeys.exceptionFlag,
    },
    {
      value: false,
      name: NonConformityFilter.No,
      propertyToFilterByOverride: developmentKeys.exceptionFlag,
    },
    {
      value: undefined,
      name: NonConformityFilter.NotApplicable,
      propertyToFilterByOverride: developmentKeys.exceptionFlag,
    },
  ];
};
