import { logout } from 'store/authentication/authentication-slice';
import { useAppDispatch } from 'store/hooks';
import { setSelectedEnvironment } from 'store/environment/environment-slice';

export const useLogout = () => {
  const dispatch = useAppDispatch();
  return () => {
    dispatch(logout());
    dispatch(setSelectedEnvironment(null));
  };
};
