import {
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
} from 'utils/http/base-http-service';
import { HttpResponse } from 'utils/http/HttpResponse';
import { Team, PaginatedResponse, TeamsBulkActionPayload } from 'models';
import { toQueryParams } from 'utils/to-query-params';
import { SortByDirection } from 'enums';
import { escapeQueryStringQueryReservedCharacters } from 'utils/elasticsearch';

export const getAllTeams = async (
  searchTerm: string,
  sortByProperty: string,
  sortByDirection: SortByDirection,
  page: number,
  perPage: number
): Promise<HttpResponse<PaginatedResponse<Team> | null>> => {
  let query: any = {
    filter: [],
  };
  if (searchTerm) {
    query.filter.push({
      bool: {
        should: [
          {
            query_string: {
              query: `*${escapeQueryStringQueryReservedCharacters(
                searchTerm
              )}*`,
              fields: ['name'],
              minimum_should_match: '100%',
            },
          },
          {
            query_string: {
              query: `*${escapeQueryStringQueryReservedCharacters(
                searchTerm
              )}*`,
              fields: ['teamFeeds.feedName'],
              minimum_should_match: '100%',
            },
          },
        ],
        minimum_should_match: 1,
      },
    });
  }
  const queryParameters = toQueryParams({
    query: JSON.stringify(query),
    sortByProperty,
    sortByDirection,
    page,
    perPage,
  });
  return await httpGet<PaginatedResponse<Team>>(`teams?${queryParameters}`);
};

export const createTeam = async (
  team: Team
): Promise<HttpResponse<Team | null>> => {
  return await httpPost<Team, Team>('teams', team);
};

export const updateTeam = async (
  payload: Team
): Promise<HttpResponse<Team | null>> => {
  const id = payload.id as number;
  return await httpPut<Team, Team>(`teams/${id}`, payload);
};

export const deleteTeams = async (
  payload: TeamsBulkActionPayload
): Promise<HttpResponse<Team[] | null>> => {
  return await httpDelete<Team[], TeamsBulkActionPayload>(
    `teams/bulk-delete`,
    payload
  );
};

export const getCountryTeams = async (
  id: number
): Promise<HttpResponse<PaginatedResponse<Team> | null>> => {
  return httpGet<PaginatedResponse<Team>>(`teams/country/${id}`);
};

export const getAuthorityTeams = async (
  id: number
): Promise<HttpResponse<PaginatedResponse<Team> | null>> => {
  return httpGet<PaginatedResponse<Team>>(`teams/authority/${id}`);
};

export const getDocumentTypeTeams = async (
  id: number
): Promise<HttpResponse<PaginatedResponse<Team> | null>> => {
  return httpGet<PaginatedResponse<Team>>(`teams/document-type/${id}`);
};
