import { httpGet } from 'utils/http/base-http-service';
import { HttpResponse } from 'utils/http/HttpResponse';
import {
  ActiveFilters,
  DocumentType,
  Environment,
  PaginatedResponse,
  TurnaroundTimeDetailedReportData,
  TurnaroundTimeExtendedData,
  TurnaroundTimeReportData,
} from 'models';
import { toQueryParams } from 'utils/to-query-params';
import { escapeQueryStringQueryReservedCharacters } from 'utils/elasticsearch';
import { SortByDirection } from 'enums';
import { getDevelopmentsBaseQuery } from 'helpers/filters';
import { filterKeys } from 'config/filter-keys';

const getDocumentTypesBaseQuery = (searchTerm: string) => {
  let query: any = {
    filter: [],
  };
  if (searchTerm) {
    query.filter.push({
      bool: {
        should: [
          {
            query_string: {
              query: `*${escapeQueryStringQueryReservedCharacters(
                searchTerm
              )}*`,
              fields: ['name'],
              minimum_should_match: '100%',
            },
          },
        ],
        minimum_should_match: 1,
      },
    });
  }
  return query;
};

export const getDocumentTypesWithDevelopmentCountGreaterThanZero = async (
  selectedEnvironment: Environment | null,
  searchTerm: string,
  page: number,
  perPage: number,
  activeFilters: ActiveFilters,
  ids?: number[]
): Promise<HttpResponse<PaginatedResponse<DocumentType> | null>> => {
  const query = getDocumentTypesBaseQuery(searchTerm);
  let filterCountersQuery: any = {
    filter: [],
  };
  query.filter.push({
    range: {
      developmentsCount: {
        gt: 0,
      },
    },
  });
  filterCountersQuery = getDevelopmentsBaseQuery(
    activeFilters,
    filterCountersQuery,
    selectedEnvironment,
    [filterKeys.documentType]
  );
  const queryParameters = toQueryParams({
    defaultQueryParameters: JSON.stringify({
      query: JSON.stringify(query),
      page,
      perPage,
      sortByProperty: 'name.keyword',
      sortByDirection: SortByDirection.ASC,
    }),
    filterCountersQueryParameters: JSON.stringify({
      query: JSON.stringify(filterCountersQuery),
    }),
    idsToFilter: JSON.stringify(ids),
  });
  return httpGet<PaginatedResponse<DocumentType>>(
    `document-types?${queryParameters}`
  );
};

export const getDocumentTypes = async (
  searchTerm: string,
  page: number,
  perPage: number
): Promise<HttpResponse<PaginatedResponse<DocumentType> | null>> => {
  const query = getDocumentTypesBaseQuery(searchTerm);
  const queryParameters = toQueryParams({
    defaultQueryParameters: JSON.stringify({
      query: JSON.stringify(query),
      page,
      perPage,
      sortByProperty: 'name.keyword',
      sortByDirection: SortByDirection.ASC,
    }),
  });
  return httpGet<PaginatedResponse<DocumentType>>(
    `document-types?${queryParameters}`
  );
};

export const getDocumentTypesWithReportData = async (
  searchTerm: string,
  sortByProperty: string,
  sortByDirection: SortByDirection,
  page: number,
  perPage: number,
  reportDataFromDateInMilliseconds: number,
  isReportDataFromAverageDevelopmentsPerDay: boolean
): Promise<HttpResponse<PaginatedResponse<DocumentType> | null>> => {
  const query = getDocumentTypesBaseQuery(searchTerm);
  const queryParameters = toQueryParams({
    defaultQueryParameters: JSON.stringify({
      query: JSON.stringify(query),
      sortByProperty,
      sortByDirection,
      page,
      perPage,
    }),
    reportDataFromDateInMilliseconds,
    isReportDataFromAverageDevelopmentsPerDay,
  });
  return httpGet<PaginatedResponse<DocumentType>>(
    `document-types?${queryParameters}`
  );
};

export const getDocumentTypesWithTurnaroundTimeReportData = async (
  searchTerm: string,
  sortByProperty: string,
  sortByDirection: SortByDirection,
  page: number,
  perPage: number,
  isReportDataFromAverageDevelopmentsPerDay: boolean,
  reportDataFromDateInMilliseconds?: number,
  reportDataToDateInMilliseconds?: number
): Promise<
  HttpResponse<
    | (PaginatedResponse<DocumentType> & {
        totalTurnaroundTimeData?: TurnaroundTimeReportData;
      })
    | null
  >
> => {
  const query = getDocumentTypesBaseQuery(searchTerm);
  const queryParameters = toQueryParams({
    defaultQueryParameters: JSON.stringify({
      query: JSON.stringify(query),
      sortByProperty,
      sortByDirection,
      page,
      perPage,
    }),
    isReportDataFromAverageDevelopmentsPerDay,
    reportDataFromDateInMilliseconds,
    reportDataToDateInMilliseconds,
  });
  return httpGet<
    PaginatedResponse<DocumentType> & {
      totalTurnaroundTimeData?: TurnaroundTimeReportData;
    }
  >(`document-types/tat?${queryParameters}`);
};

export const getDocumentTypeById = async (
  id: number,
  reportDataFromDateInMilliseconds?: number,
  isReportDataFromAverageDevelopmentsPerDay?: boolean
): Promise<HttpResponse<DocumentType | null>> => {
  const queryParameters = toQueryParams({
    isReportDataFromAverageDevelopmentsPerDay,
    reportDataFromDateInMilliseconds,
  });
  return httpGet<DocumentType>(`document-types/${id}?${queryParameters}`);
};

export const getDocumentTypeByIdWithTurnaroundTimeReportData = async (
  id: number,
  reportDataFromDateInMilliseconds?: number,
  reportDataToDateInMilliseconds?: number
): Promise<
  HttpResponse<{
    reportEntity: DocumentType;
    turnaroundTimeDetailedReportData?: TurnaroundTimeDetailedReportData;
  } | null>
> => {
  const queryParameters = toQueryParams({
    reportDataFromDateInMilliseconds,
    reportDataToDateInMilliseconds,
  });
  return httpGet<{
    reportEntity: DocumentType;
    turnaroundTimeDetailedReportData?: TurnaroundTimeDetailedReportData;
  }>(`document-types/${id}/tat?${queryParameters}`);
};

export const getDocumentTypeByIdWithTurnaroundTimeExtendedData = async (
  id: number,
  reportDataFromDateInMilliseconds?: number,
  reportDataToDateInMilliseconds?: number
): Promise<HttpResponse<TurnaroundTimeExtendedData | null>> => {
  const queryParameters = toQueryParams({
    reportDataFromDateInMilliseconds,
    reportDataToDateInMilliseconds,
  });
  return httpGet<TurnaroundTimeExtendedData>(
    `document-types/${id}/tat-extended?${queryParameters}`
  );
};
