import { Theme } from 'styles/themes';
import { FlexContainer } from 'styles/utils';
import { RadioButton } from 'components/RadioButton';
import { HTMLAttrID } from 'models';
import { RadioButtonStyles } from 'components/RadioButton/RadioButton';

export interface RadioButtonGroupOption {
  text: string;
  value: any;
  disabled?: boolean;
}

export interface RadioButtonGroupProps extends HTMLAttrID, RadioButtonStyles {
  radioButtonGroupOptions: RadioButtonGroupOption[];
  value: any;
  setValue: React.Dispatch<any>;
  name: string;
  hasDividers?: boolean;
  fullWidth?: boolean;
  style?: React.CSSProperties;
  radioButtonGroupContainerStyle?: React.CSSProperties;
  direction?: 'row' | 'column';
}

export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = (props) => {
  return (
    <FlexContainer
      id={`${props.id}Container`}
      flexDirection={props.direction || 'column'}
      display={props.fullWidth ? 'flex' : 'inline-flex'}
      onChange={(e: any) =>
        props.setValue ? props.setValue(e.target.value) : null
      }
      style={{ ...props.radioButtonGroupContainerStyle }}
    >
      {props.radioButtonGroupOptions.map((radioButtonGroupOption, y, z) => (
        <RadioButton
          key={radioButtonGroupOption.value}
          id={`${props.id}-${radioButtonGroupOption.value}`}
          text={radioButtonGroupOption.text}
          value={radioButtonGroupOption.value}
          checked={radioButtonGroupOption.value === props.value}
          disabled={radioButtonGroupOption.disabled}
          name={props.name}
          style={{
            padding: props.hasDividers ? '16px' : '0',
            boxShadow:
              props.hasDividers && y < z.length - 1
                ? `0px 1px 0px ${Theme.colors.grayBorder}`
                : 'none',
            ...props.style,
          }}
          radioSize={props.radioSize}
          fontSize={props.fontSize}
        />
      ))}
    </FlexContainer>
  );
};
