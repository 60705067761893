import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { Login } from 'pages/auth/login';
import { Signup } from 'pages/auth/signup';
import { Home } from 'pages/home';
import { Detail } from 'pages/detail';
import {
  UserManagement,
  FeedManagement,
  TeamManagement,
} from 'pages/management';
import {
  PerformanceReport,
  TurnaroundTimeDetails,
  TurnaroundTimeReport,
} from 'pages/reports';
import { routes } from './routes';
import { ProtectedRoute } from './ProtectedRoute';
import { ForgotPassword } from 'pages/auth/forgot-password';
import { ChangePassword } from 'pages/auth/change-password';
import { UrlExpired } from 'pages/auth/url-expired';
import {
  PerformanceDetails,
  PerformanceDevelopmentList,
} from 'pages/reports/performance/components';
import { useAppSelector } from 'store/hooks';
import { selectAuthenticationResponse } from 'store/authentication/authentication-slice';
import { Navbar } from 'components/Navbar';
import { memo, useEffect, useMemo, useState } from 'react';
import { Profile } from 'pages/profile';
import { EnvironmentWrapper } from 'components/Environment';
import { UserRoles } from 'enums';
import {
  AdminElasticsearch,
  AdminDevelopments,
  AdminAuthorities,
  AdminObligations,
  AdminEnvironments,
} from 'pages/admin';
import { Copilot } from 'pages/copilot';
import { NewsBrief } from 'pages/news-brief';
import { selectSelectedEnvironment } from 'store/environment/environment-slice';
import { getCanViewCopilot } from 'helpers/filters';

export const RouterConfig = () => {
  const authenticationResponse = useAppSelector(selectAuthenticationResponse);
  const isLoggedIn = !!authenticationResponse;
  const isManager = !!authenticationResponse?.isManager;
  const hasSuperadminRole = !!authenticationResponse?.roles?.includes(
    UserRoles.Superadmin
  );
  const [isShowCopilot, setIsShowCopilot] = useState(false);
  const selectedEnvironment = useAppSelector(selectSelectedEnvironment);

  const isNewsBriefShow = process.env.REACT_APP_IS_NEWS_BRIEF_SHOW === 'true';

  const returnUrl = useMemo(() => {
    const search = location.search;
    if (search.includes('returnUrl=')) {
      let returnUrl = location.search.split('returnUrl=')[1];
      if (returnUrl && returnUrl.includes('&')) {
        returnUrl = returnUrl.split('&')[0];
      }
      return returnUrl;
    }
    return null;
  }, [isLoggedIn]);

  useEffect(() => {
    const elasticsearchIndexPrefix =
      selectedEnvironment?.elasticsearchIndexPrefix || '';
    const isShow = getCanViewCopilot(elasticsearchIndexPrefix) || false;
    setIsShowCopilot(isShow);
  }, [selectedEnvironment]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to={routes.home} />} />
        <Route
          path={routes.login}
          element={
            <ProtectedRoute
              condition={!isLoggedIn}
              urlToNavigateTo={returnUrl || routes.home}
            >
              <Login />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.signup}
          element={
            <ProtectedRoute
              condition={!isLoggedIn}
              urlToNavigateTo={routes.home}
            >
              <Signup />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.forgotPassword}
          element={
            <ProtectedRoute
              condition={!isLoggedIn}
              urlToNavigateTo={routes.home}
            >
              <ForgotPassword />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.newPassword}
          element={
            <ProtectedRoute
              condition={!isLoggedIn}
              urlToNavigateTo={routes.home}
            >
              <ChangePassword />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.urlExpired}
          element={
            <ProtectedRoute
              condition={!isLoggedIn}
              urlToNavigateTo={routes.home}
            >
              <UrlExpired />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.home}
          element={
            <ProtectedRoute
              condition={isLoggedIn}
              urlToNavigateTo={routes.login}
              withReturnUrl
            >
              <EnvironmentWrapper>
                <>
                  <Navbar />
                  <Home />
                </>
              </EnvironmentWrapper>
            </ProtectedRoute>
          }
        />
        {isNewsBriefShow && (
          <Route
            path={routes.newsBrief}
            element={
              <ProtectedRoute
                condition={isLoggedIn && hasSuperadminRole}
                urlToNavigateTo={routes.login}
                withReturnUrl
              >
                <EnvironmentWrapper>
                  <>
                    <Navbar />
                    <NewsBrief />
                  </>
                </EnvironmentWrapper>
              </ProtectedRoute>
            }
          />
        )}
        {isShowCopilot && (
          <Route
            path={routes.copilot}
            element={
              <ProtectedRoute
                condition={isLoggedIn}
                urlToNavigateTo={routes.login}
                withReturnUrl
              >
                <EnvironmentWrapper>
                  <>
                    <Navbar />
                    <Copilot />
                  </>
                </EnvironmentWrapper>
              </ProtectedRoute>
            }
          />
        )}
        <Route
          path={routes.developmentDetail}
          element={
            <ProtectedRoute
              condition={isLoggedIn}
              urlToNavigateTo={routes.login}
              withReturnUrl
            >
              <EnvironmentWrapper>
                <>
                  <Navbar />
                  <Detail />
                </>
              </EnvironmentWrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.management}
          element={<Navigate to={routes.userManagement} />}
        />
        <Route
          path={routes.userManagement}
          element={
            <ProtectedRoute
              condition={isLoggedIn && isManager}
              urlToNavigateTo={routes.home}
              withReturnUrl
            >
              <EnvironmentWrapper>
                <>
                  <Navbar isExpanded />
                  <UserManagement />
                </>
              </EnvironmentWrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.teamManagement}
          element={
            <ProtectedRoute
              condition={isLoggedIn && isManager}
              urlToNavigateTo={routes.home}
              withReturnUrl
            >
              <EnvironmentWrapper>
                <>
                  <Navbar isExpanded />
                  <TeamManagement />
                </>
              </EnvironmentWrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.feedManagement}
          element={
            <ProtectedRoute
              condition={isLoggedIn && isManager}
              urlToNavigateTo={routes.home}
              withReturnUrl
            >
              <EnvironmentWrapper>
                <>
                  <Navbar isExpanded />
                  <FeedManagement />
                </>
              </EnvironmentWrapper>
            </ProtectedRoute>
          }
        />
        <>
          <Route
            path={routes.reports}
            element={<Navigate to={routes.turnaroundTimeReport} />}
          />
          <Route
            path={routes.performanceReport}
            element={
              <ProtectedRoute
                condition={isLoggedIn}
                urlToNavigateTo={routes.login}
                withReturnUrl
              >
                <EnvironmentWrapper>
                  <>
                    <Navbar isExpanded />
                    <PerformanceReport />
                  </>
                </EnvironmentWrapper>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.turnaroundTimeReport}
            element={
              <ProtectedRoute
                condition={isLoggedIn}
                urlToNavigateTo={routes.login}
                withReturnUrl
              >
                <EnvironmentWrapper>
                  <>
                    <Navbar isExpanded />
                    <TurnaroundTimeReport />
                  </>
                </EnvironmentWrapper>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.performanceDetails}
            element={
              <ProtectedRoute
                condition={isLoggedIn}
                urlToNavigateTo={routes.login}
                withReturnUrl
              >
                <EnvironmentWrapper>
                  <>
                    <Navbar isExpanded />
                    <PerformanceDetails />
                  </>
                </EnvironmentWrapper>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.turnaroundTimeDetails}
            element={
              <ProtectedRoute
                condition={isLoggedIn}
                urlToNavigateTo={routes.login}
                withReturnUrl
              >
                <EnvironmentWrapper>
                  <>
                    <Navbar isExpanded />
                    <TurnaroundTimeDetails />
                  </>
                </EnvironmentWrapper>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.performanceDevelopmentList}
            element={
              <ProtectedRoute
                condition={isLoggedIn}
                urlToNavigateTo={routes.login}
                withReturnUrl
              >
                <EnvironmentWrapper>
                  <>
                    <Navbar isExpanded />
                    <PerformanceDevelopmentList />
                  </>
                </EnvironmentWrapper>
              </ProtectedRoute>
            }
          />
        </>
        <Route
          path={routes.profile}
          element={
            <ProtectedRoute
              condition={isLoggedIn}
              urlToNavigateTo={routes.login}
              withReturnUrl
            >
              <EnvironmentWrapper>
                <>
                  <Navbar isExpanded />
                  <Profile />
                </>
              </EnvironmentWrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.admin}
          element={<Navigate to={routes.adminElasticsearch} />}
        />
        <Route
          path={routes.adminElasticsearch}
          element={
            <ProtectedRoute
              condition={isLoggedIn && hasSuperadminRole}
              urlToNavigateTo={routes.home}
              withReturnUrl
            >
              <EnvironmentWrapper>
                <>
                  <Navbar isExpanded />
                  <AdminElasticsearch />
                </>
              </EnvironmentWrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.adminDevelopments}
          element={
            <ProtectedRoute
              condition={isLoggedIn && hasSuperadminRole}
              urlToNavigateTo={routes.home}
              withReturnUrl
            >
              <EnvironmentWrapper>
                <>
                  <Navbar isExpanded />
                  <AdminDevelopments />
                </>
              </EnvironmentWrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.adminAuthorities}
          element={
            <ProtectedRoute
              condition={isLoggedIn && hasSuperadminRole}
              urlToNavigateTo={routes.home}
              withReturnUrl
            >
              <EnvironmentWrapper>
                <>
                  <Navbar isExpanded />
                  <AdminAuthorities />
                </>
              </EnvironmentWrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.adminObligations}
          element={
            <ProtectedRoute
              condition={isLoggedIn && hasSuperadminRole}
              urlToNavigateTo={routes.home}
              withReturnUrl
            >
              <EnvironmentWrapper>
                <>
                  <Navbar isExpanded />
                  <AdminObligations />
                </>
              </EnvironmentWrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.adminEnvironments}
          element={
            <ProtectedRoute
              condition={isLoggedIn && hasSuperadminRole}
              urlToNavigateTo={routes.home}
              withReturnUrl
            >
              <EnvironmentWrapper>
                <>
                  <Navbar isExpanded />
                  <AdminEnvironments />
                </>
              </EnvironmentWrapper>
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default memo(RouterConfig);
