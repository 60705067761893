import { localStorageKeys } from 'config/local-storage-keys';
import { useEffect, useState } from 'react';

export const useUserActivity = (startTime: number) => {
  const [timer, setTimer] = useState(startTime);
  localStorage.setItem(
    localStorageKeys.isPerformingIndefiniteOperation,
    btoa('false')
  );
  useEffect(() => {
    const myInterval = setInterval(() => {
      const isPerformingIndefiniteOperation = atob(
        localStorage.getItem(
          localStorageKeys.isPerformingIndefiniteOperation
        ) || btoa('null')
      );
      if (timer > 0 && isPerformingIndefiniteOperation !== 'true') {
        setTimer(timer - 1);
      }
    }, 1000);
    const resetTimeout = () => {
      setTimer(startTime);
    };
    const events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress',
    ];
    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }
    return () => {
      clearInterval(myInterval);
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
      }
    };
  });
  return timer;
};
