import { useEffect, useRef } from 'react';
import { Theme } from 'styles/themes';

interface OutputConsoleProps {
  outputs: string[];
  height: string;
}

export const OutputConsole = (props: OutputConsoleProps) => {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollableContainerRef.current?.scrollTo(
      0,
      scrollableContainerRef.current.scrollHeight
    );
  }, [JSON.stringify(props.outputs)]);

  return (
    <div
      style={{
        backgroundColor: Theme.colors.black,
        height: props.height,
        padding: '16px',
        overflowY: 'auto',
      }}
      ref={scrollableContainerRef}
    >
      {props.outputs &&
        !!props.outputs.length &&
        props.outputs.map((output, y) => {
          return (
            <span
              key={y}
              style={{
                color: Theme.colors.white,
                display: 'block',
                marginBottom: y === props.outputs.length - 1 ? '0' : '8px',
                wordBreak: 'break-word',
              }}
            >
              {output}
            </span>
          );
        })}
    </div>
  );
};
