import { useMemo, useRef, useState } from 'react';
import { BoldText, CursorPointer, FlexContainer } from 'styles/utils';
import { ReactComponent as ArrowDown } from 'assets/arrow-down.svg';
import {
  Dropdown,
  DropdownOption,
  DropdownOptionButton,
  DropdownOptionLabel,
  DropdownOptionsContainer,
} from 'components/Dropdown';
import {
  RadioButtonGroup,
  RadioButtonGroupOption,
} from 'components/RadioButtonGroup';
import { useAppDispatch } from 'store/hooks';
import { Theme } from 'styles/themes';
import { SortByDirectionButton } from 'components/SortByDirectionButton';
import { SortByDirection } from 'enums';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Option } from 'lib/utils/Option';
import { pascalCase } from 'utils/string-formatter';
import { Feed } from 'models';

interface FeedsDropdownProps {
  options: Feed[];
  selectedFeed?: Feed;
  handleSelectFeed: (feed: Feed) => void;
}

export interface FeedsDropdownOption {
  label: string;
  onClick?: () => void;
}

export const FeedsDropdown = (props: FeedsDropdownProps) => {
  const dispatch = useAppDispatch();
  const dropdownContainerRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <FlexContainer
      id="FeedsDropdownMainContainer"
      flexWrap="nowrap"
      flexDirection="row"
      style={{ width: '100%', flex: 1 }}
      ref={dropdownContainerRef}
    >
      <CursorPointer
        style={{
          display: 'flex',
          alignItems: 'center',
          color: Theme.colors.primaryColor,
        }}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <BoldText id={`FeedsDropdownTitle`}>
          <span
            style={{
              marginRight: '10px',
            }}
          >
            {props.selectedFeed?.name}
          </span>
        </BoldText>
      </CursorPointer>
      <div id={`FeedsDropdownOuterContainer`} style={{ position: 'relative' }}>
        <CursorPointer onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
          <ArrowDown
            id={`FeedsDropdownArrowDown`}
            style={{
              color: Theme.colors.grayDark,
            }}
          />
        </CursorPointer>
        <Dropdown
          id={'FeedsDropdown'}
          isOpen={isDropdownOpen}
          setIsOpen={setIsDropdownOpen}
          containerRef={dropdownContainerRef}
          transform="translate(-94%, 41px)"
          style={{ border: `1px solid ${Theme.colors.grayBorder}` }}
        >
          <DropdownOptionsContainer id={`FeedsDropdownOptionsContainer`}>
            {props.options.map((option, index) => (
              <DropdownOption
                id={`FeedsDropdown${pascalCase(option.name)}Option`}
                key={option.name}
                removeDivider={!index}
              >
                <DropdownOptionButton
                  id={`FeedsDropdown${pascalCase(option.name)}OptionButton`}
                  onClick={() => props.handleSelectFeed(option)}
                >
                  <DropdownOptionLabel
                    id={`FeedsDropdown${pascalCase(option.name)}OptionLabel`}
                  >
                    {option.name}
                  </DropdownOptionLabel>
                </DropdownOptionButton>
              </DropdownOption>
            ))}
          </DropdownOptionsContainer>
        </Dropdown>
      </div>
    </FlexContainer>
  );
};
