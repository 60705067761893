import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  CloseIconContainer,
  Modal,
  ModalContentContainer,
  ModalInnerContainer,
  ModalTitle,
} from 'components/Modal';
import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { ReactComponent as Close } from 'assets/close.svg';
import { FlexContainer } from 'styles/utils';
import { Searchbar } from 'components/Searchbar';
import { InfiniteList } from 'components/InfiniteList';
import { Loading } from 'components/Loading';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getAllAssignToTeamModalTeamsAsync,
  increaseAssignToTeamModalTeamsPage,
  resetAssignToTeamModalTeams,
  resetAssignToTeamModalTeamsPage,
  resetAssignToTeamModalTeamsSearchTerm,
  selectAssignToTeamModalTeams,
  selectAssignToTeamModalTeamsPage,
  selectAssignToTeamModalTeamsPerPage,
  selectAssignToTeamModalTeamsSearchTerm,
  selectAssignToTeamModalTeamsTotalRecords,
  selectTriggerAssignToTeamModalTeamsUpdate,
  setAssignToTeamModalTeamsSearchTerm,
  setTriggerAssignToTeamModalTeamsUpdate,
} from 'store/assign-to-team-modal-team/assign-to-team-modal-team-slice';
import { TeamCard } from './TeamCard';
import { addOrRemoveIfExists } from 'utils/array';
import { Button, OutlinedButton } from 'components/Button';
import { addUsersToTeamsAsync } from 'store/management-user/management-user-slice';
import { addFeedsToTeamsAsync } from 'store/feed/management-feed-slice';
import { Team } from 'models';

interface AssignToTeamModalProps {
  isDisplayed: boolean;
  setIsDisplayed: Dispatch<SetStateAction<boolean>>;
  type: AssignToTeamModalTypes;
  onAddToTeams?: (teams: Team[]) => Promise<void>;
}

export enum AssignToTeamModalTypes {
  AssignToTeam,
  AddToTeam,
}

const AssignToTeamModalContainer = styled.div({
  display: 'flex',
  width: '544px',
  maxHeight: '800px',
});

export const AssignToTeamModal: React.FC<AssignToTeamModalProps> = (
  props: AssignToTeamModalProps
) => {
  const dispatch = useAppDispatch();
  const teams = useAppSelector(selectAssignToTeamModalTeams);
  const totalRecords = useAppSelector(selectAssignToTeamModalTeamsTotalRecords);
  const searchTerm = useAppSelector(selectAssignToTeamModalTeamsSearchTerm);
  const page = useAppSelector(selectAssignToTeamModalTeamsPage);
  const perPage = useAppSelector(selectAssignToTeamModalTeamsPerPage);
  const triggerTeamsUpdate = useAppSelector(
    selectTriggerAssignToTeamModalTeamsUpdate
  );
  const [scrollableContainer, setScrollableContainer] =
    useState<HTMLDivElement | null>(null);
  const [selectedTeamIds, setSelectedTeamIds] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(getAllAssignToTeamModalTeamsAsync());
  }, [page, searchTerm, triggerTeamsUpdate]);

  const closeModal = () => {
    props.setIsDisplayed(false);
    setSelectedTeamIds([]);
    dispatch(resetAssignToTeamModalTeams());
    dispatch(resetAssignToTeamModalTeamsPage());
    dispatch(resetAssignToTeamModalTeamsSearchTerm());
    dispatch(setTriggerAssignToTeamModalTeamsUpdate());
    setIsLoading(false);
  };

  const addToTeams = async () => {
    setIsLoading(true);
    if (props.type === AssignToTeamModalTypes.AddToTeam) {
      if (props.onAddToTeams) {
        const teamsToBeAddedTo = teams.filter(
          (t) => t.id && selectedTeamIds.includes(t.id)
        );
        await props.onAddToTeams(teamsToBeAddedTo);
      } else {
        await dispatch(addUsersToTeamsAsync(false, true, selectedTeamIds));
      }
    } else if (props.type === AssignToTeamModalTypes.AssignToTeam) {
      await dispatch(addFeedsToTeamsAsync(false, true, selectedTeamIds));
    }
    closeModal();
  };

  const isAnyTeamSelected = useMemo(() => {
    return !!(selectedTeamIds && selectedTeamIds.length);
  }, [selectedTeamIds]);

  return (
    <Modal
      id="AssignToTeamModal"
      isDisplayed={props.isDisplayed}
      isLoading={isLoading}
    >
      <AssignToTeamModalContainer id="AssignToTeamModalContainer">
        <ModalContentContainer id="AssignToTeamModalContentContainer">
          <ModalInnerContainer id="AssignToTeamModalHeader">
            <ModalTitle
              id="AssignToTeamModalTitle"
              withoutMarginBottom={true}
              color={Theme.colors.primaryColor}
            >
              {props.type === AssignToTeamModalTypes.AddToTeam && 'Add to team'}
              {props.type === AssignToTeamModalTypes.AssignToTeam &&
                'Assign to team'}
            </ModalTitle>
            <CloseIconContainer id="AssignToTeamModalCloseIconContainer">
              <Close
                id="AssignToTeamModalCloseButton"
                onClick={() => closeModal()}
              />
            </CloseIconContainer>
          </ModalInnerContainer>
          <ModalInnerContainer id="AssignToTeamModalBodyContainer">
            <FlexContainer
              id="AssignToTeamModalBody"
              flexDirection="column"
              style={{ width: '100%' }}
            >
              <Searchbar
                id="AssignToTeamModalSearchbar"
                value={searchTerm}
                setValue={(value) => {
                  dispatch(setAssignToTeamModalTeamsSearchTerm(value));
                }}
                placeholder="Search teams..."
              />
              <div
                id="AssignToTeamModalTeamListOuterContainer"
                ref={(element) => setScrollableContainer(element)}
                style={{
                  height: '282px',
                  width: '100%',
                  overflowY: 'auto',
                  marginTop: '16px',
                  border: `1px solid ${Theme.colors.grayBorder}`,
                  borderRadius: Theme.borderRadius,
                }}
              >
                {scrollableContainer && (
                  <InfiniteList
                    targetRef={scrollableContainer}
                    onReachBottom={() =>
                      dispatch(increaseAssignToTeamModalTeamsPage())
                    }
                    hasMorePages={page < Math.ceil(totalRecords / perPage)}
                    loader={<Loading id="AssignToTeamModalTeamListLoading" />}
                  >
                    {teams.map((team, i) => {
                      if (team.id) {
                        return (
                          <TeamCard
                            key={team.id}
                            id={team.id}
                            name={team.name}
                            usersCount={team.usersCount || 0}
                            onSelect={(teamId) => {
                              const selectedTeamIdsClone = [...selectedTeamIds];
                              setSelectedTeamIds(
                                addOrRemoveIfExists(
                                  selectedTeamIdsClone,
                                  teamId
                                )
                              );
                            }}
                            selectedIds={selectedTeamIds}
                            isLast={i === teams.length - 1}
                          />
                        );
                      } else {
                        return <></>;
                      }
                    })}
                  </InfiniteList>
                )}
              </div>
            </FlexContainer>
          </ModalInnerContainer>
          <ModalInnerContainer
            id="AssignToTeamModalFooter"
            withoutBorderBottom={true}
            padding="12px 16px"
          >
            <FlexContainer
              id="AssignToTeamModalFooterContainer"
              style={{ alignItems: 'center', fontSize: Theme.fontSize - 2 }}
            >
              {!isAnyTeamSelected && (
                <span id="AssignToTeamModalSelectAtLeastOneTeamMessage">
                  {props.type === AssignToTeamModalTypes.AddToTeam &&
                    'Select at least one team to add to'}
                  {props.type === AssignToTeamModalTypes.AssignToTeam &&
                    'Select at least one team to assign to'}
                </span>
              )}
              {isAnyTeamSelected && (
                <span id="AssignToTeamModalSelectTeamMessage">
                  Teams selected{' '}
                  <span
                    id="AssignToTeamModalTeamsSelectedCount"
                    style={{
                      color: Theme.colors.secondaryColor,
                      fontWeight: 'bold',
                    }}
                  >
                    ({selectedTeamIds.length})
                  </span>
                </span>
              )}
            </FlexContainer>
            <FlexContainer
              id="AssignToTeamModalButtonsContainer"
              style={{ marginLeft: 'auto' }}
            >
              <OutlinedButton
                id="AssignToTeamModalCancelButton"
                color={Theme.colors.secondaryColor}
                style={{ marginRight: '10px' }}
                onClick={() => closeModal()}
              >
                Cancel
              </OutlinedButton>
              <Button
                id="AssignToTeamModalSubmitButton"
                onClick={addToTeams}
                isDisabled={!isAnyTeamSelected}
              >
                {props.type === AssignToTeamModalTypes.AddToTeam &&
                  'Add to team'}
                {props.type === AssignToTeamModalTypes.AssignToTeam &&
                  'Assign to team'}
              </Button>
            </FlexContainer>
          </ModalInnerContainer>
        </ModalContentContainer>
      </AssignToTeamModalContainer>
    </Modal>
  );
};
