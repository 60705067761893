import styled from '@emotion/styled';
import { Table, Tbody, Td, Th, Thead, Tr } from 'components/Table';
import { CountryFlagIcon } from 'components/CountryFlagIcon/CountryFlagIcon';
import { Theme } from 'styles/themes';
import { BoldText, FlexContainer } from 'styles/utils';
import { formatNumber } from 'utils/number-formatter';
import { useMemo, useState } from 'react';
import { ArrowDirections, Tooltip } from 'components/Tooltip';
import { ReactComponent as Info } from 'assets/info.svg';
import { ReactComponent as ArrowUp } from 'assets/arrow-drop-up.svg';
import { ReactComponent as ArrowDown } from 'assets/arrow-drop-down.svg';
import { BlueChip } from 'components/Chip';
import { PerformanceReportEntity } from 'models/PerformanceReportEntity';
import { RedirectToDevelopmentsButton } from 'pages/reports/performance/components/RedirectToDevelopmentsButton';
import { DevelopmentReviewStatuses } from 'enums';
import { useParams } from 'react-router-dom';
import { Team } from 'models';
import { reportByOptions } from 'pages/reports/performance/report-by-options';
import { useAppSelector } from 'store/hooks';
import { selectPeriodProperty } from 'store/performance-report/performance-report-slice';

const StyledTable = styled(Table)({
  border: `1px solid ${Theme.colors.grayBorder}`,
  position: 'relative',
  boxShadow: 'none',
});

const StyledTd = styled(Td)({
  border: `1px solid ${Theme.colors.grayBorder}`,
  borderCollapse: 'collapse',
  padding: '16px',
});

const StyledTh = styled(Th)({
  border: `1px solid ${Theme.colors.grayBorder}`,
  borderCollapse: 'collapse',
});

const TdText = styled.span({
  fontSize: Theme.fontSize,
});

const TooltipContainer = styled.div({
  marginLeft: 'auto',
  position: 'relative',
  height: '20px',
});

const TooltipTextContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '220px',
  height: '44px',
  textAlign: 'center',
  fontSize: Theme.fontSize - 2,
  lineHeight: '22px',
});

const TooltipText = styled.span({
  fontWeight: 'normal',
});

const SectionTitle = styled(FlexContainer)({
  marginTop: '24px',
  marginBottom: '16px',
});

export const PerformanceDetailsTables = ({
  data,
  isCountry,
  teams,
  reportFieldText,
}: PerformanceDetailsTablesProps) => {
  const { entityType, id } = useParams<{ entityType: string; id: string }>();
  const period = useAppSelector(selectPeriodProperty);
  const [tooltipContainerElement, setTooltipContainerElement] =
    useState<HTMLDivElement | null>(null);
  const [isTooltipHovered, setIsTooltipHovered] = useState(false);

  const reportByOptionEntity = useMemo(() => {
    return Object.values(reportByOptions).find((v) => v.urlText === entityType);
  }, [entityType]);

  const increaseBetweenPreviousAndCurrentPeriodDevelopmentsAverageReviewTime =
    useMemo(() => {
      return (
        Number(
          formatNumber(
            data.increaseBetweenPreviousAndCurrentPeriodDevelopmentsAverageReviewTime ||
              0
          )
        ) * 100
      );
    }, [data]);

  const absoluteAverage = useMemo(() => {
    return Math.abs(
      increaseBetweenPreviousAndCurrentPeriodDevelopmentsAverageReviewTime
    ).toFixed(2);
  }, [increaseBetweenPreviousAndCurrentPeriodDevelopmentsAverageReviewTime]);

  const teamsBody = teams.map((team) => (
    <Tr style={{ height: '48px' }}>
      <StyledTd>
        <TdText>{team.name}</TdText>
      </StyledTd>
      <StyledTd>
        <TdText>{team.usersCount}</TdText>
      </StyledTd>
      <StyledTd>
        {team.teamFeeds?.map((feed, index) => (
          <BlueChip key={index} style={{ maxWidth: '275px' }}>
            {feed.feedName}
          </BlueChip>
        ))}
      </StyledTd>
    </Tr>
  ));

  return (
    <>
      <StyledTable>
        <Thead>
          <Tr style={{ height: '48px' }}>
            <StyledTh width="228px" padding="12px 16px">
              {reportFieldText}
            </StyledTh>
            <StyledTh width="152px" padding="12px 16px">
              <FlexContainer>New devs</FlexContainer>
            </StyledTh>
            <StyledTh width="152px" padding="12px 16px">
              <FlexContainer>Completed</FlexContainer>
            </StyledTh>
            <StyledTh width="152px" padding="12px 16px">
              <FlexContainer>No action</FlexContainer>
            </StyledTh>
            <StyledTh width="152px" padding="12px 16px">
              <FlexContainer>Actionable</FlexContainer>
            </StyledTh>
          </Tr>
        </Thead>
        <Tbody>
          <Tr style={{ height: '48px' }}>
            <StyledTd
              style={{
                backgroundColor: `${Theme.colors.secondaryColor}0D`,
              }}
            >
              <FlexContainer style={{ alignItems: 'center' }}>
                {isCountry && !!data.name && (
                  <CountryFlagIcon countryName={data.name} marginRight={12} />
                )}
                <TdText
                  style={{
                    color: Theme.colors.secondaryColor,
                    fontWeight: 'bold',
                  }}
                >
                  {data.name}
                </TdText>
              </FlexContainer>
            </StyledTd>
            <StyledTd>
              <TdText>{formatNumber(data.newDevelopmentsCount!)}</TdText>
            </StyledTd>
            <StyledTd>
              <FlexContainer style={{ justifyContent: 'space-between' }}>
                <TdText>
                  {formatNumber(data.completedDevelopmentsCount!)}
                </TdText>
                <RedirectToDevelopmentsButton
                  reviewStatuses={[
                    DevelopmentReviewStatuses.ActionRequired,
                    DevelopmentReviewStatuses.NoActionRequired,
                  ]}
                  entityType={reportByOptionEntity!.value}
                  id={id}
                  period={period}
                />
              </FlexContainer>
            </StyledTd>
            <StyledTd>
              <FlexContainer style={{ justifyContent: 'space-between' }}>
                <TdText>
                  {formatNumber(data.notActionableDevelopmentsCount!)}
                </TdText>
                <RedirectToDevelopmentsButton
                  reviewStatuses={[DevelopmentReviewStatuses.NoActionRequired]}
                  entityType={reportByOptionEntity!.value}
                  id={id}
                  period={period}
                />
              </FlexContainer>
            </StyledTd>
            <StyledTd>
              <FlexContainer
                style={{ justifyContent: 'space-between', paddingRight: 3 }}
              >
                <TdText>
                  {formatNumber(data.actionableDevelopmentsCount!)}
                </TdText>
                <RedirectToDevelopmentsButton
                  reviewStatuses={[DevelopmentReviewStatuses.ActionRequired]}
                  entityType={reportByOptionEntity!.value}
                  id={id}
                  period={period}
                />
              </FlexContainer>
            </StyledTd>
          </Tr>
        </Tbody>
      </StyledTable>
      <SectionTitle>
        <span>Development review time</span>
        <TooltipContainer
          style={{ marginLeft: 12 }}
          ref={(tooltipContainer) =>
            setTooltipContainerElement(tooltipContainer)
          }
        >
          <Tooltip
            relativeToElement={tooltipContainerElement}
            hasArrow
            arrowDirection={ArrowDirections.Down}
          >
            <TooltipTextContainer>
              <TooltipText>Average completion time of a</TooltipText>
              <TooltipText> development after upload</TooltipText>
            </TooltipTextContainer>
          </Tooltip>
          <Info
            onMouseOver={() => setIsTooltipHovered(true)}
            onMouseLeave={() => setIsTooltipHovered(false)}
            style={{
              color: isTooltipHovered
                ? Theme.colors.secondaryColor
                : Theme.colors.grayDark,
            }}
          />
        </TooltipContainer>
      </SectionTitle>
      <StyledTable>
        <Thead>
          <Tr style={{ height: '48px' }}>
            <StyledTh width="228px" padding="12px 16px">
              Current time
            </StyledTh>
            <StyledTh width="152px" padding="12px 16px">
              <FlexContainer>Previous time</FlexContainer>
            </StyledTh>
          </Tr>
        </Thead>
        <Tbody>
          <Tr style={{ height: '48px' }}>
            <StyledTd>
              <TdText>
                {data.developmentsAverageReviewTimeInDaysAndHours}
              </TdText>
            </StyledTd>
            <StyledTd
              style={{ alignItems: 'center', display: 'flex', border: 'none' }}
            >
              {increaseBetweenPreviousAndCurrentPeriodDevelopmentsAverageReviewTime >
                0 && <ArrowUp />}
              {increaseBetweenPreviousAndCurrentPeriodDevelopmentsAverageReviewTime <
                0 && <ArrowDown />}
              <TdText>
                <BoldText>{absoluteAverage}%</BoldText> (
                {data.previousPeriodDevelopmentsAverageReviewTimeInDaysAndHours}
                )
              </TdText>
            </StyledTd>
          </Tr>
        </Tbody>
      </StyledTable>
      <SectionTitle>
        <span>
          Teams <BoldText>({teams.length})</BoldText>
        </span>
      </SectionTitle>
      <StyledTable>
        <Thead>
          <Tr style={{ height: '48px' }}>
            <StyledTh width="160px" padding="12px 16px">
              Name
            </StyledTh>
            <StyledTh width="160px" padding="12px 16px">
              <FlexContainer>Members</FlexContainer>
            </StyledTh>
            <StyledTh padding="12px 16px">
              <FlexContainer>Feeds</FlexContainer>
            </StyledTh>
          </Tr>
        </Thead>
        <Tbody>{teamsBody}</Tbody>
      </StyledTable>
    </>
  );
};

interface PerformanceDetailsTablesProps {
  isCountry: boolean;
  data: PerformanceReportEntity;
  teams: Team[];
  reportFieldText?: string;
}
