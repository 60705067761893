import { filterKeys } from 'config/filter-keys';
import { PerformanceReportEntities } from 'enums';

export const reportByOptions = {
  [PerformanceReportEntities.Language]: {
    text: 'Language',
    searchBarText: 'languages',
    queryParam: 'languageId',
    urlText: 'language',
    value: PerformanceReportEntities.Language,
  },
  [PerformanceReportEntities.Country]: {
    text: 'Jurisdiction',
    searchBarText: 'jurisdiction',
    queryParam: filterKeys.country,
    urlText: 'country',
    value: PerformanceReportEntities.Country,
  },
  [PerformanceReportEntities.Authority]: {
    text: 'Issuing Authority',
    searchBarText: 'issuing authority',
    queryParam: filterKeys.authority,
    urlText: 'authority',
    value: PerformanceReportEntities.Authority,
  },
  [PerformanceReportEntities.DocumentType]: {
    text: 'Document type',
    searchBarText: 'document types',
    queryParam: filterKeys.documentType,
    urlText: 'document_type',
    value: PerformanceReportEntities.DocumentType,
  },
};
