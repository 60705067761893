import { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'navigation/routes';
import {
  getAllDevelopmentsAsync,
  increaseDevelopmentsPage,
  resetDevelopmentsPage,
  selectDevelopments,
  selectDevelopmentsPage,
  selectDevelopmentsSearchTerm,
  selectDevelopmentsSortByProperty,
  selectDevelopmentsSortByDirection,
  selectDevelopmentsPerPage,
  selectTriggerFeedUpdate,
  setTriggerFeedUpdate,
  updateDevelopment,
  selectDevelopmentsSearchProperties,
  selectIsAdvancedSearchEnabled,
  setIsAdvancedSearchEnabled,
  selectHomePreventsGetDevelopments,
  setHomePreventsGetDevelopments,
  selectDevelopmentsTotalRecords,
  selectPdfContentSnippets,
} from 'store/development/development-slice';
import {
  selectActiveFilters,
  selectFeedActiveFilters,
  setAllActiveFilters,
  setAllFeedActiveFilters,
} from 'store/home-sidebar-filter/home-sidebar-filter-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectScrollPosition,
  setScrollPosition,
} from 'store/scroll-sync/scroll-sync-slice';
import { FlexContainer } from 'styles/utils';
import {
  HomeSidebar,
  HomeHeader,
  HomeSubHeader,
  HomeAdvancedSearch,
} from './components';
import { Theme } from 'styles/themes';
import {
  CreateFeedModal,
  CreateFeedModalTypes,
} from 'components/CreateFeedModal';
import {
  getUserLastViewedFeedAsync,
  selectFeedAsync,
  selectFeedPickerLastViewedFeed,
  selectFeedPickerAppliedFeed,
  setHomeWatchesAppliedFeed,
  selectHomeWatchesAppliedFeed,
} from 'store/feed/feed-picker-slice';
import { developmentKeys } from 'config/development-keys';
import { DevelopmentList } from 'components/DevelopmentList';
import { Development } from 'models';
import { filterKeys } from 'config/filter-keys';
import { throttle } from 'lodash';

export const Home = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const developments = useAppSelector(selectDevelopments);
  const pdfContentSnippets = useAppSelector(selectPdfContentSnippets);
  const searchTerm = useAppSelector(selectDevelopmentsSearchTerm);
  const searchProperties = useAppSelector(selectDevelopmentsSearchProperties);
  const sortByProperty = useAppSelector(selectDevelopmentsSortByProperty);
  const sortByDirection = useAppSelector(selectDevelopmentsSortByDirection);
  const activeFilters = useAppSelector(selectActiveFilters);
  const feedActiveFilters = useAppSelector(selectFeedActiveFilters);
  const totalRecords = useAppSelector(selectDevelopmentsTotalRecords);
  const perPage = useAppSelector(selectDevelopmentsPerPage);
  const page = useAppSelector(selectDevelopmentsPage);
  const triggerFeedUpdate = useAppSelector(selectTriggerFeedUpdate);
  const lastViewedFeed = useAppSelector(selectFeedPickerLastViewedFeed);
  const appliedFeed = useAppSelector(selectFeedPickerAppliedFeed);
  const homeWatchesAppliedFeed = useAppSelector(selectHomeWatchesAppliedFeed);
  const scrollPosition = useAppSelector(selectScrollPosition);
  const isAdvancedSearchEnabled = useAppSelector(selectIsAdvancedSearchEnabled);
  const homePreventsGetDevelopments = useAppSelector(
    selectHomePreventsGetDevelopments
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isDisplayingDevelopments, setIsDisplayingDevelopments] =
    useState(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isCreateFeedModalDisplayed, setIsCreateFeedModalDisplayed] =
    useState(false);
  const [
    createFeedModalSelectsFeedAfterUpsert,
    setCreateFeedModalSelectsFeedAfterUpsert,
  ] = useState<boolean>(true);
  const [startingScrollPosition, setStartingScrollPosition] =
    useState<number>(0);
  const isFirstTimeSettingStartingScrollPosition = useRef(true);

  useEffect(() => {
    if (!appliedFeed) {
      (async () => {
        const lastViewedFeedResponse = await dispatch(
          getUserLastViewedFeedAsync()
        );
        if (lastViewedFeedResponse) {
          await dispatch(selectFeedAsync(lastViewedFeedResponse));
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (appliedFeed && homeWatchesAppliedFeed) {
      const allFeedActiveFilters = [
        {
          propertyToFilterBy: filterKeys.authority,
          values: appliedFeed.authorityIds,
        },
        {
          propertyToFilterBy: filterKeys.language,
          values: appliedFeed.languageIds,
        },
        {
          propertyToFilterBy: filterKeys.region,
          values: appliedFeed.regionIds,
        },
        {
          propertyToFilterBy: filterKeys.country,
          values: appliedFeed.countryIds,
        },
        {
          propertyToFilterBy: filterKeys.documentType,
          values: appliedFeed.documentTypeIds,
        },
        {
          propertyToFilterBy: filterKeys.subcategory,
          values: appliedFeed.subcategoryIds,
        },
        {
          propertyToFilterBy: filterKeys.isWithinRequiredTurnaroundTime,
          values: [],
        },
        {
          propertyToFilterBy: developmentKeys.pageCount,
          values: [],
        },
        {
          propertyToFilterBy: developmentKeys.exceptionFlag,
          values: [],
        },
      ];
      dispatch(resetDevelopmentsPage());
      dispatch(setAllActiveFilters(allFeedActiveFilters));
      dispatch(setAllFeedActiveFilters(allFeedActiveFilters));
      dispatch(setTriggerFeedUpdate());
    }
  }, [appliedFeed, homeWatchesAppliedFeed]);

  useEffect(() => {
    if (!homePreventsGetDevelopments) {
      if (lastViewedFeed) {
        setIsLoading(true);
        dispatch(getAllDevelopmentsAsync())
          .catch((error: string) => {
            setErrorMessage(error);
          })
          .finally(() => {
            setTimeout(() => {
              setIsLoading(false);
            }, 1000);
          });
      }
    } else {
      dispatch(setHomePreventsGetDevelopments(false));
      setIsLoading(false);
    }
  }, [
    searchTerm,
    page,
    sortByProperty,
    sortByDirection,
    triggerFeedUpdate,
    JSON.stringify(searchProperties),
  ]);

  useEffect(() => {
    if (isFirstTimeSettingStartingScrollPosition.current) {
      setStartingScrollPosition(scrollPosition);
      isFirstTimeSettingStartingScrollPosition.current = false;
    }
  }, [scrollPosition]);

  const isFiltering = useMemo(() => {
    return Object.entries(activeFilters).some(
      ([key, values]) =>
        (activeFilters as any)[key] &&
        (activeFilters as any)[key].length > 0 &&
        (!feedActiveFilters[key] ||
          (feedActiveFilters[key] && feedActiveFilters[key].length === 0))
    );
  }, [activeFilters, feedActiveFilters]);

  const handleScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
    throttle(() => {
      const scrollTop = event.currentTarget.scrollTop;
      dispatch(setScrollPosition(scrollTop));
    }, Number.MAX_SAFE_INTEGER)();
  }, []);

  const handleDevelopmentClick = (id: string) => {
    isFirstTimeSettingStartingScrollPosition.current = true;
    navigate(routes.developmentDetail.replace(':developmentId', id));
    dispatch(setHomeWatchesAppliedFeed(false));
  };

  const handleOpenDevelopmenInNewTab = (id: string) => {
    const detailUrl =
      location.protocol +
      '//' +
      location.host +
      routes.developmentDetail.replace(':developmentId', id);
    setTimeout(() => window.open(detailUrl, '_blank', 'noreferrer'));
  };

  const handleReachBottom = () => {
    dispatch(increaseDevelopmentsPage());
  };

  const handleToggleAdvancedSearch = useCallback(() => {
    dispatch(setIsAdvancedSearchEnabled(!isAdvancedSearchEnabled));
  }, [isAdvancedSearchEnabled]);

  const handleRefreshDevelopments = useCallback(() => {
    setIsDisplayingDevelopments(false);
    dispatch(setScrollPosition(0));
    dispatch(resetDevelopmentsPage());
    dispatch(setTriggerFeedUpdate());
    setIsDisplayingDevelopments(true);
  }, []);

  return (
    <FlexContainer
      id="HomeContainer"
      flexWrap="nowrap"
      style={{
        height: '100%',
        flex: 1,
        backgroundColor: Theme.colors.primaryBackgroundColor,
        overflowX: 'auto',
      }}
    >
      <HomeSidebar
        isFiltering={isFiltering}
        setIsCreateFeedModalDisplayed={setIsCreateFeedModalDisplayed}
        setCreateFeedModalSelectsFeedAfterUpsert={
          setCreateFeedModalSelectsFeedAfterUpsert
        }
      />
      <FlexContainer
        id="HomeMainContainer"
        flexWrap="nowrap"
        flexDirection="column"
        style={{
          flex: '1 1 auto',
          overflowX: 'auto',
        }}
      >
        <HomeHeader
          setIsCreateFeedModalDisplayed={setIsCreateFeedModalDisplayed}
        />
        <HomeSubHeader
          isFiltering={isFiltering}
          isAdvancedSearchEnabled={isAdvancedSearchEnabled}
          onClickAdvancedSearchButton={handleToggleAdvancedSearch}
          onClickRefreshButton={handleRefreshDevelopments}
        />
        {isDisplayingDevelopments && (
          <>
            {isAdvancedSearchEnabled && <HomeAdvancedSearch />}
            <DevelopmentList
              isLoading={isLoading}
              onScroll={handleScroll}
              developments={developments}
              errorMessage={errorMessage}
              handleReachBottom={handleReachBottom}
              page={page}
              totalRecords={totalRecords}
              perPage={perPage}
              scrollPosition={startingScrollPosition}
              handleDevelopmentClick={handleDevelopmentClick}
              handleDevelopmentCtrlClick={handleOpenDevelopmenInNewTab}
              onAfterSave={(development: Development | null) => {
                if (
                  Object.keys(activeFilters).find(
                    (x) => x === developmentKeys.savedByUserIds
                  ) &&
                  activeFilters[developmentKeys.savedByUserIds] &&
                  activeFilters[developmentKeys.savedByUserIds].length
                ) {
                  dispatch(setTriggerFeedUpdate());
                } else {
                  if (development) {
                    dispatch(updateDevelopment(development));
                  }
                }
              }}
              maxHeightModifier={isAdvancedSearchEnabled ? -48 : 0}
              pdfContentSnippets={pdfContentSnippets}
            />
          </>
        )}
      </FlexContainer>
      <CreateFeedModal
        isDisplayed={isCreateFeedModalDisplayed}
        setIsDisplayed={setIsCreateFeedModalDisplayed}
        type={CreateFeedModalTypes.FeedPicker}
        selectsFeedAfterUpsert={createFeedModalSelectsFeedAfterUpsert}
      />
    </FlexContainer>
  );
};
