import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { Input } from 'components/Input';
import { OutputConsole } from 'components/OutputConsole';
import { localStorageKeys } from 'config/local-storage-keys';
import { RealTimeSetStandardizedAuthorityNameEvent } from 'enums';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { selectAuthenticationResponse } from 'store/authentication/authentication-slice';
import { useAppSelector } from 'store/hooks';
import { Theme } from 'styles/themes';
import { BaseSocketService } from 'utils/socket/BaseSocketService';

interface SetStandardizedAuthorityNamesCardProps {}

export const SetStandardizedAuthorityNamesCard = (
  props: SetStandardizedAuthorityNamesCardProps
) => {
  const authenticationResponse = useAppSelector(selectAuthenticationResponse);

  const [socketService, setSocketService] = useState<BaseSocketService | null>(
    null
  );
  const [outputs, setOutputs] = useState<string[]>([]);
  const [authoritiesMappingFilePath, setAuthoritiesMappingFilePath] =
    useState('');
  const [authoritiesMappingFile, setAuthoritiesMappingFile] =
    useState<File | null>(null);
  const [isProcessOnGoing, setIsProcessOnGoing] = useState<boolean>(false);

  const authoritiesMappingFileInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (authenticationResponse?.accessToken) {
      setSocketService(
        new BaseSocketService(authenticationResponse.accessToken)
      );
    }
  }, [JSON.stringify(authenticationResponse)]);

  useEffect(() => {
    if (socketService) {
      socketService.listen(
        RealTimeSetStandardizedAuthorityNameEvent.SetStandardizedAuthorityNameUpdateProgress,
        (output: string) => {
          setOutputs((currentValue) => [...currentValue, output]);
        }
      );
      socketService.listen(
        RealTimeSetStandardizedAuthorityNameEvent.SetStandardizedAuthorityNameFinished,
        () => {
          setIsProcessOnGoing(false);
          localStorage.setItem(
            localStorageKeys.isPerformingIndefiniteOperation,
            btoa('false')
          );
        }
      );
      return () => {
        socketService.disconnect();
      };
    }
  }, [socketService]);

  const handlePrimaryAction = useCallback(async () => {
    if (
      authenticationResponse &&
      socketService &&
      (authoritiesMappingFilePath || authoritiesMappingFile)
    ) {
      setOutputs([]);
      localStorage.setItem(
        localStorageKeys.isPerformingIndefiniteOperation,
        btoa('true')
      );
      setIsProcessOnGoing(true);
      if (authoritiesMappingFilePath) {
        socketService.emit(
          RealTimeSetStandardizedAuthorityNameEvent.SetStandardizedAuthorityName,
          authenticationResponse.id,
          authoritiesMappingFilePath
        );
      } else if (authoritiesMappingFile) {
        const arrayBuffer = await authoritiesMappingFile.arrayBuffer();
        socketService.emit(
          RealTimeSetStandardizedAuthorityNameEvent.SetStandardizedAuthorityName,
          authenticationResponse.id,
          arrayBuffer
        );
      }
      setAuthoritiesMappingFilePath('');
      setAuthoritiesMappingFile(null);
      if (authoritiesMappingFileInput.current) {
        authoritiesMappingFileInput.current.value = '';
      }
    }
  }, [
    socketService,
    JSON.stringify(authenticationResponse),
    authoritiesMappingFilePath,
    authoritiesMappingFile,
    authoritiesMappingFileInput,
  ]);

  return (
    <Card>
      <span
        style={{
          fontSize: Theme.fontSize,
          fontWeight: 'bold',
          color: Theme.colors.primaryColor,
          marginBottom: '16px',
        }}
      >
        Set Standardized Authority Names
      </span>
      <div>
        <div style={{ width: '100%', marginBottom: '16px' }}>
          <Input
            placeholder="Authorities mapping file path..."
            value={authoritiesMappingFilePath}
            setValue={setAuthoritiesMappingFilePath}
          />
          <span
            style={{
              fontSize: Theme.fontSize - 4,
              display: 'block',
              marginTop: '8px',
            }}
          >
            Example: ../shared/authorities-mappings/Authority-Mapping-List.xlsx
          </span>
        </div>
        <span style={{ display: 'block', marginBottom: '16px' }}>OR</span>
        <div style={{ width: '100%', marginBottom: '16px' }}>
          <input
            type="file"
            ref={authoritiesMappingFileInput}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const file = e.target.files ? e.target.files[0] : null;
              setAuthoritiesMappingFile(file);
            }}
          />
        </div>
        <Button
          onClick={() => {
            handlePrimaryAction();
          }}
          style={{ marginBottom: '16px' }}
          isDisabled={isProcessOnGoing}
        >
          Run
        </Button>
        <div>
          <span
            style={{
              marginBottom: '16px',
              display: 'block',
            }}
          >
            Output
          </span>
          <OutputConsole outputs={outputs} height="300px" />
        </div>
      </div>
    </Card>
  );
};
