import { DropdownArrow } from 'components/Dropdown';
import { HTMLAttrID } from 'models';
import { ReactNode, useState } from 'react';
import { Theme } from 'styles/themes';
import { HorizontalDivider, FlexContainer, BoldText } from 'styles/utils';

interface DetailFieldProps extends HTMLAttrID {
  title: string;
  items?: {
    value: string;
    onClick?: () => void;
    isClickable?: boolean;
  }[];
  customContent?: ReactNode;
}

export const DetailField = (props: DetailFieldProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  return (
    <FlexContainer
      id={props.id ? `${props.id}Container` : undefined}
      flexDirection="column"
      style={{ margin: '12px 0 12px 0' }}
    >
      <FlexContainer
        id={props.id ? `${props.id}Header` : undefined}
        style={{ marginBottom: '8px' }}
      >
        <BoldText id={props.id ? `${props.id}HeaderTitle` : undefined}>
          {props.title}
        </BoldText>
        {props.items && props.items.length > 1 && (
          <>
            <BoldText
              id={props.id ? `${props.id}HeaderCount` : undefined}
              color={Theme.colors.secondaryColor}
              style={{ marginLeft: '4px' }}
            >
              ({props.items.length})
            </BoldText>
            <div
              id={
                props.id ? `${props.id}HeaderDropdownArrowContainer` : undefined
              }
              style={{
                height: '24px',
                marginLeft: 'auto',
                cursor: 'pointer',
              }}
              onClick={() => {
                setIsCollapsed(!isCollapsed);
              }}
            >
              <DropdownArrow
                id={props.id ? `${props.id}HeaderDropdownArrowIcon` : undefined}
                isExpanded={!isCollapsed}
                color={Theme.colors.secondaryColor}
              />
            </div>
          </>
        )}
      </FlexContainer>
      <HorizontalDivider
        id={props.id ? `${props.id}HorizontalDivider` : undefined}
        color={Theme.colors.grayShadow}
      />
      <FlexContainer
        id={props.id ? `${props.id}Content` : undefined}
        flexDirection="column"
        style={{ marginTop: '8px' }}
      >
        {props.items &&
          props.items
            .filter((_, y) => {
              if (isCollapsed) {
                return y === 0;
              } else {
                return true;
              }
            })
            .map((item, y) => {
              return (
                <span
                  id={props.id ? `${props.id}ContentItem${y}` : undefined}
                  key={y}
                  onClick={item.onClick}
                  style={{
                    cursor: item.onClick ? 'pointer' : 'default',
                    marginBottom: '6px',
                    color: item.isClickable
                      ? Theme.colors.secondaryColor
                      : 'default',
                    textDecoration: item.isClickable ? 'underline' : 'initial',
                  }}
                >
                  {item.value}
                </span>
              );
            })}
        {props.customContent}
      </FlexContainer>
    </FlexContainer>
  );
};
