import styled from '@emotion/styled';
import {
  CloseIconContainer,
  Modal,
  ModalContentContainer,
  ModalInnerContainer,
  ModalTitle,
} from 'components/Modal';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Theme } from 'styles/themes';
import { ReactComponent as Close } from 'assets/close.svg';
import { FlexContainer } from 'styles/utils';
import { Button, OutlinedButton } from 'components/Button';
import { useLogout } from 'hooks/use-logout';

interface InactivityWarningModalProps {
  isDisplayed: boolean;
  setIsDisplayed: Dispatch<SetStateAction<boolean>>;
  timeUntilLogout: number;
}

const InactivityWarningModalContainer = styled.div({
  display: 'flex',
  width: '624px',
});

export const InactivityWarningModal = (props: InactivityWarningModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const logout = useLogout();

  const minutesLeft = useMemo(() => {
    const minutes = Math.floor(props.timeUntilLogout / 60);
    const minutesString = String(minutes);
    if (minutesString.length === 1) {
      return `0${minutesString}`;
    }
    return minutesString;
  }, [props.timeUntilLogout]);

  const secondsLeft = useMemo(() => {
    const seconds = props.timeUntilLogout % 60;
    const secondsString = String(seconds);
    if (secondsString.length === 1) {
      return `0${secondsString}`;
    }
    return secondsString;
  }, [props.timeUntilLogout]);

  const closeModal = () => {
    props.setIsDisplayed(false);
    setIsLoading(false);
  };

  const handleMainAction = () => {
    logout();
  };

  return (
    <Modal
      id="InactivityWarningModal"
      isDisplayed={props.isDisplayed}
      isLoading={isLoading}
    >
      <InactivityWarningModalContainer id="InactivityWarningModalContainer">
        <ModalContentContainer id="InactivityWarningModalContentContainer">
          <ModalInnerContainer id="InactivityWarningModalInnerContainer">
            <ModalTitle
              id="InactivityWarningModalTitle"
              withoutMarginBottom={true}
              color={Theme.colors.primaryColor}
            >
              Are you still there?
            </ModalTitle>
            <CloseIconContainer id="InactivityWarningModalCloseIconContainer">
              <Close
                id="InactivityWarningModalClose"
                onClick={() => closeModal()}
              />
            </CloseIconContainer>
          </ModalInnerContainer>
          <ModalInnerContainer id="InactivityWarningModalInnerContainer1">
            <FlexContainer
              id="InactivityWarningModalFlexContainer1"
              flexDirection="column"
              style={{ width: '100%' }}
            >
              <span>
                If not, we will log you out in:{' '}
                <b>
                  {minutesLeft}:{secondsLeft}
                </b>
              </span>
            </FlexContainer>
          </ModalInnerContainer>
          <ModalInnerContainer
            id="InactivityWarningModalInnerContainer2"
            withoutBorderBottom={true}
            padding="12px 16px"
          >
            <FlexContainer
              id="InactivityWarningModalFlexContainer2"
              style={{ marginLeft: 'auto' }}
            >
              <Button
                id="InactivityWarningModalCloseButton"
                style={{ marginRight: '10px' }}
                onClick={() => closeModal()}
              >
                I'm here
              </Button>
              <OutlinedButton
                id="InactivityWarningModalLogOutButton"
                color={Theme.colors.secondaryColor}
                onClick={handleMainAction}
              >
                Log out
              </OutlinedButton>
            </FlexContainer>
          </ModalInnerContainer>
        </ModalContentContainer>
      </InactivityWarningModalContainer>
    </Modal>
  );
};
