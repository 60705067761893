import styled from '@emotion/styled';
import { CountryFlagIcon } from 'components/CountryFlagIcon/CountryFlagIcon';
import { Development } from 'models';
import { FlexContainer } from 'styles/utils';
import { useCallback, useState } from 'react';
import { SaveDevelopmentButton } from 'components/SaveDevelopmentButton';
import { ArrowDirections, Tooltip } from 'components/Tooltip';
import { BlueChip } from 'components/Chip';
import { Theme } from 'styles/themes';

interface DevelopmentCardRightSideUpperSideRightSideProps {
  developmentId?: number;
  countryName: string;
  savedByUserIds?: number[];
  isHovered: boolean;
  onAfterSave: (development: Development | null) => void;
  developmentRowId?: number;
}

const DevelopmentCardRightSideUpperSideRightSideContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  padding: '16px 16px 16px 0px',
  width: '200px',
});

const DevelopmentIdContainer = styled.div({
  fontWeight: 'bold',
  fontSize: Theme.fontSize,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  maxWidth: '100%',
});

const CountryNameWrapper = styled.div({
  whiteSpace: 'nowrap',
});

export const DevelopmentCardRightSideUpperSideRightSide = (
  props: DevelopmentCardRightSideUpperSideRightSideProps
) => {
  const [isDevelopmentSavedByUser, setIsDevelopmentSavedByUser] =
    useState(false);
  const [flagIconElement, setFlagIconElement] = useState<HTMLDivElement | null>(
    null
  );
  const onFlagIconRefChange = useCallback((section: HTMLDivElement) => {
    if (section) {
      setFlagIconElement(section);
    }
  }, []);

  return (
    <DevelopmentCardRightSideUpperSideRightSideContainer
      id={`DevelopmentCardRightSideUpperSideRightSideContainer${props.developmentId}`}
    >
      <FlexContainer
        id={`DevelopmentCardRightSideUpperSideRightSideFlexContainer${props.developmentId}`}
        style={{ alignItems: 'center', marginBottom: '8px' }}
      >
        <div
          style={{
            display:
              props.isHovered || isDevelopmentSavedByUser ? 'block' : 'none',
          }}
        >
          <SaveDevelopmentButton
            developmentId={props.developmentId}
            savedByUserIds={props.savedByUserIds}
            setIsDevelopmentSavedByUser={setIsDevelopmentSavedByUser}
            onAfterSave={props.onAfterSave}
            hasTooltip={false}
          />
        </div>
        {!!props.countryName && (
          <div
            id={`DevelopmentCardRightSideUpperSideRightSideFlagIconContainer${props.developmentId}`}
            style={{
              display: 'flex',
              marginLeft: '12px',
              position: 'relative',
            }}
            ref={onFlagIconRefChange}
          >
            <CountryFlagIcon
              id={`DevelopmentCardRightSideUpperSideRightSideFlagIcon${props.developmentId}`}
              countryName={props.countryName}
              marginRight={0}
            />
            <Tooltip
              id={`DevelopmentCardRightSideUpperSideRightSideTooltip${props.developmentId}`}
              hasArrow
              arrowDirection={ArrowDirections.Right}
              relativeToElement={flagIconElement}
            >
              <CountryNameWrapper
                id={`DevelopmentCardRightSideUpperSideRightSideCountryNameWrapper${props.developmentId}`}
              >
                <span>{props.countryName}</span>
              </CountryNameWrapper>
            </Tooltip>
          </div>
        )}
      </FlexContainer>
      <DevelopmentIdContainer
        id={`DevelopmentCardRightSideUpperSideRightSideDocumentIdContainer${props.developmentRowId}`}
      >
        <BlueChip
          id={`DevelopmentCardRightSideUpperSideRightSideDocumentIdChip${props.developmentRowId}`}
          noMargin
          style={{
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
        >
          {`Source ID ${props.developmentRowId}`}
        </BlueChip>
      </DevelopmentIdContainer>
      <DevelopmentIdContainer
        id={`DevelopmentCardRightSideUpperSideRightSideDocumentIdContainer${props.developmentId}`}
      >
        <BlueChip
          id={`DevelopmentCardRightSideUpperSideRightSideDocumentIdChip${props.developmentId}`}
          noMargin
          style={{
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
        >
          {`Dev ID ${props.developmentId}`}
        </BlueChip>
      </DevelopmentIdContainer>
    </DevelopmentCardRightSideUpperSideRightSideContainer>
  );
};
