import { Duration } from 'date-fns';

export interface PeriodOptions {
  [key: string]: {
    text: string;
    durationObject: Duration;
  };
}

export const periodOptions: PeriodOptions = {
  sevenDays: {
    text: '7 days',
    durationObject: {
      days: 7,
    },
  },
  oneMonth: {
    text: '1 month',
    durationObject: {
      months: 1,
    },
  },
  twoMonths: {
    text: '2 months',
    durationObject: {
      months: 2,
    },
  },
  threeMonths: {
    text: '3 months',
    durationObject: {
      months: 3,
    },
  },
  sixMonths: {
    text: '6 months',
    durationObject: {
      months: 6,
    },
  },
  oneYear: {
    text: '1 year',
    durationObject: {
      years: 1,
    },
  },
};
