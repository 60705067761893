import styled from '@emotion/styled';
import { Theme } from 'styles/themes';

export const ManagementMainBody = styled.div({
  width: '70%',
  flexGrow: 1,
  backgroundColor: Theme.colors.primaryBackgroundColor,
  padding: 16,
  position: 'relative',
});
