import styled from '@emotion/styled';
import { useCallback, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectFilterPickerEntries,
  setTriggerGetAllTurnaroundTimeFilterSetEntries,
  updateFilterPickerEntries,
  updateTurnaroundTimeActiveFilters,
} from 'store/turnaround-time-report/turnaround-time-filter-slice';
import { setTriggerGetEntityDetailsAsync } from 'store/turnaround-time-report/turnaround-time-report-slice';
import { FlexContainer } from 'styles/utils';
import { ClosableBlueChip } from 'components/Chip';
import { OverflowXContainer } from 'components/OverflowXContainer';
import { filterKeys } from 'config/filter-keys';
import { FilterEntry } from 'models';

const FilterChipsContainer = styled(FlexContainer)({
  flexGrow: 1,
  width: '100%',
  height: '48px',
  alignItems: 'center',
});

const FlexOverflowContainer = styled(OverflowXContainer)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  gap: '12px',
});

const FilterTypeLabel = styled.span({
  fontWeight: 'bold',
});

const filterTypeMap: { [x: string]: { name: string } } = {
  [filterKeys.authority]: {
    name: 'Issuing Authority',
  },
  [filterKeys.subcategory]: {
    name: 'Subcategory',
  },
  [filterKeys.documentType]: {
    name: 'Document Type',
  },
};

export const TurnaroundTimeDetailsFilterChipsPool = () => {
  const dispatch = useAppDispatch();
  const filterPickerEntries = useAppSelector(selectFilterPickerEntries);
  const [isContainerHovered, setIsContainerHovered] = useState(false);

  const overflowXElementWidth = document
    .querySelector('#FilterChipsPoolOverflowXContainer')
    ?.getBoundingClientRect().width;
  const overflowXElementScrollWidth = document.querySelector(
    '#FilterChipsPoolOverflowXContainer'
  )?.scrollWidth;

  const isOverflowX = useMemo(
    () =>
      Math.ceil(overflowXElementScrollWidth || 0) >
      Math.ceil(overflowXElementWidth || 0),
    [overflowXElementWidth, overflowXElementScrollWidth]
  );

  const handleCloseChip = useCallback((filterEntry: FilterEntry) => {
    dispatch(
      updateTurnaroundTimeActiveFilters({
        propertyToFilterBy: filterEntry.propertyToFilterByOverride || '',
        value: filterEntry.value,
      })
    );
    dispatch(updateFilterPickerEntries(filterEntry));
    dispatch(setTriggerGetAllTurnaroundTimeFilterSetEntries());
    dispatch(setTriggerGetEntityDetailsAsync());
  }, []);

  return (
    <FilterChipsContainer>
      <FlexOverflowContainer
        id="FilterChipsPoolOverflowXContainer"
        isScrollDisplayed={isContainerHovered && isOverflowX}
        onMouseEnter={() => setIsContainerHovered(true)}
        onMouseLeave={() => setIsContainerHovered(false)}
      >
        {filterPickerEntries.map((filterEntry) => (
          <ClosableBlueChip
            key={`${filterEntry.propertyToFilterByOverride}${filterEntry.value}`}
            onClickCloseButton={() => handleCloseChip(filterEntry)}
            noWrap
            style={{
              maxHeight: '28px',
            }}
          >
            {filterEntry.propertyToFilterByOverride && (
              <FilterTypeLabel>
                {`${
                  filterTypeMap[filterEntry.propertyToFilterByOverride].name
                }:`}
                &nbsp;
              </FilterTypeLabel>
            )}
            {filterEntry.name}
          </ClosableBlueChip>
        ))}
      </FlexOverflowContainer>
    </FilterChipsContainer>
  );
};
