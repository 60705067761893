import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { ClosableChip } from 'components/Chip';

export const ClosableBlueChip = styled(ClosableChip)((props) => ({
  backgroundColor: Theme.colors.secondaryColorLighter,
  border: `1px solid ${
    props.isSelected
      ? Theme.colors.secondaryColorLight
      : Theme.colors.transparent
  }`,
  color: Theme.colors.primaryColor,
}));
