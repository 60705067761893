import format from 'date-fns/format';
import { memo, useCallback, useMemo } from 'react';
import { ReactComponent as ArrowDown } from 'assets/arrow-down.svg';
import styled from '@emotion/styled';
import { Select } from '../Select';
import { getRecentYears, MONTHS } from 'utils/date';
import { isAfter, isBefore } from 'date-fns';
import { Theme } from 'styles/themes';

export const MonthSelector = memo(
  ({
    currentDate,
    onNextClick,
    onPrevClick,
    onMonthChange,
    onYearChange,
    maxDate,
    minDate,
  }: IMonthSelectorProps) => {
    const prevClick = useCallback(
      () => onPrevClick(currentDate),
      [onPrevClick, currentDate]
    );
    const nextClick = useCallback(
      () => onNextClick(currentDate),
      [onNextClick, currentDate]
    );
    const monthOptions = useMemo(() => {
      return MONTHS.map((m, i) => ({
        label: m,
        value: i + 1,
      }));
    }, []);

    const yearOptions = useMemo(() => {
      return getRecentYears().map((y) => ({
        label: `${y}`,
        value: y,
      }));
    }, []);
    const handleMonthSelect = useCallback(
      (value: number) => onMonthChange(currentDate, value),
      [currentDate]
    );
    const handleYearSelect = useCallback(
      (value: number) => onYearChange(currentDate, value),
      [currentDate]
    );
    const showLeftArrow = useMemo(
      () => !minDate || isAfter(currentDate, minDate),
      [currentDate, minDate]
    );
    const showRightArrow = useMemo(
      () => !maxDate || isBefore(currentDate, maxDate),
      [currentDate, maxDate]
    );
    return (
      <Container data-testid="datepicker-monthselector">
        <Button type="button" onClick={prevClick} aria-label="Previous month">
          {showLeftArrow && (
            <ArrowContainer>
              <ArrowDown
                style={{
                  color: Theme.colors.grayDarker,
                  transform: 'rotate(90deg)',
                }}
              />
            </ArrowContainer>
          )}
        </Button>
        <SelectsContainer>
          <Select
            options={monthOptions}
            selected={format(currentDate, 'M')}
            onChange={handleMonthSelect}
          />
          <Select
            options={yearOptions}
            selected={format(currentDate, 'yyyy')}
            onChange={handleYearSelect}
          />
        </SelectsContainer>
        <Button type="button" onClick={nextClick} aria-label="Previous month">
          {showRightArrow && (
            <ArrowContainer>
              <ArrowDown
                style={{
                  color: Theme.colors.grayDarker,
                  transform: 'rotate(-90deg)',
                }}
              />
            </ArrowContainer>
          )}
        </Button>
      </Container>
    );
  }
);

const SelectsContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  columnGap: 6,
});

const ArrowContainer = styled.div({
  display: 'flex',
  border: `1px solid ${Theme.colors.grayBorder}`,
  borderRadius: Theme.borderRadius,
  padding: '6px 0',
});

const Container = styled.div<{ double?: boolean }>({
  alignItems: 'center',
  display: 'grid',
  gridTemplateColumns: '24px 1fr 24px',
  justifyContent: 'center',
  columnGap: 6,
  padding: '0 2px 0.5em 2px',
  textAlign: 'center',
  width: '100%',

  '& + &': {
    marginLeft: '20px',
  },
});

const Button = styled.button({
  background: Theme.colors.transparent,
  border: 'none',
  borderRadius: 'none',
  cursor: 'pointer',
  margin: 0,
  outline: 'none',
  padding: 0,

  '&>*': {
    pointerEvents: 'none',
  },
});

interface IMonthSelectorProps {
  currentDate: Date;

  onNextClick: (d: Date) => any;

  onPrevClick: (d: Date) => any;

  onMonthChange: (d: Date, month: number) => any;

  onYearChange: (d: Date, year: number) => any;

  minDate?: Date;

  maxDate?: Date;
}
