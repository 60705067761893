import { Header } from 'components/Header';
import { Theme } from 'styles/themes';
import { BoldText, VerticalDivider, FlexContainer } from 'styles/utils';
import { ReactComponent as Team } from 'assets/team.svg';
import { useMemo, useRef, useState } from 'react';
import { Dropdown, DropdownArrow } from 'components/Dropdown';
import { FeedPicker } from 'components/FeedPicker';
import {
  selectFeedPickerAppliedFeed,
  setFeedPickerFeedToEdit,
} from 'store/feed/feed-picker-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAuthenticationResponse } from 'store/authentication/authentication-slice';
import { ReactComponent as Gear } from 'assets/gear.svg';
import { format } from 'date-fns';
import { ReactComponent as Management } from 'assets/management.svg';

interface HomeHeaderProps {
  setIsCreateFeedModalDisplayed: React.Dispatch<boolean>;
}

export const HomeHeader = (props: HomeHeaderProps) => {
  const dispatch = useAppDispatch();
  const [isFeedPickerDropdownOpen, setIsFeedPickerDropdownOpen] =
    useState(false);
  const headerContainerRef = useRef<HTMLDivElement>(null);
  const selectedFeed = useAppSelector(selectFeedPickerAppliedFeed);
  const authenticationResponse = useAppSelector(selectAuthenticationResponse);

  const HomeHeaderRightSide = useMemo(() => {
    const teamFeed = selectedFeed?.teamFeeds?.find((x) =>
      authenticationResponse?.teamIds?.includes(x.teamId)
    );
    if (
      selectedFeed?.userId === authenticationResponse?.id &&
      selectedFeed?.id !== Number.MAX_SAFE_INTEGER
    ) {
      return (
        <>
          <Team id="HomeHeaderTeamIcon" style={{ marginRight: '5px' }} />
          <span id="HomeHeaderCreated" style={{ marginRight: '20px' }}>
            Created{' '}
            {selectedFeed?.createdAt
              ? format(new Date(selectedFeed?.createdAt || ''), 'PPP')
              : 'No Date'}
          </span>
          <Gear
            id="HomeHeaderGearIcon"
            color={Theme.colors.grayDark}
            style={{ marginRight: '5px' }}
            onClick={(e) => {
              dispatch(setFeedPickerFeedToEdit(selectedFeed));
              props.setIsCreateFeedModalDisplayed(true);
              e.stopPropagation();
            }}
          />
        </>
      );
    } else if (!!teamFeed) {
      return (
        <>
          <Team id="HomeHeaderTeamIcon" style={{ marginRight: '5px' }} />
          <span id="HomeHeaderTeamName" style={{ marginRight: '5px' }}>
            {teamFeed.teamName}
          </span>
          <VerticalDivider height={'24px'} style={{ margin: '0 6px' }} />
          <Management
            id="HomeHeaderManagementIcon"
            style={{ margin: '0 5px' }}
          />
          <span id="HomeHeaderAssigned" style={{ marginRight: '5px' }}>
            Assigned {format(new Date(teamFeed.createdAt || ''), 'PPP')}
          </span>
        </>
      );
    } else {
      return (
        <>
          <Team id="HomeHeaderTeamIcon" style={{ marginRight: '5px' }} />
          <span id="HomeHeaderAllTeams" style={{ marginRight: '10px' }}>
            All teams
          </span>
        </>
      );
    }
  }, [selectedFeed]);

  return (
    <Header
      id="HomeHeader"
      ref={headerContainerRef}
      hasBorderLeft
      hasBorderBottom
      hasBorderRight
      style={{ position: 'relative', cursor: 'pointer', userSelect: 'none' }}
      onClick={(e) => {
        if (
          headerContainerRef.current === e.target ||
          headerContainerRef.current?.firstChild === e.target ||
          headerContainerRef.current?.firstChild?.contains(e.target as Node)
        ) {
          setIsFeedPickerDropdownOpen(!isFeedPickerDropdownOpen);
        }
      }}
    >
      <FlexContainer
        id="HomeHeaderContainer"
        style={{
          flex: '1 1 auto',
        }}
      >
        <FlexContainer
          id="HomeHeaderFeedTitleContainer"
          style={{ alignItems: 'center' }}
        >
          <BoldText>
            <span>Feed: </span>
            <span style={{ color: Theme.colors.secondaryColor }}>
              {selectedFeed?.name || 'All developments'}
            </span>
          </BoldText>
        </FlexContainer>
        <FlexContainer
          id="HomeHeaderRightSideContainer"
          style={{ alignItems: 'center', marginLeft: 'auto' }}
        >
          {HomeHeaderRightSide}
          <DropdownArrow
            id="HomeHeaderRightSideDropdownArrow"
            isExpanded={isFeedPickerDropdownOpen}
          />
        </FlexContainer>
      </FlexContainer>
      <Dropdown
        id="HomeHeaderRightSideDropdown"
        isOpen={isFeedPickerDropdownOpen}
        setIsOpen={setIsFeedPickerDropdownOpen}
        containerRef={headerContainerRef}
        transform="translate(-50%, 54px)"
        style={{
          width: '100%',
          boxShadow: `0px 7px 9px ${Theme.colors.grayShadow}`,
          cursor: 'default',
        }}
      >
        <FeedPicker
          setIsCreateFeedModalDisplayed={props.setIsCreateFeedModalDisplayed}
          setIsFeedPickerDropdownOpen={setIsFeedPickerDropdownOpen}
        />
      </Dropdown>
    </Header>
  );
};
