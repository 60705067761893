import styled from '@emotion/styled';
import { CountryFlagIcon } from 'components/CountryFlagIcon';
import { Row, Td, Tr } from 'components/Table';
import { PerformanceReportEntity } from 'models';
import { useCallback, useState, useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import {
  selectIsReportDataFromAverageDevelopmentsPerDay,
  selectPeriodProperty,
  selectReportByProperty,
} from 'store/performance-report/performance-report-slice';
import { Theme } from 'styles/themes';
import { FlexContainer } from 'styles/utils';
import { formatNumber } from 'utils/number-formatter';

interface PerformanceRowProps {
  data: PerformanceReportEntity;
  onClickRow?: (row: Row<PerformanceReportEntity>) => void;
  isFaded?: boolean;
  isCountry?: boolean;
}

const StyledTr = styled(Tr)({
  height: '48px',
});

const StyledTd = styled(Td)({
  border: `1px solid ${Theme.colors.grayBorder}`,
  borderCollapse: 'collapse',
  padding: '16px',
});

const TdText = styled.span({
  fontSize: Theme.fontSize,
});

export const PerformanceRow = (props: PerformanceRowProps) => {
  const { data } = props;
  const reportByProperty = useAppSelector(selectReportByProperty);
  const period = useAppSelector(selectPeriodProperty);
  const isReportDataFromAverageDevelopmentsPerDay = useAppSelector(
    selectIsReportDataFromAverageDevelopmentsPerDay
  );
  const [isHighlighted, setHighlighted] = useState(false);
  const handleHighlightChange = useCallback(
    (highlight: boolean) => () => setHighlighted(highlight),
    [setHighlighted]
  );

  const handleClick = useCallback(() => {
    props.onClickRow && props.onClickRow({ ...data });
  }, [data]);

  const percentage = useMemo(() => {
    if (data.completion) {
      return data.completion * 100;
    }
    return 0;
  }, [data]);

  return (
    <StyledTr
      onMouseEnter={handleHighlightChange(true)}
      onMouseLeave={handleHighlightChange(false)}
      onClick={handleClick}
      isHighlighted={isHighlighted}
      isFaded={props.isFaded}
    >
      <StyledTd>
        <FlexContainer style={{ alignItems: 'center', flexFlow: 'row' }}>
          {props.isCountry && !!data.name && (
            <CountryFlagIcon countryName={data.name} marginRight={12} />
          )}
          <TdText>{data.name}</TdText>
        </FlexContainer>
      </StyledTd>
      <StyledTd>
        <TdText>
          {isReportDataFromAverageDevelopmentsPerDay
            ? data.newDevelopmentsCountAverage
            : formatNumber(data.newDevelopmentsCount!)}
        </TdText>
      </StyledTd>
    </StyledTr>
  );
};
