import styled from '@emotion/styled';
import { ReactComponent as ArrowDropdownIcon } from 'assets/arrow-down.svg';
import { Theme } from 'styles/themes';
import { Chip, ChipProps } from 'components/Chip';
import { CSSProperties, ReactNode } from 'react';

interface DropdownChipProps extends ChipProps {
  style?: CSSProperties;
  onClickDropdownButton: () => void;
  iconColor?: string;
}

const ArrowDropdownButton = styled.button({
  marginLeft: '6px',
  border: 'none',
  backgroundColor: Theme.colors.transparent,
  cursor: 'pointer',
  width: '18px',
  height: '18px',
});

export const DropdownChip = (props: DropdownChipProps) => {
  const { children, onClickDropdownButton, ...rest } = props;
  return (
    <Chip
      {...rest}
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        fontSize: Theme.fontSize - 2,
        cursor: 'pointer',
        ...props.style,
      }}
      onClick={onClickDropdownButton}
    >
      {children}
      <ArrowDropdownButton id={`${props.id}ArrowDropdownButton`}>
        <ArrowDropdownIcon
          id={`${props.id}ArrowDropdownIcon`}
          color={props.iconColor}
          width={18}
          height={18}
        />
      </ArrowDropdownButton>
    </Chip>
  );
};
