import styled from '@emotion/styled';
import { ReactComponent as CloseIcon } from 'assets/close-secondary.svg';
import { Theme } from 'styles/themes';
import { Chip, ChipProps } from 'components/Chip';
import { CSSProperties } from 'react';

interface ClosableChipProps extends ChipProps {
  onClickCloseButton: () => void;
  noWrap?: boolean;
  style?: CSSProperties;
}

const CloseButton = styled.button({
  padding: '2px',
  marginLeft: '6px',
  border: 'none',
  backgroundColor: Theme.colors.transparent,
  cursor: 'pointer',
});

const ClosableChipContentWrapper = styled.div({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const ClosableChip = (props: ClosableChipProps) => {
  const { children, onClickCloseButton, ...rest } = props;
  return (
    <Chip
      {...rest}
      style={{
        display: 'flex',
        alignItems: 'center',
        fontSize: Theme.fontSize - 2,
        overflow: props.noWrap ? 'visible' : 'hidden',
        ...props.style,
      }}
    >
      {!props.noWrap ? (
        <ClosableChipContentWrapper id={`${props.id}ContentWrapper`}>
          {children}
        </ClosableChipContentWrapper>
      ) : (
        children
      )}
      <CloseButton id={`${props.id}CloseButton`} onClick={onClickCloseButton}>
        <CloseIcon id={`${props.id}CloseIcon`} />
      </CloseButton>
    </Chip>
  );
};
