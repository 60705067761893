import { io, Socket } from 'socket.io-client';

export class BaseSocketService {
  private socket: Socket;
  private readonly socketServiceUrl: string =
    process.env.REACT_APP_SOCKET_URL || '';

  constructor(accessToken: string) {
    this.socket = io(this.socketServiceUrl, {
      auth: {
        token: accessToken,
      },
    });
  }

  public emit(event: string, ...args: any[]): void {
    this.socket.emit<string>(event, ...args);
  }

  public listen(event: string, callback: (...args: any[]) => void): void {
    this.socket.on<string>(event, callback);
  }

  public removeListener(event: string): void {
    this.socket.removeListener<string>(event);
  }

  public disconnect(): void {
    this.socket.removeAllListeners();
    this.socket.disconnect();
  }
}
