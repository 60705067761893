import { routes } from 'navigation/routes';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { setDevelopmentDetailSearchTerm } from 'store/development-detail/development-detail-slice';
import { selectDevelopmentsSearchTerm } from 'store/development/development-slice';
import { setHomeWatchesAppliedFeed } from 'store/feed/feed-picker-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Theme } from 'styles/themes';
import { FlexContainer } from 'styles/utils';

interface DevelopmentCardRightSideLowerSideProps {
  developmentId?: number;
  pdfContentSnippetHighlights: string[];
}

export const DevelopmentCardRightSideLowerSide = (
  props: DevelopmentCardRightSideLowerSideProps
) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const resultsToDisplay = 3;
  const developmentsSearchTerm = useAppSelector(selectDevelopmentsSearchTerm);

  const goToDetailWithSearchQuery = useCallback(() => {
    if (props.developmentId) {
      dispatch(setDevelopmentDetailSearchTerm(developmentsSearchTerm));
      navigate(
        routes.developmentDetail.replace(
          ':developmentId',
          String(props.developmentId)
        )
      );
      dispatch(setHomeWatchesAppliedFeed(false));
    }
  }, [developmentsSearchTerm, props.developmentId]);

  return (
    <FlexContainer
      id={`DevelopmentCardRightSideLowerSide${props.developmentId}`}
      style={{
        borderTop: `1px solid ${Theme.colors.grayBorder}`,
        width: '100%',
        padding: '16px',
        gap: '8px',
      }}
      flexDirection="column"
      onClick={(e) => {
        e.stopPropagation();
        goToDetailWithSearchQuery();
      }}
    >
      {props.pdfContentSnippetHighlights
        .filter((_, y) => y < resultsToDisplay)
        .map((pdfContentSnippetHighlight, index) => {
          return (
            <span key={index}>
              "...
              <span
                dangerouslySetInnerHTML={{
                  __html: pdfContentSnippetHighlight,
                }}
              ></span>
              ..."
            </span>
          );
        })}
      {props.pdfContentSnippetHighlights.length > resultsToDisplay && (
        <span>
          <b>
            {'+'}
            {props.pdfContentSnippetHighlights.length - resultsToDisplay}{' '}
            results
          </b>
        </span>
      )}
    </FlexContainer>
  );
};
