import { Link, matchPath, NavLink, useLocation } from 'react-router-dom';
import { routes } from 'navigation/routes';
import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { ReactComponent as LogoSmallWhite } from 'assets/logo-small-white.svg';
import { ReactComponent as LogoNameSmall } from 'assets/logo-name-32px.svg';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { ArrowDirections, Tooltip } from 'components/Tooltip';
import { HTMLAttrID } from 'models';
import { pascalCase } from 'utils/string-formatter';

interface BaseNavbarSectionProps {
  name: string;
  route?: string;
  children?: ReactNode;
  routesMatch?: string[];
}

interface NavbarSectionProps extends BaseNavbarSectionProps, HTMLAttrID {
  type: NavbarSectionTypes;
  isExpanded?: boolean;
  subSections?: BaseNavbarSectionProps[];
  onClick?: () => void;
}

export enum NavbarSectionTypes {
  Logo,
  TopSection,
  BottomSection,
  BottomLogo,
}

const NavbarSectionContainerBase = styled.div({
  width: '100%',
  position: 'relative',
  overflowX: 'visible',
  cursor: 'pointer',
  color: Theme.colors.white,
  'a, > div': {
    width: '100%',
    height: '56px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: Theme.colors.white,
    fontWeight: 'bold',
  },
  svg: {
    color: Theme.colors.white,
  },
  '.active': {
    backgroundColor: Theme.colors.primaryColorDark,
  },
  '.active > svg': {
    color: Theme.colors.secondaryColorLight,
  },
});

const NavbarTopSectionContainer = styled(NavbarSectionContainerBase)({
  borderBottom: 'solid 1px rgba(255, 255, 255, 0.15)',
});

const NavbarBottomSectionContainer = styled(NavbarSectionContainerBase)({
  borderTop: 'solid 1px rgba(255, 255, 255, 0.15)',
});

const ExpandedNavbarSectionContainerBase = styled(NavbarSectionContainerBase)({
  'a, > div': {
    padding: '12px',
    justifyContent: 'flex-start',
  },
  '.subsection': {
    height: 'auto',
    padding: '10px 16px 10px 24px',
    flexDirection: 'column',
  },
  '.subsection-link': {
    height: '40px',
  },
});

const ExpandedNavbarSubSectionContainer = styled(
  ExpandedNavbarSectionContainerBase
)((props: { removeUpperDivider: boolean }) => ({
  borderTop: `solid ${
    props.removeUpperDivider ? 0 : 1
  }px rgba(255, 255, 255, 0.15)`,
  a: {
    borderRadius: Theme.borderRadius,
  },
}));

const ExpandedNavbarTopSectionContainer = styled(
  ExpandedNavbarSectionContainerBase
)({
  borderBottom: 'solid 1px rgba(255, 255, 255, 0.15)',
});

const ExpandedNavbarBottomSectionContainer = styled(
  ExpandedNavbarSectionContainerBase
)({
  borderTop: 'solid 1px rgba(255, 255, 255, 0.15)',
});

const ExpandedNavbarBottomLogoSectionContainer = styled(
  ExpandedNavbarBottomSectionContainer
)({
  cursor: 'default',
  '> div': {
    justifyContent: 'center',
  },
});

const LogoSection = (props: NavbarSectionProps) => {
  const containerId = props.id
    ? `${props.id}NavbarSectionContainer`
    : undefined;
  const linkId = props.id ? `${props.id}NavbarSectionLink` : undefined;
  const logoId = props.id ? `${props.id}NavbarSectionLogo` : undefined;
  if (props.isExpanded) {
    return (
      <ExpandedNavbarTopSectionContainer id={containerId}>
        <Link id={linkId} to={{ pathname: routes.home }} className="no-style">
          <LogoNameSmall id={logoId} />
        </Link>
      </ExpandedNavbarTopSectionContainer>
    );
  }
  return (
    <NavbarTopSectionContainer id={containerId}>
      <Link id={linkId} to={{ pathname: routes.home }} className="no-style">
        <LogoSmallWhite id={logoId} />
      </Link>
    </NavbarTopSectionContainer>
  );
};

const TopNavbarSection = (props: NavbarSectionProps) => {
  const { pathname } = useLocation();
  const [sectionElement, setSectionElement] = useState<HTMLDivElement | null>(
    null
  );
  const onSectionRefChange = useCallback((section: HTMLDivElement) => {
    if (section) {
      setSectionElement(section);
    }
  }, []);
  const subSections = props.subSections ?? [];
  const routesMatch = props.routesMatch;

  const isMatchingSubRoute = useMemo(() => {
    return routesMatch?.some((pathPattern) => {
      return matchPath(pathPattern, pathname);
    });
  }, [pathname]);

  const isActive = useMemo(
    () =>
      pathname === props.route ||
      subSections.map((r) => r.route).includes(pathname) ||
      isMatchingSubRoute,
    [pathname, props.route, subSections, isMatchingSubRoute]
  );

  const activeSubSection = useMemo(
    () =>
      subSections.find(
        (subSection) =>
          subSection.route === pathname ||
          subSection.routesMatch?.some((pathPattern) =>
            matchPath(pathPattern, pathname)
          )
      ),
    [pathname, subSections]
  );

  const containerId = props.id
    ? `${props.id}NavbarSectionContainer`
    : undefined;
  const navLinkId = props.id ? `${props.id}NavbarSectionNavLink` : undefined;
  const nameId = props.id ? `${props.id}NavbarSectionName` : undefined;
  const tooltipId = props.id ? `${props.id}NavbarSectionTooltip` : undefined;
  const subsectionId = props.id ? `${props.id}NavbarSubsection` : undefined;
  const subsectionContainerId = props.id
    ? `${props.id}NavbarSubsectionContainer`
    : undefined;
  const subsectionNavLinkId = props.id
    ? `${props.id}NavbarSubsectionNavLink`
    : undefined;
  const subsectionContentId = props.id
    ? `${props.id}NavbarSubsectionContent`
    : undefined;

  if (props.isExpanded) {
    return (
      <ExpandedNavbarTopSectionContainer id={containerId}>
        <NavLink
          id={navLinkId}
          to={{ pathname: props.route }}
          className="no-style"
        >
          <div
            id={nameId}
            style={{
              marginRight: '8px',
              width: 24,
              height: 24,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {props.children}
          </div>
          {props.name}
        </NavLink>
        {isActive && subSections.length > 0 && (
          <div id={subsectionId} className="subsection">
            {subSections.map((subSection, index) => (
              <ExpandedNavbarSubSectionContainer
                id={`${subsectionContainerId}${pascalCase(subSection.name)}`}
                key={subSection.name}
                removeUpperDivider={
                  !index ||
                  subSections[index].name === activeSubSection?.name ||
                  subSections[index - 1].name === activeSubSection?.name
                }
              >
                <NavLink
                  id={`${subsectionNavLinkId}${pascalCase(subSection.name)}`}
                  to={{ pathname: subSection.route }}
                  className="subsection-link no-style"
                  style={{
                    backgroundColor:
                      activeSubSection?.route &&
                      subSection.route === activeSubSection.route
                        ? Theme.colors.primaryColorDark
                        : '',
                  }}
                >
                  {subSection.children && (
                    <div
                      id={`${subsectionContentId}${pascalCase(
                        subSection.name
                      )}`}
                      style={{
                        marginRight: '8px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {subSection.children}
                    </div>
                  )}
                  {subSection.name}
                </NavLink>
              </ExpandedNavbarSubSectionContainer>
            ))}
          </div>
        )}
      </ExpandedNavbarTopSectionContainer>
    );
  }
  return (
    <NavbarTopSectionContainer id={containerId} ref={onSectionRefChange}>
      <NavLink
        id={navLinkId}
        to={{ pathname: props.route }}
        className="no-style"
      >
        {props.children}
        <Tooltip
          id={tooltipId}
          relativeToElement={sectionElement}
          hasArrow
          arrowDirection={ArrowDirections.Left}
        >
          <span id={nameId} style={{ whiteSpace: 'nowrap' }}>
            {props.name}
          </span>
        </Tooltip>
      </NavLink>
    </NavbarTopSectionContainer>
  );
};

const BottomNavbarSection = (props: NavbarSectionProps) => {
  const [sectionElement, setSectionElement] = useState<HTMLDivElement | null>(
    null
  );
  const onSectionRefChange = useCallback((section: HTMLDivElement) => {
    if (section) {
      setSectionElement(section);
    }
  }, []);

  const containerId = props.id
    ? `${props.id}NavbarSectionContainer`
    : undefined;
  const nameId = props.id ? `${props.id}NavbarSectionName` : undefined;
  const contentId = props.id ? `${props.id}NavbarSectionContent` : undefined;
  const tooltipId = props.id ? `${props.id}NavbarSectionTooltip` : undefined;

  if (props.isExpanded) {
    return (
      <ExpandedNavbarBottomSectionContainer id={containerId}>
        <div id={nameId} onClick={props.onClick}>
          <div
            id={contentId}
            style={{
              marginRight: '8px',
              width: 24,
              height: 24,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {props.children}
          </div>
          {props.name}
        </div>
      </ExpandedNavbarBottomSectionContainer>
    );
  }
  return (
    <NavbarBottomSectionContainer
      id={containerId}
      ref={onSectionRefChange}
      onClick={props.onClick}
    >
      <div id={contentId}>
        {props.children}
        <Tooltip
          id={tooltipId}
          relativeToElement={sectionElement}
          hasArrow
          arrowDirection={ArrowDirections.Left}
        >
          <span id={nameId} style={{ whiteSpace: 'nowrap' }}>
            {props.name}
          </span>
        </Tooltip>
      </div>
    </NavbarBottomSectionContainer>
  );
};

const BottomLogoSection = (props: NavbarSectionProps) => {
  const containerId = props.id
    ? `${props.id}NavbarSectionContainer`
    : undefined;
  const nameId = props.id ? `${props.id}NavbarSectionName` : undefined;
  const contentId = props.id ? `${props.id}NavbarSectionContent` : undefined;
  return (
    <ExpandedNavbarBottomLogoSectionContainer id={containerId}>
      <div id={nameId}>
        {props.name}
        <div id={contentId} style={{ marginLeft: '8px' }}>
          {props.children}
        </div>
      </div>
    </ExpandedNavbarBottomLogoSectionContainer>
  );
};

export const NavbarSection = (props: NavbarSectionProps) => {
  const htmlId = props.name ? pascalCase(props.name) : undefined;
  switch (props.type) {
    case NavbarSectionTypes.Logo:
      return <LogoSection {...props} id={htmlId} />;
    case NavbarSectionTypes.TopSection:
      return <TopNavbarSection {...props} id={htmlId} />;
    case NavbarSectionTypes.BottomSection:
      return <BottomNavbarSection {...props} id={htmlId} />;
    case NavbarSectionTypes.BottomLogo:
      return <BottomLogoSection {...props} id={htmlId} />;
  }
};
