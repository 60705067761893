import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactNode } from 'react';
import { RootState } from '../store';

export interface ToastState {
  isDisplayed: boolean;
  content: ReactNode;
  distanceFromRight: number;
  durationInMilliseconds: number;
}

const initialState: ToastState = {
  isDisplayed: false,
  content: null,
  distanceFromRight: 0,
  durationInMilliseconds: 3000,
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    displayToast: (
      state,
      action: PayloadAction<{
        content: ReactNode;
        rightDistance?: number;
        durationInMilliseconds?: number;
      }>
    ) => {
      const { content, rightDistance, durationInMilliseconds } = action.payload;
      if (!state.isDisplayed) {
        state.isDisplayed = true;
        state.content = content;
        state.distanceFromRight = rightDistance || 0;
        state.durationInMilliseconds = durationInMilliseconds || 3000;
      }
    },
    hideToast: (state) => {
      state.isDisplayed = false;
      state.content = null;
      state.distanceFromRight = 0;
    },
  },
});

export const { displayToast, hideToast } = toastSlice.actions;

export const selectIsDisplayed = (state: RootState) => state.toast.isDisplayed;
export const selectContent = (state: RootState) => state.toast.content;
export const selectDistanceFromRight = (state: RootState) =>
  state.toast.distanceFromRight;
export const selectDurationInMilliseconds = (state: RootState) =>
  state.toast.durationInMilliseconds;

export default toastSlice.reducer;
