import { Link as RouterLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { Theme } from 'styles/themes';

interface TypographyBaseProps {
  align?: 'left' | 'center' | 'right';
  alignItems?: string;
  color?: keyof typeof Theme.colors;
  decoration?: string;
  display?: string;
  size?: string | number;
  weight?: number;
  as?: string;
  textStyle?: string;
  bold?: boolean;
  disabled?: boolean;
  isBlock?: boolean;
  noMargin?: boolean;
}

export const TypographyBase = styled.p<TypographyBaseProps>((props) => ({
  alignItems: props.alignItems,
  color: props.disabled
    ? Theme.colors.grayBorder
    : props.color
    ? Theme.colors[props.color]
    : 'inherit',
  display: props.isBlock ? 'block' : props.display ? props.display : 'initial',
  fontSize: props.size || Theme.fontSize,
  fontWeight: props.weight || props.bold ? 700 : 400,
  letterSpacing: 'normal',
  lineHeight: 'normal',
  margin: 0,
  marginBottom: props.noMargin ? 0 : '0.5em',
  fontStyle: props.textStyle || 'normal',
  textAlign: props.align || 'inherit',
  textDecoration: props.decoration || 'initial',
}));

export const Caption = styled(TypographyBase)((props) => ({
  fontSize: props.size || 14,
}));

export const Body = TypographyBase;

export const BodyBig = styled(TypographyBase)((props) => ({
  fontSize: props.size || 18,
}));

export const Subtitle = styled(TypographyBase)((props) => ({
  fontSize: props.size || 27,
  fontWeight: props.weight || props.bold ? 600 : 400,
})).withComponent('h4');

export const Title = styled(TypographyBase)((props) => ({
  fontSize: props.size || 40,
  fontWeight: props.weight || props.bold ? 600 : 400,
})).withComponent('h3');

export const SubHeading = styled(TypographyBase)((props) => ({
  fontSize: props.size || 61,
  fontWeight: props.weight || props.bold ? 600 : 400,
})).withComponent('h2');

export const Heading = styled(TypographyBase)((props) => ({
  fontSize: props.size || 91,
  fontWeight: props.weight || props.bold ? 600 : 400,
})).withComponent('h1');

export const A = styled(TypographyBase)({
  cursor: 'pointer',
}).withComponent('a');

export const Link = TypographyBase.withComponent(RouterLink);

export const EllipsedSpan = styled(TypographyBase)((props) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})).withComponent('span');
