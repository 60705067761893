import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { OutputConsole } from 'components/OutputConsole';
import { localStorageKeys } from 'config/local-storage-keys';
import { RealTimeDevelopmentsPdfContentEvent } from 'enums';
import { Environment } from 'models';
import { useCallback, useEffect, useState } from 'react';
import { selectAuthenticationResponse } from 'store/authentication/authentication-slice';
import { useAppSelector } from 'store/hooks';
import { Theme } from 'styles/themes';
import { BaseSocketService } from 'utils/socket/BaseSocketService';

interface SetDevelopmentsPdfContentCardProps {
  selectedEnvironment: Environment | null;
}

export const SetDevelopmentsPdfContentCard = (
  props: SetDevelopmentsPdfContentCardProps
) => {
  const authenticationResponse = useAppSelector(selectAuthenticationResponse);

  const [socketService, setSocketService] = useState<BaseSocketService | null>(
    null
  );
  const [outputs, setOutputs] = useState<string[]>([]);
  const [isProcessOnGoing, setIsProcessOnGoing] = useState<boolean>(false);

  useEffect(() => {
    if (authenticationResponse?.accessToken) {
      setSocketService(
        new BaseSocketService(authenticationResponse.accessToken)
      );
    }
  }, [JSON.stringify(authenticationResponse)]);

  useEffect(() => {
    if (socketService) {
      socketService.listen(
        RealTimeDevelopmentsPdfContentEvent.SetPdfContentUpdateProgress,
        (output: string) => {
          setOutputs((currentValue) => [...currentValue, output]);
        }
      );
      socketService.listen(
        RealTimeDevelopmentsPdfContentEvent.SetPdfContentFinished,
        () => {
          setIsProcessOnGoing(false);
          localStorage.setItem(
            localStorageKeys.isPerformingIndefiniteOperation,
            btoa('false')
          );
        }
      );
      return () => {
        socketService.disconnect();
      };
    }
  }, [socketService]);

  const handlePrimaryAction = useCallback(async () => {
    if (props.selectedEnvironment && authenticationResponse && socketService) {
      setOutputs([]);
      localStorage.setItem(
        localStorageKeys.isPerformingIndefiniteOperation,
        btoa('true')
      );
      setIsProcessOnGoing(true);
      socketService.emit(
        RealTimeDevelopmentsPdfContentEvent.SetPdfContent,
        authenticationResponse.id,
        props.selectedEnvironment.elasticsearchIndexPrefix
      );
    }
  }, [
    props.selectedEnvironment?.elasticsearchIndexPrefix,
    socketService,
    JSON.stringify(authenticationResponse),
  ]);

  return (
    <Card>
      <span
        style={{
          fontSize: Theme.fontSize,
          fontWeight: 'bold',
          color: Theme.colors.primaryColor,
          marginBottom: '16px',
        }}
      >
        Set Developments PDF Content
      </span>
      <div>
        <Button
          onClick={() => {
            handlePrimaryAction();
          }}
          style={{ marginBottom: '16px' }}
          isDisabled={isProcessOnGoing}
        >
          Run
        </Button>
        <div>
          <span
            style={{
              marginBottom: '16px',
              display: 'block',
            }}
          >
            Output
          </span>
          <OutputConsole outputs={outputs} height="300px" />
        </div>
      </div>
    </Card>
  );
};
