import styled from '@emotion/styled';
import { localStorageKeys } from 'config/local-storage-keys';
import { RealTimeExportDevelopmentsEvent } from 'enums';
import { AuthenticationResponse, Environment } from 'models';
import { useCallback, useEffect, useState } from 'react';
import { selectAuthenticationResponse } from 'store/authentication/authentication-slice';
import { selectSelectedEnvironment } from 'store/environment/environment-slice';
import { useAppSelector } from 'store/hooks';
import { downloadFile } from 'utils/download-file';
import { BaseSocketService } from 'utils/socket/BaseSocketService';
import { ReactComponent as ExportIcon } from 'assets/export.svg';
import { ProgressBar } from 'components/ProgressBar';
import { SubHeaderButton } from 'components/SubHeaderButton';

interface ExportDevelopmentsButtonProps {
  totalRecords: number;
  emitExportEvent: (
    socketService: BaseSocketService,
    authenticationResponse: AuthenticationResponse,
    environment: Environment
  ) => void;
}

const ProgressBarContainer = styled.div({
  display: 'inline-flex',
  alignItems: 'center',
  flexGrow: 1,
  height: '30px',
  marginRight: '12px',
  minWidth: '100px',
});

export const ExportDevelopmentsButton = (
  props: ExportDevelopmentsButtonProps
) => {
  const authenticationResponse = useAppSelector(selectAuthenticationResponse);
  const selectedEnvironment = useAppSelector(selectSelectedEnvironment);

  const [socketService, setSocketService] = useState<BaseSocketService | null>(
    null
  );
  const [exportDevelopmentsPercentage, setExportDevelopmentsPercentage] =
    useState(0);
  const [
    isExportDevelopmentsProcessOnGoing,
    setIsExportDevelopmentsProcessOnGoing,
  ] = useState<boolean>(false);
  const [isExportButtonHovered, setIsExportButtonHovered] = useState(false);

  useEffect(() => {
    if (authenticationResponse?.accessToken) {
      setSocketService(
        new BaseSocketService(authenticationResponse.accessToken)
      );
    }
  }, [JSON.stringify(authenticationResponse)]);

  useEffect(() => {
    if (socketService) {
      socketService.listen(
        RealTimeExportDevelopmentsEvent.ExportDevelopmentsUpdateProgress,
        (progressPercentage: number) => {
          setExportDevelopmentsPercentage(progressPercentage);
        }
      );
      socketService.listen(
        RealTimeExportDevelopmentsEvent.ExportDevelopmentsFinished,
        (buffer: Buffer, fileName: string) => {
          const blob = new Blob([buffer]);
          downloadFile(blob, fileName);
          setIsExportDevelopmentsProcessOnGoing(false);
          localStorage.setItem(
            localStorageKeys.isPerformingIndefiniteOperation,
            btoa('false')
          );
        }
      );
      return () => {
        socketService.disconnect();
      };
    }
  }, [socketService]);

  const handleExportDevelopments = useCallback(() => {
    if (
      socketService &&
      authenticationResponse &&
      selectedEnvironment?.elasticsearchIndexPrefix
    ) {
      localStorage.setItem(
        localStorageKeys.isPerformingIndefiniteOperation,
        btoa('true')
      );
      setIsExportDevelopmentsProcessOnGoing(true);
      props.emitExportEvent(
        socketService,
        authenticationResponse,
        selectedEnvironment
      );
    }
  }, [
    JSON.stringify(authenticationResponse),
    socketService,
    selectedEnvironment?.elasticsearchIndexPrefix,
    props.emitExportEvent,
  ]);

  return (
    <>
      {!isExportDevelopmentsProcessOnGoing && (
        <SubHeaderButton
          id="DevelopmentsExportButton"
          title={`Export developments ${
            !props.totalRecords ? '(No results)' : ''
          }`}
          onMouseOver={() => setIsExportButtonHovered(true)}
          onMouseLeave={() => setIsExportButtonHovered(false)}
          isHighlighted={isExportButtonHovered && !!props.totalRecords}
          disabled={!props.totalRecords}
          onClick={() => {
            handleExportDevelopments();
            setIsExportButtonHovered(false);
          }}
        >
          <ExportIcon id="DevelopmentsExportIcon" />
        </SubHeaderButton>
      )}
      {isExportDevelopmentsProcessOnGoing && (
        <ProgressBarContainer id="DevelopmentExportProgressBarContainer">
          <ProgressBar
            id="DevelopmentExportProgressBar"
            percentage={exportDevelopmentsPercentage}
            displaysPercentage={true}
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
              fontSize: '12px',
            }}
          />
        </ProgressBarContainer>
      )}
    </>
  );
};
