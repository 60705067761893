import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { getAuthorities } from 'services/authorities/authorities-service';
import { Authority, PaginatedResponse } from 'models';
import { paginationConfig } from 'config/pagination-config';

export interface NewFeedFilterAuthorityState {
  authorities: Authority[];
  totalRecords: number;
  searchTerm: string;
  page: number;
  perPage: number;
}

const initialState: NewFeedFilterAuthorityState = {
  authorities: [],
  totalRecords: 0,
  searchTerm: '',
  page: 1,
  perPage: paginationConfig.authoritiesPerPage,
};

export const getAllNewFeedFilterAuthoritiesAsync = (): AppThunk<
  Promise<PaginatedResponse<Authority> | null>
> => {
  return async (dispatch, getState) => {
    const searchTerm = getState().newFeedFilterAuthority.searchTerm;
    const page = getState().newFeedFilterAuthority.page;
    const perPage = getState().newFeedFilterAuthority.perPage;
    const response = await getAuthorities(searchTerm, page, perPage);
    if (page > 1) {
      dispatch(addNewFeedFilterAuthorities(response.data));
    } else {
      dispatch(setNewFeedFilterAuthorities(response.data));
    }
    return response.data;
  };
};

export const newFeedFilterAuthoritySlice = createSlice({
  name: 'new-feed-filter-authority',
  initialState,
  reducers: {
    setNewFeedFilterAuthorities: (
      state,
      action: PayloadAction<PaginatedResponse<Authority> | null>
    ) => {
      state.authorities = action.payload?.records ?? [];
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    addNewFeedFilterAuthorities: (
      state,
      action: PayloadAction<PaginatedResponse<Authority> | null>
    ) => {
      state.authorities = state.authorities.concat(
        action.payload?.records ?? []
      );
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    setNewFeedFilterAuthoritiesSearchTerm: (
      state,
      action: PayloadAction<string>
    ) => {
      state.page = 1;
      state.searchTerm = action.payload;
    },
    increaseNewFeedFilterAuthoritiesPage: (state) => {
      state.page = state.page + 1;
    },
    resetNewFeedFilterAuthoritiesPage: (state) => {
      state.page = 1;
    },
    resetNewFeedFilterAuthoritiesSearchTerm: (state) => {
      state.searchTerm = '';
    },
  },
});

export const {
  setNewFeedFilterAuthorities,
  addNewFeedFilterAuthorities,
  setNewFeedFilterAuthoritiesSearchTerm,
  increaseNewFeedFilterAuthoritiesPage,
  resetNewFeedFilterAuthoritiesPage,
  resetNewFeedFilterAuthoritiesSearchTerm,
} = newFeedFilterAuthoritySlice.actions;

export const selectNewFeedFilterAuthorities = (state: RootState) =>
  state.newFeedFilterAuthority.authorities;
export const selectNewFeedFilterAuthoritiesTotalRecords = (state: RootState) =>
  state.newFeedFilterAuthority.totalRecords;
export const selectNewFeedFilterAuthoritiesSearchTerm = (state: RootState) =>
  state.newFeedFilterAuthority.searchTerm;
export const selectNewFeedFilterAuthoritiesPage = (state: RootState) =>
  state.newFeedFilterAuthority.page;
export const selectNewFeedFilterAuthoritiesPerPage = (state: RootState) =>
  state.newFeedFilterAuthority.perPage;

export default newFeedFilterAuthoritySlice.reducer;
