import { PdfViewer, PdfHighlight } from 'components/PdfViewer';
import { useMemo } from 'react';
import { selectSelectedHighlights } from 'store/development-detail/development-detail-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Theme } from 'styles/themes';
import { DevelopmentCitation, DevelopmentCitationHighlight } from 'models';
import { selectAllDetailDevelopmentCitations } from 'store/detail-development-citation/detail-development-citation-slice';

interface DevelopmentPdfViewerProps {
  developmentId: string;
  searchTerm: string;
  onClickDevelopmentCitation: (
    developmentCitation: DevelopmentCitation
  ) => void;
  setIsPdfRendering: React.Dispatch<boolean>;
}

export const DevelopmentPdfViewer = (props: DevelopmentPdfViewerProps) => {
  const dispatch = useAppDispatch();
  const developmentCitations = useAppSelector(
    selectAllDetailDevelopmentCitations
  );
  const selectedHighlights = useAppSelector(selectSelectedHighlights);

  const formattedHighlights: PdfHighlight[] = useMemo(() => {
    return (
      developmentCitations
        .flatMap((x) => x.highlights || [])
        .map((highlight) => {
          const [x0, y0, x1, y1] = highlight.boundingBox.map(Number);

          let color = '';
          if (highlight.developmentCitationHasDirectObligationMatches) {
            color = Theme.colors.citationHighlightGreen;
          }
          if (
            !color &&
            highlight.developmentCitationHasIndirectObligationMatches
          ) {
            color = Theme.colors.citationHighlightBlue;
          }
          if (
            !color &&
            developmentCitations
              .find((x) => x.id === highlight.developmentCitationId)
              ?.matches?.some((x) => !!x.cmsId)
          ) {
            color = Theme.colors.citationHighlightYellow;
          }
          if (!color) {
            color = Theme.colors.citationHighlightGray;
          }

          const formattedHighlight: PdfHighlight = {
            ...highlight,
            rect: { x: x0 - 1, y: y0 - 1, w: x1 - x0 + 2, h: y1 - y0 + 2 },
            color,
            isPermanent: true,
            isFromSearch: false,
            scrollToPage: false,
            onClick: () => {
              const developmentCitation = developmentCitations.find(
                (x) => x.id === highlight.developmentCitationId
              );
              if (developmentCitation) {
                props.onClickDevelopmentCitation(developmentCitation);
              }
            },
          };
          return formattedHighlight;
        }) || []
    );
  }, [JSON.stringify(developmentCitations)]);

  const formattedSelectedHighlights: PdfHighlight[] = useMemo(() => {
    return (
      selectedHighlights.map((selectedHighlight, y) => {
        const [x0, y0, x1, y1] = selectedHighlight.boundingBox.map(Number);

        let color = '';
        if (
          (selectedHighlight as DevelopmentCitationHighlight)
            .developmentCitationHasDirectObligationMatches
        ) {
          color = Theme.colors.citationHighlightGreenSelected;
        }
        if (
          !color &&
          (selectedHighlight as DevelopmentCitationHighlight)
            .developmentCitationHasIndirectObligationMatches
        ) {
          color = Theme.colors.citationHighlightBlueSelected;
        }
        if (
          !color &&
          developmentCitations
            .find(
              (x) =>
                x.id ===
                (selectedHighlight as DevelopmentCitationHighlight)
                  .developmentCitationId
            )
            ?.matches?.some((x) => !!x.cmsId)
        ) {
          color = Theme.colors.citationHighlightYellowSelected;
        }
        if (!color) {
          color = Theme.colors.citationHighlightGraySelected;
        }

        const formattedSelectedHighlight: PdfHighlight = {
          ...selectedHighlight,
          rect: { x: x0 - 1, y: y0 - 1, w: x1 - x0 + 2, h: y1 - y0 + 2 },
          color,
          isPermanent: false,
          isFromSearch: false,
          scrollToPage: y === 0,
        };
        return formattedSelectedHighlight;
      }) || []
    );
  }, [
    JSON.stringify(selectedHighlights),
    JSON.stringify(developmentCitations),
  ]);

  const allHighlights: PdfHighlight[] = useMemo(() => {
    return formattedHighlights.concat(formattedSelectedHighlights);
  }, [
    JSON.stringify(formattedHighlights),
    JSON.stringify(formattedSelectedHighlights),
  ]);

  const pdfUrl = useMemo(() => {
    return `${process.env.REACT_APP_API_URL}/developments/${props.developmentId}/pdf`;
  }, [props.developmentId]);

  return (
    <PdfViewer
      pdfUrl={pdfUrl}
      searchTerm={props.searchTerm}
      highlights={allHighlights}
      setIsPdfRendering={props.setIsPdfRendering}
      skipScrollOnHighlightClick
    />
  );
};
