import {
  BigTitleStatus,
  DetailButton,
  ManagementDetail,
  TitleValue,
} from 'pages/management/components';
import { Theme } from 'styles/themes';
import { FlexContainer } from 'styles/utils';
import { ReactComponent as AddIcon } from 'assets/plus-circle.svg';
import { ReactComponent as ManagerIcon } from 'assets/manager-small.svg';
import { ReactComponent as DeleteIcon } from 'assets/delete-user.svg';
import { ReactComponent as EditIcon } from 'assets/edit-smaller.svg';
import { ReactComponent as RevokeAccessIcon } from 'assets/cancel.svg';
import { ReactComponent as GiveAccessIcon } from 'assets/give-access.svg';
import { ReactComponent as ResendIcon } from 'assets/resend-invite.svg';
import { BlueChip } from 'components/Chip';
import { format } from 'date-fns';
import { User } from 'models';
import { UserStatus, UserStatusColorValueMap } from 'enums/UserStatus';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  giveUserAccessAsync,
  revokeUserAccessAsync,
  sendInviteAsync,
  toggleDeleteUserModal,
} from 'store/management-user/management-user-slice';
import { selectAuthenticationResponse } from 'store/authentication/authentication-slice';
import styled from '@emotion/styled';
import { ArrowDirections, Tooltip } from 'components/Tooltip';
import { Body } from 'components/Typography';
import { displayToast } from 'store/toast/toast-slice';
import { Alert } from 'components/Alert';
import { ReactComponent as Mail } from 'assets/mail.svg';
import { ManagementSectionProps } from '../components/ManagementDetail';

const managerLabel = (
  <FlexContainer
    id="UserManagementDetailManagerLabelContainer"
    style={{ alignItems: 'center' }}
  >
    <FlexContainer
      id="UserManagementDetailManagerIconContainer"
      style={{ marginRight: '4px' }}
    >
      <ManagerIcon id="UserManagementDetailManagerIcon" />
    </FlexContainer>
    <div id="UserManagementDetailManagerLabel">Manager</div>
  </FlexContainer>
);

const DeleteTooltip = memo(
  ({ targetRef }: { targetRef: HTMLElement | null }) => (
    <TooltipContainer
      id="UserManagementDetailDeleteTooltipOuterContainer"
      key="DeleteTooltip"
    >
      <Tooltip
        id="UserManagementDetailDeleteTooltip"
        relativeToElement={targetRef}
        arrowDirection={ArrowDirections.Down}
        hasArrow
      >
        <Body id="UserManagementDetailDeleteTooltipMessage" noMargin>
          To delete a user you
        </Body>
        <Body id="UserManagementDetailDeleteTooltipMessageBold" noMargin bold>
          must revoke access
        </Body>
      </Tooltip>
    </TooltipContainer>
  )
);

const TooltipContainer = styled.div({
  position: 'relative',
  whiteSpace: 'nowrap',
  display: 'inline',
  right: '50%',
});

export const UserDetail = ({
  user,
  isLoading,
  setIsAddToTeamModalDisplayed,
  onEditMode,
}: UserDetailProps) => {
  const dispatch = useAppDispatch();
  const authenticationResponse = useAppSelector(selectAuthenticationResponse);
  const [deleteButtonRef, setDeleteButtonRef] = useState<HTMLElement | null>(
    null
  );
  const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false);
  const [userStatus, setUserStatus] = useState(user.status);
  const [userStatusText, setUserStatusText] = useState(user.statusText);

  const createdDate = user.createdAt
    ? format(new Date(user.createdAt), 'MMMM d, yyyy')
    : 'No available created date';

  const isDisabled = useMemo(() => {
    return Number(userStatus) === UserStatus.NoAccess;
  }, [userStatus]);

  const isCurrentUser = useMemo(
    () =>
      !!user.id &&
      !!authenticationResponse?.id &&
      user.id === authenticationResponse?.id,
    [user.id, authenticationResponse]
  );

  const location = (() => {
    const countryAndCity = [];
    if (user.countryName) {
      countryAndCity.push(user.countryName);
    }
    if (user.city) {
      countryAndCity.push(user.city);
    }
    if (countryAndCity.length > 0) {
      return countryAndCity.join(', ');
    }
    return 'No location available';
  })();

  const handleAccessToggle = useCallback(async () => {
    setIsLoadingDetail(true);
    let users: User[];
    if (isDisabled) {
      users = await dispatch(giveUserAccessAsync());
    } else {
      users = await dispatch(revokeUserAccessAsync());
    }
    setIsLoadingDetail(false);

    if (!users.length) return;

    setUserStatusText(users[0].statusText);
    setUserStatus(isDisabled ? UserStatus.Active : UserStatus.NoAccess);
  }, [isDisabled]);

  const toggleDeleteConfirmationModal = useCallback(() => {
    dispatch(toggleDeleteUserModal());
  }, []);

  const resendInvite = useCallback(async () => {
    setIsLoadingDetail(true);
    await dispatch(sendInviteAsync(user));
    dispatch(
      displayToast({
        content: (
          <Alert id="ResendInviteAlert">
            <div
              id="ResendInviteAlertMessageContainer"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Mail
                id="ResendInviteAlertMailIcon"
                style={{ marginRight: '7px' }}
              />
              Invite was sent again!
            </div>
          </Alert>
        ),
        rightDistance: 50,
      })
    );
    setIsLoadingDetail(false);
  }, []);

  const teamList =
    user.teams && user.teamsCount ? (
      <FlexContainer
        id="UserManagementDetailTeamList"
        style={{ marginTop: '16px', gap: '8px' }}
      >
        {user.teams.map((t) => (
          <BlueChip
            id={`UserManagementDetailTeamListBlueChip${t.id}`}
            key={t.id}
            style={{ fontSize: Theme.fontSize - 2, maxWidth: '275px' }}
          >
            {t.name}
          </BlueChip>
        ))}
      </FlexContainer>
    ) : (
      <FlexContainer id="UserManagementDetailTeamList" />
    );

  const sections: ManagementSectionProps[] = [
    {
      children: [
        <BigTitleStatus
          id="UserManagementDetailNameTitle"
          key="NameTitle"
          title={user.fullName || ''}
          status={userStatusText || ''}
          statusColor={UserStatusColorValueMap[userStatus || UserStatus.Active]}
          value={user.isManager && managerLabel}
          valueColor={Theme.colors.secondaryColor}
        />,
      ],
    },
    {
      children: [
        <TitleValue
          id="UserManagementDetailUserCreated"
          key="UserCreated"
          title="User created"
          value={createdDate}
        />,
      ],
    },
    {
      children: [
        <TitleValue
          id="UserManagementDetailEmail"
          key="Email"
          title="Email"
          value={user.email}
        />,
      ],
    },
    {
      children: [
        <TitleValue
          id="UserManagementDetailLocation"
          key="Location"
          title="Location"
          value={location}
        />,
      ],
    },
    {
      children: [
        <TitleValue
          id="UserManagementDetailTeams"
          key="Teams"
          title="Teams"
          count={user.teamsCount ?? 0}
          buttonIcon={<AddIcon />}
          onClickButton={() => setIsAddToTeamModalDisplayed(true)}
          value={teamList}
        />,
      ],
    },
  ];

  const accessButtonValues = isDisabled
    ? {
        id: 'UserManagementDetailGiveAccessButton',
        key: 'GiveAccess',
        label: 'Give access',
        icon: <GiveAccessIcon />,
        color: Theme.colors.green,
      }
    : {
        id: 'UserManagementDetailRevokeAccessButton',
        key: 'RevokeAccess',
        label: 'Revoke access',
        icon: <RevokeAccessIcon />,
        color: Theme.colors.grayDark,
        disabled: isCurrentUser,
      };

  const activeUserButtons = [
    <DetailButton
      id="UserManagementDetailDeleteButton"
      key="Delete"
      label="Delete"
      icon={<DeleteIcon />}
      color={Theme.colors.red}
      isDisabled={!isDisabled || isCurrentUser}
      onClick={toggleDeleteConfirmationModal}
      innerRef={setDeleteButtonRef}
      element={
        !isDisabled ? <DeleteTooltip targetRef={deleteButtonRef} /> : null
      }
      removeDivider
    />,
    <DetailButton {...accessButtonValues} onClick={handleAccessToggle} />,
    <DetailButton
      id="UserManagementDetailEditButton"
      key="Edit"
      label="Edit"
      icon={<EditIcon />}
      color={Theme.colors.secondaryColor}
      onClick={() => onEditMode(true)}
    />,
  ];

  const invitedUserButtons = [
    <DetailButton
      id="UserManagementDetailDeleteButton"
      key="Delete"
      label="Delete"
      icon={<DeleteIcon />}
      color={Theme.colors.red}
      isDisabled={isCurrentUser}
      onClick={toggleDeleteConfirmationModal}
      innerRef={setDeleteButtonRef}
      removeDivider
    />,
    <DetailButton
      id="UserManagementDetailEditButton"
      key="Edit"
      label="Edit"
      icon={<EditIcon />}
      color={Theme.colors.secondaryColor}
      onClick={() => onEditMode(true)}
    />,
    <DetailButton
      id="UserManagementDetailResendInviteButton"
      key="ResendInvite"
      label="Resend invite"
      icon={<ResendIcon />}
      color={Theme.colors.orange}
      onClick={resendInvite}
    />,
  ];

  const currentButtons = (() => {
    const status = userStatus === undefined ? -1 : Number(userStatus);
    switch (status) {
      case UserStatus.InviteSent:
        return invitedUserButtons;
      case UserStatus.Active:
      default:
        return activeUserButtons;
    }
  })();

  return (
    <ManagementDetail
      id="UserManagementDetail"
      sections={sections}
      buttons={currentButtons}
      isLoading={isLoading || isLoadingDetail}
    />
  );
};

interface UserDetailProps {
  user: User;
  isLoading?: boolean;
  setIsAddToTeamModalDisplayed: Dispatch<SetStateAction<boolean>>;
  onEditMode: (value: boolean) => void;
}
