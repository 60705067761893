import { HTMLAttributes, useState } from 'react';
import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import {
  DevelopmentCardLeftSide,
  DevelopmentCardRightSideUpperSideLeftSide,
  DevelopmentCardRightSideUpperSideRightSide,
  DevelopmentCardRightSideLowerSide,
} from './components';
import { Development } from 'models';
import { developmentKeys } from 'config/development-keys';
import { FlexContainer } from 'styles/utils';

interface DevelopmentCardProps extends HTMLAttributes<any> {
  development: Development;
  onAfterSave: (development: Development | null) => void;
  pdfContentSnippet?: {
    [x: string]: string[];
  };
}

const DevelopmentCardContainer = styled.div({
  minHeight: '147px',
  display: 'flex',
  alignItems: 'stretch',
  backgroundColor: `${Theme.colors.white}`,
  borderRadius: Theme.borderRadius,
  cursor: 'pointer',
  boxShadow: `1px 1px 8px 0px ${Theme.colors.grayDarkest}1A`,
  margin: '16px',
});

export const DevelopmentCard = (props: DevelopmentCardProps) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <DevelopmentCardContainer
      id={`DevelopmentCardContainer${props.development?.externalId}`}
      onClick={props.onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <DevelopmentCardLeftSide
        developmentId={props.development?.id}
        obligationsCount={props.development?.obligationsCount}
      />
      <FlexContainer
        id={`DevelopmentCardRightSide${props.development.id}`}
        flexDirection="column"
        style={{
          width: 'calc(100% - 123px)',
        }}
      >
        <FlexContainer
          id={`DevelopmentCardRightSideUpperSide${props.development.id}`}
          style={{
            width: '100%',
            flexGrow: 1,
            gap: '8px',
          }}
        >
          <DevelopmentCardRightSideUpperSideLeftSide
            developmentId={props.development.id}
            title={props.development.title || props.development.externalId}
            developmentRows={props.development.developmentRows || []}
            country={props.development.mainAuthority?.countryName || 'N/A'}
            citationsCount={props.development.developmentCitationsCount}
            publicationDate={
              props.development.publicationDates?.[0]?.publicationDate
            }
            processingDate={props.development.processingDate}
            hasDevelopmentVersions={
              props.development.developmentVersions &&
              props.development.developmentVersions.length > 1
            }
            isLatestVersion={props.development.isLatestVersion}
          />
          <DevelopmentCardRightSideUpperSideRightSide
            developmentId={props.development?.id}
            countryName={props.development.mainAuthority?.countryName || ''}
            savedByUserIds={props.development?.savedByUserIds}
            isHovered={isHovered}
            onAfterSave={props.onAfterSave}
            developmentRowId={props.development?.rawDevelopment['Row Id']}
          />
        </FlexContainer>
        {props.pdfContentSnippet &&
          props.pdfContentSnippet[developmentKeys.pdfContent] &&
          props.pdfContentSnippet[developmentKeys.pdfContent].length && (
            <DevelopmentCardRightSideLowerSide
              developmentId={props.development.id}
              pdfContentSnippetHighlights={
                props.pdfContentSnippet[developmentKeys.pdfContent]
              }
            />
          )}
      </FlexContainer>
    </DevelopmentCardContainer>
  );
};
