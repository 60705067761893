export const inputValidationRegExp: { readonly [k: string]: RegExp } = {
  atLeastOneLetterOrNumber: /[A-Za-zÀ-ÖØ-öø-ÿ0-9]/,
  startingWhiteSpaces: /^\s/,
  endingWhiteSpaces: /\s$/,
  consecutiveWhiteSpaces: /\s\s/,
  onlyWhiteSpaces: /^\s+$/,
  validEmail:
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
};

export const inputValidationMsg: { readonly [k: string]: string } = {
  hasInvalidSpaces: 'Must not contain starting, ending or double spaces.',
  hasNoLettersOrNumbers: 'Must contain at least one letter or number.',
  hasInvalidEmailFormat: 'Invalid e-mail.',
};

export const validateTextInput = (
  nameValue: string,
  setInvalidNameValueError: (errorMsg: string) => void
) => {
  if (nameValue) {
    if (
      nameValue.match(inputValidationRegExp.startingWhiteSpaces) ||
      nameValue.match(inputValidationRegExp.endingWhiteSpaces) ||
      nameValue.match(inputValidationRegExp.consecutiveWhiteSpaces)
    ) {
      setInvalidNameValueError(inputValidationMsg.hasInvalidSpaces);
    } else if (
      !nameValue.match(inputValidationRegExp.atLeastOneLetterOrNumber)
    ) {
      setInvalidNameValueError(inputValidationMsg.hasNoLettersOrNumbers);
    } else {
      setInvalidNameValueError('');
    }
  } else {
    setInvalidNameValueError('');
  }
};

export const validateEmailInput = (
  emailValue: string,
  setEmailValueError: (errorMsg: string) => void
) => {
  if (emailValue) {
    if (emailValue.match(inputValidationRegExp.validEmail)) {
      setEmailValueError('');
    } else {
      setEmailValueError(inputValidationMsg.hasInvalidEmailFormat);
    }
  } else {
    setEmailValueError('');
  }
};

export const hasInvalidSpaces = (nameValue: string) => {
  if (nameValue) {
    return !!(
      nameValue.match(inputValidationRegExp.startingWhiteSpaces) ||
      nameValue.match(inputValidationRegExp.endingWhiteSpaces) ||
      nameValue.match(inputValidationRegExp.consecutiveWhiteSpaces)
    );
  }
  return undefined;
};

export const hasOnlySpaces = (nameValue: string) =>
  inputValidationRegExp.onlyWhiteSpaces.test(nameValue);
