import styled from '@emotion/styled';
import {
  CloseIconContainer,
  Modal,
  ModalContentContainer,
  ModalInnerContainer,
  ModalTitle,
} from 'components/Modal';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Theme } from 'styles/themes';
import { ReactComponent as Close } from 'assets/close.svg';
import { FlexContainer } from 'styles/utils';
import { Button, OutlinedButton } from 'components/Button';
import { Searchbar } from 'components/Searchbar';
import { EnvironmentsList, Source } from 'models/reports/Source';
import { Checkbox } from 'components/Checkbox';
import { environmentsAssignUnassign } from 'services/environments/environments-service';
import { HttpResponse } from 'utils/http/HttpResponse';
import { useAppDispatch } from 'store/hooks';
import { displayToast } from 'store/toast/toast-slice';
import { Alert } from 'components/Alert';

interface EnvironmentModalProps {
  isDisplayed: boolean;
  setIsDisplayed: Dispatch<SetStateAction<boolean>>;
  environmentsList: EnvironmentsList[];
  sidebarData?: Source;
  onSaveEnvironment: () => void;
}

const EnvironmentContainer = styled.div({
  borderRadius: '8px',
  border: `1px solid ${Theme.colors.grayBorder}`,
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',
});

const EnvironmentModalContainer = styled.div({
  display: 'flex',
  width: '552px',
});

const EnvironmentContainerStyles = {
  justifyContent: 'space-between',
  gap: '16px',
  margin: '16px 0',
  maxHeight: '300px',
  overflow: 'auto',
};

export const EnvironmentModal: React.FC<EnvironmentModalProps> = (
  props: EnvironmentModalProps
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [checkedStates, setCheckedStates] = useState<{
    [key: number]: boolean;
  }>({});
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useAppDispatch();
  const environmentsName = props.sidebarData?.Environments
    ? props.sidebarData?.Environments.split(',').map((env) => env.trim())
    : [];
  const existingEnvironmentIds = props.environmentsList
    .filter((env) => environmentsName.includes(env.name))
    .map((env) => env.id);

  const closeModal = () => {
    props.setIsDisplayed(false);
  };
  const cancelModal = () => {
    props.setIsDisplayed(false);
  };
  const handleCheckboxChange = (id: number) => {
    setCheckedStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  type GroupedData = Record<string, EnvironmentsList[]>;
  const groupByOrganizationName = (data: EnvironmentsList[]): GroupedData => {
    return data.reduce<GroupedData>((acc, item) => {
      const organizationName = item.organizationName;

      if (!organizationName) {
        return acc;
      }

      if (!acc[organizationName]) {
        acc[organizationName] = [];
      }
      acc[organizationName].push(item);

      return acc;
    }, {});
  };

  const groupEnvironmentData: GroupedData = useMemo(() => {
    return groupByOrganizationName(props.environmentsList);
  }, [props.environmentsList]);

  const filteredGroupEnvironmentData = useMemo(() => {
    if (!searchTerm) return groupEnvironmentData;

    const lowercasedSearchTerm = searchTerm.toLowerCase();

    return Object.entries(groupEnvironmentData).reduce<GroupedData>(
      (acc, [organizationName, environments]) => {
        const filteredEnvironments = environments.filter((environment) =>
          environment.name.toLowerCase().includes(lowercasedSearchTerm)
        );

        if (filteredEnvironments.length > 0) {
          acc[organizationName] = filteredEnvironments;
        }

        return acc;
      },
      {}
    );
  }, [groupEnvironmentData, searchTerm]);

  useEffect(() => {
    const updatedStates: Record<number, boolean> = {};
    Object.entries(groupEnvironmentData).forEach(([_, environments]) => {
      environments.forEach((environment) => {
        // Check if the environment name is in the list from SidebarData
        updatedStates[environment.id] = environmentsName.includes(
          environment.name
        );
      });
    });

    setCheckedStates(updatedStates);
  }, [
    groupEnvironmentData,
    props.sidebarData?.Environments,
    props.isDisplayed,
  ]);

  const matchedEnvironmentIds = useMemo(() => {
    return Object.keys(checkedStates)
      .filter((id) => checkedStates[Number(id)]) // Convert key to number and check value
      .map(Number); // Ensure IDs are numbers
  }, [checkedStates]);

  // Environments Assign Unassign
  const handleEnvironmentsAssignment = useCallback(async () => {
    const updatedUnassignedEnvironmentIds =
      existingEnvironmentIds.filter(
        (id) => !matchedEnvironmentIds.includes(id)
      ) || [];
    const updatedAssignedEnvironmentIds =
      matchedEnvironmentIds.filter(
        (id) => !existingEnvironmentIds.includes(id)
      ) || [];
    try {
      setErrorMessage('');
      if (
        props.sidebarData?.RowId &&
        (updatedUnassignedEnvironmentIds.length > 0 ||
          updatedAssignedEnvironmentIds.length > 0)
      ) {
        const response = await environmentsAssignUnassign(
          updatedAssignedEnvironmentIds.length > 0
            ? [[props.sidebarData?.RowId, updatedAssignedEnvironmentIds]]
            : [],
          updatedUnassignedEnvironmentIds.length > 0
            ? [[props.sidebarData?.RowId, updatedUnassignedEnvironmentIds]]
            : []
        );
        // On success: Display toaster
        dispatch(
          displayToast({
            content: (
              <Alert>
                <span>Environments updated successfully!</span>
              </Alert>
            ),
            rightDistance: 50,
          })
        );
      }
    } catch (error) {
      const httpResponse = error as HttpResponse<null>;
      if (!httpResponse.success && httpResponse.error) {
        setErrorMessage(httpResponse.error.message);
      }
    } finally {
      props.setIsDisplayed(false);
      setCheckedStates({});
      if (
        props.sidebarData?.RowId &&
        (updatedUnassignedEnvironmentIds.length > 0 ||
          updatedAssignedEnvironmentIds.length > 0)
      ) {
        props.onSaveEnvironment();
      }
    }
  }, [props.sidebarData?.RowId, matchedEnvironmentIds]);

  return (
    <Modal
      id="EnvironmentModal"
      isDisplayed={props.isDisplayed}
      isLoading={isLoading}
    >
      <EnvironmentModalContainer>
        <ModalContentContainer id="EnvironmentModalContentContainer">
          <ModalInnerContainer id="EnvironmentModalHeader">
            <ModalTitle
              id="EnvironmentModalTitle"
              withoutMarginBottom={true}
              color={Theme.colors.primaryColor}
            >
              Environments
            </ModalTitle>
            <CloseIconContainer id="EnvironmentModalCloseIconContainer">
              <Close
                id="EnvironmentModalCloseButton"
                onClick={() => closeModal()}
              />
            </CloseIconContainer>
          </ModalInnerContainer>
          <ModalInnerContainer
            flexDirection="column"
            id="EnvironmentModalBodyContainer"
          >
            <FlexContainer
              id="EnvironmentModalBodyContainer"
              flexDirection="column"
              style={{ width: '100%' }}
            >
              <Searchbar
                id="EnvironmentModalSearchbar"
                value={searchTerm}
                setValue={(value) => {
                  setSearchTerm(value);
                }}
                placeholder="Search environments..."
              />
            </FlexContainer>
            <FlexContainer
              flexDirection="column"
              flexWrap="nowrap"
              style={EnvironmentContainerStyles}
            >
              {Object.entries(filteredGroupEnvironmentData).map(
                ([organizationName, environments], index) => (
                  <EnvironmentContainer key={environments[index]?.id}>
                    <div>
                      <b>{organizationName}</b>
                    </div>
                    {environments?.map((environment) => (
                      <Checkbox
                        key={environment?.id} // Ensure each checkbox has a unique key
                        id={`Filter${environment?.id}UserCheckboxInput`}
                        isChecked={checkedStates[environment?.id] || false} // Ensure it defaults to false if undefined
                        onChange={() => handleCheckboxChange(environment?.id)}
                        label={environment?.name}
                        style={{ gap: '4px', flexDirection: 'row-reverse' }}
                      />
                    ))}
                  </EnvironmentContainer>
                )
              )}
            </FlexContainer>
          </ModalInnerContainer>
          <ModalInnerContainer
            id="EnvironmentModalFooter"
            withoutBorderBottom={true}
            padding="12px 16px"
          >
            <FlexContainer
              id="EnvironmentModalButtonsContainer"
              style={{ marginLeft: 'auto' }}
              flexDirection="row"
            >
              <OutlinedButton
                id="EnvironmentModalCancelButton"
                color={Theme.colors.secondaryColor}
                style={{ marginRight: '10px' }}
                onClick={() => cancelModal()}
              >
                Cancel
              </OutlinedButton>
              <Button
                id="EnvironmentModalSaveButton"
                onClick={() => {
                  handleEnvironmentsAssignment();
                }}
              >
                Save
              </Button>
            </FlexContainer>
          </ModalInnerContainer>
        </ModalContentContainer>
      </EnvironmentModalContainer>
    </Modal>
  );
};
