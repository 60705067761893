import styled from '@emotion/styled';
import { useState } from 'react';
import { ReactComponent as Manager } from 'assets/manager.svg';
import { ArrowDirections, Tooltip } from 'components/Tooltip';
import { HTMLAttrID } from 'models';
import { Theme } from 'styles/themes';

interface ManagerTooltipProps extends HTMLAttrID {
  isFirstOrSecond?: boolean;
}

const ManagerIconContainer = styled.div({
  position: 'relative',
  height: '18px',
});

const TooltipTextContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '194px',
  height: '44px',
  textAlign: 'center',
  fontSize: Theme.fontSize - 2,
  lineHeight: '22px',
});

export const ManagerTooltipContent = ({ id }: HTMLAttrID) => {
  return (
    <TooltipTextContainer id={id}>
      Managers have access to user and team management
    </TooltipTextContainer>
  );
};

export const ManagerTooltip = (props: ManagerTooltipProps) => {
  const [managerIconContainerElement, setManagerIconContainerElement] =
    useState<HTMLDivElement | null>(null);

  const handleManagerIconContainerRefChange = (
    managerIconContainer: HTMLDivElement
  ) => {
    if (managerIconContainer) {
      setManagerIconContainerElement(managerIconContainer);
    }
  };

  return (
    <ManagerIconContainer
      id={`${props.id}IconContainer`}
      ref={handleManagerIconContainerRefChange}
    >
      <Manager
        id={`${props.id}Icon`}
        style={{
          color: Theme.colors.secondaryColor,
        }}
      />
      <Tooltip
        id={props.id}
        hasArrow
        arrowDirection={
          props.isFirstOrSecond ? ArrowDirections.Left : ArrowDirections.Down
        }
        relativeToElement={managerIconContainerElement}
      >
        <ManagerTooltipContent id={`${props.id}Content`} />
      </Tooltip>
    </ManagerIconContainer>
  );
};
