import styled from '@emotion/styled';
import { Theme } from 'styles/themes';

export const Overlay = styled.div<{
  backgroundColor?: string;
  opacity?: number;
}>((props) => ({
  backgroundColor: props.backgroundColor
    ? props.backgroundColor
    : Theme.colors.grayDarkest,
  opacity: props.opacity ? props.opacity : 0.6,
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 3,
}));
