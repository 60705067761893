import styled from '@emotion/styled';
import { Theme } from 'styles/themes';

const FeedListEmptyStateContainer = styled.div({
  height: '120px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const FeedListEmptyStateText = styled.p({
  fontSize: Theme.fontSize,
  fontStyle: 'italic',
  fontWeight: 400,
  lineHeight: '21.79px',
  textAlign: 'center',
  color: Theme.colors.grayDarkest,
});

export const FeedListEmptyState = () => {
  return (
    <FeedListEmptyStateContainer id="FeedListEmptyStateContainer">
      <FeedListEmptyStateText id="FeedListEmptyStateText">
        No matches found
      </FeedListEmptyStateText>
    </FeedListEmptyStateContainer>
  );
};
