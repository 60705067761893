import RouterConfig from './navigation/RouterConfig';
import { FlexContainer } from 'styles/utils';
import { Toast } from 'components/Toast';
import { useUserActivity } from 'hooks/use-user-activity';
import { InactivityWarningModal } from 'components/InactivityWarningModal';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectAuthenticationResponse } from 'store/authentication/authentication-slice';
import { useLogout } from 'hooks/use-logout';
import { SECONDS_IN_MINUTE } from 'utils/date';

export const App = () => {
  const secondsInAMinute = SECONDS_IN_MINUTE;
  const minutesUntilLogout = (process.env.REACT_APP_MINUTES_UNTIL_LOGOUT ||
    480) as number;
  const minutesUntilLogoutWarning = (process.env
    .REACT_APP_MINUTES_UNTIL_LOGOUT_WARNING || 475) as number;
  const timeUntilLogout = useUserActivity(
    minutesUntilLogout * secondsInAMinute
  );
  const [
    isInactivityWarningModalDisplayed,
    setIsInactivityWarningModalDisplayed,
  ] = useState(false);
  const authenticationResponse = useAppSelector(selectAuthenticationResponse);
  const logout = useLogout();

  useEffect(() => {
    const isLoggedIn = !!authenticationResponse?.accessToken;
    const secondsPassed =
      minutesUntilLogout * secondsInAMinute - timeUntilLogout;
    const secondsUntilLogoutWarning =
      minutesUntilLogoutWarning * secondsInAMinute;
    if (isLoggedIn && secondsPassed >= secondsUntilLogoutWarning) {
      setIsInactivityWarningModalDisplayed(true);
    }
  }, [timeUntilLogout, authenticationResponse?.accessToken]);

  useEffect(() => {
    const isLoggedIn = !!authenticationResponse?.accessToken;
    if (isLoggedIn && timeUntilLogout === 0) {
      logout();
    }
  }, [timeUntilLogout, authenticationResponse?.accessToken]);

  return (
    <FlexContainer
      flexDirection="row"
      flexWrap="nowrap"
      style={{ height: '100%' }}
    >
      <RouterConfig />
      <Toast />
      <InactivityWarningModal
        isDisplayed={isInactivityWarningModalDisplayed}
        setIsDisplayed={setIsInactivityWarningModalDisplayed}
        timeUntilLogout={timeUntilLogout}
      />
    </FlexContainer>
  );
};

export default App;
