import { Alert } from 'components/Alert';
import {
  Dropdown,
  DropdownOption,
  DropdownOptionButton,
  DropdownOptionLabel,
  DropdownOptionsContainer,
} from 'components/Dropdown';
import { useRef, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { displayToast } from 'store/toast/toast-slice';
import { CursorPointer, FlexContainer } from 'styles/utils';
import { copyToClipboard } from 'utils/copy-to-clipboard';
import { ReactComponent as Copy } from 'assets/copy.svg';
import { ReactComponent as Edit } from 'assets/edit.svg';
import { ReactComponent as ClipWhite } from 'assets/clip-white.svg';
import { ReactComponent as OptionsIcon } from 'assets/options.svg';
import { Theme } from 'styles/themes';
import {
  DevelopmentCitation,
  DevelopmentCitationMatchNormalizedCitation,
  HTMLAttrID,
} from 'models';
import styled from '@emotion/styled';

interface ObligationMatchCardOptionsProps extends HTMLAttrID {
  developmentCitation: DevelopmentCitation;
  developmentCitationMatchNormalizedCitation: DevelopmentCitationMatchNormalizedCitation;
}

const ObligationMatchCardOptionsContainer = styled.div({
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: '0 0 24px',
  height: '24px',
  userSelect: 'none',
  position: 'relative',
});

export const ObligationMatchCardOptions = (
  props: ObligationMatchCardOptionsProps
) => {
  const dispatch = useAppDispatch();
  const [isOptionsDropdownOpen, setIsOptionsDropdownOpen] = useState(false);
  const optionsDropdownContainerRef = useRef(null);

  const handleCopy = (text: string, fieldName: string): void => {
    copyToClipboard(text);
    dispatch(
      displayToast({
        content: (
          <Alert>
            <span>
              <ClipWhite style={{ marginRight: '7px' }} />
              {fieldName} copied to clipboard!
            </span>
          </Alert>
        ),
        rightDistance: 300,
      })
    );
  };

  return (
    <ObligationMatchCardOptionsContainer
      id={props.id ? `${props.id}Container` : undefined}
    >
      <CursorPointer
        id={props.id ? `${props.id}Button` : undefined}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: '0 0 24px',
          height: '24px',
        }}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
          setIsOptionsDropdownOpen(!isOptionsDropdownOpen);
        }}
      >
        <OptionsIcon
          id={props.id ? `${props.id}Icon` : undefined}
          style={{ transform: 'rotate(90deg)' }}
        />
      </CursorPointer>
      <Dropdown
        id={props.id ? `${props.id}Dropdown` : undefined}
        isOpen={isOptionsDropdownOpen}
        setIsOpen={setIsOptionsDropdownOpen}
        containerRef={optionsDropdownContainerRef}
        transform="translate(-100%, 25%)"
        style={{
          border: `1px solid ${Theme.colors.grayBorder}`,
          boxShadow: `0px 1px 15px ${Theme.colors.grayShadow}`,
        }}
      >
        <div style={{ width: '176px', color: Theme.colors.black }}>
          <FlexContainer flexDirection="column">
            <DropdownOptionsContainer style={{ width: '100%' }}>
              {!!props.developmentCitationMatchNormalizedCitation
                .normalizedCitation.cmsId && (
                <DropdownOption
                  id={props.id ? `${props.id}DropdownCopyCmsOption` : undefined}
                  style={{ height: 42 }}
                >
                  <DropdownOptionButton
                    id={
                      props.id
                        ? `${props.id}DropdownCopyCmsOptionButton`
                        : undefined
                    }
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      e.stopPropagation();
                      setIsOptionsDropdownOpen(false);
                      handleCopy(
                        props.developmentCitationMatchNormalizedCitation
                          .normalizedCitation.cmsId!,
                        'CMS'
                      );
                    }}
                    style={{
                      padding: 8,
                    }}
                  >
                    <Copy color={Theme.colors.grayDark} />
                    <DropdownOptionLabel
                      id={
                        props.id
                          ? `${props.id}DropdownCopyCmsOptionLabel`
                          : undefined
                      }
                      style={{
                        height: 22,
                        margin: '0px 0px 0px 6px',
                      }}
                    >
                      Copy CMS
                    </DropdownOptionLabel>
                  </DropdownOptionButton>
                </DropdownOption>
              )}
              <DropdownOption
                id={
                  props.id ? `${props.id}DropdownCopyCitationOption` : undefined
                }
                style={{ height: 42 }}
              >
                <DropdownOptionButton
                  id={
                    props.id
                      ? `${props.id}DropdownCopyCitationOptionButton`
                      : undefined
                  }
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation();
                    setIsOptionsDropdownOpen(false);
                    handleCopy(
                      props.developmentCitationMatchNormalizedCitation
                        .normalizedCitation.name,
                      'Citation'
                    );
                  }}
                  style={{
                    padding: 8,
                  }}
                >
                  <Copy color={Theme.colors.grayDark} />
                  <DropdownOptionLabel
                    id={
                      props.id
                        ? `${props.id}DropdownCopyCitationOptionLabel`
                        : undefined
                    }
                    style={{
                      height: 22,
                      margin: '0px 0px 0px 6px',
                    }}
                  >
                    Copy citation
                  </DropdownOptionLabel>
                </DropdownOptionButton>
              </DropdownOption>
            </DropdownOptionsContainer>
          </FlexContainer>
        </div>
      </Dropdown>
    </ObligationMatchCardOptionsContainer>
  );
};
