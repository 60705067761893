import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { User, PaginatedResponse } from 'models';
import { getAllUsers } from 'services/users/users-service';
import { paginationConfig } from 'config/pagination-config';
import { SortByDirection } from 'enums';

export interface CreateTeamModalState {
  users: User[];
  totalRecords: number;
  page: number;
  perPage: number;
  searchTerm: string;
  triggerUsersUpdate: number;
}

const initialState: CreateTeamModalState = {
  users: [],
  totalRecords: 0,
  page: 1,
  perPage: paginationConfig.createTeamModalUsersPerPage,
  searchTerm: '',
  triggerUsersUpdate: 0,
};

export const getAllCreateTeamModalUsersAsync = (): AppThunk<
  Promise<PaginatedResponse<User> | null>
> => {
  return async (dispatch, getState) => {
    const createTeamModalUserState = getState().createTeamModalUser;
    const page = createTeamModalUserState.page;
    const perPage = createTeamModalUserState.perPage;
    const searchTerm = createTeamModalUserState.searchTerm;
    const response = await getAllUsers(
      searchTerm,
      page,
      perPage,
      'fullName.keyword',
      SortByDirection.ASC
    );
    if (page > 1) {
      dispatch(addCreateTeamModalUsers(response.data));
    } else {
      dispatch(setCreateTeamModalUsers(response.data));
    }
    return response.data;
  };
};

export const createTeamModalUserSlice = createSlice({
  name: 'create-team-modal-user',
  initialState,
  reducers: {
    setCreateTeamModalUsers: (
      state,
      action: PayloadAction<PaginatedResponse<User> | null>
    ) => {
      state.users = action.payload?.records ?? [];
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    addCreateTeamModalUsers: (
      state,
      action: PayloadAction<PaginatedResponse<User> | null>
    ) => {
      state.users = state.users.concat(action.payload?.records ?? []);
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    increaseCreateTeamModalUsersPage: (state) => {
      state.page = state.page + 1;
    },
    resetCreateTeamModalUsers: (state) => {
      state.users = [];
      state.totalRecords = 0;
    },
    resetCreateTeamModalUsersPage: (state) => {
      state.page = 1;
    },
    setCreateTeamModalUsersSearchTerm: (
      state,
      action: PayloadAction<string>
    ) => {
      state.page = 1;
      state.searchTerm = action.payload;
    },
    resetCreateTeamModalUsersSearchTerm: (state) => {
      state.searchTerm = '';
    },
    setTriggerCreateTeamModalUsersUpdate: (state) => {
      state.triggerUsersUpdate = state.triggerUsersUpdate + 1;
    },
  },
});

export const {
  setCreateTeamModalUsers,
  addCreateTeamModalUsers,
  increaseCreateTeamModalUsersPage,
  resetCreateTeamModalUsers,
  resetCreateTeamModalUsersPage,
  setCreateTeamModalUsersSearchTerm,
  resetCreateTeamModalUsersSearchTerm,
  setTriggerCreateTeamModalUsersUpdate,
} = createTeamModalUserSlice.actions;

export const selectCreateTeamModalUsers = (state: RootState) =>
  state.createTeamModalUser.users;
export const selectCreateTeamModalUsersTotalRecords = (state: RootState) =>
  state.createTeamModalUser.totalRecords;
export const selectCreateTeamModalUsersPage = (state: RootState) =>
  state.createTeamModalUser.page;
export const selectCreateTeamModalUsersPerPage = (state: RootState) =>
  state.createTeamModalUser.perPage;
export const selectCreateTeamModalUsersSearchTerm = (state: RootState) =>
  state.createTeamModalUser.searchTerm;
export const selectTriggerCreateTeamModalUsersUpdate = (state: RootState) =>
  state.createTeamModalUser.triggerUsersUpdate;

export default createTeamModalUserSlice.reducer;
