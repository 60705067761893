import { httpPost, httpPut } from 'utils/http/base-http-service';
import { HttpResponse } from 'utils/http/HttpResponse';
import { AuthenticationResponse, LoginCredentials } from 'models';

export const login = async (
  loginCredentials: LoginCredentials
): Promise<HttpResponse<AuthenticationResponse | null>> => {
  return await httpPost<AuthenticationResponse, LoginCredentials>(
    'auth/login',
    loginCredentials,
    false
  );
};

export const recoverPassword = async (
  email: string
): Promise<HttpResponse<void | null>> => {
  return await httpPost<null, { email: string }>(
    'auth/recover-password',
    {
      email,
    },
    false
  );
};

export const setUserPassword = async (
  password: string,
  accessToken: string
): Promise<HttpResponse<AuthenticatorResponse | null>> => {
  return await httpPut<AuthenticatorResponse, { password: string }>(
    'auth/set-password',
    { password },
    false,
    {
      Authorization: `Bearer ${accessToken}`,
    }
  );
};

export const changeUserPassword = async (
  currentPassword: string,
  newPassword: string,
  confirmedNewPassword: string
): Promise<HttpResponse<AuthenticationResponse | null>> => {
  return await httpPut<
    AuthenticationResponse,
    {
      currentPassword: string;
      newPassword: string;
      confirmedNewPassword: string;
    }
  >('auth/change-password', {
    currentPassword,
    newPassword,
    confirmedNewPassword,
  });
};
