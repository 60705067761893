import { ThemeProvider } from '@emotion/react';
import styled from '@emotion/styled';
import { AbstractDetails } from 'components/KeyChangesJurisdictions/KeyChangesJurisdictions';
import { ArrowDirections, Tooltip } from 'components/Tooltip';
import theme from 'lib/styles/theme';
import { KeyChanges } from 'lib/utils/Option';
import { useState } from 'react';
import { KeyChangePopout } from './KeyChangePopout';

interface KeyChangeAbstractProps {
  KeyChanges: KeyChanges;
  index: number;
  uniqueId: number;
  abstract?: AbstractDetails;
  handleSelectAbstract: (uniqueID: number) => void;
}

const KeyChange = styled.li({});

const OpenDevelopmentModal = styled.sup({
  opacity: '1',
  color: theme.font.color.blue,
  position: 'relative',
});

const PopoutContainer = styled.div({
  width: '200px',
});

const AbstractTitle = styled.div({
  color: theme.font.color.blue,
  fontSize: '15px',
  marginBottom: '8px',
  cursor: 'pointer',
  marginTop: '10px',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const IssuingAuthority = styled.div({
  fontSize: '13px',
  marginBottom: '6px',
});

const EffectiveDate = styled.div({
  fontSize: '13px',
  marginBottom: '10px',
});

const KeyChangeAbstract: React.FC<KeyChangeAbstractProps> = (props) => {
  const [saveButtonTooltipContainer, setSaveButtonTooltipContainer] =
    useState<HTMLSpanElement | null>(null);
  return props.abstract ? (
    <ThemeProvider theme={theme}>
      <KeyChange>
        {props.KeyChanges.result}{' '}
        <OpenDevelopmentModal
          ref={(saveButtonTooltipContainer) => {
            setSaveButtonTooltipContainer(saveButtonTooltipContainer);
          }}
        >
          {`[${props.abstract && props.abstract.id}]`}
          <KeyChangePopout
            id={`DevelopmentDetailSubHeaderSaveTooltip`}
            relativeToElement={saveButtonTooltipContainer}
            hasArrow
            arrowDirection={ArrowDirections.Down}
          >
            <PopoutContainer id={`DevelopmentDetailSubHeaderSaveTooltipSpan`}>
              {props.abstract && (
                <div>
                  <AbstractTitle
                    onClick={() => props.handleSelectAbstract(props.uniqueId)}
                  >
                    {props.abstract.title}
                  </AbstractTitle>
                  <IssuingAuthority>
                    {props.abstract.issuingAuthority}
                  </IssuingAuthority>
                  {props.abstract.effectiveDate && (
                    <EffectiveDate>
                      Effective Date: {props.abstract.effectiveDate}
                    </EffectiveDate>
                  )}
                </div>
              )}
            </PopoutContainer>
          </KeyChangePopout>
        </OpenDevelopmentModal>
      </KeyChange>
    </ThemeProvider>
  ) : null;
};

export default KeyChangeAbstract;
