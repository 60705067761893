export const Theme: {
  colors: {
    [x: string]: string;
  };
  fontSize: number;
  fontFamily: string;
  fontWeight: {
    [x: string]: string;
  };
  borderRadius: number;
} = {
  colors: {
    primaryColor: '#01416A',
    primaryColorDark: '#01253D',
    secondaryColor: '#0298F7',
    secondaryColorLight: '#76C9FE',
    secondaryColorLighter: '#E5F5FF',
    primaryBackgroundColor: '#F4F5F6',
    secondaryBackgroundColor: '#F3F3F3',
    tertiaryBackgroundColor: '#E5E5E5',
    quaternaryBackgroundColor: '#F1F6F8',
    redBackgroundColor: 'rgba(255, 0, 0, 0.1)',
    gray: '#94A0A8',
    grayDark: '#89969F',
    grayDarker: '#57636B',
    grayDarkest: '#262626',
    grayBorder: '#DDE1E3',
    grayShadow: 'rgba(38, 38, 38, 0.25)',
    grayDisabled: '#BBBBBB',
    white: '#FFFFFF',
    blue: '#0170B5',
    blueLight: '#7CCDFE',
    blueLighter: '#9DD9FE',
    blueLightest: '#0298F70D',
    red: '#CF2D17',
    redLight: '#F08080',
    purpleLight: '#9370DB',
    black: 'black',
    green: '#27A40E',
    greenLight: '#00ED4C',
    greenLighter: '#85FFAC',
    greenLightest: '#E6FFEE',
    greenDark: '#084514',
    orange: '#E16D0E',
    orangeLight: '#F4A460',
    orangeLightest: '#FAE3D1',
    orangeDark: '#A74D03',
    yellow: '#FFFF75',
    yellowLight: '#FFFFD2',
    transparent: 'transparent',
    cmsGreen: '#CEE5CB',
    cmsGreenSelected: '#91C58A',
    citationHighlightGreen: '#C8E6C9',
    citationHighlightGreenSelected: '#81C784',
    cmsBlue: '#C2DDF8',
    cmsBlueSelected: '#78B3F0',
    citationHighlightBlue: '#BBDEFB',
    citationHighlightBlueSelected: '#64B5F6',
    cmsYellow: '#FEF9CA',
    cmsYellowSelected: '#FDF188',
    citationHighlightYellow: '#FFF9C4',
    citationHighlightYellowSelected: '#FFF176',
    cmsGray: '#EEEEEE',
    citationHighlightGray: '#E0E0E0',
    citationHighlightGraySelected: '#BDBDBD',
  },
  fontSize: 16,
  fontFamily: 'sans-serif',
  fontWeight: {
    normal: 'normal',
    bold: 'bold',
  },
  borderRadius: 4,
};
