import { BlueChip } from 'components/Chip';
import styled from '@emotion/styled';
import { RefObject, useEffect, useMemo, useState } from 'react';
import { ArrowDirections, Tooltip } from 'components/Tooltip';
import { Body } from 'components/Typography';
import { HTMLAttrID } from 'models';

interface BlueChipListProps extends HTMLAttrID {
  items: any[];
  getLabel: (item: any) => string;
  isSelected?: boolean;
  cutterElement?: RefObject<Element>;
  hasFlexWrap?: boolean;
  maxWidth?: number;
  inverseTooltipDirection?: boolean;
}

const ListContainer = styled.div<{ hasFlexWrap?: boolean }>((props) => ({
  display: 'flex',
  width: '100%',
  flexWrap: props.hasFlexWrap ? 'wrap' : 'nowrap',
}));

const TooltipContainer = styled.div({
  position: 'relative',
  whiteSpace: 'nowrap',
  display: 'inline',
});

export const BlueChipList = (props: BlueChipListProps) => {
  const { id, items, getLabel, isSelected, cutterElement } = props;
  const [listElement, setListElement] = useState<HTMLDivElement | null>(null);
  const [counterElement, setCounterElement] = useState<HTMLElement | null>(
    null
  );
  const [wrapIndex, setWrapIndex] = useState(0);
  const [isAlreadyRendered, setIsAlreadyRendered] = useState(false);

  useEffect(() => {
    if (isAlreadyRendered) {
      setWrapIndex(0);
      setIsAlreadyRendered(false);
    }
  }, [items.length]);

  useEffect(() => {
    if (listElement && !isAlreadyRendered) {
      const children = Array.from(listElement.children);
      const availableWidth = listElement.getBoundingClientRect().width * 0.8;
      let consumedWidth = 0;
      const updatedIndex = children.some((node, i) => {
        consumedWidth += node.getBoundingClientRect().width;
        if (consumedWidth > availableWidth) {
          setWrapIndex(i);
          return true;
        }
      });
      if (!updatedIndex) {
        setWrapIndex(0);
      }
      setIsAlreadyRendered(true);
    }
  }, [listElement, isAlreadyRendered, wrapIndex]);

  const counter = useMemo(() => {
    return items.length - wrapIndex;
  }, [wrapIndex, items.length]);

  return (
    <ListContainer id={id} ref={setListElement} hasFlexWrap={props.hasFlexWrap}>
      {items.slice(0, wrapIndex || items.length).map((item, y) => (
        <BlueChip
          id={`${id}Chip${item.id}`}
          key={item.id || y}
          isSelected={isSelected}
          style={{
            maxWidth: props.maxWidth,
          }}
        >
          {getLabel(item)}
        </BlueChip>
      ))}
      {!!wrapIndex && (
        <TooltipContainer id={`${id}CounterChipContainer`}>
          <BlueChip id={`${id}CounterChip`} ref={setCounterElement} key={id}>
            +{counter}
          </BlueChip>
          <Tooltip
            id={`${id}CounterTooltip`}
            relativeToElement={counterElement}
            hasArrow
            arrowDirection={
              props.inverseTooltipDirection
                ? ArrowDirections.Up
                : ArrowDirections.Down
            }
            arrowFlipDirection={
              props.inverseTooltipDirection
                ? ArrowDirections.Down
                : ArrowDirections.Up
            }
            cutterElement={cutterElement}
          >
            {items.slice(wrapIndex).map((item, y) => (
              <Body id={`${id}Element${item.id}`} key={item.id || y} noMargin>
                {getLabel(item)}
              </Body>
            ))}
          </Tooltip>
        </TooltipContainer>
      )}
    </ListContainer>
  );
};
