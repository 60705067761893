import styled from '@emotion/styled';
import { useState } from 'react';
import { Theme } from 'styles/themes';
import { FlexContainer } from 'styles/utils';
import { useLongPress } from 'hooks/use-long-press';

interface ZoomButtonProps {
  scale: number;
  setScale: React.Dispatch<number>;
  right: number;
}

const ZoomButtonContainer = styled.div<{ isHovered: boolean; right: number }>(
  (props) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    bottom: 45,
    right: props.right,
    zIndex: 1,
    width: '48px',
    background: Theme.colors.white,
    opacity: props.isHovered ? 1 : 0.5,
    borderRadius: Theme.borderRadius,
    padding: '5px',
    lineHeight: '20px',
  })
);

const ZoomInButton = styled.span({
  cursor: 'pointer',
  fontSize: '28px',
  fontWeight: 'lighter',
  userSelect: 'none',
});

const ZoomPercentText = styled.span({
  fontSize: Theme.fontSize - 2,
  fontWeight: 'bold',
  userSelect: 'none',
});

const ZoomOutButton = styled.span({
  cursor: 'pointer',
  fontSize: '14px',
  fontWeight: 'bold',
  userSelect: 'none',
});

export const ZoomButton = (props: ZoomButtonProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleZoomIn = () => {
    if (props.scale < 2.0) {
      props.setScale(props.scale + 0.1);
    }
  };
  const handleZoomOut = () => {
    if (Number(props.scale.toFixed(1)) > 0.5) {
      props.setScale(props.scale - 0.1);
    }
  };
  const zoomInLongPress = useLongPress(handleZoomIn);
  const zoomOutLongPress = useLongPress(handleZoomOut);

  return (
    <ZoomButtonContainer
      id="ZoomButton"
      isHovered={isHovered}
      right={props.right}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <FlexContainer
        id="ZoomInButtonContainer"
        style={{ justifyContent: 'center' }}
      >
        <ZoomInButton
          id="ZoomInButton"
          onClick={handleZoomIn}
          {...zoomInLongPress}
        >
          +
        </ZoomInButton>
      </FlexContainer>
      <FlexContainer
        id="ZoomPercentTextContainer"
        style={{
          justifyContent: 'center',
          marginTop: '7px',
          marginBottom: '2px',
        }}
      >
        <ZoomPercentText id="ZoomPercentText">
          {(props.scale * 100).toFixed(0)}%
        </ZoomPercentText>
      </FlexContainer>
      <FlexContainer
        id="ZoomOutButtonContainer"
        style={{ justifyContent: 'center' }}
      >
        <ZoomOutButton
          id="ZooOutButton"
          onClick={handleZoomOut}
          {...zoomOutLongPress}
        >
          —
        </ZoomOutButton>
      </FlexContainer>
    </ZoomButtonContainer>
  );
};
