import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { getCountriesWithDevelopmentCountGreaterThanZero } from 'services/countries/countries-service';
import { Country, PaginatedResponse } from 'models';
import { paginationConfig } from 'config/pagination-config';
import { filterKeys } from 'config/filter-keys';
import { isArraySubsetOfAnother } from 'utils/array';

export interface HomeSidebarFilterCountryState {
  countries: Country[];
  totalRecords: number;
  searchTerm: string;
  page: number;
  previousPage: number;
  perPage: number;
  isConsecutiveFetch: boolean;
}

const initialState: HomeSidebarFilterCountryState = {
  countries: [],
  totalRecords: 0,
  searchTerm: '',
  page: 1,
  previousPage: 1,
  perPage: paginationConfig.countriesPerPage,
  isConsecutiveFetch: false,
};

export const getAllHomeSidebarFilterCountriesAsync = (): AppThunk<
  Promise<PaginatedResponse<Country> | null>
> => {
  return async (dispatch, getState) => {
    const selectedEnvironment = getState().environment.selectedEnvironment;
    const searchTerm = getState().homeSidebarFilterCountry.searchTerm;
    const page = getState().homeSidebarFilterCountry.page;
    const previousPage = getState().homeSidebarFilterCountry.previousPage;
    const perPage = getState().homeSidebarFilterCountry.perPage;
    const activeFilters = getState().homeSidebarFilter.activeFilters;
    const ids = getState().feedPicker.appliedFeed?.countryIds;
    const isConsecutiveFetch =
      getState().homeSidebarFilterCountry.isConsecutiveFetch;

    const getCountries = async (pageNumber: number) => {
      return await getCountriesWithDevelopmentCountGreaterThanZero(
        selectedEnvironment,
        searchTerm,
        pageNumber,
        perPage,
        activeFilters,
        ids
      );
    };

    if (isConsecutiveFetch) {
      dispatch(setIsConsecutiveCountriesFetch(false));
    }

    if (
      isConsecutiveFetch &&
      activeFilters[filterKeys.country] &&
      activeFilters[filterKeys.country].length &&
      !ids?.length
    ) {
      let currentPage = 0;
      let isLastPage = false;
      let allCountryIds: number[] = [];
      let areAllCheckedFiltersDisplayed = false;
      let response;
      let incomingCountries: Country[] = [];
      do {
        response = await getCountries(++currentPage);
        if (!response.data) {
          return null;
        }
        isLastPage =
          currentPage >= Math.ceil(response.data.totalRecords / perPage);
        incomingCountries = incomingCountries.concat(response.data.records);
        allCountryIds = allCountryIds.concat(
          response.data.records.map((x) => x.id!)
        );
        areAllCheckedFiltersDisplayed = isArraySubsetOfAnother(
          activeFilters[filterKeys.country] as number[],
          allCountryIds
        );
      } while (!areAllCheckedFiltersDisplayed && !isLastPage);
      response.data.records = incomingCountries;
      dispatch(setHomeSidebarFilterCountriesPage(currentPage));
      dispatch(setHomeSidebarFilterCountries(response.data));
      return response.data;
    } else {
      const response = await getCountries(page);
      if (page === 1) {
        dispatch(setHomeSidebarFilterCountries(response.data));
      } else {
        if (page > previousPage) {
          dispatch(addHomeSidebarFilterCountries(response.data));
        }
      }
      return response.data;
    }
  };
};

export const homeSidebarFilterCountrySlice = createSlice({
  name: 'home-sidebar-filter-country',
  initialState,
  reducers: {
    setHomeSidebarFilterCountries: (
      state,
      action: PayloadAction<PaginatedResponse<Country> | null>
    ) => {
      state.countries = action.payload?.records ?? [];
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    addHomeSidebarFilterCountries: (
      state,
      action: PayloadAction<PaginatedResponse<Country> | null>
    ) => {
      state.countries = state.countries.concat(action.payload?.records ?? []);
      state.totalRecords = action.payload?.totalRecords ?? 0;
      state.previousPage = state.page;
    },
    setHomeSidebarFilterCountriesSearchTerm: (
      state,
      action: PayloadAction<string>
    ) => {
      state.page = 1;
      state.searchTerm = action.payload;
    },
    setHomeSidebarFilterCountriesPage: (
      state,
      action: PayloadAction<number>
    ) => {
      state.page = action.payload;
    },
    increaseHomeSidebarFilterCountriesPage: (state) => {
      state.page = state.page + 1;
    },
    resetHomeSidebarFilterCountriesPage: (state) => {
      state.page = 1;
      state.previousPage = 1;
    },
    setIsConsecutiveCountriesFetch: (state, action: PayloadAction<boolean>) => {
      state.isConsecutiveFetch = action.payload;
    },
  },
});

export const {
  setHomeSidebarFilterCountries,
  addHomeSidebarFilterCountries,
  setHomeSidebarFilterCountriesSearchTerm,
  setHomeSidebarFilterCountriesPage,
  increaseHomeSidebarFilterCountriesPage,
  resetHomeSidebarFilterCountriesPage,
  setIsConsecutiveCountriesFetch,
} = homeSidebarFilterCountrySlice.actions;

export const selectHomeSidebarFilterCountries = (state: RootState) =>
  state.homeSidebarFilterCountry.countries;
export const selectHomeSidebarFilterCountriesTotalRecords = (
  state: RootState
) => state.homeSidebarFilterCountry.totalRecords;
export const selectHomeSidebarFilterCountriesSearchTerm = (state: RootState) =>
  state.homeSidebarFilterCountry.searchTerm;
export const selectHomeSidebarFilterCountrySearchTerm = (state: RootState) =>
  state.homeSidebarFilterCountry.searchTerm;
export const selectHomeSidebarFilterCountriesPage = (state: RootState) =>
  state.homeSidebarFilterCountry.page;
export const selectHomeSidebarFilterCountriesPerPage = (state: RootState) =>
  state.homeSidebarFilterCountry.perPage;

export default homeSidebarFilterCountrySlice.reducer;
