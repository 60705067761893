import styled from '@emotion/styled';
import { ReportsLabels } from 'pages/reports/interfaces/ReportsLabels';
import { RadioButtonGroup } from 'components/RadioButtonGroup';
import { useAppDispatch } from 'store/hooks';
import { setIsReportDataFromAverageDevelopmentsPerDay } from 'store/performance-report/performance-report-slice';
import { Theme } from 'styles/themes';

const Container = styled.div({
  flexGrow: 1,
  backgroundColor: 'white',
  padding: '17px 15px',
  position: 'relative',
  boxShadow: '1px 1px 8px rgba(38, 38, 38, 0.1)',
  borderRadius: Theme.borderRadius,
});

const TableHeader = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: '16px',
});

export const ReportDetailsContainer: React.FC<ReportTableContainerProps> = ({
  tableNameLabel,
  isReportDataFromAverageDevelopmentsPerDay,
  children,
}) => {
  const dispatch = useAppDispatch();

  return (
    <Container>
      <TableHeader>
        <div>
          <span>{tableNameLabel}</span>
        </div>
        <div>
          <RadioButtonGroup
            radioButtonGroupOptions={[
              {
                text: 'Total developments',
                value: 'total',
              },
              {
                text: 'Average per day',
                value: 'average',
              },
            ]}
            direction="row"
            value={
              isReportDataFromAverageDevelopmentsPerDay ? 'average' : 'total'
            }
            fontSize={18}
            radioSize={20}
            setValue={(newValue) => {
              if (newValue === 'average') {
                dispatch(setIsReportDataFromAverageDevelopmentsPerDay(true));
                return;
              }

              dispatch(setIsReportDataFromAverageDevelopmentsPerDay(false));
            }}
            name={'reportType'}
            style={{ marginLeft: 10 }}
          />
        </div>
      </TableHeader>
      {children}
    </Container>
  );
};

interface ReportTableContainerProps extends ReportsLabels {
  isReportDataFromAverageDevelopmentsPerDay: boolean;
}
