import { localStorageKeys } from 'config/local-storage-keys';
import { HttpContentType } from 'enums';
import { AuthenticationResponse, Environment } from 'models';
import { clearLocalStorageAndRedirectToLogin } from 'utils/auth';
import { FileHttpResponse } from './FileHttpResponse';
import { HttpReadStreamActions } from './HttpReadStreamActions';
import { HttpResponse } from './HttpResponse';

const httpRequest = async <T, K>(
  requestPath: string,
  method: string,
  headers: HeadersInit,
  payload: K,
  withAuthorization: boolean,
  readStreamActions?: HttpReadStreamActions
): Promise<HttpResponse<T | null>> => {
  const baseUrl: string = process.env.REACT_APP_API_URL || '';
  const body = payload ? JSON.stringify(payload) : null;

  const authenticationResponse = JSON.parse(
    atob(
      localStorage.getItem(localStorageKeys.authenticationResponse) ||
        btoa('null')
    )
  ) as AuthenticationResponse | undefined;
  const accessToken = authenticationResponse?.accessToken;

  const selectedEnvironment = JSON.parse(
    atob(
      localStorage.getItem(localStorageKeys.selectedEnvironment) || btoa('null')
    )
  ) as Environment | undefined;
  const environmentId = selectedEnvironment?.id
    ? String(selectedEnvironment?.id)
    : '';

  const contentTypeHeaderKey = 'Content-Type';
  let requestContentType: HttpContentType = HttpContentType.JSON;

  if (Object.keys(headers).includes(contentTypeHeaderKey)) {
    requestContentType = (
      headers as { [contentTypeHeaderKey]: HttpContentType }
    )[contentTypeHeaderKey];
  }

  return new Promise<HttpResponse<T | null>>((resolve, reject) => {
    fetch(`${baseUrl}/${requestPath}`, {
      method,
      headers: {
        [contentTypeHeaderKey]: requestContentType || '',
        Authorization:
          withAuthorization && accessToken ? `Bearer ${accessToken}` : '',
        'hz-environment': environmentId,
        'hz-api-key': process.env.REACT_APP_API_KEY || '',
        ...headers,
      },
      body,
    })
      .then(async (res: Response) => {
        const responseHeaders = res.headers;
        let responseContentType: HttpContentType | null = null;
        const responseHeadersContentType =
          responseHeaders.get(contentTypeHeaderKey);
        if (responseHeadersContentType) {
          responseContentType = responseHeadersContentType.split(
            ';'
          )[0] as HttpContentType;
        }

        let receivedLength = 0;
        let chunks: Uint8Array[] = [];
        if (readStreamActions) {
          const reader = res.body?.getReader();
          const contentLength = +(responseHeaders.get('Content-Length') || 0);
          if (!!reader && !!contentLength) {
            readStreamActions.begin();
            while (true) {
              const { done, value } = await reader.read();
              if (done) {
                break;
              }
              if (!!value) {
                chunks.push(value);
                receivedLength += value.length;
              }
              readStreamActions.postProgress(receivedLength, contentLength);
            }
            readStreamActions.end();
          }
        }

        switch (responseContentType) {
          case HttpContentType.JSON:
            return await res.json();
          case HttpContentType.CSV:
            const csvBlob = !!readStreamActions
              ? new Blob(chunks)
              : await res.blob();
            const contentDisposition = responseHeaders.get(
              'content-disposition'
            );
            const fileName = contentDisposition
              ?.split('; ')
              .find((x) => x.includes('filename'))
              ?.split('=')[1];
            return {
              data: {
                fileName,
                blob: csvBlob,
              },
              status: res.status,
              success: true,
              error: null,
            } as HttpResponse<FileHttpResponse>;
          default:
            const allChunks = new Uint8Array(receivedLength);
            let position = 0;
            for (let chunk of chunks) {
              allChunks.set(chunk, position);
              position += chunk.length;
            }
            const result = new TextDecoder('utf-8').decode(allChunks);
            return JSON.parse(result);
        }
      })
      .then(
        (result: HttpResponse<T>) => {
          if (
            result.status === 403 &&
            result.error &&
            (result.error.message === 'jwt expired' ||
              result.error.name === 'TokenExpiredError')
          ) {
            clearLocalStorageAndRedirectToLogin();
          }
          if (!result.success) {
            reject(result);
          }
          resolve(result);
        },
        (error) => {
          if (error instanceof Error || error instanceof TypeError) {
            reject(error.message);
          } else {
            reject(error);
          }
        }
      )
      .catch((error) => {
        reject(error);
      });
  });
};

export const httpGet = async <T>(
  requestPath: string,
  withAuthorization: boolean = true,
  headers: {} = {}
): Promise<HttpResponse<T | null>> => {
  return await httpRequest(
    requestPath,
    'GET',
    headers,
    null,
    withAuthorization
  );
};

export const httpPost = async <T, K>(
  requestPath: string,
  payload: K,
  withAuthorization: boolean = true,
  headers: HeadersInit = {},
  readStreamActions?: HttpReadStreamActions
): Promise<HttpResponse<T | null>> => {
  return await httpRequest(
    requestPath,
    'POST',
    headers,
    payload,
    withAuthorization,
    readStreamActions
  );
};

export const httpPut = async <T, K>(
  requestPath: string,
  payload: K,
  withAuthorization: boolean = true,
  headers: HeadersInit = {}
): Promise<HttpResponse<T | null>> => {
  return await httpRequest(
    requestPath,
    'PUT',
    headers,
    payload,
    withAuthorization
  );
};

export const httpPatch = async <T, K>(
  requestPath: string,
  payload: K,
  withAuthorization: boolean = true,
  headers: HeadersInit = {}
): Promise<HttpResponse<T | null>> => {
  return await httpRequest(
    requestPath,
    'PATCH',
    headers,
    payload,
    withAuthorization
  );
};

export const httpDelete = async <T, K = null>(
  requestPath: string,
  payload?: K,
  withAuthorization: boolean = true,
  headers: HeadersInit = {}
): Promise<HttpResponse<T | null>> => {
  return await httpRequest(
    requestPath,
    'DELETE',
    headers,
    payload,
    withAuthorization
  );
};
