import styled from '@emotion/styled';
import { Theme } from 'styles/themes';

export const ReportsMainBody = styled.div({
  flexGrow: 1,
  backgroundColor: Theme.colors.primaryBackgroundColor,
  padding: 16,
  position: 'relative',
  height: '100%',
});
