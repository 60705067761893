import { getCode } from 'country-list';
import { CountryCode, CountryName } from 'enums';

export const getCountryCodeFromName = (
  countryName: string
): string | undefined => {
  switch (countryName) {
    case CountryName.EuropeanUnion:
      return CountryCode.EuropeanUnion;
    case CountryName.UnitedKingdom:
      return CountryCode.UnitedKingdom;
    case CountryName.UnitedStates:
      return CountryCode.UnitedStates;
    case CountryName.SouthKorea:
      return CountryCode.SouthKorea;
    case CountryName.Taiwan:
      return CountryCode.Taiwan;
    default:
      // Mappings: https://github.com/fannarsh/country-list/blob/master/data.json
      return getCode(countryName);
  }
};
