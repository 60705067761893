import styled from '@emotion/styled';
import { ReactComponent as Redirect } from 'assets/redirect.svg';
import { DevelopmentReviewStatuses, PerformanceReportEntities } from 'enums';
import { routes } from 'navigation/routes';
import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { Theme } from 'styles/themes';
import { reportByOptions } from 'pages/reports/performance/report-by-options';

const RedirectContainer = styled.div({
  cursor: 'pointer',
});

interface RedirectToDevelopmentsButtonProps {
  reviewStatuses: DevelopmentReviewStatuses[];
  id: string | undefined;
  entityType: PerformanceReportEntities;
  period: string;
}

export const RedirectToDevelopmentsButton = ({
  entityType,
  id,
  reviewStatuses,
  period,
}: RedirectToDevelopmentsButtonProps) => {
  const navigate = useNavigate();

  const handleRedirect = useCallback(
    (e) => {
      e.stopPropagation();
      const basePath = generatePath(routes.performanceDevelopmentList, {
        id,
        entityType: reportByOptions[entityType].urlText,
      });
      const statusString = reviewStatuses
        .map((s) => `reviewStatus=${s}`)
        .join('&');
      const params = `?${statusString}&period=${period}`;
      navigate(basePath + params);
    },
    [entityType, period]
  );

  return (
    <RedirectContainer onClick={handleRedirect}>
      <Redirect color={Theme.colors.grayDark} />
    </RedirectContainer>
  );
};
