import addMonths from 'date-fns/addMonths';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { Calendar } from '../Calendar';
import { DaysContainer } from '../DaysContainer';
import { MonthSelector } from '../MonthSelector';
import { useCalendar, useDateRange } from '../useCalendar';
import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { endOfMonth, isAfter, isBefore, isSameMonth } from 'date-fns';
import { Button, OutlinedButton, UnderlinedButton } from 'components/Button';
import { Caption } from 'components/Typography';
import { DateRange, dateRangeToNormalizedString } from 'utils/date';

export interface DatePickerProps {
  initialDate?: Date;
  initialValue?: Partial<DateRange> | null;
  onChange: (value: Date) => void;
  minimumDate?: Date;
  maximumDate?: Date;
  disableWeekends?: boolean;
  onConfirm?: (range: Partial<DateRange>) => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onCancel?: () => void;
  isSingle?: boolean;
}

export const DatePicker = memo((props: DatePickerProps) => {
  const {
    initialDate,
    initialValue,
    onChange,
    onConfirm,
    onCancel,
    isSingle,
    confirmButtonText,
    cancelButtonText,
  } = props;

  const [initialStartDate, initialEndDate] = useMemo(
    () => [initialValue?.startDate, initialValue?.endDate],
    [initialValue]
  );

  const [range, rangeDispatcher] = useDateRange(
    initialStartDate,
    initialEndDate
  );

  const [leftState, leftDispatchers] = useCalendar(
    initialStartDate || initialDate,
    onChange,
    initialStartDate
  );
  const [rightState, rightDispatchers] = useCalendar(
    initialEndDate || addMonths(initialDate || new Date(), 1),
    onChange,
    initialEndDate
  );

  useEffect(() => {
    if (
      isSameMonth(leftState.date, rightState.date) ||
      isAfter(leftState.date, rightState.date)
    ) {
      rightDispatchers.setMonth(leftState.date, leftState.date.getMonth() + 2);
    }
  }, [leftState.date, rightState.date]);

  const applyDateRange = useCallback(
    (d: Date) => {
      if (isSingle) {
        rangeDispatcher.setStartDate(d);
        return;
      }
      if (!range.startDate || isBefore(d, range.startDate)) {
        rangeDispatcher.setStartDate(d);
      } else if (!range.endDate) {
        rangeDispatcher.setEndDate(d);
      } else {
        rangeDispatcher.setStartDate(d);
        rangeDispatcher.setEndDate(undefined);
      }
    },
    [range, rangeDispatcher, isSingle]
  );

  const handleLeftCalendarClick = useCallback(
    (d: Date) => {
      applyDateRange(d);
      leftDispatchers.setValue(d);
    },
    [leftDispatchers, rightState.date]
  );

  const dateRangeStr = useMemo(
    () => dateRangeToNormalizedString(range),
    [range]
  );

  const handleRightCalendarClick = useCallback(
    (d: Date) => {
      applyDateRange(d);
      rightDispatchers.setValue(d);
    },
    [rightDispatchers]
  );

  const handleClear = useCallback(() => {
    rangeDispatcher.setEndDate(undefined);
    rangeDispatcher.setStartDate(undefined);
  }, []);

  const handleConfirm = useCallback(() => {
    if (onConfirm) {
      onConfirm(range);
    }
  }, [range, onConfirm]);

  const minRightCalendarDate = useMemo(
    () => endOfMonth(addMonths(leftState.date, 1)),
    [leftState.date]
  );

  return (
    <DatePickerContainer id="DatePickerContainer" isOpen={true}>
      <Container id="DatePickerInnerContainer">
        <MonthSelectorContainer id="DatePickerMonthSelectorContainer">
          <MonthSelector
            currentDate={leftState.date}
            onNextClick={leftDispatchers.nextMonth}
            onPrevClick={leftDispatchers.prevMonth}
            onMonthChange={leftDispatchers.setMonth}
            onYearChange={leftDispatchers.setYear}
          />
          {!isSingle && (
            <MonthSelector
              currentDate={rightState.date}
              onNextClick={rightDispatchers.nextMonth}
              onPrevClick={rightDispatchers.prevMonth}
              onMonthChange={rightDispatchers.setMonth}
              onYearChange={rightDispatchers.setYear}
              minDate={minRightCalendarDate}
            />
          )}
        </MonthSelectorContainer>
        <CalendarContainer id="DatePickerCalendarContainer" single={isSingle}>
          <CalendarFragment id="DatePickerCalendarFragment" single={isSingle}>
            <DaysContainer />
            <Calendar
              currentDate={leftState.date}
              onClick={handleLeftCalendarClick}
              selectedDate={leftState.value}
              matrix={leftState.calendar[0]}
              minimumDate={props.minimumDate}
              maximumDate={props.maximumDate}
              disableWeekends={props.disableWeekends}
              dateRange={range}
            />
          </CalendarFragment>
          {!isSingle && (
            <CalendarFragment id="DatePickerCalendarFragment2">
              <DaysContainer />
              <Calendar
                currentDate={rightState.date}
                onClick={handleRightCalendarClick}
                selectedDate={rightState.value}
                matrix={rightState.calendar[0]}
                minimumDate={props.minimumDate}
                maximumDate={props.maximumDate}
                disableWeekends={props.disableWeekends}
                dateRange={range}
              />
            </CalendarFragment>
          )}
        </CalendarContainer>
      </Container>
      <ButtonsRow id="DatePickerButtonRow">
        {!isSingle && (
          <UnderlinedButton
            id="DatePickerClearAllButton"
            color={Theme.colors.secondaryColor}
            onClick={handleClear}
            style={{ marginRight: 'auto' }}
          >
            Clear all
          </UnderlinedButton>
        )}
        <FlexContainer style={{ marginLeft: 'auto', alignItems: 'center' }}>
          {!isSingle && dateRangeStr && (
            <FilteringByLabel
              id="DatePickerFilteringByLabel"
              style={{ marginRight: '8px' }}
            >
              Filtering by{' '}
              <Caption
                id="DatePickerDateRangeCaption"
                bold
                color="secondaryColor"
              >
                ({dateRangeStr})
              </Caption>
            </FilteringByLabel>
          )}
          <OutlinedButton
            id="DatePickerCancelButton"
            color={Theme.colors.secondaryColor}
            onClick={onCancel}
            style={{ marginRight: '8px' }}
          >
            {cancelButtonText || 'Cancel'}
          </OutlinedButton>
          <Button
            id="DatePickerOkButton"
            isDisabled={!range.startDate}
            onClick={handleConfirm}
          >
            {confirmButtonText || 'Ok'}
          </Button>
        </FlexContainer>
      </ButtonsRow>
    </DatePickerContainer>
  );
});

const FlexContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
});

const FilteringByLabel = styled.div({
  display: 'inline',
  textAlign: 'right',
  flexGrow: 0.8,
});

const MonthSelectorContainer = styled(FlexContainer)({
  justifyContent: 'space-evenly',
  width: '100%',
});

const ButtonsRow = styled(FlexContainer)({
  borderTop: `2px solid ${Theme.colors.grayBorder}`,
  width: '100%',
  alignItems: 'center',
  padding: '12px 18px',
});

const DatePickerContainer = styled.div<{ isOpen: boolean }>({
  marginTop: '0.5em',
  maxWidth: 600,
});

const Container = styled.div({
  borderRadius: 3,
  padding: 8,
  paddingBottom: 0,
  background: Theme.colors.white,
  left: 0,
  margin: '0 16px',
  maxWidth: '100%',
  top: 'calc(100% + 1.2em)',
  zIndex: 25,
});

const CalendarContainer = styled.div<{ single?: boolean }>((props) => ({
  display: 'flex',
  justifyContent: 'space-evenly',
  padding: props.single ? 0 : '0 12px',
}));

const CalendarFragment = styled.div<{ single?: boolean }>((props) => ({
  maxWidth: props.single ? '100%' : '50%',

  '& + &': {
    marginLeft: '20px',
  },
}));
