export const copyToClipboard = (text: string): void => {
  const shareInput: HTMLInputElement = document.createElement('input');
  shareInput.style.position = 'absolute';
  shareInput.style.top = '0px';
  shareInput.setAttribute('id', 'share-input');
  shareInput.value = text;
  const body = document.body;
  body.appendChild(shareInput);
  shareInput.select();
  shareInput.setSelectionRange(0, Number.MAX_SAFE_INTEGER);
  document.execCommand('copy');
  body.removeChild(shareInput);
};
