import styled from '@emotion/styled';
import { Button } from 'components/Button';
import { Checkbox } from 'components/Checkbox';
import { Modal } from 'components/Modal';
import { filterKeys } from 'config/filter-keys';
import { ActiveFilters } from 'models';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { setTriggerFeedUpdate } from 'store/development/development-slice';
import { selectHomeSidebarFilterAuthorities } from 'store/home-sidebar-filter/home-sidebar-filter-authority-slice';
import { selectHomeSidebarFilterCountries } from 'store/home-sidebar-filter/home-sidebar-filter-country-slice';
import { selectHomeSidebarFilterDocumentTypes } from 'store/home-sidebar-filter/home-sidebar-filter-document-type-slice';
import { selectHomeSidebarFilterLanguages } from 'store/home-sidebar-filter/home-sidebar-filter-language-slice';
import { selectHomeSidebarFilterRegions } from 'store/home-sidebar-filter/home-sidebar-filter-region-slice';
import {
  selectActiveFilters,
  selectFilterCounters,
  setActiveFilters,
  updateActiveFilters,
} from 'store/home-sidebar-filter/home-sidebar-filter-slice';
import { selectHomeSidebarFilterSubcategories } from 'store/home-sidebar-filter/home-sidebar-filter-subcategory-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setNewFeedActiveFilters } from 'store/new-feed-filter/new-feed-filter-slice';
import { Theme } from 'styles/themes';

interface FilterResetWarningModalProps {
  isDisplayed: boolean;
  setIsDisplayed: Dispatch<SetStateAction<boolean>>;
  previousActiveFilters: ActiveFilters;
  previousDatePropertySelected: string | null;
  setIsCreateFeedModalDisplayed: React.Dispatch<boolean>;
  setCreateFeedModalSelectsFeedAfterUpsert: React.Dispatch<boolean>;
  isDateRangeSelected: boolean;
  setIsDateRangeSelected: React.Dispatch<boolean>;
}

const FilterResetWarningModalContainer = styled.div({
  display: 'flex',
  width: '506px',
  maxHeight: '297px',
  position: 'relative',
  padding: '20px 40px',
  alignItems: 'center',
  flexDirection: 'column',
});

const FilterResetWarningModalTitle = styled.p({
  fontWeight: 'bold',
  fontSize: Theme.fontSize + 4,
  lineHeight: '27px',
  marginBottom: '20px',
  textAlign: 'center',
});

const FilterResetWarningModalButtonsContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: '20px',
});

export const FilterResetWarningModal = (
  props: FilterResetWarningModalProps
) => {
  const [savesCurrentFiltersAsFeed, setSavesCurrentFiltersAsFeed] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();
  const activeFilters = useAppSelector(selectActiveFilters);
  const filterCounters = useAppSelector(selectFilterCounters);
  const filterLanguages = useAppSelector(selectHomeSidebarFilterLanguages);
  const filterRegions = useAppSelector(selectHomeSidebarFilterRegions);
  const filterCountries = useAppSelector(selectHomeSidebarFilterCountries);
  const filterAuthorities = useAppSelector(selectHomeSidebarFilterAuthorities);
  const filterSubcategories = useAppSelector(
    selectHomeSidebarFilterSubcategories
  );
  const filterDocumentTypes = useAppSelector(
    selectHomeSidebarFilterDocumentTypes
  );

  useEffect(() => {
    if (props.isDateRangeSelected) {
      const activeFiltersClone = JSON.parse(JSON.stringify(activeFilters));
      if (filterCounters) {
        if (
          activeFiltersClone[filterKeys.authority] &&
          activeFiltersClone[filterKeys.authority].length
        ) {
          activeFiltersClone[filterKeys.authority] = (
            activeFiltersClone[filterKeys.authority] as number[]
          ).filter((id) => {
            return filterAuthorities.some((x) => x.id === id);
          });
          dispatch(
            updateActiveFilters({
              propertyToFilterBy: filterKeys.authority,
              value: activeFiltersClone[filterKeys.authority],
              replace: true,
            })
          );
        }

        if (
          activeFiltersClone[filterKeys.subcategory] &&
          activeFiltersClone[filterKeys.subcategory].length
        ) {
          activeFiltersClone[filterKeys.subcategory] = (
            activeFiltersClone[filterKeys.subcategory] as number[]
          ).filter((id) => {
            return filterSubcategories.some((x) => x.id === id);
          });
          dispatch(
            updateActiveFilters({
              propertyToFilterBy: filterKeys.subcategory,
              value: activeFiltersClone[filterKeys.subcategory],
              replace: true,
            })
          );
        }

        if (
          activeFiltersClone[filterKeys.language] &&
          activeFiltersClone[filterKeys.language].length
        ) {
          activeFiltersClone[filterKeys.language] = (
            activeFiltersClone[filterKeys.language] as number[]
          ).filter((id) => {
            return filterLanguages.some((x) => x.id === id);
          });
          dispatch(
            updateActiveFilters({
              propertyToFilterBy: filterKeys.language,
              value: activeFiltersClone[filterKeys.language],
              replace: true,
            })
          );
        }

        if (
          activeFiltersClone[filterKeys.region] &&
          activeFiltersClone[filterKeys.region].length
        ) {
          activeFiltersClone[filterKeys.region] = (
            activeFiltersClone[filterKeys.region] as number[]
          ).filter((id) => {
            return filterRegions.some((x) => x.id === id);
          });
          dispatch(
            updateActiveFilters({
              propertyToFilterBy: filterKeys.region,
              value: activeFiltersClone[filterKeys.region],
              replace: true,
            })
          );
        }

        if (
          activeFiltersClone[filterKeys.country] &&
          activeFiltersClone[filterKeys.country].length
        ) {
          activeFiltersClone[filterKeys.country] = (
            activeFiltersClone[filterKeys.country] as number[]
          ).filter((id) => {
            return filterCountries.some((x) => x.id === id);
          });
          dispatch(
            updateActiveFilters({
              propertyToFilterBy: filterKeys.country,
              value: activeFiltersClone[filterKeys.country],
              replace: true,
            })
          );
        }

        if (
          activeFiltersClone[filterKeys.documentType] &&
          activeFiltersClone[filterKeys.documentType].length
        ) {
          activeFiltersClone[filterKeys.documentType] = (
            activeFiltersClone[filterKeys.documentType] as number[]
          ).filter((id) => {
            return filterDocumentTypes.some((x) => x.id === id);
          });
          dispatch(
            updateActiveFilters({
              propertyToFilterBy: filterKeys.documentType,
              value: activeFiltersClone[filterKeys.documentType],
              replace: true,
            })
          );
        }

        if (
          !activeFiltersClone[filterKeys.authority].length &&
          !activeFiltersClone[filterKeys.subcategory].length &&
          !activeFiltersClone[filterKeys.language].length &&
          !activeFiltersClone[filterKeys.region].length &&
          !activeFiltersClone[filterKeys.country].length &&
          !activeFiltersClone[filterKeys.documentType].length &&
          (!!activeFilters[filterKeys.authority].length ||
            !!activeFilters[filterKeys.subcategory].length ||
            !!activeFilters[filterKeys.language].length ||
            !!activeFilters[filterKeys.region].length ||
            !!activeFilters[filterKeys.country].length ||
            !!activeFilters[filterKeys.documentType].length)
        ) {
          props.setIsDisplayed(true);
        }
      }
    }
  }, [
    JSON.stringify(activeFilters),
    JSON.stringify(filterCounters),
    JSON.stringify(filterLanguages),
    JSON.stringify(filterRegions),
    JSON.stringify(filterCountries),
    JSON.stringify(filterAuthorities),
    JSON.stringify(filterSubcategories),
    JSON.stringify(filterDocumentTypes),
    props.isDateRangeSelected,
  ]);

  useEffect(() => {
    if (props.isDateRangeSelected) {
      dispatch(setTriggerFeedUpdate());
    }
  }, [JSON.stringify(filterCounters), props.isDateRangeSelected]);

  const closeModal = useCallback(() => {
    props.setIsDateRangeSelected(false);
    setSavesCurrentFiltersAsFeed(false);
    props.setIsDisplayed(false);
  }, []);

  const handleClickButton = useCallback(() => {
    const previousActiveFiltersClone: ActiveFilters = {
      ...props.previousActiveFilters,
    };
    if (props.previousDatePropertySelected) {
      delete previousActiveFiltersClone[props.previousDatePropertySelected];
    }
    dispatch(setActiveFilters(previousActiveFiltersClone));
    if (savesCurrentFiltersAsFeed) {
      dispatch(setNewFeedActiveFilters(props.previousActiveFilters));
      props.setCreateFeedModalSelectsFeedAfterUpsert(false);
      props.setIsCreateFeedModalDisplayed(true);
    }
    dispatch(setTriggerFeedUpdate());
    closeModal();
  }, [
    JSON.stringify(props.previousActiveFilters),
    props.previousDatePropertySelected,
    savesCurrentFiltersAsFeed,
  ]);

  return (
    <Modal id="CreateFeedModal" isDisplayed={props.isDisplayed}>
      <FilterResetWarningModalContainer id="FilterResetWarningModalContainer">
        <FilterResetWarningModalTitle id="FilterResetWarningModalTitle">
          Your filters did not return any results
        </FilterResetWarningModalTitle>
        <span
          id="FilterResetWarningModalExplanation"
          style={{ textAlign: 'center', marginBottom: '20px' }}
        >
          The combination of the selected date and the previously applied
          filters did not return results
        </span>
        <span
          id="FilterResetWarningModalWarning"
          style={{
            textAlign: 'center',
            marginBottom: '20px',
            fontWeight: 'bold',
          }}
        >
          The date range filter will be cleared.
        </span>
        <Checkbox
          id="FilterResetWarningModalNewFeedCheckbox"
          label="Save currently selected filters as a new feed"
          onChange={() =>
            setSavesCurrentFiltersAsFeed(!savesCurrentFiltersAsFeed)
          }
          isChecked={savesCurrentFiltersAsFeed}
        />
        <FilterResetWarningModalButtonsContainer id="FilterResetWarningModalButtonsContainer">
          <Button
            id="FilterResetWarningModalProceedButton"
            onClick={handleClickButton}
          >
            OK
          </Button>
        </FilterResetWarningModalButtonsContainer>
      </FilterResetWarningModalContainer>
    </Modal>
  );
};
