import { memo } from 'react';
import { Row } from '../Row/Row';
import styled from '@emotion/styled';
import { Theme } from 'styles/themes';

const DAYS = [
  { label: 'Su', name: 'Sunday' },
  { label: 'Mo', name: 'Monday' },
  { label: 'Tu', name: 'Tuesday' },
  { label: 'We', name: 'Wednesday' },
  { label: 'Th', name: 'Thursday' },
  { label: 'Fr', name: 'Friday' },
  { label: 'Sa', name: 'Saturday' },
];

export const DaysContainer = memo(() => (
  <DaysRow>
    {DAYS.map((d) => (
      <Day key={d.name}>{d.label}</Day>
    ))}
  </DaysRow>
));

const DaysRow = styled(Row)((props) => ({
  borderRadius: Theme.borderRadius,
  padding: '4px 12px',
}));

const Day = styled.div((props) => ({
  color: Theme.colors.grayDarker,
  fontStyle: 'italic',
  fontSize: Theme.fontSize,
}));
