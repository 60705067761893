import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { OutputConsole } from 'components/OutputConsole';
import { SelectSearchbar } from 'components/SelectSearchbar';
import { ElasticsearchIndexes } from 'enums';
import { Environment } from 'models';
import { useCallback, useState } from 'react';
import { cleanData } from 'services/elasticsearch/elasticsearch-service';
import { Theme } from 'styles/themes';

interface CleanDataCardProps {
  elasticsearchIndexes: {
    id: ElasticsearchIndexes;
    label: ElasticsearchIndexes;
  }[];
  selectedEnvironment: Environment | null;
}

export const CleanDataCard = (props: CleanDataCardProps) => {
  const [selectedIndexes, setSelectedIndexes] = useState<string[]>([]);
  const [outputs, setOutputs] = useState<string[]>([]);
  const [isConfirmationModalDisplayed, setIsConfirmationModalDisplayed] =
    useState(false);
  const [isProcessOnGoing, setIsProcessOnGoing] = useState<boolean>(false);

  const handlePrimaryAction = useCallback(async () => {
    if (props.selectedEnvironment) {
      setIsProcessOnGoing(true);
      setOutputs([]);
      const response = await cleanData(
        props.selectedEnvironment.elasticsearchIndexPrefix,
        selectedIndexes as ElasticsearchIndexes[]
      );
      if (!!response.data) {
        setOutputs((currentValue) => [...currentValue, response.data!]);
      }
      setSelectedIndexes([]);
      setIsProcessOnGoing(false);
    }
  }, [
    props.selectedEnvironment?.elasticsearchIndexPrefix,
    JSON.stringify(selectedIndexes),
  ]);

  return (
    <Card>
      <span
        style={{
          fontSize: Theme.fontSize,
          fontWeight: 'bold',
          color: Theme.colors.primaryColor,
          marginBottom: '16px',
        }}
      >
        Clean Data
      </span>
      <div>
        <div style={{ width: '100%', marginBottom: '16px' }}>
          <SelectSearchbar
            placeholder="Select indexes..."
            recordsPropertyToDisplay="label"
            recordsPropertyToSortBy="label"
            values={selectedIndexes}
            setValues={(values) => setSelectedIndexes(values)}
            isMultiselect
            perPage={props.elasticsearchIndexes.length}
            rawData={props.elasticsearchIndexes}
            isRawDataMode
          />
        </div>
        <Button
          onClick={() => {
            setIsConfirmationModalDisplayed(true);
          }}
          style={{ marginBottom: '16px' }}
          isDisabled={isProcessOnGoing}
        >
          Run
        </Button>
        <div>
          <span
            style={{
              marginBottom: '16px',
              display: 'block',
            }}
          >
            Output
          </span>
          <OutputConsole outputs={outputs} height="300px" />
        </div>
      </div>
      <ConfirmationModal
        title={'Clean Elasticsearch Data?'}
        onPrimaryAction={() => {
          handlePrimaryAction();
          setIsConfirmationModalDisplayed(false);
        }}
        onSecondaryAction={() => {
          setIsConfirmationModalDisplayed(false);
        }}
        isDisplayed={isConfirmationModalDisplayed}
        primaryText="Yes"
        secondaryText="Cancel"
      >
        <span>
          Are you sure that you want to clean the data in elasticsearch for{' '}
          {selectedIndexes.length
            ? selectedIndexes.length === 1
              ? 'index ' + selectedIndexes[0]
              : 'indexes ' + selectedIndexes.join(', ')
            : 'every index'}
          ?
        </span>
      </ConfirmationModal>
    </Card>
  );
};
