import styled from '@emotion/styled';
import { CSSProperties, ReactNode, HTMLAttributes } from 'react';
import { Theme } from 'styles/themes';

export interface AlertProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  style?: CSSProperties;
}

const AlertContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '8px 12px',
  backgroundColor: Theme.colors.grayDarkest,
  borderRadius: Theme.borderRadius,
  color: Theme.colors.white,
  fontSize: Theme.fontSize - 2,
  lineHeight: '19px',
});

export const Alert: React.FC<AlertProps> = (props: AlertProps) => {
  return (
    <AlertContainer id={props.id} style={props.style}>
      {props.children}
    </AlertContainer>
  );
};
