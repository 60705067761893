import { useMemo, useRef, useState } from 'react';
import { BoldText, CursorPointer, FlexContainer } from 'styles/utils';
import { ReactComponent as ArrowDown } from 'assets/arrow-down.svg';
import {
  Dropdown,
  DropdownOption,
  DropdownOptionButton,
  DropdownOptionIcon,
  DropdownOptionLabel,
  DropdownOptionsContainer,
} from 'components/Dropdown';
import {
  RadioButtonGroup,
  RadioButtonGroupOption,
} from 'components/RadioButtonGroup';
import { useAppDispatch } from 'store/hooks';
import { Theme } from 'styles/themes';
import { SortByDirectionButton } from 'components/SortByDirectionButton';
import { SortByDirection } from 'enums';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { NewsBriefing, Option } from 'lib/utils/Option';
import { pascalCase } from 'utils/string-formatter';
import { CountryFlagIcon } from 'components/CountryFlagIcon';
import styled from '@emotion/styled';

interface CountrySelectorDropdownProps {
  jurisdictions: { [key: string]: NewsBriefing };
  selectedJurisdiction?: string;
  handleSetJurisdiction: (jurisdiction: string) => void;
}

const CountryFlagContainer = styled.div({
  marginRight: '10px',
});

export const CountrySelectorDropdown = (
  props: CountrySelectorDropdownProps
) => {
  const dispatch = useAppDispatch();
  const dropdownContainerRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <FlexContainer
      id="CountrySelectorDropdownMainContainer"
      flexWrap="nowrap"
      flexDirection="row"
      ref={dropdownContainerRef}
    >
      <CursorPointer
        style={{
          display: 'flex',
          alignItems: 'center',
          color: Theme.colors.primaryColor,
        }}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <CountryFlagContainer
          id={`CountrySelectorDropdown${pascalCase(
            props.selectedJurisdiction || ''
          )}OptionIcon`}
        >
          <CountryFlagIcon
            countryName={props.selectedJurisdiction || ''}
            marginRight={0}
          />
        </CountryFlagContainer>
        <BoldText id={`CountrySelectorDropdownTitle`}>
          <span
            style={{
              marginRight: '10px',
            }}
          >
            {props.selectedJurisdiction}
          </span>
        </BoldText>
      </CursorPointer>
      <div
        id={`CountrySelectorDropdownOuterContainer`}
        style={{ position: 'relative' }}
      >
        <CursorPointer onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
          <ArrowDown
            id={`CountrySelectorDropdownArrowDown`}
            style={{
              color: Theme.colors.grayDark,
            }}
          />
        </CursorPointer>
        <Dropdown
          id={'CountrySelectorDropdown'}
          isOpen={isDropdownOpen}
          setIsOpen={setIsDropdownOpen}
          containerRef={dropdownContainerRef}
          transform="translate(-94%, 41px)"
          style={{ border: `1px solid ${Theme.colors.grayBorder}` }}
        >
          <DropdownOptionsContainer
            id={`CountrySelectorDropdownOptionsContainer`}
          >
            {Object.entries(props.jurisdictions).map(([key, value], index) => (
              <DropdownOption
                id={`CountrySelectorDropdown${pascalCase(key)}Option`}
                key={key}
                removeDivider={!index}
              >
                <DropdownOptionButton
                  id={`CountrySelectorDropdown${pascalCase(key)}OptionButton`}
                  onClick={() => props.handleSetJurisdiction(key)}
                >
                  <DropdownOptionLabel
                    id={`CountrySelectorDropdown${pascalCase(key)}OptionLabel`}
                  >
                    {key}
                  </DropdownOptionLabel>
                </DropdownOptionButton>
              </DropdownOption>
            ))}
          </DropdownOptionsContainer>
        </Dropdown>
      </div>
    </FlexContainer>
  );
};
