import styled from '@emotion/styled';
import { Avatar } from 'components/Avatar';
import { Checkbox } from 'components/Checkbox';
import { ManagerTooltip } from 'components/ManagerTooltip';
import { Theme } from 'styles/themes';
import { FlexContainer } from 'styles/utils';

interface UserCardProps {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  isManager: boolean;
  onSelect: (id: number) => void;
  selectedIds: number[];
  isLast: boolean;
  isFirstOrSecond: boolean;
}

const UserCardContainer = styled.div<{ isLast?: boolean }>((props) => ({
  display: 'flex',
  padding: '7px 16px',
  alignItems: 'center',
  borderBottom: props.isLast ? 'none' : `1px solid ${Theme.colors.grayBorder}`,
}));

export const UserCard = (props: UserCardProps) => {
  return (
    <UserCardContainer id={`UserCard${props.id}`} isLast={props.isLast}>
      <div
        id={`UserCard${props.id}AvatarContainer`}
        style={{ flex: '0 0 32px' }}
      >
        <Avatar
          id={`UserCard${props.id}AvatarIcon`}
          text={`${props.firstName.charAt(0)}${props.lastName.charAt(0)}`}
        />
      </div>
      <FlexContainer
        id={`UserCard${props.id}NameContainer`}
        style={{ margin: '0 8px', flex: '1 1 auto' }}
      >
        <span
          id={`UserCard${props.id}Name`}
          style={{ marginRight: props.isManager ? '12px' : '0px' }}
        >
          {props.fullName}
        </span>
        {props.isManager && (
          <ManagerTooltip
            id={`UserCard${props.id}ManagerTooltip`}
            isFirstOrSecond={props.isFirstOrSecond}
          />
        )}
      </FlexContainer>
      <FlexContainer
        id={`UserCard${props.id}CheckboxContainer`}
        style={{
          alignItems: 'center',
          flex: '0 0 24px',
          justifyContent: 'flex-end',
        }}
      >
        <Checkbox
          id={`UserCard${props.id}Checkbox`}
          isChecked={props.selectedIds.includes(props.id)}
          onChange={() => props.onSelect(props.id)}
        />
      </FlexContainer>
    </UserCardContainer>
  );
};
