import styled from '@emotion/styled';
import { HTMLAttributes } from 'react';
import { Theme } from 'styles/themes';

interface InputLabelProps extends HTMLAttributes<HTMLLabelElement> {
  htmlFor?: string;
  isFieldRequired?: boolean;
  isBold?: boolean;
}

const Label = styled.label({
  display: 'block',
  marginBottom: '6px',
});

export const InputLabel = (props: InputLabelProps) => {
  return (
    <Label htmlFor={props.htmlFor}>
      <span style={{ fontWeight: props.isBold ? 'bold' : 'initial' }}>
        {props.children}{' '}
      </span>
      {props.isFieldRequired && (
        <span style={{ color: Theme.colors.red }}>*</span>
      )}
    </Label>
  );
};
