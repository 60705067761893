import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { Input } from 'components/Input';
import { useState } from 'react';
import { InputProps, InputRightIconContainer } from 'components/Input';
import { ReactComponent as Eye } from 'assets/eye-gray.svg';
import { ReactComponent as EyeCancel } from 'assets/eye-cancel.svg';

interface PasswordInputProps extends InputProps {}

const StyledPasswordInput = styled(Input)(
  (props: InputProps & { isPasswordVisible: boolean }) => ({
    padding: '8px 38px 8px 8px',
    fontSize: props.value
      ? props.isPasswordVisible
        ? Theme.fontSize - 2
        : '44px'
      : '24px',
  })
);

export const PasswordInput = (props: PasswordInputProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <StyledPasswordInput
      {...props}
      isPasswordVisible={isPasswordVisible}
      type={!isPasswordVisible ? 'password' : 'text'}
      rightIcon={
        props.value && !!props.value.length ? (
          <InputRightIconContainer
            hasCursorPointer
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          >
            {isPasswordVisible && <EyeCancel />}
            {!isPasswordVisible && <Eye />}
          </InputRightIconContainer>
        ) : undefined
      }
    />
  );
};
