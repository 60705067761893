import { applyTimezoneOffset } from './date';

export const searchTermToElasticsearchQueryClause = (
  properties: string[],
  searchTerm: string,
  isByWholeWord?: boolean
): {
  query_string: {
    query: string;
    fields: string[];
    minimum_should_match: string;
  };
} => {
  return {
    query_string: {
      query: isByWholeWord
        ? `${escapeQueryStringQueryReservedCharacters(searchTerm)}`
        : `*${escapeQueryStringQueryReservedCharacters(searchTerm)}*`,
      fields: properties,
      minimum_should_match: '100%',
    },
  };
};

export const numericMatchToElasticsearchQueryClause = (
  property: string,
  numericTerm: string
): {
  match: {
    [x: string]: string;
  };
} => {
  return {
    match: {
      [property]: numericTerm,
    },
  };
};

export const dateRangeStringToElasticsearchQueryClauses = (
  property: string,
  dateRange: string,
  appliesTimezoneOffset: boolean
): {
  range: {
    [key: string]: {
      gte: string;
      lte: string;
    };
  };
} => {
  const [start, end] = dateRange.split('-');
  return {
    range: {
      [property]: {
        gte: appliesTimezoneOffset ? applyTimezoneOffset(start) : start,
        lte: appliesTimezoneOffset ? applyTimezoneOffset(end) : end,
      },
    },
  };
};

/** Reserved characters reference: https://www.elastic.co/guide/en/elasticsearch/reference/7.17/query-dsl-query-string-query.html#_reserved_characters */
export const escapeQueryStringQueryReservedCharacters = (
  query: string
): string => {
  return query.replace(
    /(\+|\-|\=|&&|\|\||\>|\<|\!|\(|\)|\{|\}|\[|\]|\^|"|~|\*|\?|\:|\\|\/|\bAND\b|\bOR\b)/g,
    '\\$&'
  );
};
