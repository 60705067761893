import styled from '@emotion/styled';
import theme, { Theme } from '../../lib/styles/theme';

export const PageContainer = styled.div({
  width: '100%',
  minHeight: '100vh',
  display: 'flex',
  backgroundColor: Theme.colors.primary,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

export const HeaderContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '45px',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.font.color.gray4}`,
  paddingBottom: '15px',
});
export const PageContentContainer = styled.div({
  marginLeft: '5%',
  width: '90%',
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: 'white',
  borderBottom: `8px solid ${Theme.colors.borderGray}`,
  lineHeight: '24px',
});
export const MainContainer = styled.div({
  width: '100%',
  justifyContent: 'start',
  marginTop: '15px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: 'calc(100vh - 55px)',
});

export const DropdownContainer = styled.div({
  width: '50%',
  justifyItems: 'center',
  alignContent: 'center',
  marginBottom: '50px',
});

export const SectionContainer = styled.div({
  width: '60%',
  minHeight: '100vh',
  height: '100%',
  marginRight: '40px',
  display: 'flex',
  flexDirection: 'column',
});

export const SectionHeaderContainer = styled.div({
  display: 'flex',
  alignItems: 'flex-end',
  height: '75px',
  width: '100%',
  justifyContent: 'space-between',
  flexDirection: 'row',
});

export const DevelopmentsContainer = styled.div({
  width: '35%',
  minHeight: '100vh',
  height: '100%',
  marginTop: '20px',
  marginRight: '50px',
});

export const DevelopmentsHeaderContainer = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  height: '50px',
  width: '100%',
  justifyContent: 'space-between',
});

export const NewsBriefContainer = styled.div({
  width: '100%',
  height: '100%',
  display: 'flex',
  fontSize: '16px',
  color: 'black',
  alignItems: 'flex-start',
  justifyContent: 'start',
  flexDirection: 'column',
  backgroundColor: 'white',
  lineHeight: '24px',
});

export const KeyChangesContainer = styled.div({
  display: 'flex',
  marginTop: '32px',
  flexDirection: 'column',
});

export const SectionHeader = styled.div({
  fontSize: '18px',
  fontWeight: 'bold',
  color: theme.sidebar.colorPallete.primary,
});

export const SummaryContainer = styled.div({
  display: 'flex',
  marginTop: '32px',
  flexDirection: 'column',
});

export const Summary = styled.div({
  columnCount: 2,
  columnGap: '40px',
  columnWidth: '40%',
  marginTop: '20px',
  marginBottom: '30px',
  fontSize: '16px',
  lineHeight: '24px',
});

export const FlagContainer = styled.div({
  display: 'flex',
  width: '100%',
});

export const CountryContainer = styled.div({
  display: 'flex',
  marginTop: '35px',
  marginRight: '10px',
});

export const FeedsDropdownContainer = styled.div({
  width: '200px',
});

export const DisclaimerContainer = styled.div({
  display: 'flex',
  backgroundColor: Theme.colors.highlight4,
  width: '90%',
  height: '35px',
  marginLeft: '5%',
  marginTop: '20px',
  padding: '7px',
  paddingLeft: '20px',
});
