import styled from '@emotion/styled';
import { BaseButtonProps } from 'pages/management/interfaces/ManagementDetailButton';
import { HTMLAttrID } from 'models';
import { ReactNode } from 'react';
import { Theme } from 'styles/themes';

const ButtonContainer = styled.div<{
  removeDivider?: boolean;
}>(({ removeDivider }) => ({
  height: '100%',
  display: 'flex',
  borderLeft: `solid ${removeDivider ? 0 : 1}px ${Theme.colors.grayBorder}`,
  flexGrow: 1,
}));

const ActualButton = styled.button<{
  color?: string;
  isDisabled?: boolean;
}>(({ color, isDisabled }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: 'none',
  backgroundColor: Theme.colors.transparent,
  cursor: isDisabled ? 'default' : 'pointer',
  color: color ?? 'inherit',
}));

const IconContainer = styled.div({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '6px',
});

const Label = styled.div({
  fontSize: '16px',
  fontWeight: 'bold',
  lineHeight: '22px',
});

export const DetailButton = (props: DetailButtonProps) => {
  const disabledModifier = props.isDisabled ? '88' : '';
  const color = props.color ? props.color + disabledModifier : undefined;
  return (
    <>
      <ButtonContainer
        id={`${props.id}Container`}
        removeDivider={props.removeDivider}
        ref={props.innerRef}
      >
        <ActualButton
          id={props.id}
          color={color}
          isDisabled={props.isDisabled}
          onClick={!props.isDisabled ? props.onClick : undefined}
        >
          <IconContainer id={`${props.id}IconContainer`}>
            {props.icon}
          </IconContainer>
          <Label id={`${props.id}Label`}>{props.label}</Label>
        </ActualButton>
        {props.element}
      </ButtonContainer>
    </>
  );
};

interface DetailButtonProps extends BaseButtonProps, HTMLAttrID {
  removeDivider?: boolean;
  innerRef?: React.Ref<any>;
  element?: ReactNode;
}
