import styled from '@emotion/styled';
import { Searchbar } from 'components/Searchbar';
import { Theme } from 'styles/themes';
import { FlexContainer } from 'styles/utils';
import { ReportsLabels } from 'pages/reports/interfaces/ReportsLabels';
import { PeriodDropdown } from 'components/PeriodDropdown';
import { ReactNode } from 'react';

interface ReportsSubHeaderProps extends ReportsLabels {
  children?: ReactNode;
  searchTerm?: string;
  onSearchChange?: (value: string) => void;
  period: string;
  setPeriod: (period: string) => void;
}

const SubHeaderContainer = styled.div({
  width: '100%',
  height: '56px',
  padding: '12px 16px',
  borderBottom: `solid 1px ${Theme.colors.grayBorder}`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const ReportDetailsSubHeader = (props: ReportsSubHeaderProps) => {
  return (
    <SubHeaderContainer style={{ justifyContent: 'flex-end' }}>
      {props.onSearchChange && (
        <div style={{ width: '30%' }}>
          <Searchbar
            value={props.searchTerm}
            setValue={props.onSearchChange}
            placeholder={props.searchBarLabel}
          />
        </div>
      )}
      <FlexContainer style={{ alignItems: 'center' }}>
        <PeriodDropdown
          periodProperty={props.period}
          setPeriodProperty={props.setPeriod}
        />
      </FlexContainer>
    </SubHeaderContainer>
  );
};
