import { useCallback, useEffect, useMemo, useState } from 'react';
import { FlexContainer } from 'styles/utils';
import {
  ReportsHeader,
  ReportsIndexSubHeader,
  ReportsMainBody,
} from 'pages/reports/components';
import { turnaroundTimeReportLabels } from './labels';
import { Loading } from 'components/Loading';
import { TurnaroundTimeTable } from './components/TurnaroundTimeTable';
import { Pagination } from 'components/Pagination';
import { SidebarModal } from './components/SidebarModal';
import {
  getAllEnvironments,
  getCountriesList,
  getSourceList,
} from 'services/reports/reports-service';
import { CountriesList, EnvironmentsList, Source } from 'models/reports/Source';
import { SortByDirection, UserRoles } from 'enums';
import { EnvironmentModal } from 'components/EnvironmentModal';
import { exportToExcel } from 'helpers/excel-download';
import { exportHeader } from 'config/IndexReportHeader';
import { selectAuthenticationResponse } from 'store/authentication/authentication-slice';
import { useAppSelector } from 'store/hooks';
import { selectSelectedEnvironment } from 'store/environment/environment-slice';

interface IndexTimeReportProps {
  selectedIndexId: number;
  environmentsList: EnvironmentsList[];
}

export const IndexTimeReport = ({
  selectedIndexId,
  environmentsList,
}: IndexTimeReportProps) => {
  const authenticationResponse = useAppSelector(selectAuthenticationResponse);
  const [selectedCountryName, setSelectedCountryName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortByProperty, setSortByProperty] = useState('RowId');
  const [sortByDirection, setSortByDirection] = useState(SortByDirection.DESC);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Source[]>([]);
  const [countriesList, setCountriesList] = useState<CountriesList[]>([]);
  const [sidebarData, setSidebarData] = useState<Source>();
  const [isSidebarDisplayed, setIsSidebarDisplayed] = useState(false);
  const [error, setError] = useState<string | null>(null); // Error state accepts string or null
  const [environmentIds, setEnvironmentIds] = useState<string>('');
  const [isEnvironmentModalDisplayed, setIsEnvironmentModalDisplayed] =
    useState(false);
  const [countriesIds, setCountriesIds] = useState<string>('');
  const hasSuperadminRole = !!authenticationResponse?.roles?.includes(
    UserRoles.Superadmin
  );
  const [organizationId, setOrganizationId] = useState<number | undefined>();
  const [environmentsListData, setEnvironmentsListData] = useState<
    EnvironmentsList[]
  >([]);

  useEffect(() => {
    if (!selectedIndexId && selectedIndexId == 0) {
      setOrganizationId(undefined);
    } else {
      setOrganizationId(selectedIndexId);
    }
  }, [selectedIndexId]);

  useEffect(() => {
    hasSuperadminRole && setOrganizationId(undefined);
  }, []);

  const getEnvironmentsList = async () => {
    setIsLoading(true);
    setError(null); // Clear previous errors
    try {
      const response = await getAllEnvironments(organizationId);

      // Assert or check that data is an array
      if (Array.isArray(response?.data)) {
        const filteredEnvironments: EnvironmentsList[] = response.data.map(
          ({ id, name, organizationId, organizationName }) => ({
            id,
            name,
            organizationId,
            organizationName,
          })
        );
        setEnvironmentsListData(filteredEnvironments);
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        setError(err.message); // Accessing `message` property of `Error`
      } else {
        setError('An unexpected error occurred'); // Handling non-Error cases
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getEnvironmentsList();
  }, [organizationId]);

  const getAllCountriesList = async () => {
    setIsLoading(true);
    setError(null); // Clear previous errors
    try {
      const response = await getCountriesList();
      // Safely access records and check if it's an array
      const records = response?.data?.records;
      if (Array.isArray(records)) {
        setCountriesList(records);
      } else {
        setCountriesList([]); // Fallback to an empty array if no data
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        setError(err.message); // Accessing `message` property of `Error`
      } else {
        setError('An unexpected error occurred'); // Handling non-Error cases
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllCountriesList();
  }, []);

  const fetchSourceList = async () => {
    setIsLoading(true);
    setError(null); // Clear previous errors
    try {
      const response = await getSourceList(
        searchTerm,
        searchTerm || environmentIds || countriesIds || organizationId
          ? 1
          : page,
        perPage,
        sortByProperty,
        sortByDirection,
        environmentIds,
        countriesIds,
        organizationId
      );
      setTotalRecords(response?.data?.totalRecords ?? 0);
      setData(response?.data?.records ?? []); // Assuming `items` contains the data
    } catch (err: unknown) {
      if (err instanceof Error) {
        setError(err.message); // Accessing `message` property of `Error`
      } else {
        setError('An unexpected error occurred'); // Handling non-Error cases
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (searchTerm || environmentIds || countriesIds || organizationId) {
      setPage(1);
    }
  }, [searchTerm, environmentIds, countriesIds, organizationId]);

  useEffect(() => {
    if (hasSuperadminRole) {
      fetchSourceList();
    } else {
      if (organizationId) {
        fetchSourceList();
      }
    }
  }, [environmentIds, searchTerm, page, organizationId, countriesIds]); // Re-fetch on dependency change

  const handleSearchChange = (searchText: string) => {
    setSearchTerm(searchText);
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleRowClick = (row: Source) => {
    setSidebarData(row);
    setIsSidebarDisplayed(!isSidebarDisplayed);
    setSelectedCountryName(row.Countries || '');
  };

  const handleSelectedIds = (ids: string) => {
    setEnvironmentIds(ids);
  };
  const handleSelectedCountriesIds = (ids: string) => {
    setCountriesIds(ids);
  };

  const onClickEnvironment = useCallback(() => {
    setIsEnvironmentModalDisplayed(true);
  }, []);

  const handleExportReports = () => {
    exportToExcel(data, exportHeader);
  };

  const handleSaveEnvironment = useCallback(() => {
    fetchSourceList();
    setIsSidebarDisplayed(false);
  }, []);

  const handleClosedSidebar = (isClosed: boolean) => {
    setIsSidebarDisplayed(isClosed);
  };

  return (
    <>
      <FlexContainer
        flexWrap="nowrap"
        flexDirection="column"
        style={{
          width: '100%',
          flex: 1,
          overflowX: 'hidden',
        }}
      >
        <ReportsHeader
          breadcrumb={[{ text: turnaroundTimeReportLabels.title }]}
        />
        <ReportsIndexSubHeader
          {...turnaroundTimeReportLabels}
          searchBarLabel={`${turnaroundTimeReportLabels.searchBarLabel}...`}
          searchProps={{
            searchTerm: searchTerm,
            onSearchChange: handleSearchChange,
          }}
          hasNoBorders={true}
          filterOptions={environmentsListData}
          CoutriesFilterOptions={countriesList}
          selectedIds={handleSelectedIds}
          selectedCountriesIds={handleSelectedCountriesIds}
          onExportReports={handleExportReports}
        />
        <ReportsMainBody
          style={{
            padding: 0,
            backgroundColor: 'white',
          }}
        >
          {isLoading && <Loading />}
          <FlexContainer style={{ height: 'calc(100% - 48px)' }}>
            <div
              style={{
                flexGrow: 1,
                backgroundColor: 'white',
                padding: '16px',
                position: 'relative',
              }}
            >
              <TurnaroundTimeTable
                data={data}
                handleRowClick={handleRowClick}
              />
            </div>
          </FlexContainer>
          <Pagination
            id="TurnaroundTimePagination"
            currentPage={page}
            totalPages={Math.ceil(totalRecords / perPage)}
            onPageChange={handlePageChange}
            justifyContent="center"
          />
        </ReportsMainBody>
        {isSidebarDisplayed && (
          <SidebarModal
            isDisplayed={isSidebarDisplayed}
            data={sidebarData}
            country={selectedCountryName}
            isManagement={hasSuperadminRole}
            onClickEnvironment={onClickEnvironment}
            closedSidebar={handleClosedSidebar}
          />
        )}
        {isEnvironmentModalDisplayed &&
          environmentsList &&
          environmentsList.length > 0 && (
            <EnvironmentModal
              isDisplayed={isEnvironmentModalDisplayed}
              setIsDisplayed={setIsEnvironmentModalDisplayed}
              environmentsList={environmentsList}
              sidebarData={sidebarData}
              onSaveEnvironment={handleSaveEnvironment}
            />
          )}
      </FlexContainer>
    </>
  );
};
