import styled from '@emotion/styled';
import { Searchbar } from 'components/Searchbar';
import { Theme } from 'styles/themes';
import { VerticalDivider, FlexContainer } from 'styles/utils';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  StatusDropdown,
  PerformanceSortDevelopmentsDropdown,
} from 'pages/reports/performance/components/PerformanceDevelopmentList/components';
import {
  selectPeriodProperty,
  setPeriodProperty,
  selectPerformanceReportDevelopmentsSearchTerm,
  setPerformanceReportDevelopmentsSearchTerm,
} from 'store/performance-report/performance-report-slice';
import { PeriodDropdown } from 'components/PeriodDropdown';

const SubHeaderContainer = styled.div({
  width: '100%',
  height: '56px',
  padding: '12px 16px',
  borderBottom: `solid 1px ${Theme.colors.grayBorder}`,
  backgroundColor: Theme.colors.white,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const ReportDevelopmentsListSubHeader = () => {
  const dispatch = useAppDispatch();
  const searchTerm = useAppSelector(
    selectPerformanceReportDevelopmentsSearchTerm
  );
  const periodProperty = useAppSelector(selectPeriodProperty);

  const handleSetPeriodProperty = (period: string) => {
    dispatch(setPeriodProperty(period));
  };

  return (
    <SubHeaderContainer style={{ justifyContent: 'space-between' }}>
      <div style={{ width: '30%' }}>
        <Searchbar
          value={searchTerm}
          setValue={(value) => {
            dispatch(setPerformanceReportDevelopmentsSearchTerm(value));
          }}
          placeholder={'Search developments...'}
        />
      </div>
      <FlexContainer style={{ alignItems: 'center' }}>
        <StatusDropdown />
        <VerticalDivider height={'24px'} style={{ margin: '0 6px' }} />
        <PerformanceSortDevelopmentsDropdown />
        <VerticalDivider height={'24px'} style={{ margin: '0 6px' }} />
        <PeriodDropdown
          periodProperty={periodProperty}
          setPeriodProperty={handleSetPeriodProperty}
          onSelectPeriod={(period: string) => {
            const url = window.location.href;
            const urlWithoutPeriod = url.split('&period')[0];
            window.history.pushState(
              '',
              '',
              `${urlWithoutPeriod}&period=${period}`
            );
          }}
        />
      </FlexContainer>
    </SubHeaderContainer>
  );
};
