import {
  NewsBriefing,
  NewsBriefingPayload,
  NewsBriefingResponse,
} from 'lib/utils/Option';
import { httpGet, httpPost } from 'utils/http/base-http-service';
import { HttpResponse } from 'utils/http/HttpResponse';

export const getNewsBrief = async (
  NewsBriefingPayload: NewsBriefingPayload
): Promise<HttpResponse<NewsBriefingResponse | null>> => {
  return await httpPost<NewsBriefingResponse | null, NewsBriefingPayload>(
    `news-briefing`,
    NewsBriefingPayload
  );
};
