import { FeedCard } from './components/FeedCard';
import { FeedListEmptyState } from './FeedListEmptyState';
import { InfiniteList } from 'components/InfiniteList';
import { Loading } from 'components/Loading';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getCurrentUserFeedsAsync,
  increaseFeedsPage,
  selectFeedAsync,
  selectFeedPickerFeeds,
  selectFeedPickerFeedsPage,
  selectFeedPickerFeedsPerPage,
  selectFeedPickerFeedsSearchTerm,
  selectFeedPickerFeedsTotalRecords,
  selectFeedPickerAppliedFeed,
} from 'store/feed/feed-picker-slice';
import { Feed } from 'models';

interface FeedListProps {
  setIsFeedPickerDropdownOpen: React.Dispatch<boolean>;
}

export const FeedList = (props: FeedListProps) => {
  const dispatch = useAppDispatch();
  const selectedFeed = useAppSelector(selectFeedPickerAppliedFeed);
  const feeds = useAppSelector(selectFeedPickerFeeds);
  const totalRecords = useAppSelector(selectFeedPickerFeedsTotalRecords);
  const page = useAppSelector(selectFeedPickerFeedsPage);
  const perPage = useAppSelector(selectFeedPickerFeedsPerPage);
  const searchTerm = useAppSelector(selectFeedPickerFeedsSearchTerm);
  const [scrollableContainer, setScrollableContainer] =
    useState<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (!feeds || !feeds.length) {
        setIsLoading(true);
      }
      await dispatch(getCurrentUserFeedsAsync());
      setIsLoading(false);
    })();
  }, [page, searchTerm, selectedFeed]);

  const handleReachBottom = () => {
    dispatch(increaseFeedsPage());
  };

  const handleFeedClick = (feed: Feed) => {
    dispatch(selectFeedAsync(feed));
    props.setIsFeedPickerDropdownOpen(false);
  };

  return (
    <div
      id="FeedList"
      ref={(element) => setScrollableContainer(element)}
      style={{
        minHeight: '120px',
        maxHeight: '480px',
        width: '100%',
        overflowY: 'auto',
      }}
    >
      {isLoading && <Loading id="DevelopmentListLoading" />}
      {!isLoading && feeds.length === 0 && <FeedListEmptyState />}
      {!isLoading && feeds.length > 0 && scrollableContainer && (
        <InfiniteList
          targetRef={scrollableContainer}
          onReachBottom={handleReachBottom}
          hasMorePages={page < Math.ceil(totalRecords / perPage)}
        >
          {feeds.map((feed) => (
            <FeedCard
              key={feed.id}
              feed={feed}
              onClick={() => handleFeedClick(feed)}
            />
          ))}
        </InfiniteList>
      )}
    </div>
  );
};
