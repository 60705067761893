import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ActiveFilters } from 'models';
import { addOrRemoveIfExists } from 'utils/array';

export interface NewFeedFilterState {
  activeFilters: ActiveFilters;
  isAuthoritiesFilterCollapsed: boolean;
  isSubcategoriesFilterCollapsed: boolean;
  isLanguagesFilterCollapsed: boolean;
  isRegionsFilterCollapsed: boolean;
  isCountriesFilterCollapsed: boolean;
  isDocumentTypesFilterCollapsed: boolean;
  triggerGetAllFilterSetEntries: number;
}

const initialState: NewFeedFilterState = {
  activeFilters: {},
  isAuthoritiesFilterCollapsed: true,
  isSubcategoriesFilterCollapsed: true,
  isLanguagesFilterCollapsed: true,
  isRegionsFilterCollapsed: true,
  isCountriesFilterCollapsed: true,
  isDocumentTypesFilterCollapsed: true,
  triggerGetAllFilterSetEntries: 0,
};

export const newFeedFilterSlice = createSlice({
  name: 'new-feed-filter',
  initialState,
  reducers: {
    updateNewFeedActiveFilters: (
      state,
      action: PayloadAction<{
        propertyToFilterBy: string;
        value: any;
      }>
    ) => {
      const { propertyToFilterBy, value } = action.payload;
      state.activeFilters[propertyToFilterBy] = addOrRemoveIfExists(
        state.activeFilters[propertyToFilterBy] as any[],
        value
      );
    },
    setNewFeedActiveFilters: (state, action: PayloadAction<ActiveFilters>) => {
      state.activeFilters = action.payload;
    },
    setIsNewFeedFilterAuthoritiesCollapsed: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isAuthoritiesFilterCollapsed = action.payload;
    },
    setIsNewFeedFilterSubcategoriesCollapsed: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isSubcategoriesFilterCollapsed = action.payload;
    },
    setIsNewFeedFilterLanguagesCollapsed: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isLanguagesFilterCollapsed = action.payload;
    },
    setIsNewFeedFilterRegionsCollapsed: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isRegionsFilterCollapsed = action.payload;
    },
    setIsNewFeedFilterCountriesCollapsed: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isCountriesFilterCollapsed = action.payload;
    },
    setIsNewFeedFilterDocumentTypesCollapsed: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isDocumentTypesFilterCollapsed = action.payload;
    },
    setNewFeedFilterTriggerGetAllFilterSetEntries: (state) => {
      state.triggerGetAllFilterSetEntries =
        state.triggerGetAllFilterSetEntries + 1;
    },
  },
});

export const {
  updateNewFeedActiveFilters,
  setNewFeedActiveFilters,
  setIsNewFeedFilterAuthoritiesCollapsed,
  setIsNewFeedFilterSubcategoriesCollapsed,
  setIsNewFeedFilterLanguagesCollapsed,
  setIsNewFeedFilterRegionsCollapsed,
  setIsNewFeedFilterCountriesCollapsed,
  setIsNewFeedFilterDocumentTypesCollapsed,
  setNewFeedFilterTriggerGetAllFilterSetEntries,
} = newFeedFilterSlice.actions;

export const selectNewFeedActiveFilters = (state: RootState) =>
  state.newFeedFilter.activeFilters;
export const selectIsNewFeedFilterAuthoritiesCollapsed = (state: RootState) =>
  state.newFeedFilter.isAuthoritiesFilterCollapsed;
export const selectIsNewFeedFilterSubcategoriesCollapsed = (state: RootState) =>
  state.newFeedFilter.isSubcategoriesFilterCollapsed;
export const selectIsNewFeedFilterLanguagesCollapsed = (state: RootState) =>
  state.newFeedFilter.isLanguagesFilterCollapsed;
export const selectIsNewFeedFilterRegionsCollapsed = (state: RootState) =>
  state.newFeedFilter.isRegionsFilterCollapsed;
export const selectIsNewFeedFilterCountriesCollapsed = (state: RootState) =>
  state.newFeedFilter.isCountriesFilterCollapsed;
export const selectIsNewFeedFilterDocumentTypesCollapsed = (state: RootState) =>
  state.newFeedFilter.isDocumentTypesFilterCollapsed;
export const selectNewFeedFilterTriggerGetAllFilterSetEntries = (
  state: RootState
) => state.newFeedFilter.triggerGetAllFilterSetEntries;

export default newFeedFilterSlice.reducer;
