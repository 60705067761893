import styled from '@emotion/styled';
import { Theme } from 'styles/themes';

interface DevelopmentCardLeftSideProps {
  developmentId?: number;
  obligationsCount?: number;
}

const DevelopmentCardLeftSideContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flex: '0 0 123px',
  borderRight: `1px solid ${Theme.colors.grayBorder}`,
});

const ObligationTitle = styled.div((props) => ({
  fontSize: Theme.fontSize + 2,
  lineHeight: '25px',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  marginTop: 0,
  color: 'inherit',
}));

const ObligationAmount = styled.div((props) => ({
  fontWeight: 'bold',
  fontSize: '35px',
  lineHeight: '48px',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  color: 'inherit',
}));

export const DevelopmentCardLeftSide = (
  props: DevelopmentCardLeftSideProps
) => {
  const obligationAmount = props.obligationsCount || 0;

  return (
    <DevelopmentCardLeftSideContainer
      id={`DevelopmentCardLeftSideContainer${props.developmentId}`}
    >
      <ObligationTitle
        id={`DevelopmentCardLeftSideObligationTitle${props.developmentId}`}
        title={`Obligations: ${obligationAmount}`}
      >
        <span
          id={`DevelopmentCardLeftSideObligationTitleSpan${props.developmentId}`}
        >
          OBL
        </span>
      </ObligationTitle>
      <ObligationAmount
        id={`DevelopmentCardLeftSideObligationAmount${props.developmentId}`}
        title={`Obligations: ${obligationAmount}`}
      >
        <span
          id={`DevelopmentCardLeftSideObligationAmountSpan${props.developmentId}`}
        >
          {obligationAmount >= 1000 ? '999+' : obligationAmount}
        </span>
      </ObligationAmount>
    </DevelopmentCardLeftSideContainer>
  );
};
