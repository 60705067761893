import { httpGet, httpPost, httpPut } from 'utils/http/base-http-service';
import { HttpResponse } from 'utils/http/HttpResponse';
import { DevelopmentCitation, Environment, PaginatedResponse } from 'models';
import { toQueryParams } from 'utils/to-query-params';

export const getDevelopmentCitationsOfDevelopment = async (
  developmentId: number,
  environment: Environment | null,
  regionId?: number,
  processingDate?: string,
  page?: number,
  perPage?: number
): Promise<HttpResponse<PaginatedResponse<DevelopmentCitation> | null>> => {
  const query = {
    filter: [
      {
        bool: {
          must: [
            {
              match: {
                developmentId,
              },
            },
            {
              match: {
                isAutomatic: environment
                  ? environment.defaultsToAutomaticDevelopmentCitations
                  : true,
              },
            },
          ],
        },
      },
    ],
  };
  const queryParameters = toQueryParams({
    query: JSON.stringify(query),
    page,
    perPage,
    regionId,
    processingDate,
  });
  return await httpGet<PaginatedResponse<DevelopmentCitation>>(
    `development-citations?${queryParameters}`
  );
};
