import { CopilotChatPayload } from 'models/CopilotChatPayload';
import { httpGet, httpPost } from 'utils/http/base-http-service';
import { HttpResponse } from 'utils/http/HttpResponse';

export const getCopilotChat = async (
  copilotChatPayload: CopilotChatPayload
): Promise<HttpResponse<any | null>> => {
  return await httpPost<any | null, CopilotChatPayload>(
    `copilot/query`,
    copilotChatPayload
  );
};
