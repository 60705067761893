import { Modal } from 'components/Modal';
import { BodyBig } from 'components/Typography';
import { FlexContainer } from 'styles/utils';
import { Button, OutlinedButton } from 'components/Button';
import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { HTMLAttrID } from 'models';
import { Theme } from 'styles/themes';

interface ConfirmationModalProps extends HTMLAttrID {
  title: string;
  children: ReactNode;
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  primaryText?: string;
  secondaryText?: string;
  isDisplayed: boolean;
  titleIcon?: ReactNode;
}

const MainContainer = styled.div({
  padding: 24,
  maxWidth: '30vw',
  textAlign: 'center',
});

const ButtonsContainer = styled(FlexContainer)({
  width: '100%',
  justifyContent: 'center',
  marginTop: 16,
  columnGap: 8,
});

const TitleContainer = styled.div({
  width: '100%',
  marginBottom: 16,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const ConfirmationModal = (props: ConfirmationModalProps) => {
  return (
    <Modal id={props.id} isDisplayed={props.isDisplayed}>
      <MainContainer id={`${props.id}MainContainer`}>
        <TitleContainer id={`${props.id}TitleContainer`}>
          {props.titleIcon}
          <BodyBig id={`${props.id}Title`} bold noMargin>
            {props.title}
          </BodyBig>
        </TitleContainer>
        {props.children}
        <ButtonsContainer id={`${props.id}ButtonsContainer`}>
          <OutlinedButton
            id={`${props.id}CancelButton`}
            color={Theme.colors.secondaryColor}
            onClick={props.onSecondaryAction}
          >
            {props.secondaryText || 'Cancel'}
          </OutlinedButton>
          <Button id={`${props.id}OkButton`} onClick={props.onPrimaryAction}>
            {props.primaryText || 'Ok'}
          </Button>
        </ButtonsContainer>
      </MainContainer>
    </Modal>
  );
};
