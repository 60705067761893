import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { getSubcategoriesWithDevelopmentCountGreaterThanZero } from 'services/subcategories/subcategories-service';
import { Subcategory, PaginatedResponse } from 'models';
import { paginationConfig } from 'config/pagination-config';

export interface TurnaroundTimeFilterSubcategoryState {
  subcategories: Subcategory[];
  totalRecords: number;
  searchTerm: string;
  page: number;
  previousPage: number;
  perPage: number;
}

const initialState: TurnaroundTimeFilterSubcategoryState = {
  subcategories: [],
  totalRecords: 0,
  searchTerm: '',
  page: 1,
  previousPage: 1,
  perPage: paginationConfig.subcategoriesPerPage,
};

export const getAllTurnaroundTimeFilterSubcategoriesAsync = (): AppThunk<
  Promise<PaginatedResponse<Subcategory> | null>
> => {
  return async (dispatch, getState) => {
    const selectedEnvironment = getState().environment.selectedEnvironment;
    const searchTerm = getState().turnaroundTimeFilterSubcategory.searchTerm;
    const page = getState().turnaroundTimeFilterSubcategory.page;
    const previousPage =
      getState().turnaroundTimeFilterSubcategory.previousPage;
    const perPage = getState().turnaroundTimeFilterSubcategory.perPage;
    const activeFilters =
      getState().turnaroundTimeFilter.turnaroundTimeActiveFilters;
    const response = await getSubcategoriesWithDevelopmentCountGreaterThanZero(
      selectedEnvironment,
      searchTerm,
      page,
      perPage,
      activeFilters,
      []
    );
    if (page === 1) {
      dispatch(setTurnaroundTimeFilterSubcategories(response.data));
    } else {
      if (page > previousPage) {
        dispatch(addTurnaroundTimeFilterSubcategories(response.data));
      }
    }
    return response.data;
  };
};

export const turnaroundTimeFilterSubcategorySlice = createSlice({
  name: 'turnaround-time-filter-subcategory',
  initialState,
  reducers: {
    setTurnaroundTimeFilterSubcategories: (
      state,
      action: PayloadAction<PaginatedResponse<Subcategory> | null>
    ) => {
      state.subcategories = action.payload?.records ?? [];
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    addTurnaroundTimeFilterSubcategories: (
      state,
      action: PayloadAction<PaginatedResponse<Subcategory> | null>
    ) => {
      state.subcategories = state.subcategories.concat(
        action.payload?.records ?? []
      );
      state.totalRecords = action.payload?.totalRecords ?? 0;
      state.previousPage = state.page;
    },
    setTurnaroundTimeFilterSubcategoriesSearchTerm: (
      state,
      action: PayloadAction<string>
    ) => {
      state.page = 1;
      state.searchTerm = action.payload;
    },
    increaseTurnaroundTimeFilterSubcategoriesPage: (state) => {
      state.page = state.page + 1;
    },
    resetTurnaroundTimeFilterSubcategoriesPage: (state) => {
      state.page = 1;
      state.previousPage = 1;
    },
  },
});

export const {
  setTurnaroundTimeFilterSubcategories,
  addTurnaroundTimeFilterSubcategories,
  setTurnaroundTimeFilterSubcategoriesSearchTerm,
  increaseTurnaroundTimeFilterSubcategoriesPage,
  resetTurnaroundTimeFilterSubcategoriesPage,
} = turnaroundTimeFilterSubcategorySlice.actions;

export const selectTurnaroundTimeFilterSubcategories = (state: RootState) =>
  state.turnaroundTimeFilterSubcategory.subcategories;
export const selectTurnaroundTimeFilterSubcategoriesTotalRecords = (
  state: RootState
) => state.turnaroundTimeFilterSubcategory.totalRecords;
export const selectTurnaroundTimeFilterSubcategoriesSearchTerm = (
  state: RootState
) => state.turnaroundTimeFilterSubcategory.searchTerm;
export const selectTurnaroundTimeFilterSubcategorySearchTerm = (
  state: RootState
) => state.turnaroundTimeFilterSubcategory.searchTerm;
export const selectTurnaroundTimeFilterSubcategoriesPage = (state: RootState) =>
  state.turnaroundTimeFilterSubcategory.page;
export const selectTurnaroundTimeFilterSubcategoriesPerPage = (
  state: RootState
) => state.turnaroundTimeFilterSubcategory.perPage;

export default turnaroundTimeFilterSubcategorySlice.reducer;
