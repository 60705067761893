import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { getDocumentTypes } from 'services/document-types/document-types-service';
import { DocumentType, PaginatedResponse } from 'models';
import { paginationConfig } from 'config/pagination-config';

export interface NewFeedFilterDocumentTypeState {
  documentTypes: DocumentType[];
  totalRecords: number;
  searchTerm: string;
  page: number;
  perPage: number;
}

const initialState: NewFeedFilterDocumentTypeState = {
  documentTypes: [],
  totalRecords: 0,
  searchTerm: '',
  page: 1,
  perPage: paginationConfig.documentTypesPerPage,
};

export const getAllNewFeedFilterDocumentTypesAsync = (): AppThunk<
  Promise<PaginatedResponse<DocumentType> | null>
> => {
  return async (dispatch, getState) => {
    const searchTerm = getState().newFeedFilterDocumentType.searchTerm;
    const page = getState().newFeedFilterDocumentType.page;
    const perPage = getState().newFeedFilterDocumentType.perPage;
    const response = await getDocumentTypes(searchTerm, page, perPage);
    if (page > 1) {
      dispatch(addNewFeedFilterDocumentTypes(response.data));
    } else {
      dispatch(setNewFeedFilterDocumentTypes(response.data));
    }
    return response.data;
  };
};

export const newFeedFilterDocumentTypeSlice = createSlice({
  name: 'new-feed-filter-documentType',
  initialState,
  reducers: {
    setNewFeedFilterDocumentTypes: (
      state,
      action: PayloadAction<PaginatedResponse<DocumentType> | null>
    ) => {
      state.documentTypes = action.payload?.records ?? [];
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    addNewFeedFilterDocumentTypes: (
      state,
      action: PayloadAction<PaginatedResponse<DocumentType> | null>
    ) => {
      state.documentTypes = state.documentTypes.concat(
        action.payload?.records ?? []
      );
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    setNewFeedFilterDocumentTypesSearchTerm: (
      state,
      action: PayloadAction<string>
    ) => {
      state.page = 1;
      state.searchTerm = action.payload;
    },
    increaseNewFeedFilterDocumentTypesPage: (state) => {
      state.page = state.page + 1;
    },
    resetNewFeedFilterDocumentTypesPage: (state) => {
      state.page = 1;
    },
    resetNewFeedFilterDocumentTypesSearchTerm: (state) => {
      state.searchTerm = '';
    },
  },
});

export const {
  setNewFeedFilterDocumentTypes,
  addNewFeedFilterDocumentTypes,
  setNewFeedFilterDocumentTypesSearchTerm,
  increaseNewFeedFilterDocumentTypesPage,
  resetNewFeedFilterDocumentTypesPage,
  resetNewFeedFilterDocumentTypesSearchTerm,
} = newFeedFilterDocumentTypeSlice.actions;

export const selectNewFeedFilterDocumentTypes = (state: RootState) =>
  state.newFeedFilterDocumentType.documentTypes;
export const selectNewFeedFilterDocumentTypesTotalRecords = (
  state: RootState
) => state.newFeedFilterDocumentType.totalRecords;
export const selectNewFeedFilterDocumentTypesSearchTerm = (state: RootState) =>
  state.newFeedFilterDocumentType.searchTerm;
export const selectNewFeedFilterDocumentTypesPage = (state: RootState) =>
  state.newFeedFilterDocumentType.page;
export const selectNewFeedFilterDocumentTypesPerPage = (state: RootState) =>
  state.newFeedFilterDocumentType.perPage;

export default newFeedFilterDocumentTypeSlice.reducer;
