import { FeedAppliedFiltersProps } from './interfaces';
import { StaticFeedAppliedFilters } from './StaticFeedAppliedFilters';
import { DynamicFeedAppliedFilters } from './DynamicFeedAppliedFilters';

export const FeedAppliedFilters = (props: FeedAppliedFiltersProps) =>
  props.withDynamicMapping ? (
    <DynamicFeedAppliedFilters {...props} />
  ) : (
    <StaticFeedAppliedFilters {...props} />
  );
