import { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { CountryFlagIcon } from 'components/CountryFlagIcon';
import { Row, Td, Tr } from 'components/Table';
import { Theme } from 'styles/themes';
import { FlexContainer } from 'styles/utils';
import { Source } from 'models/reports/Source';
import { copyToClipboard } from 'utils/copy-to-clipboard';
import { ReactComponent as Copy } from 'assets/copy.svg';

interface TimeRowProps {
  data: Source;
  onClickRow: (row: Source) => void;
  isFaded?: boolean;
}

const StyledTr = styled(Tr)({
  height: '48px',
  cursor: 'pointer',
});

const StyledTd = styled(Td)({
  border: `1px solid ${Theme.colors.grayBorder}`,
  borderCollapse: 'collapse',
  padding: '16px',
});

const TdText = styled.span({
  fontSize: Theme.fontSize,
});

const BlueHighlight = styled.span({
  backgroundColor: '#E5F5FF',
  marginBottom: '5px', // Adds space between items
  padding: '2px 6px', // Adjust padding for a neat appearance
  borderRadius: '4px',
  fontSize: '14px',
  fontWeight: 400,
  color: '#01416A',
  display: 'inline-block', // Keeps it inline with new-line behavior
});

export const TurnaroundTimeRow = (props: TimeRowProps) => {
  const { data } = props;
  const [isHighlighted, setHighlighted] = useState(false);
  const [showCopy, setShowCopy] = useState(false);

  const environmentList =
    (data?.Environments && data.Environments.split(',')) || [];

  const handleHighlightChange = useCallback(
    (highlight: boolean) => () => setHighlighted(highlight),
    [setHighlighted]
  );

  const handleOnCopy = (): void => {
    copyToClipboard(String(data.RowId && data.RowId));
  };

  const handleOnFocus = useCallback(
    (show: boolean) => () => setShowCopy(show),
    [setShowCopy]
  );

  return (
    <StyledTr
      onMouseEnter={handleHighlightChange(true)}
      onMouseLeave={handleHighlightChange(false)}
      onClick={() => props.onClickRow(data)}
      isHighlighted={isHighlighted}
    >
      <StyledTd
        onMouseEnter={handleOnFocus(true)}
        onMouseLeave={handleOnFocus(false)}
      >
        <TdText>
          {data.RowId}
          {showCopy && (
            <Copy
              width={10}
              height={12}
              style={{ margin: 'auto 0 auto 6px' }}
              onClick={handleOnCopy}
            />
          )}
        </TdText>
      </StyledTd>
      <StyledTd style={{ textAlign: 'start' }}>
        <FlexContainer
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            flexFlow: 'row',
          }}
        >
          <FlexContainer>
            {data && !!data.Countries && (
              <CountryFlagIcon countryName={data.Countries} marginRight={12} />
            )}
            <TdText style={{ fontWeight: 'normal' }}>{data.Countries}</TdText>
          </FlexContainer>
        </FlexContainer>
      </StyledTd>
      <StyledTd>
        <TdText>{data.Authorities}</TdText>
      </StyledTd>
      <StyledTd>
        <TdText>{data.Subcategories}</TdText>
      </StyledTd>
      <StyledTd>
        <TdText>{data.DocumentTypes}</TdText>
      </StyledTd>
      <StyledTd>
        <TdText>
          {environmentList &&
            environmentList.length > 0 &&
            environmentList.map((env, index) => (
              <BlueHighlight key={index}>{env.trim()}</BlueHighlight>
            ))}
        </TdText>
      </StyledTd>
    </StyledTr>
  );
};
