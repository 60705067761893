import { useMemo, useState } from 'react';
import { Theme } from 'styles/themes';
import { Header } from 'components/Header';
import { BackButton } from 'components/BackButton';
import { BoldText } from 'styles/utils';
import { BlueChip } from 'components/Chip';
import { ReactComponent as Copy } from 'assets/copy.svg';
import { copyToClipboard } from 'utils/copy-to-clipboard';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { displayToast } from 'store/toast/toast-slice';
import { Alert } from 'components/Alert';
import { ReactComponent as ClipWhite } from 'assets/clip-white.svg';
import { OverflowXContainer } from 'components/OverflowXContainer';
import { selectAuthenticationResponse } from 'store/authentication/authentication-slice';
import { UserRoles } from 'enums';

interface DetailHeaderProps {
  developmentId?: number;
  developmentExternalId?: string;
  developmentTitle?: string;
  onClickBack: () => void;
}

export const DetailHeader = (props: DetailHeaderProps) => {
  const dispatch = useAppDispatch();
  const authenticationResponse = useAppSelector(selectAuthenticationResponse);
  const hasSuperadminRole = authenticationResponse?.roles?.includes(
    UserRoles.Superadmin
  );
  const [isTitleHovered, setIsTitleHovered] = useState(false);

  const overflowXElementWidth = document
    .querySelector('#DevelopmentDetailHeaderOverflowXContainer')
    ?.getBoundingClientRect().width;
  const overflowXElementScrollWidth = document.querySelector(
    '#DevelopmentDetailHeaderOverflowXContainer'
  )?.scrollWidth;

  const isOverflowX = useMemo(
    () =>
      Math.ceil(overflowXElementScrollWidth || 0) >
      Math.ceil(overflowXElementWidth || 0),
    [overflowXElementWidth, overflowXElementScrollWidth]
  );

  const handleOnCopy = (): void => {
    copyToClipboard(
      String(
        hasSuperadminRole ? props.developmentExternalId : props.developmentId
      )
    );
    dispatch(
      displayToast({
        content: (
          <Alert>
            <span>
              <ClipWhite style={{ marginRight: '7px' }} />
              Document ID copied to clipboard!
            </span>
          </Alert>
        ),
        rightDistance: 300,
      })
    );
  };

  return (
    <Header
      id="DevelopmentDetailHeader"
      hasBorderRight
      style={{
        width: '100%',
        flex: '0 0 40px',
        paddingTop: '16px',
      }}
    >
      <BackButton
        id="DevelopmentDetailHeaderBackButton"
        onClick={props.onClickBack}
      />
      <OverflowXContainer
        id="DevelopmentDetailHeaderOverflowXContainer"
        isScrollDisplayed={isTitleHovered && isOverflowX}
        onMouseOver={() => setIsTitleHovered(true)}
        onMouseLeave={() => setIsTitleHovered(false)}
        style={{ flex: '1 1 0%' }}
      >
        <BoldText
          id="DevelopmentDetailHeaderTitle"
          title={props.developmentTitle || ''}
        >
          {props.developmentTitle}
        </BoldText>
      </OverflowXContainer>
      <BlueChip
        id="DevelopmentDetailHeaderIdChip"
        onClick={handleOnCopy}
        style={{
          border: 'none',
          fontSize: Theme.fontSize - 2,
          cursor: 'pointer',
        }}
      >
        ID{' '}
        {hasSuperadminRole ? props.developmentExternalId : props.developmentId}
        <Copy width={10} height={12} style={{ margin: 'auto 0 auto 6px' }} />
      </BlueChip>
    </Header>
  );
};
