import { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { CountryFlagIcon } from 'components/CountryFlagIcon';
import { Row, Td, Tr } from 'components/Table';
import { DropdownArrow } from 'components/Dropdown';
import { PerformanceReportEntity } from 'models';
import { Theme } from 'styles/themes';
import { FlexContainer } from 'styles/utils';
import { formatNumber } from 'utils/number-formatter';

interface TurnaroundTimeRowProps {
  data: PerformanceReportEntity;
  onClickRow?: (row: Row<PerformanceReportEntity>) => void;
  onClickArrow: (id: number, isToExpand: boolean) => void;
  isFaded?: boolean;
  isCountry?: boolean;
}

const StyledTr = styled(Tr)({
  height: '48px',
  cursor: 'pointer',
});

const StyledTd = styled(Td)({
  border: `1px solid ${Theme.colors.grayBorder}`,
  borderCollapse: 'collapse',
  padding: '16px',
  textAlign: 'end',
});

const TdText = styled.span({
  fontSize: Theme.fontSize,
  fontWeight: 'bold',
});

const TdChevron = styled.div({
  width: '40px',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
});

export const TurnaroundTimeRow = (props: TurnaroundTimeRowProps) => {
  const { data } = props;
  const [isHighlighted, setHighlighted] = useState(false);
  const [isRowExpanded, setIsRowExpanded] = useState(false);

  const handleHighlightChange = useCallback(
    (highlight: boolean) => () => setHighlighted(highlight),
    [setHighlighted]
  );

  const handleClick = useCallback(() => {
    props.onClickRow && props.onClickRow({ ...data });
  }, [data]);

  const handleClickOnArrow = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (isRowExpanded) {
        props.onClickArrow(data.id!, false);
        setIsRowExpanded(false);
      } else {
        props.onClickArrow(data.id!, true);
        setIsRowExpanded(true);
      }
    },
    [isRowExpanded]
  );

  return (
    <StyledTr
      onMouseEnter={handleHighlightChange(true)}
      onMouseLeave={handleHighlightChange(false)}
      onClick={handleClick}
      isHighlighted={isHighlighted}
    >
      <StyledTd style={{ textAlign: 'start' }}>
        <FlexContainer
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            flexFlow: 'row',
          }}
        >
          <FlexContainer>
            {props.isCountry && !!data.name && (
              <CountryFlagIcon countryName={data.name} marginRight={12} />
            )}
            <TdText style={{ fontWeight: 'normal' }}>{data.name}</TdText>
          </FlexContainer>
          <TdChevron
            onClick={handleClickOnArrow}
            style={{ paddingTop: `${isRowExpanded ? 0 : 10}px` }}
          >
            <DropdownArrow
              isExpanded={isRowExpanded}
              color={Theme.colors.primaryColor}
            />
          </TdChevron>
        </FlexContainer>
      </StyledTd>
      <StyledTd>
        <TdText>
          {formatNumber(data.turnaroundTimeReportData!.totalWithin2hours)}
        </TdText>
      </StyledTd>
      <StyledTd>
        <TdText>
          {formatNumber(data.turnaroundTimeReportData!.totalWithin24hours)}
        </TdText>
      </StyledTd>
      <StyledTd>
        <TdText>
          {formatNumber(data.turnaroundTimeReportData!.totalWithin36hours)}
        </TdText>
      </StyledTd>
      <StyledTd>
        <TdText>
          {formatNumber(data.turnaroundTimeReportData!.totalWithin60hours)}
        </TdText>
      </StyledTd>
      <StyledTd>
        <TdText>
          {formatNumber(data.turnaroundTimeReportData!.totalWithin4days)}
        </TdText>
      </StyledTd>
      <StyledTd>
        <TdText>
          {formatNumber(data.turnaroundTimeReportData!.totalDevelopments)}
        </TdText>
      </StyledTd>
    </StyledTr>
  );
};
