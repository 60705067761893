import { useRef, useState, RefObject, useEffect } from 'react';
import styled from '@emotion/styled';
import { PDFDocumentProxy, PDFPageProxy, PageViewport } from 'pdfjs-dist';
import { EventBus, PDFLinkService } from 'pdfjs-dist/legacy/web/pdf_viewer';
import { TextItem } from 'pdfjs-dist/types/src/display/api';
import { OverflowYAutoContainer } from 'components/OverflowYAutoContainer';
import { Theme } from 'styles/themes';
import { ZoomButton } from './components/ZoomButton';
import { ResultsNavigationHeader } from './components/ResultsNavigationHeader';
import { Alert } from 'components/Alert';
import { FlexContainer } from 'styles/utils';
import { ReactComponent as Document } from 'assets/document.svg';
import { Loading } from 'components/Loading';

export interface PdfViewerProps {
  pdfUrl: string;
  searchTerm: string;
  highlights: PdfHighlight[];
  setIsPdfRendering: React.Dispatch<boolean>;
  skipScrollOnHighlightClick: boolean;
}

type PdfJsLib = typeof import('pdfjs-dist/legacy/build/pdf');

export interface PdfHighlight {
  page: number;
  rect: { x: number; y: number; w: number; h: number };
  color: string;
  isPermanent: boolean;
  isFromSearch: boolean;
  scrollToPage: boolean;
  boundingBox?: string[];
  onClick?: () => void;
}

interface PdfPreRenderedPage {
  page: PDFPageProxy;
  viewport: PageViewport;
}

const PdfUnavailableAlertContainer = styled.div({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

const AnnotationLayer = styled.div({
  width: '100%!important',
  height: 'calc(100% - 4px)!important',
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  overflow: 'hidden',
  opacity: 0,
  lineHeight: 1,
  '> section': {
    color: 'transparent',
    position: 'absolute',
    whiteSpace: 'pre',
    cursor: 'text',
    transformOrigin: '0% 0%',
  },
  '> .linkAnnotation > a': {
    position: 'absolute',
    fontSize: '1em',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
});

export const PdfViewer: React.FC<PdfViewerProps> = (props: PdfViewerProps) => {
  const pageContainerId = 'PdfViewerPageContainer';
  const pageIdPrefix = 'PdfViewerPage';
  const canvasIdPrefix = 'PdfViewerCanvas';
  const annotationLayerIdPrefix = 'PdfViewerAnnotationLayer';
  const loadingIdPrefix = 'PdfViewerLoading';
  const searchResultsHighlightColor = Theme.colors.orangeLight;
  const currentSearchResultHighlightColor = Theme.colors.redLight;

  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [scale, setScale] = useState(1.0);
  const [isPdfUnavailable, setIsPdfUnavailable] = useState(false);
  const [areAllCanvasContainersInDom, setAreAllCanvasContainersInDom] =
    useState<boolean>(false);
  const [pdfJsLib, setPdfJsLib] = useState<PdfJsLib | null>(null);
  const [pdf, setPdf] = useState<PDFDocumentProxy | null>(null);
  const [totalPages, setTotalPages] = useState(0);
  const [pagesLoading, setPagesLoading] = useState<number[]>([]);
  const [preRenderedPages, setPreRenderedPages] = useState<
    PdfPreRenderedPage[]
  >([]);
  const [allHighlights, setAllHighlights] = useState<PdfHighlight[]>([]);
  const [triggerScrollToHighlight, setTriggerScrollToHighlight] = useState(0);
  const [totalSearchResults, setTotalSearchResults] = useState<number>(0);
  const [currentSearchResult, setCurrentSearchResult] = useState<number | null>(
    null
  );
  const [skippingScrollOnHighlightClick, setSkippingScrollOnHighlightClick] =
    useState(false);

  useEffect(() => {
    (async () => {
      const pdfJs = await import('pdfjs-dist/legacy/build/pdf');
      pdfJs.GlobalWorkerOptions.workerSrc =
        window.location.origin + '/pdf.worker.min.js';
      setPdfJsLib(pdfJs);
      await getPdf(pdfJs);
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (totalPages && areAllCanvasContainersInDom) {
        for (const pageNumber of Array.from(Array(totalPages).keys())) {
          await setCanvasDimensionsAndPreRenderedPage(pageNumber + 1);
        }
        props.setIsPdfRendering(false);
      }
    })();
  }, [totalPages, areAllCanvasContainersInDom]);

  useEffect(() => {
    if (totalPages && areAllCanvasContainersInDom) {
      for (const pageNumber of Array.from(Array(totalPages).keys())) {
        rerenderPageWhenCanvasIsVisible(pageNumber + 1);
      }
    }
  }, [totalPages, areAllCanvasContainersInDom, JSON.stringify(allHighlights)]);

  useEffect(() => {
    (async () => {
      if (
        totalPages &&
        areAllCanvasContainersInDom &&
        preRenderedPages.length === totalPages
      ) {
        if (props.searchTerm) {
          const allPagesSearchHighlights: PdfHighlight[] = [];
          for (const pageNumber of Array.from(Array(totalPages).keys())) {
            const preRenderedPage = preRenderedPages.find(
              (x) => x.page.pageNumber === pageNumber + 1
            );
            if (preRenderedPage) {
              const pageSearchHighlights = await getPageSearchHighlights(
                preRenderedPage.page,
                preRenderedPage.page.pageNumber,
                props.searchTerm
              );
              allPagesSearchHighlights.push(...pageSearchHighlights);
            }
          }
          setAllHighlights((currentHighlights) => [
            ...currentHighlights.filter((x) => !x.isFromSearch),
            ...allPagesSearchHighlights,
          ]);
          setTotalSearchResults(allPagesSearchHighlights.length);
        } else {
          setAllHighlights((currentHighlights) => [
            ...currentHighlights.filter((x) => !x.isFromSearch),
          ]);
          setTotalSearchResults(0);
        }
        setCurrentSearchResult(0);
      }
    })();
  }, [
    totalPages,
    areAllCanvasContainersInDom,
    preRenderedPages.length,
    props.searchTerm,
  ]);

  useEffect(() => {
    const currentSearchHighlights = allHighlights
      .filter((x) => x.isFromSearch)
      .map((highlight) => {
        return {
          ...highlight,
          scrollToPage: false,
        };
      });
    setAllHighlights([...props.highlights, ...currentSearchHighlights]);
  }, [JSON.stringify(props.highlights)]);

  useEffect(() => {
    if (currentSearchResult !== null) {
      const currentHighlights = allHighlights
        .filter((x) => !x.isFromSearch)
        .map((highlight) => {
          return {
            ...highlight,
            scrollToPage: false,
          };
        });
      const newHighlightsFromSearch = allHighlights
        .filter((x) => x.isFromSearch)
        .map((highlight, y) => {
          if (y === currentSearchResult) {
            return {
              ...highlight,
              color: currentSearchResultHighlightColor,
              scrollToPage: true,
            };
          } else {
            return {
              ...highlight,
              color: searchResultsHighlightColor,
              scrollToPage: false,
            };
          }
        });
      setAllHighlights([...currentHighlights, ...newHighlightsFromSearch]);
    }
  }, [
    JSON.stringify(
      allHighlights
        .filter((x) => x.isFromSearch)
        .map((x) => {
          return {
            page: x.page,
            rect: x.rect,
          };
        })
    ),
    currentSearchResult,
  ]);

  useEffect(() => {
    const temporaryHighlights = allHighlights.filter((x) => !x.isPermanent);
    if (temporaryHighlights && temporaryHighlights.length) {
      const highlightToScrollTo = temporaryHighlights.find(
        (x) => x.scrollToPage
      );
      if (highlightToScrollTo) {
        rerenderPageWhenCanvasIsVisible(highlightToScrollTo.page);
        if (!skippingScrollOnHighlightClick) {
          scrollToPage(
            highlightToScrollTo.page - 1,
            highlightToScrollTo.rect.y,
            scrollableContainerRef
          );
        } else {
          setSkippingScrollOnHighlightClick(false);
        }
      }
    }
  }, [JSON.stringify(allHighlights.filter((x) => !x.isPermanent))]);

  useEffect(() => {
    if (areAllCanvasContainersInDom) {
      const pdfViewerPageContainer = document.getElementById(
        pageContainerId
      ) as HTMLDivElement | null;
      if (pdfViewerPageContainer) {
        pdfViewerPageContainer.style.width = `calc(100% + ${
          scale * 100 - 100
        }%)`;
      }
    }
  }, [scale, areAllCanvasContainersInDom]);

  const getPdf = async (pdfJs: PdfJsLib): Promise<void> => {
    try {
      const pdfTypedArray: Uint8Array | undefined = await new Promise(
        (pdfTypedArrayResolve) => {
          fetch(props.pdfUrl)
            .then((response) => response.blob())
            .then(async (blob) => {
              const typedArray = new Uint8Array(await blob.arrayBuffer());
              pdfTypedArrayResolve(typedArray);
            });
        }
      );
      if (!pdfTypedArray) {
        throw 'No PDF typed array could be fetched from PDF URL';
      }
      const pdfDocument = await pdfJs.getDocument({
        data: pdfTypedArray,
        cMapUrl: 'pdfjs-dist/cmaps/',
        cMapPacked: true,
      }).promise;
      setPdf(pdfDocument);
      setTotalPages(pdfDocument.numPages);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      setIsPdfUnavailable(true);
      setIsLoading(false);
    }
  };

  const setCanvasDimensionsAndPreRenderedPage = async (
    pageNumber: number
  ): Promise<PdfPreRenderedPage | undefined> => {
    const canvas = document.getElementById(
      `${canvasIdPrefix}${pageNumber - 1}`
    ) as HTMLCanvasElement | null;
    if (pdf && canvas) {
      const pdfPageProxy = await pdf.getPage(pageNumber);
      const viewport = pdfPageProxy.getViewport({ scale: 2 });
      canvas.setAttribute('height', String(viewport.height));
      canvas.setAttribute('width', String(viewport.width));
      const preRenderedPage: PdfPreRenderedPage = {
        page: pdfPageProxy,
        viewport,
      };
      setPreRenderedPages((currentPages) => [
        ...currentPages.filter((x) => x.page.pageNumber !== pageNumber),
        preRenderedPage,
      ]);
      return preRenderedPage;
    }
  };

  const rerenderPageWhenCanvasIsVisible = (pageNumber: number): void => {
    const canvasId = `${canvasIdPrefix}${pageNumber - 1}`;
    const canvas = document.getElementById(
      canvasId
    ) as HTMLCanvasElement | null;
    if (canvas) {
      const timeouts: {
        [x: string]: NodeJS.Timeout;
      } = {};
      const intersectionObserver = new IntersectionObserver((entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          timeouts[entry.target.id] = setTimeout(async () => {
            await rerenderPage(pageNumber, allHighlights);
            intersectionObserver.unobserve(canvas);
          }, 500);
        } else {
          clearTimeout(timeouts[entry.target.id]);
        }
      });
      intersectionObserver.observe(canvas);
    }
  };

  const renderPage = (
    pageNumber: number,
    preRenderedPage: PdfPreRenderedPage,
    highlights: PdfHighlight[]
  ): void => {
    const canvas = document.getElementById(
      `${canvasIdPrefix}${pageNumber - 1}`
    ) as HTMLCanvasElement | null;
    const annotationLayer = document.getElementById(
      `${annotationLayerIdPrefix}${pageNumber - 1}`
    ) as HTMLDivElement | null;
    if (pdfJsLib && canvas && annotationLayer) {
      const canvasContext = canvas.getContext('2d');
      if (canvasContext) {
        const renderTask = preRenderedPage.page.render({
          canvasContext,
          viewport: preRenderedPage.viewport,
        });
        if (renderTask) {
          renderTask.promise
            .then(async () => {
              return await preRenderedPage.page.getAnnotations();
            })
            .then(async (annotationData) => {
              const eventBus = new EventBus();

              const pdfLinkService = new PDFLinkService({
                eventBus,
                externalLinkTarget: 2,
              });
              pdfLinkService.setDocument(pdf);

              pdfJsLib.AnnotationLayer.render({
                viewport: preRenderedPage.viewport.clone({ scale: 1 }),
                div: annotationLayer,
                annotations: annotationData,
                page: preRenderedPage.page,
                linkService: pdfLinkService,
                downloadManager: undefined,
                renderForms: true,
              });

              if (highlights && highlights.length) {
                canvasContext.globalCompositeOperation = 'multiply';
                canvasContext.setTransform(
                  preRenderedPage.viewport.transform[0],
                  preRenderedPage.viewport.transform[1],
                  preRenderedPage.viewport.transform[2],
                  preRenderedPage.viewport.transform[3],
                  preRenderedPage.viewport.transform[4],
                  preRenderedPage.viewport.transform[5]
                );
                drawPageHighlights(pageNumber, canvasContext, highlights);
                setPageHighlightsActions(
                  pageNumber,
                  canvas,
                  canvasContext,
                  annotationLayer,
                  highlights
                );
              }

              setPagesLoading((currentPagesLoading) => [
                ...currentPagesLoading.filter((x) => x !== pageNumber),
              ]);
            });
        }
      }
    }
  };

  const drawPageHighlights = (
    pageNumber: number,
    canvasContext: CanvasRenderingContext2D,
    highlights: PdfHighlight[]
  ): void => {
    const currentPageHighlights = highlights.filter(
      (x) => x.page === pageNumber
    );
    if (currentPageHighlights && currentPageHighlights.length) {
      currentPageHighlights.forEach((highlight) => {
        if (highlight.color) {
          canvasContext.fillStyle = highlight.color;
        }
        canvasContext.fillRect(
          highlight.rect.x,
          highlight.rect.y,
          highlight.rect.w,
          highlight.rect.h
        );
      });
    }
  };

  const setPageHighlightsActions = (
    pageNumber: number,
    canvas: HTMLCanvasElement,
    canvasContext: CanvasRenderingContext2D,
    annotationLayer: HTMLDivElement,
    highlights: PdfHighlight[]
  ): void => {
    const currentPageHighlights = highlights.filter(
      (x) => x.page === pageNumber
    );
    if (currentPageHighlights && currentPageHighlights.length) {
      annotationLayer.onmousemove = (event) => {
        const canvasBoundingClientRect = canvas.getBoundingClientRect();
        const x =
          ((event.clientX - canvasBoundingClientRect.left) /
            (canvasBoundingClientRect.right - canvasBoundingClientRect.left)) *
          canvas.width;
        const y =
          ((event.clientY - canvasBoundingClientRect.top) /
            (canvasBoundingClientRect.bottom - canvasBoundingClientRect.top)) *
          canvas.height;
        const domPoint = canvasContext
          .getTransform()
          .inverse()
          .transformPoint(new DOMPoint(x, y));

        const isMouseOverAnyHighlight = currentPageHighlights.some(
          (highlight) => {
            if (highlight.boundingBox && highlight.boundingBox.length) {
              const [x0, y0, x1, y1] = highlight.boundingBox.map(Number);
              const isMouseOverHighlight =
                domPoint.x >= x0 &&
                domPoint.x <= x1 &&
                domPoint.y >= y0 &&
                domPoint.y <= y1;
              if (isMouseOverHighlight) {
                if (props.skipScrollOnHighlightClick) {
                  setSkippingScrollOnHighlightClick(true);
                }
                if (highlight.onClick) {
                  document.getElementsByTagName('body')[0].style.cursor =
                    'pointer';
                  annotationLayer.onclick = highlight.onClick;
                }
              } else {
                if (props.skipScrollOnHighlightClick) {
                  setSkippingScrollOnHighlightClick(false);
                }
              }
              return isMouseOverHighlight;
            }
          }
        );
        if (!isMouseOverAnyHighlight) {
          document.getElementsByTagName('body')[0].style.cursor = 'default';
          annotationLayer.onclick = () => {};
        }
      };
    }
  };

  const rerenderPage = async (
    pageNumber: number,
    highlights: PdfHighlight[]
  ): Promise<void> => {
    setPagesLoading((currentPagesLoading) => [
      ...currentPagesLoading.filter((x) => x !== pageNumber),
      pageNumber,
    ]);
    const pdfViewerPage = document.getElementById(
      `${pageIdPrefix}${pageNumber - 1}`
    ) as HTMLDivElement | null;
    let preRenderedPage = preRenderedPages.find(
      (x) => x.page.pageNumber === pageNumber
    );
    if (!preRenderedPage) {
      preRenderedPage = await setCanvasDimensionsAndPreRenderedPage(pageNumber);
    }
    if (pdfViewerPage && preRenderedPage) {
      const canvas = document.getElementById(
        `${canvasIdPrefix}${pageNumber - 1}`
      ) as HTMLCanvasElement | null;
      if (canvas) {
        pdfViewerPage.removeChild(canvas);
        const newCanvas = document.createElement('canvas');
        newCanvas.id = `${canvasIdPrefix}${pageNumber - 1}`;
        newCanvas.style.width = '100%';
        newCanvas.style.backgroundColor = 'white';
        newCanvas.setAttribute(
          'height',
          String(preRenderedPage.viewport.height)
        );
        newCanvas.setAttribute('width', String(preRenderedPage.viewport.width));
        pdfViewerPage.appendChild(newCanvas);
        renderPage(pageNumber, preRenderedPage, highlights);
      }
    }
  };

  const getPageSearchHighlights = async (
    page: PDFPageProxy,
    pageNumber: number,
    searchTerm: string
  ): Promise<PdfHighlight[]> => {
    return new Promise(async (resolve) => {
      const textContent = await page.getTextContent();
      const results = (textContent.items as TextItem[]).filter((item) => {
        return item.str.toLowerCase().includes(searchTerm.toLowerCase());
      });
      const searchHighlights = results.map((result) => {
        const width = result.width;
        const height = result.height;
        const x = result.transform[4];
        const y = result.transform[5];

        const searchTermPercent = (searchTerm.length * 100) / result.str.length;
        const stringRestPercent = 100 - searchTermPercent;
        const widthToRemove = (stringRestPercent * width) / 100;

        const charsWithTerm: string[] = [];
        for (let index = 0; index < result.str.length; index++) {
          const char = result.str[index];
          charsWithTerm.push(char);
          if (
            charsWithTerm
              .join('')
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          ) {
            break;
          }
        }
        const charsBeforeTerm = charsWithTerm.slice(
          0,
          charsWithTerm.length - searchTerm.length
        );
        const charsBeforeTermPercent =
          (charsBeforeTerm.length * 100) / result.str.length;
        const xToAdd = (charsBeforeTermPercent * width) / 100;

        return {
          page: pageNumber,
          rect: {
            x: x + xToAdd,
            y: y - 1,
            w: width - widthToRemove,
            h: height,
          },
          color: searchResultsHighlightColor,
          isPermanent: false,
          isFromSearch: true,
          scrollToPage: false,
        };
      });
      resolve(searchHighlights);
    });
  };

  const goToNextSearchResult = (): void => {
    if (
      currentSearchResult !== null &&
      currentSearchResult < totalSearchResults - 1
    ) {
      setCurrentSearchResult(currentSearchResult + 1);
    }
  };

  const goToPreviousSearchResult = (): void => {
    if (currentSearchResult !== null && currentSearchResult > 0) {
      setCurrentSearchResult(currentSearchResult - 1);
    }
  };

  const scrollToPage = (
    pageNumber: number,
    y: number,
    scrollableContainerRef: RefObject<HTMLDivElement>
  ): void => {
    const pageToScrollTo = document.getElementById(
      `${pageIdPrefix}${pageNumber}`
    ) as HTMLDivElement | null;
    if (pageToScrollTo) {
      const top =
        pageToScrollTo.offsetTop + pageToScrollTo.scrollHeight / 1.5 - y;
      scrollableContainerRef.current?.scrollTo({
        top,
      });
    }
  };

  return (
    <OverflowYAutoContainer
      id="PdfViewer"
      ref={scrollableContainerRef}
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        padding: '15px',
      }}
    >
      {isLoading && <Loading id={loadingIdPrefix} opacity={1} />}
      {!isPdfUnavailable && (
        <ZoomButton scale={scale} setScale={setScale} right={-54} />
      )}
      {isPdfUnavailable && (
        <PdfUnavailableAlertContainer id="PdfUnavailableAlertContainer">
          <Alert id="PdfUnavailableAlert">
            <FlexContainer
              id="PdfUnavailableAlertInnerContainer"
              style={{ alignItems: 'center' }}
            >
              <Document
                id="PdfUnavailableAlertDocumentIcon"
                style={{ marginRight: '5px' }}
              />
              <span
                id="PdfUnavailableAlertMessage"
                style={{
                  fontSize: Theme.fontSize + 2,
                  lineHeight: '25px',
                }}
              >
                No PDF available
              </span>
            </FlexContainer>
          </Alert>
        </PdfUnavailableAlertContainer>
      )}
      {!isPdfUnavailable && (
        <>
          {!!props.searchTerm && (
            <ResultsNavigationHeader
              currentResult={
                totalSearchResults > 0 && currentSearchResult !== null
                  ? currentSearchResult + 1
                  : currentSearchResult || 0
              }
              totalResults={totalSearchResults}
              goToNextResult={goToNextSearchResult}
              goToPreviousResult={goToPreviousSearchResult}
            />
          )}
          <div
            id={pageContainerId}
            style={{
              width: '100%',
              margin: '0 auto 15px auto',
            }}
          >
            {Array.from(Array(totalPages).keys()).map((pageNumber) => {
              const pageId = `${pageIdPrefix}${pageNumber}`;
              const canvasId = `${canvasIdPrefix}${pageNumber}`;
              const annotationLayerId = `${annotationLayerIdPrefix}${pageNumber}`;
              const loadingId = `${loadingIdPrefix}${pageNumber}`;

              return (
                <div
                  id={pageId}
                  key={pageNumber}
                  style={{
                    width: '100%',
                    position: 'relative',
                    marginTop: '6px',
                  }}
                  ref={() =>
                    pageNumber === totalPages - 1
                      ? setAreAllCanvasContainersInDom(true)
                      : null
                  }
                >
                  <canvas
                    id={canvasId}
                    style={{
                      width: '100%',
                      backgroundColor: 'white',
                    }}
                  />
                  <AnnotationLayer id={annotationLayerId} />
                  {pagesLoading.some((x) => x === pageNumber + 1) && (
                    <Loading id={loadingId} opacity={1} />
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}
    </OverflowYAutoContainer>
  );
};
