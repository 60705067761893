import {
  httpDelete,
  httpGet,
  httpPatch,
  httpPost,
  httpPut,
} from 'utils/http/base-http-service';
import { HttpResponse } from 'utils/http/HttpResponse';
import { toQueryParams } from 'utils/to-query-params';
import {
  PaginatedResponse,
  Development,
  DevelopmentUserSave,
  DevelopmentUserView,
  ActiveFilters,
  FilterCounters,
  Environment,
  ReprocessDevelopmentPayload,
} from 'models';
import { ReprocessOperation, SortByDirection } from 'enums';
import { applyTimezoneOffset, dateRangeToString } from 'utils/date';
import {
  searchTermToElasticsearchQueryClause,
  numericMatchToElasticsearchQueryClause,
} from 'utils/elasticsearch';
import { developmentKeys } from 'config/development-keys';
import { getDevelopmentsBaseQuery } from 'helpers/filters';
import { downloadFile } from 'utils/download-file';
import { Theme } from 'styles/themes';

const developmentSearchTermToElasticsearchShouldQueryArray = (
  searchTerm: string,
  searchProperties: (keyof Development | string)[]
) => {
  const queries: any = [];
  if (searchProperties.includes(developmentKeys.externalId)) {
    queries.push(
      searchTermToElasticsearchQueryClause(
        [developmentKeys.externalId],
        searchTerm,
        true
      )
    );
  }
  if (
    searchProperties.includes(developmentKeys.id) &&
    !isNaN(Number(searchTerm))
  ) {
    queries.push(
      numericMatchToElasticsearchQueryClause(developmentKeys.id, searchTerm)
    );
  }
  const otherSearchProperties = searchProperties.filter(
    (searchProperty) =>
      searchProperty !== developmentKeys.id &&
      searchProperty !== developmentKeys.externalId
  );
  if (otherSearchProperties.length) {
    queries.push(
      searchTermToElasticsearchQueryClause(otherSearchProperties, searchTerm)
    );
  }
  return queries;
};

export const getDevelopmentsQuery = (
  selectedEnvironment: Environment | null,
  activeFilters: ActiveFilters,
  searchTerm?: string,
  searchProperties?: (keyof Development | string)[],
  fromDateInMilliseconds?: number,
  toDateInMilliseconds?: number,
  propertiesToExcludeFromFilterCounterQuery?: string[]
) => {
  let query: any = {
    filter: [],
  };
  query = getDevelopmentsBaseQuery(
    activeFilters,
    query,
    selectedEnvironment,
    propertiesToExcludeFromFilterCounterQuery
  );
  if (searchTerm && searchProperties && searchProperties.length) {
    const searchShouldClauses =
      developmentSearchTermToElasticsearchShouldQueryArray(
        searchTerm,
        searchProperties
      );
    query.filter.push({
      bool: {
        should: searchShouldClauses,
        minimum_should_match: 1,
      },
    });
  }
  if (fromDateInMilliseconds && toDateInMilliseconds) {
    query.filter.push({
      bool: {
        filter: [
          {
            range: {
              [developmentKeys.createdAt]: {
                gte: applyTimezoneOffset(String(fromDateInMilliseconds)),
                lte: applyTimezoneOffset(String(toDateInMilliseconds)),
              },
            },
          },
        ],
      },
    });
  }
  return query;
};

const getDevelopmentsCompositeAggregations = (
  afterKey?: string,
  perPage?: number,
  sortByProperty?: string,
  sortByDirection?: SortByDirection
) => {
  const aggregations: any = {
    by_id: {
      composite: {
        size: perPage,
        sources: [
          {
            identifier: {
              terms: {
                field: developmentKeys.id,
              },
            },
          },
        ],
        after: afterKey ? JSON.parse(afterKey) : undefined,
      },
      aggs: {
        top_development_hits: {
          top_hits: {
            size: 1,
          },
        },
      },
    },
  };
  if (sortByProperty && sortByDirection) {
    aggregations.by_id.composite.sources.unshift({
      sort_by: {
        terms: {
          field: sortByProperty,
          order: sortByDirection,
        },
      },
    });
  }
  return aggregations;
};

export const getAllDevelopments = async (
  selectedEnvironment: Environment | null,
  searchTerm: string,
  sortByProperty: string,
  sortByDirection: SortByDirection,
  page: number,
  perPage: number,
  activeFilters: ActiveFilters,
  fromDateInMilliseconds?: number,
  toDateInMilliseconds?: number,
  searchProperties?: (keyof Development | string)[]
): Promise<HttpResponse<PaginatedResponse<Development> | null>> => {
  const query = getDevelopmentsQuery(
    selectedEnvironment,
    activeFilters,
    searchTerm,
    searchProperties,
    fromDateInMilliseconds,
    toDateInMilliseconds
  );
  const queryParameters = toQueryParams({
    query: JSON.stringify(query),
    sortByProperty,
    sortByDirection,
    page,
    perPage,
    highlight: searchProperties?.includes(developmentKeys.pdfContent)
      ? JSON.stringify({
          pre_tags: [
            `<span style="background-color: ${Theme.colors.orangeLight};">`,
          ],
          post_tags: [`</span>`],
          number_of_fragments: 2147483646,
          fragment_size: 100,
          fields: {
            [developmentKeys.pdfContent]: {},
          },
        })
      : undefined,
  });
  return await httpGet<PaginatedResponse<Development>>(
    `developments?${queryParameters}`
  );
};

export const getDevelopmentById = async (
  id: string
): Promise<HttpResponse<Development | null>> => {
  return await httpGet<Development>(`developments/${id}`);
};

export const viewDevelopment = async (
  developmentId: number,
  userId: number
): Promise<HttpResponse<Development | null>> => {
  return await httpPost<Development, DevelopmentUserView>('developments/view', {
    developmentId,
    userId,
  });
};

export const saveDevelopment = async (
  developmentId: number,
  userId: number
): Promise<HttpResponse<Development | null>> => {
  return await httpPost<Development, DevelopmentUserSave>('developments/save', {
    developmentId,
    userId,
  });
};

export const reprocessDevelopment = async (
  developmentId: number,
  userId: number,
  operation: ReprocessOperation,
  reason: string
): Promise<HttpResponse<null>> => {
  return await httpPost<null, ReprocessDevelopmentPayload>(
    'developments/reprocess',
    {
      developmentId,
      userId,
      operation,
      reason,
    }
  );
};

export const getFilterCounters = async (
  selectedEnvironment: Environment | null,
  activeFilters: ActiveFilters,
  searchTerm: string,
  searchProperties: (keyof Development | string)[]
): Promise<HttpResponse<FilterCounters | null>> => {
  const query = getDevelopmentsQuery(
    selectedEnvironment,
    activeFilters,
    searchTerm,
    searchProperties,
    undefined,
    undefined
  );
  const queryParameters = toQueryParams({
    query: JSON.stringify(query),
  });
  return await httpGet<FilterCounters>(
    `developments/get-filter-counters?${queryParameters}`
  );
};

export const getRecentlyViewed = async (
  page: number,
  perPage: number,
  userId: number
): Promise<HttpResponse<PaginatedResponse<Development> | null>> => {
  const dateRange = {
    startDate: new Date(new Date().setHours(new Date().getHours() - 6)),
    endDate: new Date(),
  };
  const [start, end] = dateRangeToString(dateRange).split('-');
  const query = {
    filter: [
      {
        range: {
          [`${developmentKeys.viewedByUsers}.dateViewed`]: {
            gte: start,
            lte: end,
          },
        },
      },
      {
        bool: {
          should: [
            {
              match: {
                [`${developmentKeys.viewedByUsers}.userId`]: {
                  query: String(userId),
                  minimum_should_match: '100%',
                },
              },
            },
          ],
          minimum_should_match: 1,
        },
      },
    ],
  };
  const sortByDirection = SortByDirection.DESC;
  const sortByProperty = `${developmentKeys.viewedByUsers}.dateViewed`;
  const queryParameters = toQueryParams({
    query: JSON.stringify(query),
    sortByDirection,
    sortByProperty,
    page,
    perPage,
  });
  return await httpGet<PaginatedResponse<Development>>(
    `developments/recently-viewed?${queryParameters}`
  );
};

export const matchObligations = async (
  id: number
): Promise<HttpResponse<any>> => {
  return await httpPost<any, { id: number }>(
    `developments/${id}/match-obligations`,
    { id }
  );
};

export const getPdf = (developmentId: number, externalId: string): void => {
  const pdfUrl = `${process.env.REACT_APP_API_URL}/developments/${developmentId}/pdf`;
  const fileName = `${externalId}.pdf`;

  fetch(pdfUrl)
    .then((response) => response.blob())
    .then((blob) => {
      downloadFile(blob, fileName);
    });
};

export const deleteDevelopment = async (
  id: number
): Promise<HttpResponse<string | null>> => {
  return await httpDelete<string>(`developments/${id}`);
};

export const restoreDevelopment = async (
  id: number
): Promise<HttpResponse<string | null>> => {
  return await httpPost<string, null>(`developments/${id}/restore`, null);
};
