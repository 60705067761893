import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { getFilterCounters } from 'services/developments/developments-service';
import { ActiveFilters, FilterCounters, FilterEntryValue } from 'models';
import { addOrRemoveIfExists } from 'utils/array';

export interface HomeSidebarFilterState {
  activeFilters: ActiveFilters;
  feedActiveFilters: ActiveFilters;
  filterCounters: FilterCounters | null;
  isStatusFilterCollapsed: boolean;
  isAuthoritiesFilterCollapsed: boolean;
  isSubcategoriesFilterCollapsed: boolean;
  isLanguagesFilterCollapsed: boolean;
  isRegionsFilterCollapsed: boolean;
  isCountriesFilterCollapsed: boolean;
  isDocumentTypesFilterCollapsed: boolean;
  isDateFilterCollapsed: boolean;
  isPageCountFilterCollapsed: boolean;
  isTurnaroundTimeFilterCollapsed: boolean;
  isNonConformityFilterCollapsed: boolean;
  triggerGetAllFilterSetEntries: number;
  skipSetAllActiveFiltersOnce: boolean;
}

const initialState: HomeSidebarFilterState = {
  activeFilters: {},
  feedActiveFilters: {},
  filterCounters: null,
  isStatusFilterCollapsed: true,
  isAuthoritiesFilterCollapsed: true,
  isSubcategoriesFilterCollapsed: true,
  isLanguagesFilterCollapsed: true,
  isRegionsFilterCollapsed: true,
  isCountriesFilterCollapsed: true,
  isDocumentTypesFilterCollapsed: true,
  isDateFilterCollapsed: true,
  isPageCountFilterCollapsed: true,
  isTurnaroundTimeFilterCollapsed: true,
  isNonConformityFilterCollapsed: true,
  triggerGetAllFilterSetEntries: 0,
  skipSetAllActiveFiltersOnce: false,
};

export const getFilterCountersAsync = (): AppThunk<
  Promise<FilterCounters | null>
> => {
  return async (dispatch, getState) => {
    const selectedEnvironment = getState().environment.selectedEnvironment;
    const activeFilters = getState().homeSidebarFilter.activeFilters;
    const searchTerm = getState().development.searchTerm;
    const searchProperties = getState().development.searchProperties;
    const response = await getFilterCounters(
      selectedEnvironment,
      activeFilters,
      searchTerm,
      searchProperties
    );
    dispatch(setFilterCounters(response.data));
    return response.data;
  };
};

export const homeSidebarFilterSlice = createSlice({
  name: 'home-sidebar-filter',
  initialState,
  reducers: {
    setFilterCounters: (
      state,
      action: PayloadAction<FilterCounters | null>
    ) => {
      state.filterCounters = action.payload;
    },
    updateActiveFilters: (
      state,
      action: PayloadAction<{
        propertyToFilterBy: string;
        value: FilterEntryValue;
        replace?: boolean;
      }>
    ) => {
      const { propertyToFilterBy, value, replace } = action.payload;
      if (
        !state.feedActiveFilters[propertyToFilterBy] ||
        (state.feedActiveFilters[propertyToFilterBy] &&
          !state.feedActiveFilters[propertyToFilterBy].includes(
            JSON.stringify(value)
          ))
      ) {
        (state.activeFilters[propertyToFilterBy] as any) = replace
          ? value
          : addOrRemoveIfExists(
              state.activeFilters[propertyToFilterBy] as any[],
              value
            );
      } else {
        (state.activeFilters[propertyToFilterBy] as any) = replace
          ? value
          : [value];
      }
      state.feedActiveFilters[propertyToFilterBy] = [];
    },
    clearActiveFilters: (state) => {
      for (const key in state.activeFilters) {
        if (Object.prototype.hasOwnProperty.call(state.activeFilters, key)) {
          if (Array.isArray(state.activeFilters[key])) {
            state.activeFilters[key] = [];
          } else if (typeof state.activeFilters[key] === 'string') {
            state.activeFilters[key] = '';
          }
        }
      }
    },
    setActiveFilters: (state, action: PayloadAction<ActiveFilters>) => {
      state.activeFilters = action.payload;
    },
    setAllActiveFilters: (
      state,
      action: PayloadAction<
        {
          propertyToFilterBy: string;
          values: any[];
        }[]
      >
    ) => {
      if (state.skipSetAllActiveFiltersOnce) {
        state.skipSetAllActiveFiltersOnce = false;
        return;
      }
      action.payload.forEach((activeFilter) => {
        state.activeFilters[activeFilter.propertyToFilterBy] =
          activeFilter.values;
      });
    },
    skipSetAllActiveFiltersOnce: (state) => {
      state.skipSetAllActiveFiltersOnce = true;
    },
    setFeedActiveFilters: (
      state,
      action: PayloadAction<{
        propertyToFilterBy: string;
        values: any[];
      }>
    ) => {
      const { propertyToFilterBy, values } = action.payload;
      state.feedActiveFilters[propertyToFilterBy] = values;
    },
    setAllFeedActiveFilters: (
      state,
      action: PayloadAction<
        {
          propertyToFilterBy: string;
          values: any[];
        }[]
      >
    ) => {
      action.payload.forEach((feedActiveFilter) => {
        state.feedActiveFilters[feedActiveFilter.propertyToFilterBy] =
          feedActiveFilter.values;
      });
    },
    setIsStatusFilterCollapsed: (state, action: PayloadAction<boolean>) => {
      state.isStatusFilterCollapsed = action.payload;
    },
    setIsAuthoritiesFilterCollapsed: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isAuthoritiesFilterCollapsed = action.payload;
    },
    setIsSubcategoriesFilterCollapsed: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isSubcategoriesFilterCollapsed = action.payload;
    },
    setIsLanguagesFilterCollapsed: (state, action: PayloadAction<boolean>) => {
      state.isLanguagesFilterCollapsed = action.payload;
    },
    setIsRegionsFilterCollapsed: (state, action: PayloadAction<boolean>) => {
      state.isRegionsFilterCollapsed = action.payload;
    },
    setIsCountriesFilterCollapsed: (state, action: PayloadAction<boolean>) => {
      state.isCountriesFilterCollapsed = action.payload;
    },
    setIsDocumentTypesFilterCollapsed: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isDocumentTypesFilterCollapsed = action.payload;
    },
    setIsDateFilterCollapsed: (state, action: PayloadAction<boolean>) => {
      state.isDateFilterCollapsed = action.payload;
    },
    setIsPageCountFilterCollapsed: (state, action: PayloadAction<boolean>) => {
      state.isPageCountFilterCollapsed = action.payload;
    },
    setIsTurnaroundTimeFilterCollapsed: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isTurnaroundTimeFilterCollapsed = action.payload;
    },
    setIsNonConformityFilterCollapsed: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isNonConformityFilterCollapsed = action.payload;
    },
    setTriggerGetAllFilterSetEntries: (state) => {
      state.triggerGetAllFilterSetEntries =
        state.triggerGetAllFilterSetEntries + 1;
    },
  },
});

export const {
  setFilterCounters,
  updateActiveFilters,
  clearActiveFilters,
  setActiveFilters,
  setAllActiveFilters,
  skipSetAllActiveFiltersOnce,
  setFeedActiveFilters,
  setAllFeedActiveFilters,
  setIsStatusFilterCollapsed,
  setIsAuthoritiesFilterCollapsed,
  setIsSubcategoriesFilterCollapsed,
  setIsLanguagesFilterCollapsed,
  setIsRegionsFilterCollapsed,
  setIsCountriesFilterCollapsed,
  setIsDocumentTypesFilterCollapsed,
  setIsDateFilterCollapsed,
  setIsPageCountFilterCollapsed,
  setIsTurnaroundTimeFilterCollapsed,
  setIsNonConformityFilterCollapsed,
  setTriggerGetAllFilterSetEntries,
} = homeSidebarFilterSlice.actions;

export const selectFilterCounters = (state: RootState) =>
  state.homeSidebarFilter.filterCounters;
export const selectActiveFilters = (state: RootState) =>
  state.homeSidebarFilter.activeFilters;
export const selectSkipSetAllActiveFiltersOnce = (state: RootState) =>
  state.homeSidebarFilter.skipSetAllActiveFiltersOnce;
export const selectFeedActiveFilters = (state: RootState) =>
  state.homeSidebarFilter.feedActiveFilters;
export const selectIsStatusFilterCollapsed = (state: RootState) =>
  state.homeSidebarFilter.isStatusFilterCollapsed;
export const selectIsAuthoritiesFilterCollapsed = (state: RootState) =>
  state.homeSidebarFilter.isAuthoritiesFilterCollapsed;
export const selectIsSubcategoriesFilterCollapsed = (state: RootState) =>
  state.homeSidebarFilter.isSubcategoriesFilterCollapsed;
export const selectIsLanguagesFilterCollapsed = (state: RootState) =>
  state.homeSidebarFilter.isLanguagesFilterCollapsed;
export const selectIsRegionsFilterCollapsed = (state: RootState) =>
  state.homeSidebarFilter.isRegionsFilterCollapsed;
export const selectIsCountriesFilterCollapsed = (state: RootState) =>
  state.homeSidebarFilter.isCountriesFilterCollapsed;
export const selectIsDocumentTypesFilterCollapsed = (state: RootState) =>
  state.homeSidebarFilter.isDocumentTypesFilterCollapsed;
export const selectIsDateFilterCollapsed = (state: RootState) =>
  state.homeSidebarFilter.isDateFilterCollapsed;
export const selectIsPageCountFilterCollapsed = (state: RootState) =>
  state.homeSidebarFilter.isPageCountFilterCollapsed;
export const selectIsTurnaroundTimeFilterCollapsed = (state: RootState) =>
  state.homeSidebarFilter.isTurnaroundTimeFilterCollapsed;
export const selectIsNonConformityFilterCollapsed = (state: RootState) =>
  state.homeSidebarFilter.isNonConformityFilterCollapsed;
export const selectTriggerGetAllFilterSetEntries = (state: RootState) =>
  state.homeSidebarFilter.triggerGetAllFilterSetEntries;

export default homeSidebarFilterSlice.reducer;
