import { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { Theme } from 'styles/themes';
import { Modal } from 'components/Modal/Modal';
//import theme from 'lib/styles/theme';
import { FlexContainer } from 'styles/utils';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getCurrentUserFeedsAsync,
  selectFeedPickerFeeds,
} from 'store/feed/feed-picker-slice';
import { Feed } from 'models';
import { ReactComponent as Info } from 'assets/info.svg';
import {
  ChatContainer,
  ChatContainerHeader,
  ChatElementsContainer,
  CitationContainer,
  CitationElementsContainer,
  CitationsContainerHeader,
  ClearButton,
  ClearButtonContainer,
  InputContainer,
  PageContainer,
  SendButton,
} from './Copilot.styles';
import { Input } from 'components/Input';
import { useFocus } from 'hooks/use-focus';
import { getCopilotChat } from 'services/copilot/copilot-service';
import { CopilotChatbox } from 'components/CopilotChatbox';
import { ChatType } from 'components/CopilotChatbox/CopilotChatbox';
import { v4 as uuid } from 'uuid';
import {
  CopilotCitations,
  Document,
} from 'components/CopilotCitations/CopilotCitations';

export const Copilot = () => {
  const [inputRef, setInputFocus] = useFocus();
  const [searchTerm, setSearchTerm] = useState('');
  const [chatHistory, setChatHistory] = useState<ChatType[]>([]);
  const [conversationId, setConversationId] = useState<string>(uuid());
  const [citations, setCitations] = useState<Document[]>([]);
  const [disableClear, setDisableClear] = useState<boolean>(false);

  const handleClickSend = useCallback(async () => {
    setChatHistory([...chatHistory, { role: 'USER', message: searchTerm }]);
    const response = await getCopilotChat({
      question: searchTerm,
      conversationId: conversationId,
    });
    setChatHistory(response.data.chatHistory);
    if (response.data.documents) {
      setCitations(response.data.documents);
    }
    setDisableClear(false);
  }, [searchTerm]);

  const handleClearChat = useCallback(() => {
    if (!disableClear) {
      setChatHistory([]);
      setCitations([]);
      setConversationId(uuid());
    }
  }, [disableClear, setChatHistory, setConversationId]);

  return (
    <FlexContainer
      id="HomeContainer"
      flexWrap="nowrap"
      style={{
        height: '100%',
        flex: 1,
        overflowX: 'auto',
      }}
    >
      <PageContainer>
        <ChatContainer>
          <ChatContainerHeader>Copilot Chat</ChatContainerHeader>
          <ChatElementsContainer>
            <CopilotChatbox chatHistory={chatHistory} />
          </ChatElementsContainer>
          <ClearButtonContainer>
            {chatHistory.length ? (
              <ClearButton disabled={disableClear} onClick={handleClearChat}>
                Clear Chat
              </ClearButton>
            ) : null}
          </ClearButtonContainer>
          <InputContainer>
            <Input
              id={'testid'}
              inputRef={inputRef}
              style={{
                backgroundColor: Theme.colors.white,
                border: `2px solid ${Theme.colors.primaryColor}`,
                padding: '18px',
              }}
              placeholder={'Message Copilot'}
              setValue={(value) => {
                setSearchTerm(value);
              }}
              value={searchTerm}
            />
            <SendButton
              onClick={() => {
                setDisableClear(true);
                setSearchTerm('');
                handleClickSend();
              }}
            >
              Send
            </SendButton>
          </InputContainer>
        </ChatContainer>
        <CitationContainer>
          <CitationsContainerHeader>References</CitationsContainerHeader>
          <CitationElementsContainer>
            <CopilotCitations documents={citations} />
          </CitationElementsContainer>
        </CitationContainer>
      </PageContainer>
    </FlexContainer>
  );
};
