import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { getRecentlyViewed } from 'services/developments/developments-service';
import { Development, PaginatedResponse } from 'models';
import { paginationConfig } from 'config/pagination-config';

export interface RecentlyViewedDevelopmentsState {
  developments: Development[];
  totalRecords: number;
  page: number;
  perPage: number;
}

const initialState: RecentlyViewedDevelopmentsState = {
  developments: [],
  totalRecords: 0,
  page: 1,
  perPage: paginationConfig.developmentsPerPage,
};

export const getAllRecentlyViewedDevelopmentsAsync = (): AppThunk<
  Promise<PaginatedResponse<Development> | null>
> => {
  return async (dispatch, getState) => {
    const authenticationResponse =
      getState().authentication.authenticationResponse;
    const userId = authenticationResponse?.id;
    const page = getState().recentlyViewedDevelopment.page;
    const perPage = getState().recentlyViewedDevelopment.perPage;
    if (userId) {
      const response = await getRecentlyViewed(page, perPage, userId);
      if (page > 1) {
        dispatch(addRecentlyViewedDevelopments(response.data));
      } else {
        dispatch(setRecentlyViewedDevelopments(response.data));
      }
      return response.data;
    }
    return null;
  };
};

export const recentlyViewedDevelopmentsSlice = createSlice({
  name: 'recently-viewed-developments',
  initialState,
  reducers: {
    setRecentlyViewedDevelopments: (
      state,
      action: PayloadAction<PaginatedResponse<Development> | null>
    ) => {
      state.developments = action.payload?.records ?? [];
      state.totalRecords = action.payload?.totalRecords ?? 0;
    },
    addRecentlyViewedDevelopments: (
      state,
      action: PayloadAction<PaginatedResponse<Development> | null>
    ) => {
      state.developments = state.developments.concat(
        action.payload?.records ?? []
      );
    },
    increaseRecentlyViewedDevelopmentsPage: (state) => {
      state.page = state.page + 1;
    },
    resetRecentlyViewedDevelopmentsPage: (state) => {
      state.page = 1;
    },
  },
});

export const {
  setRecentlyViewedDevelopments,
  addRecentlyViewedDevelopments,
  increaseRecentlyViewedDevelopmentsPage,
  resetRecentlyViewedDevelopmentsPage,
} = recentlyViewedDevelopmentsSlice.actions;

export const selectRecentlyViewedDevelopments = (state: RootState) =>
  state.recentlyViewedDevelopment.developments;
export const selectRecentlyViewedDevelopmentsTotalRecords = (
  state: RootState
) => state.recentlyViewedDevelopment.totalRecords;
export const selectRecentlyViewedDevelopmentsPage = (state: RootState) =>
  state.recentlyViewedDevelopment.page;
export const selectRecentlyViewedDevelopmentsPerPage = (state: RootState) =>
  state.recentlyViewedDevelopment.perPage;

export default recentlyViewedDevelopmentsSlice.reducer;
