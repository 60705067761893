import {
  Dropdown,
  DropdownOption,
  DropdownOptionButton,
  DropdownOptionLabel,
  DropdownOptionsContainer,
} from 'components/Dropdown';
import { ReactComponent as ArrowDown } from 'assets/arrow-down.svg';
import { useRef, useState } from 'react';
import { Theme } from 'styles/themes';
import { BoldText, CursorPointer, FlexContainer } from 'styles/utils';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { DevelopmentReviewStatuses } from 'enums';
import { routes } from 'navigation/routes';
import { useAppSelector } from 'store/hooks';
import { selectPeriodProperty } from 'store/performance-report/performance-report-slice';

const statuses = [
  {
    text: 'All completed',
    path: `reviewStatus=${DevelopmentReviewStatuses.ActionRequired}&reviewStatus=${DevelopmentReviewStatuses.NoActionRequired}`,
  },
  {
    text: 'Action Required',
    path: `reviewStatus=${DevelopmentReviewStatuses.ActionRequired}`,
  },
  {
    text: 'No Action',
    path: `reviewStatus=${DevelopmentReviewStatuses.NoActionRequired}`,
  },
];

export const StatusDropdown = () => {
  const search = useLocation().search;
  const navigate = useNavigate();
  const { entityType, id } = useParams<{ entityType: string; id: string }>();
  const period = useAppSelector(selectPeriodProperty);
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const statusDropdownContainerRef = useRef(null);
  const status = statuses.find((s) => search.includes(`?${s.path}`));

  return (
    <FlexContainer
      ref={statusDropdownContainerRef}
      style={{ alignItems: 'center' }}
    >
      <CursorPointer
        style={{ display: 'flex', margin: '0 8px 0 6px' }}
        onClick={() => setIsStatusDropdownOpen(!isStatusDropdownOpen)}
      >
        <BoldText>
          <span
            style={{ marginRight: '5px', color: Theme.colors.primaryColor }}
          >
            Show
          </span>
          <span
            style={{
              color: Theme.colors.secondaryColor,
            }}
          >
            {status?.text}
          </span>
        </BoldText>
      </CursorPointer>
      <div style={{ position: 'relative' }}>
        <CursorPointer
          onClick={() => setIsStatusDropdownOpen(!isStatusDropdownOpen)}
        >
          <div style={{ display: 'flex' }}>
            <ArrowDown style={{ color: Theme.colors.grayDarker }} />
          </div>
        </CursorPointer>
        <Dropdown
          isOpen={isStatusDropdownOpen}
          setIsOpen={setIsStatusDropdownOpen}
          containerRef={statusDropdownContainerRef}
          transform="translate(-95%, 30%)"
          style={{
            border: `1px solid ${Theme.colors.grayBorder}`,
          }}
        >
          <div style={{ width: '180px', color: Theme.colors.black }}>
            <FlexContainer flexDirection="column">
              <DropdownOptionsContainer style={{ width: '100%' }}>
                {statuses.map((status, index) => {
                  const isFirst = index === 0;
                  const isLast = false;
                  return (
                    <DropdownOption
                      key={index}
                      style={{ height: isFirst || isLast ? 46 : 38 }}
                    >
                      <DropdownOptionButton
                        onClick={() => {
                          setIsStatusDropdownOpen(false);
                          navigate(
                            `${generatePath(routes.performanceDevelopmentList, {
                              entityType,
                              id,
                            })}?${status.path}&period=${period}`
                          );
                        }}
                        style={{
                          paddingTop: isFirst ? 16 : 8,
                          paddingBottom: isLast ? 16 : 8,
                        }}
                      >
                        <DropdownOptionLabel style={{ height: 22, margin: 0 }}>
                          {status.text}
                        </DropdownOptionLabel>
                      </DropdownOptionButton>
                    </DropdownOption>
                  );
                })}
              </DropdownOptionsContainer>
            </FlexContainer>
          </div>
        </Dropdown>
      </div>
    </FlexContainer>
  );
};
