import styled from '@emotion/styled';
import { useCallback, useRef, useState } from 'react';
import { Theme } from 'styles/themes';
import { FlexContainer } from 'styles/utils';
import { Dropdown, DropdownArrow } from 'components/Dropdown';
import { OverflowYAutoContainer } from 'components/OverflowYAutoContainer';
import { Loading } from 'components/Loading';

interface TurnaroundTimeDetailsFilterSetProps {
  title: string;
  count: number;
  isLoading: boolean;
  children: JSX.Element;
  transform: string;
}

const FilterSetContainer = styled(FlexContainer)({
  alignItems: 'center',
  padding: '0 8px',
  cursor: 'pointer',
});

const ChevronContainer = styled.div({
  width: '40px',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
});

const FilterSetLabel = styled.span((props: { isHighlighted?: boolean }) => ({
  fontWeight: 'bold',
  color: props.isHighlighted ? Theme.colors.primaryColor : undefined,
}));

export const TurnaroundTimeDetailsFilterSet = (
  props: TurnaroundTimeDetailsFilterSetProps
) => {
  const [isDropdownExpanded, setIsDropdownExpanded] = useState(false);
  const dropdownContainerRef = useRef(null);
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      setIsDropdownExpanded(!isDropdownExpanded);
    },
    [isDropdownExpanded]
  );
  return (
    <FilterSetContainer ref={dropdownContainerRef} onClick={handleClick}>
      <FilterSetLabel isHighlighted={isDropdownExpanded}>
        {`${props.title} (${props.count})`}
      </FilterSetLabel>
      <ChevronContainer
        style={{
          paddingTop: `${isDropdownExpanded ? 0 : 10}px`,
          marginTop: `${isDropdownExpanded ? -10 : 0}px`,
        }}
      >
        <DropdownArrow
          isExpanded={isDropdownExpanded}
          color={isDropdownExpanded ? Theme.colors.primaryColor : undefined}
          style={{ transition: 'none' }}
        />
        <Dropdown
          isOpen={isDropdownExpanded}
          setIsOpen={setIsDropdownExpanded}
          keepOpenAfterClick
          containerRef={dropdownContainerRef}
          transform={props.transform}
          style={{
            border: `1px solid ${Theme.colors.grayBorder}`,
            boxShadow: `0px 1px 15px ${Theme.colors.grayShadow}`,
            left: 0,
          }}
        >
          {props.isLoading && <Loading />}
          <div style={{ width: '300px', height: '395px' }}>
            <OverflowYAutoContainer
              style={{ height: '100%', width: '100%', margin: 'auto' }}
            >
              {props.children}
            </OverflowYAutoContainer>
          </div>
        </Dropdown>
      </ChevronContainer>
    </FilterSetContainer>
  );
};
