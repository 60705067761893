import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { ChangeEvent, useCallback } from 'react';
import ArrowDown from 'assets/arrow-down.svg';

const SelectContainer = styled.div({
  width: '150%',
  '& + &': {
    width: '100%',
  },
});

const SelectInput = styled.select({
  border: `1px solid ${Theme.colors.grayBorder}`,
  height: 40,
  borderRadius: Theme.borderRadius,
  padding: '0 8px',
  paddingRight: '34px',
  appearance: 'none',
  fontWeight: 600,
  backgroundImage: `url(${ArrowDown})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'calc(100% - 6px)',
  width: '100%',
});

export const Select = (props: ISelectProps<any>) => {
  const { options, onChange, selected } = props;
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      onChange && onChange(e.target.value);
    },
    [onChange]
  );
  return (
    <SelectContainer>
      <SelectInput value={selected} onChange={handleChange}>
        {options.map((o) => (
          <option key={o.value} value={o.value}>
            {o.label}
          </option>
        ))}
      </SelectInput>
    </SelectContainer>
  );
};

interface IOption<T = any> {
  label: string;
  value: T;
}

interface ISelectProps<T> {
  options: IOption<T>[];
  selected?: IOption<T> | T;
  onChange: (newValue: T) => void;
}
