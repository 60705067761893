import { Header } from 'components/Header';
import { selectAuthenticationResponse } from 'store/authentication/authentication-slice';
import { useAppSelector } from 'store/hooks';
import { Theme } from 'styles/themes';
import { BoldText, FlexContainer } from 'styles/utils';
import { useEffect, useState } from 'react';
import { User } from 'models';
import { getCurrentUser } from 'services/users/users-service';
import { OverflowYAutoContainer } from 'components/OverflowYAutoContainer';
import { UserCard, UserTeamsCard } from './components';
import { Loading } from 'components/Loading';
import { PasswordChangeModal } from 'components/PasswordChangeModal';

export const Profile = () => {
  const authenticationResponse = useAppSelector(selectAuthenticationResponse);
  const [user, setUser] = useState<User | null>(null);
  const [triggerUserUpdate, setTriggerUserUpdate] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordChangeModalDisplayed, setIsPasswordChangeModalDisplayed] =
    useState(false);

  useEffect(() => {
    (async () => {
      if (authenticationResponse) {
        setIsLoading(true);
        const fetchedUser = (
          await getCurrentUser(authenticationResponse.accessToken)
        ).data;
        setUser(fetchedUser);
        setIsLoading(false);
      }
    })();
  }, [authenticationResponse?.accessToken, triggerUserUpdate]);

  return (
    <>
      <FlexContainer
        flexDirection="column"
        style={{
          width: '100%',
          flex: 1,
          position: 'relative',
        }}
      >
        <Header hasBorderBottom style={{ width: '100%' }}>
          <BoldText style={{ fontSize: Theme.fontSize + 2 }}>
            My profile
          </BoldText>
        </Header>
        {isLoading && <Loading />}
        {user && (
          <OverflowYAutoContainer
            style={{
              backgroundColor: Theme.colors.primaryBackgroundColor,
              width: '100%',
              padding: '16px',
              height: 'calc(100% - 56px)',
            }}
          >
            <div style={{ display: 'flex' }}>
              <UserCard
                user={user}
                triggerUserUpdate={triggerUserUpdate}
                setTriggerUserUpdate={setTriggerUserUpdate}
                setIsPasswordChangeModalDisplayed={
                  setIsPasswordChangeModalDisplayed
                }
              />
              <UserTeamsCard user={user} />
            </div>
          </OverflowYAutoContainer>
        )}
      </FlexContainer>
      <PasswordChangeModal
        isDisplayed={isPasswordChangeModalDisplayed}
        setIsDisplayed={setIsPasswordChangeModalDisplayed}
      />
    </>
  );
};
