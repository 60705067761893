import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { ReactComponent as DoubleArrowIcon } from 'assets/double-arrow.svg';
import { useState } from 'react';
import { ArrowDirections, Tooltip } from 'components/Tooltip';
import { HTMLAttrID } from 'models';

interface OpenSidebarButtonProps extends HTMLAttrID {
  left?: number;
  top?: number;
  right?: number;
  bottom?: number;
  onClick: () => void;
  isSidePanelOpen?: boolean;
}

const OpenSidebarButtonContainer = styled.div<{
  isHovered: boolean;
  left?: number;
  top?: number;
  right?: number;
  bottom?: number;
}>((props) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  left: props.left || 'auto',
  top: props.top || 'auto',
  right: props.right || 'auto',
  bottom: props.bottom || 'auto',
  zIndex: 1,
  width: '48px',
  height: '48px',
  background: Theme.colors.white,
  opacity: props.isHovered ? 1 : 0.5,
  borderRadius: Theme.borderRadius,
  padding: '16px',
  cursor: 'pointer',
}));

const TooltipContainer = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});

export const OpenSidebarButton = (props: OpenSidebarButtonProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [tooltipContainer, setTooltipContainer] =
    useState<HTMLSpanElement | null>(null);

  return (
    <OpenSidebarButtonContainer
      id={props.id}
      isHovered={isHovered}
      left={props.left}
      top={props.top}
      right={props.right}
      bottom={props.bottom}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={props.onClick}
    >
      <DoubleArrowIcon
        id={props.id + 'Icon'}
        width={16}
        height={16}
        style={{
          transform: props.isSidePanelOpen ? 'rotate(180deg)' : undefined,
        }}
      />
      <TooltipContainer
        id={props.id + 'TooltipContainer'}
        ref={(tooltipContainer) => setTooltipContainer(tooltipContainer)}
      >
        <Tooltip
          id={props.id + 'Tooltip'}
          relativeToElement={tooltipContainer}
          hasArrow
          arrowDirection={ArrowDirections.Down}
        >
          <span style={{ whiteSpace: 'nowrap' }}>
            {props.isSidePanelOpen ? 'Hide' : 'Open'} side panel
          </span>
        </Tooltip>
      </TooltipContainer>
    </OpenSidebarButtonContainer>
  );
};
