import { DevelopmentCitationMatch, HTMLAttrID } from 'models';
import { useEffect, useMemo } from 'react';
import { Theme } from 'styles/themes';
import { DevelopmentCitationMatchDetails } from './components/DevelopmentCitationMatchDetails';

interface DevelopmentCitationMatchDetailsListProps extends HTMLAttrID {
  developmentCitationMatches: DevelopmentCitationMatch[];
  isSelectedDevelopmentCitation: boolean;
  isCmsIdsListExpanded: boolean;
  setMainCmsColor: React.Dispatch<React.SetStateAction<string>>;
}

export const DevelopmentCitationMatchDetailsList = (
  props: DevelopmentCitationMatchDetailsListProps
) => {
  const cmsYellow = useMemo(() => {
    return props.isSelectedDevelopmentCitation
      ? Theme.colors.cmsYellowSelected
      : Theme.colors.cmsYellow;
  }, [props.isSelectedDevelopmentCitation]);

  const cmsBlue = useMemo(() => {
    return props.isSelectedDevelopmentCitation
      ? Theme.colors.cmsBlueSelected
      : Theme.colors.cmsBlue;
  }, [props.isSelectedDevelopmentCitation]);

  const cmsGreen = useMemo(() => {
    return props.isSelectedDevelopmentCitation
      ? Theme.colors.cmsGreenSelected
      : Theme.colors.cmsGreen;
  }, [props.isSelectedDevelopmentCitation]);

  const developmentCitationMatchDetailsArray = useMemo(() => {
    return props.developmentCitationMatches.map((developmentCitationMatch) => {
      let cmsPriority = 3;
      let cmsColor = cmsYellow;

      const hasAnyDirectMatch =
        developmentCitationMatch.developmentCitationMatchNormalizedCitations?.some(
          (developmentCitationMatchNormalizedCitation) =>
            developmentCitationMatchNormalizedCitation.isDirectMatch
        );
      const hasAnyIndirectMatch =
        developmentCitationMatch.developmentCitationMatchNormalizedCitations?.some(
          (developmentCitationNormalizedCitation) =>
            !developmentCitationNormalizedCitation.isDirectMatch
        );
      if (hasAnyIndirectMatch) {
        cmsPriority = 2;
        cmsColor = cmsBlue;
      }
      if (hasAnyDirectMatch) {
        cmsPriority = 1;
        cmsColor = cmsGreen;
      }
      return {
        developmentCitationMatch,
        cmsPriority,
        cmsColor,
      };
    });
  }, [
    JSON.stringify(props.developmentCitationMatches),
    cmsYellow,
    cmsBlue,
    cmsGreen,
  ]);

  useEffect(() => {
    if (developmentCitationMatchDetailsArray.some((x) => x.cmsPriority === 3)) {
      props.setMainCmsColor(cmsYellow);
    }
    if (developmentCitationMatchDetailsArray.some((x) => x.cmsPriority === 2)) {
      props.setMainCmsColor(cmsBlue);
    }
    if (developmentCitationMatchDetailsArray.some((x) => x.cmsPriority === 1)) {
      props.setMainCmsColor(cmsGreen);
    }
  }, [JSON.stringify(developmentCitationMatchDetailsArray)]);

  return (
    <>
      {developmentCitationMatchDetailsArray
        .sort((a, b) => a.cmsPriority - b.cmsPriority)
        .filter((_, y) => {
          if (!props.isCmsIdsListExpanded) {
            return y === 0;
          }
          return true;
        })
        .map((developmentCitationMatchDetailsElement) => {
          return (
            <DevelopmentCitationMatchDetails
              key={
                developmentCitationMatchDetailsElement.developmentCitationMatch
                  .id
              }
              id={String(
                developmentCitationMatchDetailsElement.developmentCitationMatch
                  .id
              )}
              cmsId={
                developmentCitationMatchDetailsElement.developmentCitationMatch
                  .cmsId
              }
              citationName={
                developmentCitationMatchDetailsElement.developmentCitationMatch
                  .citationName
              }
              cmsColor={developmentCitationMatchDetailsElement.cmsColor}
            />
          );
        })}
    </>
  );
};
