import styled from '@emotion/styled';
import { ReactNode, useState } from 'react';
import { Theme } from 'styles/themes';
import { CursorPointer } from 'styles/utils';
import { ReactComponent as ArrowDownIcon } from 'assets/arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/arrow-up.svg';

interface CollapsibleGridFieldProps {
  height?: string;
  title: ReactNode;
  children?: ReactNode;
  isCollapsible?: boolean;
}

const GridField = styled.div<{ height?: string }>((props) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: `inset 0px -1px 0px ${Theme.colors.grayBorder}`,
  height: props.height || 'auto',
  width: '100%',
  padding: '15px',
}));

export const CollapsibleGridField = (props: CollapsibleGridFieldProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  return (
    <GridField height={props.height}>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          {props.title}
        </div>
        {props.isCollapsible && (
          <CursorPointer
            onClick={() => setIsCollapsed(!isCollapsed)}
            style={{ marginLeft: 'auto', userSelect: 'none' }}
          >
            {isCollapsed ? (
              <ArrowDownIcon style={{ color: Theme.colors.grayDark }} />
            ) : (
              <ArrowUpIcon style={{ color: Theme.colors.grayDark }} />
            )}
          </CursorPointer>
        )}
      </div>
      {!!props.children && (
        <div
          style={{
            display: isCollapsed ? 'none' : 'flex',
            marginTop: isCollapsed ? '0px' : '14px',
          }}
        >
          {props.children}
        </div>
      )}
    </GridField>
  );
};
