import styled from '@emotion/styled';
import {
  CloseIconContainer,
  Modal,
  ModalContentContainer,
} from 'components/Modal';
import { Theme } from 'styles/themes';
import { ReactComponent as Close } from 'assets/close.svg';
import { useEffect, useState } from 'react';
import { FlexContainer } from 'styles/utils';
import { CountryFlagIcon } from 'components/CountryFlagIcon';
import { DetailButton } from 'pages/management/components';
import { ReactComponent as EditIcon } from 'assets/edit-smaller.svg';
import { ReactComponent as CancelIcon } from 'assets/cancel.svg';
import { ReactComponent as ConfirmEditIcon } from 'assets/confirm-edit.svg';
import {
  Source,
  SourceDetails,
  SourceDetailsResponse,
} from 'models/reports/Source';
import { ReactComponent as AddCircle } from 'assets/add_circle.svg';
import { Textarea } from 'components/Textarea';
import { Input } from 'components/Input';
import { BlockLike } from 'typescript';
import { Table, Tbody, Td, Th, Thead, Tr } from 'components/Table';
import { getSourceDetails } from 'services/sources/source-service';

interface SidebarModalProps {
  isDisplayed: boolean;
  isManagement?: boolean;
  data?: Source;
  country: string;
  onClickEnvironment?: () => void;
  closedSidebar: (isClosed: boolean) => void;
}

export const SidebarModalContainer = styled.div({
  display: 'flex',
  height: '100%',
});

export const Title = styled.h1({
  fontFamily: Theme.fontFamily,
  fontSize: Theme.fontSizes.normal,
  fontWeight: Theme.fontWeight.extraBold,
  lineHeight: '22px',
  color: '#262626',
});

export const Description = styled.div({
  fontFamily: Theme.fontFamily,
  fontSize: Theme.fontSize,
  fontWeight: Theme.fontWeight.normal,
  lineHeight: '22px',
  textAlign: 'left',
  color: '#262626',
});

const SideDetailFooter = styled.div({
  width: '100%',
  height: '56px',
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  borderTop: `solid 1px ${Theme.colors.grayBorder}`,
  marginTop: 'auto',
});

const CustomModal = {
  borderRadius: '0',
  top: '0%',
  left: 'inherit',
  transform: 'none',
  right: '0',
  height: '100%',
  boxShadow: '-4px 4px 40px 0px rgba(0, 0, 0, 0.1)',
  borderLeft: '1px solid rgba(221, 225, 227, 1)',
  padding: '0',
  width: '475px',
  zIndex: '3',
};

const SideDetailHeader = styled.div({
  gap: '0px',
  justifyContent: 'space-between',
  display: 'flex',
  paddingBottom: '20px',
  margin: '15px',
});

const SideDetailTitle = styled.div({
  fontSize: Theme.fontSizes.extraLarge,
  fontWeight: Theme.fontWeight.extraBold,
});

const SideDetailBody = styled.div({
  margin: '0 15px',
  height: 'calc(100% - 150px)',
  overflow: 'auto',
});

const StyledTable = styled(Table)({
  border: `1px solid ${Theme.colors.grayBorder}`,
  position: 'relative',
  boxShadow: 'none',
  fontSize: Theme.fontSizes.base,
});

const StyledTr = styled(Tr)({
  height: '30px',
});

const StyledTh = styled(Th)({
  border: `1px solid ${Theme.colors.grayBorder}`,
  borderCollapse: 'collapse',
  padding: '6px 8px',
  fontWeight: 'bold',
});
const StyledTd = styled(Td)({
  border: `1px solid ${Theme.colors.grayBorder}`,
  borderCollapse: 'collapse',
});

interface PubDateRange {
  maxPubDate: string | null;
  minPubDate: string | null;
}

export const SidebarModal: React.FC<SidebarModalProps> = ({
  data,
  country,
  isManagement = false,
  onClickEnvironment,
  ...props
}) => {
  const [isEdit, setIsEdit] = useState(true);
  const [noteText, setNoteText] = useState('');
  const [baseURLText, setBaseURLText] = useState('');
  const [isDisplayed, setIsDisplayed] = useState(props.isDisplayed);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [sourceDetails, setSourceDetails] = useState<SourceDetails[]>([]);
  const [pubDateRange, setPubDateRange] = useState<PubDateRange>({
    maxPubDate: null,
    minPubDate: null,
  });

  const environmentList =
    (data?.Environments && data.Environments.split(',')) || [];

  useEffect(() => {
    setIsEdit(props.isDisplayed);
    setIsDisplayed(props.isDisplayed);
  }, [props.isDisplayed]);

  const closeModal = () => {
    props.closedSidebar(false);
    setIsDisplayed(false);
    setIsEdit(true);
  };

  const handleEdit = () => {
    setIsEdit(false);
  };
  const handleCancel = () => {
    setIsEdit(true);
  };
  const handleConfirmEdit = () => {
    alert('Saved');
    setIsEdit(true);
  };

  useEffect(() => {
    const fetchSourceDetails = async () => {
      if (typeof data?.RowId !== 'number') {
        setError('Invalid RowId.');
        return;
      }
      setIsLoading(true);
      try {
        const response = (await getSourceDetails(data?.RowId)) as {
          data: SourceDetailsResponse;
        };
        if (response?.data?.records?.length > 0) {
          setSourceDetails(response.data.records);
        } else {
          setError('No data found.');
        }
      } catch (err) {
        setError('Failed to fetch source details.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchSourceDetails();
  }, [data?.RowId]);

  useEffect(() => {
    if (sourceDetails && sourceDetails.length > 0) {
      // Safely map and filter only valid date values
      const validDates = sourceDetails
        .map((record) => {
          const timestamp = record.PubDate
            ? new Date(record.PubDate).getTime()
            : NaN;
          return isNaN(timestamp) ? null : timestamp;
        })
        .filter((time): time is number => time !== null); // Type guard for valid numbers

      if (validDates.length > 0) {
        const maxPubDate = new Date(Math.max(...validDates));
        const minPubDate = new Date(Math.min(...validDates));

        setPubDateRange({
          maxPubDate: maxPubDate.toISOString().split('T')[0],
          minPubDate: minPubDate.toISOString().split('T')[0],
        });
      } else {
        // Show dash if no valid dates
        setPubDateRange({
          maxPubDate: '--',
          minPubDate: '--',
        });
      }
    } else {
      // Show dash if sourceDetails is empty
      setPubDateRange({
        maxPubDate: '--',
        minPubDate: '--',
      });
    }
  }, [sourceDetails]);

  return (
    <Modal
      id="SidebarModal"
      isDisplayed={isDisplayed}
      style={CustomModal}
      isOverlay={false}
    >
      <SidebarModalContainer id="SidebarModalContainer">
        <ModalContentContainer id="SidebarModalContentContainer">
          <SideDetailHeader>
            <CountryFlagIcon countryName={country} marginRight={12} />
            <SideDetailTitle>{country}</SideDetailTitle>
            <CloseIconContainer id="SidebarModalCloseIconContainer">
              <Close
                id="SidebarModalCloseButton"
                onClick={() => closeModal()}
              />
            </CloseIconContainer>
          </SideDetailHeader>
          <SideDetailBody>
            <FlexContainer
              flexDirection="column"
              style={{ width: '100%', padding: '16px 0' }}
            >
              <Title>{'Issuing Authority'}</Title>
              <Description>{data?.Authorities}</Description>
            </FlexContainer>
            <FlexContainer
              flexDirection="column"
              style={{ width: '100%', padding: '16px 0' }}
            >
              <Title>{'Subcategory'}</Title>
              <Description>{data?.Subcategories}</Description>
            </FlexContainer>
            <FlexContainer
              flexDirection="column"
              style={{ width: '100%', padding: '16px 0' }}
            >
              <Title>{'Document Type'}</Title>
              <Description>{data?.DocumentTypes}</Description>
            </FlexContainer>
            <FlexContainer
              flexDirection="column"
              style={{ width: '98%', padding: '16px 0' }}
            >
              <StyledTable>
                <Thead>
                  <StyledTr>
                    <StyledTh style={{ minWidth: '60px' }}>
                      <FlexContainer>No. of Dev</FlexContainer>
                    </StyledTh>
                    <StyledTh style={{ minWidth: '150px' }}>
                      <FlexContainer>Min Pub Date</FlexContainer>
                    </StyledTh>
                    <StyledTh style={{ minWidth: '90px' }}>
                      <FlexContainer>Max Pub Date</FlexContainer>
                    </StyledTh>
                  </StyledTr>
                </Thead>
                <Tbody>
                  {sourceDetails && sourceDetails.length > 0 && (
                    <StyledTr>
                      <StyledTd style={{ padding: '6px 8px' }}>
                        {sourceDetails.length}
                      </StyledTd>
                      <StyledTd style={{ padding: '6px 8px' }}>
                        {pubDateRange.minPubDate}
                      </StyledTd>
                      <StyledTd style={{ padding: '6px 8px' }}>
                        {pubDateRange.maxPubDate}
                      </StyledTd>
                    </StyledTr>
                  )}
                </Tbody>
              </StyledTable>
            </FlexContainer>
            <FlexContainer
              flexDirection="column"
              style={{ width: '100%', padding: '16px 0' }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Title>{`Environment (${environmentList?.length})`}</Title>
                {isManagement && (
                  <AddCircle
                    style={{ cursor: 'pointer' }}
                    onClick={onClickEnvironment}
                  />
                )}
              </div>
              <Description style={{ padding: '16px 0' }}>
                {environmentList &&
                  environmentList.length > 0 &&
                  environmentList.map((env, index) => (
                    <span
                      key={index} // Use the index as the key
                      style={{
                        backgroundColor: '#E5F5FF',
                        marginRight: '5px',
                        padding: '6px',
                        borderRadius: '4px',
                        fontSize: '14px',
                        fontWeight: 400,
                        color: '#01416A',
                        marginBottom: '10px',
                        display: 'inline-block',
                      }}
                    >
                      {env.trim()}
                    </span>
                  ))}
              </Description>
            </FlexContainer>
            {isManagement ? (
              <>
                <FlexContainer
                  flexDirection="column"
                  style={{ width: '100%', gap: '16px', paddingBottom: '0' }}
                >
                  <Title>{'Notes'}</Title>
                  {isEdit ? (
                    <Description>{data?.Note}</Description>
                  ) : (
                    <Description>
                      <Textarea
                        id="FeedbackModalTextarea"
                        placeholder="Write something"
                        value={noteText}
                        setValue={setNoteText}
                        style={{
                          minHeight: '120px',
                          borderRadius: '6px',
                          border: '1px solid rgba(197, 197, 210, 1)',
                        }}
                      />
                    </Description>
                  )}
                </FlexContainer>
                <FlexContainer
                  flexDirection="column"
                  style={{ width: '100%', margin: '16px 0', gap: '16px' }}
                >
                  <Title>{'Base URL'}</Title>
                  {isEdit ? (
                    <Description>{data?.BaseURL}</Description>
                  ) : (
                    <Input
                      id="CreateFeedModalName"
                      placeholder="www.example.com"
                      value={baseURLText}
                      setValue={(value) => {
                        setBaseURLText(value);
                      }}
                    />
                  )}
                </FlexContainer>
              </>
            ) : (
              <>
                <FlexContainer flexDirection="column" style={{ width: '100%' }}>
                  <Title>{'Notes'}</Title>
                  <Description>{data?.Note}</Description>
                </FlexContainer>
                <FlexContainer
                  flexDirection="column"
                  style={{ width: '100%', margin: '16px 0' }}
                >
                  <Title>{'Base URL'}</Title>
                  <Description>{data?.BaseURL}</Description>
                </FlexContainer>
              </>
            )}
          </SideDetailBody>
          {isManagement && (
            <SideDetailFooter>
              {isEdit && (
                <DetailButton
                  id="FeedManagementDetailEditButton"
                  key="Edit"
                  label="Edit"
                  icon={<EditIcon />}
                  color={Theme.colors.secondaryColor}
                  onClick={handleEdit}
                />
              )}
              {!isEdit && (
                <>
                  <DetailButton
                    id="FeedManagementDetailDeleteButton"
                    key="Cancel"
                    label="Cancel"
                    icon={<CancelIcon />}
                    color={Theme.colors.red}
                    onClick={handleCancel}
                    removeDivider
                    style={{ width: '50%' }}
                  />
                  <DetailButton
                    id="FeedManagementDetailEditButton"
                    key="ConfirmEditIcon"
                    label="Confirm edit"
                    icon={<ConfirmEditIcon />}
                    color={Theme.colors.secondaryColor}
                    onClick={handleConfirmEdit}
                    style={{ width: '50%' }}
                  />
                </>
              )}
            </SideDetailFooter>
          )}
        </ModalContentContainer>
      </SidebarModalContainer>
    </Modal>
  );
};
