import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DevelopmentUserReview } from 'models';
import { RootState } from '../store';

export interface RealTimeReviewState {
  reviewers: DevelopmentUserReview[];
}

const initialState: RealTimeReviewState = {
  reviewers: [],
};

export const realTimeReviewersSlice = createSlice({
  name: 'real-time-reviewers',
  initialState,
  reducers: {
    setReviewers: (state, action: PayloadAction<DevelopmentUserReview[]>) => {
      const reviewers = action.payload;
      state.reviewers = reviewers;
    },
  },
});

export const { setReviewers } = realTimeReviewersSlice.actions;

export const selectReviewers = (state: RootState) =>
  state.realTimeReviewers.reviewers;

export default realTimeReviewersSlice.reducer;
