import styled from '@emotion/styled';
import { FlexContainer } from 'styles/utils';
import { turnaroundTimeSummaryLabels } from '../labels';

interface TurnaroundTimeSummaryDataProps {
  turnaroudBucketType: keyof typeof turnaroundTimeSummaryLabels;
  value: number;
  isBold?: boolean;
}

const Title = styled.div(({ isBold }: { isBold?: boolean }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  lineHeight: '32px',
  fontSize: '14px',
  fontWeight: isBold ? 'bold' : 'normal',
}));

const Value = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  lineHeight: '44px',
  fontSize: '32px',
  fontWeight: 'bold',
});

export const TurnaroundTimeSummaryData = (
  props: TurnaroundTimeSummaryDataProps
) => {
  return (
    <FlexContainer
      flexDirection="column"
      style={{
        width: '100%',
        flexGrow: 1,
        justifyContent: 'space-between',
      }}
    >
      <Title isBold={props.isBold}>
        {turnaroundTimeSummaryLabels[props.turnaroudBucketType]}
      </Title>
      <Value>{props.value}</Value>
    </FlexContainer>
  );
};
