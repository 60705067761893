import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import {
  getDevelopmentById,
  viewDevelopment,
} from 'services/developments/developments-service';
import {
  Development,
  DevelopmentCitationHighlight,
  DevelopmentMetadataHighlight,
  NormalizedCitationObligation,
} from 'models';
import { getAllRecentlyViewedDevelopmentsAsync } from 'store/recently-viewed-development/recently-viewed-development-slice';

export interface DevelopmentDetailState {
  developmentDetail: Development | null;
  selectedHighlights:
    | DevelopmentMetadataHighlight[]
    | DevelopmentCitationHighlight[];
  searchTerm: string;
  displayNewDevelopmentVersionAvailableModal: boolean;
}

const initialState: DevelopmentDetailState = {
  developmentDetail: null,
  selectedHighlights: [],
  searchTerm: '',
  displayNewDevelopmentVersionAvailableModal: true,
};

export const getDevelopmentByIdAsync = (
  id: string
): AppThunk<Promise<Development | null>> => {
  return async (dispatch, getState) => {
    const response = await getDevelopmentById(id);
    const development = response.data;
    if (development) {
      development.pdfContent = undefined;
    }
    dispatch(setDevelopmentDetail(development));
    return development;
  };
};

export const viewAsync = (
  developmentId: number
): AppThunk<Promise<Development | null>> => {
  return async (dispatch, getState) => {
    const authenticationResponse =
      getState().authentication.authenticationResponse;
    const userId = authenticationResponse?.id;
    if (developmentId && userId) {
      const response = await viewDevelopment(developmentId, userId);
      dispatch(setDevelopmentDetail(response.data));
      dispatch(getAllRecentlyViewedDevelopmentsAsync());
      return response.data;
    } else {
      return null;
    }
  };
};

export const developmentDetailSlice = createSlice({
  name: 'development-detail',
  initialState,
  reducers: {
    setDevelopmentDetail: (
      state,
      action: PayloadAction<Development | null>
    ) => {
      state.developmentDetail = action.payload;
    },
    resetDevelopment: (state) => {
      state.developmentDetail = null;
    },
    setSelectedHighlights: (
      state,
      action: PayloadAction<
        DevelopmentMetadataHighlight[] | DevelopmentCitationHighlight[]
      >
    ) => {
      state.selectedHighlights = action.payload;
    },
    setDevelopmentDetailSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    resetDevelopmentDetailSearchTerm: (state) => {
      state.searchTerm = '';
    },
    setDisplayNewDevelopmentVersionAvailableModal: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.displayNewDevelopmentVersionAvailableModal = action.payload;
    },
  },
});

export const {
  setDevelopmentDetail,
  resetDevelopment,
  setSelectedHighlights,
  setDevelopmentDetailSearchTerm,
  resetDevelopmentDetailSearchTerm,
  setDisplayNewDevelopmentVersionAvailableModal,
} = developmentDetailSlice.actions;

export const selectDevelopmentDetail = (state: RootState) =>
  state.developmentDetail.developmentDetail;
export const selectSelectedHighlights = (state: RootState) =>
  state.developmentDetail.selectedHighlights;
export const selectDevelopmentDetailSearchTerm = (state: RootState) =>
  state.developmentDetail.searchTerm;
export const selectDisplayNewDevelopmentVersionAvailableModal = (
  state: RootState
) => state.developmentDetail.displayNewDevelopmentVersionAvailableModal;

export default developmentDetailSlice.reducer;
