import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { Button } from 'components/Button';

export const TextButton = styled(Button)({
  backgroundColor: Theme.colors.transparent,
  padding: 0,
  minWidth: 'unset',
  height: 'auto',
});
