import styled from '@emotion/styled';
import { CSSProperties, ReactElement, useRef } from 'react';
import { BaseButtonProps } from 'pages/management/interfaces/ManagementDetailButton';
import { Theme } from 'styles/themes';
import { Loading } from 'components/Loading';
import { HTMLAttrID } from 'models';
import { OverflowYAutoContainer } from 'components/OverflowYAutoContainer';

const ManagementDetailContainer = styled.div({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
});

const ManagementDetailBody = styled.div<{
  subtractedHeight?: number;
}>({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const ManagementDetailSection = styled.div<{ removeDivider?: boolean }>(
  ({ removeDivider }) => ({
    width: '100%',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px 0',
    borderTop: `solid ${removeDivider ? 0 : 1}px ${Theme.colors.grayBorder}`,
  })
);

const ManagementDetailFooter = styled.div({
  width: '100%',
  height: '56px',
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  borderTop: `solid 1px ${Theme.colors.grayBorder}`,
  boxShadow: '0px -5px 15px rgba(38, 38, 38, 0.05)',
});

export const ManagementDetail = (props: ManagementDetailProps) => {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  return (
    <ManagementDetailContainer id={props.id}>
      {props.isLoading && <Loading id={`${props.id}Loading`} />}
      <ManagementDetailBody>
        <OverflowYAutoContainer
          id={`${props.id}InvisibleScrollContainer`}
          ref={scrollableContainerRef}
          style={{ height: '100%' }}
          isScrollbarInvisible
          maxHeightModifier={-4}
        >
          {props.sections.map((section, index) => {
            return (
              <ManagementDetailSection
                id={`${props.id}Section${index}`}
                key={index}
                removeDivider={!index}
                style={section.styles}
              >
                {section.children}
              </ManagementDetailSection>
            );
          })}
        </OverflowYAutoContainer>
      </ManagementDetailBody>
      <ManagementDetailFooter id={`${props.id}Footer`}>
        {props.buttons}
      </ManagementDetailFooter>
    </ManagementDetailContainer>
  );
};

export interface ManagementSectionProps {
  children: JSX.Element[] | JSX.Element;
  styles?: CSSProperties;
}

export interface ManagementDetailProps extends HTMLAttrID {
  sections: ManagementSectionProps[];
  buttons: ReactElement<BaseButtonProps>[];
  isLoading?: boolean;
}
