import {
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
} from 'utils/http/base-http-service';
import { HttpResponse } from 'utils/http/HttpResponse';
import {
  AddFeedsToTeamsPayload,
  DefaultFeedUserView,
  Feed,
  FeedUserView,
  PaginatedResponse,
  FeedsBulkActionPayload,
} from 'models';
import { toQueryParams } from 'utils/to-query-params';
import { SortByDirection } from 'enums';
import { escapeQueryStringQueryReservedCharacters } from 'utils/elasticsearch';

export const getAllFeeds = async (
  searchTerm: string,
  page: number,
  perPage: number,
  sortByProperty: string,
  sortByDirection: SortByDirection
): Promise<HttpResponse<PaginatedResponse<Feed> | null>> => {
  let query: any = {
    filter: [],
  };
  if (searchTerm) {
    query.filter.push({
      bool: {
        should: [
          {
            query_string: {
              query: `*${escapeQueryStringQueryReservedCharacters(
                searchTerm
              )}*`,
              fields: ['name'],
              minimum_should_match: '100%',
            },
          },
          {
            query_string: {
              query: `*${escapeQueryStringQueryReservedCharacters(
                searchTerm
              )}*`,
              fields: ['teamFeeds.teamName'],
              minimum_should_match: '100%',
            },
          },
        ],
        minimum_should_match: 1,
      },
    });
  }
  const queryParameters = toQueryParams({
    query: JSON.stringify(query),
    page,
    perPage,
    sortByProperty,
    sortByDirection,
  });
  return await httpGet<PaginatedResponse<Feed>>(`feeds?${queryParameters}`);
};

export const getCurrentUserFeeds = async (
  page: number,
  perPage: number,
  searchTerm?: string
): Promise<HttpResponse<PaginatedResponse<Feed> | null>> => {
  let query: any = {};
  if (searchTerm) {
    query.filter = [
      {
        bool: {
          should: [
            {
              query_string: {
                query: `*${escapeQueryStringQueryReservedCharacters(
                  searchTerm
                )}*`,
                fields: ['name'],
                minimum_should_match: '100%',
              },
            },
          ],
          minimum_should_match: 1,
        },
      },
    ];
  }
  const queryParameters = toQueryParams({
    query: JSON.stringify(query),
    sortByProperty: 'lastDateViewedByUser',
    page,
    perPage,
  });
  return await httpGet<PaginatedResponse<Feed>>(
    `feeds/current-user-feeds?${queryParameters}`
  );
};

export const getUserLastViewedFeed = async (): Promise<
  HttpResponse<Feed | null>
> => {
  return await httpGet<Feed | null>(`feeds/user-last-viewed-feed`);
};

export const createFeed = async (
  feed: Feed
): Promise<HttpResponse<Feed | null>> => {
  return await httpPost<Feed, Feed>('feeds', feed);
};

export const updateFeed = async (
  feed: Feed
): Promise<HttpResponse<Feed | null>> => {
  return await httpPut<Feed, Feed>(`feeds/${feed.id}`, feed);
};

export const deleteFeed = async (
  feedId: number
): Promise<HttpResponse<boolean | null>> => {
  return await httpDelete(`feeds/${feedId}`);
};

export const deleteFeeds = async (
  payload: FeedsBulkActionPayload
): Promise<HttpResponse<Feed[] | null>> => {
  return await httpDelete<Feed[], FeedsBulkActionPayload>(
    `feeds/bulk-delete`,
    payload
  );
};

export const viewFeed = async (
  feedId: number,
  userId: number,
  environmentId?: number
): Promise<HttpResponse<Feed | null>> => {
  return await httpPost<Feed, FeedUserView>('feeds/view', {
    feedId,
    userId,
    environmentId,
  });
};

export const viewDefaultFeed = async (
  userId: number,
  environmentId?: number
): Promise<HttpResponse<DefaultFeedUserView | null>> => {
  return await httpPost<DefaultFeedUserView, DefaultFeedUserView>(
    'feeds/view-default-feed',
    {
      userId,
      environmentId,
    }
  );
};

export const addFeedsToTeams = async (
  addFeedsToTeamsPayload: AddFeedsToTeamsPayload
): Promise<HttpResponse<Feed[] | null>> => {
  return await httpPost<Feed[], AddFeedsToTeamsPayload>(
    'feeds/add-teams',
    addFeedsToTeamsPayload
  );
};
