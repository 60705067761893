import styled from '@emotion/styled';

export const Row = styled.div({
  alignContent: 'center',
  alignItems: 'center',
  display: 'grid',
  gridColumnGap: '8px',
  gridTemplateColumns: 'repeat(7, 32px)',
  gridTemplateRows: '32px',
  justifyContent: 'center',
  justifyItems: 'center',
  textAlign: 'center',
  width: '100%',

  '& + &': {
    marginTop: '8px',
  },
});
