import {
  addDays,
  addMonths,
  isBefore,
  isEqual,
  lastDayOfMonth,
  startOfWeek,
  subMonths,
  setMonth,
  setYear,
  format,
  parse,
  startOfDay,
  endOfDay,
  sub,
  isSameDay,
  isSameYear,
} from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { curry } from 'lodash/fp';
import { padTo2Digits } from 'utils/number-formatter';
import { isNully } from 'utils/var';

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export enum DateFormats {
  T = 'T',
  ISO = 'yyyy-MM-DDTHH:mm:ss.SSSXX',
  LOCALE_STRING = 'P, pp',
  P = 'P',
  PP = 'PP',
  PPp = 'PPp',
  PPP = 'PPP',
}

export const HOURS_IN_A_DAY = 24;
export const MINUTES_IN_HOUR = 60;
export const SECONDS_IN_MINUTE = 60;
export const MILLIS_IN_SECOND = 1000;
export const MILLIS_IN_A_MINUTE = MILLIS_IN_SECOND * SECONDS_IN_MINUTE;
export const MILLIS_IN_A_DAY =
  MILLIS_IN_SECOND * SECONDS_IN_MINUTE * MINUTES_IN_HOUR * HOURS_IN_A_DAY;

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

export type DateRangeInMilliseconds = {
  [x in keyof DateRange]: number;
};

/**
 * Returns a two-dimensional array with calendar represented dates
 */
export function matrix(
  year: number,
  month: number,
  weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6 = 0,
  monthsToCover: number = 1
): Date[][][] {
  const allMonths = [];
  for (let i = 0; i < monthsToCover; i++) {
    const baseDate = new Date(year, month + i, 1);
    const lastDay = lastDayOfMonth(baseDate);
    const startDate = startOfWeek(baseDate, { weekStartsOn });
    const sameMonth = (d: Date) => isBefore(d, lastDay) || isEqual(d, lastDay);
    const rows = 7;
    const cols = 7;
    const length = rows * cols;
    const result: Date[][] = Array.from({ length })
      // create a list of dates
      .map((_, index) => addDays(startDate, index))
      // fold the array into a matrix
      .reduce(
        (m, _, index, days) =>
          !(index % cols !== 0) ? [...m, days.slice(index, index + cols)] : m,
        [] as any[]
      );

    const [lastRow, ...firstRows] = [...result].reverse();

    allMonths.push(lastRow.some(sameMonth) ? result : firstRows.reverse());
  }
  return allMonths;
}

export const updateMonth = curry(
  (action: 'SUB' | 'ADD', amount: number, date: Date) =>
    action === 'ADD' ? addMonths(date, amount) : subMonths(date, amount)
);

export const setMonthUtil = curry((date: Date, month: number) =>
  setMonth(date, month - 1)
);

export const updateYear = curry((date: Date, year: number) =>
  setYear(date, year)
);

export const getRecentYears = (
  count: number = 20,
  future: boolean = false
): number[] => {
  const years = [];
  const currentYear = +format(new Date(), 'yyyy');
  let y = !future ? currentYear - count : currentYear - count / 2;
  for (; y < currentYear + (!future ? count : count / 2); y++) {
    years.push(y);
  }
  return years;
};

export const getCurrentDateMilliseconds = (): number => {
  const currentDateMilliseconds = new Date().getTime();
  return currentDateMilliseconds;
};

export const getSubtractionFromDateInMilliseconds = (
  date: Date,
  subtract: Duration
): number => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const dateWithoutTime = new Date(Date.UTC(year, month, day));

  return sub(dateWithoutTime, subtract).getTime();
};

export const getDatesDifferenceInMilliseconds = (a: Date, b: Date): number => {
  return Math.abs(a.getTime() - b.getTime());
};

export const getDaysBetweenDates = (
  from?: Date | number,
  to?: Date | number,
  roundUp?: boolean
): number => {
  const [fromInMilliseconds, toInMilliseconds] = [from, to].map((d) =>
    isNully(d) ? Date.now() : typeof d === 'number' ? d : d!.getTime()
  );
  const diffInDays =
    Math.abs(fromInMilliseconds - toInMilliseconds) / MILLIS_IN_A_DAY;
  return roundUp ? Math.ceil(diffInDays) : Math.floor(diffInDays);
};

export const ignoreDateRangeTime = (
  range: Partial<DateRange>
): Partial<DateRange> => {
  return {
    startDate: range.startDate ? startOfDay(range.startDate) : undefined,
    endDate: range.endDate ? endOfDay(range.endDate) : undefined,
  };
};

export const dateRangeInMillisecondsToDateRange = (
  rangeInMilliseconds: Partial<DateRangeInMilliseconds>
): Partial<DateRange> => ({
  startDate: rangeInMilliseconds.startDate
    ? new Date(rangeInMilliseconds.startDate)
    : undefined,
  endDate: rangeInMilliseconds.endDate
    ? new Date(rangeInMilliseconds.endDate)
    : undefined,
});

export const dateRangeToNormalizedDateRangeInMilliseconds = (
  range: Partial<DateRange>
): DateRangeInMilliseconds => {
  const rangeDateParams = Object.values(range);
  const onlyOneParamHasValue =
    rangeDateParams.some((d) => isNully(d)) &&
    rangeDateParams.some((d) => !isNully(d));
  if (onlyOneParamHasValue) {
    if (!!range.startDate) {
      range.endDate = range.startDate;
    } else {
      range.startDate = range.endDate;
    }
  }
  const startDateInMilliseconds = range.startDate?.getTime() ?? Date.now();
  const endDateInMilliseconds = range.endDate?.getTime() ?? Date.now();
  const isReversed = startDateInMilliseconds > endDateInMilliseconds;
  const normalizedDateRange = ignoreDateRangeTime({
    startDate: new Date(
      isReversed ? endDateInMilliseconds : startDateInMilliseconds
    ),
    endDate: new Date(
      isReversed ? startDateInMilliseconds : endDateInMilliseconds
    ),
  });
  return {
    startDate: normalizedDateRange.startDate!.getTime(),
    endDate: normalizedDateRange.endDate!.getTime(),
  };
};

export const dateRangeToString = (
  range: Partial<DateRange>,
  formatToUse: DateFormats = DateFormats.T,
  spaces: boolean = false
): string => {
  if (!range || !range.startDate) return '';
  const separator = spaces ? ' - ' : '-';
  return `${format(range.startDate, formatToUse)}${
    range.endDate ? `${separator}${format(range.endDate, formatToUse)}` : ''
  }`;
};

export const dateRangeInMillisecondsToString = (
  range: Partial<DateRangeInMilliseconds>,
  spaces: boolean = false
): string => {
  if (!range.startDate) return '';
  const separator = spaces ? ' - ' : '-';
  return `${range.startDate}${
    range.endDate ? `${separator}${range.endDate}` : ''
  }`;
};

export const dateRangeToNormalizedString = (
  range: Partial<DateRange>
): string | null => {
  let str = null;

  if (!range) return str;
  let { startDate, endDate } = range;
  if (startDate && endDate && isSameDay(startDate, endDate)) {
    endDate = undefined;
  }

  if (startDate) {
    if (endDate && isSameYear(startDate, endDate)) {
      str = format(startDate, 'MMM dd');
    } else {
      str = format(startDate, 'MMM dd, yyyy');
    }
    if (endDate) {
      str += ` - ${format(endDate, 'MMM dd, yyyy')}`;
    }
  }
  return str;
};

export const dateRangeInMillisecondsStringToDateRange = (
  input: string,
  formatToUse: DateFormats = DateFormats.T
): Partial<DateRange> | null => {
  const [startDate, endDate] = input.split('-');
  if (!!startDate.length) {
    return {
      startDate: parse(startDate, formatToUse, new Date()),
      endDate: endDate ? parse(endDate, formatToUse, new Date()) : undefined,
    };
  } else {
    return null;
  }
};

export const dateRangeInMillisecondsStringToDateRangeInMilliseconds = (
  input: string
): Partial<DateRangeInMilliseconds> | null => {
  const [startDate, endDate] = input.split('-');
  if (!!startDate.length) {
    return {
      startDate: Number(startDate) || undefined,
      endDate: Number(endDate) || undefined,
    };
  } else {
    return null;
  }
};

export const getPreviousDateRange = (
  range: Partial<DateRange>
): Partial<DateRange> => {
  if (!range.startDate && !range.endDate) {
    return range;
  }
  let startDate: Date;
  let endDate: Date;
  if (!!range.startDate) {
    startDate = range.startDate;
    endDate = range.endDate || new Date();
  } else {
    startDate = range.endDate!;
    endDate = new Date();
  }
  const dateDiff = getDatesDifferenceInMilliseconds(endDate, startDate);
  const previousEndDate = new Date(startDate.getTime() - 1);
  const previousStartDate = new Date(previousEndDate.getTime() - dateDiff);
  return {
    startDate: previousStartDate,
    endDate: previousEndDate,
  };
};

export const applyTimezoneOffset = (millis: string): string => {
  const date = parse(millis, DateFormats.T, new Date());
  const offset = date.getTimezoneOffset() * MILLIS_IN_A_MINUTE;
  return `${+millis - offset}`;
};

export const dateToUTCDateString = (
  date: Date | string,
  dateFormat: DateFormats
): string => {
  return formatInTimeZone(date, 'UTC', dateFormat);
};

export const millisecondsToHMS = (
  milliseconds: number,
  rollOver?: boolean
): string => {
  let seconds = Math.floor(milliseconds / MILLIS_IN_SECOND);
  let minutes = Math.floor(seconds / SECONDS_IN_MINUTE);
  let hours = Math.floor(minutes / MINUTES_IN_HOUR);
  seconds %= SECONDS_IN_MINUTE;
  minutes %= MINUTES_IN_HOUR;
  if (rollOver) {
    hours %= HOURS_IN_A_DAY;
  }
  return `${hours}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
};
