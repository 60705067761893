export const routes = {
  login: '/login',
  home: '/developments',
  signup: '/signup',
  forgotPassword: '/forgot-password',
  newPassword: '/new-password',
  urlExpired: '/url-expired',
  developmentDetail: '/developments/:developmentId',
  management: '/management',
  userManagement: '/management/users',
  teamManagement: '/management/teams',
  feedManagement: '/management/feeds',
  reports: '/reports',
  performanceReport: '/reports/performance',
  performanceDetails: '/reports/performance/:entityType/:id',
  performanceDevelopmentList:
    '/reports/performance/:entityType/:id/developments',
  turnaroundTimeReport: '/reports/turnaround-time',
  turnaroundTimeDetails: '/reports/turnaround-time/:entityType/:id',
  turnaroundTimeDevelopmentList:
    '/reports/turnaround-time/:entityType/:id/developments',
  profile: '/profile',
  admin: '/admin',
  adminElasticsearch: '/admin/elasticsearch',
  adminDevelopments: '/admin/developments',
  adminAuthorities: '/admin/authorities',
  adminObligations: '/admin/obligations',
  adminEnvironments: '/admin/environments',
  copilot: '/copilot',
  newsBrief: '/news-brief',
};

export const routeNames = {
  [routes.home]: 'Development Feed',
  [routes.developmentDetail]: 'Development Detail',
  [routes.userManagement]: 'User Management',
  [routes.teamManagement]: 'Team Management',
  [routes.feedManagement]: 'Feed Management',
  [routes.performanceReport]: 'Performance Report',
  [routes.turnaroundTimeReport]: 'Turnaround Time Report',
  [routes.profile]: 'Profile',
  [routes.admin]: 'Admin',
};
