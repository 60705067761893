import styled from '@emotion/styled';
import {
  CloseIconContainer,
  Modal,
  ModalContentContainer,
  ModalInnerContainer,
  ModalTitle,
} from 'components/Modal';
import { Theme } from 'styles/themes';
import { ReactComponent as Close } from 'assets/close.svg';
import { Dispatch, SetStateAction } from 'react';
import { FlexContainer } from 'styles/utils';
import { Button } from 'components/Button';
import legends from 'config/legend.json';

const LegendTitleText = 'Legend';

interface Legend {
  title: string;
  description: string;
  color: string;
}

interface LegendModalProps {
  isDisplayed: boolean;
  setIsDisplayed: Dispatch<SetStateAction<boolean>>;
}

const legendsData: Legend[] =
  legends?.legends?.map((legend) => ({
    title: legend.title,
    color: legend.color,
    description: legend.decription,
  })) || [];

export const LegendModalContainer = styled.div({
  display: 'flex',
  width: '624px',
});

export const LegendTitle = styled.h1({
  fontFamily: Theme.fontFamily,
  fontSize: Theme.fontSize,
  fontWeight: Theme.fontWeight.bold,
  lineHeight: '16px',
  padding: '4px 0 10px',
});

export const LegendDescription = styled.p({
  fontFamily: Theme.fontFamily,
  fontSize: Theme.fontSize,
  fontWeight: Theme.fontWeight.normal,
  lineHeight: '22px',
  textAlign: 'left',
});

export const LegendModal: React.FC<LegendModalProps> = (
  props: LegendModalProps
) => {
  const closeModal = () => {
    props.setIsDisplayed(false);
  };

  return (
    <Modal id="LegendModal" isDisplayed={props.isDisplayed}>
      <LegendModalContainer id="LegendModalContainer">
        <ModalContentContainer id="LegendModalContentContainer">
          <ModalInnerContainer id="LegendModalHeader">
            <ModalTitle
              id="LegendModalTitle"
              withoutMarginBottom={true}
              color={Theme.colors.primaryColor}
            >
              {LegendTitleText}
            </ModalTitle>
            <CloseIconContainer id="LegendModalCloseIconContainer">
              <Close id="LegendModalCloseButton" onClick={() => closeModal()} />
            </CloseIconContainer>
          </ModalInnerContainer>
          {legendsData.map((legend: Legend, index: number) => (
            <ModalInnerContainer id="LegendModalBody" key={legend.title}>
              <FlexContainer
                id="ReprocessDevelopmentModalFormContainer"
                flexDirection="column"
                style={{ width: '100%' }}
              >
                <LegendTitle id={`LegendTitle${index + 1}`}>
                  <span
                    style={{
                      fontSize: Theme.fontSize - 1,
                      color: Theme.colors.black,
                      wordBreak: 'break-word',
                      backgroundColor: Theme.colors[legend.color],
                      borderRadius: Theme.borderRadius,
                      padding: '3px 4px',
                      marginRight: '4px',
                    }}
                  >
                    {legend.title}
                  </span>
                </LegendTitle>
                <LegendDescription id={`LegendDescription${index + 1}`}>
                  {legend.description}
                </LegendDescription>
              </FlexContainer>
            </ModalInnerContainer>
          ))}
          <ModalInnerContainer
            id="LegendModalFooter"
            withoutBorderBottom={true}
            padding="12px 16px"
          >
            <FlexContainer
              id="LegendModalButtonsContainer"
              style={{ marginLeft: 'auto' }}
            >
              <Button id="LegendModalSubmitButton" onClick={() => closeModal()}>
                Close
              </Button>
            </FlexContainer>
          </ModalInnerContainer>
        </ModalContentContainer>
      </LegendModalContainer>
    </Modal>
  );
};
