import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginatedResponse, Team, TeamsBulkActionPayload } from 'models';
import { AppThunk, RootState } from '../store';
import {
  deleteTeams,
  getAllTeams,
  updateTeam,
} from 'services/teams/teams-service';
import { paginationConfig } from 'config/pagination-config';
import { SortByDirection } from 'enums';

export interface ManagementTeamState {
  teams: Team[];
  selectedTeams: { [key: number]: Team };
  areAllTeamsSelected: boolean;
  totalRecords: number;
  sortByProperty: string;
  sortByDirection: SortByDirection;
  page: number;
  perPage: number;
  searchTerm: string;
  triggerTeamsUpdate: number;
  isDeleteConfirmationModalDisplayed: boolean;
  selectedTeamsOutsideOfCurrentPage: Team[];
}

const initialState: ManagementTeamState = {
  teams: [],
  selectedTeams: {},
  areAllTeamsSelected: false,
  totalRecords: 0,
  sortByProperty: 'name.keyword',
  sortByDirection: SortByDirection.ASC,
  page: 1,
  perPage: paginationConfig.managementTeamsPerPage,
  searchTerm: '',
  triggerTeamsUpdate: 0,
  isDeleteConfirmationModalDisplayed: false,
  selectedTeamsOutsideOfCurrentPage: [],
};

export const getAllManagementTeamsAsync = (): AppThunk<Promise<Team[]>> => {
  return async (dispatch, getState) => {
    const managementTeamState = getState().managementTeam;
    const page = managementTeamState.page;
    const perPage = managementTeamState.perPage;
    const searchTerm = managementTeamState.searchTerm;
    const sortByProperty = managementTeamState.sortByProperty;
    const sortByDirection = managementTeamState.sortByDirection;
    const response = await getAllTeams(
      searchTerm,
      sortByProperty,
      sortByDirection,
      page,
      perPage
    );
    dispatch(updateSelectedManagementTeams(response?.data?.records || []));
    dispatch(setManagementTeams(response?.data));
    return response?.data?.records || [];
  };
};

export const updateTeamAsync = (
  payload: Team
): AppThunk<Promise<Team | null>> => {
  return async (dispatch, getState) => {
    const response = await updateTeam(payload);
    if (response.data) {
      await dispatch(getAllManagementTeamsAsync());
      dispatch(selectManagementTeam(response.data));
    }
    return response?.data;
  };
};

export const deleteTeamsAsync = (): AppThunk<Promise<Team[]>> => {
  return async (dispatch, getState) => {
    const managementTeamState = getState().managementTeam;
    const payload: TeamsBulkActionPayload = {
      teamIds: Object.keys(managementTeamState.selectedTeams).map((x) =>
        Number(x)
      ),
      affectsAllTeams: managementTeamState.areAllTeamsSelected,
      searchTerm: managementTeamState.searchTerm,
    };
    const response = await deleteTeams(payload);
    dispatch(removeSelectedManagementTeams());
    dispatch(setTriggerManagementTeamsUpdate());
    return response?.data || [];
  };
};

export const managementTeamSlice = createSlice({
  name: 'management-team',
  initialState,
  reducers: {
    setManagementTeams: (
      state,
      action: PayloadAction<PaginatedResponse<Team> | null>
    ) => {
      state.teams = action.payload?.records || [];
      state.totalRecords = action.payload?.totalRecords || 0;
    },
    selectManagementTeam: (state, action: PayloadAction<Team>) => {
      const team = action.payload;
      const selectedTeamsLength = Object.keys(state.selectedTeams).length + 1;

      if (state.selectedTeams[team.id || 0]) {
        delete state.selectedTeams[team.id || 0];
      } else {
        state.selectedTeams[team.id || 0] = team;
        if (
          selectedTeamsLength === state.totalRecords &&
          state.areAllTeamsSelected
        ) {
          state.areAllTeamsSelected = false;
          state.selectedTeams = {};
        }
      }
    },
    selectAllManagementTeams: (state) => {
      if (
        !Object.keys(state.selectedTeams).length &&
        !state.areAllTeamsSelected
      ) {
        state.areAllTeamsSelected = true;
      } else {
        state.selectedTeams = {};
        state.areAllTeamsSelected = false;
      }
    },
    updateSelectedManagementTeams: (state, action: PayloadAction<Team[]>) => {
      const teams = action.payload;
      teams.forEach((team) => {
        if (!!state.selectedTeams[team.id || 0]) {
          state.selectedTeams[team.id || 0] = team;
        }
      });
    },
    removeSelectedManagementTeams: (state) => {
      if (state.areAllTeamsSelected) {
        state.teams = Object.values(state.selectedTeams).slice(
          0,
          state.perPage
        );
        state.areAllTeamsSelected = false;
      }
      state.selectedTeams = {};
    },
    setManagementTeamsPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setManagementTeamsSearchTerm: (state, action: PayloadAction<string>) => {
      state.page = 1;
      state.searchTerm = action.payload;
      state.areAllTeamsSelected = false;
    },
    setManagementTeamsSortByProperty: (
      state,
      action: PayloadAction<string>
    ) => {
      state.sortByProperty = action.payload;
    },
    setManagementTeamsSortByDirection: (
      state,
      action: PayloadAction<SortByDirection>
    ) => {
      state.sortByDirection = action.payload;
    },
    setTriggerManagementTeamsUpdate: (state) => {
      state.triggerTeamsUpdate = state.triggerTeamsUpdate + 1;
    },
    setIsManagementTeamsDeleteConfirmationModalDisplayed: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isDeleteConfirmationModalDisplayed = action.payload;
    },
    addSelectedManagementTeamsOutsideOfCurrentPage: (
      state,
      action: PayloadAction<Team[]>
    ) => {
      const arrClone = [...state.selectedTeamsOutsideOfCurrentPage];
      const idsArr = arrClone.map((x) => x.id);
      action.payload.forEach((team) => {
        if (!idsArr.includes(team.id)) {
          arrClone.push(team);
        }
      });
      state.selectedTeamsOutsideOfCurrentPage = arrClone;
    },
    removeSelectedManagementTeamsOutsideOfCurrentPage: (
      state,
      action: PayloadAction<Team[]>
    ) => {
      const arrClone = [...state.selectedTeamsOutsideOfCurrentPage];
      const idsArr = arrClone.map((x) => x.id);
      action.payload.forEach((team) => {
        const index = idsArr.indexOf(team.id);
        if (index > -1) {
          arrClone.splice(index, 1);
        }
      });
      state.selectedTeamsOutsideOfCurrentPage = arrClone;
    },
  },
});

export const {
  setManagementTeams,
  selectManagementTeam,
  selectAllManagementTeams,
  updateSelectedManagementTeams,
  removeSelectedManagementTeams,
  setManagementTeamsPage,
  setManagementTeamsSearchTerm,
  setManagementTeamsSortByProperty,
  setManagementTeamsSortByDirection,
  setTriggerManagementTeamsUpdate,
  setIsManagementTeamsDeleteConfirmationModalDisplayed,
  addSelectedManagementTeamsOutsideOfCurrentPage,
  removeSelectedManagementTeamsOutsideOfCurrentPage,
} = managementTeamSlice.actions;

export const selectManagementTeams = (state: RootState) =>
  state.managementTeam.teams;
export const selectSelectedManagementTeams = (state: RootState) =>
  state.managementTeam.selectedTeams;
export const selectAreAllManagementTeamsSelected = (state: RootState) =>
  state.managementTeam.areAllTeamsSelected;
export const selectManagementTeamsTotalRecords = (state: RootState) =>
  state.managementTeam.totalRecords;
export const selectManagementTeamsPage = (state: RootState) =>
  state.managementTeam.page;
export const selectManagementTeamsPerPage = (state: RootState) =>
  state.managementTeam.perPage;
export const selectManagementTeamsSearchTerm = (state: RootState) =>
  state.managementTeam.searchTerm;
export const selectManagementTeamsSortByProperty = (state: RootState) =>
  state.managementTeam.sortByProperty;
export const selectManagementTeamsSortByDirection = (state: RootState) =>
  state.managementTeam.sortByDirection;
export const selectTriggerManagementTeamsUpdate = (state: RootState) =>
  state.managementTeam.triggerTeamsUpdate;
export const selectIsManagementTeamsDeleteConfirmationModalDisplayed = (
  state: RootState
) => state.managementTeam.isDeleteConfirmationModalDisplayed;
export const selectSelectedManagementTeamsOutsideOfCurrentPage = (
  state: RootState
) => state.managementTeam.selectedTeamsOutsideOfCurrentPage;

export default managementTeamSlice.reducer;
