import { Button } from 'components/Button';
import { Header } from 'components/Header';
import { Searchbar } from 'components/Searchbar';
import { VerticalDivider, FlexContainer } from 'styles/utils';
import { ReactComponent as CheckCircleSmaller } from 'assets/check-circle-smaller.svg';
import {
  selectFeedPickerFeedsSearchTerm,
  setFeedsSearchTerm,
} from 'store/feed/feed-picker-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { FeedList } from './components/FeedList';

interface FeedPickerProps {
  setIsCreateFeedModalDisplayed: React.Dispatch<boolean>;
  setIsFeedPickerDropdownOpen: React.Dispatch<boolean>;
}

export const FeedPicker = (props: FeedPickerProps) => {
  const dispatch = useAppDispatch();
  const searchTerm = useAppSelector(selectFeedPickerFeedsSearchTerm);

  return (
    <FlexContainer
      id="FeedPicker"
      flexDirection="column"
      style={{
        width: '100%',
      }}
    >
      <Header
        id="FeedPickerHeader"
        hasBorderTop
        hasBorderBottom
        hasBorderRight
        style={{ width: '100%' }}
      >
        <FlexContainer
          id="FeedPickerContainer"
          style={{ flex: '1 1 auto', alignItems: 'center' }}
        >
          <div
            id="FeedPickerSearchBarOuterContainer"
            style={{ flex: '1 1 auto' }}
          >
            <Searchbar
              id="FeedPickerSearchbar"
              value={searchTerm}
              setValue={(value) => {
                dispatch(setFeedsSearchTerm(value));
              }}
              placeholder="Search feeds..."
            />
          </div>
          <VerticalDivider height={'24px'} style={{ margin: '0 6px' }} />
          <Button
            id="FeedPickerCreateNewFeedButton"
            onClick={() => props.setIsCreateFeedModalDisplayed(true)}
          >
            <CheckCircleSmaller
              id="FeedPickerCheckCircleIcon"
              style={{ marginRight: '6px' }}
            />
            Create new feed
          </Button>
        </FlexContainer>
      </Header>
      <FeedList
        setIsFeedPickerDropdownOpen={props.setIsFeedPickerDropdownOpen}
      />
    </FlexContainer>
  );
};
