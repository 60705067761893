import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { Theme } from 'styles/themes';
import { FlexContainer, BoldText, CursorPointer } from 'styles/utils';
import { ReactComponent as ArrowDown } from 'assets/arrow-down.svg';
import {
  Dropdown,
  DropdownOptionsContainer,
  DropdownOption,
} from 'components/Dropdown';
import { HTMLAttrID } from 'models';
import { pascalCase } from 'utils/string-formatter';
import { Checkbox } from 'components/Checkbox';
import { EnvironmentsList } from 'models/reports/Source';
import styled from '@emotion/styled';

export interface DropdownProps extends HTMLAttrID {
  title: string;
  options: EnvironmentsList[];
  selectedIds: (ids: string) => void;
}

const CustomDropdownOptionsContainer = styled(DropdownOptionsContainer)`
  width: 264px;
  gap: 8px;
  padding: 8px 0;
`;

const CustomDropdownOption = styled(DropdownOption)`
  height: auto;
  padding: 16px;
`;

export const FilterDropdown = (props: DropdownProps) => {
  const dropdownContainerRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [checkedStates, setCheckedStates] = useState<{
    [key: number]: boolean;
  }>({});

  const trueCount = Object.values(checkedStates).filter(
    (value) => value === true
  ).length;

  const checkedIds = Object.entries(checkedStates)
    .filter(([key, value]) => value === true) // Filter entries where the value is true
    .map(([key]) => parseInt(key, 10)) // Convert keys to integers
    .join(','); // Join the array into a comma-separated string

  const handleCheckboxChange = (id: number) => {
    setCheckedStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  useEffect(() => {
    props.selectedIds(checkedIds);
  }, [handleCheckboxChange]);

  return (
    <FlexContainer
      style={{ gap: '8px' }}
      id={`${props.id}MainContainer`}
      ref={dropdownContainerRef}
    >
      <CursorPointer
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <BoldText
          id={`${props.id}Title`}
          style={{ display: 'flex', gap: '4px' }}
        >
          <span
            style={{
              fontSize: '16px',
              color: '#01416A',
            }}
          >
            {props.title}
          </span>
          <span style={{ color: '#0298F7' }}>({trueCount})</span>
        </BoldText>
      </CursorPointer>
      <div id={`${props.id}OuterContainer`} style={{ position: 'relative' }}>
        <CursorPointer onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
          <ArrowDown id={`${props.id}ArrowDown`} />
        </CursorPointer>
        <Dropdown
          id={props.id}
          isOpen={isDropdownOpen}
          setIsOpen={setIsDropdownOpen}
          containerRef={dropdownContainerRef}
          transform="translate(-94%, 41px)"
          style={{
            border: `1px solid ${Theme.colors.grayBorder}`,
            maxHeight: '450px',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <CustomDropdownOptionsContainer id={`${props.id}OptionsContainer`}>
            {props.options.map((option, index) => (
              <CustomDropdownOption
                id={`${props.id}${pascalCase(option.name)}Option`}
                key={option.name}
                removeDivider={true}
              >
                <Checkbox
                  key={option.id} // Ensure each checkbox has a unique key
                  id={`Filter${props.id}UserCheckboxInput`}
                  isChecked={checkedStates[option.id] || false} // Ensure it defaults to false if undefined
                  onChange={() => handleCheckboxChange(option.id)}
                  label={option.name}
                  style={{ gap: '4px' }}
                />
              </CustomDropdownOption>
            ))}
          </CustomDropdownOptionsContainer>
        </Dropdown>
      </div>
    </FlexContainer>
  );
};
