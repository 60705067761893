import styled from '@emotion/styled';
import { Theme } from 'styles/themes';

export const Card = styled.div({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: Theme.colors.white,
  borderRadius: Theme.borderRadius,
  boxShadow: '1px 1px 8px rgba(38, 38, 38, 0.1)',
  padding: '16px 15px',
});
