import styled from '@emotion/styled';
import { UnderlinedButton } from 'components/Button';
import { Sidebar } from 'components/Sidebar';
import { Theme } from 'styles/themes';
import { BoldText, HorizontalDivider } from 'styles/utils';
import { Development } from 'models';
import {
  Dispatch,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  resetDevelopment,
  resetDevelopmentDetailSearchTerm,
  selectSelectedHighlights,
  setDisplayNewDevelopmentVersionAvailableModal,
  setSelectedHighlights,
} from 'store/development-detail/development-detail-slice';
import { DateFormats, dateToUTCDateString } from 'utils/date';
import { developmentKeys } from 'config/development-keys';
import { DetailField } from './components/DetailField';
import {
  BlueChip,
  DropdownGreenChip,
  DropdownOrangeChip,
} from 'components/Chip';
import { DevelopmentVersion } from 'models/Development';
import { useOnClickOutside } from 'hooks/use-on-click-outside';
import { ReactComponent as Check } from 'assets/check.svg';
import { useNavigate } from 'react-router-dom';
import { routes } from 'navigation/routes';
import { OverflowYAutoContainer } from 'components/OverflowYAutoContainer';

export interface DetailLeftSidebarProps {
  development: Development | null;
  isDisplayed: boolean;
  setIsDisplayed: Dispatch<boolean>;
}

const DocumentDetailsContainer = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '0 15px',
});

const DocumentDetailsHeader = styled.div({
  width: '100%',
  display: 'flex',
  padding: '24px 15px 12px 15px',
});

const DocumentDetailsText = styled.span({
  fontSize: Theme.fontSize,
  color: Theme.colors.primaryColor,
});

const DevelopmentVersionsDropdownRow = (props: {
  developmentVersion: DevelopmentVersion;
  currentDevelopmentId: number;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const navigateToRelatedDevelopment = (id: number) => {
    if (id !== props.currentDevelopmentId) {
      dispatch(resetDevelopment());
      dispatch(resetDevelopmentDetailSearchTerm());
      dispatch(setDisplayNewDevelopmentVersionAvailableModal(false));
      navigate(routes.developmentDetail.replace(':developmentId', String(id)));
    }
  };

  return (
    <div
      style={{
        height: '40px',
        padding: '8px 16px 8px 16px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      onClick={() => navigateToRelatedDevelopment(props.developmentVersion.id)}
    >
      <BlueChip style={{ marginRight: '16px' }}>
        ID {props.developmentVersion.id}
      </BlueChip>
      <span>
        {dateToUTCDateString(
          props.developmentVersion.processingDate,
          DateFormats.PPp
        )}
      </span>
      {props.developmentVersion.id === props.currentDevelopmentId && (
        <Check
          color={Theme.colors.green}
          width={20}
          height={20}
          style={{ marginLeft: 'auto' }}
        />
      )}
    </div>
  );
};

const DevelopmentVersionsDropdownChip = (props: {
  isLatestVersion: boolean;
  developmentVersions: DevelopmentVersion[];
  currentDevelopmentId: number;
  children: ReactNode;
}) => {
  const [
    isDevelopmentVersionsDropdownDisplayed,
    setIsDevelopmentVersionsDropdownDisplayed,
  ] = useState(false);
  const toggleDevelopmentVersionsDropdown = () => {
    setIsDevelopmentVersionsDropdownDisplayed(
      !isDevelopmentVersionsDropdownDisplayed
    );
  };

  const developmentVersionsDropdownContainerRef = useRef(null);
  useOnClickOutside(developmentVersionsDropdownContainerRef, () => {
    setIsDevelopmentVersionsDropdownDisplayed(false);
  });

  const latestDevelopmentVersion =
    props.developmentVersions && props.developmentVersions.length
      ? props.developmentVersions.reduce((a, b) =>
          new Date(a.processingDate!) > new Date(b.processingDate!) ? a : b
        )
      : null;
  const developmentVersionsWithoutLatestDevelopmentVersion =
    props.developmentVersions.filter(
      (x) => x.id !== latestDevelopmentVersion?.id
    );

  return (
    <div
      style={{
        display: 'inline-flex',
      }}
      ref={developmentVersionsDropdownContainerRef}
    >
      {props.isLatestVersion ? (
        <DropdownGreenChip
          id="RelatedDevelopmentsDropdownChip"
          onClickDropdownButton={toggleDevelopmentVersionsDropdown}
          iconColor={Theme.colors.greenDark}
          noMargin
        >
          {props.children}
        </DropdownGreenChip>
      ) : (
        <DropdownOrangeChip
          id="RelatedDevelopmentsDropdownChip"
          onClickDropdownButton={toggleDevelopmentVersionsDropdown}
          iconColor={Theme.colors.orangeDark}
          noMargin
        >
          {props.children}
        </DropdownOrangeChip>
      )}
      {props.developmentVersions &&
        !!props.developmentVersions.length &&
        isDevelopmentVersionsDropdownDisplayed && (
          <OverflowYAutoContainer
            style={{
              position: 'absolute',
              borderRadius: Theme.borderRadius + 4,
              padding: '8px 0px 8px 0px',
              boxShadow: `0px 1px 15px ${Theme.colors.grayShadow}`,
              zIndex: 2,
              transform: 'translateY(36px)',
              backgroundColor: Theme.colors.white,
              width: '320px',
              maxHeight: '256px',
              fontSize: Theme.fontSize - 2,
            }}
          >
            {latestDevelopmentVersion && (
              <div>
                <div
                  style={{
                    height: '40px',
                    padding: '8px 16px 8px 16px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span>
                    <b>LATEST VERSION</b>
                  </span>
                </div>
                <DevelopmentVersionsDropdownRow
                  developmentVersion={latestDevelopmentVersion}
                  currentDevelopmentId={props.currentDevelopmentId}
                />
              </div>
            )}
            {developmentVersionsWithoutLatestDevelopmentVersion &&
              !!developmentVersionsWithoutLatestDevelopmentVersion.length && (
                <>
                  <HorizontalDivider
                    style={{
                      margin: '16px 8px',
                      width: 'calc(100% - 16px)',
                    }}
                  />
                  <div>
                    <div
                      style={{
                        height: '40px',
                        padding: '8px 16px 8px 16px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <span>
                        <b>OLDER VERSIONS</b>
                      </span>
                    </div>
                    {developmentVersionsWithoutLatestDevelopmentVersion.map(
                      (developmentVersion) => {
                        return (
                          <DevelopmentVersionsDropdownRow
                            key={developmentVersion.id}
                            developmentVersion={developmentVersion}
                            currentDevelopmentId={props.currentDevelopmentId}
                          />
                        );
                      }
                    )}
                  </div>
                </>
              )}
          </OverflowYAutoContainer>
        )}
    </div>
  );
};

export const DetailLeftSidebar: React.FC<DetailLeftSidebarProps> = (
  props: DetailLeftSidebarProps
) => {
  const FIELD_PLACEHOLDER_VALUE = 'Unable to detect';
  const dispatch = useAppDispatch();
  const [documentDetailsHeaderHeight, setDocumentDetailsHeaderHeight] =
    useState<number>(0);
  const selectedHighlights = useAppSelector(selectSelectedHighlights);

  const metadataHighlights = useMemo(() => {
    return [
      {
        key: developmentKeys.documentCitation,
        highlights: props.development?.documentCitationHighlights || [],
      },
    ];
  }, [props.development?.documentCitationHighlights]);

  const isMetadataClickable = useCallback(
    (selectedMetadataKey: keyof Development) => {
      const metadataHighlight = metadataHighlights.find(
        (x) => x.key === selectedMetadataKey
      );
      return !!(
        metadataHighlight &&
        metadataHighlight.highlights &&
        !!metadataHighlight.highlights.length
      );
    },
    [metadataHighlights]
  );

  const setSelectedMetadataHighlights = useCallback(
    (selectedMetadataKey: keyof Development) => {
      const metadataHighlight = metadataHighlights.find(
        (x) => x.key === selectedMetadataKey
      );
      if (
        metadataHighlight &&
        metadataHighlight.highlights &&
        metadataHighlight.highlights.length
      ) {
        if (
          JSON.stringify(metadataHighlight.highlights) !==
          JSON.stringify(selectedHighlights)
        ) {
          dispatch(setSelectedHighlights(metadataHighlight.highlights));
        } else {
          dispatch(setSelectedHighlights([]));
        }
      }
    },
    [metadataHighlights, JSON.stringify(selectedHighlights)]
  );

  const isPublicationDateClickable = useCallback(
    (publicationDateId: string) => {
      const publicationDateHighlights =
        props.development?.publicationDates?.find(
          (x) => String(x.id) === publicationDateId
        )?.highlights || [];
      return !!publicationDateHighlights.length;
    },
    [props.development?.publicationDates]
  );

  const handleSelectedPublicationDateHighlights = useCallback(
    (publicationDateId: string) => {
      const publicationDateHighlights =
        props.development?.publicationDates?.find(
          (x) => String(x.id) === publicationDateId
        )?.highlights || [];
      if (publicationDateHighlights.length) {
        if (
          JSON.stringify(publicationDateHighlights) !==
          JSON.stringify(selectedHighlights)
        ) {
          dispatch(setSelectedHighlights(publicationDateHighlights));
        } else {
          dispatch(setSelectedHighlights([]));
        }
      }
    },
    [props.development?.publicationDates, JSON.stringify(selectedHighlights)]
  );

  const isEffectiveDateClickable = useCallback(
    (effectiveDateId: string) => {
      const effectiveDateHighlights =
        props.development?.effectiveDates?.find(
          (x) => String(x.id) === effectiveDateId
        )?.highlights || [];
      return !!effectiveDateHighlights.length;
    },
    [props.development?.effectiveDates]
  );

  const handleSelectedEffectiveDateHighlights = useCallback(
    (effectiveDateId: string) => {
      const effectiveDateHighlights =
        props.development?.effectiveDates?.find(
          (x) => String(x.id) === effectiveDateId
        )?.highlights || [];
      if (effectiveDateHighlights.length) {
        if (
          JSON.stringify(effectiveDateHighlights) !==
          JSON.stringify(selectedHighlights)
        ) {
          dispatch(setSelectedHighlights(effectiveDateHighlights));
        } else {
          dispatch(setSelectedHighlights([]));
        }
      }
    },
    [props.development?.effectiveDates, JSON.stringify(selectedHighlights)]
  );

  const isComplianceDateClickable = useCallback(
    (complianceDateId: string) => {
      const complianceDateHighlights =
        props.development?.complianceDates?.find(
          (x) => String(x.id) === complianceDateId
        )?.highlights || [];
      return !!complianceDateHighlights.length;
    },
    [props.development?.complianceDates]
  );

  const handleSelectedComplianceDateHighlights = useCallback(
    (complianceDateId: string) => {
      const complianceDateHighlights =
        props.development?.complianceDates?.find(
          (x) => String(x.id) === complianceDateId
        )?.highlights || [];
      if (complianceDateHighlights.length) {
        if (
          JSON.stringify(complianceDateHighlights) !==
          JSON.stringify(selectedHighlights)
        ) {
          dispatch(setSelectedHighlights(complianceDateHighlights));
        } else {
          dispatch(setSelectedHighlights([]));
        }
      }
    },
    [props.development?.complianceDates, JSON.stringify(selectedHighlights)]
  );

  return (
    <>
      {props.isDisplayed && (
        <Sidebar
          id="DevelopmentDetailLeftSidebar"
          style={{
            backgroundColor: Theme.colors.secondaryBackgroundColor,
            minWidth: 'auto',
            flex: '0 0 25%',
          }}
          header={
            <DocumentDetailsHeader
              id="DocumentDetailsHeader"
              ref={(documentDetailsHeader) => {
                if (documentDetailsHeader) {
                  setDocumentDetailsHeaderHeight(
                    documentDetailsHeader.clientHeight
                  );
                }
              }}
            >
              <DocumentDetailsText id="DocumentDetailsText">
                <BoldText>Document details</BoldText>
              </DocumentDetailsText>
              <UnderlinedButton
                id="DocumentDetailsHideButton"
                color={Theme.colors.secondaryColor}
                style={{ marginLeft: 'auto' }}
                onClick={() => props.setIsDisplayed(false)}
              >
                Hide
              </UnderlinedButton>
            </DocumentDetailsHeader>
          }
          headerHeight={documentDetailsHeaderHeight}
        >
          <DocumentDetailsContainer id="DocumentDetailsContainer">
            <DetailField
              id="DocumentDetailsRelatedDevelopments"
              title={'Related developments'}
              items={[{ value: 'Version (Processed Date)' }]}
              customContent={
                <div>
                  {props.development && (
                    <DevelopmentVersionsDropdownChip
                      isLatestVersion={
                        props.development.isLatestVersion || false
                      }
                      developmentVersions={
                        props.development.developmentVersions || []
                      }
                      currentDevelopmentId={props.development.id!}
                    >
                      {dateToUTCDateString(
                        new Date(props.development.processingDate!),
                        DateFormats.PPp
                      )}
                    </DevelopmentVersionsDropdownChip>
                  )}
                </div>
              }
            />
            <DetailField
              id="DocumentDetailsAuthorityField"
              title={'Issuing Authority'}
              items={
                props.development?.developmentRows &&
                props.development.developmentRows.length
                  ? props.development.developmentRows.map((developmentRow) => ({
                      value:
                        developmentRow.row?.authority?.name ||
                        FIELD_PLACEHOLDER_VALUE,
                    }))
                  : [{ value: FIELD_PLACEHOLDER_VALUE }]
              }
            />
            <DetailField
              id="DocumentDetailsSubcategoryField"
              title={'Subcategory'}
              items={
                props.development?.developmentRows &&
                props.development.developmentRows.length
                  ? props.development.developmentRows.map((developmentRow) => ({
                      value:
                        developmentRow.subcategoryName ||
                        FIELD_PLACEHOLDER_VALUE,
                    }))
                  : [{ value: FIELD_PLACEHOLDER_VALUE }]
              }
            />
            <DetailField
              id="DocumentDetailsDocumentTypeField"
              title={'Document type'}
              items={
                props.development?.developmentRows &&
                props.development.developmentRows.length
                  ? props.development.developmentRows.map((developmentRow) => ({
                      value:
                        developmentRow.row?.documentTypeName ||
                        FIELD_PLACEHOLDER_VALUE,
                    }))
                  : [{ value: FIELD_PLACEHOLDER_VALUE }]
              }
            />
            <DetailField
              id="DocumentDetailsDocumentCitationField"
              title={'Document citation'}
              items={[
                {
                  value:
                    props.development?.documentCitation ||
                    FIELD_PLACEHOLDER_VALUE,
                  onClick: () => {
                    setSelectedMetadataHighlights(
                      developmentKeys.documentCitation
                    );
                  },
                  isClickable: isMetadataClickable(
                    developmentKeys.documentCitation
                  ),
                },
              ]}
            />
            <DetailField
              id="DocumentDetailsComplianceDateField"
              title={'Compliance date'}
              items={
                props.development?.complianceDates &&
                props.development.complianceDates.length
                  ? props.development.complianceDates.map((complianceDate) => ({
                      value: dateToUTCDateString(
                        new Date(complianceDate.complianceDate),
                        DateFormats.PP
                      ),
                      onClick: () => {
                        handleSelectedComplianceDateHighlights(
                          String(complianceDate.id)
                        );
                      },
                      isClickable: isComplianceDateClickable(
                        String(complianceDate.id)
                      ),
                    }))
                  : [{ value: FIELD_PLACEHOLDER_VALUE }]
              }
            />
            <DetailField
              id="DocumentDetailsEffectiveDateField"
              title={'Effective date'}
              items={
                props.development?.effectiveDates &&
                props.development.effectiveDates.length
                  ? props.development.effectiveDates.map((effectiveDate) => ({
                      value: dateToUTCDateString(
                        new Date(effectiveDate.effectiveDate),
                        DateFormats.PP
                      ),
                      onClick: () => {
                        handleSelectedEffectiveDateHighlights(
                          String(effectiveDate.id)
                        );
                      },
                      isClickable: isEffectiveDateClickable(
                        String(effectiveDate.id)
                      ),
                    }))
                  : [{ value: FIELD_PLACEHOLDER_VALUE }]
              }
            />
            <DetailField
              id="DocumentDetailsProcessedDateField"
              title={'Processed date'}
              items={
                props.development?.processingDate
                  ? [
                      {
                        value: dateToUTCDateString(
                          new Date(props.development.processingDate),
                          DateFormats.PPp
                        ),
                      },
                    ]
                  : [{ value: FIELD_PLACEHOLDER_VALUE }]
              }
            />
            <DetailField
              id="DocumentDetailsPublishedDateField"
              title={'Published date'}
              items={
                props.development?.publicationDates &&
                props.development.publicationDates.length
                  ? props.development.publicationDates.map(
                      (publicationDate) => ({
                        value: dateToUTCDateString(
                          new Date(publicationDate.publicationDate),
                          DateFormats.PP
                        ),
                        onClick: () => {
                          handleSelectedPublicationDateHighlights(
                            String(publicationDate.id)
                          );
                        },
                        isClickable: isPublicationDateClickable(
                          String(publicationDate.id)
                        ),
                      })
                    )
                  : [{ value: FIELD_PLACEHOLDER_VALUE }]
              }
            />
            <DetailField
              id="DocumentDetailsCountryField"
              title={'Jurisdiction'}
              items={[
                {
                  value:
                    props.development?.mainAuthority?.countryName ||
                    FIELD_PLACEHOLDER_VALUE,
                },
              ]}
            />
            <DetailField
              id="DocumentDetailsMatchVersion"
              title={'Match Version'}
              items={[
                {
                  value:
                    props.development?.autoMatchVersion ||
                    FIELD_PLACEHOLDER_VALUE,
                },
              ]}
            />
          </DocumentDetailsContainer>
        </Sidebar>
      )}
    </>
  );
};
