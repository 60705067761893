import {
  Dropdown,
  DropdownOption,
  DropdownOptionButton,
  DropdownOptionLabel,
  DropdownOptionsContainer,
} from 'components/Dropdown';
import { useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Theme } from 'styles/themes';
import { BoldText, CursorPointer, FlexContainer } from 'styles/utils';
import { ReactComponent as ArrowDown } from 'assets/arrow-down.svg';
import {
  selectReportByProperty,
  setReportByProperty,
} from 'store/performance-report/performance-report-slice';
import { PerformanceReportEntities } from 'enums';

export const reportByOptions = [
  {
    text: 'Jurisdiction',
    value: PerformanceReportEntities.Country,
    searchBarText: 'jurisdiction',
  },
  {
    text: 'Issuing Authority',
    value: PerformanceReportEntities.Authority,
    searchBarText: 'issuing authority',
  },
  {
    text: 'Document type',
    value: PerformanceReportEntities.DocumentType,
    searchBarText: 'document types',
  },
];

export const ReportByDropdown = () => {
  const dispatch = useAppDispatch();
  const reportByProperty = useAppSelector(selectReportByProperty);
  const [isReportByDropdownOpen, setIsReportByDropdownOpen] = useState(false);
  const reportByDropdownContainerRef = useRef(null);

  const reportByLabel = useMemo(() => {
    const option = reportByOptions.find((o) => o.value === reportByProperty);
    return option ? option.text : reportByOptions[0].text;
  }, [reportByOptions, reportByProperty]);

  return (
    <FlexContainer
      ref={reportByDropdownContainerRef}
      style={{ alignItems: 'center', userSelect: 'none' }}
    >
      <CursorPointer
        style={{ display: 'flex', margin: '0 8px 0 6px' }}
        onClick={() => setIsReportByDropdownOpen(!isReportByDropdownOpen)}
      >
        <BoldText>
          <span
            style={{ marginRight: '5px', color: Theme.colors.primaryColor }}
          >
            Report by
          </span>
          <span
            style={{
              color: Theme.colors.secondaryColor,
            }}
          >
            {reportByLabel}
          </span>
        </BoldText>
      </CursorPointer>
      <div style={{ position: 'relative' }}>
        <CursorPointer
          onClick={() => setIsReportByDropdownOpen(!isReportByDropdownOpen)}
        >
          <div style={{ display: 'flex' }}>
            <ArrowDown style={{ color: Theme.colors.grayDarker }} />
          </div>
        </CursorPointer>
        <Dropdown
          isOpen={isReportByDropdownOpen}
          setIsOpen={setIsReportByDropdownOpen}
          containerRef={reportByDropdownContainerRef}
          transform="translate(-100%, 25%)"
          style={{
            border: `1px solid ${Theme.colors.grayBorder}`,
            boxShadow: `0px 1px 15px ${Theme.colors.grayShadow}`,
          }}
        >
          <div style={{ width: '144px', color: Theme.colors.black }}>
            <FlexContainer flexDirection="column">
              <DropdownOptionsContainer style={{ width: '100%' }}>
                {reportByOptions.map((criteria, index) => {
                  const isFirst = index === 0;
                  const isLast = index + 1 === reportByOptions.length;
                  return (
                    <DropdownOption
                      key={index}
                      style={{ height: isFirst || isLast ? 46 : 38 }}
                    >
                      <DropdownOptionButton
                        onClick={() => {
                          setIsReportByDropdownOpen(false);
                          dispatch(setReportByProperty(criteria.value));
                        }}
                        style={{
                          paddingTop: isFirst ? 16 : 8,
                          paddingBottom: isLast ? 16 : 8,
                        }}
                      >
                        <DropdownOptionLabel style={{ height: 22, margin: 0 }}>
                          {criteria.text}
                        </DropdownOptionLabel>
                      </DropdownOptionButton>
                    </DropdownOption>
                  );
                })}
              </DropdownOptionsContainer>
            </FlexContainer>
          </div>
        </Dropdown>
      </div>
    </FlexContainer>
  );
};
