import styled from '@emotion/styled';
import { HTMLAttrID } from 'models';
import { useMemo } from 'react';
import { getCountryCodeFromName } from 'helpers/country';

interface CountryFlagIconProps extends HTMLAttrID {
  countryName: string;
  marginRight: number;
}

const CountryFlagIconContainer = styled.div<{ marginRight: number }>(
  (props) => ({
    display: 'flex',
    flex: `0 0 ${32 + props.marginRight}px`,
    height: '24px',
  })
);

export const CountryFlagIcon = (props: CountryFlagIconProps) => {
  const countryCode = useMemo(() => {
    return getCountryCodeFromName(props.countryName);
  }, [props.countryName]);
  const imageSource = useMemo(() => {
    return `http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`;
  }, [countryCode]);

  return countryCode ? (
    <CountryFlagIconContainer marginRight={props.marginRight}>
      <img
        id={props.id}
        src={imageSource}
        alt={props.countryName}
        style={{
          width: `calc(100% - ${props.marginRight}px)`,
          height: '100%',
          marginRight: props.marginRight,
        }}
      />
    </CountryFlagIconContainer>
  ) : (
    <></>
  );
};
