import { Header } from 'components/Header';
import { OverflowYAutoContainer } from 'components/OverflowYAutoContainer';
import { Theme } from 'styles/themes';
import { BoldText, FlexContainer } from 'styles/utils';
import {
  AddAuthorityAliasCard,
  SetStandardizedAuthorityNamesCard,
} from './components';

export const AdminAuthorities = () => {
  return (
    <>
      <FlexContainer
        flexDirection="column"
        style={{
          width: '100%',
          flex: 1,
          position: 'relative',
        }}
      >
        <Header hasBorderBottom style={{ width: '100%' }}>
          <BoldText style={{ fontSize: Theme.fontSize + 2 }}>
            Authorities Operations
          </BoldText>
        </Header>
        <OverflowYAutoContainer
          style={{
            backgroundColor: Theme.colors.primaryBackgroundColor,
            width: '100%',
            padding: '16px',
            height: 'calc(100% - 56px)',
          }}
        >
          <AddAuthorityAliasCard />
          <br />
          <SetStandardizedAuthorityNamesCard />
        </OverflowYAutoContainer>
      </FlexContainer>
    </>
  );
};
