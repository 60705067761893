export const teamManagementLabels = {
  title: 'Team management',
  createButtonLabel: 'Create new team',
  searchBarPlaceholder: 'Search teams...',
  countLabel: 'Teams',
  selectedCountLabel: 'Selected',
  dropdownLabel: 'Team options',
  placeholderTitle: 'Team details',
  placeholderDescriptionFirstLine: 'Select a team to display',
  placeholderDescriptionSecondLine: 'its details here',
};
