import { useMemo } from 'react';
import { FeedAppliedFiltersProps } from './interfaces';
import { buildFilterField, buildTitleField } from './helpers';

export const StaticFeedAppliedFilters = (props: FeedAppliedFiltersProps) => {
  const appliedFiltersTitle = useMemo(
    () =>
      buildTitleField(
        props.feed.filtersCount,
        props.isEdit,
        props.onClickEditFilters
      ),
    [props.feed.filtersCount, props.isEdit, props.onClickEditFilters]
  );

  const appliedFilters = useMemo(() => {
    const filterFields = [appliedFiltersTitle];
    if (props.feed.languageNames && props.feed.languageNames.length) {
      filterFields.push(buildFilterField('Language', props.feed.languageNames));
    }
    if (props.feed.regionNames && props.feed.regionNames.length) {
      filterFields.push(buildFilterField('Region', props.feed.regionNames));
    }
    if (props.feed.countryNames && props.feed.countryNames.length) {
      filterFields.push(buildFilterField('Country', props.feed.countryNames));
    }
    if (props.feed.authorityNames && props.feed.authorityNames.length) {
      filterFields.push(
        buildFilterField('Authority', props.feed.authorityNames)
      );
    }
    if (props.feed.subcategoryNames && props.feed.subcategoryNames.length) {
      filterFields.push(
        buildFilterField('Subcategory', props.feed.subcategoryNames)
      );
    }
    if (props.feed.documentTypeNames && props.feed.documentTypeNames.length) {
      filterFields.push(
        buildFilterField('Document type', props.feed.documentTypeNames)
      );
    }
    return filterFields;
  }, [
    appliedFiltersTitle,
    props.feed.languageNames,
    props.feed.regionNames,
    props.feed.countryNames,
    props.feed.authorityNames,
    props.feed.subcategoryNames,
    props.feed.documentTypeNames,
  ]);

  return <>{appliedFilters}</>;
};
