import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { DetailButton, ManagementDetail, TitleValue } from '../components';
import { ClosableBlueChip } from 'components/Chip';
import { Input } from 'components/Input';
import { Feed, Team, TeamFeed } from 'models';
import { Theme } from 'styles/themes';
import { FlexContainer } from 'styles/utils';
import { ReactComponent as CancelIcon } from 'assets/cancel.svg';
import { ReactComponent as ConfirmIcon } from 'assets/confirm-edit.svg';
import { ReactComponent as AddIcon } from 'assets/plus-circle.svg';
import { useOnClickOutside } from 'hooks/use-on-click-outside';
import { FeedAppliedFilters } from './components/FeedAppliedFilters';
import { ManagementSectionProps } from '../components/ManagementDetail';

export const FeedEdit = ({
  feed,
  containerRef,
  updatedFeedFields,
  addedTeams,
  onClickAddTeamsButton,
  onClickEditFiltersButton,
  resetAddedTeams,
  resetUpdatedFeedFields,
  onAttemptToCancel,
  onSubmit,
}: FeedEditProps) => {
  useOnClickOutside(containerRef, onAttemptToCancel);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean | undefined>(undefined);
  const [isEditingFilters, setIsEditingFilters] = useState<boolean>(false);
  const [name, setName] = useState<string>(feed.name ?? '');
  const [languageIds, setLanguageIds] = useState<number[]>(
    feed.languageIds ?? []
  );
  const [regionIds, setRegionIds] = useState<number[]>(feed.regionIds ?? []);
  const [countryIds, setCountryIds] = useState<number[]>(feed.countryIds ?? []);
  const [authorityIds, setAuthorityIds] = useState<number[]>(
    feed.authorityIds ?? []
  );
  const [subcategoryIds, setSubcategoryIds] = useState<number[]>(
    feed.subcategoryIds ?? []
  );
  const [documentTypeIds, setDocumentTypeIds] = useState<number[]>(
    feed.documentTypeIds ?? []
  );
  const [teamFeeds, setTeamFeeds] = useState<TeamFeed[]>(feed.teamFeeds ?? []);

  const canSubmit = isDirty && name;

  useEffect(() => {
    if (isDirty === undefined) {
      setIsDirty(false);
    } else {
      setIsDirty(true);
    }
  }, [
    name,
    teamFeeds,
    languageIds,
    regionIds,
    countryIds,
    authorityIds,
    subcategoryIds,
    documentTypeIds,
  ]);

  useEffect(() => {
    if (updatedFeedFields) {
      setName(updatedFeedFields.name);
      setLanguageIds(updatedFeedFields.languageIds);
      setRegionIds(updatedFeedFields.regionIds);
      setCountryIds(updatedFeedFields.countryIds);
      setAuthorityIds(updatedFeedFields.authorityIds);
      setSubcategoryIds(updatedFeedFields.subcategoryIds);
      setDocumentTypeIds(updatedFeedFields.documentTypeIds);
      resetUpdatedFeedFields();
    }
  }, [updatedFeedFields]);

  useEffect(() => {
    if (addedTeams.length) {
      const currentTeamIds = teamFeeds.map((tf) => tf.teamId);
      const finalAddedTeamFeeds = addedTeams
        .filter((t) => !currentTeamIds.includes(Number(t.id)))
        .map(
          (t) =>
            ({
              teamId: Number(t.id),
              teamName: t.name,
              feedId: Number(feed.id),
            } as TeamFeed)
        );
      setTeamFeeds(teamFeeds.concat(finalAddedTeamFeeds));
      resetAddedTeams();
    }
  }, [addedTeams]);

  const handleRemoveTeamFeed = useCallback(
    (teamFeed: TeamFeed, from: TeamFeed[]) => {
      const newTeamFeeds = from.filter(
        (tf: TeamFeed) => tf.teamId !== teamFeed.teamId
      );
      setTeamFeeds(newTeamFeeds);
    },
    []
  );

  const onClickEditFilters = useCallback(() => {
    const currentFeed: Feed = {
      ...feed,
      name,
      languageIds,
      regionIds,
      countryIds,
      authorityIds: authorityIds,
      subcategoryIds,
      documentTypeIds,
      teamFeeds,
    };
    onClickEditFiltersButton(currentFeed);
    setIsEditingFilters(true);
  }, [
    feed,
    name,
    teamFeeds,
    languageIds,
    regionIds,
    countryIds,
    subcategoryIds,
    authorityIds,
    documentTypeIds,
  ]);

  const handleConfirmEdit = useCallback(async () => {
    if (canSubmit) {
      setIsLoading(true);
      const namedTeamFeeds: TeamFeed[] = teamFeeds.map((tf) => ({
        ...tf,
        name,
      }));
      const editedFeed: Feed = {
        ...feed,
        name,
        languageIds,
        regionIds,
        countryIds,
        authorityIds: authorityIds,
        subcategoryIds,
        documentTypeIds,
        teamFeeds: namedTeamFeeds,
      };
      await onSubmit(editedFeed);
      setIsLoading(false);
    }
  }, [
    canSubmit,
    name,
    teamFeeds,
    languageIds,
    regionIds,
    countryIds,
    authorityIds,
    subcategoryIds,
    documentTypeIds,
  ]);

  const filtersCount =
    languageIds.length +
    regionIds.length +
    countryIds.length +
    authorityIds.length +
    subcategoryIds.length +
    documentTypeIds.length;

  const teamList = useMemo(
    () =>
      teamFeeds.length ? (
        <FlexContainer
          id="FeedManagementEditTeamList"
          style={{ marginTop: '16px', gap: '8px' }}
        >
          {teamFeeds.map((tf) => (
            <ClosableBlueChip
              id={`FeedManagementEditTeamListBlueChip${tf.teamId}`}
              key={tf.teamId}
              isSelected
              onClickCloseButton={() => handleRemoveTeamFeed(tf, teamFeeds)}
              style={{
                maxHeight: '28px',
              }}
            >
              {tf.teamName}
            </ClosableBlueChip>
          ))}
        </FlexContainer>
      ) : (
        <FlexContainer id="FeedManagementEditTeamList" />
      ),
    [teamFeeds]
  );

  const sections: ManagementSectionProps[] = [
    {
      children: [
        <TitleValue
          id="FeedManagementEditName"
          key="EditName"
          title="Name"
          value={
            <Input
              id="id-edit-name"
              placeholder="Enter name..."
              value={name}
              setValue={setName}
              style={{
                marginTop: '4px',
                borderColor: name ? Theme.colors.grayBorder : Theme.colors.red,
              }}
            />
          }
        />,
      ],
    },
    {
      children: (
        <FeedAppliedFilters
          key="AppliedFilters"
          feed={{
            ...feed,
            languageIds,
            regionIds,
            countryIds,
            authorityIds: authorityIds,
            subcategoryIds,
            documentTypeIds,
            filtersCount,
          }}
          isEdit
          onClickEditFilters={onClickEditFilters}
          withDynamicMapping={isEditingFilters}
        />
      ),
    },
    {
      children: [
        <TitleValue
          id="FeedManagementEditTeams"
          key="EditTeams"
          title="Assigned to"
          count={teamFeeds.length}
          buttonIcon={<AddIcon />}
          onClickButton={onClickAddTeamsButton}
          value={teamList}
        />,
      ],
    },
  ];

  const buttons = [
    <DetailButton
      id="FeedManagementEditCancelButton"
      key="Cancel"
      label="Cancel"
      icon={<CancelIcon />}
      color={Theme.colors.red}
      onClick={onAttemptToCancel}
      removeDivider
    />,
    <DetailButton
      id="FeedManagementEditConfirmEditButton"
      key="ConfirmEdit"
      label="Confirm edit"
      icon={<ConfirmIcon />}
      color={Theme.colors.secondaryColor}
      onClick={canSubmit ? handleConfirmEdit : undefined}
      isDisabled={!canSubmit}
    />,
  ];

  return (
    <ManagementDetail
      id="FeedManagementEdit"
      sections={sections}
      buttons={buttons}
      isLoading={isLoading}
    />
  );
};

interface FeedEditProps {
  feed: Feed;
  containerRef: MutableRefObject<any>;
  updatedFeedFields: Feed | null;
  addedTeams: Team[];
  onClickAddTeamsButton: () => void;
  onClickEditFiltersButton: (feed?: Feed) => void;
  resetAddedTeams: () => void;
  resetUpdatedFeedFields: () => void;
  onAttemptToCancel: () => void;
  onSubmit: (feed: Feed) => Promise<void>;
}
