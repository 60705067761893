import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FlexContainer, HorizontalDivider } from 'styles/utils';
import { Theme } from 'styles/themes';
import {
  ReportsHeader,
  ReportsSubHeader,
  ReportsSubHeaderSubTitle,
  ReportsMainBody,
} from 'pages/reports/components';
import { turnaroundTimeReportLabels } from './labels';
import { PerformanceReportEntities } from 'enums';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getEntityDetailsAsync,
  resetCustomDateRangeInMilliseconds,
  resetTurnaroundTimeDetailedData,
  resetTurnaroundTimeReportPage,
  selectCustomDateRangeInMilliseconds,
  selectEntityDetails,
  selectPeriodProperty,
  selectReportByProperty,
  selectTriggerGetEntityDetailsAsync,
  selectTurnaroundTimeDetailedData,
  setCustomDateRangeInMilliseconds,
  setPeriodProperty,
  setReportByProperty,
} from 'store/turnaround-time-report/turnaround-time-report-slice';
import {
  selectFilterPickerEntries,
  setTurnaroundTimeActiveFilters,
  updateTurnaroundTimeActiveFilters,
  setSomeTurnaroundTimeActiveFilters,
} from 'store/turnaround-time-report/turnaround-time-filter-slice';
import {
  clearActiveFilters,
  setActiveFilters,
  skipSetAllActiveFiltersOnce,
} from 'store/home-sidebar-filter/home-sidebar-filter-slice';
import { setIsConsecutiveDocumentTypesFetch } from 'store/home-sidebar-filter/home-sidebar-filter-document-type-slice';
import { setIsConsecutiveLanguagesFetch } from 'store/home-sidebar-filter/home-sidebar-filter-language-slice';
import { setIsConsecutiveRegionsFetch } from 'store/home-sidebar-filter/home-sidebar-filter-region-slice';
import { setIsConsecutiveCountriesFetch } from 'store/home-sidebar-filter/home-sidebar-filter-country-slice';
import { setIsConsecutiveAuthoritiesFetch } from 'store/home-sidebar-filter/home-sidebar-filter-authority-slice';
import { setIsConsecutiveSubcategoriesFetch } from 'store/home-sidebar-filter/home-sidebar-filter-subcategory-slice';
import { Loading } from 'components/Loading';
import { TurnaroundTimeDetailsTable } from './components/TurnaroundTimeDetailsTable';
import { reportByOptions } from '../performance/report-by-options';
import { CountryFlagIcon } from 'components/CountryFlagIcon';
import { TurnaroundTimeDetailsSummary } from './components/TurnaroundTimeDetailsSummary';
import { OverflowYAutoContainer } from 'components/OverflowYAutoContainer';
import { DatePickerModal } from 'components/DatePicker';
import { periodOptions } from 'config/report-period-options';
import { routes } from 'navigation/routes';
import {
  DateRange,
  dateRangeInMillisecondsToDateRange,
  dateRangeInMillisecondsToString,
  dateRangeToNormalizedDateRangeInMilliseconds,
  dateRangeToNormalizedString,
  dateRangeToString,
  getDaysBetweenDates,
  getPreviousDateRange,
  getSubtractionFromDateInMilliseconds,
  dateRangeInMillisecondsStringToDateRange,
} from 'utils/date';
import { ActiveFilters, FilterEntry, FilterEntryValue } from 'models';
import {
  TurnaroundTimeDetailsFilterChipsPool,
  TurnaroundTimeDetailsFilterPicker,
} from './components/TurnaroundTimeDetailsFilters';
import { filterKeys } from 'config/filter-keys';
import { developmentKeys } from 'config/development-keys';

export const TurnaroundTimeDetails = () => {
  const { entityType, id } = useParams<{ entityType: string; id: string }>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [scrollableContainer, setScrollableContainer] =
    useState<HTMLDivElement | null>(null);
  const reportByProperty = useAppSelector(selectReportByProperty);
  const periodProperty = useAppSelector(selectPeriodProperty);
  const customDateRangeInMilliseconds = useAppSelector(
    selectCustomDateRangeInMilliseconds
  );
  const turnaroundTimeDetailedData = useAppSelector(
    selectTurnaroundTimeDetailedData
  );
  const entityDetails = useAppSelector(selectEntityDetails);
  const filterPickerEntries = useAppSelector(selectFilterPickerEntries);
  const triggerGetEntityDetailsAsync = useAppSelector(
    selectTriggerGetEntityDetailsAsync
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isDatePickerDisplayed, setIsDatePickerDisplayed] = useState(false);
  const [rangePeriodText, setRangePeriodText] = useState<string | null>(null);
  const [previousRangePeriodText, setPreviousRangePeriodText] = useState<
    string | null
  >(null);

  const reportByOptionEntity = useMemo(() => {
    return Object.values(reportByOptions).find((v) => v.urlText === entityType);
  }, [entityType]);

  useEffect(() => {
    if (reportByOptionEntity) {
      dispatch(setReportByProperty(reportByOptionEntity.value));
    }
    dispatch(clearActiveFilters());
    dispatch(
      updateTurnaroundTimeActiveFilters({
        propertyToFilterBy: developmentKeys.processingDate,
        value: '',
        replace: true,
      })
    );
    dispatch(
      setSomeTurnaroundTimeActiveFilters([
        {
          propertyToFilterBy: filterKeys.isWithinRequiredTurnaroundTime,
          values: [],
        },
        {
          propertyToFilterBy: developmentKeys.pageCount,
          values: [],
        },
        {
          propertyToFilterBy: developmentKeys.exceptionFlag,
          values: [],
        },
      ])
    );
  }, []);

  useEffect(() => {
    const periodParam = searchParams.get('period');
    const filtersParam = searchParams.get('filterEntries');
    const isPreviousParam = searchParams.get('isPreviousPeriod');
    if (periodParam) {
      if (periodOptions[periodParam]) {
        dispatch(resetCustomDateRangeInMilliseconds());
        dispatch(setPeriodProperty(periodParam));
      } else {
        const dateRange = dateRangeInMillisecondsStringToDateRange(periodParam);
        if (dateRange) {
          dispatch(
            setCustomDateRangeInMilliseconds(
              dateRangeToNormalizedDateRangeInMilliseconds(dateRange)
            )
          );
        }
      }
    } else {
      if (customDateRangeInMilliseconds) {
        setSearchParams({
          period: dateRangeInMillisecondsToString(
            customDateRangeInMilliseconds
          ),
        });
      } else {
        setSearchParams({ period: periodProperty });
      }
    }
    if (filtersParam) {
      const filterEntries = JSON.parse(
        decodeURIComponent(filtersParam)
      ) as FilterEntry[];
      searchParams.delete('filterEntries');
      searchParams.delete('isPreviousPeriod');
      handleClickTableCell(filterEntries, isPreviousParam === 'true');
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (customDateRangeInMilliseconds) {
      setSearchParams({
        period: dateRangeInMillisecondsToString(customDateRangeInMilliseconds),
      });
    } else {
      setSearchParams({ period: periodProperty });
    }
    dispatch(
      getEntityDetailsAsync(Number(id), reportByOptionEntity!.value)
    ).then(() => setIsLoading(false));
  }, [
    id,
    entityType,
    periodProperty,
    customDateRangeInMilliseconds,
    triggerGetEntityDetailsAsync,
  ]);

  useEffect(() => {
    if (customDateRangeInMilliseconds) {
      const customDateRange = dateRangeInMillisecondsToDateRange(
        customDateRangeInMilliseconds
      );
      setRangePeriodText(dateRangeToNormalizedString(customDateRange));
      setPreviousRangePeriodText(
        dateRangeToNormalizedString(getPreviousDateRange(customDateRange))
      );
    } else {
      setRangePeriodText(null);
      setPreviousRangePeriodText(null);
    }
  }, [customDateRangeInMilliseconds]);

  useEffect(() => {
    return () => {
      dispatch(resetTurnaroundTimeReportPage());
      dispatch(resetTurnaroundTimeDetailedData());
    };
  }, []);

  const pediodLabel = useMemo(() => {
    const option = periodOptions[periodProperty];
    const optionLabel = option ? option.text : periodOptions.sevenDays.text;
    const firstLabelWord = optionLabel.split(' ')[0];
    return firstLabelWord === '1' ? optionLabel.replace('1 ', '') : optionLabel;
  }, [periodOptions, periodProperty]);

  const isByCountry = useMemo(
    () => reportByProperty === PerformanceReportEntities.Country,
    [reportByProperty]
  );

  const countryTitleElement = useMemo(
    () =>
      isByCountry && entityDetails ? (
        <FlexContainer
          title={entityDetails.name}
          style={{ alignItems: 'center' }}
        >
          <CountryFlagIcon countryName={entityDetails.name} marginRight={12} />
          <span
            style={{ fontSize: '20px', marginTop: '4px', fontWeight: 'bold' }}
          >
            {entityDetails.name}
          </span>
        </FlexContainer>
      ) : undefined,
    [isByCountry, entityDetails]
  );

  const rangePeriodTextElement = useMemo(
    () =>
      rangePeriodText ? (
        <ReportsSubHeaderSubTitle>
          <span>{`${turnaroundTimeReportLabels.subTitle} (TaT): `}</span>
          <span style={{ color: Theme.colors.secondaryColor }}>
            {`(${rangePeriodText})`}
          </span>
        </ReportsSubHeaderSubTitle>
      ) : undefined,
    [rangePeriodText]
  );

  const previousRangePeriodTextElement = useMemo(() => {
    if (!previousRangePeriodText) {
      return undefined;
    }
    const rangeDaysDiff = getDaysBetweenDates(
      customDateRangeInMilliseconds?.startDate,
      customDateRangeInMilliseconds?.endDate,
      true
    );
    return (
      <ReportsSubHeaderSubTitle>
        <span>{`Previous ${
          rangeDaysDiff > 1 ? `${rangeDaysDiff} days` : 'day'
        }: `}</span>
        <span style={{ color: Theme.colors.secondaryColor }}>
          {`(${previousRangePeriodText})`}
        </span>
      </ReportsSubHeaderSubTitle>
    );
  }, [previousRangePeriodText, customDateRangeInMilliseconds]);

  const currentPeriodDateFilter = useMemo(() => {
    let periodDateFilter: string = '';
    if (customDateRangeInMilliseconds) {
      periodDateFilter = dateRangeInMillisecondsToString(
        customDateRangeInMilliseconds
      );
    } else {
      const currentDate = new Date();
      const periodPropertyInMilliseconds = getSubtractionFromDateInMilliseconds(
        currentDate,
        periodOptions[periodProperty].durationObject
      );
      periodDateFilter = dateRangeInMillisecondsToString({
        startDate: periodPropertyInMilliseconds,
        endDate: currentDate.getTime(),
      });
    }
    return periodDateFilter;
  }, [customDateRangeInMilliseconds, periodProperty]);

  const previousPeriodDateFilter = useMemo(() => {
    let periodDateFilter: string = '';
    if (customDateRangeInMilliseconds) {
      periodDateFilter = dateRangeToString(
        getPreviousDateRange(
          dateRangeInMillisecondsToDateRange(customDateRangeInMilliseconds)
        )
      );
    } else {
      const currentDate = new Date();
      const periodPropertyInMilliseconds = getSubtractionFromDateInMilliseconds(
        currentDate,
        periodOptions[periodProperty].durationObject
      );
      const previousPeriodPropertyInMilliseconds =
        periodPropertyInMilliseconds -
        (currentDate.getTime() - periodPropertyInMilliseconds);
      periodDateFilter = dateRangeInMillisecondsToString({
        startDate: previousPeriodPropertyInMilliseconds,
        endDate: periodPropertyInMilliseconds,
      });
    }
    return periodDateFilter;
  }, [customDateRangeInMilliseconds, periodProperty]);

  const handleSetPeriod = (period: string) => {
    dispatch(resetCustomDateRangeInMilliseconds());
    dispatch(setPeriodProperty(period));
  };

  const handleResetPage = () => {
    dispatch(resetTurnaroundTimeReportPage());
  };

  const handleGoBack = () => {
    navigate(routes.turnaroundTimeReport);
  };

  const handleOpenDatePicker = () => {
    setIsDatePickerDisplayed(true);
  };

  const handleCloseDatePicker = () => {
    setIsDatePickerDisplayed(false);
  };

  const handleConfirmDatePicker = (range: Partial<DateRange>) => {
    dispatch(
      setCustomDateRangeInMilliseconds(
        dateRangeToNormalizedDateRangeInMilliseconds(range)
      )
    );
    handleCloseDatePicker();
  };

  const handleClickTableCell = useCallback(
    (
      filterEntries: FilterEntry[],
      isFromPreviousPeriod?: boolean,
      isForNewTab?: boolean
    ) => {
      const allFilterEntries = [...filterEntries, ...filterPickerEntries];
      if (isForNewTab) {
        const filterEntriesString = encodeURIComponent(
          JSON.stringify(allFilterEntries)
        );
        const url =
          window.location.href +
          `&isPreviousPeriod=${
            isFromPreviousPeriod ? 'true' : 'false'
          }&filterEntries=${filterEntriesString}`;
        setTimeout(() => window.open(url, '_blank', 'noreferrer'));
        return;
      }
      const filterEntryValues: { [x: string]: FilterEntryValue[] } = {
        [filterKeys.country]: [],
        [filterKeys.authority]: [],
        [filterKeys.subcategory]: [],
        [filterKeys.documentType]: [],
        [filterKeys.isWithinRequiredTurnaroundTime]: [],
        [developmentKeys.pageCount]: [],
        [developmentKeys.exceptionFlag]: [],
      };
      const entityTypeOption = Object.values(reportByOptions).find(
        (option) => option.urlText === entityType
      );
      if (entityTypeOption?.value === PerformanceReportEntities.Country) {
        filterEntryValues[filterKeys.country] = [Number(id)];
        allFilterEntries.forEach((filterEntry) => {
          if (filterEntry.propertyToFilterByOverride) {
            filterEntryValues[filterEntry.propertyToFilterByOverride].push(
              filterEntry.value
            );
          }
        });
      } else {
        if (entityTypeOption && id) {
          filterEntryValues[entityTypeOption.queryParam] = [Number(id)];
        }
        filterEntries.forEach((filterEntry) => {
          filterEntryValues[filterEntry.propertyToFilterByOverride!].push(
            filterEntry.value
          );
        });
      }
      const newActiveFilters: ActiveFilters = {
        ...filterEntryValues,
        [developmentKeys.processingDate]: isFromPreviousPeriod
          ? previousPeriodDateFilter
          : currentPeriodDateFilter,
      };
      dispatch(setTurnaroundTimeActiveFilters(newActiveFilters));
      dispatch(setActiveFilters(newActiveFilters));
      dispatch(setIsConsecutiveLanguagesFetch(true));
      dispatch(setIsConsecutiveRegionsFetch(true));
      dispatch(setIsConsecutiveCountriesFetch(true));
      dispatch(setIsConsecutiveAuthoritiesFetch(true));
      dispatch(setIsConsecutiveSubcategoriesFetch(true));
      dispatch(setIsConsecutiveDocumentTypesFetch(true));
      dispatch(skipSetAllActiveFiltersOnce());
      setTimeout(() => navigate(routes.home));
    },
    [
      entityType,
      id,
      currentPeriodDateFilter,
      previousPeriodDateFilter,
      filterPickerEntries,
    ]
  );

  return (
    <>
      <FlexContainer
        flexWrap="nowrap"
        flexDirection="column"
        style={{
          width: '100%',
          flex: 1,
          overflowX: 'hidden',
          padding: '0 16px',
        }}
      >
        <ReportsHeader
          breadcrumb={[{ text: `${turnaroundTimeReportLabels.title}:` }]}
          onClickBack={handleGoBack}
        />
        <div style={{ padding: '0 16px' }}>
          <HorizontalDivider />
        </div>
        <ReportsSubHeader
          {...turnaroundTimeReportLabels}
          subTitle={entityDetails?.name || ''}
          periodProps={{
            period: customDateRangeInMilliseconds ? 'Custom' : periodProperty,
            setPeriod: handleSetPeriod,
            onSelectPeriod: handleResetPage,
            onSelectCustomPeriod: handleOpenDatePicker,
          }}
          hasNoBorders
        >
          {countryTitleElement}
        </ReportsSubHeader>
        <div style={{ padding: '0 16px' }}>
          <HorizontalDivider />
        </div>
        <ReportsMainBody
          style={{
            padding: 0,
            backgroundColor: 'white',
          }}
        >
          <OverflowYAutoContainer
            ref={setScrollableContainer}
            style={{
              height: 'calc(100vh - 160px)',
              width: '100%',
            }}
          >
            {scrollableContainer && (
              <FlexContainer flexDirection="column">
                {isLoading && <Loading />}
                <ReportsSubHeader
                  {...turnaroundTimeReportLabels}
                  subTitle={`${turnaroundTimeReportLabels.subTitle} (TaT): Last ${pediodLabel}`}
                  hasNoBorders
                  totalDevelopments={
                    turnaroundTimeDetailedData.detailedReportDataByPeriods
                      .currentPeriodReportData.totalDevelopments
                  }
                >
                  {rangePeriodTextElement}
                </ReportsSubHeader>
                {isByCountry &&
                  entityDetails?.id &&
                  currentPeriodDateFilter && (
                    <>
                      <ReportsSubHeader
                        {...turnaroundTimeReportLabels}
                        hasNoBorders
                      >
                        <TurnaroundTimeDetailsFilterPicker
                          countryId={entityDetails.id}
                          dateFilter={currentPeriodDateFilter}
                        />
                      </ReportsSubHeader>
                      <ReportsSubHeader
                        {...turnaroundTimeReportLabels}
                        hasNoBorders
                      >
                        <TurnaroundTimeDetailsFilterChipsPool />
                      </ReportsSubHeader>
                    </>
                  )}
                <TurnaroundTimeDetailsSummary
                  data={turnaroundTimeDetailedData}
                  isLoading={false}
                />
                <TurnaroundTimeDetailsTable
                  data={
                    turnaroundTimeDetailedData.detailedReportDataByPeriods
                      .currentPeriodReportData
                  }
                  onClickCell={handleClickTableCell}
                />
                <ReportsSubHeader
                  {...turnaroundTimeReportLabels}
                  subTitle={`Previous ${pediodLabel}`}
                  hasNoBorders
                >
                  {previousRangePeriodTextElement}
                </ReportsSubHeader>
                <TurnaroundTimeDetailsTable
                  data={
                    turnaroundTimeDetailedData.detailedReportDataByPeriods
                      .previousPeriodReportData
                  }
                  onClickCell={handleClickTableCell}
                  isPreviousPeriod
                />
              </FlexContainer>
            )}
          </OverflowYAutoContainer>
        </ReportsMainBody>
      </FlexContainer>
      <DatePickerModal
        isOpen={isDatePickerDisplayed}
        onChange={() => {}}
        onConfirm={handleConfirmDatePicker}
        onCancel={handleCloseDatePicker}
      />
    </>
  );
};
