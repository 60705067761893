import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { Body, EllipsedSpan } from 'components/Typography';
import { DateFormats, dateToUTCDateString } from 'utils/date';
import { DevelopmentRow } from 'models';
import { useCallback } from 'react';
import { useAppSelector } from 'store/hooks';
import {
  selectDevelopmentsSearchProperties,
  selectDevelopmentsSearchTerm,
} from 'store/development/development-slice';
import { developmentKeys } from 'config/development-keys';
import { FlexContainer } from 'styles/utils';
import { GreenChip, OrangeChip } from 'components/Chip';

interface DevelopmentCardRightSideUpperSideLeftSideProps {
  developmentId?: number;
  title: string;
  developmentRows: DevelopmentRow[];
  citationsCount?: number;
  publicationDate?: string;
  processingDate?: string;
  country: string;
  hasDevelopmentVersions?: boolean;
  isLatestVersion?: boolean;
}

const DevelopmentCardRightSideUpperSideLeftSideContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 0 16px 16px',
  overflow: 'hidden',
  width: 'calc(100% - 108px)',
  gap: '8px',
});

const DevelopmentTitleContainer = styled.div({
  fontWeight: 'bold',
  fontSize: Theme.fontSize + 2,
  lineHeight: '25px',
  display: 'flex',
  alignItems: 'center',
});

const DevelopmentField = styled.div({
  fontWeight: 'normal',
  fontSize: Theme.fontSize,
  minHeight: '22px',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '4px',
  flexDirection: 'column',
});

export const DevelopmentCardRightSideUpperSideLeftSide = (
  props: DevelopmentCardRightSideUpperSideLeftSideProps
) => {
  const searchProperties = useAppSelector(selectDevelopmentsSearchProperties);
  const searchTerm = useAppSelector(selectDevelopmentsSearchTerm);
  const isRightPanelShow =
    process.env.REACT_APP_IS_CITATION_RIGHT_PANEL_SHOW === 'true';
  const developmentRows = props.developmentRows;
  const citationsCount = props.citationsCount || 0;
  const processingDate = props.processingDate
    ? 'Processed ' +
      dateToUTCDateString(new Date(props.processingDate), DateFormats.PPp)
    : 'No available processed date';
  const publishedDate = props.publicationDate
    ? 'Published ' +
      dateToUTCDateString(new Date(props.publicationDate), DateFormats.PP)
    : 'No available published date';

  const highlightText = useCallback((text: string, term: string) => {
    const escapeRegExp = (str: string) =>
      str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const escapedTerm = escapeRegExp(term);
    const regExp = new RegExp(escapedTerm, 'gi');
    return text.replace(
      regExp,
      `<span style="background-color: ${Theme.colors.orangeLight};">$&</span>`
    );
  }, []);

  const highlightTitle = useCallback(
    (title: string) => {
      if (searchTerm && searchProperties.includes(developmentKeys.title)) {
        return highlightText(title, searchTerm);
      }
      return title;
    },
    [JSON.stringify(searchProperties), searchTerm]
  );

  const highlightAuthority = useCallback(
    (authority: string = 'N/A') => {
      if (
        authority &&
        searchTerm &&
        searchProperties.includes(
          `${developmentKeys.authorityAliasesAuthorities}.name`
        )
      ) {
        return highlightText(authority, searchTerm);
      }
      return authority;
    },
    [JSON.stringify(searchProperties), searchTerm]
  );

  const highlightDocumentType = useCallback(
    (documentType: string = 'N/A') => {
      if (
        documentType &&
        searchTerm &&
        searchProperties.includes(`developmentRows.row.documentTypeName`)
      ) {
        return highlightText(documentType, searchTerm);
      }
      return documentType;
    },
    [JSON.stringify(searchProperties), searchTerm]
  );

  return (
    <DevelopmentCardRightSideUpperSideLeftSideContainer
      id={`DevelopmentCardRightSideUpperSideLeftSideContainer${props.developmentId}`}
    >
      <DevelopmentTitleContainer
        id={`DevelopmentCardRightSideUpperSideLeftSideTitle${props.developmentId}`}
      >
        <EllipsedSpan
          id={`DevelopmentCardRightSideUpperSideLeftSideTitleSpan${props.developmentId}`}
          title={props.title}
          style={{
            fontWeight: 'bold',
          }}
          noMargin
        >
          <span
            dangerouslySetInnerHTML={{
              __html: highlightTitle(props.title),
            }}
          ></span>
        </EllipsedSpan>
      </DevelopmentTitleContainer>
      <FlexContainer style={{ gap: '8px', marginTop: 'auto' }}>
        <FlexContainer flexDirection="column">
          <DevelopmentField
            id={`DevelopmentCardRightSideUpperSideLeftSideFieldOne${props.developmentId}`}
          >
            <Body
              id={`DevelopmentCardRightSideUpperSideLeftSideFieldOneSpan${props.developmentId}`}
              title={props.country}
              style={{
                fontWeight: 'bold',
              }}
              noMargin
            >
              {props.country}
            </Body>
          </DevelopmentField>
          <DevelopmentField
            id={`DevelopmentCardRightSideUpperSideLeftSideFieldTwo${props.developmentId}`}
          >
            <span
              id={`DevelopmentCardRightSideUpperSideLeftSideFieldTwoSpan${props.developmentId}`}
            >
              {developmentRows.map((developmentRow, y) => {
                return (
                  <span
                    key={
                      (developmentRow.developmentId || 0) +
                      (developmentRow.rowId || 0)
                    }
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {y !== 0 ? ', ' : ' '}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: highlightAuthority(
                          developmentRow.row?.authority?.name
                        ),
                      }}
                    ></span>{' '}
                    —{' '}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: highlightDocumentType(
                          developmentRow.row?.documentTypeName
                        ),
                      }}
                    ></span>
                  </span>
                );
              })}
              {isRightPanelShow && (
                <span>
                  {' '}
                  ({citationsCount} citation{citationsCount === 1 ? '' : 's'})
                </span>
              )}
            </span>
          </DevelopmentField>
          <DevelopmentField
            id={`DevelopmentCardRightSideUpperSideLeftSideFieldThree${props.developmentId}`}
          >
            <Body
              id={`DevelopmentCardRightSideUpperSideLeftSideFieldThreeSpan${props.developmentId}`}
              title={`${processingDate} (${publishedDate})`}
              noMargin
            >
              {processingDate} ({publishedDate})
            </Body>
          </DevelopmentField>
        </FlexContainer>
        {props.hasDevelopmentVersions && (
          <FlexContainer style={{ marginLeft: 'auto', marginTop: 'auto' }}>
            {props.isLatestVersion ? (
              <GreenChip noMargin>Latest version</GreenChip>
            ) : (
              <OrangeChip noMargin>Previous version</OrangeChip>
            )}
          </FlexContainer>
        )}
      </FlexContainer>
    </DevelopmentCardRightSideUpperSideLeftSideContainer>
  );
};
