import { CSSProperties, useCallback, useMemo, useState } from 'react';
import { HTMLAttrID } from 'models';
import { Theme } from 'styles/themes';
import { ReactComponent as ArrowDown } from 'assets/arrow-down-smaller.svg';

interface DevelopmentCitationMatchDetailsProps extends HTMLAttrID {
  cmsId: string;
  citationName: string;
  cmsColor: string;
}

export const DevelopmentCitationMatchDetails = (
  props: DevelopmentCitationMatchDetailsProps
) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const collapsedStyle: CSSProperties = useMemo(
    () =>
      !isExpanded
        ? {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: '100%',
            display: 'inline-block',
          }
        : {},
    [isExpanded]
  );

  const handleToggleExpand = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      setIsExpanded(!isExpanded);
    },
    [isExpanded]
  );

  return (
    <div
      id={props.id ? `${props.id}ContentCmsIdTextContainer` : undefined}
      style={{ lineHeight: '24px', ...collapsedStyle }}
      onClick={!isExpanded ? handleToggleExpand : undefined}
    >
      <span
        id={props.id ? `${props.id}ContentCmsIdTextCmsId` : undefined}
        style={{
          fontSize: Theme.fontSize - 1,
          color: Theme.colors.black,
          wordBreak: 'break-word',
          backgroundColor: props.cmsColor,
          borderRadius: Theme.borderRadius,
          padding: '3px 4px',
          marginRight: '4px',
        }}
      >
        {props.cmsId}
      </span>
      <span
        id={props.id ? `${props.id}ContentCmsIdTextCitationName` : undefined}
        style={{ fontSize: Theme.fontSize - 1 }}
      >
        {props.citationName}
      </span>
      {isExpanded && (
        <span
          id={
            props.id
              ? `${props.id}ContentCmsIdTextCollapseButtonArrowDownContainer`
              : undefined
          }
          style={{
            marginLeft: '4px',
            backgroundColor: props.cmsColor,
            borderRadius: Theme.borderRadius,
            padding: '3px 4px',
            cursor: 'pointer',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: Theme.fontSize - 4,
            width: '22px',
            height: '17px',
            transform: 'translate(0, -4px)',
          }}
          onClick={handleToggleExpand}
        >
          <ArrowDown
            id={
              props.id
                ? `${props.id}ContentCmsIdTextCollapseButtonArrowDown`
                : undefined
            }
            color={Theme.colors.black}
            style={{
              width: '8px',
              height: '5px',
              flexShrink: 0,
              transform: 'rotate(180deg)',
            }}
          />
        </span>
      )}
    </div>
  );
};
