import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { FlexContainer } from 'styles/utils';
import { formatDistance } from 'date-fns';
import { HTMLAttributes } from 'react';

export interface RecentlyViewedDevelopmentCardProps
  extends HTMLAttributes<any> {
  title?: string;
  documentType?: string;
  authority?: string;
  dateViewed?: string;
}

const RecentTitleText = styled.span({
  fontWeight: 'bold',
  fontSize: Theme.fontSize,
  lineHeight: '23.5px',
  overflowWrap: 'anywhere',
});

const ViewedTimeAgoText = styled.span({
  fontSize: Theme.fontSize - 2,
  lineHeight: '19px',
  color: Theme.colors.gray,
  margin: 'auto 0',
  width: '100%',
  wordBreak: 'break-word',
});

export const RecentlyViewedDevelopmentCard = (
  props: RecentlyViewedDevelopmentCardProps
) => {
  const timeSinceLastViewed = formatDistance(
    new Date(props.dateViewed || new Date().toISOString()),
    new Date(),
    { addSuffix: true }
  );

  return (
    <FlexContainer
      id={`${props.id}Container`}
      flexDirection="column"
      style={{
        padding: '16px',
        boxShadow: `0px 1px 0px ${Theme.colors.grayBorder}`,
        cursor: 'pointer',
      }}
      onClick={props.onClick}
    >
      <RecentTitleText id={`${props.id}RecentTitleText`} title={props.title}>
        {props.title}
      </RecentTitleText>
      <span
        id={`${props.id}DocumentTypeAndAuthority`}
        style={{ marginBottom: '12px', lineHeight: '21.79px' }}
      >
        {props.authority}, {props.documentType}
      </span>
      <FlexContainer id={`${props.id}InnerContainer`}>
        <ViewedTimeAgoText id={`${props.id}ViewedTimeAgoText`}>
          Viewed {timeSinceLastViewed}
        </ViewedTimeAgoText>
      </FlexContainer>
    </FlexContainer>
  );
};
