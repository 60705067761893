import styled from '@emotion/styled';
import {
  CloseIconContainer,
  Modal,
  ModalContentContainer,
  ModalInnerContainer,
  ModalTitle,
} from 'components/Modal';
import { Theme } from 'styles/themes';
import { ReactComponent as Close } from 'assets/close.svg';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { FlexContainer } from 'styles/utils';
import { Button, OutlinedButton } from 'components/Button';
import { useAppDispatch } from 'store/hooks';
import { HttpResponse } from 'utils/http/HttpResponse';
import { RadioButtonGroup } from 'components/RadioButtonGroup';
import { displayToast } from 'store/toast/toast-slice';
import { Alert } from 'components/Alert';
import { InputLabel } from 'components/InputLabel';
import { ReprocessOperation } from 'enums';
import { useParams } from 'react-router-dom';
import { reprocessAsync } from 'store/development/development-slice';
import { Textarea } from 'components/Textarea';

interface ReprocessDevelopmentModalProps {
  isDisplayed: boolean;
  setIsDisplayed: Dispatch<SetStateAction<boolean>>;
}

export const ReprocessDevelopmentModalContainer = styled.div({
  display: 'flex',
  width: '624px',
});

export const ReprocessDevelopmentModal: React.FC<
  ReprocessDevelopmentModalProps
> = (props: ReprocessDevelopmentModalProps) => {
  const dispatch = useAppDispatch();
  const { developmentId } = useParams<{ developmentId?: string }>();
  const [operation, setOperation] = useState<ReprocessOperation | null>(null);
  const [reason, setReason] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const isReprocessButtonDisabled = useMemo(() => {
    return Number.isNaN(Number(developmentId)) || !operation || !reason;
  }, [developmentId, operation, reason]);

  const clearFields = () => {
    setOperation(null);
    setReason('');
    setIsLoading(false);
  };

  const closeModal = () => {
    props.setIsDisplayed(false);
    clearFields();
  };

  const reprocess = async () => {
    if (Number.isNaN(Number(developmentId))) {
      displayErrorToast('Development ID must be a number.');
      return;
    }
    if (!operation) {
      displayErrorToast('Operation to perform is required.');
      return;
    }
    if (!reason) {
      displayErrorToast('Reason for the request is required.');
      return;
    }
    setIsLoading(true);
    try {
      await dispatch(reprocessAsync(Number(developmentId), operation, reason));
      closeModal();
    } catch (error) {
      const httpResponse = error as HttpResponse<null>;
      if (httpResponse.error) {
        displayErrorToast(httpResponse.error.message);
      }
      setIsLoading(false);
    }
  };

  const displayErrorToast = (message: string) => {
    dispatch(
      displayToast({
        content: (
          <Alert
            id="ReprocessDevelopmentModalErrorToastAlert"
            style={{ backgroundColor: Theme.colors.redLight }}
          >
            <span
              id="ReprocessDevelopmentModalErrorToastAlertMessage"
              style={{ fontSize: Theme.fontSize + 2 }}
            >
              {message}
            </span>
          </Alert>
        ),
        rightDistance: 200,
        durationInMilliseconds: 10000,
      })
    );
  };

  return (
    <Modal
      id="ReprocessDevelopmentModal"
      isDisplayed={props.isDisplayed}
      isLoading={isLoading}
    >
      <ReprocessDevelopmentModalContainer id="ReprocessDevelopmentModalContainer">
        <ModalContentContainer id="ReprocessDevelopmentModalContentContainer">
          <ModalInnerContainer id="ReprocessDevelopmentModalHeader">
            <ModalTitle
              id="ReprocessDevelopmentModalTitle"
              withoutMarginBottom={true}
              color={Theme.colors.primaryColor}
            >
              Reprocess development
            </ModalTitle>
            <CloseIconContainer id="ReprocessDevelopmentModalCloseIconContainer">
              <Close
                id="ReprocessDevelopmentModalCloseButton"
                onClick={() => closeModal()}
              />
            </CloseIconContainer>
          </ModalInnerContainer>
          <ModalInnerContainer id="ReprocessDevelopmentModalBody">
            <FlexContainer
              id="ReprocessDevelopmentModalFormContainer"
              flexDirection="column"
              style={{ width: '100%' }}
            >
              <FlexContainer
                id="ReprocessDevelopmentModalFormInputGroup1"
                flexDirection="column"
                style={{ width: '100%', marginBottom: '24px' }}
              >
                <InputLabel
                  id="ReprocessDevelopmentModalOperationLabel"
                  isFieldRequired
                  isBold
                >
                  Operation to perform
                </InputLabel>
                <RadioButtonGroup
                  id="ReprocessDevelopmentModalOperationRadioButtonGroup"
                  radioButtonGroupOptions={[
                    {
                      text: `Re-run automatic matching with tool's latest version`,
                      value: ReprocessOperation.AutoMatch,
                    },
                    {
                      text: `Re-work manual matches`,
                      value: ReprocessOperation.GroundTruth,
                    },
                  ]}
                  value={operation}
                  setValue={(value) => {
                    setOperation(value);
                  }}
                  name="operation"
                  fullWidth
                  radioButtonGroupContainerStyle={{ gap: '8px' }}
                />
              </FlexContainer>
              <FlexContainer
                id="ReprocessDevelopmentModalFormInputGroup2"
                style={{ width: '100%' }}
              >
                <InputLabel
                  id="ReprocessDevelopmentModalReasonLabel"
                  htmlFor="ReprocessDevelopmentModalReasonTextarea"
                  isFieldRequired
                  isBold
                >
                  Reason of the request
                </InputLabel>
                <Textarea
                  id="ReprocessDevelopmentModalReasonTextarea"
                  placeholder="Reason..."
                  value={reason}
                  setValue={setReason}
                />
              </FlexContainer>
            </FlexContainer>
          </ModalInnerContainer>
          <ModalInnerContainer
            id="ReprocessDevelopmentModalFooter"
            withoutBorderBottom={true}
            padding="12px 16px"
          >
            <FlexContainer
              id="ReprocessDevelopmentModalButtonsContainer"
              style={{ marginLeft: 'auto' }}
            >
              <OutlinedButton
                id="ReprocessDevelopmentModalCancelButton"
                color={Theme.colors.secondaryColor}
                style={{ marginRight: '10px' }}
                onClick={() => closeModal()}
              >
                Cancel
              </OutlinedButton>
              <Button
                id="ReprocessDevelopmentModalSubmitButton"
                onClick={reprocess}
                isDisabled={isReprocessButtonDisabled}
              >
                Send
              </Button>
            </FlexContainer>
          </ModalInnerContainer>
        </ModalContentContainer>
      </ReprocessDevelopmentModalContainer>
    </Modal>
  );
};
