import { FlexContainer } from 'styles/utils';
import { EnvironmentBanner } from './components';
import { useAppSelector } from 'store/hooks';
import { selectSelectedEnvironment } from 'store/environment/environment-slice';

interface EnvironmentWrapperProps {
  children: JSX.Element;
}

export const EnvironmentWrapper = (props: EnvironmentWrapperProps) => {
  const selectedEnvironment = useAppSelector(selectSelectedEnvironment);

  return (
    <FlexContainer
      id="EnvironmentContainer"
      flexDirection="column"
      flexWrap="nowrap"
      style={{ width: '100%', height: '100%' }}
    >
      {selectedEnvironment && (
        <EnvironmentBanner selectedEnvironment={selectedEnvironment} />
      )}
      <FlexContainer
        id="MainLayoutContainer"
        style={{
          width: '100%',
          flex: 1,
        }}
      >
        {props.children}
      </FlexContainer>
    </FlexContainer>
  );
};
