import { compose } from 'lodash/fp';
import { Dispatch, useMemo, useState } from 'react';
import {
  matrix,
  updateMonth,
  setMonthUtil,
  updateYear,
  DateRange,
} from 'utils/date';

export function useCalendar(
  initialDate: Date = new Date(Date.now()),
  onChange: (d: Date) => void,
  initialValue?: Date,
  monthsToCover: number = 1
): CalendarHook {
  const [date, setDate] = useState(initialDate);
  const [value, setValue] = useState<undefined | Date>(initialValue);

  const nextMonth = compose(setDate, updateMonth('ADD', 1));
  const prevMonth = compose(setDate, updateMonth('SUB', 1));
  const setMonth = compose(setDate, setMonthUtil);
  const setYear = compose(setDate, updateYear);

  const calendar = useMemo(
    () => matrix(date.getFullYear(), date.getMonth(), 0, monthsToCover),
    [date, monthsToCover]
  );

  const setSideEffectValue = useMemo(
    () => (d: Date) => {
      onChange(d);
      setValue(d);
    },
    [setValue, onChange]
  );

  return [
    { calendar, value, date },
    {
      prevMonth,
      nextMonth,
      setValue: setSideEffectValue,
      setDate,
      setMonth,
      setYear,
    },
  ];
}

export function useDateRange(
  initialStartDate?: Date,
  initialEndDate?: Date
): DateRangeHook {
  const [startDate, setStartDate] = useState<Date | undefined>(
    initialStartDate
  );
  const [endDate, setEndDate] = useState<Date | undefined>(initialEndDate);

  return [
    { startDate, endDate },
    { setStartDate, setEndDate },
  ];
}

interface ICalendarHook {
  calendar: Date[][][];
  value: Date | undefined;
  date: Date;
}

type DateReceiverFN = (d: Date) => void;
type OptionalDateReceiverFN = (d: Date | undefined) => void;
type SetDateFN = (d: Date, value: number) => void;

interface ICalendarHookDispatcher {
  nextMonth: DateReceiverFN;
  prevMonth: DateReceiverFN;
  setValue: DateReceiverFN;
  setDate: Dispatch<React.SetStateAction<Date>>;
  setMonth: SetDateFN;
  setYear: SetDateFN;
}

type CalendarHook = [ICalendarHook, ICalendarHookDispatcher];

interface IDateRangeHook extends Partial<DateRange> {}

interface IDateRangeHookDispatcher {
  setStartDate: OptionalDateReceiverFN;
  setEndDate: OptionalDateReceiverFN;
}

type DateRangeHook = [IDateRangeHook, IDateRangeHookDispatcher];
