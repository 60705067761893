import { memo, useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { RadioButton } from 'components/RadioButton';
import { Environment } from 'models';
import { pascalCase } from 'utils/string-formatter';
import { EnvironmentsList } from 'models/reports/Source';

interface IndexReportEnvironmentPickerProps {
  onSelect: (selectedOrg: string | undefined) => void;
  isSelectedOrg: boolean;
  environmentsList: EnvironmentsList[];
  indexSelectedEnvironment: (seletedEnv: Environment) => void;
  selectedEnvironment: Environment;
}

const HorizonManagement = {
  id: 0,
  name: 'Horizon Management',
  organizationId: 0,
  organizationName: 'Horizon Management',
};

const EnvironmentPickerContainer = styled.div({
  width: '288px',
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
});

const EnvironmentPickerTitle = styled.div({
  marginBottom: '4px',
  alignItems: 'center',
  fontSize: Theme.fontSize,
  lineHeight: `${Theme.fontSize + 4}px`,
  color: Theme.colors.grayDark,
});

const EnvironmentPickerButton = styled.button<{
  isSelected?: boolean;
}>((props) => ({
  width: '100%',
  padding: '16px',
  marginTop: '9px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  textAlign: 'start',
  fontWeight: props.isSelected ? 'bold' : 'inherit',
  fontSize: Theme.fontSize,
  lineHeight: `${Theme.fontSize + 4}px`,
  backgroundColor: props.isSelected
    ? Theme.colors.blueLightest
    : Theme.colors.transparent,
  border: `1px solid ${
    props.isSelected ? Theme.colors.secondaryColor : Theme.colors.grayBorder
  }`,
  color: Theme.colors.primaryColor,
  cursor: 'pointer',
}));

export const IndexReportEnvironmentPicker = memo(
  (props: IndexReportEnvironmentPickerProps) => {
    const [selectedEnvironment, setSelectedEnvironment] =
      useState<Environment>();
    useEffect(() => {
      setSelectedEnvironment(props.selectedEnvironment);
    }, [props.selectedEnvironment]);

    // Ensure unique organizationId and organizationName using a Map
    const uniqueOrganizations = [
      HorizonManagement,
      ...Array.from(
        props.environmentsList
          .reduce((map, item) => {
            if (!map.has(item.organizationId)) {
              map.set(item.organizationId, item);
            }
            return map;
          }, new Map())
          .values()
      ),
    ];

    const handleSelectEnvironment = useCallback(
      (environment: Environment) => {
        props.indexSelectedEnvironment(environment);
        props.onSelect(environment.organizationName);
      },
      [props.onSelect]
    );

    return (
      <EnvironmentPickerContainer id="EnvironmentPickerContainer">
        <EnvironmentPickerTitle id="EnvironmentPickerTitle">
          Select environment:
        </EnvironmentPickerTitle>
        {uniqueOrganizations &&
          uniqueOrganizations.map((environment) => (
            <EnvironmentPickerButton
              id={`EnvironmentPickerButton${pascalCase(
                environment.organizationName
              )}`}
              key={environment.organizationName}
              isSelected={
                props.isSelectedOrg
                  ? environment.organizationName ===
                    selectedEnvironment?.organizationName
                  : environment.organizationName ===
                    HorizonManagement.organizationName
              }
              onClick={() => handleSelectEnvironment(environment)}
            >
              <RadioButton
                key={`EnvironmentPickerRadioButton${pascalCase(
                  environment.organizationName
                )}`}
                id={`EnvironmentPickerRadioButton${pascalCase(
                  environment.organizationName
                )}`}
                text={''}
                checked={
                  props.isSelectedOrg
                    ? environment.organizationName ===
                      selectedEnvironment?.organizationName
                    : environment.organizationName ===
                      HorizonManagement.organizationName
                }
                name={environment.organizationName}
                style={{
                  marginRight: '8px',
                }}
              />
              {environment.organizationName}
            </EnvironmentPickerButton>
          ))}
      </EnvironmentPickerContainer>
    );
  }
);
