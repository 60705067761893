import styled from '@emotion/styled';
import { HTMLAttributes } from 'react';
import { Theme } from 'styles/themes';

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  isDisabled?: boolean;
  fontWeight?: number;
  fontSize?: number;
  backgroundColor?: string;
  color?: string;
}

const StyledButton = styled.button<ButtonProps>((props) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 12px',
  minWidth: '80px',
  minHeight: '32px',
  borderRadius: Theme.borderRadius,
  fontStyle: 'normal',
  fontWeight: props.fontWeight || 'bold',
  fontSize: props.fontSize || Theme.fontSize - 2,
  lineHeight: '19px',
  cursor: props.isDisabled ? 'not-allowed' : 'pointer',
  outline: 'none',
  webkitAppearance: 'none',
  mozAppearance: 'none',
  backgroundColor: props.backgroundColor || Theme.colors.secondaryColor,
  color: props.color || Theme.colors.white,
  border: 'none',
  opacity: props.isDisabled ? '0.5' : '1',
}));

export const Button = (props: ButtonProps) => (
  <StyledButton
    {...props}
    onClick={props.isDisabled ? undefined : props.onClick}
  />
);
