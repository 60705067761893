import { AuthContainer, LoginSubTitle } from 'pages/auth/components';

export const UrlExpired = () => {
  return (
    <AuthContainer
      title="This invitation has expired"
      subtitle={
        <LoginSubTitle style={{ marginTop: '18px' }}>
          Contact an administrator to send a new invitation.
        </LoginSubTitle>
      }
      showPolicy={false}
    />
  );
};
