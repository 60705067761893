export const filterKeys = {
  language: 'authorityAliasesAuthorities.languageIds',
  authority: 'authorityAliasesAuthorities.id',
  region: 'authorityAliasesAuthorities.regionId',
  country: 'authorityAliasesAuthorities.countryId',
  documentType: 'authorityAliasesAuthorities.documentTypeIds',
  subcategory: 'authorityAliasesAuthorities.subcategoryIds',
  publicationDates: 'publicationDates.publicationDate',
  effectiveDates: 'effectiveDates.effectiveDate',
  complianceDates: 'complianceDates.complianceDate',
  isWithinRequiredTurnaroundTime:
    'turnaroundTimeData.isWithinRequiredTurnaroundTime',
};
