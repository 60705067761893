import { FlexContainer } from 'styles/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectReportByProperty,
  selectIsReportDataFromAverageDevelopmentsPerDay,
  selectPeriodProperty,
  getEntityDetails,
  selectEntityDetails,
  getEntityTeams,
  selectEntityTeams,
  setPeriodProperty,
} from 'store/performance-report/performance-report-slice';
import {
  ReportDetailsContainer,
  ReportDetailsMainBody,
  ReportDetailsSubHeader,
} from 'pages/reports/performance/components/PerformanceDetails/components';
import { Loading } from 'components/Loading';
import { performanceReportLabels } from 'pages/reports';
import { useParams } from 'react-router-dom';
import { PerformanceDetailsTables } from 'pages/reports/performance/components/PerformanceDetails/components/PerformanceDetailsTables';
import { routes } from 'navigation/routes';
import { ReportsHeader } from 'pages/reports/components';
import { PerformanceReportEntities } from 'enums';
import { reportByOptions } from 'pages/reports/performance/report-by-options';

export const PerformanceDetails = () => {
  const { entityType, id } = useParams<{ entityType: string; id: string }>();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const reportByProperty = useAppSelector(selectReportByProperty);
  const isReportDataFromAverageDevelopmentsPerDay = useAppSelector(
    selectIsReportDataFromAverageDevelopmentsPerDay
  );
  const periodProperty = useAppSelector(selectPeriodProperty);
  const entityDetails = useAppSelector(selectEntityDetails);
  const entityTeams = useAppSelector(selectEntityTeams);

  const reportByOptionEntity = useMemo(() => {
    return Object.values(reportByOptions).find((v) => v.urlText === entityType);
  }, [entityType]);

  const reportBySearchBarLabel = useMemo(() => {
    return reportByOptionEntity?.searchBarText || '';
  }, [reportByProperty]);

  const reportTypeText = useMemo(
    () => reportByOptionEntity?.text || '',
    [reportByProperty]
  );

  const getAllDataAsync = useCallback(async () => {
    setIsLoading(true);
    await dispatch(getEntityDetails(Number(id), reportByOptionEntity!.value));
    await dispatch(getEntityTeams(Number(id), reportByOptionEntity!.value));
    setIsLoading(false);
  }, [
    entityType,
    id,
    isReportDataFromAverageDevelopmentsPerDay,
    periodProperty,
  ]);

  useEffect(() => {
    getAllDataAsync();
  }, [
    entityType,
    id,
    isReportDataFromAverageDevelopmentsPerDay,
    periodProperty,
  ]);

  const handleSetPeriodProperty = (period: string) => {
    dispatch(setPeriodProperty(period));
  };

  const breadcrumb = useMemo(() => {
    return [
      {
        text: performanceReportLabels.title,
        path: routes.performanceReport,
      },
      {
        text: entityDetails?.name || '',
      },
    ];
  }, [entityType, id, entityDetails]);

  const isCountry = useMemo(() => {
    return reportByOptionEntity?.text === PerformanceReportEntities.Country;
  }, [entityType, reportByOptionEntity]);

  return (
    <FlexContainer
      flexWrap="nowrap"
      flexDirection="column"
      style={{
        width: '100%',
        flex: 1,
        overflowX: 'hidden',
      }}
    >
      <ReportsHeader
        breadcrumb={breadcrumb}
        backButtonPath={routes.performanceReport}
        hasBorderBottom
      />
      <ReportDetailsSubHeader
        {...performanceReportLabels}
        searchBarLabel={`${performanceReportLabels.searchBarLabel} ${reportBySearchBarLabel}...`}
        period={periodProperty}
        setPeriod={handleSetPeriodProperty}
      />
      <ReportDetailsMainBody>
        {isLoading && <Loading />}
        <FlexContainer style={{ height: 'calc(100% - 55px)' }}>
          <ReportDetailsContainer
            isReportDataFromAverageDevelopmentsPerDay={
              isReportDataFromAverageDevelopmentsPerDay
            }
            {...performanceReportLabels}
          >
            {entityDetails && (
              <PerformanceDetailsTables
                data={entityDetails}
                isCountry={isCountry}
                teams={entityTeams}
                reportFieldText={reportTypeText}
              />
            )}
          </ReportDetailsContainer>
        </FlexContainer>
      </ReportDetailsMainBody>
    </FlexContainer>
  );
};
