import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { Input } from 'components/Input';
import { OutputConsole } from 'components/OutputConsole';
import { Environment } from 'models';
import { useCallback, useState } from 'react';
import { toggleChannel } from 'services/environments/environments-service';
import { Theme } from 'styles/themes';
import { HttpResponse } from 'utils/http/HttpResponse';

interface ToggleChannelCardProps {
  selectedEnvironment: Environment | null;
}

export const ToggleChannelCard = (props: ToggleChannelCardProps) => {
  const [outputs, setOutputs] = useState<string[]>([]);
  const [rowIds, setRowIds] = useState<string>('');
  const [isProcessOnGoing, setIsProcessOnGoing] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handlePrimaryAction = useCallback(async () => {
    try {
      setErrorMessage('');
      if (props.selectedEnvironment && rowIds) {
        setIsProcessOnGoing(true);
        setOutputs([]);
        const response = await toggleChannel(
          props.selectedEnvironment.elasticsearchIndexPrefix,
          rowIds
        );
        if (!!response.data) {
          setOutputs((currentValue) => [...currentValue, response.data!]);
        }
        setRowIds('');
      }
    } catch (error) {
      const httpResponse = error as HttpResponse<null>;
      if (!httpResponse.success && httpResponse.error) {
        setErrorMessage(httpResponse.error.message);
      }
    } finally {
      setIsProcessOnGoing(false);
    }
  }, [props.selectedEnvironment?.elasticsearchIndexPrefix, rowIds]);

  return (
    <Card>
      <span
        style={{
          fontSize: Theme.fontSize,
          fontWeight: 'bold',
          color: Theme.colors.primaryColor,
          marginBottom: '16px',
        }}
      >
        Toggle Channel
      </span>
      <div>
        <div style={{ width: '100%', marginBottom: '16px' }}>
          <Input
            placeholder="Row IDs separated by commas..."
            value={rowIds}
            setValue={setRowIds}
            error={errorMessage}
          />
          <span
            style={{
              fontSize: Theme.fontSize - 4,
              display: 'block',
              marginTop: '8px',
            }}
          >
            Example: 1,2,3
          </span>
        </div>
        <Button
          onClick={() => {
            handlePrimaryAction();
          }}
          style={{ marginBottom: '16px' }}
          isDisabled={isProcessOnGoing}
        >
          Run
        </Button>
        <div>
          <span
            style={{
              marginBottom: '16px',
              display: 'block',
            }}
          >
            Output
          </span>
          <OutputConsole outputs={outputs} height="300px" />
        </div>
      </div>
    </Card>
  );
};
