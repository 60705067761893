import { httpGet } from 'utils/http/base-http-service';
import { HttpResponse } from 'utils/http/HttpResponse';
import { DevelopmentEvent, PaginatedResponse } from 'models';
import { toQueryParams } from 'utils/to-query-params';
import { SortByDirection } from 'enums/SortByDirection';

export const getAllDevelopmentEvents = async (
  sortByProperty: string,
  sortByDirection: SortByDirection,
  page: number,
  perPage: number,
  developmentId: number,
  developmentProcessingDate: string
): Promise<HttpResponse<PaginatedResponse<DevelopmentEvent> | null>> => {
  let query: any = {
    filter: [
      {
        bool: {
          must: [
            {
              match: {
                developmentId: {
                  query: String(developmentId),
                  minimum_should_match: '100%',
                },
              },
            },
          ],
        },
      },
    ],
  };
  const queryParameters = toQueryParams({
    query: JSON.stringify(query),
    sortByProperty,
    sortByDirection,
    page,
    perPage,
    processingDate: developmentProcessingDate,
  });
  return httpGet<PaginatedResponse<DevelopmentEvent>>(
    `development-events?${queryParameters}`
  );
};
