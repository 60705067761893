import { Development } from 'models';

export const developmentKeys: {
  [x: string]: keyof Development;
} = {
  id: 'id',
  externalId: 'externalId',
  title: 'title',
  pdfContent: 'pdfContent',
  savedByUserIds: 'savedByUserIds',
  documentCitation: 'documentCitation',
  processingDate: 'processingDate',
  mainPublicationDate: 'mainPublicationDate',
  mainEffectiveDate: 'mainEffectiveDate',
  publicationDates: 'publicationDates',
  effectiveDates: 'effectiveDates',
  complianceDates: 'complianceDates',
  obligationsCount: 'obligationsCount',
  developmentCitationsCount: 'developmentCitationsCount',
  developmentCitations: 'developmentCitations',
  authorityAliasesAuthorities: 'authorityAliasesAuthorities',
  viewedByUsers: 'viewedByUsers',
  ingestionStatus: 'ingestionStatus',
  externalRowIds: 'externalRowIds',
  pageCount: 'pageCount',
  exceptionFlag: 'exceptionFlag',
  isLatestNonUniqueVersion: 'isLatestNonUniqueVersion',
  createdAt: 'createdAt',
};
