import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import {
  getAllDevelopments,
  getDevelopmentById,
  getFilterCounters,
  reprocessDevelopment,
  saveDevelopment,
} from 'services/developments/developments-service';
import { Development, PaginatedResponse } from 'models';
import { paginationConfig } from 'config/pagination-config';
import { ReprocessOperation, SortByDirection } from 'enums';
import { developmentKeys } from 'config/development-keys';
import { updateElementInArrayIfExists } from 'utils/array';
import { setFilterCounters } from 'store/home-sidebar-filter/home-sidebar-filter-slice';

export interface DevelopmentsState {
  developments: Development[];
  searchTerm: string;
  searchProperties: (keyof Development | string)[];
  sortByProperty: string;
  sortByDirection: SortByDirection;
  page: number;
  perPage: number;
  totalRecords: number;
  triggerFeedUpdate: number;
  isAdvancedSearchEnabled: boolean;
  homePreventsGetDevelopments: boolean;
  pdfContentSnippets: {
    [x: string]: string[];
  }[];
}

const initialState: DevelopmentsState = {
  developments: [],
  searchTerm: '',
  searchProperties: [
    developmentKeys.externalId,
    developmentKeys.title,
    developmentKeys.id,
    developmentKeys.pdfContent,
  ],
  sortByProperty: developmentKeys.processingDate,
  sortByDirection: SortByDirection.DESC,
  page: 1,
  perPage: paginationConfig.developmentsPerPage,
  totalRecords: 0,
  triggerFeedUpdate: 0,
  isAdvancedSearchEnabled: false,
  homePreventsGetDevelopments: false,
  pdfContentSnippets: [],
};

export const getAllDevelopmentsAsync = (): AppThunk<
  Promise<PaginatedResponse<Development> | null>
> => {
  return async (dispatch, getState) => {
    const selectedEnvironment = getState().environment.selectedEnvironment;
    const searchTerm = getState().development.searchTerm;
    const searchProperties = getState().development.searchProperties;
    const sortByProperty = getState().development.sortByProperty;
    const sortByDirection = getState().development.sortByDirection;
    const activeFilters = getState().homeSidebarFilter.activeFilters;
    const page = getState().development.page;
    const perPage = getState().development.perPage;
    const response = await getAllDevelopments(
      selectedEnvironment,
      searchTerm,
      sortByProperty,
      sortByDirection,
      page,
      perPage,
      activeFilters,
      undefined,
      undefined,
      searchProperties
    );
    if (page > 1) {
      dispatch(addDevelopments(response.data));
    } else {
      dispatch(setDevelopments(response.data));
    }
    return response.data;
  };
};

export const refreshSingleDevelopmentByIdAsync = (
  id: string
): AppThunk<Promise<Development | null>> => {
  return async (dispatch, getState) => {
    const hasDevelopment = getState().development.developments.some(
      (d) => Number(d.id) === Number(id)
    );
    if (hasDevelopment) {
      const response = await getDevelopmentById(id);
      const development = response.data;
      if (development) {
        dispatch(updateDevelopment(development));
      }
      return development;
    }
    return null;
  };
};

export const saveAsync = (
  developmentId: number
): AppThunk<Promise<Development | null>> => {
  return async (dispatch, getState) => {
    const authenticationResponse =
      getState().authentication.authenticationResponse;
    const userId = authenticationResponse?.id;
    if (developmentId && userId) {
      const response = await saveDevelopment(developmentId, userId);
      const selectedEnvironment = getState().environment.selectedEnvironment;
      const activeFilters = getState().homeSidebarFilter.activeFilters;
      const searchTerm = getState().development.searchTerm;
      const searchProperties = getState().development.searchProperties;
      const countersResponse = await getFilterCounters(
        selectedEnvironment,
        activeFilters,
        searchTerm,
        searchProperties
      );
      dispatch(setFilterCounters(countersResponse.data));
      return response.data;
    } else {
      return null;
    }
  };
};

export const reprocessAsync = (
  developmentId: number,
  operation: ReprocessOperation,
  reason: string
): AppThunk<Promise<null>> => {
  return async (dispatch, getState) => {
    const authenticationResponse =
      getState().authentication.authenticationResponse;
    const userId = authenticationResponse?.id;
    if (userId) {
      const response = await reprocessDevelopment(
        developmentId,
        userId,
        operation,
        reason
      );
      return response.data;
    } else {
      return null;
    }
  };
};

export const developmentsSlice = createSlice({
  name: 'developments',
  initialState,
  reducers: {
    setDevelopments: (
      state,
      action: PayloadAction<PaginatedResponse<Development> | null>
    ) => {
      state.developments = action.payload?.records ?? [];
      state.totalRecords = action.payload?.totalRecords ?? 0;
      state.pdfContentSnippets = action.payload?.highlight ?? [];
    },
    addDevelopments: (
      state,
      action: PayloadAction<PaginatedResponse<Development> | null>
    ) => {
      state.developments = state.developments.concat(
        action.payload?.records ?? []
      );
      state.pdfContentSnippets = state.pdfContentSnippets.concat(
        action.payload?.highlight ?? []
      );
    },
    setDevelopmentsSearchTerm: (state, action: PayloadAction<string>) => {
      state.page = 1;
      state.searchTerm = action.payload;
    },
    setDevelopmentsSearchProperties: (
      state,
      action: PayloadAction<(keyof Development | string)[]>
    ) => {
      state.page = 1;
      state.searchProperties = action.payload;
    },
    setDevelopmentsSortByProperty: (state, action: PayloadAction<string>) => {
      state.page = 1;
      state.sortByProperty = action.payload;
    },
    setDevelopmentsSortByDirection: (
      state,
      action: PayloadAction<SortByDirection>
    ) => {
      state.page = 1;
      state.sortByDirection = action.payload;
    },
    increaseDevelopmentsPage: (state) => {
      state.page = state.page + 1;
    },
    resetDevelopmentsPage: (state) => {
      state.page = 1;
    },
    updateDevelopment: (state, action: PayloadAction<Development>) => {
      const development = { ...action.payload };
      state.developments = updateElementInArrayIfExists(
        state.developments,
        development,
        developmentKeys.id
      );
    },
    setTriggerFeedUpdate: (state) => {
      state.triggerFeedUpdate = state.triggerFeedUpdate + 1;
    },
    resetDevelopmentsSearchTerm: (state) => {
      state.searchTerm = '';
    },
    resetDevelopmentsSortBy: (state) => {
      state.sortByDirection = SortByDirection.DESC;
      state.sortByProperty = developmentKeys.processingDate;
    },
    setIsAdvancedSearchEnabled: (state, action: PayloadAction<boolean>) => {
      state.isAdvancedSearchEnabled = action.payload;
      state.page = 1;
    },
    setHomePreventsGetDevelopments: (state, action: PayloadAction<boolean>) => {
      state.homePreventsGetDevelopments = action.payload;
    },
  },
});

export const {
  setDevelopments,
  setDevelopmentsSearchTerm,
  setDevelopmentsSearchProperties,
  setDevelopmentsSortByProperty,
  setDevelopmentsSortByDirection,
  addDevelopments,
  increaseDevelopmentsPage,
  resetDevelopmentsPage,
  updateDevelopment,
  setTriggerFeedUpdate,
  resetDevelopmentsSearchTerm,
  resetDevelopmentsSortBy,
  setIsAdvancedSearchEnabled,
  setHomePreventsGetDevelopments,
} = developmentsSlice.actions;

export const selectDevelopments = (state: RootState) =>
  state.development.developments;
export const selectDevelopmentsSearchTerm = (state: RootState) =>
  state.development.searchTerm;
export const selectDevelopmentsSearchProperties = (state: RootState) =>
  state.development.searchProperties;
export const selectDevelopmentsSortByProperty = (state: RootState) =>
  state.development.sortByProperty;
export const selectDevelopmentsSortByDirection = (state: RootState) =>
  state.development.sortByDirection;
export const selectDevelopmentsTotalRecords = (state: RootState) =>
  state.development.totalRecords;
export const selectDevelopmentsPage = (state: RootState) =>
  state.development.page;
export const selectDevelopmentsPerPage = (state: RootState) =>
  state.development.perPage;
export const selectTriggerFeedUpdate = (state: RootState) =>
  state.development.triggerFeedUpdate;
export const selectIsAdvancedSearchEnabled = (state: RootState) =>
  state.development.isAdvancedSearchEnabled;
export const selectHomePreventsGetDevelopments = (state: RootState) =>
  state.development.homePreventsGetDevelopments;
export const selectPdfContentSnippets = (state: RootState) =>
  state.development.pdfContentSnippets;

export default developmentsSlice.reducer;
