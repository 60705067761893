export {
  Body,
  BodyBig,
  Caption,
  TypographyBase,
  Heading,
  Link,
  Subtitle,
  SubHeading,
  Title,
  A,
  EllipsedSpan,
} from './Typography';
