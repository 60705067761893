import { ReactNode, useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Searchbar } from 'components/Searchbar';
import { Theme } from 'styles/themes';
import { VerticalDivider, FlexContainer } from 'styles/utils';
import { ReportsLabels } from 'pages/reports/interfaces/ReportsLabels';
import { PeriodDropdown } from 'components/PeriodDropdown';
import { ReportByDropdown } from '../components';
import {
  PerformanceReportEntities,
  RealTimeExportDevelopmentsEvent,
} from 'enums';
import { OverflowXContainer } from 'components/OverflowXContainer';
import { ExportDevelopmentsButton } from 'components/ExportDevelopmentsButton';
import { useAppSelector } from 'store/hooks';
import { selectTurnaroundTimeActiveFilters } from 'store/turnaround-time-report/turnaround-time-filter-slice';
import {
  selectFromDateInMilliseconds,
  selectToDateInMilliseconds,
} from 'store/turnaround-time-report/turnaround-time-report-slice';
import { AuthenticationResponse, Environment } from 'models';
import { getDevelopmentsQuery } from 'services/developments/developments-service';
import { BaseSocketService } from 'utils/socket/BaseSocketService';

interface ReportsSubHeaderSearchProps {
  searchTerm: string;
  onSearchChange: (value: string) => void;
}

interface ReportsSubHeaderPeriodProps {
  period: string;
  setPeriod: (period: string) => void;
  onSelectPeriod: () => void;
  onSelectCustomPeriod?: () => void;
}

interface ReportsSubHeaderReportByProps {
  reportBy: PerformanceReportEntities;
  setReportBy: (reportEntity: PerformanceReportEntities) => void;
}

interface ReportsSubHeaderProps extends ReportsLabels {
  children?: ReactNode;
  searchProps?: ReportsSubHeaderSearchProps;
  periodProps?: ReportsSubHeaderPeriodProps;
  reportByProps?: ReportsSubHeaderReportByProps;
  hasNoBorders?: boolean;
  totalDevelopments?: number;
}

const SubHeaderContainer = styled.div((props: { hasNoBorders?: boolean }) => ({
  width: '100%',
  height: '56px',
  padding: '12px 16px',
  borderBottom: props.hasNoBorders
    ? 'none'
    : `solid 1px ${Theme.colors.grayBorder}`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const SubTitle = styled.span({
  fontSize: '18px',
  lineHeight: '25px',
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  marginRight: '8px',
});

export const ReportsSubHeader = (props: ReportsSubHeaderProps) => {
  const turnaroundTimeActiveFilters = useAppSelector(
    selectTurnaroundTimeActiveFilters
  );
  const fromDateInMilliseconds = useAppSelector(selectFromDateInMilliseconds);
  const toDateInMilliseconds = useAppSelector(selectToDateInMilliseconds);

  const [isSubTitleHovered, setIsSubTitleHovered] = useState(false);
  const overflowXElementWidth = document
    .querySelector('#ReportSubHeaderOverflowXContainer')
    ?.getBoundingClientRect().width;
  const overflowXElementScrollWidth = document.querySelector(
    '#ReportSubHeaderOverflowXContainer'
  )?.scrollWidth;

  const isOverflowX = useMemo(
    () =>
      Math.ceil(overflowXElementScrollWidth || 0) >
      Math.ceil(overflowXElementWidth || 0),
    [overflowXElementWidth, overflowXElementScrollWidth]
  );

  const handleExportDevelopments = useCallback(
    (
      socketService: BaseSocketService,
      authenticationResponse: AuthenticationResponse,
      environment: Environment
    ) => {
      const query = getDevelopmentsQuery(
        environment,
        turnaroundTimeActiveFilters,
        undefined,
        undefined,
        fromDateInMilliseconds,
        toDateInMilliseconds
      );
      const queryParameters = {
        query: JSON.stringify(query),
      };
      const withTurnaroundTimeData = true;
      socketService.emit(
        RealTimeExportDevelopmentsEvent.ExportDevelopments,
        authenticationResponse.id,
        environment.elasticsearchIndexPrefix,
        queryParameters,
        withTurnaroundTimeData
      );
    },
    [
      JSON.stringify(turnaroundTimeActiveFilters),
      fromDateInMilliseconds,
      toDateInMilliseconds,
    ]
  );

  return (
    <SubHeaderContainer hasNoBorders={props.hasNoBorders}>
      <OverflowXContainer
        id="ReportSubHeaderOverflowXContainer"
        isScrollDisplayed={isSubTitleHovered && isOverflowX}
        onMouseEnter={() => setIsSubTitleHovered(true)}
        onMouseLeave={() => setIsSubTitleHovered(false)}
        style={{
          marginRight: 0,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {props.children}
        {!!props.searchProps && (
          <Searchbar
            value={props.searchProps.searchTerm}
            setValue={props.searchProps.onSearchChange}
            placeholder={props.searchBarLabel}
          />
        )}
        {!props.searchProps && !props.children && props.subTitle && (
          <>
            <SubTitle title={props.subTitle}>{props.subTitle}</SubTitle>
            {!!props.totalDevelopments && (
              <ExportDevelopmentsButton
                totalRecords={props.totalDevelopments}
                emitExportEvent={handleExportDevelopments}
              />
            )}
          </>
        )}
      </OverflowXContainer>
      {(!!props.reportByProps || !!props.periodProps) && (
        <FlexContainer
          style={{
            alignItems: 'center',
            whiteSpace: 'nowrap',
            marginLeft: '16px',
          }}
          flexWrap="nowrap"
        >
          {!!props.reportByProps && (
            <ReportByDropdown
              reportByProperty={props.reportByProps.reportBy}
              setReportByProperty={props.reportByProps.setReportBy}
            />
          )}
          {!!props.reportByProps && !!props.periodProps && (
            <VerticalDivider height={'24px'} style={{ margin: '0 6px' }} />
          )}
          {!!props.periodProps && (
            <PeriodDropdown
              periodProperty={props.periodProps.period}
              setPeriodProperty={props.periodProps.setPeriod}
              onSelectPeriod={props.periodProps.onSelectPeriod}
              onSelectCustomPeriod={props.periodProps.onSelectCustomPeriod}
            />
          )}
        </FlexContainer>
      )}
    </SubHeaderContainer>
  );
};
