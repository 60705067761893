import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ManagementSidebar,
  ManagementHeader,
  ManagementSubHeader,
  ManagementMainBody,
  ManagementDropdown,
} from 'pages/management/components';
import { FlexContainer } from 'styles/utils';
import { teamManagementLabels } from './labels';
import { ReactComponent as AddMemberIcon } from 'assets/add-member.svg';
import { ReactComponent as AssignFeedIcon } from 'assets/assign-feed.svg';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { ManagementDropdownOption } from '../components/ManagementDropdown';
import { Loading } from 'components/Loading';
import { Pagination } from 'components/Pagination';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Row } from 'components/Table';
import { Team, Feed, User } from 'models';
import {
  addSelectedManagementTeamsOutsideOfCurrentPage,
  deleteTeamsAsync,
  getAllManagementTeamsAsync,
  removeSelectedManagementTeamsOutsideOfCurrentPage,
  selectAllManagementTeams,
  selectAreAllManagementTeamsSelected,
  selectIsManagementTeamsDeleteConfirmationModalDisplayed,
  selectManagementTeam,
  selectManagementTeams,
  selectManagementTeamsPage,
  selectManagementTeamsPerPage,
  selectManagementTeamsSearchTerm,
  selectManagementTeamsSortByDirection,
  selectManagementTeamsSortByProperty,
  selectManagementTeamsTotalRecords,
  selectSelectedManagementTeams,
  selectSelectedManagementTeamsOutsideOfCurrentPage,
  selectTriggerManagementTeamsUpdate,
  setIsManagementTeamsDeleteConfirmationModalDisplayed,
  setManagementTeamsPage,
  setManagementTeamsSearchTerm,
  setTriggerManagementTeamsUpdate,
  updateTeamAsync,
} from 'store/management-team/management-team-slice';
import {
  resetAssignToTeamSidebarFeedsAll,
  updateAssignToTeamSidebarFeeds,
} from 'store/assign-to-team-sidebar-feed/assign-to-team-sidebar-feed-slice';
import {
  getUserLastViewedFeedAsync,
  setSelectedFeed,
} from 'store/feed/feed-picker-slice';
import { setManagementFeedToEdit } from 'store/feed/management-feed-slice';
import { TeamsTable } from './components';
import { TeamDetail } from './TeamDetail';
import { TeamEdit } from './TeamEdit';
import { TeamAssignFeedSidebar } from './TeamAssignFeedSidebar';
import { CreateTeamModal } from 'components/CreateTeamModal';
import {
  getAllCreateTeamModalUsersAsync,
  selectCreateTeamModalUsers,
  selectCreateTeamModalUsersPage,
  selectCreateTeamModalUsersSearchTerm,
  selectTriggerCreateTeamModalUsersUpdate,
} from 'store/create-team-modal-user/create-team-modal-user-slice';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { Body } from 'components/Typography';
import {
  CreateFeedModal,
  CreateFeedModalTypes,
} from 'components/CreateFeedModal';

export const TeamManagement = () => {
  const dispatch = useAppDispatch();
  const teams = useAppSelector(selectManagementTeams);
  const areAllTeamsSelected = useAppSelector(
    selectAreAllManagementTeamsSelected
  );
  const page = useAppSelector(selectManagementTeamsPage);
  const perPage = useAppSelector(selectManagementTeamsPerPage);
  const selectedTeams = useAppSelector(selectSelectedManagementTeams);
  const totalRecords = useAppSelector(selectManagementTeamsTotalRecords);
  const searchTerm = useAppSelector(selectManagementTeamsSearchTerm);
  const sortByProperty = useAppSelector(selectManagementTeamsSortByProperty);
  const sortByDirection = useAppSelector(selectManagementTeamsSortByDirection);
  const triggerTeamsUpdate = useAppSelector(selectTriggerManagementTeamsUpdate);
  const selectedTeamsOutsideOfCurrentPage = useAppSelector(
    selectSelectedManagementTeamsOutsideOfCurrentPage
  );
  const isDeleteConfirmationModalDisplayed = useAppSelector(
    selectIsManagementTeamsDeleteConfirmationModalDisplayed
  );

  const users = useAppSelector(selectCreateTeamModalUsers);
  const usersSearchTerm = useAppSelector(selectCreateTeamModalUsersSearchTerm);
  const usersPage = useAppSelector(selectCreateTeamModalUsersPage);
  const triggerUsersUpdate = useAppSelector(
    selectTriggerCreateTeamModalUsersUpdate
  );

  const sidebarContainerRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetailSidebar, setIsLoadingDetailSidebar] = useState(false);
  const [isCreateTeamModalDisplayed, setIsCreateTeamModalDisplayed] =
    useState(false);
  const [isAddUsersToTeamsModalDisplayed, setIsAddUsersToTeamsModalDisplayed] =
    useState(false);

  const [isAssigningFeeds, setIsAssigningFeeds] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [
    isDiscardConfirmationModalDisplayed,
    setIsDiscardConfirmationModalDisplayed,
  ] = useState(false);
  const [addedFeedsForTeamEdit, setAddedFeedsForTeamEdit] = useState<Feed[]>(
    []
  );
  const [addedUsersForTeamEdit, setAddedUsersForTeamEdit] = useState<User[]>(
    []
  );

  const onClickCreateButton = useCallback(() => {
    setIsCreateTeamModalDisplayed(true);
  }, []);
  const [isCreateFeedModalDisplayed, setIsCreateFeedModalDisplayed] =
    useState(false);

  const totalPages = useMemo(
    () => Math.ceil(totalRecords / perPage),
    [totalRecords]
  );

  const teamRows: Row<Team>[] = useMemo(() => {
    return teams.map((u: Team) => ({
      ...u,
      checked: areAllTeamsSelected !== !!selectedTeams[u.id || 0],
    }));
  }, [teams, selectedTeams, areAllTeamsSelected]);

  const teamsChecked = useMemo(
    () => Object.keys(selectedTeams).length,
    [selectedTeams]
  );

  const checkedTeams = useMemo(
    () =>
      areAllTeamsSelected
        ? teams.filter(
            (u) =>
              !Object.keys(selectedTeams)
                .map((key) => Number(key))
                .includes(Number(u.id))
          )
        : Object.values(selectedTeams),
    [selectedTeams, areAllTeamsSelected, teams]
  );

  const areSomeTeamsChecked = useMemo(
    () => !!teamsChecked || areAllTeamsSelected,
    [areAllTeamsSelected, teamsChecked]
  );

  const selectedTeamsCount = useMemo(() => {
    return areAllTeamsSelected ? totalRecords - teamsChecked : teamsChecked;
  }, [areAllTeamsSelected, totalRecords, teamsChecked]);

  const singleSelectedTeam = useMemo(() => {
    if (selectedTeamsCount === 1) {
      if (checkedTeams.length === 1) {
        return checkedTeams[0];
      } else {
        if (selectedTeamsOutsideOfCurrentPage.length === 1) {
          return selectedTeamsOutsideOfCurrentPage[0];
        } else {
          return undefined;
        }
      }
    } else {
      return undefined;
    }
  }, [selectedTeamsCount, checkedTeams, selectedTeamsOutsideOfCurrentPage]);

  const selectedTeamsText = useMemo(
    () =>
      selectedTeamsCount > 1
        ? `${selectedTeamsCount} teams selected`
        : areAllTeamsSelected && selectedTeamsCount === 1
        ? '1 team selected'
        : undefined,
    [selectedTeamsCount, areAllTeamsSelected]
  );

  const spotlitTeams = useMemo(
    () => (isEditing || isAssigningFeeds ? checkedTeams : undefined),
    [checkedTeams, isAssigningFeeds, isEditing]
  );

  const canBulkDelete = useMemo(
    () => selectedTeamsCount > 0,
    [selectedTeamsCount]
  );

  const canStartAssigningFeeds = useMemo(
    () => selectedTeamsCount > 0,
    [selectedTeamsCount]
  );

  const handleSelectRow = useCallback((row: Row<Team>) => {
    dispatch(selectManagementTeam(row));
  }, []);

  const handleSelectAll = useCallback(() => {
    dispatch(selectAllManagementTeams());
  }, []);

  const handleSearchTermChange = useCallback(
    (value: string) => dispatch(setManagementTeamsSearchTerm(value)),
    []
  );

  const handlePageChange = useCallback(
    (page: number) => {
      dispatch(addSelectedManagementTeamsOutsideOfCurrentPage(checkedTeams));
      dispatch(setManagementTeamsPage(page));
    },
    [checkedTeams]
  );

  const setIsDeleteConfirmationModalDisplayed = useCallback(
    (isDeleteConfirmationModalDisplayed: boolean) => {
      dispatch(
        setIsManagementTeamsDeleteConfirmationModalDisplayed(
          isDeleteConfirmationModalDisplayed
        )
      );
    },
    []
  );

  const handleDeleteTeams = useCallback(async () => {
    setIsLoadingDetailSidebar(true);
    setIsDeleteConfirmationModalDisplayed(false);
    await dispatch(deleteTeamsAsync());
    setIsLoadingDetailSidebar(false);
  }, []);

  const handleDeleteFeedsFromModal = useCallback(async () => {
    setIsLoadingDetailSidebar(true);
    dispatch(resetAssignToTeamSidebarFeedsAll());
    setIsEditing(false);
    setIsAssigningFeeds(false);
    dispatch(setTriggerManagementTeamsUpdate());
    dispatch(getUserLastViewedFeedAsync()).then((feed) => {
      dispatch(setSelectedFeed(feed));
    });
    setIsLoadingDetailSidebar(false);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getAllManagementTeamsAsync())
      .then((newTeams) => {
        dispatch(removeSelectedManagementTeamsOutsideOfCurrentPage(newTeams));
      })
      .finally(() => setIsLoading(false));
  }, [page, searchTerm, sortByProperty, sortByDirection, triggerTeamsUpdate]);

  useEffect(() => {
    dispatch(getAllCreateTeamModalUsersAsync());
  }, [usersPage, usersSearchTerm, triggerUsersUpdate]);

  useEffect(() => {
    if (!singleSelectedTeam) {
      setIsEditing(false);
    }
  }, [singleSelectedTeam]);

  const handleOpenAddUsersModal = useCallback(() => {
    setIsAddUsersToTeamsModalDisplayed(true);
  }, []);

  const handleOpenAssignFeedsSidebar = useCallback(() => {
    setIsAssigningFeeds(true);
  }, []);

  const handleDiscardChanges = useCallback(() => {
    setIsAssigningFeeds(false);
    setIsEditing(false);
    setIsDiscardConfirmationModalDisplayed(false);
  }, []);

  const handleCloseDiscardChangesModal = useCallback(
    () => setIsDiscardConfirmationModalDisplayed(false),
    []
  );

  const handleOpenDiscardChangesModal = useCallback(() => {
    if (
      !isAddUsersToTeamsModalDisplayed &&
      !isCreateFeedModalDisplayed &&
      (isAssigningFeeds || isEditing)
    ) {
      setIsDiscardConfirmationModalDisplayed(true);
    }
  }, [
    isEditing,
    isAssigningFeeds,
    isAddUsersToTeamsModalDisplayed,
    isCreateFeedModalDisplayed,
  ]);

  const handleSubmitAssignFeedsToTeams = useCallback(
    (feeds?: Feed[]) => {
      if (isAssigningFeeds && feeds) {
        dispatch(setTriggerManagementTeamsUpdate());
        setIsAssigningFeeds(false);
      }
    },
    [isAssigningFeeds]
  );

  const handleOpenEditFeedModal = useCallback((feed: Feed) => {
    dispatch(setManagementFeedToEdit(feed));
    setIsCreateFeedModalDisplayed(true);
  }, []);

  const handleAfterEditFeed = useCallback((updatedFeed: Feed | null) => {
    if (updatedFeed) {
      dispatch(updateAssignToTeamSidebarFeeds([updatedFeed]));
      dispatch(setTriggerManagementTeamsUpdate());
    }
  }, []);

  const handleSubmitEditedTeam = useCallback(
    async (team: Team) => {
      if (isEditing) {
        const updatedTeam = await dispatch(updateTeamAsync(team));
        if (updatedTeam) {
          setIsEditing(false);
          dispatch(selectManagementTeam(updatedTeam));
        }
      }
    },
    [isEditing]
  );

  const handleToggleEditMode = useCallback(
    (value: boolean) => setIsEditing(value),
    []
  );

  const handleAddFeedsForUserEdit = useCallback((feeds: Feed[]) => {
    setAddedFeedsForTeamEdit(feeds);
    setIsAssigningFeeds(false);
  }, []);

  const handleResetAddedFeedsForUserEdit = useCallback(
    () => setAddedFeedsForTeamEdit([]),
    []
  );

  const handleAddUsersForUserEdit = useCallback(
    (users: User[]) => setAddedUsersForTeamEdit(users),
    []
  );

  const handleResetAddedUsersForUserEdit = useCallback(
    () => setAddedUsersForTeamEdit([]),
    []
  );

  const dropdownOptions: ManagementDropdownOption[] = [
    {
      label: 'Add member',
      icon: <AddMemberIcon />,
      onClick: handleOpenAddUsersModal,
    },
    {
      label: 'Assign feed',
      icon: <AssignFeedIcon />,
      onClick: handleOpenAssignFeedsSidebar,
      isDisabled: !canStartAssigningFeeds,
    },
    {
      label: 'Delete',
      icon: <DeleteIcon />,
      onClick: () => setIsDeleteConfirmationModalDisplayed(true),
      isDisabled: !canBulkDelete,
    },
  ];

  return (
    <FlexContainer
      id="TeamManagementContainer"
      flexWrap="nowrap"
      flexDirection="column"
      style={{ width: '100%', flex: 1 }}
    >
      <ManagementHeader
        id="TeamManagementHeader"
        {...teamManagementLabels}
        onClickCreateButton={onClickCreateButton}
      />
      <ManagementSubHeader
        id="TeamManagementSubHeader"
        {...teamManagementLabels}
        count={totalRecords}
        selectedCount={selectedTeamsCount}
        searchTerm={searchTerm}
        onSearchChange={handleSearchTermChange}
      >
        <ManagementDropdown
          id="TeamManagementDropdown"
          title={teamManagementLabels.dropdownLabel}
          options={dropdownOptions}
          isDisabled={!selectedTeamsCount || isAssigningFeeds}
        />
      </ManagementSubHeader>
      <FlexContainer
        id="TeamManagementContentContainer"
        flexWrap="nowrap"
        flexDirection="row"
        style={{ flex: '1' }}
      >
        <ManagementMainBody id="TeamManagementMainBody">
          {isLoading && <Loading id="TeamManagementLoading" />}
          <TeamsTable
            onSelectAll={handleSelectAll}
            onSelectRow={handleSelectRow}
            rows={teamRows}
            areSomeChecked={areSomeTeamsChecked}
            sortDirection={sortByDirection}
            sortField={sortByProperty}
            spotlitTeams={spotlitTeams}
          />
          <Pagination
            id="TeamManagementPagination"
            currentPage={page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </ManagementMainBody>
        <ManagementSidebar
          id="TeamManagementSidebar"
          {...teamManagementLabels}
          containerRef={sidebarContainerRef}
        >
          {(isEditing && singleSelectedTeam && (
            <>
              <TeamEdit
                containerRef={sidebarContainerRef}
                team={singleSelectedTeam}
                addedFeeds={addedFeedsForTeamEdit}
                addedUsers={addedUsersForTeamEdit}
                onClickAddFeedsButton={handleOpenAssignFeedsSidebar}
                onClickAddUsersButton={handleOpenAddUsersModal}
                resetAddedFeeds={handleResetAddedFeedsForUserEdit}
                resetAddedUsers={handleResetAddedUsersForUserEdit}
                onAttemptToCancel={handleOpenDiscardChangesModal}
                onSubmit={handleSubmitEditedTeam}
                isHidden={isAssigningFeeds}
              />
              {isAssigningFeeds && (
                <TeamAssignFeedSidebar
                  containerRef={sidebarContainerRef}
                  selectedTeams={selectedTeams}
                  areAllTeamsSelected={areAllTeamsSelected}
                  teamsSearchTerm={searchTerm}
                  onAttemptToCancel={handleOpenDiscardChangesModal}
                  onAfterSubmit={() => {}}
                  onSubmit={handleAddFeedsForUserEdit}
                  onEditFeed={handleOpenEditFeedModal}
                  withOptions
                />
              )}
            </>
          )) ||
            (canStartAssigningFeeds && isAssigningFeeds && (
              <TeamAssignFeedSidebar
                containerRef={sidebarContainerRef}
                selectedTeams={selectedTeams}
                areAllTeamsSelected={areAllTeamsSelected}
                teamsSearchTerm={searchTerm}
                onAttemptToCancel={handleOpenDiscardChangesModal}
                onAfterSubmit={handleSubmitAssignFeedsToTeams}
                onEditFeed={handleOpenEditFeedModal}
                withOptions
              />
            )) ||
            (singleSelectedTeam && (
              <TeamDetail
                team={singleSelectedTeam}
                isLoading={isLoadingDetailSidebar}
                onOpenAssingFeedsSidebar={handleOpenAssignFeedsSidebar}
                onAddMemberClicked={handleOpenAddUsersModal}
                onEditMode={handleToggleEditMode}
              />
            )) ||
            selectedTeamsText}
        </ManagementSidebar>
      </FlexContainer>
      <CreateTeamModal
        isDisplayed={isCreateTeamModalDisplayed}
        setIsDisplayed={setIsCreateTeamModalDisplayed}
        users={users}
      />
      <CreateTeamModal
        isDisplayed={isAddUsersToTeamsModalDisplayed}
        setIsDisplayed={setIsAddUsersToTeamsModalDisplayed}
        isAddUsersToTeamsModal
        users={users}
        onAddUsers={isEditing ? handleAddUsersForUserEdit : undefined}
      />
      <CreateFeedModal
        isDisplayed={isCreateFeedModalDisplayed}
        setIsDisplayed={setIsCreateFeedModalDisplayed}
        onAfterSubmit={handleAfterEditFeed}
        onDelete={handleDeleteFeedsFromModal}
        type={CreateFeedModalTypes.FeedManagement}
      />
      <ConfirmationModal
        id="DeleteTeamConfirmationModal"
        title={
          selectedTeamsCount > 1 ? 'Delete these teams?' : 'Delete this team?'
        }
        onPrimaryAction={() => setIsDeleteConfirmationModalDisplayed(false)}
        onSecondaryAction={handleDeleteTeams}
        isDisplayed={isDeleteConfirmationModalDisplayed}
        primaryText="Cancel"
        secondaryText="Yes, delete"
      >
        <Body id="DeleteTeamConfirmationModalMessage">
          {selectedTeamsCount > 1 ? 'These teams' : 'This team'} will be
          permanently removed from the platform,{' '}
        </Body>
        <Body id="DeleteTeamConfirmationModalWarning" bold>
          {' '}
          this action can't be undone.{' '}
        </Body>
        <Body id="DeleteTeamConfirmationModalAreYouSure">
          Are you sure you wish to delete?
        </Body>
      </ConfirmationModal>
      <ConfirmationModal
        id="DiscardTeamChangesConfirmationModal"
        title="Discard changes?"
        onPrimaryAction={handleCloseDiscardChangesModal}
        onSecondaryAction={handleDiscardChanges}
        isDisplayed={isDiscardConfirmationModalDisplayed}
        primaryText="Cancel"
        secondaryText="Yes, discard"
      >
        <Body id="DiscardTeamChangesConfirmationMessage">
          Exiting without confirming will discard any changes done.
        </Body>
      </ConfirmationModal>
    </FlexContainer>
  );
};
