import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectIsDisplayed,
  selectContent,
  selectDistanceFromRight,
  hideToast,
  selectDurationInMilliseconds,
} from 'store/toast/toast-slice';
import { CSSTransition } from 'react-transition-group';
import { useRef } from 'react';

const TransformContainer = styled.div<{ distanceFromRight: number }>(
  (props) => ({
    '.slide-in-from-right-enter, .slide-in-from-right-enter-done': {
      transform: `translateX(-${props.distanceFromRight}px)`,
    },
  })
);

const ToastContainer = styled.div({
  display: 'flex',
  position: 'absolute',
  zIndex: 4,
  right: 0,
  bottom: '100px',
  transition: 'transform 1s ease',
  maxWidth: '50%',
});

export const Toast = () => {
  const dispatch = useAppDispatch();
  const nodeRef = useRef<HTMLDivElement>(null);
  const isDisplayed = useAppSelector(selectIsDisplayed);
  const content = useAppSelector(selectContent);
  const distanceFromRight = useAppSelector(selectDistanceFromRight);
  const durationInMilliseconds = useAppSelector(selectDurationInMilliseconds);

  return (
    <TransformContainer distanceFromRight={distanceFromRight}>
      <CSSTransition
        nodeRef={nodeRef}
        in={isDisplayed}
        classNames="slide-in-from-right"
        addEndListener={(done: () => void) => {
          if (nodeRef.current) {
            const onTransitionEnd = () => {
              setTimeout(() => {
                done();
                dispatch(hideToast());
              }, durationInMilliseconds);
            };

            nodeRef.current.addEventListener(
              'transitionend',
              onTransitionEnd,
              false
            );
          }
        }}
      >
        <ToastContainer ref={nodeRef}>{content}</ToastContainer>
      </CSSTransition>
    </TransformContainer>
  );
};
