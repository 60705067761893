import styled from '@emotion/styled';
import { Theme } from 'styles/themes';

export const PageContainer = styled.div({
  width: '100%',
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '30px',
});

export const ChatContainer = styled.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '65%',
  height: '100%',
  borderRadius: '5px',
  backgroundColor: Theme.colors.secondaryBackgroundColor,
});

export const CitationContainer = styled.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '30%',
  height: '100%',
  borderRadius: '5px',
  backgroundColor: Theme.colors.secondaryBackgroundColor,
});

export const ChatContainerHeader = styled.div({
  position: 'absolute',
  top: 0,
  left: '42%',
  paddingTop: '5px',
  paddingBottom: '5px',
  fontSize: '18px',
  color: Theme.colors.primaryColor,
});

export const CitationsContainerHeader = styled.div({
  position: 'absolute',
  top: 0,
  left: '42%',
  paddingTop: '5px',
  paddingBottom: '5px',
  fontSize: '18px',
  color: Theme.colors.primaryColor,
});

export const InputContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  padding: '10px',
  position: 'absolute',
  width: '100%',
  bottom: 0,
});

export const SendButton = styled.button({
  width: '80px',
  padding: '8px 18px 8px 18px',
  borderRadius: '5px',
  border: `2px solid ${Theme.colors.primaryColor}`,
  backgroundColor: Theme.colors.white,
  color: Theme.colors.primaryColor,
  fontSize: '18px',
  marginLeft: '20px',
  ':hover': {
    cursor: 'pointer',
  },
});

export const ChatElementsContainer = styled.div({
  height: '97%',
  width: '100%',
  backgroundColor: Theme.colors.white,
  backgroundClip: 'content-box, padding-box',
  boxShadow: `inset 0 0 0 10px ${Theme.colors.secondaryBackgroundColor}`,
  position: 'absolute',
  top: '25px',
});

export const ClearButtonContainer = styled.div({
  position: 'absolute',
  bottom: '60px',
  width: '100%',
  alignContent: 'center',
});

export const ClearButton = styled.button({
  position: 'relative',
  top: '50%',
  left: '38%',
  width: '150px',
  padding: '8px 18px 8px 18px',
  borderRadius: '5px',
  border: `2px solid ${Theme.colors.redBackgroundColor}`,
  backgroundColor: Theme.colors.redBackgroundColor,
  color: Theme.colors.primaryColor,
  fontSize: '18px',
  marginLeft: '20px',
  ':hover': {
    cursor: 'pointer',
  },
  ':disabled': {
    backgroundColor: Theme.colors.primaryBackgroundColor,
  },
});

export const CitationElementsContainer = styled.div({
  height: '97%',
  width: '100%',
  backgroundColor: Theme.colors.white,
  backgroundClip: 'content-box, padding-box',
  boxShadow: `inset 0 0 0 10px ${Theme.colors.secondaryBackgroundColor}`,
  position: 'absolute',
  top: '25px',
  overflow: 'hidden',
});
