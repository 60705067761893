import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface ScrollSyncState {
  scrollPosition: number;
}

const initialState: ScrollSyncState = {
  scrollPosition: 0,
};

export const scrollSyncSlice = createSlice({
  name: 'scroll-sync',
  initialState,
  reducers: {
    setScrollPosition: (state, action: PayloadAction<number>) => {
      state.scrollPosition = action.payload;
    },
  },
});

export const { setScrollPosition } = scrollSyncSlice.actions;

export const selectScrollPosition = (state: RootState) =>
  state.scrollSync.scrollPosition;

export default scrollSyncSlice.reducer;
