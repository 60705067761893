import { memo } from 'react';
import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { Button } from 'components/Button';

export interface NotFoundPromptProps {
  onClickButton: () => void;
}

const NotFoundPromptContainer = styled.div({
  width: '320px',
  padding: '24px 58px',
  display: 'flex',
  flexDirection: 'column',
});

const NotFoundPromptTitle = styled.div({
  marginBottom: '16px',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  fontSize: Theme.fontSize + 2,
  lineHeight: `${Theme.fontSize + 4}px`,
  fontWeight: 'bold',
});

const NotFoundPromptDescription = styled.div({
  marginBottom: '16px',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  fontSize: Theme.fontSize,
  lineHeight: `${Theme.fontSize + 4}px`,
});

const NotFoundPromptButtonsContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
});

export const NotFoundPrompt = memo((props: NotFoundPromptProps) => {
  return (
    <NotFoundPromptContainer id="NotFoundPromptContainer">
      <NotFoundPromptTitle id="NotFoundPromptTitle">
        Development not found
      </NotFoundPromptTitle>
      <NotFoundPromptDescription id="NotFoundPromptDescription">
        This Development ID does not exist.
        <strong>
          You will be redirected to the <i>Development Feed</i> page.
        </strong>
      </NotFoundPromptDescription>
      <NotFoundPromptButtonsContainer id="NotFoundPromptButtonsContainer">
        <Button id="NotFoundPromptButton" onClick={props.onClickButton}>
          OK
        </Button>
      </NotFoundPromptButtonsContainer>
    </NotFoundPromptContainer>
  );
});
