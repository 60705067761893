import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { BoldText, CursorPointer, FlexContainer } from 'styles/utils';
import { Dropdown, DropdownArrow } from 'components/Dropdown';
import {
  getAllRecentlyViewedDevelopmentsAsync,
  selectRecentlyViewedDevelopments,
  selectRecentlyViewedDevelopmentsPage,
  selectRecentlyViewedDevelopmentsTotalRecords,
} from 'store/recently-viewed-development/recently-viewed-development-slice';
import { RecentlyViewedDevelopmentsList } from '../RecentlyViewedDevelopmentsList';

interface RecentlyViewedDevelopmentsDropdownProps {
  scrollableParentContainerHeight: number;
  dropdownContainerHeight: number;
}

const RecentlyViewedDevelopmentsDropdownContainer = styled.div<{
  height: number;
}>((props) => ({
  display: 'flex',
  width: '100%',
  borderBottom: `1px solid ${Theme.colors.grayBorder}`,
  height: props.height,
  padding: '0 15px',
  position: 'relative',
  justifyContent: 'space-between',
  userSelect: 'none',
}));

export const RecentlyViewedDevelopmentsDropdown = (
  props: RecentlyViewedDevelopmentsDropdownProps
) => {
  const dispatch = useAppDispatch();
  const developments = useAppSelector(selectRecentlyViewedDevelopments);
  const totalRecords = useAppSelector(
    selectRecentlyViewedDevelopmentsTotalRecords
  );
  const page = useAppSelector(selectRecentlyViewedDevelopmentsPage);
  const recentDropdownRef = useRef(null);
  const [isRecentDropdownOpen, setIsRecentDropdownOpen] = useState(false);

  const toggleDropdown = useCallback(() => {
    setIsRecentDropdownOpen(!isRecentDropdownOpen);
  }, [isRecentDropdownOpen, setIsRecentDropdownOpen]);

  useEffect(() => {
    dispatch(getAllRecentlyViewedDevelopmentsAsync());
  }, [page]);

  return (
    <RecentlyViewedDevelopmentsDropdownContainer
      id="RecentlyViewedDevelopmentsContainer"
      ref={recentDropdownRef}
      height={props.dropdownContainerHeight}
    >
      <CursorPointer
        id="RecentlyViewedDevelopmentsCursorPointer"
        style={{ display: 'flex', alignItems: 'center', flexGrow: 2 }}
        onClick={toggleDropdown}
      >
        <BoldText id="RecentlyViewedDevelopmentsBoldText">
          <span
            id="RecentlyViewedDevelopmentsTitle"
            style={{ marginRight: '5px', color: Theme.colors.primaryColor }}
          >
            Recently viewed
          </span>
          <span
            id="RecentlyViewedDevelopmentsTotalRecords"
            style={{ color: Theme.colors.secondaryColor }}
          >
            ({totalRecords})
          </span>
        </BoldText>
      </CursorPointer>
      {developments && !!developments.length && (
        <FlexContainer
          id="RecentlyViewedDevelopmentsInnerContainer"
          style={{
            alignItems: 'center',
          }}
        >
          <CursorPointer
            id="RecentlyViewedDevelopmentsInnerCursorPointer"
            onClick={toggleDropdown}
            style={{ width: '24px', height: '24px' }}
          >
            <DropdownArrow
              id="RecentlyViewedDevelopmentsDropdownArrow"
              isExpanded={isRecentDropdownOpen}
            />
          </CursorPointer>
          <Dropdown
            id="RecentlyViewedDevelopmentsDropdown"
            isOpen={isRecentDropdownOpen}
            setIsOpen={setIsRecentDropdownOpen}
            containerRef={recentDropdownRef}
            transform={`translate(-50%, ${
              props.dropdownContainerHeight - 1
            }px)`}
            style={{
              border: `1px solid ${Theme.colors.grayBorder}`,
              width: '100%',
              maxHeight:
                props.scrollableParentContainerHeight -
                props.dropdownContainerHeight,
              boxShadow: `10px 10px 15px -9px ${Theme.colors.grayShadow}`,
            }}
          >
            <RecentlyViewedDevelopmentsList developments={developments} />
          </Dropdown>
        </FlexContainer>
      )}
    </RecentlyViewedDevelopmentsDropdownContainer>
  );
};
