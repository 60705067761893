import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { EnvironmentSelector } from './components';
import { Modal } from 'components/Modal';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setAuthenticationResponse } from 'store/authentication/authentication-slice';
import {
  getCurrentUserEnvironmentsAsync,
  getDevelopmentEnvironmentAsync,
  selectCurrentUserEnvironments,
  setSelectedEnvironment,
} from 'store/environment/environment-slice';
import { AuthenticationResponse, Environment } from 'models';
import { HttpResponse } from 'utils/http/HttpResponse';

interface EnvironmentSelectorModalProps {
  loginAuthenticationResponse: AuthenticationResponse | null;
  developmentIdToFilterEnvironment: number | null;
  onErrorCallback: (message: string) => void;
}

export const EnvironmentSelectorModal = memo(
  (props: EnvironmentSelectorModalProps) => {
    const dispatch = useAppDispatch();
    const [isModalEnabled, setIsModalEnabled] = useState(false);

    useEffect(() => {
      (async () => {
        if (!!props.loginAuthenticationResponse) {
          if (!props.developmentIdToFilterEnvironment) {
            await dispatch(
              getCurrentUserEnvironmentsAsync(
                props.loginAuthenticationResponse.accessToken
              )
            );
            setIsModalEnabled(true);
          } else {
            try {
              const environment = await dispatch(
                getDevelopmentEnvironmentAsync(
                  props.developmentIdToFilterEnvironment,
                  props.loginAuthenticationResponse.accessToken
                )
              );
              if (!!environment) {
                dispatch(setSelectedEnvironment(environment));
                dispatch(
                  setAuthenticationResponse(props.loginAuthenticationResponse)
                );
              }
            } catch (error) {
              const httpResponse = error as HttpResponse<Environment>;
              if (httpResponse.status === 404) {
                await dispatch(
                  getCurrentUserEnvironmentsAsync(
                    props.loginAuthenticationResponse.accessToken
                  )
                );
                setIsModalEnabled(true);
              }
            }
          }
        }
      })();
    }, [
      props.loginAuthenticationResponse,
      props.developmentIdToFilterEnvironment,
    ]);

    const handleClickOutsideModal = useCallback(() => {
      setIsModalEnabled(false);
    }, []);

    const isDisplayed = useMemo(
      () => !!props.loginAuthenticationResponse && isModalEnabled,
      [props.loginAuthenticationResponse, isModalEnabled]
    );
    const currentUserEnvironments = useAppSelector(
      selectCurrentUserEnvironments
    );

    const handleSelectEnvironment = useCallback(
      (environment: Environment) => {
        dispatch(setSelectedEnvironment(environment));
        dispatch(setAuthenticationResponse(props.loginAuthenticationResponse));
      },
      [props.loginAuthenticationResponse]
    );

    useEffect(() => {
      if (currentUserEnvironments && currentUserEnvironments.length == 1) {
        setIsModalEnabled(false);
        currentUserEnvironments.map((environment) =>
          handleSelectEnvironment(environment)
        );
      }
    }, [props.loginAuthenticationResponse, currentUserEnvironments]);
    return (
      <Modal
        id="EnvironmentSelectorModal"
        isDisplayed={isDisplayed}
        onClickOutside={handleClickOutsideModal}
        style={{
          width: '24%',
        }}
      >
        <EnvironmentSelector
          loginAuthenticationResponse={props.loginAuthenticationResponse}
        />
      </Modal>
    );
  }
);
