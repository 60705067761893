import { Button } from 'components/Button';
import { PasswordInput } from 'components/Input';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from 'models';
import { ErrorText, FlexContainer } from 'styles/utils';
import { ReactComponent as Warning } from 'assets/warning.svg';
import { useLocation } from 'react-router-dom';
import { setUserPassword } from 'services/auth/auth-service';
import { getCurrentUser } from 'services/users/users-service';
import { routes } from 'navigation/routes';
import { PasswordTooltip } from 'components/PasswordTooltip';
import { validatePassword } from 'helpers/password';
import { Body } from 'components/Typography';
import { Theme } from 'styles/themes';
import { AuthContainer, LoginSubTitle } from 'pages/auth/components';

export const Signup = () => {
  const search = useLocation().search;
  const navigate = useNavigate();
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const [user, setUser] = useState<User | null>(null);
  const [confirmation, setConfirmation] = useState('');
  const [isPasswordSet, setIsPasswordSet] = useState(false);
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorGettingUser, setErrorGettingUser] = useState(false);
  const isButtonDisabled = useMemo(() => {
    const passwordValidity = validatePassword(password || '');
    return !confirmation || !passwordValidity.isValid;
  }, [confirmation, password]);

  const urlSearchParams = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);

  const token = useMemo(() => {
    return urlSearchParams.get('token');
  }, [urlSearchParams]);

  useEffect(() => {
    window.onkeydown = (e: any) => {
      if (e.key === 'Enter' && !isButtonDisabled) {
        handleSignup();
      }
    };
  }, [isButtonDisabled, confirmation, password]);

  useEffect(() => {
    if (token) {
      getCurrentUser(token)
        .then((response) => setUser(response.data))
        .catch(() => setErrorGettingUser(true));
    }
  }, [token]);

  useEffect(() => {
    if (errorGettingUser) {
      navigate(`${routes.urlExpired}`);
    }
  }, [errorGettingUser]);

  const handleSignup = useCallback(() => {
    if (isPasswordSet) {
      navigate(routes.login);
      return;
    }
    if (password !== confirmation) {
      setErrorMessage('Passwords must match');
      return;
    } else {
      setErrorMessage('');
    }
    setUserPassword(password, token || '').then(() => setIsPasswordSet(true));
  }, [setIsPasswordSet, password, token, confirmation, isPasswordSet]);

  return errorGettingUser ? (
    <Body>The url is either invalid or has expired.</Body>
  ) : (
    <AuthContainer
      title={
        isPasswordSet
          ? `Welcome to Horizon, ${user?.firstName} ${user?.lastName}`
          : `Hello, ${user?.firstName} ${user?.lastName}!`
      }
      subtitle={
        <LoginSubTitle>
          {isPasswordSet
            ? 'You have completed your registration and have been added to the platform'
            : 'Please create your password to finish registering your account.'}
        </LoginSubTitle>
      }
      showPolicy={!isPasswordSet}
    >
      <>
        {!isPasswordSet && (
          <>
            <FlexContainer
              id="SignupPasswordContainer"
              style={{
                width: '100%',
                marginBottom: '15px',
                position: 'relative',
              }}
            >
              <label
                htmlFor="password"
                style={{ marginRight: 'auto', marginBottom: '5px' }}
              >
                Password
              </label>
              <PasswordInput
                id="password"
                placeholder="●●●●●●●●"
                value={password}
                setValue={setPassword}
                inputRef={passwordInputRef}
              />
              <PasswordTooltip relativeToElement={passwordInputRef} />
            </FlexContainer>
            <FlexContainer
              id="SignupRetypePasswordContainer"
              style={{ width: '100%', marginBottom: '15px' }}
            >
              <label
                htmlFor="retype-password"
                style={{ marginRight: 'auto', marginBottom: '5px' }}
              >
                Retype password
              </label>
              <PasswordInput
                id="retype-password"
                placeholder="●●●●●●●●"
                value={confirmation}
                setValue={setConfirmation}
              />
            </FlexContainer>
          </>
        )}
        <Button
          id="SignupSubmitButton"
          onClick={handleSignup}
          isDisabled={isButtonDisabled}
        >
          {isPasswordSet ? 'Go to Log in' : 'Register'}
        </Button>
        {errorMessage && errorMessage.length > 0 && (
          <FlexContainer
            id="SignupErrorContainer"
            style={{ marginTop: '10px' }}
          >
            <Warning
              id="SignupWarningIcon"
              style={{ margin: 'auto 5px auto 0', color: Theme.colors.red }}
            />
            <ErrorText id="SignupErrorText" style={{ margin: 'auto 0' }}>
              {errorMessage}
            </ErrorText>
          </FlexContainer>
        )}
      </>
    </AuthContainer>
  );
};
