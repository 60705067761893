import { BlueChip, GreenChip, YellowChip } from 'components/Chip';
import {
  DevelopmentCitation,
  DevelopmentCitationMatchNormalizedCitation,
  HTMLAttrID,
} from 'models';
import { Theme } from 'styles/themes';
import { CursorPointer, FlexContainer, VerticalDivider } from 'styles/utils';
import { ReactComponent as ExternalLinkIcon } from 'assets/external-link.svg';
import { ReactComponent as ParentIndicatorIcon } from 'assets/parent-indicator.svg';
import { ReactComponent as ChildIndicatorIcon } from 'assets/child-indicator.svg';
import styled from '@emotion/styled';
import { ObligationMatchCardOptions } from './components/ObligationMatchCardOptions';
import { useMemo } from 'react';

interface ObligationMatchesCardProps extends HTMLAttrID {
  developmentCitation: DevelopmentCitation;
  isSelected: boolean;
  areDirect: boolean;
}

const ObligationMatchesCardContainer = styled.div({
  backgroundColor: Theme.colors.quaternaryBackgroundColor,
  padding: '8px',
  borderRadius: Theme.borderRadius,
  marginTop: '8px',
  display: 'flex',
  flexDirection: 'column',
});

const CmsIdChip = (
  props: {
    cmsId: string;
    doesntHaveObligations: boolean;
    areDirect: boolean;
    isSelected?: boolean;
  } & HTMLAttrID
) => {
  if (props.doesntHaveObligations) {
    return (
      <YellowChip
        id={props.id}
        style={{
          fontSize: Theme.fontSize - 2,
          color: Theme.colors.grayDarker,
          backgroundColor: props.isSelected
            ? Theme.colors.cmsYellowSelected
            : Theme.colors.cmsYellow,
          marginBottom: '8px',
        }}
      >
        {props.cmsId}
      </YellowChip>
    );
  } else {
    if (props.areDirect) {
      return (
        <GreenChip
          id={props.id}
          style={{
            fontSize: Theme.fontSize - 2,
            color: Theme.colors.grayDarker,
            backgroundColor: props.isSelected
              ? Theme.colors.cmsGreenSelected
              : Theme.colors.cmsGreen,
            marginBottom: '8px',
          }}
        >
          {props.cmsId}
        </GreenChip>
      );
    } else {
      return (
        <BlueChip
          id={props.id}
          style={{
            fontSize: Theme.fontSize - 2,
            color: Theme.colors.grayDarker,
            backgroundColor: props.isSelected
              ? Theme.colors.cmsBlueSelected
              : Theme.colors.cmsBlue,
            marginBottom: '8px',
          }}
        >
          {props.cmsId}
        </BlueChip>
      );
    }
  }
};

const ObligationChip = styled.div({
  backgroundColor: Theme.colors.quaternaryBackgroundColor,
  borderRadius: Theme.borderRadius,
  display: 'flex',
  marginTop: '8px',
  padding: '8px',
});

export const ObligationMatchesCard = (props: ObligationMatchesCardProps) => {
  const goToObligationUrl = (obligationUrl: string): void => {
    const obligationLinkTarget = '_blank';
    const obligationLinkWindow = window.open(
      obligationUrl,
      obligationLinkTarget
    );
    if (obligationLinkWindow) {
      obligationLinkWindow.focus();
    }
  };

  const developmentCitationMatches = useMemo(() => {
    return props.developmentCitation.matches || [];
  }, [JSON.stringify(props.developmentCitation.matches)]);

  const developmentCitationMatchNormalizedCitations = useMemo(() => {
    return (
      developmentCitationMatches.reduce(
        (a: DevelopmentCitationMatchNormalizedCitation[], b) =>
          a.concat(b.developmentCitationMatchNormalizedCitations || []),
        []
      ) || []
    );
  }, [JSON.stringify(developmentCitationMatches)]);

  const yellow = props.isSelected
    ? Theme.colors.cmsYellowSelected
    : Theme.colors.cmsYellow;
  const blue = props.isSelected
    ? Theme.colors.cmsBlueSelected
    : Theme.colors.cmsBlue;
  const green = props.isSelected
    ? Theme.colors.cmsGreenSelected
    : Theme.colors.cmsGreen;

  return (
    <ObligationMatchesCardContainer
      id={props.id ? `${props.id}Container` : undefined}
    >
      {developmentCitationMatchNormalizedCitations
        ?.filter((x) => x.isDirectMatch === props.areDirect)
        .map((developmentCitationMatchNormalizedCitation, y, z) => {
          return (
            <div
              id={props.id ? `${props.id}Content${y}` : undefined}
              key={`${developmentCitationMatchNormalizedCitation.developmentCitationMatchId}_${developmentCitationMatchNormalizedCitation.normalizedCitation.id}`}
              style={{
                border: `1px solid ${
                  props.isSelected
                    ? Theme.colors.secondaryColor
                    : Theme.colors.grayBorder
                }`,
                padding: '8px',
                display: 'flex',
                backgroundColor: Theme.colors.white,
                borderRadius: Theme.borderRadius,
                flex: '1 1 auto',
                marginBottom: y !== z.length - 1 ? '6px' : '0px',
              }}
            >
              <VerticalDivider
                id={
                  props.id ? `${props.id}ContentVerticalDivider${y}` : undefined
                }
                color={
                  !developmentCitationMatchNormalizedCitation.normalizedCitation
                    .obligations?.length
                    ? yellow
                    : props.areDirect
                    ? green
                    : blue
                }
                style={{
                  marginRight: '8px',
                }}
                thickness={'2px'}
              />
              <FlexContainer
                id={props.id ? `${props.id}ContentContainer${y}` : undefined}
                flexDirection="column"
                style={{
                  alignItems: 'flex-start',
                  flex: '1 1 auto',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'flex-start',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginRight: '6px',
                    }}
                  >
                    {!!developmentCitationMatchNormalizedCitation
                      .normalizedCitation.cmsId && (
                      <CmsIdChip
                        id={
                          props.id
                            ? `${props.id}ContentCmsIdChip${y}`
                            : undefined
                        }
                        cmsId={
                          developmentCitationMatchNormalizedCitation
                            .normalizedCitation.cmsId
                        }
                        doesntHaveObligations={
                          !developmentCitationMatchNormalizedCitation
                            .normalizedCitation.obligations?.length
                        }
                        areDirect={props.areDirect}
                        isSelected={props.isSelected}
                      />
                    )}
                    <span
                      id={
                        props.id
                          ? `${props.id}ContentNormalizedCitationText${y}`
                          : undefined
                      }
                      style={{
                        wordBreak: 'break-word',
                        fontSize: Theme.fontSize - 2,
                      }}
                    >
                      {
                        developmentCitationMatchNormalizedCitation
                          .normalizedCitation.name
                      }
                    </span>
                  </div>
                  <ObligationMatchCardOptions
                    id={props.id ? `${props.id}ContentOptions${y}` : undefined}
                    developmentCitation={props.developmentCitation}
                    developmentCitationMatchNormalizedCitation={
                      developmentCitationMatchNormalizedCitation
                    }
                  />
                </div>
                {developmentCitationMatchNormalizedCitation.normalizedCitation
                  .obligations &&
                  !!developmentCitationMatchNormalizedCitation
                    .normalizedCitation.obligations.length && (
                    <div style={{ width: '100%' }}>
                      {developmentCitationMatchNormalizedCitation.normalizedCitation.obligations.map(
                        (obligation, y2) => {
                          return (
                            <ObligationChip
                              id={
                                props.id
                                  ? `${props.id}${y}ObligationChip${y2}`
                                  : undefined
                              }
                              key={obligation.id}
                            >
                              {!developmentCitationMatchNormalizedCitation.isDirectMatch && (
                                <span
                                  style={{
                                    margin: 'auto 8px auto 0',
                                    height: '16px',
                                  }}
                                >
                                  {developmentCitationMatchNormalizedCitation.isIndirectChildMatch ? (
                                    <ChildIndicatorIcon
                                      id={
                                        props.id
                                          ? `${props.id}${y}ObligationChipChildIndicatorIcon${y2}`
                                          : undefined
                                      }
                                    />
                                  ) : (
                                    <ParentIndicatorIcon
                                      id={
                                        props.id
                                          ? `${props.id}${y}ObligationChipParentIndicatorIcon${y2}`
                                          : undefined
                                      }
                                    />
                                  )}
                                </span>
                              )}
                              <span
                                id={
                                  props.id
                                    ? `${props.id}${y}ObligationChipText${y2}`
                                    : undefined
                                }
                                style={{
                                  wordBreak: 'break-word',
                                  fontSize: Theme.fontSize - 2,
                                }}
                              >
                                {obligation.name}
                              </span>
                              {obligation.url && (
                                <CursorPointer
                                  id={
                                    props.id
                                      ? `${props.id}${y}ObligationChipUrlButton${y2}`
                                      : undefined
                                  }
                                  style={{
                                    margin: 'auto 0 auto auto',
                                    height: '16px',
                                  }}
                                  onClick={(
                                    e: React.MouseEvent<HTMLElement>
                                  ) => {
                                    e.stopPropagation();
                                    if (obligation.url) {
                                      goToObligationUrl(obligation.url);
                                    }
                                  }}
                                >
                                  <ExternalLinkIcon
                                    id={
                                      props.id
                                        ? `${props.id}${y}ObligationChipUrlIcon${y2}`
                                        : undefined
                                    }
                                    color={Theme.colors.gray}
                                  />
                                </CursorPointer>
                              )}
                            </ObligationChip>
                          );
                        }
                      )}
                    </div>
                  )}
              </FlexContainer>
            </div>
          );
        })}
    </ObligationMatchesCardContainer>
  );
};
