import { useMemo } from 'react';
import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { FlexContainer } from 'styles/utils';
import { millisecondsToHMS } from 'utils/date';
import { formatNumber, formatToPercentage } from 'utils/number-formatter';
import { isNully } from 'utils/var';

interface TurnaroundTimeDetailsSummaryDataProps {
  title: string;
  value: number | null | undefined;
  isPercentageValue?: boolean;
  isTimeValue?: boolean;
  differencePercentage?: number | null;
  isReverseDisadvantage?: boolean;
  isBold?: boolean;
}

const Title = styled.div(({ isBold }: { isBold?: boolean }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  lineHeight: '22px',
  fontSize: '16px',
  fontWeight: isBold ? 'bold' : 'normal',
  marginTop: '4px',
}));

const Value = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  lineHeight: '64px',
  fontSize: '46px',
  fontWeight: 'bold',
});

const ComparisonValue = styled.div((props: { isDisadvantage?: boolean }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  marginLeft: '15px',
  marginBottom: '11px',
  lineHeight: '28px',
  fontSize: '20px',
  color: props.isDisadvantage ? Theme.colors.orange : Theme.colors.green,
}));

export const TurnaroundTimeDetailsSummaryData = (
  props: TurnaroundTimeDetailsSummaryDataProps
) => {
  const value = useMemo(() => {
    switch (true) {
      case isNully(props.value):
        return '-';
      case props.isPercentageValue:
        return formatToPercentage(props.value!);
      case props.isTimeValue:
        return millisecondsToHMS(props.value!);
      default:
        return formatNumber(props.value!);
    }
  }, [props.value, props.isPercentageValue, props.isTimeValue]);

  const differencePercentage = useMemo(
    () =>
      typeof props.differencePercentage === 'number'
        ? formatToPercentage(props.differencePercentage, true)
        : '',
    [props.differencePercentage]
  );

  const isDisadvantage = useMemo(
    () =>
      typeof props.differencePercentage === 'number'
        ? props.isReverseDisadvantage
          ? props.differencePercentage > 0
          : props.differencePercentage < 0
        : false,
    [props.differencePercentage, props.isReverseDisadvantage]
  );

  return (
    <FlexContainer
      flexDirection="column"
      style={{
        width: '100%',
        flexGrow: 1,
        justifyContent: 'space-between',
      }}
    >
      <Title isBold={props.isBold}>{props.title}</Title>
      <FlexContainer style={{ alignItems: 'flex-end' }}>
        <Value>{value}</Value>
        <ComparisonValue isDisadvantage={isDisadvantage}>
          {differencePercentage}
        </ComparisonValue>
      </FlexContainer>
    </FlexContainer>
  );
};
