import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ManagementSidebar,
  ManagementHeader,
  ManagementSubHeader,
  ManagementMainBody,
  ManagementDropdown,
} from 'pages/management/components';
import { FlexContainer } from 'styles/utils';
import { userManagementLabels } from './labels';
import { UserDetail } from './UserDetail';
import { UserEdit } from './UserEdit';
import { ReactComponent as AddToTeamIcon } from 'assets/add-to-team.svg';
import { ReactComponent as GiveAccessIcon } from 'assets/plus-circle.svg';
import { ReactComponent as RevokeAccessIcon } from 'assets/revoke-access.svg';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import {
  AssignToTeamModal,
  AssignToTeamModalTypes,
} from 'components/AssignToTeamModal';
import { CreateUserModal } from 'components/CreateUserModal';
import { UsersTable } from './components';
import {
  getAllManagementUsersAsync,
  selectAllManagementUsers,
  selectAreAllManagementUsersSelected,
  selectManagementUsersPage,
  selectSelectedManagementUsers,
  selectManagementUsersTotalRecords,
  selectManagementUser,
  selectManagementUsers,
  setManagementUsersSearchTerm,
  selectManagementUsersPerPage,
  selectManagementUsersSortByDirection,
  selectManagementUsersSortByProperty,
  selectTriggerManagementUsersUpdate,
  selectManagementUsersSearchTerm,
  setManagementUsersPage,
  revokeUserAccessAsync,
  giveUserAccessAsync,
  toggleDeleteUserModal,
  selectIsDeletingManagementUsers,
  deleteUsersAsync,
  updateUserAsync,
  addUsersToTeamsAsync,
  addSelectedManagementUsersOutsideOfCurrentPage,
  removeSelectedManagementUsersOutsideOfCurrentPage,
  selectSelectedManagementUsersOutsideOfCurrentPage,
} from 'store/management-user/management-user-slice';
import { selectAuthenticationResponse } from 'store/authentication/authentication-slice';
import { setTriggerAssignToTeamModalTeamsUpdate } from 'store/assign-to-team-modal-team/assign-to-team-modal-team-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Row } from 'components/Table/Table';
import { User, Team } from 'models';
import { Pagination } from 'components/Pagination';
import { Loading } from 'components/Loading';
import { ManagementDropdownOption } from '../components/ManagementDropdown';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { Body } from 'components/Typography';
import { UserStatus } from 'enums';
import { ArrowDirections, Tooltip } from 'components/Tooltip/Tooltip';
import styled from '@emotion/styled';

const TooltipContainer = styled.div({
  position: 'relative',
  whiteSpace: 'nowrap',
  display: 'inline',
  height: '100%',
  right: '50%',
});

const DeleteTooltip = memo(
  ({ targetRef }: { targetRef: HTMLElement | null }) => (
    <TooltipContainer id="UserDeleteTooltipContainer" key="DeleteTooltip">
      <Tooltip
        id="UserDeleteTooltip"
        relativeToElement={targetRef}
        arrowDirection={ArrowDirections.Up}
        hasArrow
      >
        <Body id="UserDeleteTooltipMessage" noMargin>
          To delete a user you
        </Body>
        <Body id="UserDeleteTooltipWarning" noMargin bold>
          must revoke access
        </Body>
      </Tooltip>
    </TooltipContainer>
  )
);

export const UserManagement = () => {
  const dispatch = useAppDispatch();
  const authenticationResponse = useAppSelector(selectAuthenticationResponse);
  const users = useAppSelector(selectManagementUsers);
  const areAllSelected = useAppSelector(selectAreAllManagementUsersSelected);
  const page = useAppSelector(selectManagementUsersPage);
  const perPage = useAppSelector(selectManagementUsersPerPage);
  const selectedUsers = useAppSelector(selectSelectedManagementUsers);
  const totalRecords = useAppSelector(selectManagementUsersTotalRecords);
  const searchTerm = useAppSelector(selectManagementUsersSearchTerm);
  const sortByProperty = useAppSelector(selectManagementUsersSortByProperty);
  const sortByDirection = useAppSelector(selectManagementUsersSortByDirection);
  const triggerUsersUpdate = useAppSelector(selectTriggerManagementUsersUpdate);
  const selectedUsersOutsideOfCurrentPage = useAppSelector(
    selectSelectedManagementUsersOutsideOfCurrentPage
  );
  const displayDeleteConfirmationModal = useAppSelector(
    selectIsDeletingManagementUsers
  );

  const sidebarContainerRef = useRef(null);

  const [deleteButtonRef, setDeleteButtonRef] = useState<HTMLElement | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetailSidebar, setIsLoadingDetailSidebar] = useState(false);

  const toggleDeleteConfirmationModal = useCallback(() => {
    dispatch(toggleDeleteUserModal());
  }, []);

  const [isAddToTeamModalDisplayed, setIsAddToTeamModalDisplayed] =
    useState(false);
  const [isCreateUserModalDisplayed, setIsCreateUserModalDisplayed] =
    useState(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [
    isDiscardConfirmationModalDisplayed,
    setIsDiscardConfirmationModalDisplayed,
  ] = useState(false);
  const [addedTeamsForUserEdit, setAddedTeamsForUserEdit] = useState<Team[]>(
    []
  );

  const totalPages = useMemo(
    () => Math.ceil(totalRecords / perPage),
    [totalRecords, perPage]
  );

  const checkedUsers = useMemo(() => {
    return areAllSelected
      ? users.filter(
          (u) =>
            !Object.keys(selectedUsers)
              .map((key) => Number(key))
              .includes(Number(u.id))
        )
      : Object.values(selectedUsers);
  }, [selectedUsers, areAllSelected, users]);

  const handlePageChange = useCallback(
    (page: number) => {
      dispatch(addSelectedManagementUsersOutsideOfCurrentPage(checkedUsers));
      dispatch(setManagementUsersPage(page));
    },
    [checkedUsers]
  );

  useEffect(() => {
    setIsLoading(true);
    dispatch(getAllManagementUsersAsync())
      .then((newUsers) => {
        dispatch(removeSelectedManagementUsersOutsideOfCurrentPage(newUsers));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [page, searchTerm, sortByProperty, sortByDirection, triggerUsersUpdate]);

  const userRows: Row<User>[] = useMemo(() => {
    return users.map((u: User) => ({
      ...u,
      checked: areAllSelected !== !!selectedUsers[u.id || 0],
    }));
  }, [users, selectedUsers, areAllSelected]);

  const usersChecked = useMemo(
    () => Object.keys(selectedUsers).length,
    [selectedUsers]
  );

  const selectedUsersCount = useMemo(
    () => (areAllSelected ? totalRecords - usersChecked : usersChecked),
    [areAllSelected, totalRecords, usersChecked]
  );

  const areSomeUsersChecked = useMemo(
    () => !!usersChecked || areAllSelected,
    [areAllSelected, usersChecked]
  );

  const singleSelectedUser = useMemo(() => {
    if (selectedUsersCount === 1) {
      if (checkedUsers.length === 1) {
        return checkedUsers[0];
      } else {
        if (selectedUsersOutsideOfCurrentPage.length === 1) {
          return selectedUsersOutsideOfCurrentPage[0];
        } else {
          return undefined;
        }
      }
    } else {
      return undefined;
    }
  }, [selectedUsersCount, checkedUsers, selectedUsersOutsideOfCurrentPage]);

  const selectedUsersText = useMemo(
    () =>
      selectedUsersCount > 1
        ? `${selectedUsersCount} users selected`
        : areAllSelected && selectedUsersCount === 1
        ? '1 user selected'
        : undefined,
    [selectedUsersCount, areAllSelected]
  );

  const isCurrentUserSelected = useMemo(
    () =>
      !!authenticationResponse?.id &&
      checkedUsers.some((u) => u.id === authenticationResponse.id),
    [checkedUsers, authenticationResponse]
  );

  const areInviteSentUsersSelected = useMemo(
    () =>
      checkedUsers.some(
        (u) =>
          Number(u.status) === UserStatus.InviteSent && u.status !== undefined
      ),
    [checkedUsers]
  );

  const areActiveUsersSelected = useMemo(
    () => checkedUsers.some((u) => Number(u.status) === UserStatus.Active),
    [checkedUsers]
  );

  const canBulkGiveAccess = useMemo(
    () => !singleSelectedUser || !areInviteSentUsersSelected,
    [singleSelectedUser, areInviteSentUsersSelected]
  );

  const canBulkRevokeAccess = useMemo(
    () =>
      !singleSelectedUser ||
      (!areInviteSentUsersSelected && !isCurrentUserSelected),
    [singleSelectedUser, areInviteSentUsersSelected, isCurrentUserSelected]
  );

  const canBulkDelete = useMemo(
    () =>
      !singleSelectedUser ||
      (!areActiveUsersSelected && !isCurrentUserSelected),
    [singleSelectedUser, areActiveUsersSelected, isCurrentUserSelected]
  );

  useEffect(() => {
    if (!singleSelectedUser) {
      setIsEditing(false);
    }
  }, [singleSelectedUser]);

  const onClickCreateButton = useCallback(() => {
    setIsCreateUserModalDisplayed(true);
  }, []);

  const handleSelectRow = useCallback((row: Row<User>) => {
    dispatch(selectManagementUser(row));
  }, []);

  const handleSelectAll = useCallback(() => {
    dispatch(selectAllManagementUsers());
  }, []);

  const handleSearchTermChange = useCallback(
    (value: string) => dispatch(setManagementUsersSearchTerm(value)),
    []
  );

  const handleToggleEditMode = useCallback(
    (value: boolean) => setIsEditing(value),
    []
  );

  const handleAddToTeamsForSelectedUsers = useCallback(
    async (teams: Team[]) => {
      const selectedTeamIds = teams.map((t) => Number(t.id));
      await dispatch(addUsersToTeamsAsync(false, true, selectedTeamIds));
    },
    [areAllSelected, selectedUsers, users]
  );

  const handleAddToTeamsForUserEdit = useCallback(
    async (teams: Team[]) => setAddedTeamsForUserEdit(teams),
    []
  );

  const handleResetAddedTeamsForUserEdit = useCallback(
    () => setAddedTeamsForUserEdit([]),
    []
  );

  const handleDiscardChanges = useCallback(() => {
    setIsEditing(false);
    setIsDiscardConfirmationModalDisplayed(false);
  }, []);

  const handleCloseDiscardChangesModal = useCallback(
    () => setIsDiscardConfirmationModalDisplayed(false),
    []
  );

  const handleOpenDiscardChangesModal = useCallback(() => {
    if (isEditing && !isAddToTeamModalDisplayed) {
      setIsDiscardConfirmationModalDisplayed(true);
    }
  }, [isEditing, isAddToTeamModalDisplayed]);

  const handleSubmitEditedUser = useCallback(
    async (user: User) => {
      if (isEditing) {
        const updatedUser = await dispatch(updateUserAsync(user));
        if (updatedUser) {
          dispatch(setTriggerAssignToTeamModalTeamsUpdate());
          setIsEditing(false);
          dispatch(selectManagementUser(updatedUser));
        }
      }
    },
    [isEditing]
  );

  const handleGiveAccess = useCallback(async () => {
    setIsLoadingDetailSidebar(true);
    await dispatch(giveUserAccessAsync());
    setIsLoadingDetailSidebar(false);
  }, []);

  const handleRevokeAccess = useCallback(async () => {
    setIsLoadingDetailSidebar(true);
    await dispatch(revokeUserAccessAsync());
    setIsLoadingDetailSidebar(false);
  }, []);

  const handleDeleteUser = useCallback(async () => {
    setIsLoadingDetailSidebar(true);
    toggleDeleteConfirmationModal();
    await dispatch(deleteUsersAsync());
    setIsLoadingDetailSidebar(false);
  }, []);

  const dropdownOptions: ManagementDropdownOption[] = [
    {
      label: 'Add to team',
      icon: <AddToTeamIcon />,
      onClick: () => {
        setIsAddToTeamModalDisplayed(true);
      },
    },
    {
      label: 'Give access',
      icon: <GiveAccessIcon />,
      onClick: handleGiveAccess,
      isDisabled: !canBulkGiveAccess,
    },
    {
      label: 'Revoke access',
      icon: <RevokeAccessIcon />,
      onClick: handleRevokeAccess,
      isDisabled: !canBulkRevokeAccess,
    },
    {
      label: 'Delete',
      icon: <DeleteIcon />,
      onClick: toggleDeleteConfirmationModal,
      isDisabled: !canBulkDelete,
      tooltip: !canBulkDelete ? (
        <DeleteTooltip targetRef={deleteButtonRef} />
      ) : null,
      innerRef: setDeleteButtonRef,
    },
  ];

  return (
    <FlexContainer
      id="UserManagementContainer"
      flexWrap="nowrap"
      flexDirection="column"
      style={{ width: '100%', flex: 1 }}
    >
      <ManagementHeader
        id="UserManagementHeader"
        {...userManagementLabels}
        onClickCreateButton={onClickCreateButton}
      />
      <ManagementSubHeader
        id="UserManagementSubHeader"
        {...userManagementLabels}
        count={totalRecords}
        selectedCount={selectedUsersCount}
        searchTerm={searchTerm}
        onSearchChange={handleSearchTermChange}
      >
        <ManagementDropdown
          id="UserManagementDropdown"
          title={userManagementLabels.dropdownLabel}
          isDisabled={!selectedUsersCount}
          options={dropdownOptions}
        />
      </ManagementSubHeader>
      <FlexContainer
        id="UserManagementContentContainer"
        flexWrap="nowrap"
        flexDirection="row"
        style={{ flex: '1' }}
      >
        <ManagementMainBody id="UserManagementMainBody">
          {isLoading && <Loading id="UserManagementLoading" />}
          <UsersTable
            onSelectAll={handleSelectAll}
            onSelectRow={handleSelectRow}
            rows={userRows}
            areSomeChecked={areSomeUsersChecked}
            spotlitUser={isEditing ? singleSelectedUser : undefined}
            sortDirection={sortByDirection}
            sortField={sortByProperty}
          />
          <Pagination
            id="UserManagementPagination"
            currentPage={page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </ManagementMainBody>
        <ManagementSidebar
          id="UserManagementSidebar"
          {...userManagementLabels}
          containerRef={sidebarContainerRef}
        >
          {(singleSelectedUser && isEditing && (
            <UserEdit
              user={singleSelectedUser}
              editContainerRef={sidebarContainerRef}
              addedTeams={addedTeamsForUserEdit}
              setIsAddToTeamModalDisplayed={setIsAddToTeamModalDisplayed}
              resetAddedTeams={handleResetAddedTeamsForUserEdit}
              onAttemptToCancel={handleOpenDiscardChangesModal}
              onSubmit={handleSubmitEditedUser}
            />
          )) ||
            (singleSelectedUser && (
              <UserDetail
                user={singleSelectedUser}
                isLoading={isLoadingDetailSidebar}
                setIsAddToTeamModalDisplayed={setIsAddToTeamModalDisplayed}
                onEditMode={handleToggleEditMode}
              />
            )) ||
            selectedUsersText}
        </ManagementSidebar>
      </FlexContainer>
      <AssignToTeamModal
        isDisplayed={isAddToTeamModalDisplayed}
        setIsDisplayed={setIsAddToTeamModalDisplayed}
        type={AssignToTeamModalTypes.AddToTeam}
        onAddToTeams={
          isEditing
            ? handleAddToTeamsForUserEdit
            : handleAddToTeamsForSelectedUsers
        }
      />
      <CreateUserModal
        isDisplayed={isCreateUserModalDisplayed}
        setIsDisplayed={setIsCreateUserModalDisplayed}
      />
      <ConfirmationModal
        id="DeleteUserConfirmationModal"
        title={
          selectedUsersCount > 1 ? 'Delete these users?' : 'Delete this user?'
        }
        onPrimaryAction={toggleDeleteConfirmationModal}
        onSecondaryAction={handleDeleteUser}
        isDisplayed={displayDeleteConfirmationModal}
        primaryText="Cancel"
        secondaryText="Yes, delete"
      >
        <Body id="DeleteUserConfirmationModalMessage">
          {selectedUsersCount > 1 ? 'These users' : 'This user'} will be
          permanently removed from the platform,{' '}
        </Body>
        <Body id="DeleteUserConfirmationModalWarning" bold>
          {' '}
          this action can't be undone.{' '}
        </Body>
        <Body id="DeleteUserConfirmationModalAreYouSure">
          Are you sure you wish to delete?
        </Body>
      </ConfirmationModal>
      <ConfirmationModal
        id="DiscardUserChangesConfirmationModal"
        title="Discard changes?"
        onPrimaryAction={handleCloseDiscardChangesModal}
        onSecondaryAction={handleDiscardChanges}
        isDisplayed={isDiscardConfirmationModalDisplayed}
        primaryText="Cancel"
        secondaryText="Yes, discard"
      >
        <Body id="DiscardUserChangesConfirmationMessage">
          Exiting without confirming will discard any changes done.
        </Body>
      </ConfirmationModal>
    </FlexContainer>
  );
};
