import styled from '@emotion/styled';
import { Avatar } from 'components/Avatar';
import { Checkbox } from 'components/Checkbox';
import { Theme } from 'styles/themes';
import { FlexContainer } from 'styles/utils';
import { ReactComponent as UserSecondary } from 'assets/user-secondary.svg';

interface TeamCardProps {
  id: number;
  name: string;
  usersCount: number;
  onSelect: (id: number) => void;
  selectedIds: number[];
  isLast: boolean;
}

const TeamCardContainer = styled.div<{ isLast?: boolean }>((props) => ({
  display: 'flex',
  padding: '7px 16px',
  alignItems: 'center',
  borderBottom: props.isLast ? 'none' : `1px solid ${Theme.colors.grayBorder}`,
}));

const UsersCountText = styled.span({
  color: Theme.colors.secondaryColor,
  lineHeight: '22px',
  marginRight: '4px',
  fontWeight: 'bold',
});

export const TeamCard = (props: TeamCardProps) => {
  return (
    <TeamCardContainer id={`TeamCard${props.id}`} isLast={props.isLast}>
      <div
        id={`TeamCard${props.id}AvatarContainer`}
        style={{ flex: '0 0 32px' }}
      >
        <Avatar id={`TeamCard${props.id}Avatar`} text={props.name.charAt(0)} />
      </div>
      <span
        id={`TeamCard${props.id}Name`}
        style={{ margin: '0 8px', flex: '1 1 auto' }}
      >
        {props.name}
      </span>
      <FlexContainer
        id={`TeamCard${props.id}InfoContainer`}
        style={{
          alignItems: 'center',
          flex: '0 0 103px',
          justifyContent: 'flex-end',
        }}
      >
        <UsersCountText id={`TeamCard${props.id}UsersCount`}>
          ({props.usersCount})
        </UsersCountText>
        <UserSecondary
          id={`TeamCard${props.id}UserIcon`}
          style={{ marginRight: '12px' }}
        />
        <Checkbox
          id={`TeamCard${props.id}UserCheckboxInput`}
          isChecked={props.selectedIds.includes(props.id)}
          onChange={() => props.onSelect(props.id)}
        />
      </FlexContainer>
    </TeamCardContainer>
  );
};
