import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { ellipseText } from 'utils/ellipse-text';
import { nFormatNumber } from 'utils/number-formatter';

interface FeedCardLeftSideProps {
  id?: number;
  title: string;
  newDevelopmentsCount: number;
  isSelected: boolean;
}

const FeedCardLeftSideContainer = styled.div({
  width: '30%',
  height: '100%',
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const FeedTitle = styled.div<{ blue?: boolean }>((props) => ({
  fontWeight: 'bold',
  fontSize: '18px',
  lineHeight: '25px',
  display: 'flex',
  justifyContent: 'center',
  color: props.blue ? Theme.colors.secondaryColor : Theme.colors.black,
  wordBreak: 'break-word',
  textAlign: 'center',
}));

const NewDevelopmentsContainer = styled.div({
  fontSize: '16px',
  lineHeight: '22px',
  display: 'flex',
  justifyContent: 'center',
});

const CountText = styled.span({
  fontWeight: 'bold',
  color: Theme.colors.secondaryColor,
});

const MessageText = styled.span({
  color: Theme.colors.black,
});

export const FeedCardLeftSide = (props: FeedCardLeftSideProps) => {
  const newDevelopmentsCount = nFormatNumber(props.newDevelopmentsCount);

  return (
    <FeedCardLeftSideContainer id={`FeedCardLeftSideContainer${props.id}`}>
      <FeedTitle
        id={`FeedCardLeftSideContainer${props.id}`}
        blue={props.isSelected}
        title={props.title}
      >
        {ellipseText(props.title, 40)}
      </FeedTitle>
      <NewDevelopmentsContainer
        id={`FeedCardLeftSideNewDevelopmentsContainer${props.id}`}
      >
        <CountText id={`FeedCardLeftSideNewDevelopmentsCountText${props.id}`}>
          {newDevelopmentsCount}
        </CountText>
        <MessageText
          id={`FeedCardLeftSideNewDevelopmentsMessageText${props.id}`}
        >
          &nbsp;new developments
        </MessageText>
      </NewDevelopmentsContainer>
    </FeedCardLeftSideContainer>
  );
};
