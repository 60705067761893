import React from 'react';
import { HTMLAttributes, ReactNode } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

interface InfiniteListProps extends HTMLAttributes<any> {
  onReachBottom: () => void;
  hasMorePages: boolean;
  targetRef: ReactNode;
  loader?: ReactNode;
  dataLength?: number;
}

export const InfiniteList = (props: InfiniteListProps) => {
  return (
    <InfiniteScroll
      dataLength={props.dataLength || React.Children.count(props.children)}
      next={props.onReachBottom}
      hasMore={props.hasMorePages}
      loader={props.loader}
      scrollableTarget={props.targetRef}
    >
      {props.children}
    </InfiniteScroll>
  );
};
