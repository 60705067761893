import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAuthenticationResponse } from 'store/authentication/authentication-slice';
import { RecentlyViewedDevelopmentCard } from './components/RecentlyViewedDevelopmentCard';
import {
  increaseRecentlyViewedDevelopmentsPage,
  resetRecentlyViewedDevelopmentsPage,
  selectRecentlyViewedDevelopmentsPage,
  selectRecentlyViewedDevelopmentsPerPage,
  selectRecentlyViewedDevelopmentsTotalRecords,
} from 'store/recently-viewed-development/recently-viewed-development-slice';
import { InfiniteList } from 'components/InfiniteList';
import { routes } from 'navigation/routes';
import { useNavigate } from 'react-router-dom';
import { Loading } from 'components/Loading';
import {
  resetDevelopment,
  resetDevelopmentDetailSearchTerm,
} from 'store/development-detail/development-detail-slice';
import { OverflowYAutoContainer } from 'components/OverflowYAutoContainer';
import { Development } from 'models';

interface RecentlyViewedDevelopmentsListProps {
  developments: Development[];
  onClick?: () => void;
  currentDevelopmentId?: string;
}

export const RecentlyViewedDevelopmentsList = (
  props: RecentlyViewedDevelopmentsListProps
) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const authenticationResponse = useAppSelector(selectAuthenticationResponse);
  const totalRecords = useAppSelector(
    selectRecentlyViewedDevelopmentsTotalRecords
  );
  const perPage = useAppSelector(selectRecentlyViewedDevelopmentsPerPage);
  const page = useAppSelector(selectRecentlyViewedDevelopmentsPage);
  const [_, setDisplayTrigger] = useState(false);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const handleDevelopmentClick = (id: string) => {
    if (props.currentDevelopmentId !== id) {
      navigate(routes.developmentDetail.replace(':developmentId', id));
      dispatch(resetRecentlyViewedDevelopmentsPage());
      dispatch(resetDevelopment());
      dispatch(resetDevelopmentDetailSearchTerm());
    }
    if (props.onClick) {
      props.onClick();
    }
  };

  useEffect(() => {
    if (!!scrollableContainerRef.current) {
      setDisplayTrigger(true);
    }
  }, [scrollableContainerRef.current]);

  return (
    <OverflowYAutoContainer
      id="RecentlyViewedDevelopmentsList"
      ref={scrollableContainerRef}
      style={{ height: '100%', width: '100%' }}
      doesntWaitForMaxHeightToRenderChildren
    >
      {!!props.developments.length && scrollableContainerRef.current && (
        <InfiniteList
          targetRef={scrollableContainerRef.current}
          onReachBottom={() =>
            dispatch(increaseRecentlyViewedDevelopmentsPage())
          }
          hasMorePages={page < Math.ceil(totalRecords / perPage)}
          loader={<Loading id="RecentlyViewedDevelopmentsLoading" />}
        >
          {props.developments.map((development) => (
            <RecentlyViewedDevelopmentCard
              id={`RecentlyViewedDevelopmentCard${development.externalId}`}
              key={development.externalId}
              title={development.title}
              documentType={development.mainDocumentType?.name}
              authority={development.mainAuthority?.name}
              dateViewed={
                development.viewedByUsers?.find(
                  (x) => x.userId === authenticationResponse?.id
                )?.dateViewed
              }
              onClick={() => handleDevelopmentClick(String(development.id))}
            />
          ))}
        </InfiniteList>
      )}
    </OverflowYAutoContainer>
  );
};
