import { httpGet } from 'utils/http/base-http-service';
import { HttpResponse } from 'utils/http/HttpResponse';
import { Region, PaginatedResponse, ActiveFilters, Environment } from 'models';
import { toQueryParams } from 'utils/to-query-params';
import { escapeQueryStringQueryReservedCharacters } from 'utils/elasticsearch';
import { SortByDirection } from 'enums';
import { getDevelopmentsBaseQuery } from 'helpers/filters';
import { filterKeys } from 'config/filter-keys';

const getRegionsBaseQuery = (searchTerm: string) => {
  let query: any = {
    filter: [],
  };
  if (searchTerm) {
    query.filter.push({
      bool: {
        should: [
          {
            query_string: {
              query: `*${escapeQueryStringQueryReservedCharacters(
                searchTerm
              )}*`,
              fields: ['name'],
              minimum_should_match: '100%',
            },
          },
        ],
        minimum_should_match: 1,
      },
    });
  }
  return query;
};

export const getRegionsWithDevelopmentCountGreaterThanZero = async (
  selectedEnvironment: Environment | null,
  searchTerm: string,
  page: number,
  perPage: number,
  activeFilters: ActiveFilters,
  ids?: number[]
): Promise<HttpResponse<PaginatedResponse<Region> | null>> => {
  const query = getRegionsBaseQuery(searchTerm);
  let filterCountersQuery: any = {
    filter: [],
  };
  query.filter.push({
    range: {
      developmentsCount: {
        gt: 0,
      },
    },
  });
  filterCountersQuery = getDevelopmentsBaseQuery(
    activeFilters,
    filterCountersQuery,
    selectedEnvironment,
    [filterKeys.region]
  );
  const queryParameters = toQueryParams({
    defaultQueryParameters: JSON.stringify({
      query: JSON.stringify(query),
      page,
      perPage,
      sortByProperty: 'name.keyword',
      sortByDirection: SortByDirection.ASC,
    }),
    filterCountersQueryParameters: JSON.stringify({
      query: JSON.stringify(filterCountersQuery),
    }),
    idsToFilter: JSON.stringify(ids),
  });
  return httpGet<PaginatedResponse<Region>>(`regions?${queryParameters}`);
};

export const getRegions = async (
  searchTerm: string,
  page: number,
  perPage: number
): Promise<HttpResponse<PaginatedResponse<Region> | null>> => {
  const query = getRegionsBaseQuery(searchTerm);
  const queryParameters = toQueryParams({
    defaultQueryParameters: JSON.stringify({
      query: JSON.stringify(query),
      page,
      perPage,
      sortByProperty: 'name.keyword',
      sortByDirection: SortByDirection.ASC,
    }),
  });
  return httpGet<PaginatedResponse<Region>>(`regions?${queryParameters}`);
};
