import styled from '@emotion/styled';
import { Theme } from 'styles/themes';

export const DropdownOptionsContainer = styled.div({
  width: '208px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: Theme.colors.white,
  borderRadius: Theme.borderRadius,
  boxShadow: `0px 1px 15px ${Theme.colors.grayShadow}`,
  zIndex: 2,
});

export const DropdownOption = styled.div<{ removeDivider?: boolean }>(
  ({ removeDivider }) => ({
    width: '100%',
    display: 'flex',
    height: '48px',
    alignItems: 'center',
    borderTop: `solid ${removeDivider ? 0 : 1}px ${Theme.colors.grayBorder}`,
  })
);

export const DropdownOptionButton = styled.button({
  padding: '12px',
  border: 'none',
  display: 'flex',
  width: '100%',
  cursor: 'pointer',
  backgroundColor: Theme.colors.transparent,
  ':hover': {
    backgroundColor: Theme.colors.blueLightest,
    svg: {
      color: Theme.colors.secondaryColor,
    },
  },
  ':disabled': {
    svg: {
      color: 'inherit',
    },
  },
});

export const DropdownOptionIcon = styled.div({
  width: '24px',
  height: '24px',
  marginRight: '6px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: Theme.colors.grayDark,
});

export const DropdownOptionLabel = styled.div({
  height: '24px',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  fontSize: Theme.fontSize,
  lineHeight: '22px',
});
