import styled from '@emotion/styled';
import { SortByDirectionButton } from 'components/SortByDirectionButton';
import { Table, Tbody, Th, Thead, Tr } from 'components/Table';
import { ReportsTableProps } from 'pages/reports/interfaces/ReportsTableProps';
import { useEffect, useMemo, useState } from 'react';
import {
  selectPerformanceReportPage,
  selectPerformanceReportPreviousPage,
  setPerformanceReportSortByDirection,
  setPerformanceReportSortByProperty,
} from 'store/performance-report/performance-report-slice';
import { Theme } from 'styles/themes';
import { FlexContainer } from 'styles/utils';
import { OverflowYAutoContainer } from 'components/OverflowYAutoContainer';
import { PerformanceReportEntity } from 'models';
import { InfiniteList } from 'components/InfiniteList';
import { useAppSelector } from 'store/hooks';
import { PerformanceReportEntities } from 'enums';
import { reportByOptions } from '../ReportByDropdown/ReportByDropdown';
import { PerformanceRow } from './components';

interface PerformanceTableProps
  extends ReportsTableProps<PerformanceReportEntity> {
  onReachBottom: () => void;
  reportByProperty: PerformanceReportEntities;
  hasMoreRows: boolean;
}

const StyledTable = styled(Table)({
  border: `1px solid ${Theme.colors.grayBorder}`,
  position: 'relative',
  boxShadow: 'none',
});

const StyledTr = styled(Tr)({
  height: '48px',
});

const StyledTh = styled(Th)({
  border: `1px solid ${Theme.colors.grayBorder}`,
  borderCollapse: 'collapse',
});

export const PerformanceTable = (props: PerformanceTableProps) => {
  const { sortDirection, sortField, rows } = props;
  const page = useAppSelector(selectPerformanceReportPage);
  const previousPage = useAppSelector(selectPerformanceReportPreviousPage);
  const [scrollableContainer, setScrollableContainer] =
    useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (page === 1 && previousPage === 1) {
      scrollableContainer?.scrollTo({
        top: 0,
      });
    }
  }, [page, previousPage]);

  const reportTypeText = useMemo(
    () =>
      reportByOptions.find((o) => o.value === props.reportByProperty)?.text ||
      '',
    [props.reportByProperty]
  );

  return (
    <OverflowYAutoContainer
      ref={setScrollableContainer}
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      {scrollableContainer && (
        <InfiniteList
          targetRef={scrollableContainer}
          onReachBottom={props.onReachBottom}
          hasMorePages={props.hasMoreRows}
          dataLength={rows.length}
        >
          <StyledTable>
            <Thead>
              <StyledTr>
                <StyledTh width="228px" padding="12px 16px">
                  {reportTypeText}
                </StyledTh>
                <StyledTh width="152px" padding="12px 16px">
                  <FlexContainer>
                    New devs
                    <SortByDirectionButton
                      propertyToSortBy="newDevelopmentsCount"
                      currentPropertyBeingSorted={sortField}
                      currentDirectionBeingSorted={sortDirection}
                      setSortByProperty={setPerformanceReportSortByProperty}
                      setSortByDirection={setPerformanceReportSortByDirection}
                    />
                  </FlexContainer>
                </StyledTh>
              </StyledTr>
            </Thead>
            <Tbody>
              {rows.map((row) => (
                <PerformanceRow
                  key={row.id}
                  data={row}
                  isCountry={
                    props.reportByProperty === PerformanceReportEntities.Country
                  }
                />
              ))}
            </Tbody>
          </StyledTable>
        </InfiniteList>
      )}
    </OverflowYAutoContainer>
  );
};
