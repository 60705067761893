import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useRef,
  useState,
} from 'react';
import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { Feed } from 'models';
import { formatNumber } from 'utils/number-formatter';
import { ReactComponent as OptionsIcon } from 'assets/options.svg';
import { ReactComponent as EditIcon } from 'assets/edit.svg';
import { ReactComponent as DuplicateIcon } from 'assets/copy.svg';
import { BlueChipList } from 'components/Chip';
import { Body } from 'components/Typography';
import {
  Dropdown,
  DropdownOptionsContainer,
  DropdownOption,
  DropdownOptionButton,
  DropdownOptionIcon,
  DropdownOptionLabel,
} from 'components/Dropdown';
import { HTMLAttrID } from 'models';

interface ISelectableProps {
  isSelected?: boolean;
}

interface IHighlightableProps {
  isHighlighted?: boolean;
}

interface FeedShortCardDropdownOptionsProps {
  onEditFeed?: () => void;
  onDuplicate?: () => void;
}

interface FeedShortCardDropdownProps
  extends FeedShortCardDropdownOptionsProps,
    HTMLAttrID {
  dropdownContainerRef: MutableRefObject<any>;
  isDropdownOpen: boolean;
  setIsDropdownOpen: Dispatch<SetStateAction<boolean>>;
}

export interface FeedShortCardProps extends ISelectableProps, HTMLAttrID {
  feed: Feed;
  onClick?: () => void;
  options?: FeedShortCardDropdownOptionsProps;
}

const CardContainer = styled.div<ISelectableProps>(({ isSelected }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: Theme.borderRadius,
  border: `1px solid ${
    isSelected ? Theme.colors.secondaryColor : Theme.colors.grayBorder
  }`,
  overflow: 'hidden',
  cursor: 'pointer',
}));

const TitleContainer = styled.div<ISelectableProps & IHighlightableProps>(
  ({ isSelected, isHighlighted }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '2px 2px 2px 8px',
    borderBottom: `1px solid ${
      isSelected ? Theme.colors.secondaryColor : Theme.colors.grayBorder
    }`,
    backgroundColor:
      isSelected || isHighlighted
        ? Theme.colors.blueLightest
        : Theme.colors.primaryBackgroundColor,
    color: isSelected ? Theme.colors.secondaryColor : Theme.colors.grayDarkest,
    fontWeight: 'bold',
  })
);

const OptionsButton = styled.button<ISelectableProps & IHighlightableProps>(
  ({ isSelected, isHighlighted }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 10px',
    border: `none`,
    backgroundColor: Theme.colors.transparent,
    color:
      isSelected || isHighlighted
        ? Theme.colors.secondaryColor
        : Theme.colors.grayDark,
    cursor: 'pointer',
  })
);

const ContentContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px',
  gap: '8px',
  backgroundColor: Theme.colors.white,
});

const FieldContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const ChipListContainer = styled.div({
  display: 'flex',
  marginTop: '10px',
  marginLeft: '-2px',
});

const FeedShortCardDropdown = (props: FeedShortCardDropdownProps) => {
  return (
    <Dropdown
      id={props.id}
      isOpen={props.isDropdownOpen}
      setIsOpen={props.setIsDropdownOpen}
      containerRef={props.dropdownContainerRef}
      transform="translate(-25%, -109px)"
      style={{ border: `1px solid ${Theme.colors.grayBorder}` }}
    >
      <DropdownOptionsContainer id={`${props.id}OptionsContainer`}>
        <DropdownOption id={`${props.id}OptionEditFeed`} removeDivider>
          <DropdownOptionButton
            id={`${props.id}OptionEditFeedButton`}
            onClick={props.onEditFeed}
            disabled={!props.onEditFeed}
          >
            <DropdownOptionIcon id={`${props.id}OptionEditFeedIconContainer`}>
              <EditIcon id={`${props.id}OptionEditFeedIcon`} />
            </DropdownOptionIcon>
            <DropdownOptionLabel id={`${props.id}OptionEditFeedLabel`}>
              Edit Feed
            </DropdownOptionLabel>
          </DropdownOptionButton>
        </DropdownOption>
        <DropdownOption id={`${props.id}OptionDuplicate`}>
          <DropdownOptionButton
            id={`${props.id}OptionDuplicateButton`}
            onClick={props.onDuplicate}
            disabled={!props.onDuplicate}
          >
            <DropdownOptionIcon id={`${props.id}OptionDuplicateIconContainer`}>
              <DuplicateIcon id={`${props.id}OptionDuplicateIcon`} />
            </DropdownOptionIcon>
            <DropdownOptionLabel id={`${props.id}OptionDuplicateLabel`}>
              Duplicate
            </DropdownOptionLabel>
          </DropdownOptionButton>
        </DropdownOption>
      </DropdownOptionsContainer>
    </Dropdown>
  );
};

export const FeedShortCard = (props: FeedShortCardProps) => {
  const dropdownContainerRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleToggleDropdown = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setIsDropdownOpen(!isDropdownOpen);
    },
    [isDropdownOpen]
  );

  const handleClickOnCard = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (isDropdownOpen) {
        e.stopPropagation();
        setIsDropdownOpen(false);
      } else if (props.onClick) {
        props.onClick();
      }
    },
    [props.onClick, isDropdownOpen]
  );

  const dropdownOptions = props.options || {};

  const title = props.feed.name || 'No name';
  const filtersApplied = formatNumber(props.feed.filtersCount || 0);
  const developmentsPerDay = formatNumber(
    props.feed.averageNewDevelopmentsPerDayCount
      ? props.feed.averageNewDevelopmentsPerDayCount > 1
        ? Math.round(props.feed.averageNewDevelopmentsPerDayCount)
        : 1
      : 0
  );

  return (
    <CardContainer
      id={props.id}
      ref={dropdownContainerRef}
      isSelected={props.isSelected}
      onClick={handleClickOnCard}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <TitleContainer
        id={`${props.id}TitleContainer`}
        isSelected={props.isSelected}
        isHighlighted={isHovered}
      >
        <div id={`${props.id}Title`}>{title}</div>
        {props.options && (
          <OptionsButton
            id={`${props.id}OptionsButton`}
            isSelected={props.isSelected}
            isHighlighted={isHovered}
            onClick={handleToggleDropdown}
          >
            <OptionsIcon id={`${props.id}OptionsIcon`} />
          </OptionsButton>
        )}
      </TitleContainer>
      <ContentContainer id={`${props.id}ContentContainer`}>
        <FieldContainer id={`${props.id}FiltersAppliedContainer`}>
          <div id={`${props.id}FiltersAppliedLabel`}>Filters applied</div>
          <div id={`${props.id}FiltersApplied`}>{filtersApplied}</div>
        </FieldContainer>
        <FieldContainer id={`${props.id}DevelopmentsPerDayContainer`}>
          <div id={`${props.id}DevelopmentsPerDayLabel`}>
            Developments per day
          </div>
          <div id={`${props.id}DevelopmentsPerDay`}>{developmentsPerDay}</div>
        </FieldContainer>
        <ChipListContainer id={`${props.id}ChipListContainer`}>
          {props.feed.teamFeeds?.length ? (
            <BlueChipList
              id={`${props.id}BlueChipList`}
              items={props.feed.teamFeeds}
              getLabel={(teamFeed) => teamFeed.teamName}
              maxWidth={275}
            />
          ) : (
            <div style={{ marginLeft: '4px' }}>
              <Body
                id={`${props.id}NoTeamAssignedMessage`}
                noMargin
                color="grayDark"
                textStyle="italic"
              >
                No team assigned
              </Body>
            </div>
          )}
        </ChipListContainer>
      </ContentContainer>
      <div id={`${props.id}DropdownContainer`} style={{ position: 'relative' }}>
        <FeedShortCardDropdown
          id={`${props.id}Dropdown`}
          dropdownContainerRef={dropdownContainerRef}
          isDropdownOpen={isDropdownOpen}
          setIsDropdownOpen={setIsDropdownOpen}
          {...dropdownOptions}
        />
      </div>
    </CardContainer>
  );
};
