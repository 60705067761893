import { Environment } from 'models';
import { httpGet, httpPost } from 'utils/http/base-http-service';
import { HttpResponse } from 'utils/http/HttpResponse';

export const getCurrentUserEnvironments = async (
  accessToken: string
): Promise<HttpResponse<Environment[] | null>> => {
  return await httpGet<Environment[]>(
    'environments/current-user-environments',
    false,
    {
      Authorization: `Bearer ${accessToken}`,
    }
  );
};

export const getDevelopmentEnvironment = async (
  developmentId: number,
  accessToken: string
): Promise<HttpResponse<Environment | null>> => {
  return await httpGet<Environment>(
    `environments/current-user-environment-by-development/${developmentId}`,
    false,
    {
      Authorization: `Bearer ${accessToken}`,
    }
  );
};

export const toggleChannel = async (
  environmentElasticsearchIndexPrefix: string,
  rowId: string
): Promise<HttpResponse<string | null>> => {
  return await httpPost<
    string,
    {
      environmentElasticsearchIndexPrefix: string;
      rowId: string;
    }
  >(`environments/toggle-channel`, {
    environmentElasticsearchIndexPrefix,
    rowId,
  });
};

export const environmentsAssignUnassign = async (
  assignments: [[number, number[]]] | [],
  unassignments: [[number, number[]]] | []
): Promise<HttpResponse<number | null>> => {
  return await httpPost<
    number,
    {
      assignments: [[number, number[]]] | [];
      unassignments: [[number, number[]]] | [];
    }
  >(`environments/assign-unassign`, {
    assignments,
    unassignments,
  });
};
