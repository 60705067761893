import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { DropdownChip } from 'components/Chip';

export const DropdownOrangeChip = styled(DropdownChip)((props) => ({
  backgroundColor: Theme.colors.orangeLightest,
  border: `1px solid ${
    props.isSelected ? Theme.colors.orangeLight : Theme.colors.transparent
  }`,
  color: Theme.colors.orangeDark,
}));
