import {
  useEffect,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
  MutableRefObject,
  useCallback,
} from 'react';
import {
  ManagementDetail,
  DetailButton,
  TitleValue,
} from 'pages/management/components';
import { Input } from 'components/Input';
import { SelectSearchbar } from 'components/SelectSearchbar';
import { ClosableBlueChip } from 'components/Chip';
import { ReactComponent as CancelIcon } from 'assets/cancel.svg';
import { ReactComponent as ConfirmIcon } from 'assets/confirm-edit.svg';
import { ReactComponent as AddIcon } from 'assets/plus-circle.svg';
import { Theme } from 'styles/themes';
import { FlexContainer } from 'styles/utils';
import { getSelectSearchbarCountries } from 'services/countries/countries-service';
import { User, Team } from 'models';
import { useOnClickOutside } from 'hooks/use-on-click-outside';
import { validateTextInput } from 'utils/input-validation';
import { paginationConfig } from 'config/pagination-config';
import { ManagerTooltipContent } from 'components/ManagerTooltip/ManagerTooltip';
import { CheckboxInput } from 'components/CheckboxInput';
import { ManagementSectionProps } from '../components/ManagementDetail';

export const UserEdit = ({
  user,
  editContainerRef,
  addedTeams,
  setIsAddToTeamModalDisplayed,
  onAttemptToCancel,
  resetAddedTeams,
  onSubmit,
}: UserEditProps) => {
  useOnClickOutside(editContainerRef, onAttemptToCancel);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean | undefined>(undefined);
  const [invalidFirstNameError, setInvalidFirstNameError] =
    useState<string>('');
  const [invalidLastNameError, setInvalidLastNameError] = useState<string>('');
  const [invalidCityNameError, setInvalidCityNameError] = useState<string>('');
  const [firstName, setFirstName] = useState<string>(user.firstName);
  const [lastName, setLastName] = useState<string>(user.lastName);
  const [countryIds, setCountryIds] = useState<number[]>(
    user.countryId ? [user.countryId] : []
  );
  const [city, setCity] = useState<string>(user.city);
  const [isManager, setIsManager] = useState<boolean>(!!user.isManager);
  const [teams, setTeams] = useState<Team[]>(user.teams ?? []);

  const isAnyFieldWithError = useMemo(() => {
    return (
      !!invalidFirstNameError ||
      !!invalidLastNameError ||
      !!invalidCityNameError
    );
  }, [invalidFirstNameError, invalidLastNameError, invalidCityNameError]);

  const canSubmit = useMemo(
    () =>
      isDirty &&
      firstName &&
      lastName &&
      countryIds.length &&
      countryIds[0] &&
      city &&
      !isAnyFieldWithError,
    [isDirty, firstName, lastName, countryIds, city, isAnyFieldWithError]
  );

  useEffect(() => {
    if (isDirty === undefined) {
      setIsDirty(false);
    } else {
      setIsDirty(true);
    }
  }, [firstName, lastName, countryIds, city, isManager, teams]);

  useEffect(() => {
    validateTextInput(firstName, setInvalidFirstNameError);
  }, [firstName]);

  useEffect(() => {
    validateTextInput(lastName, setInvalidLastNameError);
  }, [lastName]);

  useEffect(() => {
    validateTextInput(city, setInvalidCityNameError);
  }, [city]);

  useEffect(() => {
    if (addedTeams.length) {
      const currentTeamsIds = teams.map((t) => t.id);
      const finalAddedTeams = addedTeams.filter(
        (t) => !currentTeamsIds.includes(t.id)
      );
      setTeams(teams.concat(finalAddedTeams));
      resetAddedTeams();
    }
  }, [addedTeams]);

  const handleRemoveTeam = useCallback((team: Team, from: Team[]) => {
    const newTeams = from.filter((t: Team) => t.id !== team.id);
    setTeams(newTeams);
  }, []);

  const handleConfirmEdit = useCallback(async () => {
    if (canSubmit) {
      setIsLoading(true);
      const countryId = countryIds[0] || 0;
      const teamIds = teams.map((t) => t.id as number);
      const editedUser: User = {
        ...user,
        firstName,
        lastName,
        countryId,
        city,
        isManager,
        teams,
        teamIds,
      };
      await onSubmit(editedUser);
      setIsLoading(false);
    }
  }, [
    canSubmit,
    firstName,
    lastName,
    countryIds,
    city,
    isManager,
    teams,
    user,
  ]);

  const teamList = useMemo(
    () =>
      teams.length ? (
        <FlexContainer
          id="UserManagementEditTeamList"
          style={{ marginTop: '16px', gap: '8px' }}
        >
          {teams.map((t) => (
            <ClosableBlueChip
              id={`UserManagementEditTeamListBlueChip${t.id}`}
              key={t.id}
              isSelected
              onClickCloseButton={() => handleRemoveTeam(t, teams)}
              style={{
                maxHeight: '28px',
              }}
            >
              {t.name}
            </ClosableBlueChip>
          ))}
        </FlexContainer>
      ) : (
        <FlexContainer id="UserManagementEditTeamList" />
      ),
    [teams]
  );

  const sections: ManagementSectionProps[] = [
    {
      children: [
        <TitleValue
          id="UserManagementEditName"
          key="EditName"
          title="Name"
          value={
            <Input
              id="id-edit-first-name"
              placeholder="Enter name..."
              value={firstName}
              setValue={setFirstName}
              maxLength={255}
              error={invalidFirstNameError}
              containerStyle={{ marginTop: '4px' }}
              style={{
                borderColor:
                  firstName && !invalidFirstNameError
                    ? Theme.colors.grayBorder
                    : Theme.colors.red,
              }}
            />
          }
        />,
      ],
    },
    {
      children: [
        <TitleValue
          id="UserManagementEditLastName"
          key="EditLastName"
          title="Last name"
          value={
            <Input
              id="id-edit-last-name"
              placeholder="Enter last name..."
              value={lastName}
              setValue={setLastName}
              maxLength={255}
              error={invalidLastNameError}
              containerStyle={{ marginTop: '4px' }}
              style={{
                borderColor:
                  lastName && !invalidLastNameError
                    ? Theme.colors.grayBorder
                    : Theme.colors.red,
              }}
            />
          }
        />,
      ],
    },
    {
      children: [
        <TitleValue
          id="UserManagementEditCountry"
          key="EditCountry"
          title="Jurisdiction"
          value={
            <div
              style={{
                marginTop: '3px',
                border: countryIds.length
                  ? `1px solid ${Theme.colors.transparent}`
                  : `1px solid ${Theme.colors.red}`,
                borderRadius: Theme.borderRadius,
              }}
            >
              <SelectSearchbar
                id="id-edit-country"
                getAllRecords={getSelectSearchbarCountries}
                placeholder="Select jurisdiction..."
                recordsPropertyToDisplay="name"
                recordsPropertyToSortBy="name.keyword"
                values={countryIds}
                setValues={setCountryIds}
                preSelectedLabel={user.countryName}
                perPage={paginationConfig.countriesDropdownPerPage}
              />
            </div>
          }
        />,
      ],
    },
    {
      children: [
        <TitleValue
          id="UserManagementEditCity"
          key="EditCity"
          title="City"
          value={
            <Input
              id="id-edit-city"
              placeholder="Enter city..."
              value={city}
              setValue={setCity}
              maxLength={255}
              error={invalidCityNameError}
              containerStyle={{ marginTop: '4px' }}
              style={{
                borderColor:
                  city && !invalidCityNameError
                    ? Theme.colors.grayBorder
                    : Theme.colors.red,
              }}
            />
          }
        />,
      ],
    },
    {
      children: [
        <TitleValue
          id="UserManagementEditIsManager"
          key="EditIsManager"
          value={
            <CheckboxInput
              label="Assign as manager"
              isSelected={isManager}
              setIsSelected={setIsManager}
              style={{
                border: 'none',
                padding: 0,
              }}
              hasTooltip
              tooltipContent={<ManagerTooltipContent />}
              tooltipStyles={{ transform: 'translateX(-41%)' }}
            />
          }
        />,
      ],
    },
    {
      children: [
        <TitleValue
          id="UserManagementEditTeams"
          key="EditTeams"
          title="Teams"
          count={teams.length}
          buttonIcon={<AddIcon />}
          onClickButton={() => setIsAddToTeamModalDisplayed(true)}
          value={teamList}
        />,
      ],
    },
  ];

  const editingUserButtons = [
    <DetailButton
      id="UserManagementEditCancelButton"
      key="Cancel"
      label="Cancel"
      icon={<CancelIcon />}
      color={Theme.colors.red}
      onClick={onAttemptToCancel}
      removeDivider
    />,
    <DetailButton
      id="UserManagementEditConfirmEditButton"
      key="ConfirmEdit"
      label="Confirm edit"
      icon={<ConfirmIcon />}
      color={Theme.colors.secondaryColor}
      onClick={canSubmit ? handleConfirmEdit : undefined}
      isDisabled={!canSubmit}
    />,
  ];

  return (
    <ManagementDetail
      id="UserManagementEdit"
      sections={sections}
      buttons={editingUserButtons}
      isLoading={isLoading}
    />
  );
};

interface UserEditProps {
  user: User;
  editContainerRef: MutableRefObject<any>;
  addedTeams: Team[];
  setIsAddToTeamModalDisplayed: Dispatch<SetStateAction<boolean>>;
  resetAddedTeams: () => void;
  onAttemptToCancel: () => void;
  onSubmit: (user: User) => Promise<void>;
}
