import styled from '@emotion/styled';
import { Theme } from 'styles/themes';

export const OverflowXContainer = styled.div<{
  isScrollDisplayed?: boolean;
}>((props) => {
  return {
    marginRight: '10px',
    whiteSpace: 'nowrap',
    overflowX: props.isScrollDisplayed ? 'scroll' : 'hidden',
    '&::-webkit-scrollbar': props.isScrollDisplayed
      ? {
          height: '6px',
        }
      : undefined,
    '&::-webkit-scrollbar-thumb': props.isScrollDisplayed
      ? {
          backgroundColor: Theme.colors.grayShadow,
          borderRadius: Theme.borderRadius,
        }
      : undefined,
    '&::-webkit-scrollbar-thumb:active': props.isScrollDisplayed
      ? {
          backgroundColor: Theme.colors.gray,
        }
      : undefined,
    '&::-moz-scrollbar': props.isScrollDisplayed
      ? {
          height: '6px',
        }
      : undefined,
  };
});
