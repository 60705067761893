import { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as StackSquaresIcon } from 'assets/stack-squares.svg';
import { Row, Td, Tr } from 'components/Table';
import { TurnaroundTimeDataByTime } from 'models';
import { Theme } from 'styles/themes';
import { FlexContainer } from 'styles/utils';
import { formatNumber } from 'utils/number-formatter';
import { TurnaroundPageBucketTypes } from 'enums/TurnaroundPageBucketTypes';
import { TurnaroundTimeBucketTypes } from 'enums/TurnaroundTimeBucketTypes';
import { turnaroundPageSummaryLabels } from '../labels';

interface TurnaroundTimeExtendedRowProps {
  data: TurnaroundTimeDataByTime;
  pageBucketType: TurnaroundPageBucketTypes;
}

const StyledTr = styled(Tr)({
  height: '32px',
});

const StyledTd = styled(Td)({
  border: `1px solid ${Theme.colors.grayBorder}`,
  borderCollapse: 'collapse',
  padding: '16px',
  textAlign: 'end',
});

const TdText = styled.span({
  fontSize: Theme.fontSize,
});

export const TurnaroundTimeExtendedRow = (
  props: TurnaroundTimeExtendedRowProps
) => {
  const { data } = props;
  const [isHighlighted, setHighlighted] = useState(false);

  const handleHighlightChange = useCallback(
    (highlight: boolean) => () => setHighlighted(highlight),
    [setHighlighted]
  );

  return (
    <StyledTr
      onMouseEnter={handleHighlightChange(true)}
      onMouseLeave={handleHighlightChange(false)}
      isHighlighted={isHighlighted}
    >
      <StyledTd>
        <FlexContainer
          style={{ alignItems: 'center', flexFlow: 'row', marginLeft: '7px' }}
        >
          <StackSquaresIcon style={{ marginRight: '12px' }} />
          <TdText>{turnaroundPageSummaryLabels[props.pageBucketType]}</TdText>
        </FlexContainer>
      </StyledTd>
      <StyledTd>
        <TdText>
          {formatNumber(data[TurnaroundTimeBucketTypes.Within2hours])}
        </TdText>
      </StyledTd>
      <StyledTd>
        <TdText>
          {formatNumber(data[TurnaroundTimeBucketTypes.Within24hours])}
        </TdText>
      </StyledTd>
      <StyledTd>
        <TdText>
          {formatNumber(data[TurnaroundTimeBucketTypes.Within36hours])}
        </TdText>
      </StyledTd>
      <StyledTd>
        <TdText>
          {formatNumber(data[TurnaroundTimeBucketTypes.Within60hours])}
        </TdText>
      </StyledTd>
      <StyledTd>
        <TdText>
          {formatNumber(data[TurnaroundTimeBucketTypes.Within4days])}
        </TdText>
      </StyledTd>
      <StyledTd>
        <TdText>{formatNumber(data.totalDevelopments)}</TdText>
      </StyledTd>
    </StyledTr>
  );
};
