import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { FlexContainer } from 'styles/utils';

interface ReportSummaryBlockProps {
  children: JSX.Element;
  hasDarkBars?: boolean;
}

const BlockBar = styled.div((props: { isThick?: boolean; color: string }) => ({
  width: '100%',
  height: props.isThick ? '4px' : '0',
  border: props.isThick ? 'none' : `solid 1px ${props.color}`,
  backgroundColor: props.color,
}));

export const ReportSummaryBlock = (props: ReportSummaryBlockProps) => {
  const barsColor = props.hasDarkBars
    ? Theme.colors.black
    : Theme.colors.grayDark;

  return (
    <FlexContainer
      flexDirection="column"
      style={{
        height: '110px',
        padding: '0 4px',
        flexGrow: 1,
        justifyContent: 'space-between',
      }}
    >
      <BlockBar color={barsColor} />
      {props.children}
      <BlockBar color={barsColor} isThick />
    </FlexContainer>
  );
};
