import { ManagementLabels } from 'pages/management/interfaces/ManagementLabels';

export const userManagementLabels: ManagementLabels = {
  title: 'User management',
  createButtonLabel: 'Create new user',
  searchBarPlaceholder: 'Search users...',
  countLabel: 'Users',
  selectedCountLabel: 'Selected',
  dropdownLabel: 'User options',
  placeholderTitle: 'User details',
  placeholderDescriptionFirstLine: 'Select a user to display',
  placeholderDescriptionSecondLine: 'their details here',
};
