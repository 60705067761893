import styled from '@emotion/styled';
import { SortByDirectionButton } from 'components/SortByDirectionButton';
import { Table, Tbody, Th, Thead, Tr } from 'components/Table';
import { ReportsTableProps } from 'pages/reports/interfaces/ReportsTableProps';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  selectTurnaroundTimeReportPage,
  selectTurnaroundTimeReportPreviousPage,
  setTurnaroundTimeReportSortByDirection,
  setTurnaroundTimeReportSortByProperty,
  getEntityExtendedDataAsync,
  setTurnaroundTimeReportRowExtendedData,
} from 'store/turnaround-time-report/turnaround-time-report-slice';
import { Theme } from 'styles/themes';
import { FlexContainer } from 'styles/utils';
import { OverflowYAutoContainer } from 'components/OverflowYAutoContainer';
import { PerformanceReportEntity } from 'models';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { PerformanceReportEntities } from 'enums';
import { reportByOptions } from '../../components/ReportByDropdown';
import { TurnaroundTimeRow } from './TurnaroundTimeRow';
import { TurnaroundTimeExtendedRow } from './TurnaroundTimeExtendedRow';
import { TurnaroundPageBucketTypes } from '../../../../enums/TurnaroundPageBucketTypes';

interface TurnaroundTimeTableProps
  extends ReportsTableProps<PerformanceReportEntity> {
  onReachBottom: () => void;
  reportByProperty: PerformanceReportEntities;
  hasMoreRows: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

const StyledTable = styled(Table)({
  border: `1px solid ${Theme.colors.grayBorder}`,
  position: 'relative',
  boxShadow: 'none',
});

const StyledTr = styled(Tr)({
  height: '48px',
});

const StyledTh = styled(Th)({
  border: `1px solid ${Theme.colors.grayBorder}`,
  borderCollapse: 'collapse',
});

export const TurnaroundTimeTable = (props: TurnaroundTimeTableProps) => {
  const dispatch = useAppDispatch();
  const page = useAppSelector(selectTurnaroundTimeReportPage);
  const previousPage = useAppSelector(selectTurnaroundTimeReportPreviousPage);
  const [scrollableContainer, setScrollableContainer] =
    useState<HTMLDivElement | null>(null);
  const { sortDirection, sortField, rows } = props;

  useEffect(() => {
    if (page === 1 && previousPage === 1) {
      scrollableContainer?.scrollTo({
        top: 0,
      });
    }
  }, [page, previousPage]);

  const reportTypeText = useMemo(
    () =>
      reportByOptions.find((o) => o.value === props.reportByProperty)?.text ||
      '',
    [props.reportByProperty]
  );

  const handleClickArrow = useCallback(
    (id: number, isToExpand: boolean) => {
      props.setIsLoading(true);
      if (isToExpand) {
        dispatch(getEntityExtendedDataAsync(id, props.reportByProperty)).then(
          () => {
            props.setIsLoading(false);
          }
        );
      } else {
        dispatch(setTurnaroundTimeReportRowExtendedData({ id }));
        props.setIsLoading(false);
      }
    },
    [props.reportByProperty, props.setIsLoading]
  );

  return (
    <OverflowYAutoContainer
      ref={setScrollableContainer}
      style={{
        height: '100%',
        width: '100%',
        borderBottom: `solid 1px ${Theme.colors.grayBorder}`,
      }}
    >
      {scrollableContainer && (
        <StyledTable>
          <Thead>
            <StyledTr>
              <StyledTh padding="12px 16px">{reportTypeText}</StyledTh>
              <StyledTh padding="12px 16px" style={{ fontWeight: 'normal' }}>
                <FlexContainer style={{ justifyContent: 'flex-end' }}>
                  Within 2 hrs
                </FlexContainer>
              </StyledTh>
              <StyledTh padding="12px 16px" style={{ fontWeight: 'normal' }}>
                <FlexContainer style={{ justifyContent: 'flex-end' }}>
                  Within 24 hrs
                </FlexContainer>
              </StyledTh>
              <StyledTh padding="12px 16px" style={{ fontWeight: 'normal' }}>
                <FlexContainer style={{ justifyContent: 'flex-end' }}>
                  Within 36 hrs
                </FlexContainer>
              </StyledTh>
              <StyledTh padding="12px 16px" style={{ fontWeight: 'normal' }}>
                <FlexContainer style={{ justifyContent: 'flex-end' }}>
                  Within 60 hrs
                </FlexContainer>
              </StyledTh>
              <StyledTh padding="12px 16px" style={{ fontWeight: 'normal' }}>
                <FlexContainer style={{ justifyContent: 'flex-end' }}>
                  Within 4 days
                </FlexContainer>
              </StyledTh>
              <StyledTh padding="12px 16px">
                <FlexContainer style={{ justifyContent: 'flex-end' }}>
                  Total
                  {/* <SortByDirectionButton
                    propertyToSortBy="newDevelopmentsCount"
                    currentPropertyBeingSorted={sortField}
                    currentDirectionBeingSorted={sortDirection}
                    setSortByProperty={setPerformanceReportSortByProperty}
                    setSortByDirection={setPerformanceReportSortByDirection}
                  /> */}
                </FlexContainer>
              </StyledTh>
            </StyledTr>
          </Thead>
          <Tbody>
            {rows.map((row) => {
              const tableRows: JSX.Element[] = [
                <TurnaroundTimeRow
                  key={`row_${row.id}`}
                  data={row}
                  onClickRow={props.onClickRow}
                  onClickArrow={handleClickArrow}
                  isCountry={
                    props.reportByProperty === PerformanceReportEntities.Country
                  }
                />,
              ];
              const bucketToExclude =
                TurnaroundPageBucketTypes.MoreThan1700Pages;
              if (row.turnaroundTimeExtendedData) {
                const { turnaroundTimeExtendedData } = row;
                for (const bucket in turnaroundTimeExtendedData) {
                  if (
                    (bucket as TurnaroundPageBucketTypes) !== bucketToExclude
                  ) {
                    tableRows.push(
                      <TurnaroundTimeExtendedRow
                        key={`${bucket}_${row.id}`}
                        data={
                          turnaroundTimeExtendedData[
                            bucket as TurnaroundPageBucketTypes
                          ]
                        }
                        pageBucketType={bucket as TurnaroundPageBucketTypes}
                      />
                    );
                  }
                }
              }
              return tableRows;
            })}
          </Tbody>
        </StyledTable>
      )}
    </OverflowYAutoContainer>
  );
};
