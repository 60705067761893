import { useMemo, useRef, useState } from 'react';
import { BoldText, CursorPointer, FlexContainer } from 'styles/utils';
import { ReactComponent as ArrowDown } from 'assets/arrow-down.svg';
import { Dropdown } from 'components/Dropdown';
import {
  RadioButtonGroup,
  RadioButtonGroupOption,
} from 'components/RadioButtonGroup';
import { useAppDispatch } from 'store/hooks';
import { Theme } from 'styles/themes';
import { SortByDirectionButton } from 'components/SortByDirectionButton';
import { SortByDirection } from 'enums';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

interface SortDevelopmentsDropdownProps {
  sortByOptions: RadioButtonGroupOption[];
  sortByProperty: string;
  sortByDirection: SortByDirection;
  setSortByDirection: ActionCreatorWithPayload<SortByDirection, string>;
  setSortByProperty: ActionCreatorWithPayload<string, string>;
}

export const SortDevelopmentsDropdown = (
  props: SortDevelopmentsDropdownProps
) => {
  const dispatch = useAppDispatch();
  const sortDropdownContainerRef = useRef(null);
  const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);

  const sortByLabel = useMemo(() => {
    const option = props.sortByOptions.find(
      (o) => o.value === props.sortByProperty
    );
    return option ? option.text : props.sortByOptions[0].text;
  }, [props.sortByOptions, props.sortByProperty]);

  return (
    <FlexContainer
      id="DevelopmentSort"
      ref={sortDropdownContainerRef}
      style={{ alignItems: 'center', userSelect: 'none' }}
    >
      <SortByDirectionButton
        id="DevelopmentSortByDirectionButton"
        propertyToSortBy={props.sortByProperty}
        currentPropertyBeingSorted={props.sortByProperty}
        currentDirectionBeingSorted={props.sortByDirection}
        setSortByProperty={props.setSortByProperty}
        setSortByDirection={props.setSortByDirection}
      />
      <CursorPointer
        id="DevelopmentSortSortBy"
        style={{ display: 'flex', margin: '0 8px 0 6px' }}
        onClick={() => setIsSortDropdownOpen(!isSortDropdownOpen)}
      >
        <BoldText>
          <span style={{ marginRight: '5px' }}>Sort by</span>
          <span
            style={{
              color: Theme.colors.secondaryColor,
            }}
          >
            {sortByLabel}
          </span>
        </BoldText>
      </CursorPointer>
      <div id="DevelopmentSortOptions" style={{ position: 'relative' }}>
        <CursorPointer
          id="DevelopmentSortDropdownArrowContainer"
          onClick={() => setIsSortDropdownOpen(!isSortDropdownOpen)}
        >
          <ArrowDown
            id="DevelopmentSortDropdownArrow"
            style={{ color: Theme.colors.grayDarker }}
          />
        </CursorPointer>
        <Dropdown
          id="DevelopmentSortDropdown"
          isOpen={isSortDropdownOpen}
          setIsOpen={setIsSortDropdownOpen}
          containerRef={sortDropdownContainerRef}
          transform="translate(-100%, 15%)"
          style={{
            border: `1px solid ${Theme.colors.grayBorder}`,
            boxShadow: `0px 1px 15px ${Theme.colors.grayShadow}`,
          }}
        >
          <div style={{ width: '205px', color: Theme.colors.black }}>
            <RadioButtonGroup
              id="DevelopmentSortRadioButtonGroup"
              radioButtonGroupOptions={props.sortByOptions}
              value={props.sortByProperty}
              setValue={(value) => {
                setIsSortDropdownOpen(false);
                dispatch(props.setSortByProperty(value));
              }}
              name="sortBy"
              hasDividers
              fullWidth
            />
          </div>
        </Dropdown>
      </div>
    </FlexContainer>
  );
};
