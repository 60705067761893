import { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as StackSquaresIcon } from 'assets/stack-squares.svg';
import { Td, Tr } from 'components/Table';
import { TurnaroundTimeDetailedData } from 'models';
import { Theme } from 'styles/themes';
import { FlexContainer } from 'styles/utils';
import { formatNumber, formatToPercentage } from 'utils/number-formatter';
import { TurnaroundPageBucketTypes } from 'enums/TurnaroundPageBucketTypes';
import { turnaroundPageSummaryLabels } from '../labels';
import { millisecondsToHMS } from 'utils/date';
import { isNully } from 'utils/var';

interface TurnaroundTimeDetailsRowProps {
  data: TurnaroundTimeDetailedData;
  pageBucketType: TurnaroundPageBucketTypes;
  requiredTatText: string;
  onClickPageBucketCell?: (event: React.MouseEvent<any, MouseEvent>) => void;
  onClickOutsideTatCell?: (event: React.MouseEvent<any, MouseEvent>) => void;
}

const StyledTr = styled(Tr)({
  height: '48px',
});

const StyledTd = styled(Td)({
  border: `1px solid ${Theme.colors.grayBorder}`,
  borderCollapse: 'collapse',
  padding: '16px',
  textAlign: 'end',
});

const TdText = styled.span((props: { isRed?: boolean }) => ({
  fontSize: Theme.fontSize,
  color: props.isRed ? Theme.colors.red : Theme.colors.black,
}));

export const TurnaroundTimeDetailsRow = (
  props: TurnaroundTimeDetailsRowProps
) => {
  const { data } = props;
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [isPageBucketHovered, setIsPageBucketHovered] = useState(false);
  const [isOutsideTatHovered, setIsOutsideTatHovered] = useState(false);

  const hasOutsideTatData = useMemo(
    () => !isNully(data.totalOutsideTurnaroundTime),
    [data.totalOutsideTurnaroundTime]
  );

  const isBadOutsideTatTotal = useMemo(
    () =>
      !isNully(data.totalOutsideTurnaroundTime) &&
      data.totalOutsideTurnaroundTime! > 0,
    [data.totalOutsideTurnaroundTime]
  );

  const isBadWithinTatPercentage = useMemo(
    () =>
      !isNully(data.withinTurnaroundTimePercentage) &&
      data.withinTurnaroundTimePercentage! < 98,
    [data.withinTurnaroundTimePercentage]
  );

  const handleHighlightChange = useCallback(
    (highlight: boolean) => () => setIsHighlighted(highlight),
    [setIsHighlighted]
  );

  const handlePageBucketHover = useCallback(
    (isHovered: boolean) => () => setIsPageBucketHovered(isHovered),
    [setIsPageBucketHovered]
  );

  const handleOutsideTatHover = useCallback(
    (isHovered: boolean) => () => setIsOutsideTatHovered(isHovered),
    [setIsOutsideTatHovered]
  );

  return (
    <StyledTr
      onMouseEnter={handleHighlightChange(true)}
      onMouseLeave={handleHighlightChange(false)}
      isHighlighted={isHighlighted}
    >
      <StyledTd
        onMouseEnter={handlePageBucketHover(true)}
        onMouseLeave={handlePageBucketHover(false)}
        onClick={props.onClickPageBucketCell}
        style={{ cursor: 'pointer' }}
      >
        <FlexContainer style={{ alignItems: 'center', flexFlow: 'row' }}>
          <StackSquaresIcon style={{ marginRight: '12px' }} />
          <TdText
            style={{
              textDecorationLine: isPageBucketHovered ? 'underline' : 'none',
              color: Theme.colors.primaryColor,
            }}
          >
            {turnaroundPageSummaryLabels[props.pageBucketType]}
          </TdText>
        </FlexContainer>
      </StyledTd>
      <StyledTd>
        <TdText>{props.requiredTatText}</TdText>
      </StyledTd>
      <StyledTd>
        <TdText isRed={isBadWithinTatPercentage} style={{ fontWeight: 'bold' }}>
          {!isNully(data.withinTurnaroundTimePercentage)
            ? formatToPercentage(data.withinTurnaroundTimePercentage!)
            : '-'}
        </TdText>
      </StyledTd>
      <StyledTd
        onMouseEnter={handleOutsideTatHover(true)}
        onMouseLeave={handleOutsideTatHover(false)}
        onClick={props.onClickOutsideTatCell}
        style={{
          cursor: 'pointer',
          textDecorationLine:
            hasOutsideTatData && isOutsideTatHovered ? 'underline' : 'none',
        }}
      >
        {hasOutsideTatData ? (
          <>
            <TdText isRed={isBadOutsideTatTotal}>
              {formatNumber(data.totalOutsideTurnaroundTime!)}
            </TdText>
            <TdText>{` / ${formatNumber(data.totalDevelopments)}`}</TdText>
          </>
        ) : (
          <TdText>-</TdText>
        )}
      </StyledTd>
      <StyledTd>
        <TdText>
          {!isNully(data.minimumTurnaroundTimeInMilliseconds)
            ? millisecondsToHMS(data.minimumTurnaroundTimeInMilliseconds!)
            : '-'}
        </TdText>
      </StyledTd>
      <StyledTd>
        <TdText>
          {!isNully(data.maximumTurnaroundTimeInMilliseconds)
            ? millisecondsToHMS(data.maximumTurnaroundTimeInMilliseconds!)
            : '-'}
        </TdText>
      </StyledTd>
      <StyledTd>
        <TdText>
          {!isNully(data.averageTurnaroundTimeInMilliseconds)
            ? millisecondsToHMS(data.averageTurnaroundTimeInMilliseconds!)
            : '-'}
        </TdText>
      </StyledTd>
    </StyledTr>
  );
};
