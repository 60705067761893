export const formatNumber = (number: number): string => {
  return number.toLocaleString('en-US');
};

export const nFormatNumber = (num: number): string => {
  const fix = (num: number, fixed: number) => {
    const re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
    return (num.toString().match(re) || '')[0];
  };
  if (num >= 1000000000) {
    const fixedBillions = fix(num / 1000000000, 1);
    return (
      formatNumber(Number(fixedBillions.replace(/\.0$/, ''))) +
      `B${num / 1000000000 > Number(fixedBillions) ? '+' : ''}`
    );
  }
  if (num >= 1000000) {
    const fixedMillions = fix(num / 1000000, 1);
    return (
      formatNumber(Number(fixedMillions.replace(/\.0$/, ''))) +
      `M${num / 1000000 > Number(fixedMillions) ? '+' : ''}`
    );
  }
  return formatNumber(num);
};

export const formatToPercentage = (
  number: number,
  isSigned?: boolean
): string => {
  let percentage = `${formatNumber(number)}%`;
  if (isSigned && number > 0) {
    percentage = `+${percentage}`;
  }
  return percentage;
};

export const padTo2Digits = (num: number): string => {
  return num.toString().padStart(2, '0');
};
