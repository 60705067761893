import { Button, UnderlinedButton } from 'components/Button';
import { Input, PasswordInput } from 'components/Input';
import { routes } from 'navigation/routes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { matchPath, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { AuthenticationResponse, LoginCredentials } from 'models';
import {
  loginAsync,
  setAuthenticationResponse,
} from 'store/authentication/authentication-slice';
import { ErrorText, FlexContainer } from 'styles/utils';
import { Checkbox } from 'components/Checkbox';
import { ReactComponent as Warning } from 'assets/warning.svg';
import { Theme } from 'styles/themes';
import { HttpResponse } from 'utils/http/HttpResponse';
import { Loading } from 'components/Loading';
import { AuthContainer, LoginSubTitle } from 'pages/auth/components';
import { EnvironmentSelectorModal } from 'components/Environment';

export const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loginAuthenticationResponse, setLoginAuthenticationResponse] =
    useState<AuthenticationResponse | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const isLogInButtonDisabled = useMemo(() => {
    return !email || !password;
  }, [email, password]);

  useEffect(() => {
    window.onkeydown = (e: any) => {
      if (e.key === 'Enter' && !isLogInButtonDisabled) {
        handleLogin();
      }
    };
  }, [isLogInButtonDisabled, email, password]);

  const handleLogin = async () => {
    setIsLoading(true);
    const loginCredentials: LoginCredentials = {
      email,
      password,
      isRememberMe,
    };
    try {
      window.onkeydown = null;
      const response = await dispatch(loginAsync(loginCredentials));
      setLoginAuthenticationResponse(response);
    } catch (error) {
      const httpResponse = error as HttpResponse<null>;
      if (!httpResponse.success && httpResponse.error) {
        setErrorMessage(httpResponse.error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSetEnvironmentError = useCallback((message: string) => {
    setErrorMessage(message);
    setAuthenticationResponse(null);
  }, []);

  const detailsDevelopmentIdFromReturnUrl = useMemo(() => {
    const search = location.search;
    if (search.includes('returnUrl=')) {
      let returnUrl = location.search.split('returnUrl=')[1];
      if (returnUrl) {
        if (returnUrl.includes('&')) {
          returnUrl = returnUrl.split('&')[0];
        }
        if (matchPath(routes.developmentDetail, returnUrl)) {
          return Number(returnUrl.split('/')[2]);
        }
      }
    }
    return null;
  }, [location.search]);

  const goToForgotPassword = useCallback(() => {
    navigate(routes.forgotPassword);
  }, []);

  const handleToggleRememberMe = useCallback(
    () => setIsRememberMe(!isRememberMe),
    [isRememberMe]
  );

  return (
    <AuthContainer
      title="Welcome to Horizon"
      subtitle={
        <LoginSubTitle>Please complete all fields to log in.</LoginSubTitle>
      }
      showPolicy
    >
      <>
        {isLoading && <Loading id="LoginLoading" />}
        <FlexContainer
          id="LoginEmailContainer"
          style={{ width: '100%', marginBottom: '30px' }}
        >
          <label
            htmlFor="email"
            style={{ marginRight: 'auto', marginBottom: '5px' }}
          >
            Email
          </label>
          <Input
            id="email"
            placeholder="example@email.com"
            value={email}
            setValue={setEmail}
          />
        </FlexContainer>
        <FlexContainer
          id="LoginPasswordContainer"
          style={{ width: '100%', marginBottom: '15px' }}
        >
          <label
            htmlFor="password"
            style={{ marginRight: 'auto', marginBottom: '5px' }}
          >
            Password
          </label>
          <PasswordInput
            id="password"
            placeholder="●●●●●●●●"
            value={password}
            setValue={setPassword}
          />
        </FlexContainer>
        <FlexContainer
          id="LoginOptionsContainer"
          style={{ width: '100%', marginBottom: '20px' }}
        >
          <Checkbox
            id="RememberMe"
            label="Remember me"
            isChecked={isRememberMe}
            onChange={handleToggleRememberMe}
          />
          <UnderlinedButton
            id="ForgotPasswordButton"
            color={Theme.colors.secondaryColor}
            onClick={goToForgotPassword}
            style={{ marginLeft: 'auto' }}
          >
            Forgot password?
          </UnderlinedButton>
        </FlexContainer>
        <Button
          id="LoginSubmitButton"
          onClick={handleLogin}
          isDisabled={isLogInButtonDisabled}
        >
          Log in
        </Button>
        {errorMessage && errorMessage.length > 0 && (
          <FlexContainer id="LoginErrorContainer" style={{ marginTop: '10px' }}>
            <Warning
              id="LoginWarningIcon"
              style={{
                margin: 'auto 5px auto 0',
                color: Theme.colors.red,
                flex: '0 0 25px',
              }}
            />
            <ErrorText
              id="LoginErrorText"
              style={{ margin: 'auto 0', width: 'calc(100% - 30px)' }}
            >
              {errorMessage}
            </ErrorText>
          </FlexContainer>
        )}
        <EnvironmentSelectorModal
          loginAuthenticationResponse={loginAuthenticationResponse || null}
          developmentIdToFilterEnvironment={detailsDevelopmentIdFromReturnUrl}
          onErrorCallback={handleSetEnvironmentError}
        />
      </>
    </AuthContainer>
  );
};
