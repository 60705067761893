import { SelectSearchbar } from 'components/SelectSearchbar';

interface YearInputProps {
  values: any[];
  setValues: React.Dispatch<any[]>;
  options: {
    id: number;
    label: number;
  }[];
}

export const YearInput = (props: YearInputProps) => {
  return (
    <SelectSearchbar
      placeholder="Select year..."
      recordsPropertyToDisplay="label"
      recordsPropertyToSortBy="label"
      values={props.values}
      setValues={(values) => props.setValues(values)}
      perPage={props.options.length}
      rawData={props.options}
      isRawDataMode
    />
  );
};
