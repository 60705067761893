import styled from '@emotion/styled';
import { Theme } from 'styles/themes';
import { ReactComponent as CalendarIcon } from 'assets/calendar.svg';
import { DateFormats, dateRangeToString, DateRange } from 'utils/date';
import { isSameDay } from 'date-fns';
import { useMemo } from 'react';

export interface DateChipProps {
  range: Partial<DateRange> | null;
}

const ChipContainer = styled.div((props) => ({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: Theme.colors.white,
  border: `1px solid ${Theme.colors.grayBorder}`,
  borderRadius: Theme.borderRadius,
  overflow: 'hidden',
  cursor: 'pointer',
}));

const TextContainer = styled.div({
  padding: '4px 8px',
  fontSize: '16px',
  lineHeight: '22px',
  flexGrow: 2,
});

const MatchCounter = styled.div<{ grayed?: boolean }>((props) => ({
  backgroundColor: Theme.colors.primaryColor,
  minWidth: '32px',
  padding: '0px 4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const DateChip = (props: DateChipProps) => {
  const { range, ...rest } = props;

  const rangeToDisplay = useMemo(() => {
    if (!range) return range;
    const { startDate, endDate } = range;
    if (startDate && endDate && isSameDay(startDate, endDate)) {
      return { startDate };
    }
    return range;
  }, [range]);

  return (
    <>
      {rangeToDisplay && (
        <ChipContainer {...rest}>
          <MatchCounter>
            <CalendarIcon />
          </MatchCounter>
          <TextContainer>
            {dateRangeToString(rangeToDisplay, DateFormats.P, true)}
          </TextContainer>
        </ChipContainer>
      )}
    </>
  );
};
