import { FlexContainer } from 'styles/utils';
import {
  ReportsHeader,
  ReportsSubHeader,
  ReportsMainBody,
  ReportTableContainer,
} from 'pages/reports/components';
import { performanceReportLabels } from './labels';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectPerformanceReportSortByDirection,
  selectReportByProperty,
  setReportByProperty,
  selectPerformanceReportSortByProperty,
  selectPerformanceRows,
  increasePerformanceReportPage,
  selectPerformanceReportPage,
  selectPerformanceReportTotalRecords,
  selectPerformanceReportPerPage,
  selectIsReportDataFromAverageDevelopmentsPerDay,
  selectPeriodProperty,
  selectPerformanceReportSearchTerm,
  setPerformanceReportSearchTerm,
  getAllDataPerformanceReportAsync,
  setPeriodProperty,
  resetPerformanceReportPage,
} from 'store/performance-report/performance-report-slice';
import { PerformanceTable } from 'pages/reports/performance/components';
import { Loading } from 'components/Loading';
import { PerformanceReportEntity } from 'models';
import { useNavigate, generatePath } from 'react-router-dom';
import { routes } from 'navigation/routes';
import { reportByOptions } from 'pages/reports/performance/report-by-options';
import { PerformanceReportEntities } from 'enums';

export const PerformanceReport = () => {
  const navigate = useNavigate();
  const searchTerm = useAppSelector(selectPerformanceReportSearchTerm);
  const dispatch = useAppDispatch();
  const sortByProperty = useAppSelector(selectPerformanceReportSortByProperty);
  const sortByDirection = useAppSelector(
    selectPerformanceReportSortByDirection
  );
  const reportByProperty = useAppSelector(selectReportByProperty);
  const performanceRows = useAppSelector(selectPerformanceRows);
  const page = useAppSelector(selectPerformanceReportPage);
  const perPage = useAppSelector(selectPerformanceReportPerPage);
  const totalRecords = useAppSelector(selectPerformanceReportTotalRecords);
  const isReportDataFromAverageDevelopmentsPerDay = useAppSelector(
    selectIsReportDataFromAverageDevelopmentsPerDay
  );
  const [error, setError] = useState<string | null>(null); // Error state accepts string or null
  const periodProperty = useAppSelector(selectPeriodProperty);

  const reportByOptionEntity = useMemo(() => {
    return reportByOptions[reportByProperty];
  }, [reportByProperty]);

  const reportBySearchBarLabel = useMemo(() => {
    return reportByOptionEntity.searchBarText;
  }, [reportByProperty]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await dispatch(getAllDataPerformanceReportAsync());
      } catch (err: unknown) {
        if (err instanceof Error) {
          setError(err.message); // Accessing `message` property of `Error`
        } else {
          setError('An unexpected error occurred'); // Handling non-Error cases
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [
    sortByProperty,
    sortByDirection,
    page,
    isReportDataFromAverageDevelopmentsPerDay,
    periodProperty,
    searchTerm,
    reportByProperty,
  ]);

  const onReachBottom = () => {
    dispatch(increasePerformanceReportPage());
  };

  const handleClickRow = (row: PerformanceReportEntity) => {
    navigate(
      generatePath(routes.performanceDetails, {
        id: String(row.id),
        entityType: reportByOptionEntity.urlText,
      })
    );
  };

  const handleSetPeriod = (period: string) => {
    dispatch(setPeriodProperty(period));
  };

  const handleSetReportByProperty = (
    reportEntity: PerformanceReportEntities
  ) => {
    dispatch(setReportByProperty(reportEntity));
  };

  const handleResetPage = () => {
    dispatch(resetPerformanceReportPage());
  };

  return (
    <FlexContainer
      flexWrap="nowrap"
      flexDirection="column"
      style={{
        width: '100%',
        flex: 1,
        overflowX: 'hidden',
      }}
    >
      <ReportsHeader
        breadcrumb={[{ text: performanceReportLabels.title }]}
        hasBorderBottom
      />
      <ReportsSubHeader
        {...performanceReportLabels}
        searchBarLabel={`${performanceReportLabels.searchBarLabel} ${reportBySearchBarLabel}...`}
        searchProps={{
          searchTerm: searchTerm,
          onSearchChange: (value) => {
            dispatch(setPerformanceReportSearchTerm(value));
          },
        }}
        periodProps={{
          period: periodProperty,
          setPeriod: handleSetPeriod,
          onSelectPeriod: handleResetPage,
        }}
        reportByProps={{
          reportBy: reportByProperty,
          setReportBy: handleSetReportByProperty,
        }}
      />
      <ReportsMainBody>
        <FlexContainer style={{ height: 'calc(100% - 48px)' }}>
          <ReportTableContainer
            isReportDataFromAverageDevelopmentsPerDay={
              isReportDataFromAverageDevelopmentsPerDay
            }
            {...performanceReportLabels}
          >
            {isLoading && <Loading />}
            <PerformanceTable
              reportByProperty={reportByProperty}
              sortDirection={sortByDirection}
              sortField={sortByProperty}
              rows={performanceRows}
              onReachBottom={onReachBottom}
              onClickRow={handleClickRow}
              hasMoreRows={page < Math.ceil(totalRecords / perPage)}
            />
          </ReportTableContainer>
        </FlexContainer>
      </ReportsMainBody>
    </FlexContainer>
  );
};
