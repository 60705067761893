import { RadioButtonGroupOption } from 'components/RadioButtonGroup';
import { useAppSelector } from 'store/hooks';
import {
  selectDevelopmentsSortByDirection,
  selectDevelopmentsSortByProperty,
  setDevelopmentsSortByDirection,
  setDevelopmentsSortByProperty,
} from 'store/development/development-slice';
import { SortDevelopmentsDropdown } from 'components/SortDevelopmentsDropdown';
import { developmentKeys } from 'config/development-keys';

const isRightPanelShow =
  process.env.REACT_APP_IS_CITATION_RIGHT_PANEL_SHOW === 'true';

export const sortByOptions: RadioButtonGroupOption[] = [
  {
    text: 'Processed date',
    value: developmentKeys.processingDate,
  },
  {
    text: 'Published date',
    value: developmentKeys.mainPublicationDate,
  },
  {
    text: 'Effective date',
    value: developmentKeys.mainEffectiveDate,
  },
  {
    text: 'Obligation matches',
    value: developmentKeys.obligationsCount,
  },
];

// Add the "Citations" option conditionally
if (isRightPanelShow) {
  sortByOptions.push({
    text: 'Citations',
    value: developmentKeys.developmentCitationsCount,
  });
}

export const HomeSortDevelopmentsDropdown = () => {
  const sortByProperty = useAppSelector(selectDevelopmentsSortByProperty);
  const sortByDirection = useAppSelector(selectDevelopmentsSortByDirection);

  return (
    <SortDevelopmentsDropdown
      sortByOptions={sortByOptions}
      sortByProperty={sortByProperty}
      sortByDirection={sortByDirection}
      setSortByProperty={setDevelopmentsSortByProperty}
      setSortByDirection={setDevelopmentsSortByDirection}
    />
  );
};
